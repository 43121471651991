import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  registerables,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import 'chart.js/auto';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ...registerables
);

export const GeneralInfoChart = () => {
  const reportsInfo = useAppSelector((state) => state.reports);
  const { t } = useTranslation();
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      maintainAspectRatio: false,
    },
    cutout: '55%'
  };
  
  const labels = [reportsInfo.generalStandard && t("standardLuminaires"), reportsInfo.generalSolar && t("solarLuminaires"), reportsInfo.generalHybrid && t("hybridLuminaires"),
    reportsInfo.generalGateways && t("Gateways"), reportsInfo.generalSwitchboards && t("switchboards"), reportsInfo.generalRgb && t("rgbLuminaires")].filter(label => label);
  const colors = [reportsInfo.generalStandard && "#1B2559", reportsInfo.generalSolar && "#FFC107", reportsInfo.generalHybrid && "#45D0EE", reportsInfo.generalGateways && "#17181A40",
    reportsInfo.generalSwitchboards && "#A3AED0", reportsInfo.generalRgb && "#F1F4F9"].filter(color => color);
  
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: ` ${t("total")}`,
        data: [reportsInfo.generalStandard && reportsInfo.generalStandard, reportsInfo.generalSolar && reportsInfo.generalSolar, reportsInfo.generalHybrid && reportsInfo.generalHybrid,
          reportsInfo.generalGateways && reportsInfo.generalGateways, reportsInfo.generalSwitchboards && reportsInfo.generalSwitchboards,
          reportsInfo.generalRgb && reportsInfo.generalRgb].filter(data => data),
        backgroundColor: colors,
        weight: 0.5,
        borderWidth: labels.length === 1 ? 0 : 2,
      }
    ],
  };

  return (
    <div style={{ width: "200px", height: "200px" }}>
      <Doughnut options={options} data={data} />
    </div>
  )
};
