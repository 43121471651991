import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Select } from "antd";
import { useAppSelector } from "../../redux/hooks";
import { store } from "../../redux/store";
import { updateLanguageAction } from "../../redux/actions/profileActions/profileActions";
import { useProfileService } from "../../services/profileService";
import { items } from "../../utils/languages/changeLanguage";
import { useChangeLanguage } from "../../utils/languages/changeLanguage";
import { IconStyled } from "../Header/HeaderStyled";
import { CardStyled } from "./ProfilePageStyled";
import { ButtonOutlined } from "../../styles/shared-styles/buttons-sizes";
import { Text } from "../../styles/shared-styles/text-sizes";
import languageIcon from "../../assets/new_icons/Language.svg";

interface UserProps {
  language_name: string;
}

interface LanguageInformationProps {
  user: UserProps;
}

export const LanguageInformation: React.FC<LanguageInformationProps> = ({ user }: LanguageInformationProps) => {
  const [languageName, setLanguageName] = useState(user.language_name);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { changeLanguage }:any = useChangeLanguage();
  const { updateLanguage } = useProfileService();
  const { Option } = Select;
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t, i18n } = useTranslation();

  const onChange = (value: string) => {
    setLanguageName(value);
  };

  const handleUpdateLanguageClick = async () => {
    setIsLoading(true);
    setSuccess(false);

    const languageItem = items.find((item) => item.label === languageName);
    const languageId:any = languageItem ? languageItem.id : undefined;

    try {
      const token:any = Cookies.get("token");
      const response = await updateLanguage(token, languageId);
      setIsLoading(false);

      if (response) {
        store.dispatch(updateLanguageAction(languageId));
        await changeLanguage(languageItem);
        setSuccess(true);
      }

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const selectedLanguage = localStorage.getItem("selectedLanguage");

    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [i18n]);
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <CardStyled>
        <Text textType="subTitle" fontWeight="700" color={mainTextColor}>
          {t("language")}
        </Text>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
          <div style={{ width: "50%", paddingRight: "12px" }}>
            <Text marginBottom="16px">{t("systemLanguage")}</Text>
            <div style={{ position: "relative" }}>
              <IconStyled
                src={languageIcon}
                style={{
                  position: "absolute",
                  left: "0px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                  opacity: 0.7
                }}
              />
              <Select
                style={{ width: "100%" }}
                optionFilterProp="children"
                filterOption={filterOption}
                onChange={onChange}
                value={languageName}
                className="my-select"
                popupClassName="custom-select-popup-profile"
              >
                {items.map((item, index) => (
                  <Option key={index} value={item.label}>
                    {t(item.label)}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div style={{ alignSelf: "flex-end" }}>
            {isLoading ?
              <ButtonOutlined type="button" disabled style={{ border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }}>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                {t("loading...")}
              </ButtonOutlined>
            :
              <ButtonOutlined style={{ border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }} onClick={() => {handleUpdateLanguageClick()}}>   
                  {t("updateLanguage")}
              </ButtonOutlined>
            }
          </div>
        </div>
        <div style={{ height: '10px', textAlign: "right", alignSelf: "end" }}>
          {success ?
            <Text marginBottom="0px" color="green">{t("updated")}</Text>
          :
            <></>
          }
        </div>
      </CardStyled>
    </>
  );
};
