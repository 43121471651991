import Cookies from "js-cookie";
import { store } from "../redux/store";
import { notification } from "antd";
import { AddRemovePayload } from "../types/interfaces";

export const useGroupsService = () => {
  const token = Cookies.get("token");

  const getGroups = async (orgId: number, serviceId: number, setLoading?: (loading: boolean) => void) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_GROUPS_A as string}${orgId}&servid=${serviceId}`, {credentials: 'include'});
      const groups = await response.json();

      const ids:any = []
      groups.map((group:any) => {
        ids.push(group.groupId)
      });
      
      const values = store.getState();
      let groupsWithInfo:any = [];
      
      ids.forEach((id:any, i:any) => {
        let nodes = values.devices.nodes.filter((node:any) => node.groups?.includes(id.toString()));
        groupsWithInfo.push({ groupId: id, groupName: groups[i].groupName, star: groups[i].star, nodes: nodes });
      });

      //store.dispatch(getGroupsActionCreator({ groupsArr: groupsWithInfo }));
      
      if (setLoading) {
        setLoading(false);
      }

      return groupsWithInfo;
    } catch (error) {
      if (setLoading) {
        setLoading(false);
      }
      console.log('El error: ', error);
      return false;
    }
  };

  const getGroupInfo = async (groupId: number, orgId: number, serviceId: number, setLoading?: (loading: boolean) => void) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_GROUP_I as string}${groupId}?orgid=${orgId}&servid=${serviceId}`, {credentials: 'include'});
      const groupInfo = await response.json();
      const values = store.getState();
      let nodes = values.devices.nodes.filter((node:any) => node.groups?.includes(groupInfo[0].groupId.toString()));
      groupInfo[0].nodes = nodes;

      if (setLoading) {
        setLoading(false);
      }

      return groupInfo;
    } catch (error) {
      if (setLoading) {
        setLoading(false);
      }
      console.log('El error: ', error);
      return false;
    }
  };
  
  const postGroup = async (orgId: number, serviceId: number, setLoading: (loading: boolean) => void) => {
    const values = store.getState();
    let devicesIds:any = [];
    values.groups.selectedDevices.map((device:any) => {
      devicesIds.push(device.node_id)
    });

    const body = {
      group_name: values.groups.name,
      devices: devicesIds
    }
  
    try {
      let res = await fetch(`${process.env.REACT_APP_API_GROUP_P as string}${orgId}&servid=${serviceId}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        },
      });

      let groupId:any = await res.json()

      if (res.ok) {
        let tempDevices = JSON.parse(JSON.stringify(values.devices));
        
        tempDevices.nodes.forEach((item:any) => {
          devicesIds.forEach((element:any) => {
            if (item.node_id === element) {
              item.groups.push(groupId[0]);
            }
          });
        });

        return tempDevices

        //store.dispatch(loadDevicesActionCreator(tempDevices));
      }
      //return true
    } catch (error) {
      setLoading(false);
      console.log('El error: ', error);
      return false;
    }
  };
  
  const updateGroupName = async (payload: any, orgId: number, servId: number) => {
    try {
      let res = await fetch(
        `${process.env.REACT_APP_API_GROUP_N_U as string}${orgId}&servid=${servId}`,
        {
          method: "PUT",
          credentials: "include",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(payload),
        }
      );
      if (res.status === 200) {
        return true;
      }
    } catch (error: any) {
      notification.error({
        message: "Something went wrong while updating the group name.",
        description: null,
      });
      return false;
    }
  };
  
  const addRemoveDevice = async (payload: AddRemovePayload, orgId: number, servId: number)=>{
    const values = store.getState();

     try {
       let res = await fetch(
         `${process.env.REACT_APP_API_GROUP_D_U as string}${orgId}&servid=${servId}`,
         {
           method: "PUT",
           credentials: "include",
           headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(payload),
        }
      );

      if (res.status === 200) {
        let tempDevices = JSON.parse(JSON.stringify(values.devices));
        
        tempDevices.nodes.forEach((item:any) => {
          payload.add_devices.forEach((element:any) => {
            if (item.node_id === element) {
              const groupExists = item.groups.some((groupId: any) => groupId === payload.group_id.toString());
              if (!groupExists) {
                item.groups.push(payload.group_id.toString());
              }
            }
          });

          payload.remove_devices.forEach((element:any) => {
            if (item.node_id === element) {
              const groupExists = item.groups.some((groupId: any) => groupId === payload.group_id.toString());
              if (groupExists) {
                item.groups = item.groups.filter((groupId: any) => groupId !== payload.group_id.toString());
              }
            }
          });
        });

        return tempDevices
      }
    } catch (error: any) {
      notification.error({
        message: "Something went wrong while updating the group name.",
        description: null,
      });
      return false;
    }
  };
  
  const deleteGroup = async (groupId: number, orgId: number, servId: number) => {
    try {
      let res = await fetch(`${process.env.REACT_APP_API_GROUP_D as string}${groupId}&orgId=${orgId}&servId=${servId}`,
        {
          method: "DELETE",
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        return true;
      }
    } catch (error) {
      notification.error({
        message: "Something went wrong while deleting the group.",
        description: null,
      });
      console.log("El error: ", error);
      return false;
    }
  };

  return {
    getGroups,
    getGroupInfo,
    postGroup,
    updateGroupName,
    addRemoveDevice,
    deleteGroup
  };
};


