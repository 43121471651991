import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";
import DimmingChartSolar from "./DimmingChartSolar";
import DimmingChartStandard from "./DimmingChartStandard";
import { Review } from "../Step3Review/Review";
import { CardContainer, NextButton } from "../StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface DimmingProps {
  showDimming?: any;
  showReview?: any;
  setNewChartDataStandard?: any;
  newChartDataStandard?: any;
  setNewChartDataSolar?: any;
  newChartDataSolar?: any;
  goToReview?: any;
  errorMessage?: boolean;
  setErrorMessage?: any
}

export const Dimming = ({showDimming, showReview, setNewChartDataStandard, newChartDataStandard, setNewChartDataSolar, newChartDataSolar, goToReview, errorMessage,
  setErrorMessage }: DimmingProps) => {
  const [errorSunsetOffsetValue, setErrorSunsetOffsetValue] = useState(false);
  const [errorSunriseOffsetValue, setErrorSunriseOffsetValue] = useState(false);

  const selectedTypeOption = useAppSelector((state) => state.strategies.type?.value);
  const totalConsum = useAppSelector((state) => state.strategies.totalConsum);
  const maxConsum = useAppSelector((state) => state.strategies.maxConsum);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column", paddingLeft: "40px" }}>
      <div style={{ marginBottom: "16px" }}>
        {selectedTypeOption === "standard" ?
          <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step2")}</Text>
        :
          <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step3")}</Text>
        }
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("Dimming configuration")}</Text>
        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("Give a strategy the configuration you prefer. The energy saving will be calculated on the next step.")}</Text>
      </div>
      {selectedTypeOption === "standard" ?
        <DimmingChartStandard showDimming={showDimming} setNewChartDataStandard={setNewChartDataStandard} newChartDataStandard={newChartDataStandard}
          setErrorSunsetOffsetValue={setErrorSunsetOffsetValue} errorSunsetOffsetValue={errorSunsetOffsetValue} setErrorSunriseOffsetValue={setErrorSunriseOffsetValue}
          errorSunriseOffsetValue={errorSunriseOffsetValue}
        />
      :
        <DimmingChartSolar showDimming={showDimming} setNewChartDataSolar={setNewChartDataSolar} newChartDataSolar={newChartDataSolar} errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      }
      <NextButton style={{ justifySelf: "end", alignSelf: "center", width: "300px", marginTop: "64px", marginLeft: "-40px", background: errorSunsetOffsetValue ||
          errorSunriseOffsetValue || errorMessage || (selectedTypeOption !== "standard" && totalConsum > maxConsum) ? accentButtonsColor+95 : accentButtonsColor,
          border: errorSunsetOffsetValue || errorSunriseOffsetValue || errorMessage || (selectedTypeOption !== "standard" && totalConsum > maxConsum) ?
          `1px solid ${accentButtonsColor+95}` : `1px solid ${accentButtonsColor}`, cursor: errorSunsetOffsetValue || errorSunriseOffsetValue || errorMessage ||
          (selectedTypeOption !== "standard" && totalConsum > maxConsum) ? "not-allowed" : "pointer"
        }}
        onClick={() => {
          if (selectedTypeOption === "standard") {
            if (!errorSunsetOffsetValue && !errorSunriseOffsetValue) {
              goToReview();
            }
          } else {
            if (totalConsum <= maxConsum && !errorMessage) {
              goToReview();
            }
          }
        }}
      >
        {t("continue")}
      </NextButton>
      {showReview && <Review />}
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px", marginLeft: "-40px" }}>
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        {selectedTypeOption === "solar" && <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />}
        <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
      </div>
    </CardContainer>
  );
};
