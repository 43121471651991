import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { store } from "../../redux/store";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getUsersAction } from "../../redux/actions/usersActions/usersActions";
import { resetRegisterUserInfoActionCreator } from "../../redux/slices/userRegistrationSlice/userRegistrationSlice";
import { useUsersService } from "../../services/usersService";
import { LoaderModal } from "../LoaderModal/LoaderModal";
import { NewUserModal } from "./NewUserModal/NewUserModal";
import { UsersDetailsCard } from "./UsersDetailsCard/UsersDetailsCard";
import { UsersStructure } from "./UsersList/UsersStructure";
import { DropdownUsersStyled, UsersContainer } from "./UsersPageStyled";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import { TextSpan, Text } from "../../styles/shared-styles/text-sizes";
import { ReactComponent as PlusIcon } from "../../assets/new_icons/plus.svg";

interface UsersPageProps {}

export const UsersPage = () => {
  const [showOrgsList, setShowOrgsList] = useState<boolean>(false);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getUsers, getManagedUserOrgs, getManagedUserServices, getManagedUserGroups } = useUsersService();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const token = Cookies.get("token") || "";
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchManageUserData = async () => {
      try {
        setLoading(true);
        let response = await getUsers(token, setLoading); // Make the API call to fetch users
        if (response) {
          store.dispatch(getUsersAction(response));
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching users:", error);
      }
    };

    fetchManageUserData(); // Call the fetchManageUserData function when the component mounts
  }, []);
  {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "-100px", marginLeft: "-65px" }}>
    <Loader />
  </div> */}

  return (
    <UsersContainer>
      {loading ?
        <LoaderModal showLoader={loading} />
      : 
        <>
          {showNewUserModal && (
            <NewUserModal
              showModal={showNewUserModal}
              setShowModal={setShowNewUserModal}
            />
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "24px" }}>
            <div>
              <Text textType="title" fontWeight="700" marginBottom="8px">
                {t("manageYourUsers")}
              </Text>
              <Text textType="">{t("manageYourUsersSubtitle")}</Text>
            </div>
            {/* <div className="col-2">
              <DropdownUsersStyled
                style={{ paddingBottom: showOrgsList ? "20px" : "0", zIndex: 6 }}
              >
                {" "}
                <div onClick={() => setShowOrgsList(!showOrgsList)} className="filter__title-container" style={{ zIndex: 6 }}>
                  <span className="title-container__title">{t("All")}</span>
                  <span className="material-symbols-rounded title-container__icon">
                    {showOrgsList ? "expand_less" : "expand_more"}
                  </span>
                </div>
                {showOrgsList && (
                  <>
                    <ul className="list">
                      <div className="option-container">
                        <TextSpan>Option 1</TextSpan>
                      </div>
                    </ul>
                  </>
                )}
              </DropdownUsersStyled>
            </div> */}
            {user.role_name === "owner" ?
              <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button buttonType="primary" style={{ background: buttonsColor, color: accentTextColor, width: "150px", paddingLeft: "0px", paddingRight: "4px" }}
                  onClick={async() => {dispatch(resetRegisterUserInfoActionCreator()); setShowNewUserModal(true); getManagedUserOrgs(token, user.id, setLoading);
                    getManagedUserServices(token, user.id, setLoading)
                  }}
                >
                  <PlusIcon height={30} width={30} style={{ color: accentTextColor, marginBottom: "1.5px", marginRight: "4px" }} />
                  {t("New User")}
                </Button>
              </div>
            :
              <></>
            }
          </div>
          <div style={{ display: "flex", gap: "25px" }}>
            <div style={{ width: "350px" }}>
              <UsersStructure />
            </div>
            <div style={{ width: "1425px" }}>
              <UsersDetailsCard />
            </div>
          </div>
        </>
      }
    </UsersContainer>
  );
};
