import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { saveGroupInfoActionCreator } from "../../../../redux/slices/groupsSlice/groupsSlice";
import { Devices } from "../Step2/Devices";
import { LogoStyled } from "../../../Header/HeaderStyled";
import { InputField, CardContainer, NextButton } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import company from "../../../../assets/new_icons/Company.svg";
import map from "../../../../assets/new_icons/Map.svg";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface DetailsProps {
  showDevices?: any;
  goToDevices?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
};

export const Details = ({ showDevices, goToDevices, errorMessage, setErrorMessage }: DetailsProps) => {
  const dispatch = useAppDispatch();
  const groupInfo = useAppSelector((state) => state.groups);
  const orgImg: any = useAppSelector((state) => state.selectedOrganization.img);
  const orgName = useAppSelector((state) => state.selectedOrganization.orgName);
  const city = useAppSelector((state) => state.selectedOrganization.city);
  const country = useAppSelector((state) => state.selectedOrganization.country);
  const services = useAppSelector((state) => state.devices.services);
  const selectedServiceId = useAppSelector((state) => state.devices.selectedServiceId);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const { t } = useTranslation();

  const [name, setName] = useState(groupInfo.name);

  useEffect(() => {
    if (name) {
      setErrorMessage(false)
    };
    
    dispatch(saveGroupInfoActionCreator({ name: name }));
  }, [name]);

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: "36px", paddingLeft: "40px", marginLeft: "10px" }}>
        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step1")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("generalDetails")}</Text>
        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("generalDetailsSubtitleGroups")}</Text>
      </div>
      <div style={{ display: "flex", flexDirection: "column", paddingLeft: "40px", marginLeft: "10px", width: "400px", marginBottom: "57px" }}>
        <Text textType="caption" color={mainTextColor} marginBottom="12px" style={{fontSize: "14px"}}>{t("newGroupName")}*</Text>
        <InputField value={name} onChange={(e) => setName(e.target.value)} maxLength={50} placeholder={t("name")} />
        {
          errorMessage ?
            <Text marginBottom="0px" style={{display: "flex",  justifyContent: "center", color: "red"}}>{t("errorBlankInput")}</Text>  
          :
            <></>
        }
        <Text textType="caption" color={mainTextColor} marginBottom="12px" style={{marginTop: errorMessage ? "13px" : "34px", fontSize: "14px"}}>{t("organization")}</Text>
        <div className="card" style={{ padding: "14px", border: "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <LogoStyled src={orgImg ? `https://${orgImg}` : company} alt="smartec logo" />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text fontWeight="600" color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{orgName}</Text>
              <div style={{ display: "flex", gap: "6px", opacity: 0.4 }}>
                <img src={map} width={16} />
                <Text fontWeight="400" marginBottom="0px" style={{fontSize: "10px", marginTop: "2px"}}>{city}, {country}</Text>
              </div>
            </div>
          </div>
        </div> 
        <Text textType="caption" color={mainTextColor} marginBottom="12px" style={{marginTop: "34px", fontSize: "14px"}}>{t("services")}</Text>
        <div style={{ display: "flex", gap: "14px"}}>
          {services.map((service:any, index:any) => (
            <div key={index} className="card" style={{ padding: "8px", border: service.service_id === selectedServiceId ? "solid 2px" : "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
              backdropFilter: "blur(2px)", borderRadius: "10px", width: "50%", display: "flex", alignItems: "center",
               }}
            >
              <Text fontWeight="600" color={mainTextColor} marginBottom="0px" style={{fontSize: "14px", marginTop: service.service_id === selectedServiceId ? "0px" : "3px"}}>
                {service.service_name}
              </Text>         
            </div>
          ))}
        </div>
      </div>
      <NextButton style={{ background: errorMessage ? accentButtonsColor+95 : accentButtonsColor, borderColor: errorMessage ? `1px solid ${accentButtonsColor+95}` : accentButtonsColor,
        justifySelf: "end", alignSelf: "center", width: "300px", marginTop: "195px", cursor: errorMessage ?"not-allowed" : "pointer" }}
        onClick={() => {
          if (!errorMessage) {
            goToDevices()
          }
        }}
      >
        {t("continue")}
      </NextButton>
        {showDevices && <Devices />}
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
        <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
      </div>
    </CardContainer>
  );
};
