import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store";
import { UsersList } from "./UsersList";
import { CardContainer } from "../UsersPageStyled";
import { NavLink, Structure } from "./UsersStructureStyled";
import { Text } from "../../../styles/shared-styles/text-sizes";
import search from "../../../assets/new_icons/Search.svg";

interface UsersStructureProps {}

export const UsersStructure = ({}: UsersStructureProps) => {
  const [active, setActive] = useState<boolean>(true);
  const [inactive, setInactive] = useState<boolean>(false);
  const [searchUser, setSearchUser]:any = useState();
  const usersList = useAppSelector((state: RootState) => state.managedUsersList.users);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column", width: "350px", padding: "14px", paddingRight: "10px" }}>
      <div style={{ cursor: "default", marginBottom: "8px", position: 'relative', display: 'inline-block',  width: "100%", alignSelf: "flex-start", justifySelf: "flex-start" }}>
        <img src={search} width={16} style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translateY(-50%)' }}/>
        <input type="text" style={{ border: "none", background: "rgba(189, 189, 189, 0.2)", paddingLeft: "35px", width: "100%", height: "40px", borderRadius: "4px" }}
          placeholder={active ? usersList.filter((user:any) => user.active === "Y").length.toString() + " " + `${t("activeUsers")}` : 
            usersList.filter((user:any) => user.active === "N").length.toString() + " " + `${t("inactiveUsers")}`}
          value={searchUser}
          onChange={(e) => setSearchUser(e.target.value)}  
        />
      </div>
      {usersList ?
        <>      
          <div className="nav nav-pills" style={{ marginBottom: "16px" }}>
            <div className="nav-item">
              <NavLink accentButtonsColor={accentButtonsColor} className="nav-link active" id="users-tab" data-bs-toggle="tab" type="button" onClick={() => {setActive(true); setInactive(false)}}>
                {t("active")}
              </NavLink>
            </div>
            {usersList?.some((user:any) => user.active === "N") ? 
              <div className="nav-item">
                <NavLink accentButtonsColor={accentButtonsColor} className="nav-link" id="notusers-tab" data-bs-toggle="tab" type="button" onClick={() => {setInactive(true); setActive(false)}}>
                  {t("notActive")}
                </NavLink>
              </div>
            :
              <></>
            }
          </div>
          {active ?
            <Structure>
              <UsersList users={usersList?.filter((user:any) => user.active === "Y" && (!searchUser || user.name?.toLowerCase().includes(searchUser?.toLowerCase())))} />
            </Structure>
          :
            <Structure>
              <UsersList users={usersList?.filter((user:any) => user.active === "N" && (!searchUser || user.name?.toLowerCase().includes(searchUser?.toLowerCase())))} />
            </Structure>
          }
        </>
      :
        <div style={{display: "flex", justifyContent: "center"}}>
          <Text marginBottom="0px">{t("noUsers")}</Text>
        </div>
      }
    </CardContainer>
  );
};
