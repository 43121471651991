import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { useOrganizationsService } from "../../../services/selectedOrganizationService"
import { decimalRegex, numericRegex } from "../../../utils/regex";
import { ButtonOutlined } from "../../../styles/shared-styles/buttons-sizes";
import { CommonCardContainer } from "../../../styles/shared-styles/card-container";
import { Text } from "../../../styles/shared-styles/text-sizes";

export const ConsumptionConfig = () => {
  const orgInfo: any = useAppSelector((state) => state.selectedOrganization);
  const [currency, setCurrency] = useState(orgInfo.currency);
  const [price, setPrice] = useState(orgInfo.price);
  const [emissions, setEmissions] = useState(orgInfo.co2);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<boolean>(false);
  const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId = useAppSelector((state) => state.devices.selectedServiceId);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { updateOrgConsumption, getSelectedOrganizationInfo } = useOrganizationsService();
  const { t } = useTranslation();

  const handleUpdateConsumption = async () => {
    setIsLoading(true);
    setErrorUpdate(false);
    setSuccess(false);

    try {
      await updateOrgConsumption(selectedOrgId, /* currency, */ price, emissions);
      const orgInfo = await getSelectedOrganizationInfo(selectedOrgId, selectedServiceId);
      setIsLoading(false);

      if (orgInfo) {
        setSuccess(true);
      } else {
        setErrorUpdate(true);
      }

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      setIsLoading(false);
      setErrorUpdate(true);
    }
  };

  return (
    <CommonCardContainer className="mb-4">
      <Text className="mb-4" textType="cardTitle" color="black">{t("consumptionConfig")}</Text>
      <div className="d-flex justify-content-between align-items-end">
        <div className="d-flex align-items-center gap-3">
          {/* <div style={{ marginRight: "12px" }}>
            <Text marginBottom="8px">{t("currency")}</Text>
            <Select style={{ width: "100px", height: 35 }} defaultValue={currency}
              options={[
                {
                  label: "USD",
                  value: "USD",
                },
                {
                  label: "EUR",
                  value: "EUR",
                },
                {
                  label: "GBP",
                  value: "GBP",
                },
                {
                  label: "SAR",
                  value: "SAR",
                }
              ]}
            />
          </div> */}
          <div style={{ marginRight: "12px" }}>
            <Text marginBottom="8px">{t("priceKwH")}</Text>
            <input className="form-control" style={{ width: "100px" }} value={price} onChange={(e) => {setPrice(e.target.value); setErrorData(false); setErrorUpdate(false)}} />
          </div>
          <div style={{ marginRight: "12px" }}>
            <Text marginBottom="8px">{t("co2Emissions")}</Text>
            <input className="form-control" style={{ width: "100px" }} value={emissions} onChange={(e) => {setEmissions(e.target.value); setErrorData(false); setErrorUpdate(false)}} />
          </div>
        </div>
        {isLoading ? (
          <ButtonOutlined type="button" disabled height="40px" style={{ alignSelf: "flex-end", border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              {t("loading...")}
          </ButtonOutlined>
        ) : (
          <ButtonOutlined height="40px" style={{ alignSelf: "flex-end", border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }} onClick={() => {
            if (/^\s/.test(price) || /^\s/.test(emissions) || (price === "" && emissions === "")) {
              setErrorData(true)
            } else {
              if (price === "" || decimalRegex.test(price) && (emissions === "" || decimalRegex.test(emissions))) {
                handleUpdateConsumption();
              } else {
                setErrorData(true)
              }
            }
          }}>
            {t("Update")}
          </ButtonOutlined>
        )}
      </div>
      <div style={{ height: '10px', textAlign: "right", alignSelf: "end" }}>
        {errorData ? (
          <Text marginBottom="0px" color="red">{t("errorBlankInput")}</Text>
        ) :
          errorUpdate ? (
            <Text marginBottom="0px" color="red">{t("errorUpdating")}</Text>
          ) :
            success ? (
              <Text marginBottom="0px" color="green">{t("updated")}</Text>
            ) : (
              <></>
        )}
      </div>
    </CommonCardContainer>
  )
}
