import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  registerables,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import 'chart.js/auto';
import { useAppDispatch } from "../../../redux/hooks";
import { saveImageAlarmsActionCreator } from "../../../redux/slices/reportsSlice/pdfReportSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ...registerables
);

interface ValuesProps {
  values: any;
}

export const AlarmsChartPdf = ({ values }: ValuesProps) => {
  const chartRef:any = useRef(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
      },
      maintainAspectRatio: false,
    },
    cutout: '85%'
  };
  
  const labels = [t("solved"), t("pending")];
  const colors = ["#56C568", "#A3AED020"];
  
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: ` ${t("total")}`,
        data: [((values.pdfReport.resAlarmsSolved / values.pdfReport.resAlarmsTotal) * 100).toFixed(2) === "NaN" ? 0 : ((values.pdfReport.resAlarmsSolved / values.pdfReport.resAlarmsTotal) * 100).toFixed(2),
          ((values.pdfReport.resAlarmsPending / values.pdfReport.resAlarmsTotal) * 100).toFixed(2) === "NaN" ? 0 : ((values.pdfReport.resAlarmsPending / values.pdfReport.resAlarmsTotal) * 100).toFixed(2)],
        backgroundColor: colors,
        weight: 0.5
      }
    ],
  };

  useEffect(() => {
    const chart:any = chartRef.current;
    if (chart) {
      setTimeout(() => {
        const chartImage = chart.toBase64Image();
        dispatch(saveImageAlarmsActionCreator(chartImage));
      }, 2000);
    }
  }, []);

  return (
    <div style={{ width: "200px", height: "200px", position: "relative" }}>
      <Doughnut ref={chartRef} options={options} data={data} />
    </div>
  )
};
