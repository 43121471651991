import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { useOrganizationsService } from "../../../services/selectedOrganizationService";
import { PlatformPaletteModal } from "./PlatformPaletteModal";
import { Text } from "../../../styles/shared-styles/text-sizes";
import { CommonCardContainer } from "../../../styles/shared-styles/card-container";
import { ButtonOutlined } from "../../../styles/shared-styles/buttons-sizes";

export const PlatformPalette = () => {
  const [showModal, setShowModal] = useState(false);
  const [openNavBarColor, setOpenNavBarColor] = useState(false);
  const [openButtonsColor, setOpenButtonsColor] = useState(false);
  const [openAccentButtonsColor, setOpenAccentButtonsColor] = useState(false);
  const [openMainTextColor, setOpenMainTextColor] = useState(false);
  const [openSupportTextColor, setOpenSupportTextColor] = useState(false);
  const [openAccentTextColor, setOpenAccentTextColor] = useState(false);
  const [isLoadingReset, setIsLoadingReset] = useState<boolean>(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  const [errorExistingData, setErrorExistingData] = useState<boolean>(false);
  const [errorResetDone, setErrorResetDone] = useState<boolean>(false);
  const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  
  const navBarColor = useAppSelector((state) => state.selectedOrganization.navBarColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const [tempNavBarColor, setTempNavBarColor]:any = useState(navBarColor);
  const [tempButtonsColor, setTempButtonsColor]:any = useState(buttonsColor);
  const [tempAccentButtonsColor, setTempAccentButtonsColor]:any = useState(accentButtonsColor);
  const [tempMainTextColor, setTempMainTextColor]:any = useState(mainTextColor);
  const [tempSupportTextColor, setTempSupportTextColor]:any = useState(supportTextColor);
  const [tempAccentTextColor, setTempAccentTextColor]:any = useState(accentTextColor);

  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId = useAppSelector((state) => state.devices.selectedServiceId);
  const { updatePlatformPalette, getSelectedOrganizationInfo } = useOrganizationsService();
  const { t } = useTranslation();

  const handleResetPlatformPalette = async () => {
    setIsLoadingReset(true);
    setErrorUpdate(false);
    setErrorResetDone(false);
    setSuccess(false);
    
    try {
      await updatePlatformPalette(selectedOrgId, "#FFFFFF", "#FFC107", "#1B2559", "#475569", "#434F72", "#1B2559");
      /* await updatePlatformPalette(selectedOrgId, "#046F6D", "#046F6D", "#046F6D", "#3C3C3B", "#046F6D", "#FFFFFF"); */
      const orgInfo = await getSelectedOrganizationInfo(selectedOrgId, selectedServiceId);
      setIsLoadingReset(false);

      if (orgInfo) {
        setSuccess(true);
      } else {
        setErrorUpdate(true);
      }

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      setIsLoadingReset(false);
      setErrorUpdate(true);
    }
  };

  const handleUpdatePlatformPalette = async () => {
    setIsLoadingUpdate(true);
    setErrorUpdate(false);
    setErrorResetDone(false);
    setSuccess(false);

    try {
      await updatePlatformPalette(selectedOrgId, tempNavBarColor, tempButtonsColor, tempAccentButtonsColor, tempMainTextColor, tempSupportTextColor, tempAccentTextColor);
      const orgInfo = await getSelectedOrganizationInfo(selectedOrgId, selectedServiceId);
      setIsLoadingUpdate(false);

      if (orgInfo) {
        setSuccess(true);
      } else {
        setErrorUpdate(true);
      }

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      setIsLoadingUpdate(false);
      setErrorUpdate(true);
    }
  };

  useEffect(() => {
    setTempNavBarColor(navBarColor);
    setTempButtonsColor(buttonsColor);
    setTempAccentButtonsColor(accentButtonsColor);
    setTempMainTextColor(mainTextColor);
    setTempSupportTextColor(supportTextColor);
    setTempAccentTextColor(accentTextColor);
  }, [isLoadingReset, isLoadingUpdate]);
  
  return (
    <CommonCardContainer className="mb-4">
      <div className="palette-wrapper">
        <Text className="mb-4" textType="cardTitle" color="black">{t("platformColorPalette")}</Text>
        <div className="d-flex justify-content-between align-items-end">
          {/* d-flex align-items-start justify-content-between gap-5 */}
          <div className="d-flex align-items-start justify-content-between" style={{ width: '100%' }}>
            <div className="preview d-flex align-items-center">
              <p className="indicator" style={{ background: tempNavBarColor, color: "#a3aed0" }}>1</p>
              <div className="vr"></div>
              <div className="d-flex flex-column" style={{ marginBottom: "auto", marginLeft: "20px", marginTop: "20px" }}>
                <div className="d-flex flex-column align-items-start gap-1">
                  <p className="indicator" style={{ background: "none", color: "#a3aed0" }}>4</p>
                  <Text fontWeight="600" marginBottom="0" color={tempMainTextColor}>{t("titles&Text")}</Text>
                </div>
                <div className="d-flex flex-column align-items-start gap-1">
                  <Text marginBottom="0" color={tempSupportTextColor} style={{ fontSize: "10px" }}>{t("supportText")}</Text>
                  <p className="indicator" style={{ background: "none", color: "#a3aed0" }}>5</p>
                </div>
                <div className="d-flex flex-column align-items-start gap-1" style={{ marginTop: "20px"}}>
                  <p className="indicator" style={{ background: "none", color: "#a3aed0" }}>6</p>
                  <Text fontWeight="600" marginBottom="0" color={tempAccentTextColor}>{t("accentText")}</Text>
                </div>
              </div>
              <div className="d-flex flex-column align-items-center gap-4" style={{ marginLeft: "auto", marginRight: "40px"}}>
                <div className="d-flex flex-column align-items-center gap-2">
                  <p className="indicator" style={{ background: "none", color: "#a3aed0" }}>2</p>
                  <div className="line" style={{ background: tempButtonsColor }}></div>
                </div>
                <div className="d-flex flex-column align-items-center gap-2">
                  <p className="indicator" style={{ background: "none", color: "#a3aed0" }}>3</p>
                  <div className="lineBlank" style={{ border: `2px solid ${tempAccentButtonsColor}` }}></div>
                </div>
              </div>
            </div>
            <div className="d-flex gap-3" style={{ width: '400px', flexWrap: "wrap" }}>
              <div className="mb-4">
                <Text marginBottom="8px">1 - {t("navbar")}</Text>
                <input className="form-control" style={{ width: "100px", cursor: "pointer" }} placeholder="#" value={tempNavBarColor || navBarColor}
                  onClick={() => {setOpenNavBarColor(true); setShowModal(true); setErrorExistingData(false); setErrorUpdate(false); setErrorResetDone(false)}}
                />
              </div>
              <div className="mb-4">
                <Text marginBottom="8px">2 - {t("buttons")}</Text>
                <input className="form-control" style={{ width: "100px", cursor: "pointer" }} placeholder="#" value={tempButtonsColor || buttonsColor}
                  onClick={() => {setOpenButtonsColor(true); setShowModal(true); setErrorExistingData(false); setErrorUpdate(false); setErrorResetDone(false)}}
                />
              </div>
              <div className="mb-4">
                <Text marginBottom="8px">3 - {t("accentButtons")}</Text>
                <input className="form-control" style={{ width: "100px", cursor: "pointer" }} placeholder="#" value={tempAccentButtonsColor || accentButtonsColor}
                  onClick={() => {setOpenAccentButtonsColor(true); setShowModal(true); setErrorExistingData(false); setErrorUpdate(false); setErrorResetDone(false)}}
                />
              </div>
              <div className="mb-4">
                <Text marginBottom="8px">4 - {t("mainText")}</Text>
                <input className="form-control" style={{ width: "100px", cursor: "pointer" }} placeholder="#" value={tempMainTextColor || mainTextColor}
                  onClick={() => {setOpenMainTextColor(true); setShowModal(true); setErrorExistingData(false); setErrorUpdate(false); setErrorResetDone(false)}}
                />
              </div>
              <div className="mb-4">
                <Text marginBottom="8px">5 - {t("supportText")}</Text>
                <input className="form-control" style={{ width: "100px", cursor: "pointer" }} placeholder="#" value={tempSupportTextColor || supportTextColor}
                  onClick={() => {setOpenSupportTextColor(true); setShowModal(true); setErrorExistingData(false); setErrorUpdate(false); setErrorResetDone(false)}}
                />
              </div>
              <div className="mb-4">
                <Text marginBottom="8px">6 - {t("accentText")}</Text>
                <input className="form-control" style={{ width: "100px", cursor: "pointer" }} placeholder="#" maxLength={7} value={tempAccentTextColor || accentTextColor}
                  onClick={() => {setOpenAccentTextColor(true); setShowModal(true); setErrorExistingData(false); setErrorUpdate(false); setErrorResetDone(false)}}
                />
              </div>
            </div>
            {showModal &&
              <PlatformPaletteModal showModal={showModal} setShowModal={setShowModal} colorToChange={openNavBarColor ? tempNavBarColor : openButtonsColor ? tempButtonsColor : openAccentButtonsColor ?
                tempAccentButtonsColor : openMainTextColor ? tempMainTextColor : openSupportTextColor ? tempSupportTextColor : openAccentTextColor ? tempAccentTextColor : ""}
                openNavBarColor={openNavBarColor} setOpenNavBarColor={setOpenNavBarColor} openButtonsColor={openButtonsColor} setOpenButtonsColor={setOpenButtonsColor}
                openAccentButtonsColor={openAccentButtonsColor} setOpenAccentButtonsColor={setOpenAccentButtonsColor} openMainTextColor={openMainTextColor} setOpenMainTextColor={setOpenMainTextColor}
                openSupportTextColor={openSupportTextColor} setOpenSupportTextColor={setOpenSupportTextColor} openAccentTextColor={openAccentTextColor} setOpenAccentTextColor={setOpenAccentTextColor}
                setTempNavBarColor={setTempNavBarColor} setTempButtonsColor={setTempButtonsColor} setTempAccentButtonsColor={setTempAccentButtonsColor}
                setTempMainTextColor={setTempMainTextColor} setTempSupportTextColor={setTempSupportTextColor} setTempAccentTextColor={setTempAccentTextColor}
              />
            }
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ position: "absolute", right: "560px" }}>
              {isLoadingReset ? (
                <ButtonOutlined type="button" disabled height="40px" style={{ alignSelf: "flex-end", border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }}>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    {t("loading...")}
                </ButtonOutlined>
              ) : (
                <ButtonOutlined height="40px" style={{ alignSelf: "flex-end", border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }} onClick={() => {
                  if (tempNavBarColor === "#FFFFFF" && tempButtonsColor === "#FFC107" && tempAccentButtonsColor === "#1B2559" && tempMainTextColor === "#475569" &&
                  tempSupportTextColor === "#A3AED0" && tempAccentTextColor == "#1B2559") {
                  /* if (tempNavBarColor === "#046F6D" && tempButtonsColor === "#046F6D" && tempAccentButtonsColor === "#046F6D" && tempMainTextColor === "#3C3C3B" &&
                    tempSupportTextColor === "#046F6D" && tempAccentTextColor == "#FFFFFF") { */
                    setErrorResetDone(true);
                  } else {
                    handleResetPlatformPalette();
                  }
                }}>
                  {t("defaultStyle")}
                </ButtonOutlined>
              )}
            </div>
            {isLoadingUpdate ? (
              <ButtonOutlined type="button" disabled height="40px" style={{ alignSelf: "flex-end", border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }}>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                  {t("loading...")}
              </ButtonOutlined>
            ) : (
              <ButtonOutlined height="40px" style={{ alignSelf: "flex-end", border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }} onClick={() => {
                if (tempNavBarColor === navBarColor && tempButtonsColor === buttonsColor && tempAccentButtonsColor === accentButtonsColor && tempMainTextColor === mainTextColor &&
                  tempSupportTextColor === supportTextColor && tempAccentTextColor == accentTextColor) {
                    setErrorExistingData(true);
                  } else {
                    handleUpdatePlatformPalette();
                  }
              }}>
                {t("Update")}
              </ButtonOutlined>
            )}
          </div>
          {/* <ButtonOutlined height="44px" style={{ border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }}
            onClick={async () => {handleUpdatePlatformPalette()}}
          >
            {t("Update")}
          </ButtonOutlined> */}
        </div>
      </div>
      <div style={{ height: '10px', textAlign: "right", alignSelf: "end" }}>
        {errorExistingData ? (
          <Text marginBottom="0px" color="red">{t("errorExistingData")}</Text>
        ) :
          /* errorResetDone ? (
            <Text marginBottom="0px" color="red">{t("errorDefaultStyle")}</Text>
          ) : */
            errorUpdate ? (
              <Text marginBottom="0px" color="red">{t("errorUpdating")}</Text>
            ) :
              success ? (
                <Text marginBottom="0px" color="green">{t("updated")}</Text>
              ) : (
                <></>
        )}
      </div>
    </CommonCardContainer>
  )
}
