import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { LatLng } from "leaflet";
import { store } from "../../redux/store";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { fetchAlarmsFromNode } from "../../redux/actions/alarmsActions/alarmsActions";
import { resetParametersActionCreator } from "../../redux/slices/electricParametersSlice/electricParametersSlice";
import { resetSolarParametersConsumptionActionCreator } from "../../redux/slices/solar/solarParametersSlice";
import { setIsFullViewActionCreator, setIsHybridViewActionCreator, setIsListViewActionCreator, setFirstRenderMapToTrueActionCreator, allowFlyModeActionCreator, setViewsToFalseActionCreator 
} from "../../redux/slices/uiSlice/uiSlice";
import { useAlarmsService } from "../../services/alarmService";
import { useLightingService } from "../../services/lightingService";
import { useStrategiesService } from "../../services/strategiesService";
import { AlarmsCard } from "./AlarmsCard/AlarmsCard";
import { Battery } from "./SolarLuminaire/Battery/Battery";
import { BatteryConfig } from "./SolarLuminaire/BatteryConfig/BatteryConfig";
import { CurrentStrategy } from "./CurrentStrategy/CurrentStrategy";
import { DimmingCard } from "./DimmingCard/DimmingCard";
import { ElectricalParametersCard } from "./ElectricalParametersCard/ElectricalParametersCard";
import { Consumption } from "./SolarLuminaire/Consumption/Consumption";
import { GroupsCard } from "./GroupsCard/GroupsCard";
import { Lifetime } from "./SolarLuminaire/Lifetime/Lifetime";
import { LocalWeather } from "../LocalWeather/LocalWeather";
import { MiniMap } from "../CustomMaps/CustomMap/MiniMap";
import { Pannel } from "./SolarLuminaire/Pannel/Pannel";
import { Luminaire } from "./SolarLuminaire/Luminaire/Luminaire";
import { RGBCard } from "./RGBCard/RGBCard";
import { SavingsCard } from "./SavingsCard/SavingsCard";
import { StatusCard } from "./StatusCard/StatusCard";
import { TemporaryEventsCard } from "./TemporaryEventsCard/TemporaryEventsCard";
import { TotalsCard } from "./TotalsCard/TotalsCard";
import { ViewCamera } from "./ViewCamera/ViewCamera";
import { CardContainer, CardSimple, IconStyled } from "./LuminairePageStyled";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import alarmTime from "../../assets/new_icons/alarm-time.svg";
import back from "../../assets/new_icons/back.svg";

interface LuminairePageProps {}

export const LuminairePage = ({}: LuminairePageProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let { nodeId } = useParams();
  //const viewState = location.state.viewState;
  const viewState = localStorage?.getItem('viewState') || 'full';
  const { getNodeInfo, getRgbNodeInfo } = useLightingService();
  const { getStrategies, getSolarStrategies } = useStrategiesService();
  const { getNodeAlarms } = useAlarmsService();
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const nodeFromRedux = useAppSelector((state) => state.manageNode);
  const [node, setNode]: any = useState(nodeFromRedux);
  const [creationDate, setCreationDate]: any = useState();
  const [updateDate, setUpdateDate]: any = useState();
  const allDevices = useAppSelector((state) => state.devices);
  const devices = useAppSelector((state) => state.filteredDevices);
  const userRole = useAppSelector((state) => state.user.role_name);
  const isRgbVisible = useAppSelector((state) => state.ui.rgb);
  const { t } = useTranslation();
  console.log("node", node)
  const mapHalfSize = { height: "100%" };
  
  // manage going back through the button stacked in the navigator
  window.addEventListener("popstate", () => {
    if (viewState === "full") {
      dispatch(setIsFullViewActionCreator());
    } else if (viewState === "hybrid") {
      dispatch(setIsHybridViewActionCreator());
    } else if (viewState === "list") {
      dispatch(setIsListViewActionCreator());
    }
    navigate("/dashboard");
  });

  // manage going back through the button "back to overview""
  const goBackToMap = () => {
    if (viewState === "full") {
      dispatch(setIsFullViewActionCreator());
    } else if (viewState === "hybrid") {
      dispatch(setIsHybridViewActionCreator());
    } else if (viewState === "list") {
      dispatch(setIsListViewActionCreator());
    }
    //dispatch(allowFlyModeActionCreator(false))
    dispatch(setFirstRenderMapToTrueActionCreator());
    navigate("/dashboard");
  };

  const parsedLastUpdateDate = (dateString: string) => {
    let date = new Date(dateString);
  
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let year = date.getFullYear();
    let hour:any = date.getHours().toString().padStart(2, '0');
    let minute = date.getMinutes().toString().padStart(2, '0');
  
    return `${day}/${month}/${year} - ${hour}:${minute}`;
  }

  /* const parsedLastUpdateDate = (dateString: string) => {
    let date = new Date(dateString);
  
    let day = date.getUTCDate().toString().padStart(2, '0');
    let month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    let year = date.getUTCFullYear();
    let hour:any = date.getUTCHours().toString().padStart(2, '0');
    let minute = date.getUTCMinutes().toString().padStart(2, '0');
  
    return `${day}/${month}/${year} - ${hour}:${minute}`;
  } */

  useEffect(() => {
    const fetchNodeData = async (nodeId:any, viewState:any) => {
      const node = allDevices.nodes.find((node:any) => node.node_id === nodeId);
      const nodeRgb = allDevices.rgbNodes.find((node:any) => node.node_id === nodeId);

      if (node || nodeRgb) {
        if (nodeRgb !== undefined) {
          await getRgbNodeInfo(nodeId);
        } else {
          await getNodeInfo(nodeId);
          if (node.solar_id === null) {
            await getStrategies(selectedOrgId, selectedServiceId);
          } else {
            await getSolarStrategies(selectedOrgId, selectedServiceId);
          }
          try {
            const token:any = Cookies.get("token");
            let response = await getNodeAlarms(selectedOrgId, selectedServiceId, nodeId, token)
            if (response) {
              store.dispatch(fetchAlarmsFromNode(response));
            }
          } catch (error) {
            console.error("Error fetching alarm data:", error);
          }
          //dispatch(resetParametersActionCreator());
          //dispatch(resetSolarParametersConsumptionActionCreator());
        }
        dispatch(setViewsToFalseActionCreator());
      } else if (allDevices.nodes.length > 0) {
        navigate("/404");
      }
    };

    fetchNodeData(nodeId, viewState);
  }, [nodeId, viewState, allDevices]);

  useEffect(() => {
    setNode(nodeFromRedux);

    const parsedCreationDate = async () => {
      const creationDate = new Date(node.creation_date);
      let day: any = creationDate.getUTCDate();
      let month: any = creationDate.getUTCMonth() + 1;
      let year = creationDate.getUTCFullYear();

      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;

      let parsedDate = day + "/" + month + "/" + year;
      await setCreationDate(parsedDate);
    };

    const parsedUpdateDate = async () => {
      const updateDate = new Date(node.update_date);

      let day: any = updateDate.getUTCDate();
      let month: any = updateDate.getUTCMonth() + 1;
      let year = updateDate.getUTCFullYear();

      if (day < 10) day = "0" + day;
      if (month < 10) month = "0" + month;

      let parsedDate = day + "/" + month + "/" + year;
      await setUpdateDate(parsedDate);
    };

    parsedCreationDate();
    parsedUpdateDate();
  }, [devices, nodeFromRedux]);

  return (
    <div
      className="container-fluid"
      style={
        node.solar_id !== null
          ? {
              marginTop: "8px",
              paddingBottom: "20px",
              paddingRight: "0px",
              paddingLeft: "0px",
              zIndex: 2,
              background: "#FBFCFE"
            }
          : {
              marginTop: "8px",
              paddingBottom: "0px",
              paddingRight: "0px",
              paddingLeft: "0px",
              zIndex: 2,
              background: "#FBFCFE"
            }
      }
    >
      <div
        style={{
          marginBottom: "20px",
          cursor: "pointer",
          display: "inline-flex",
          alignItems: "center",
        }}
        onClick={() => {
          goBackToMap();
        }}
      >
        <IconStyled src={back} height={12} style={{ marginRight: "8px", marginTop: "1px" }} />
        <TextSpan color="black">{t("Back")}</TextSpan>
      </div>
      <ViewCamera selectedLamp={node} />

      {node.rgb === "Y" &&
        <div className="row" style={{ marginBottom: "24px", display: "flex", flexWrap: "wrap" }}>
          <div style={{ maxWidth: "33.3%" }}>
            <CardContainer>
              <RGBCard selectedLamp={node} />
            </CardContainer>
          </div>
          {node?.latitude && (
            <div style={{ flex: " 0 0 50%", maxWidth: "33.3%", position: "relative", height: "384px" }}>
              <CardContainer>
                <div style={{ position: "relative", width: "100%", height: "86.8%" }}>
                  <MiniMap
                    center={
                      new LatLng(
                        Number(node.latitude),
                        Number(node.longitude)
                      )
                    }
                    mapSize={mapHalfSize}
                    selectedLamp={node}
                  />
                </div>
                <div style={{ alignSelf: "center", position: "absolute", bottom: 0, zIndex: 2 }}>
                  <LocalWeather />
                </div>
              </CardContainer>
            </div>
          )}
          <div style={{ maxWidth: "33.3%", height: "30%" }}>
            <CardContainer>
              <AlarmsCard />
            </CardContainer>
          </div>
        </div>
      }

      {node.solar_id &&
        <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            {node.date &&
              <div style={{ display: "flex", alignItems: "center" }}>
                <IconStyled src={alarmTime} height={14} style={{ marginRight: "8px", marginTop: "1px", opacity: 0.5 }} />
                <div style={{ color: "#525252", opacity: 0.5 }}>{t("lastUpdate")} {parsedLastUpdateDate(node.date)}</div>
              </div>
            }
            <div className="row" style={{ marginBottom: "24px", display: "flex", flexWrap: "wrap" }}>
              <div className="col-6" style={{ maxWidth: "15%" }}>
                <CardContainer>
                  <Pannel selectedLamp={node} />
                </CardContainer>
              </div>
              <div className="col-6" style={{ maxWidth: "15%" }}>
                <CardContainer>
                  <Battery selectedLamp={node} />
                </CardContainer>
              </div>
              <div className="col-6" style={{ maxWidth: "15%" }}>
                <CardContainer>
                  <Luminaire selectedLamp={node} />
                </CardContainer>
              </div>
              <div className="col-7" style={{ maxWidth: "55%" }}>
                <CardContainer>
                  <Consumption selectedLamp={node} />
                </CardContainer>
              </div>
              {/*<div className="col-6" style={{ maxWidth: "25%" }}>
                <CardContainer>
                  <Savings />
                </CardContainer>
              </div>*/}
            </div>
          </div>
          <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="col-3" style={{ display: "flex", flexDirection: "column" }}>
              {node.manufacturer_name === "smartec" &&
                <div style={{ marginBottom: "24px" }}>
                  <CardContainer>
                    <BatteryConfig selectedLamp={node} />
                  </CardContainer>
                </div>
              }
              {/* Status ,Dimming, and Current Strategy cards */}
              <div style={{ marginBottom: "24px" }}>
                <CardContainer>
                  <StatusCard
                    selectedLamp={node}
                    onLamp={node.on}
                    onlineLamp={node.online}
                    />
                  <DimmingCard selectedLamp={node} dimmingValue={node.bri} />
                </CardContainer>
              </div> 
            </div>
            <div className="col-9" style={{ display: "flex", flexDirection: "column" }}>
              <div className="row" style={{ marginBottom: "24px", display: "flex", flexWrap: "wrap", height: "326px" }}>
                <div className="col-4">
                  <CardContainer>
                    <CurrentStrategy
                      selectedLamp={node}
                      selectedLampId={node.node_id}
                      selectedLampStrategyId={node.strategy_id}
                      viewState={viewState}
                    />
                  </CardContainer>
                </div>
                {/* <div className="col-4">
                  <CardContainer>
                    <TemporaryEventsCard />
                  </CardContainer>
                </div> */}
                <div className="col-4">
                  <CardContainer>
                    <AlarmsCard />
                  </CardContainer>
                </div>
              </div>
              <div className="row" style={{ marginBottom: "24px", display: "flex", flexWrap: "wrap" }}>
                {/* Map Location And Temporary Events Cards */}
                {node?.latitude && (
                  <div className="col-4" style={{ position: "relative", height: "300px" }}>
                    <CardContainer>
                      <div style={{ position: "relative", width: "100%", height: "86.8%" }}>
                        <MiniMap
                          center={
                            new LatLng(
                              Number(node.latitude),
                              Number(node.longitude)
                            )
                          }
                          mapSize={mapHalfSize}
                          selectedLamp={node}
                        />
                      </div>
                      <div style={{ alignSelf: "center", position: "absolute", bottom: 0, zIndex: 2 }}>
                        <LocalWeather />
                      </div>
                    </CardContainer>
                  </div>
                )}
                <div className="col-4">
                  <CardContainer>
                    <Lifetime selectedLamp={node} />
                  </CardContainer>
                </div>
                <div className="col-4">
                  <CardContainer>
                    <GroupsCard selectedLampId={node.node_id} />
                  </CardContainer>
                </div>
              </div>
            </div> 
          </div>
        </div>
      }

      {(!node.solar_id || node.solar_id === null) && (!node.rgb || node.rgb === "N") &&
        <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="col-3" style={{ display: "flex", flexDirection: "column" }}>
            {/* Status ,Dimming, and Current Strategy cards */}
            <div style={{ marginBottom: "24px" }}>
              <CardContainer>
                <StatusCard
                  selectedLamp={node}
                  onLamp={node.on}
                  onlineLamp={node.online}
                  />
                <DimmingCard selectedLamp={node} dimmingValue={node.bri} />
              </CardContainer>
            </div>
            <div style={{ marginBottom: "24px", height: "302px" }}>
              <CardContainer>
                <CurrentStrategy
                  selectedLamp={node}
                  selectedLampId={node.node_id}
                  selectedLampStrategyId={node.strategy_id}
                  viewState={viewState}
                />
              </CardContainer>
            </div>
          </div>
          <div className="col-9" style={{ display: "flex", flexDirection: "column" }}>
            <div className="row" style={{ marginBottom: "24px", display: "flex", flexWrap: "wrap" }}>
              <CardContainer>
                <TotalsCard selectedLamp={node} />
              </CardContainer>         
            </div>
            <div className="row" style={{ marginBottom: "24px", display: "flex", flexWrap: "wrap", height: "373px" }}>
              <CardContainer>
                <ElectricalParametersCard selectedLamp={node} />
              </CardContainer>
            </div>
          </div>
          <div className="col-12" style={{ display: "flex", marginBottom: "24px", gap: "24px" }}>       
            {/* Map Location And Temporary Events Cards */}
            {node?.latitude && (
              <div style={{ position: "relative", height: "340px", width: "24%" }}>
                <CardContainer>
                  <div style={{ position: "relative", width: "100%", height: "86.8%" }}>
                    <MiniMap
                      center={
                        new LatLng(
                          Number(node.latitude),
                          Number(node.longitude)
                        )
                      }
                      mapSize={mapHalfSize}
                      selectedLamp={node}
                    />
                  </div>
                  <div style={{ alignSelf: "center", position: "absolute", bottom: 0, zIndex: 2 }}>
                    <LocalWeather />
                  </div>
                </CardContainer>
              </div>
            )}
            {/* <div className="col-8">
              <CardContainer>
                <SavingsCard />
              </CardContainer>
            </div> */}
            {/* <div style={{ width: "24%"}}>
              <CardContainer>
                <TemporaryEventsCard />
              </CardContainer>
            </div> */}
            <div style={{ width: "24%"}}>
              <CardContainer>
                <AlarmsCard />
              </CardContainer>
            </div>
            <div style={{ width: "24%"}}>
              <CardContainer>
                <GroupsCard selectedLampId={node.node_id} />
              </CardContainer>
            </div>  
          </div>
        </div>
      }

      <div className="row" style={{ display: "flex", flexWrap: "wrap" }}>
        {/* Implementar los 8 widgets que faltan */}
        {userRole === "owner" ?
          <div
            className="col-12"
            style={{
              marginBottom: "24px",
              marginTop: "24px",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <div className="col-6" style={{ maxWidth: "12.6%" }}>
              <CardContainer style={{ height: "85%" }}>
                <CardSimple>
                  <Text color="#A3AED0" style={{ marginBottom: "8px"}}>{t("Manufacturer")}</Text>
                  <Text style={{ marginBottom: "16px" }}>{node.manufacturer_name}</Text>
                </CardSimple>
              </CardContainer>
            </div>
            <div className="col-6" style={{ maxWidth: "12.6%" }}>
              <CardContainer style={{ height: "85%" }}>
                <CardSimple>
                  <Text color="#A3AED0" style={{ marginBottom: "8px"}}>{t("Protocol")}</Text>
                  <Text style={{ marginBottom: "16px" }}>{node.protocol_name}</Text>
                </CardSimple>
              </CardContainer>
            </div>
            <div className="col-6" style={{ maxWidth: "12.6%" }}>
              <CardContainer style={{ height: "85%" }}>
                <CardSimple>
                  <Text color="#A3AED0" style={{ marginBottom: "8px"}}>{t("Case")}</Text>
                  <Text style={{ marginBottom: "16px" }}>{node.case_name}</Text>
                </CardSimple>
              </CardContainer>
            </div>
            <div className="col-6" style={{ maxWidth: "12.6%" }}>
              <CardContainer style={{ height: "85%" }}>
                <CardSimple>
                  <Text color="#A3AED0" style={{ marginBottom: "8px"}}>{t("Pole number")}</Text>
                  {node.pole ? <Text style={{ marginBottom: "16px" }}>{node.pole}</Text> : <Text style={{ marginBottom: "16px" }}>-</Text>}
                </CardSimple>
              </CardContainer>
            </div>
            <div className="col-6" style={{ maxWidth: "12.6%" }}>
              <CardContainer style={{ height: "85%" }}>
                <CardSimple>
                  <Text color="#A3AED0" style={{ marginBottom: "8px"}}>{t("Creation date")}</Text>
                  <Text style={{ marginBottom: "16px" }}>{creationDate}</Text>
                </CardSimple>
              </CardContainer>
            </div>
            <div className="col-6" style={{ maxWidth: "12.6%" }}>
              <CardContainer style={{ height: "85%" }}>
                <CardSimple>
                  <Text color="#A3AED0" style={{ marginBottom: "8px"}}>{t("Update date")}</Text>
                  <Text style={{ marginBottom: "16px" }}>{updateDate}</Text>
                </CardSimple>
              </CardContainer>
            </div>
            <div className="col-6" style={{ maxWidth: "12.6%" }}>
              <CardContainer style={{ height: "85%" }}>
                <CardSimple>
                  <Text color="#A3AED0" style={{ marginBottom: "8px"}}>{t("active")}</Text>
                  {node.active === "Y" ? (
                    <Text style={{ marginBottom: "16px" }}>{t("yes")}</Text>
                  ) : (
                    <Text style={{ marginBottom: "16px" }}>{t("no")}</Text>
                  )}
                </CardSimple>
              </CardContainer>
            </div>
          </div>
        :
          <></>
        }
      </div>
    </div>
  );
};
