import { Icon } from "leaflet";
import alarm from "../assets/new_icons/Alarm.svg";
import disconnected from "../assets/new_icons/Node disconnected.svg";
import OFF from "../assets/new_icons/OFF.svg";
import ON from "../assets/new_icons/ON.svg";
import selectedIcon from "../assets/new_icons/icon-selected.svg";
export type Status = "on" | "off" | "error" | "disconnected";

//cuando haya más iconos mover a otro archivo?
const generateIconDataUrl = (status: Status, color: string): string => {
  const svg = status === "on" ? `<svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_54881_28120)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.9999 6C16.7363 6 17.3333 5.40304 17.3333 4.66667V1.33333C17.3333 0.596953 16.7363 0 15.9999 0C15.2636 0 14.6666 0.596953 14.6666 1.33333V4.66667C14.6666 5.40304 15.2636 6 15.9999 6ZM18.5001 28.6667H13.5001C13.2689 28.6667 13.0543 28.7864 12.9328 28.9831C12.8114 29.1798 12.8005 29.4253 12.9041 29.632L13.3507 30.5253C13.7995 31.4304 14.7232 32.0021 15.7334 32H16.2667C17.2779 32.0031 18.2029 31.4312 18.6521 30.5253L19.0987 29.632C19.2023 29.4253 19.1914 29.1798 19.07 28.9831C18.9485 28.7864 18.7339 28.6667 18.5027 28.6667H18.5001ZM26.3707 5.0774C26.8913 4.55689 27.7353 4.55689 28.256 5.0774C28.7765 5.59807 28.7765 6.44207 28.256 6.96274L25.8987 9.31874C25.5639 9.66534 25.0682 9.80435 24.602 9.68233C24.1358 9.56031 23.7717 9.19625 23.6497 8.73008C23.5277 8.26391 23.6667 7.76817 24.0133 7.4334L26.3707 5.0774ZM32 17.3333C32 16.597 31.4031 16 30.6667 16H28C27.2637 16 26.6667 16.597 26.6667 17.3333C26.6667 18.0697 27.2637 18.6667 28 18.6667H30.6667C31.4031 18.6667 32 18.0697 32 17.3333ZM5.62937 5.07729L7.9867 7.43328C8.492 7.95646 8.48477 8.78806 7.97046 9.30238C7.45615 9.81669 6.62454 9.82391 6.10137 9.31862L3.74404 6.96262C3.23874 6.43945 3.24597 5.60784 3.76028 5.09353C4.27459 4.57922 5.1062 4.57199 5.62937 5.07729ZM4 16H1.33333C0.596954 16 0 16.597 0 17.3333C0 18.0697 0.596954 18.6667 1.33333 18.6667H4C4.73638 18.6667 5.33333 18.0697 5.33333 17.3333C5.33333 16.597 4.73638 16 4 16ZM7.28129 16.7173C7.28717 11.9048 11.1874 8.00516 16 8.00002C19.744 7.99632 23.0718 10.3852 24.2657 13.9338C25.4597 17.4824 24.2528 21.3971 21.268 23.6573C21.0908 23.7899 20.9908 24.0017 21.0013 24.2227L21.0253 24.6987V24.74C21.0122 25.8069 20.1443 26.6653 19.0773 26.6667H12.9226C12.4045 26.667 11.9074 26.4613 11.541 26.095C11.1746 25.7286 10.9689 25.2315 10.9693 24.7133V24.1213C10.9693 23.9115 10.8705 23.7139 10.7026 23.588C8.53944 21.968 7.27055 19.4199 7.28129 16.7173Z"
          fill="${color}"
        />
      </g>
      <defs>
        <clipPath id="clip0_54881_28120">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>`
  : status === "off" ? `<svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_54890_32101)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.28129 16.7174C7.28717 11.9048 11.1874 8.00519 15.9999 8.00005C19.744 7.99635 23.0718 10.3853 24.2657 13.9339C25.4597 17.4825 24.2528 21.3971 21.2679 23.6574C21.0908 23.7899 20.9908 24.0017 21.0013 24.2227L21.0253 24.6987V24.74C21.0122 25.8069 20.1443 26.6653 19.0773 26.6667H12.9226C12.4045 26.6671 11.9074 26.4614 11.541 26.095C11.1746 25.7286 10.9689 25.2315 10.9693 24.7134V24.1214C10.9693 23.9115 10.8705 23.7139 10.7026 23.588C8.53943 21.9681 7.27054 19.4199 7.28129 16.7174ZM13.5001 28.6667H18.5001H18.5027C18.7339 28.6667 18.9485 28.7864 19.07 28.9831C19.1914 29.1798 19.2023 29.4254 19.0987 29.632L18.6521 30.5254C18.2029 31.4313 17.2779 32.0031 16.2667 32H15.7334C14.7232 32.0021 13.7995 31.4304 13.3507 30.5254L12.9041 29.632C12.8005 29.4254 12.8114 29.1798 12.9328 28.9831C13.0543 28.7864 13.2689 28.6667 13.5001 28.6667Z"
            fill="${color}"
          />
        </g>
        <defs>
          <clipPath id="clip0_54890_32101">
            <rect width="32" height="32" fill="white"/>
          </clipPath>
        </defs>
      </svg>`
    : status === "error" ? `<svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_54890_32447)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.9776 0.00357528C11.7029 0.0735571 7.62744 1.82299 4.63223 4.87369C1.60412 7.88848 -0.0673599 12.0053 0.00208039 16.2777C-0.00288602 20.4507 1.65325 24.454 4.60483 27.4038C7.55642 30.3537 11.5607 32.0074 15.7336 32H16.0189C24.9223 31.9084 32.071 24.6266 31.9985 15.7231C32.0084 11.4985 30.3139 7.44841 27.2983 4.48961C24.2828 1.53081 20.2013 -0.0865598 15.9776 0.00357528ZM14.0005 22.0571C13.9811 21.529 14.1749 21.0154 14.5382 20.6317C14.9016 20.248 15.404 20.0266 15.9323 20.0173H15.9683C17.0615 20.0194 17.9579 20.8846 17.9987 21.9771C18.0185 22.5053 17.8249 23.0191 17.4614 23.4029C17.098 23.7866 16.5954 24.0079 16.0669 24.0169H16.0309C14.9383 24.0134 14.0427 23.149 14.0005 22.0571ZM14.6671 8.66926V16.6684C14.6671 17.4047 15.264 18.0015 16.0003 18.0015C16.7366 18.0015 17.3335 17.4047 17.3335 16.6684V8.66926C17.3335 7.93296 16.7366 7.33608 16.0003 7.33608C15.264 7.33608 14.6671 7.93296 14.6671 8.66926Z"
              fill="${color}"
            />
          </g>
          <defs>
            <clipPath id="clip0_54890_32447">
              <rect width="32" height="32" fill="white"/>
            </clipPath>
          </defs>
        </svg>`
      : status === "disconnected" ? `<svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.12752 4.31007C5.67896 3.86151 4.90848 3.90473 4.4066 4.4066C3.90473 4.90848 3.86151 5.67896 4.31007 6.12753L8.68663 10.5041C8.43942 11.3107 8.30592 12.1671 8.30484 13.0544C8.2941 15.757 9.56298 18.3051 11.7262 19.9251C11.894 20.051 11.9928 20.2486 11.9928 20.4584V21.0504C11.9925 21.5686 12.1982 22.0657 12.5646 22.4321C12.931 22.7984 13.428 23.0041 13.9462 23.0038H20.1008C20.416 23.0034 20.7139 22.9282 20.9774 22.7949L26.0744 27.8919C26.523 28.3404 27.2935 28.2972 27.7953 27.7953C28.2972 27.2935 28.3404 26.523 27.8919 26.0744L6.12752 4.31007ZM11.1459 6.62132L23.4576 18.933C25.5716 16.6257 26.313 13.3138 25.2893 10.2709C24.0953 6.72234 20.7676 4.33342 17.0235 4.33712C14.7586 4.33954 12.6958 5.20454 11.1459 6.62132ZM14.5236 25.0038H19.5236H19.5263C19.7574 25.0038 19.9721 25.1235 20.0935 25.3202C20.2149 25.5169 20.2258 25.7624 20.1223 25.9691L19.6756 26.8624C19.2264 27.7683 18.3014 28.3402 17.2903 28.3371H16.7569C15.7468 28.3392 14.823 27.7675 14.3743 26.8624L13.9276 25.9691C13.8241 25.7624 13.8349 25.5169 13.9564 25.3202C14.0778 25.1235 14.2925 25.0038 14.5236 25.0038Z"
              fill="${color}"
            />
          </svg>`
      :
          ``;

  return typeof window === "undefined"
    ? `data:image/svg+xml;base64,${Buffer.from(svg).toString("base64")}`
    : `data:image/svg+xml;base64,${window.btoa(svg)}`;
};
export const getIcon = (status: Status, selected: boolean, view?: string|any) => {
  const shadowUrl = view === "color" ? "" : selected ? selectedIcon : "";
  const shadowSize = [40, 40];
  const color = status === "on" ? "green" : status === "off" ? "purple" : status === "error" ? "red" : status === "disconnected" ? "grey" : ""; // Example color change based on status
  const iconUrl = generateIconDataUrl(status, color);

  // esto es una especie de switch, se itera y entra solo en la opción de status que se manda en la línea 43. por lo que aquí debería gestionar el relleno según el id del svg
  // Nota+: ahora mismo el bulb off es como el bulb on pero rellenadod e gris. Poner un icono que no tenga rallitas como si estuviese encendido
  const ICONS = {
    on: new Icon({
      iconUrl: ON,
      iconSize: [20, 20],
      shadowUrl,
      shadowSize: [shadowSize[0], shadowSize[1]],
      className: view === "color" ? selected ? "white-circle-icon-selected" : "white-circle-icon" : ""
    }),
    off: new Icon({
      iconUrl: OFF,
      iconSize: [20, 20],
      shadowUrl,
      shadowSize: [shadowSize[0], shadowSize[1]],
      className: view === "color" ? selected ? "white-circle-icon-selected" : "white-circle-icon" : ""
    }),
    error: new Icon({
      iconUrl: alarm,
      iconSize: [20, 20],
      shadowUrl,
      shadowSize: [shadowSize[0], shadowSize[1]],
      className: view === "color" ? selected ? "white-circle-icon-selected" : "white-circle-icon" : ""
    }),
    disconnected: new Icon({
      iconUrl: disconnected,
      shadowUrl,
      iconSize: [25, 25],
      shadowSize: [shadowSize[0], shadowSize[1]],
      className: view === "color" ? selected ? "white-circle-icon-selected" : "white-circle-icon" : ""
    }),
  };

  // aquí se indica el status del nodo
  return ICONS[status];
};



// CÓDIGO ORIGINAL
/* import { Icon } from "leaflet";
import alarm from "../assets/new_icons/Alarm.svg";
import disconnected from "../assets/new_icons/Node disconnected.svg";
import OFF from "../assets/new_icons/OFF.svg";
import ON from "../assets/new_icons/ON.svg";
import selectedIcon from "../assets/new_icons/icon-selected.svg";

export type Status = "on" | "off" | "error" | "disconnected";

export const getIcon = (status: Status, selected: boolean) => {
  const shadowUrl = selected ? selectedIcon : "";
  const shadowSize = [40, 40];

  // esto es una especie de switch, se itera y entra solo en la opción de status que se manda en la línea 43. por lo que aquí debería gestionar el relleno según el id del svg
  // Nota+: ahora mismo el bulb off es como el bulb on pero rellenadod e gris. Poner un icono que no tenga rallitas como si estuviese encendido
  const ICONS = {
    on: new Icon({
      iconUrl: ON,
      iconSize: [20, 20],
      shadowUrl,
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
    off: new Icon({
      iconUrl: OFF,
      iconSize: [20, 20],
      shadowUrl,
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
    error: new Icon({
      iconUrl: alarm,
      iconSize: [20, 20],
      shadowUrl,
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
    disconnected: new Icon({
      iconUrl: disconnected,
      shadowUrl,
      iconSize: [25, 25],
      shadowSize: [shadowSize[0], shadowSize[1]],
    }),
  };

  // aquí se indica el status del nodo
  return ICONS[status];
};
 */
