import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  registerables,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import 'chart.js/auto';
import { useAppDispatch } from "../../../redux/hooks";
import { saveImageDevicesActionCreator } from "../../../redux/slices/reportsSlice/pdfReportSlice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ...registerables
);

interface ValuesProps {
  values: any;
}

export const GeneralInfoChartPdf = ({ values }: ValuesProps) => {
  const chartRef:any = useRef(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
      },
    },
    cutout: '55%',
  }; 

  //revisar esto a futuro, los nombres de las variables
  const labels = [values?.pdfReport?.lumTypeStandard && t("standardLuminaires"), values?.pdfReport?.lumTypeSolar && t("solarLuminaires"),
    values?.pdfReport?.lumTypeHybrid && t("hybridLuminaires"), values?.pdfReport?.gateTypeSmartec && t("Gateways"),
    values?.pdfReport?.deviceSwitch && t("switchboards"), values?.pdfReport?.lumTypeRgb && t("rgbLuminaires")].filter(label => label);
  const colors = [values?.pdfReport?.lumTypeStandard && "#1B2559", values?.pdfReport?.lumTypeSolar && "#FFC107", values?.pdfReport?.lumTypeHybrid && "#45D0EE",
    values?.pdfReport?.gateTypeSmartec && "#17181A40", values?.pdfReport?.deviceSwitch && "#A3AED0", values?.pdfReport?.lumTypeRgb && "#F1F4F9"].filter(color => color);

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: ` ${t("total")}`,
        data: [values?.pdfReport?.selectedDevicesStandard?.length, values?.pdfReport?.selectedDevicesSolar?.length, values?.pdfReport?.selectedDevicesHybrid?.length,
          values?.pdfReport?.selectedDevicesSmartec?.length, values?.pdfReport?.selectedDevicesSwitch?.length, values?.pdfReport?.selectedDevicesRgb?.length].filter(data => data),
        backgroundColor: colors,
        weight: 0.5,
        borderWidth: labels.length === 1 ? 0 : 2,
      }
    ],
  };

  useEffect(() => {
    const chart:any = chartRef.current;
    if (chart) {
      setTimeout(() => {
        const chartImage = chart.toBase64Image();
        dispatch(saveImageDevicesActionCreator(chartImage));
      }, 2000);
    }
  }, []);

  return (
    <div style={{ width: "200px", height: "200px", position: "relative" }}>
      <Doughnut ref={chartRef} options={options} data={data} />
    </div>
  )
};
