import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Checkbox } from "antd";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { useUsersService } from "../../../../services/usersService";
import { IconStyled } from "../../../ProfilePage/ProfilePageStyled";
import { ButtonFilter, DropdownMenu, InputFormFiltering, SelectFormFilteringSmall, OrganizationCard, CompanyProfile, SelectFormFiltering, OptionSelect, ListItem } from "../../UsersPageStyled";
import { ButtonOutlined } from "../../../../styles/shared-styles/buttons-sizes";
import { Text, TextSpan } from "../../../../styles/shared-styles/text-sizes";
import companyFlag from "../../../../assets/new_icons/Company.svg";
import map from "../../../../assets/new_icons/Map.svg";
import statusIcon from "../../../../assets/new_icons/Switch OFF.svg";

interface OrganizationsProps {}

export const Organizations = ({}: OrganizationsProps) => {
  const [dataToUpdate, setDataToUpdate] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorExistingData, setErrorExistingData] = useState<boolean>(false);
  const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { getManagedUserOrgs, postManagedUserOrgs } = useUsersService();
  const userRole = useAppSelector((state) => state.user.role_name);
  const userInfo = useAppSelector((state: RootState) => state.managedUser);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const handleDataToUpdate = (item:any, e: any) => {
    let obj = {
      id: item.id,
      selected: e.target.checked
    }
    setDataToUpdate(prevData => [...prevData, obj]);
  }

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleUpdate = async (data:any) =>  {
    setIsLoading(true);
    let lastObjectById:any = {};
  
    data.forEach((item:any) => {
      lastObjectById[item.id] = item;
    });
  
    let filteredArray = Object.values(lastObjectById);

    let compareArrays = filteredArray.every((filteredObj:any) => {
      let obj = userInfo?.orgs?.find((originalObj:any) => originalObj.id === filteredObj.id);
      return obj && obj.selected === filteredObj.selected;
    });
    
    if (compareArrays) {
      setIsLoading(false);
      setErrorExistingData(true);
      return
    }

    try {
      const token:any = Cookies.get("token");
      const response = await postManagedUserOrgs(token, userInfo?.id, filteredArray);
      
      if (response) {
        setSuccess(true);
        await getManagedUserOrgs(token, userInfo?.id, setIsLoading);
      } else {
        setIsLoading(false);
        setErrorUpdate(true);
      }

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      setIsLoading(false);
      setErrorUpdate(true);
    }
  }

  useEffect(() => {
    setErrorUpdate(false);
    setErrorExistingData(false);
  }, [dataToUpdate]);

  useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = `
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${accentButtonsColor} !important;
        border-color: ${accentButtonsColor} !important;
        border-radius: 0px !important;
      }
      
      .ant-checkbox .ant-checkbox-inner {
        border: 2px solid ${accentButtonsColor} !important;
        border-radius: 0px !important;
      }
    `;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [accentButtonsColor]);

  return (
    <div style={{ display: "flex", flexDirection: "column", paddingRight: "30px", paddingLeft: "10px",  height: "68.6%", position: "relative" }}>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "8px" }}>
        <div>
          <Text color={mainTextColor} textType="subTitle" fontWeight="700" marginBottom="0px">
            {t("orgsPermits")}
          </Text>
        </div>
        <div>
          <ul style={{ display: "inline-flex" }}>
            {/* <ListItem>
              <IconStyled src={search} />
              <IconStyled src={filter} onClick={toggleMenu} />

              {isOpen && (
                <DropdownMenu>
                  <div
                    className="list-container"
                    style={{
                      display: "flex",
                      // justifyContent: "space-around",
                      marginBottom: "16px",
                    }}
                  >
                    <TextSpan textType="caption" color="#A3AED0">
                      Organization name
                    </TextSpan>
                    <InputFormFiltering className="form-control" />
                    <TextSpan textType="caption" color="#A3AED0">
                      Country
                    </TextSpan>
                    <SelectFormFiltering
                      id="inputState"
                      className="form-control"
                    >
                      <OptionSelect selected>Oman</OptionSelect>
                      <OptionSelect>...</OptionSelect>
                    </SelectFormFiltering>
                    <TextSpan textType="caption" color="#A3AED0">
                      City
                    </TextSpan>
                    <SelectFormFiltering className="form-control">
                      <OptionSelect selected>Muscat</OptionSelect>
                      <OptionSelect>...</OptionSelect>
                    </SelectFormFiltering>
                  </div>
                  <div
                    className="list-container"
                    style={{
                      display: "flex",
                      marginBottom: "16px",
                    }}
                  >
                    <TextSpan textType="caption" color="#A3AED0">
                      Organization type
                    </TextSpan>
                    <SelectFormFiltering className="form-control">
                      <OptionSelect selected>City Council</OptionSelect>
                      <OptionSelect>...</OptionSelect>
                    </SelectFormFiltering>

                    <TextSpan textType="caption" color="#A3AED0">
                      {t("active")}
                    </TextSpan>

                    <SelectFormFilteringSmall className="form-control">
                      <OptionSelect selected>Yes</OptionSelect>
                      <OptionSelect>...</OptionSelect>
                    </SelectFormFilteringSmall>

                    {/* <InputFormFilteringSmall className="form-control" />
                    <TextSpan textType="caption" color="#A3AED0">
                      Selected
                    </TextSpan>
                    <SelectFormFilteringSmall className="form-control">
                      <OptionSelect selected>{t("All")}</OptionSelect>
                      <OptionSelect>...</OptionSelect>
                    </SelectFormFilteringSmall>
                    <div style={{ textAlign: "center" }}>
                      <ButtonFilter>Filter</ButtonFilter>
                    </div>
                  </div>
                </DropdownMenu>
              )}
            </ListItem> */}
            <ListItem style={{ marginRight: "0px" }}>
              <TextSpan color={mainTextColor}>{userInfo?.orgs?.length} {t("Organizations").toLowerCase()}</TextSpan>
            </ListItem>
          </ul>
        </div>
      </div>
      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap", overflowY: "auto", paddingTop: "2px", paddingLeft: "3px", maxHeight: "350px"
        /* display: "grid", gridTemplateColumns: "repeat(auto-fill, 420px)",  gap: "20px", paddingTop: "2px", paddingLeft: "3px", overflowY: "auto", maxHeight: "350px" */ }}>
        {
          userInfo.orgs?.map((org:any) => (
            <OrganizationCard style={{ display: "flex", width: "420px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "6px", gap: "20px", alignItems: "center" }}>
              <CompanyProfile src={org.image ? `https://${org.image}` : companyFlag} />
              <div>
                <Text marginBottom="4px" fontWeight="700">{org.orgName}</Text>
                <div style={{ display: "flex", opacity: 0.7, gap: "14px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconStyled src={map} height={12} />
                    <Text marginBottom="0px" style={{ fontSize: "12px" }}>{org.city}, {org.country}</Text>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconStyled src={statusIcon} height={12} />
                    <Text marginBottom="0px" style={{ fontSize: "12px" }}>{org.active === "Y" ? t("yes") : t("no")}</Text>
                  </div>
                </div>
              </div>
              <Checkbox
                key={org.index}
                defaultChecked={org.selected}
                style={{ marginLeft: "auto" }}
                onChange={(e) => handleDataToUpdate(org, e)}
              />
            </OrganizationCard>
          ))
        }
      </div>
      <div style={{ textAlign: "right", marginBottom: "-2px", alignSelf: "flex-end", position: "absolute", bottom: "0" }}>  
        {isLoading ? (
          <ButtonOutlined type="button" disabled style={{ color: accentButtonsColor, border: `1px solid ${accentButtonsColor}` }}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              {t("loading...")}
          </ButtonOutlined>
        ) : (
          <ButtonOutlined style={{ color: accentButtonsColor, border: `1px solid ${accentButtonsColor}`, cursor: userRole === "admin" && userInfo.role === "admin" ? "not-allowed" : "pointer" }}
            onClick={() => {
              if (userRole === "admin" && userInfo.role === "admin") {
              } else {
                if (dataToUpdate.length === 0) {
                  setErrorUpdate(true)
                } else {
                  handleUpdate(dataToUpdate)
                }}
              }
            }
          >
            {t("Update")}
          </ButtonOutlined>
        )}
      </div>
      <div style={{ height: '10px', marginBottom: "-27px", textAlign: "right", alignSelf: "flex-end", position: "absolute", bottom: "0" }}>
        {errorExistingData ?
          <Text marginBottom="0px" color="red">{t("errorExistingData")}</Text>
        :
          errorUpdate ?
            <Text marginBottom="0px" color="red">{t("errorUpdating")}</Text>
          : 
            success ?
              <Text marginBottom="0px" color="green">{t("updated")}</Text>
            :
              <></>
        }
      </div>
    </div>
  );
};
