import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { turnBackgroundStrategiesFalseActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { Modal } from "react-bootstrap";
import { Details } from "../StrategiesSteps/Step1Details/Details";
import { Dimming } from "../StrategiesSteps/Step2Dimming/Dimming";
import { Params } from "../StrategiesSteps/Step2SolarParams/Params";
import { Review } from "../StrategiesSteps/Step3Review/Review";
import { LayoutGreyShadow } from "./NewStrategyModalStyled";
import styled from "styled-components";
import { Text } from "../../../styles/shared-styles/text-sizes";
import back from "../../../assets/new_icons/back.svg";
import smartecBackground from "../../../assets/new_icons/trama_forms_opacity.png";
import { ReactComponent as CheckIcon } from "../../../assets/new_icons/icon-selected.svg";
import { ReactComponent as CheckSelectedIcon } from "../../../assets/new_icons/check-circle.svg";

interface NewStrategyModalProps {
  showModal: boolean;
  setShowModal: any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1400px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 880px;
    width: 90%;
    background: white;
    border: 0px;
  }
`;

export const NewStrategyModal = ({ showModal, setShowModal }: NewStrategyModalProps) => {
  const dispatch = useAppDispatch();
  const [showPreventPopup, setShowPreventPopup] = useState(false)
  const [showDetails, setShowDetails] = useState(true);
  const [showParams, setShowParams] = useState(false);
  const [showDimming, setShowDimming] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [newChartDataStandard, setNewChartDataStandard] = useState();
  const [newChartDataSolar, setNewChartDataSolar] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorDuplicate, setErrorDuplicate] = useState(false);

  const strategies = useAppSelector((state) => state.strategies.tempStrategies);
  const name = useAppSelector((state) => state.strategies.name);
  const selectedColorOption = useAppSelector((state) => state.strategies.color?.value);
  const selectedTypeOption = useAppSelector((state) => state.strategies.type?.value);
  const ledsNumber = useAppSelector((state) => state.strategies.ledsNumber);
  const ledVolt = useAppSelector((state) => state.strategies.ledVolt);
  const lumPower = useAppSelector((state) => state.strategies.lumPower);
  const photoVolt = useAppSelector((state) => state.strategies.photoVolt);
  const smartManagement = useAppSelector((state) => state.strategies.smartManagement);
  const totalConsum = useAppSelector((state) => state.strategies.totalConsum);
  const maxConsum = useAppSelector((state) => state.strategies.maxConsum);
  const data = useAppSelector((state) => state.strategies.data);
  const background = useAppSelector((state) => state.graphics.backgroundStrategies);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const handlePreventPopup = () => {
    setShowPreventPopup(true);
  };

  const goToDetails = async () => {
    setShowDetails(true);
    setShowParams(false);
    setShowDimming(false);
    setShowReview(false)
  };

  const goToParams = async () => {
    if (name === "" || selectedColorOption === undefined || selectedTypeOption === undefined) {
      setErrorMessage(true);
      setErrorDuplicate(false);
      setShowDetails(true);
      setShowParams(false);
      setShowDimming(false);
      setShowReview(false);
    } else if (strategies.some((str: any) => str.strgy_name === name)) {
      setErrorMessage(false);
      setErrorDuplicate(true);
      setShowDetails(true);
      setShowParams(false);
      setShowDimming(false);
      setShowReview(false);
    } else {
      setErrorMessage(false);
      setErrorDuplicate(false);
      setShowDetails(false);
      setShowParams(true);
      setShowDimming(false);
      setShowReview(false);
    }
  };

  const goToDimming = async () => {
    if (name === "" || selectedColorOption === undefined || selectedTypeOption === undefined) {
      setErrorMessage(true);
      setErrorDuplicate(false);
      setShowDetails(true);
      setShowParams(false);
      setShowDimming(false);
      setShowReview(false);
    } else if (strategies.some((str: any) => str.strgy_name === name)) {
      setErrorMessage(false);
      setErrorDuplicate(true);
      setShowDetails(true);
      setShowParams(false);
      setShowDimming(false);
      setShowReview(false);
    } else if (selectedTypeOption === "solar" && (ledsNumber === "" || ledVolt === "" || lumPower === "" || photoVolt === "")) {
      setErrorMessage(true);
      setErrorDuplicate(false);
      setShowDetails(false);
      setShowParams(true);
      setShowDimming(false);
      setShowReview(false);
    } else {
      setErrorMessage(false);
      setErrorDuplicate(false);
      setShowDetails(false);
      setShowParams(false);
      setShowDimming(true);
      setShowReview(false);
    }
  };

  const goToReview = async () => {
    if (name === "" || selectedColorOption === undefined || selectedTypeOption === undefined) {
      setErrorMessage(true);
      setErrorDuplicate(false);
      setShowDetails(true);
      setShowParams(false);
      setShowDimming(false);
      setShowReview(false);
    } else if (strategies.some((str: any) => str.strgy_name === name)) {
      setErrorMessage(false);
      setErrorDuplicate(true);
      setShowDetails(true);
      setShowParams(false);
      setShowDimming(false);
      setShowReview(false);
    } else if (selectedTypeOption === "solar" && (ledsNumber === "" || ledVolt === "" || lumPower === "" || photoVolt === "")) {
      setErrorMessage(true);
      setErrorDuplicate(false);
      setShowDetails(false);
      setShowParams(true);
      setShowDimming(false);
      setShowReview(false);
    } else if (selectedTypeOption === "solar" && (smartManagement === "" || maxConsum === "" || totalConsum === "" || data[0].data.every((value:any) => value === null))) {
      setErrorMessage(true);
      setErrorDuplicate(false);
      setShowDetails(false);
      setShowParams(false);
      setShowDimming(true);
      setShowReview(false);
    } else {
      setErrorMessage(false);
      setErrorDuplicate(false);
      setShowDetails(false);
      setShowParams(false);
      setShowDimming(false);
      setShowReview(true);
    }
  };
  
  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      {showPreventPopup ?
        <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)",
          borderRadius: "14px", padding: "30px", display: "flex", flexDirection: "column", position: "absolute",  alignItems: "center",
          justifyContent: "center",  top: "362px", left: "407px", zIndex: 30
        }}>
          <Text>{t("Do you want to close this form? All the information will be lost")}</Text>
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {dispatch(turnBackgroundStrategiesFalseActionCreator()); setShowModal(!showModal)}}>{t("Accept")}</button>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowPreventPopup(false)}}>{t("Cancel")}</button>
          </div>
        </div>
      :
        <></>
      }
      <Modal.Body style={{ pointerEvents: showPreventPopup ? "none" : "auto", background: "white", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px",
        paddingRight: "0px", borderTopRightRadius: "0.5rem", borderTopLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem", borderBottomLeftRadius: "0.5rem", display: "flex",
        justifyContent: "space-between"}}
      >
        <div style={{ borderRight: "1px solid rgba(71, 85, 105, 0.1)", background: "rgba(236, 236, 236, 0.92)", borderTopLeftRadius: "0.5rem", borderBottomLeftRadius: "0.5rem" }} >
          <div style={{ width: "280px", height: "100%", background: `url(${smartecBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", paddingTop: "54px", paddingLeft: "40px" }}>
              <Text fontWeight="700" marginBottom="5px" color={mainTextColor} style={{fontSize: "22px" }}>{t("New strategy")}</Text>
              <div style={{display: "flex", flexDirection: "column", gap: "15px", marginTop: "60px"}}>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {!background && goToDetails()}}>
                  {showDimming || showReview || (selectedTypeOption === "solar" && showParams) ?
                    <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  :
                    <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  }
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("generalDetails")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("generalDetailsSideMenuStrategies")}</Text>
                  </div>
                </div>
                {selectedTypeOption === "solar" &&
                  <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {!background && goToParams()}}>
                    {showDimming || showReview ?
                      <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    :
                      <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    }
                    <div style={{marginTop: "-3px"}}>
                      <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("params")}</Text>
                      <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("paramsConfigSideMenu")}</Text>
                    </div>
                  </div>
                }
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {!background && goToDimming()}}>
                  {showReview ?
                    <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  :
                    <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  }
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("Dimming")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("dimmingConfigurationSideMenu")}</Text>
                  </div>
                </div>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {!background && goToReview()}}>
                  <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("Review")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("Verify all the information")}</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", width: "100%", padding: "8px 10px 0px 0px", flexDirection: "column", background: "#ffffff", borderTopRightRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem" }}
        >
          {background ? (
            <LayoutGreyShadow />
          ) : (
            <></>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {showParams ?
              <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }} 
                onClick={() => {setShowDetails(true); setShowParams(false); setShowDimming(false); setShowReview(false)}}
              >
                <img src={back} height={12} alt="" style={{ opacity: 0.5, marginRight: "8px" }} />
                <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralDetails")}</Text>
              </div>
            : showDimming ?
                selectedTypeOption === "solar" ?
                  <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }} 
                    onClick={() => {setShowDetails(false); setShowParams(true); setShowDimming(false); setShowReview(false)}}
                  >
                    <img src={back} height={12} alt="" style={{ opacity: 0.5, marginRight: "8px" }} />
                    <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToParams")}</Text>
                  </div>
                :
                  <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }} 
                    onClick={() => {setShowDetails(true); setShowParams(false); setShowDimming(false); setShowReview(false)}}
                  >
                    <img src={back} height={12} alt="" style={{ opacity: 0.5, marginRight: "8px" }} />
                    <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralDetails")}</Text>
                  </div>
              : showReview ?
                  <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }}
                    onClick={() => {setShowDetails(false); setShowParams(false); setShowDimming(true); setShowReview(false)}}
                  >
                    <img src={back} height={12} alt="" style={{ opacity: 0.5, marginRight: "8px" }} />
                    <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToDimmingConfiguration")}</Text>
                  </div>
                :
                  <div></div>
            }
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                background: "white",
                zIndex: 2
              }}
            />
          </div>
          {showDetails && 
            <Details showDimming={showDimming} goToDimming={goToDimming} showParams={showParams} goToParams={goToParams} errorMessage={errorMessage} setErrorMessage={setErrorMessage}
              errorDuplicate={errorDuplicate} setErrorDuplicate={setErrorDuplicate}
            />
          }
          {showParams && 
            <Params setShowDetails={setShowDetails} setShowParams={setShowParams} showParams={showParams} showDimming={showDimming} setShowDimming={setShowDimming} goToDimming={goToDimming}
            errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          }
          {showDimming && 
            <Dimming showDimming={showDimming} showReview={showReview} setNewChartDataStandard={setNewChartDataStandard} newChartDataStandard={newChartDataStandard}
              setNewChartDataSolar={setNewChartDataSolar} newChartDataSolar={newChartDataSolar} goToReview={goToReview} errorMessage={errorMessage} setErrorMessage={setErrorMessage}
            />
          }
          {showReview && 
            <Review onHide={setShowModal} setShowDetails={setShowDetails} setShowDimming={setShowDimming} showDimming={showDimming} setShowReview={setShowReview} 
              setNewChartDataStandard={setNewChartDataStandard} newChartDataStandard={newChartDataStandard} setNewChartDataSolar={setNewChartDataSolar} newChartDataSolar={newChartDataSolar}
              errorMessage={errorMessage} setErrorMessage={setErrorMessage}
            />
          }
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
