import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SolarStrategies } from "../../../types/interfaces";

const initialState: SolarStrategies = {
  intPower: "",
  time1: "",
  dim1: "",
  time2: "",
  dim2: "",
  time3: "",
  dim3: "",
  time4: "",
  dim4: "",
  time5: "",
  dim5: "",
  maxCurrent: "",
  voltDayNight: ""
};

const solarStrategiesSlice = createSlice({
  name: "solarStrategies",
  initialState: initialState,
  reducers: {
    getSolarStrategies: (previousData, action: PayloadAction<SolarStrategies>) => ({
      ...previousData,
      intPower: action.payload.intPower,
      time1: action.payload.time1,
      dim1: action.payload.dim1,
      time2: action.payload.time2,
      dim2: action.payload.dim2,
      time3: action.payload.time3,
      dim3: action.payload.dim3,
      time4: action.payload.time4,
      dim4: action.payload.dim4,
      time5: action.payload.time5,
      dim5: action.payload.dim5,
      maxCurrent: action.payload.maxCurrent,
      voltDayNight: action.payload.voltDayNight
    }),

    /* getSolarStrategies: (previousData, action: PayloadAction<SolarStrategies>) => {
      console.log(previousData, action, action.payload)
      return {
        ...previousData,
        intPower: action.payload.intPower,
        time1: action.payload.time1,
        dim1: action.payload.dim1,
        time2: action.payload.time2,
        dim2: action.payload.dim2,
        time3: action.payload.time3,
        dim3: action.payload.dim3,
        time4: action.payload.time4,
        dim4: action.payload.dim4,
        time5: action.payload.time5,
        dim5: action.payload.dim5
      }
    }, */

    resetSolarStrategies: (previousData) => ({
      ...previousData,
      intPower: "",
      time1: "",
      dim1: "",
      time2: "",
      dim2: "",
      time3: "",
      dim3: "",
      time4: "",
      dim4: "",
      time5: "",
      dim5: "",
      maxCurrent: "",
      voltDayNight: ""
    }),
  },
});

export const solarStrategiesReducer = solarStrategiesSlice.reducer;

export const {
  getSolarStrategies: getSolarStrategiesActionCreator,
  resetSolarStrategies: resetSolarStrategiesActionCreator,
} = solarStrategiesSlice.actions;
