import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Strategies } from "../../../types/interfaces";

const initialState: Strategies = {
  strategies: "",
  tempStrategies: "",
  selectedStrategy: "",
  labels: "",
  data: "",
  name: "",
  color: "",
  type: "",
  description: "",
  ledsNumber: "",
  ledVolt: "",
  lumPower: "",
  photoVolt: "",
  smartManagement: "",
  totalConsum: "",
  maxConsum: "",
  astroDim: false,
  sunsetHour: "",
  sunriseHour: "",
  offsetSunset: "",
  offsetSunrise: "",
  saving: 0,
  index: ""
};

const strategiesSlice = createSlice({
  name: "strategies",
  initialState: initialState,
  reducers: {
    getStrategies: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      strategies: action.payload
    }),

    getTempStrategies: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      tempStrategies: action.payload
    }),

    saveSelectedStrategy: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      selectedStrategy: action.payload.selectedStrategy
    }),

    deleteSelectedStrategy: (previousData) => ({
      ...previousData,
      selectedStrategy: ""
    }),
    
    changeStrategyChart: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      labels: action.payload.labels,
      data: action.payload.data
    }),

    changeAstroDim: (previousData) => ({
      ...previousData,
      astroDim: !previousData.astroDim
    }),

    turnAstroDimTrue: (previousData) => ({
      ...previousData,
      astroDim: true
    }),

    saveSunsetHour: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      sunsetHour: action.payload.sunsetHour
    }),

    saveSunriseHour: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      sunriseHour: action.payload.sunriseHour
    }),

    saveOffsetValues: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      offsetSunset: action.payload.offsetSunset,
      offsetSunrise: action.payload.offsetSunrise
    }),

    saveStrategyInfo: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      name: action.payload.name,
      color: action.payload.color,
      type: action.payload.type,
      description: action.payload.description
    }),

    saveSolarParams: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      ledsNumber: action.payload.ledsNumber,
      ledVolt: action.payload.ledVolt,
      lumPower: action.payload.lumPower,
      photoVolt: action.payload.photoVolt
    }),

    saveSmartManagement: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      smartManagement: action.payload.smartManagement
    }),

    saveConsums: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      totalConsum: action.payload.totalConsum,
      maxConsum: action.payload.maxConsum
    }),

    saveEnergyInfo: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      saving: action.payload.saving
    }),

    saveIndex: (previousData, action: PayloadAction<Strategies>) => ({
      ...previousData,
      index: action.payload.index
    }),

    resetStrategy: (previousData) => ({
      ...previousData,
      tempStrategies: "",
      /* selectedStrategy: "", */
      labels: "",
      data: "",
      name: "",
      color: "",
      type: "",
      description: "",
      ledsNumber: "",
      ledVolt: "",
      lumPower: "",
      photoVolt: "",
      smartManagement: "",
      totalConsum: "",
      maxConsum: "",
      astroDim: false,
      sunsetHour: "",
      sunriseHour: "",
      offsetSunset: "",
      offsetSunrise: "",
      saving: 0,
      index: ""
    }),
  },
});

export const strategiesReducer = strategiesSlice.reducer;

export const {
  getStrategies: getStrategiesActionCreator,
  getTempStrategies: getTempStrategiesActionCreator,
  saveSelectedStrategy: saveSelectedStrategyActionCreator,
  deleteSelectedStrategy: deleteSelectedStrategyActionCreator,
  changeStrategyChart: changeStrategyChartActionCreator,
  changeAstroDim: changeAstroDimActionCreator,
  turnAstroDimTrue: turnAstroDimTrueActionCreator,
  saveSunsetHour: saveSunsetHourActionCreator,
  saveSunriseHour: saveSunriseHourActionCreator,
  saveOffsetValues: saveOffsetValuesActionCreator,
  saveSolarParams: saveSolarParamsActionCreator,
  saveSmartManagement: saveSmartManagementActioncreator,
  saveConsums: saveConsumsActionCreator,
  saveStrategyInfo: saveStrategyInfoActionCreator,
  saveEnergyInfo: saveEnergyInfoActionCreator,
  saveIndex: saveIndexActionCreator,
  resetStrategy: resetStrategyActionCreator
} = strategiesSlice.actions;
