import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { saveStartDateActionCreator, saveEndDateActionCreator, changeDeviceGateActionCreator, changeDeviceLumActionCreator, changeDeviceSwitchActionCreator, resetLumTypesActionCreator,
  changeLumTypeStandardActionCreator, resetSelectedStandardActionCreator, changeLumTypeSolarActionCreator, resetSelectedSolarActionCreator, changeLumTypeHybridActionCreator,
  resetSelectedHybridActionCreator, changeLumTypeRgbActionCreator, resetSelectedRgbActionCreator, changeGateTypeSmartecActionCreator, resetSelectedSmartecActionCreator,
  changeGateTypeShuncomActionCreator, resetSelectedShuncomActionCreator, resetSelectedSwitchActionCreator } from "../../../../redux/slices/reportsSlice/pdfReportSlice";
import { Devices } from "../Step2Devices/Devices";
import { CardContainer, NextButton } from "../ReportsStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface DetailsProps {
  showDevices?: any;
  goToDevices?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
};

export const Details = ({ showDevices, goToDevices, errorMessage, setErrorMessage }: DetailsProps) => {
  const [start, setStart]:any = useState();
  const [end, setEnd]:any = useState();

  const dispatch = useAppDispatch();
  const startDate = useAppSelector((state) => state.pdfReport.startDate);
  const endDate = useAppSelector((state) => state.pdfReport.endDate);
  const deviceLum = useAppSelector((state) => state.pdfReport.deviceLum);
  const deviceGate = useAppSelector((state) => state.pdfReport.deviceGate);
  const deviceSwitch = useAppSelector((state) => state.pdfReport.deviceSwitch);
  const lumTypeStandard = useAppSelector((state) => state.pdfReport.lumTypeStandard);
  const lumTypeSolar = useAppSelector((state) => state.pdfReport.lumTypeSolar);
  const lumTypeHybrid = useAppSelector((state) => state.pdfReport.lumTypeHybrid);
  const lumTypeRgb = useAppSelector((state) => state.pdfReport.lumTypeRgb);
  const gateTypeSmartec = useAppSelector((state) => state.pdfReport.gateTypeSmartec);
  const gateTypeShuncom = useAppSelector((state) => state.pdfReport.gateTypeShuncom);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  useEffect(() => {
    if (startDate && endDate && ((deviceLum && (lumTypeStandard || lumTypeSolar || lumTypeHybrid || lumTypeRgb)) || (deviceGate && (gateTypeSmartec || gateTypeShuncom)) || deviceSwitch)) {
      setErrorMessage(false)
    };
  }, [startDate, endDate, deviceLum, lumTypeStandard, lumTypeSolar, lumTypeHybrid, lumTypeRgb, deviceGate, gateTypeSmartec, gateTypeShuncom, deviceSwitch]);

  useEffect(() => {
    if (start) {
      let startYear = start.getFullYear();
      let startMonth:any = start.getMonth() + 1;
      let startDay:any = start.getDate();
  
      if (startMonth < 10) startMonth = '0' + startMonth;
      if (startDay < 10) startDay = '0' + startDay;

      let formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
  
      dispatch(saveStartDateActionCreator(formattedStartDate));
    }

    if (end) {
      let endYear = end.getFullYear();
      let endMonth:any = end.getMonth() + 1;
      let endDay:any = end.getDate();
  
      if (endMonth < 10) endMonth = '0' + endMonth;
      if (endDay < 10) endDay = '0' + endDay;
      let formattedEndDate = `${endYear}-${endMonth}-${endDay}`;
  
      dispatch(saveEndDateActionCreator(formattedEndDate));
    }
  }, [start, end]);

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column", paddingLeft: "40px" }}>
      <div style={{ marginBottom: "100px" }}>
        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step1")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("generalDetails")}</Text>
        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("generalDetailsSubtitleReports")}</Text>
        <div style={{ display: "flex", marginTop: "40px", gap: "20px" }}>
          <div style={{ display: "flex", flexDirection: "column", alignSelf: "center", justifySelf: "center", width: "300px", marginBottom: "32px" }}>
            <Text textType="caption" color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("from")}</Text>
            <DatePicker
              className="datePickerPdfReport"
              placeholderText={t("startDate")}
              selectsRange={false}
              selected={start}
              onChange={(date: any) => setStart(date)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignSelf: "center", justifySelf: "center", width: "300px", marginBottom: "32px" }}>
            <Text textType="caption" color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("to")}</Text>
            <DatePicker
              className="datePickerPdfReport"
              placeholderText={t("endDate")}
              selectsRange={false}
              selected={end}
              onChange={(date: any) => setEnd(date)}
            />
          </div>
        </div>
        <div style={{ marginTop: "0px", marginBottom: "40px" }}>
          <Text color={mainTextColor} fontWeight="500" style={{ marginBottom: "16px", fontSize: "14px" }}>{t("selectDevice")}</Text>    
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <div className={`card px-3 py-2 `} onClick={() => {dispatch(changeDeviceLumActionCreator()); dispatch(resetLumTypesActionCreator())}}
              style={{ border: deviceLum ? `2px solid ${accentButtonsColor}` : 'none', borderRadius: "10px", cursor: "pointer", boxShadow: "0 3px 6px #d6d5d1" }}
            >
              <Text fontWeight="600" style={{ fontSize: "14px" }}>{t("Luminaires")}</Text>
            </div>
            {/* <div className={`card px-3 py-2 `} onClick={() => {dispatch(changeDeviceGateActionCreator()); dispatch(resetGateTypesActionCreator())}}
              style={{ border: deviceGate ? `2px solid ${accentButtonsColor}` : 'none', borderRadius: "10px", cursor: "pointer", boxShadow: "0 3px 6px #d6d5d1" }}
            >
              <Text fontWeight="600" style={{ fontSize: "14px" }}>{t("Gateways")}</Text>
            </div>
            <div className={`card px-3 py-2 `} onClick={() => {dispatch(changeDeviceSwitchActionCreator()); dispatch(resetSelectedSwitchActionCreator())}}
              style={{ border: deviceSwitch ? `2px solid ${accentButtonsColor}` : 'none', borderRadius: "10px", cursor: "pointer", boxShadow: "0 3px 6px #d6d5d1" }}
            >
              <Text fontWeight="600" style={{ fontSize: "14px" }}>{t("switchboards")}</Text>
            </div> */}
          </div>
        </div>
        {deviceLum &&
          <div style={{ marginTop: "0px", marginBottom: "40px" }}>
            <Text color={mainTextColor} fontWeight="500" style={{ marginBottom: "16px", fontSize: "14px" }}>{t("selectLumType")}</Text>    
            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <div className={`card px-3 py-2 `} onClick={() => {dispatch(changeLumTypeStandardActionCreator()); dispatch(resetSelectedStandardActionCreator())}}
                style={{ border: lumTypeStandard ? `2px solid ${accentButtonsColor}` : 'none', borderRadius: "10px", cursor: "pointer", boxShadow: "0 3px 6px #d6d5d1" }}
              >
                <Text fontWeight="600" style={{ fontSize: "14px" }}>{t("Standard")}</Text>
              </div>
              {/* <div className={`card px-3 py-2 `} onClick={() => {dispatch(changeLumTypeSolarActionCreator()); dispatch(resetSelectedSolarActionCreator())}}
                style={{ border: lumTypeSolar ? `2px solid ${accentButtonsColor}` : 'none', borderRadius: "10px", cursor: "pointer", boxShadow: "0 3px 6px #d6d5d1" }}
              >
                <Text fontWeight="600" style={{ fontSize: "14px" }}>{t("Solar")}</Text>
              </div> */}
              {/* <div className={`card px-3 py-2 `} onClick={() => {dispatch(changeLumTypeHybridActionCreator()); dispatch(resetSelectedHybridActionCreator())}}
                style={{ border: lumTypeHybrid ? `2px solid ${accentButtonsColor}` : 'none', borderRadius: "10px", cursor: "pointer", boxShadow: "0 3px 6px #d6d5d1" }}
              >
                <Text fontWeight="600" style={{ fontSize: "14px" }}>{t("hybrid")}</Text>
              </div> */}
              {/* <div className={`card px-3 py-2 `} onClick={() => {dispatch(changeLumTypeRgbActionCreator()); dispatch(resetSelectedRgbActionCreator())}}
                style={{ border: lumTypeRgb ? `2px solid ${accentButtonsColor}` : 'none', borderRadius: "10px", cursor: "pointer", boxShadow: "0 3px 6px #d6d5d1" }}
              >
                <Text fontWeight="600" style={{ fontSize: "14px" }}>{t("RGB")}</Text>
              </div> */}
            </div>
          </div>
        }
        {deviceGate &&
          <div style={{ marginTop: "0px", marginBottom: "40px" }}>
            <Text color={mainTextColor} fontWeight="500" style={{ marginBottom: "16px", fontSize: "14px" }}>{t("gateType")}</Text>    
            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <div className={`card px-3 py-2 `} onClick={() => {dispatch(changeGateTypeSmartecActionCreator()); dispatch(resetSelectedSmartecActionCreator())}}
                style={{ border: gateTypeSmartec? `2px solid ${accentButtonsColor}` : 'none', borderRadius: "10px", cursor: "pointer", boxShadow: "0 3px 6px #d6d5d1" }}
              >
                <Text fontWeight="600" style={{ fontSize: "14px" }}>{t("smartec")}</Text>
              </div>
              <div className={`card px-3 py-2 `} onClick={() => {dispatch(changeGateTypeShuncomActionCreator()); dispatch(resetSelectedShuncomActionCreator())}}
                style={{ border: gateTypeShuncom ? `2px solid ${accentButtonsColor}` : 'none', borderRadius: "10px", cursor: "pointer", boxShadow: "0 3px 6px #d6d5d1" }}
              >
                <Text fontWeight="600" style={{ fontSize: "14px" }}>{t("shuncom")}</Text>
              </div>
            </div>
          </div>
        }
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "absolute", bottom: 30, left: "49%" }}>
        {errorMessage ?
          <Text marginBottom="0px" style={{display: "flex", justifyContent: "center", color: "red", marginTop: "92px"}}>{t("errorBlankInput")}</Text>  
        :
          <></>
        }
        <NextButton style={{ background: errorMessage ? accentButtonsColor+95 : accentButtonsColor, borderColor: errorMessage ? `1px solid ${accentButtonsColor+95}` : accentButtonsColor,
          justifySelf: "end", alignSelf: "center", width: "300px", marginTop: errorMessage ? "10px" : "92px", cursor: errorMessage ? "not-allowed" : "pointer" }}
          onClick={() => {
            if (!errorMessage) {
              goToDevices()
            }
          }}
        >
          {t("continue")}
        </NextButton>
        {showDevices && <Devices />}
        <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
          <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
          <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
          <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        </div>
      </div>
    </CardContainer>
  );
};
