import { calculateParameters } from '../components/LuminairePage/ElectricalParametersCard/ElectricalParametersLogic';

export const useConsumptionsService = () => {

  //to get dali parameters
  const getDaliTwoDates = async (nodeId: number, start: any, end: any, parameterType: string, csv?: string) => {
    let startUnixTime = start.getTime() / 1000 + 43200;

    let startYear = start.getFullYear();
    let startMonth:any = start.getMonth() + 1;
    let startDay:any = start.getDate();
    let endYear = end.getFullYear();
    let endMonth:any = end.getMonth() + 1;
    let endDay:any = end.getDate();

    if (startMonth < 10) startMonth = '0' + startMonth;
    if (startDay < 10) startDay = '0' + startDay;
    if (endMonth < 10) endMonth = '0' + endMonth;
    if (endDay < 10) endDay = '0' + endDay;
    let formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
    let formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

    function countNumDays(start:any, end:any) {
      let startToSum = start.getTime();
      let endToSum = end.getTime();
      let difference = Math.abs(startToSum - endToSum);

      return Math.round(difference/(1000*60*60*24)) + 1;
    }

    let numDays = countNumDays(start, end);

    const body = {
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    try {
      let response = await fetch(`${process.env.REACT_APP_API_DALI_TWO as string}${nodeId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
      });
      const lightInfo = await response.json();
      if (lightInfo.length > 0) {   
        await calculateParameters(lightInfo, parameterType, numDays, startUnixTime, csv);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  //to get solar parameters
  const getSolarTwoDates = async (nodeId: number, start: any, end: any, parameterType: string, csv?: string) => {
    let startUnixTime = start.getTime() / 1000 + 43200;

    let startYear = start.getFullYear();
    let startMonth:any = start.getMonth() + 1;
    let startDay:any = start.getDate();
    let endYear = end.getFullYear();
    let endMonth:any = end.getMonth() + 1;
    let endDay:any = end.getDate();

    if (startMonth < 10) startMonth = '0' + startMonth;
    if (startDay < 10) startDay = '0' + startDay;
    if (endMonth < 10) endMonth = '0' + endMonth;
    if (endDay < 10) endDay = '0' + endDay;
    let formattedStartDate = `${startYear}-${startMonth}-${startDay-1}`;
    let formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

    function countNumDays(start:any, end:any) {
      let startToSum = start.getTime();
      let endToSum = end.getTime();
      let difference = Math.abs(startToSum - endToSum);

      return Math.round(difference/(1000*60*60*24)) + 1;
    }

    let numDays = countNumDays(start, end);

    const body = {
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    try {
      let response = await fetch(`${process.env.REACT_APP_API_SOLAR_TWO as string}${nodeId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
      });
      const solarInfo = await response.json();
      if (solarInfo.length > 0) {
        await calculateParameters(solarInfo, parameterType, numDays, startUnixTime, csv);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  //to get allegro parameters
  const getAllegroTwoDates = async (nodeId: number, start: any, end: any, parameterType: string, csv?: string) => {
    let startUnixTime = start.getTime() / 1000 + 43200;

    let startYear = start.getFullYear();
    let startMonth:any = start.getMonth() + 1;
    let startDay:any = start.getDate();
    let endYear = end.getFullYear();
    let endMonth:any = end.getMonth() + 1;
    let endDay:any = end.getDate();

    if (startMonth < 10) startMonth = '0' + startMonth;
    if (startDay < 10) startDay = '0' + startDay;
    if (endMonth < 10) endMonth = '0' + endMonth;
    if (endDay < 10) endDay = '0' + endDay;
    let formattedStartDate = `${startYear}-${startMonth}-${startDay-1}`;
    let formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

    function countNumDays(start:any, end:any) {
      let startToSum = start.getTime();
      let endToSum = end.getTime();
      let difference = Math.abs(startToSum - endToSum);

      return Math.round(difference/(1000*60*60*24)) + 1;
    }

    let numDays = countNumDays(start, end);

    const body = {
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    try {
      let response = await fetch(`${process.env.REACT_APP_API_ALLEGRO_TWO as string}${nodeId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
      });
      const allegroInfo = await response.json();
      if (allegroInfo.length > 0) {
        await calculateParameters(allegroInfo, parameterType, numDays, startUnixTime, csv);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  //to get dali-allegro parameters
  const getDaliAllegroTwoDates = async (nodeId: number, start: any, end: any, parameterType: string, csv?: string) => {
    let startUnixTime = start.getTime() / 1000 + 43200;

    let startYear = start.getFullYear();
    let startMonth:any = start.getMonth() + 1;
    let startDay:any = start.getDate();
    let endYear = end.getFullYear();
    let endMonth:any = end.getMonth() + 1;
    let endDay:any = end.getDate();

    if (startMonth < 10) startMonth = '0' + startMonth;
    if (startDay < 10) startDay = '0' + startDay;
    if (endMonth < 10) endMonth = '0' + endMonth;
    if (endDay < 10) endDay = '0' + endDay;
    let formattedStartDate = `${startYear}-${startMonth}-${startDay-1}`;
    let formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

    function countNumDays(start:any, end:any) {
      let startToSum = start.getTime();
      let endToSum = end.getTime();
      let difference = Math.abs(startToSum - endToSum);

      return Math.round(difference/(1000*60*60*24)) + 1;
    }

    let numDays = countNumDays(start, end);

    const body = {
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    try {
      let response = await fetch(`${process.env.REACT_APP_API_DALI_ALLEGRO_TWO as string}${nodeId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
      });
      const daliAllegroInfo = await response.json();
      if (daliAllegroInfo.length > 0) {
        await calculateParameters(daliAllegroInfo, parameterType, numDays, startUnixTime, csv);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  //to get shuncom parameters
  const getShuncomTwoDates = async (nodeId: number, start: any, end: any, parameterType: string, csv?: string) => {
    let startUnixTime = start.getTime() / 1000 + 43200;

    let startYear = start.getFullYear();
    let startMonth:any = start.getMonth() + 1;
    let startDay:any = start.getDate();
    let endYear = end.getFullYear();
    let endMonth:any = end.getMonth() + 1;
    let endDay:any = end.getDate();

    if (startMonth < 10) startMonth = '0' + startMonth;
    if (startDay < 10) startDay = '0' + startDay;
    if (endMonth < 10) endMonth = '0' + endMonth;
    if (endDay < 10) endDay = '0' + endDay;
    let formattedStartDate = `${startYear}-${startMonth}-${startDay-1}`;
    let formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

    function countNumDays(start:any, end:any) {
      let startToSum = start.getTime();
      let endToSum = end.getTime();
      let difference = Math.abs(startToSum - endToSum);

      return Math.round(difference/(1000*60*60*24)) + 1;
    }

    let numDays = countNumDays(start, end);

    const body = {
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    try {
      let response = await fetch(`${process.env.REACT_APP_API_SHUNCOM_TWO as string}${nodeId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
      });
      const daliAllegroInfo = await response.json();
      if (daliAllegroInfo.length > 0) {
        await calculateParameters(daliAllegroInfo, parameterType, numDays, startUnixTime, csv);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };
  
  const getStandardGroup = async (nodes: Array<string>, start: any, end: any) => {
    let startYear = start.getFullYear();
    let startMonth:any = start.getMonth() + 1;
    let startDay:any = start.getDate();
    let endYear = end.getFullYear();
    let endMonth:any = end.getMonth() + 1;
    let endDay:any = end.getDate();

    if (startMonth < 10) startMonth = '0' + startMonth;
    if (startDay < 10) startDay = '0' + startDay;
    if (endMonth < 10) endMonth = '0' + endMonth;
    if (endDay < 10) endDay = '0' + endDay;
    let formattedStartDate = `${startYear}-${startMonth}-${startDay}`;
    let formattedEndDate = `${endYear}-${endMonth}-${endDay}`;

    const body = {
      nodes: nodes,
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }

    try {
      let response = await fetch(`${process.env.REACT_APP_API_STANDARD_G as string}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
            "Content-type": "application/json; charset=UTF-8"
        },
      });

      const standardGroupInfo = await response.json();

      console.log(standardGroupInfo)

      if (standardGroupInfo) {
        return standardGroupInfo;
      } else {
        return false;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  return {
    getDaliTwoDates,
    getSolarTwoDates,
    getAllegroTwoDates,
    getDaliAllegroTwoDates,
    getShuncomTwoDates,
    getStandardGroup
  };
};

