import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";
import { CardSimple } from "../../LuminairePageStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import timestamp from "../../../../assets/new_icons/Timestamp.svg";

interface LifetimeProps {
  selectedLamp: any
}

export const Lifetime = ({ selectedLamp }: LifetimeProps) => {
  const [connectionDate, setConnectionDate] = useState("");

  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const { t } = useTranslation();

  return (
    <CardSimple style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "20px", }}>
      <div style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
        <Text fontWeight="700" style={{fontSize: "18px" }}>{t("lifetime")}</Text>
        <Text fontWeight="400" style={{ fontSize: "12px" }}>{t("firstConnection")}: {connectionDate}</Text>
      </div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
        <Text marginBottom="0px" fontWeight="700" style={{ fontSize: "28px" }}>{selectedLamp.humanTime.years} {t("years")}</Text>
        <Text marginBottom="0px">{selectedLamp.humanTime.months} {t("months")} {selectedLamp.humanTime.days} {t("days")}</Text>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "16px" }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Text marginBottom="0px" fontWeight="700" color={supportTextColor} style={{ fontSize: "28px" }}>{selectedLamp.lifetime}</Text>
          <Text marginBottom="0px" color={supportTextColor}>{t("runningHours")}</Text>
        </div>
        <img src={timestamp} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
      </div>
    </CardSimple>
  );
};
