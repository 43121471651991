import { Button } from "antd";
import { store } from '../../../redux/store';
import styled from "styled-components";
import { styledMainTheme } from "../../../styles/shared-styles/styleMainTheme";

const values = store.getState();

interface NextButtonProps {
  accentButtonsColor?: string;
}

export const TextOption = styled.option`
  color: "#959595";
  font-size: 14px;
  cursor: pointer;
`;

export const InputField = styled.input`
  display: block;
  width: 100%;
  height: 36px;
  padding: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const SelectField = styled.select`
  display: block;
  width: 100%;
  padding: 8px;
  height: 36px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
`;

export const TextAreaField = styled.textarea`
  display: block;
  width: 100%;
  padding: 8px;
  height: 96px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const CardContainer = styled.div`
  /* padding-top: 10px; */
  padding-top: 5px;
  margin-bottom: 16px;
`;

export const CancelButton = styled.button`
  padding: 8px 32px;
  background-color: transparent;
  color: ${values.selectedOrganization.supportTextColor};
  font-size: 16px;
  border: none;
  font-weight: 500;
  border: 1px solid ${values.selectedOrganization.supportTextColor};
  border-radius: 4px;
`;

export const NextButton = styled.button<NextButtonProps>`
  padding: 8px 32px;
  background-color: ${props => props.accentButtonsColor};
  /* #6a7794 */
  color: #ffffff;
  font-size: 16px;
  border: 1px solid ${props => props.accentButtonsColor};
  font-weight: 500;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
`;

export const ButtonStrategy = styled(Button)<{
  buttonType: "primary" | "secondary" | "link" | "";
}>`
  padding: 8px 48px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.buttonType === "primary"
      ? styledMainTheme.light.buttonMainColor
      : props.buttonType === "secondary"
      ? styledMainTheme?.light?.mainBackgroundColor
      : "red"};
  color: ${values.selectedOrganization.accentButtonsColor};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  border: none;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 300px;
  margin-top: 30px;
  margin-left: -40px;
  /* &:hover {
    background-color: ${(props) =>
      props.buttonType === "primary"
        ? styledMainTheme.light.buttonMainColor
        : props.buttonType === "secondary"
        ? styledMainTheme?.light?.mainBackgroundColor
        : "red"} !important;
    color: ${values.selectedOrganization.accentButtonsColor} !important;
  } */
`;
