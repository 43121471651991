// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import your language JSON files
import translationEn from "./utils/languages/en.json";
import translationEs from "./utils/languages/es.json";
import translationRw from "./utils/languages/rw.json";
import translationFr from "./utils/languages/fr.json";
import translationSw from "./utils/languages/sw.json";
import translationAr from "./utils/languages/ar.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEn },
    es: { translation: translationEs },
    rw: { translation: translationRw },
    fr: { translation: translationFr },
    sw: { translation: translationSw },
    ar: { translation: translationAr },
    // Add more languages here
  },
  lng: "en",
  fallbackLng: "en", // Fallback language if translation is missing
  interpolation: {
    escapeValue: false, // React already escapes HTML, so no need to escape twice
  },
});

export default i18n;
