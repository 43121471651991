export const numericRegex = /^\d*$/;
export const hourRegex = /^(0[0-9]|1[0-9]|2[0-3])$/;
export const minuteRegex = /^(0[0-9]|[1-5][0-9])$/;
export const dimmingRegex = /^(0|[1-9][0-9]?|100)?$/;
export const offsetRegex = /^-?\d*$/;
export const phoneNumberRegex = /^\+\d{1,3}\d*$/;
export const alphabeticRegex = /^[a-zA-ZÀ-ÿ-'\s·]*$/;
export const hexRegex = /^#?[0-9A-Fa-f]{6}$/;
export const solarBattNumericRegex = /^\d{0,2}(\.\d?)?$/;
// /^(?:\d+(?:\.\d*)?)?$/;
// /^\d*\.?\d*$/;
export const solarStrategyNumericRegex = /^[0-4]?$/;
export const decimalRegex = /^\d*\.?\d+$/;
export const mailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const validPassword = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
