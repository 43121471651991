import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import App from "./App";
import "./styles/index.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { setLanguage } from "./redux/slices/languageSlice/languageSlice";

const currentLanguage = store.getState().language.selectedLanguage; // Obtiene el idioma actual de Redux
const browserLanguage = navigator.language.split('-')[0]; // Obtiene el idioma del navegador

if (!currentLanguage) {
  store.dispatch(setLanguage(browserLanguage || "en")); // Establece el idioma en Redux
  i18n.changeLanguage(browserLanguage || "en"); // Cambia el idioma en i18n
} else {
  i18n.changeLanguage(currentLanguage); // Cambia el idioma en i18n si ya está en Redux
}

/* const selectedLanguageFromLocalStorage =
  localStorage.getItem("selectedLanguage");
store.dispatch(setLanguage(selectedLanguageFromLocalStorage || "en")); */

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
