import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { Select } from 'antd';
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { resetGroupInfoActionCreator } from "../../../redux/slices/groupsSlice/groupsSlice";
import { resetRegisterUserInfoActionCreator } from "../../../redux/slices/userRegistrationSlice/userRegistrationSlice";
import { Details } from "../UsersSteps/Step1Details/Details";
import { Roles } from "../UsersSteps/Step2Roles/Roles";
import { OrgPermit } from "../UsersSteps/Step3Orgs/OrgPermit";
import { ServicePermit } from "../UsersSteps/Step4Services/ServicePermit";
import { GroupPermit } from "../UsersSteps/Step5Groups/GroupPermit";
import { Review } from "../UsersSteps/Step6Review/Review";
import { Text } from "../../../styles/shared-styles/text-sizes";
import back from "../../../assets/new_icons/back.svg";
import { ReactComponent as CheckIcon } from "../../../assets/new_icons/icon-selected.svg";
import { ReactComponent as CheckSelectedIcon } from "../../../assets/new_icons/check-circle.svg";
import smartecBackground from "../../../assets/new_icons/trama_forms_opacity.png";

interface NewUserModalProps {
  showModal: boolean;
  setShowModal: any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1400px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 880px;
    width: 90%;
    background: white;
    border: 0px;
  }
`;

export const NewUserModal = ({ showModal, setShowModal }: NewUserModalProps) => {
  const dispatch = useAppDispatch();
  const [showPreventPopup, setShowPreventPopup] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [showRoles, setShowRoles] = useState(false);
  const [showOrgs, setShowOrgs] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const { step } = useAppSelector((state) => state.userRegistration)
  const [errorMessage, setErrorMessage] = useState(false);

  const userInfo = useAppSelector((state) => state.userRegistration.user);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const handlePreventPopup = () => {
    // dispatch(changeStep(1))
    setShowPreventPopup(true);
  };

  const goToDetails = async () => {
    setShowDetails(true);
    setShowRoles(false);
    setShowOrgs(false);
    setShowServices(false);
    setShowGroups(false);
    setShowReview(false);
  };

  const goToRoles = async () => {
    if (userInfo.first_name === "" || userInfo.email === "" || userInfo.password === "" || userInfo.password_confirm === "") {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      setShowDetails(false);
      setShowRoles(true);
      setShowOrgs(false);
      setShowServices(false);
      setShowGroups(false);
      setShowReview(false);
    }
  };

  const goToOrgs = async () => {
    if (userInfo.first_name === "" || userInfo.email === "" || userInfo.password === "" || userInfo.password_confirm === "" || userInfo.user_language === "" ||
      userInfo.role_id === "") {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      setShowDetails(false);
      setShowRoles(false);
      setShowOrgs(true);
      setShowServices(false);
      setShowGroups(false);
      setShowReview(false);
    }
  };

  const goToServices = async () => {
    if (userInfo.first_name === "" || userInfo.email === "" || userInfo.password === "" || userInfo.password_confirm === "" || userInfo.user_language === "" ||
      userInfo.role_id === "" || userInfo.organization_id === "") {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      setShowDetails(false);
      setShowRoles(false);
      setShowOrgs(false);
      setShowServices(true);
      setShowGroups(false);
      setShowReview(false);
    }
  };

  const goToGroups = async () => {
    if (userInfo.first_name === "" || userInfo.email === "" || userInfo.password === "" || userInfo.password_confirm === "" || userInfo.user_language === "" ||
      userInfo.role_id === "" || userInfo.organization_id === "" || userInfo.service_id === "") {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      setShowDetails(false);
      setShowRoles(false);
      setShowOrgs(false);
      setShowServices(false);
      setShowGroups(true);
      setShowReview(false);
    }
  };

  const goToReview = async () => {
    if (userInfo.first_name === "" || userInfo.email === "" || userInfo.password === "" || userInfo.password_confirm === "" || userInfo.user_language === "" ||
      userInfo.role_id === "" || userInfo.organization_id === "" || userInfo.service_id === "") {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      setShowDetails(false);
      setShowRoles(false);
      setShowOrgs(false);
      setShowServices(false);
      setShowGroups(false);
      setShowReview(true);
    }
  };

  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      {showPreventPopup ?
        <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)",
          borderRadius: "14px", padding: "30px", display: "flex", flexDirection: "column", position: "absolute",  alignItems: "center",
          justifyContent: "center",  top: "362px", left: "407px", zIndex: 30
        }}>
          <Text>{t("Do you want to close this form? All the information will be lost")}</Text>
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {dispatch(resetRegisterUserInfoActionCreator()); setShowModal(!showModal)}}>{t("Accept")}</button>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowPreventPopup(false)}}>{t("Cancel")}</button>
          </div>
        </div>
      :
        <></>
      }
      <Modal.Body style={{ pointerEvents: showPreventPopup ? "none" : "auto", background: "white", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px",
        paddingRight: "0px", borderTopRightRadius: "0.5rem", borderTopLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem", borderBottomLeftRadius: "0.5rem", display: "flex",
        justifyContent: "space-between"}}
      >
        <div style={{ borderRight: "1px solid rgba(71, 85, 105, 0.1)", background: "rgba(236, 236, 236, 0.92)", borderTopLeftRadius: "0.5rem", borderBottomLeftRadius: "0.5rem" }} >
          <div style={{ width: "280px", height: "100%", background: `url(${smartecBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", paddingTop: "54px", paddingLeft: "40px" }}>
              <Text fontWeight="700" marginBottom="5px" color={mainTextColor} style={{ fontSize: "22px" }}>{t("newUserRegistration")}</Text>
              <div style={{display: "flex", flexDirection: "column", gap: "15px", marginTop: "60px"}}>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {goToDetails()}}>
                  {showRoles || showOrgs || showServices || showGroups || showReview ?
                    <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  :
                    <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  }
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("generalDetails")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("generalDetailsSideMenuUsers")}</Text>
                  </div>
                </div>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {goToRoles()}}>
                  {showOrgs || showServices || showGroups || showReview ?
                    <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  :
                    <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  }
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("roleAndLanguage")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("roleAndLanguageSideMenuUsers")}</Text>
                  </div>
                </div>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {goToOrgs()}}>
                  {showServices || showGroups || showReview ?
                    <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  :
                    <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  }
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("orgsPermits")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("orgsPermitsSideMenuUsers")}</Text>
                  </div>
                </div>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {goToServices()}}>
                  {showGroups || showReview ?
                    <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  :
                    <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  }
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("servicesPermits")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("servicesPermitsSideMenuUsers")}</Text>
                  </div>
                </div>
                {/* <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {goToGroups()}}>
                  {showReview ?
                    <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  :
                    <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  }
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("groupsPermits")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("groupsPermitsSideMenuUsers")}</Text>
                  </div>
                </div> */}
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {goToReview()}}>
                  <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("Review")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("Verify all the information")}</Text>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
        <div style={{ display: "flex", width: "100%", padding: "8px 10px 0px 0px", flexDirection: "column", background: "#ffffff", borderTopRightRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {
              showRoles ?
                <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }} 
                  onClick={() => {setShowDetails(true);  setShowRoles(false); setShowOrgs(false); setShowServices(false); setShowGroups(false); setShowReview(false)}}
                >
                  <img src={back} height={12} alt="" style={{ opacity: 0.5, marginRight: "8px" }} />
                  <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralDetails")}</Text>
                </div>
              : showOrgs ?
                  <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }} 
                    onClick={() => {setShowDetails(false);  setShowRoles(true); setShowOrgs(false); setShowServices(false); setShowGroups(false); setShowReview(false)}}
                  >
                    <img src={back} height={12} alt="" style={{ opacity: 0.5, marginRight: "8px" }} />
                    <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToRoleAndLanguage")}</Text>
                  </div>
                : showServices ?
                    <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }} 
                      onClick={() => {setShowDetails(false);  setShowRoles(false); setShowOrgs(true); setShowServices(false); setShowGroups(false); setShowReview(false)}}
                    >
                      <img src={back} height={12} alt="" style={{ opacity: 0.5, marginRight: "8px" }} />
                      <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToOrgPermits")}</Text>
                    </div>
                  : showGroups ?
                      <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }} 
                        onClick={() => {setShowDetails(false);  setShowRoles(false); setShowOrgs(false); setShowServices(true); setShowGroups(false); setShowReview(false)}}
                      >
                        <img src={back} height={12} alt="" style={{ opacity: 0.5, marginRight: "8px" }} />
                        <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralDetails")}</Text>
                      </div>
                    : showReview ?
                        <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }} 
                          onClick={() => {setShowDetails(false);  setShowRoles(false); setShowOrgs(false); setShowServices(true); /* setShowGroups(true) */; setShowReview(false)}}
                        >
                          <img src={back} height={12} alt="" style={{ opacity: 0.5, marginRight: "8px" }} />
                          <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToServPermits")}</Text>
                        </div>
                      :
                        <div></div>
            }
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                background: "white",
                zIndex: 2,
              }}
            />
          </div>
          {/* FALTA REVISAR ESTOS IMPORTS, PRIMERO QUIZÁS TOQUETEAR CADA ARCHIVO Y LUEGO VOLVER AQUÍ */}
          {showDetails && 
            <Details goToRoles={goToRoles} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />}
          {showRoles && 
            <Roles goToOrgs={goToOrgs} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />}
          {showOrgs && 
            <OrgPermit goToServices={goToServices} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />}
          {showServices && 
            <ServicePermit goToReview={goToReview} /* goToGroups={goToGroups} */ errorMessage={errorMessage} setErrorMessage={setErrorMessage} />}
          {showGroups && 
            <GroupPermit goToReview={goToReview} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />}
          {showReview &&
            <Review onHide={setShowModal} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />}
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
