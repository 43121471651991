import { useState, useEffect } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { LatLng } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { setSelectedDevicesListActionCreator, setRemovedDevicesListActionCreator } from "../../../redux/slices/groupsSlice/groupsSlice";
import { Lamp } from "../../../types/interfaces";
import { GroupsCustomMarker } from "./GroupsCustomMarker";
import { GroupsMapEventHandler } from "./GroupsMapEventHandler";
import { CustomMapStyled } from "../../CustomMaps/CustomMap/CustomMapStyled";
import { GroupLeafletMapHeader } from "../GroupsPageStyled";
import "../../../styles/index.css";
import Gateway from "../../../assets/new_icons/Gateway nodePage.svg";
import Luminaire from "../../../assets/new_icons/luminaire detailCard.svg";

interface GroupsModalMapProps {
  isNewGroup?: boolean | any;
  showDevice?: boolean | any;
  showIcon?: boolean | any;
  interactiveMap?: boolean | any;
  fromGroupsPage?: boolean | any;
  fromReviewPage?: boolean | any;
}

export const GroupsLeafletMap = ({ isNewGroup, showDevice, showIcon, interactiveMap, fromGroupsPage, fromReviewPage }: GroupsModalMapProps) => {
  const devices = useAppSelector((state) => state.filteredDevices);
  const allNodes = useAppSelector((state) => state.devices.nodes);
  const filteredAllNodes = allNodes?.filter(
    (lamp: any) => lamp.latitude
  );
  const { selectedGroup, selectedDevices, removedDevices } = useAppSelector((state) => state.groups);
  const groupName = useAppSelector((state) => state.groups.selectedGroup.groupName);
  const filteredLamps = selectedGroup?.nodes?.filter(
    (lamp: any) => lamp.latitude
  );

  const [map, setMap] = useState(0);
  const dispatch = useAppDispatch();
  const setSelectedLamp = (device: any) => {
    const isSelected = selectedDevices.some(
      (dev: any) => device.node_id === dev.node_id
    );

    const isRemoved = removedDevices.some(
      (dev: any) => device.node_id === dev.node_id
    );

    if (!isSelected && !isRemoved) {
      dispatch(setSelectedDevicesListActionCreator({ selectedDevices: [...selectedDevices, device] }));
    } else if (isSelected) {
      const remainingSelected = selectedDevices.filter(
        (deviceId: any) => deviceId.node_id !== device.node_id
      );
      dispatch(setSelectedDevicesListActionCreator({ selectedDevices: remainingSelected }));
      dispatch(setRemovedDevicesListActionCreator({ removedDevices: [...removedDevices, device] }));
    } else if (isRemoved) {
      const remainingRemoved = removedDevices.filter(
        (deviceId: any) => deviceId.node_id !== device.node_id
      );
      dispatch(setRemovedDevicesListActionCreator({ removedDevices: remainingRemoved }));
      dispatch(setSelectedDevicesListActionCreator({ selectedDevices: [...selectedDevices, device] }));
    }
  };

  /* const setSelectedLamp = (device: any) => {
    const isSelected = selectedDevices.some(
      (dev: any) => device.node_id === dev.node_id
    );
    
    if (!isSelected) {
      dispatch(setSelectedDevicesListActionCreator({ selectedDevices: [...selectedDevices, device] }));
    } else {
      const added = selectedDevices?.filter(
        (deviceId: any) => deviceId !== device
      );
      const removed = selectedDevices?.filter(
        (deviceId: any) => deviceId === device
      );
      dispatch(setSelectedDevicesListActionCreator({ selectedDevices: added }));
      if (removed) {
        dispatch(setRemovedDevicesListActionCreator({ removedDevices: [...removedDevices, device] }));
      }
    }
  }; */
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const calculateCenter = (devices: any) => {
    const defaultLat = 0;
    const defaultLng = 0;
    let minLat = devices[0]?.latitude;
    let maxLat = devices[0]?.latitude;
    let minLng = devices[0]?.longitude;
    let maxLng = devices[0]?.longitude;

    devices.forEach((device: any) => {
      minLat = Math.min(minLat, device.latitude);
      maxLat = Math.max(maxLat, device.latitude);
      minLng = Math.min(minLng, device.longitude);
      maxLng = Math.max(maxLng, device.longitude);
    });

    const lat =
      ((minLat !== undefined ? minLat : defaultLat) +
        (maxLat !== undefined ? maxLat : defaultLat)) /
      2;
    const lng =
      ((minLng !== undefined ? minLng : defaultLng) +
        (maxLng !== undefined ? maxLng : defaultLng)) /
      2;

    return { lat, lng };
  };

  const center = calculateCenter(
    devices?.nodes?.filter((lamp: any) => lamp.latitude)
  );

  const calculateMaxDistance = (devices: any) => {
    let maxDistance = 0;
    devices.forEach((device1: any, index1: any) => {
      devices.slice(index1 + 1).forEach((device2: any) => {
        const distance = Math.sqrt(
          Math.pow(device1.latitude - device2.latitude, 2) +
          Math.pow(device1.longitude - device2.longitude, 2)
        );
        maxDistance = Math.max(maxDistance, distance);
      });
    });
    return maxDistance;
  };

  const maxDistance = /* fromReviewPage ?
    calculateMaxDistance(
      selectedDevices.filter((lamp: any) => lamp.latitude)
    )
  : */

    /* calculateMaxDistance(
    filteredAllNodes.filter((lamp: any) => lamp.latitude)
    );
  );*/

    calculateMaxDistance(filteredAllNodes)

/*   const maxDistance = calculateMaxDistance(
    devices?.nodes?.filter((lamp: any) => lamp.latitude)
  ); */

  const zoom =
    maxDistance < 0.01
      ? 17
      : maxDistance < 0.05
      ? 13
      : maxDistance < 0.5
        ? 10
        : 8;

  useEffect(() => {
  }, [groupName, allNodes]);

  return (
    <CustomMapStyled
      id="map-container"
      // style={{ position: "absolute", width: "50%", height: "10%" }}
    >
      <GroupLeafletMapHeader className="top-map-header">
        {showIcon && (
          <div className="top-left-map-section">
            <p style={{ border: `2px solid ${accentButtonsColor}`}}>
              <img src={Luminaire} alt="luminaire" />
              <span style={{ color: accentButtonsColor }}>{isNewGroup ? allNodes.length : fromGroupsPage ? selectedGroup.nodes.length : allNodes.length}</span>
            </p>
            {/* <p>
              <img src={Gateway} alt="gateway" />
              <span>123</span>
            </p> */}
          </div>
        )}
        {showDevice && selectedDevices.length > 0 && (
          <div className="top-right-map-section">
            <h6>{t("devicesSelected")}</h6>
            <p>
              <img src={Luminaire} alt="luminaire" />
              <span>{selectedDevices.length}</span>
            </p>
            {/* <p>
              <img src={Gateway} alt="gateway" />
              <span>123</span>
            </p> */}
          </div>
        )}
      </GroupLeafletMapHeader>
      <MapContainer
        className="my-custom-map"
        center={center}
        zoom={zoom}
        dragging={!fromGroupsPage && !fromReviewPage}
        touchZoom={false}
        doubleClickZoom={false}
        scrollWheelZoom={!fromGroupsPage && !fromReviewPage}
        boxZoom={false}
        keyboard={false}
        zoomControl={false}
        attributionControl={false}
        maxZoom={30}
        minZoom={5}
      >
        <TileLayer
          key={"map layer"}
          //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          url={`https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}`}
          maxZoom={30}
          maxNativeZoom={19}
        />
       
        <GroupsMapEventHandler
          lamps={isNewGroup ? filteredAllNodes : fromGroupsPage ? filteredLamps : filteredAllNodes}
          setMapBounds={setMap}
          fromGroupsPage={fromGroupsPage}
          isNewGroup={isNewGroup}
        ></GroupsMapEventHandler>
  
        <MarkerClusterGroup
          chunkedLoading
          onClick={() => {}}
          maxClusterRadius={0}
          removeOutsideVisibleBounds
          disableClusteringAtZoom={15}
          spiderfyOnMaxZoom={false}
          zoom={10}
        >
          {isNewGroup ?
            fromReviewPage ?
              selectedDevices.filter((el:any) => el.latitude)?.map((lamp: any) => (
                <GroupsCustomMarker
                  filteredLamps={selectedDevices}
                  fromGroupsPage={fromGroupsPage}
                  interactive={interactiveMap}
                  marker={selectedDevices}
                  key={lamp.node_id + Math.random().toString()}
                  lamp={lamp}
                  setMarker={setSelectedLamp}
                  selected={selectedDevices.some(
                    (device: any) => device.node_id === lamp.node_id
                  )}
                  editable={false}
                  center={
                    new LatLng(
                      Number.parseFloat(lamp.latitude),
                      Number.parseFloat(lamp.longitude)
                    )
                  }
                />
              ))
            :
              filteredAllNodes?.map((lamp: any) => (
                <GroupsCustomMarker
                  filteredLamps={filteredLamps}
                  fromGroupsPage={fromGroupsPage}
                  interactive={interactiveMap}
                  marker={selectedDevices}
                  key={lamp.node_id + Math.random().toString()}
                  lamp={lamp}
                  setMarker={setSelectedLamp}
                  selected={selectedDevices.some(
                    (device: any) => device.node_id === lamp.node_id
                  )}
                  editable={false}
                  center={
                    new LatLng(
                      Number.parseFloat(lamp.latitude),
                      Number.parseFloat(lamp.longitude)
                    )
                  }
                />
              ))
          :
            fromGroupsPage ? 
              filteredLamps?.map((lamp: any) => (
                <GroupsCustomMarker
                  filteredLamps={filteredLamps}
                  fromGroupsPage={fromGroupsPage}
                  interactive={interactiveMap}
                  marker={selectedGroup?.nodes}
                  key={lamp.node_id + Math.random().toString()}
                  lamp={lamp}
                  setMarker={setSelectedLamp}
                  selected={selectedGroup?.nodes.some(
                    (device: any) => device.node_id === lamp.node_id
                  )}
                  editable={false}
                  center={
                    new LatLng(
                      Number.parseFloat(lamp.latitude),
                      Number.parseFloat(lamp.longitude)
                    )
                  }
                />
              ))
            :
              filteredAllNodes?.map((lamp: any) => (
                <GroupsCustomMarker
                  filteredLamps={filteredLamps}
                  fromGroupsPage={fromGroupsPage}
                  interactive={interactiveMap}
                  marker={selectedDevices}
                  key={lamp.node_id + Math.random().toString()}
                  lamp={lamp}
                  setMarker={setSelectedLamp}
                  selected={selectedDevices.some(
                    (device: any) => device.node_id === lamp.node_id
                  )} // Che
                  editable={false}
                  center={
                    new LatLng(
                      Number.parseFloat(lamp.latitude),
                      Number.parseFloat(lamp.longitude)
                    )
                  }
                />
              ))
          }
          {/* {filteredLamps?.map((lamp: any) => (
            <GroupsCustomMarker
              filteredLamps={filteredLamps}
              fromGroupsPage={fromGroupsPage}
              interactive={interactiveMap}
              marker={selectedDevices}
              key={lamp.node_id + Math.random().toString()}
              lamp={lamp}
              setMarker={setSelectedLamp}
              selected={selectedDevices.some(
                (device: any) => device.node_id === lamp.node_id
              )} // Che
              editable={false}
              center={
                new LatLng(
                  Number.parseFloat(lamp.latitude),
                  Number.parseFloat(lamp.longitude)
                )
              }
            />
          ))} */}
        </MarkerClusterGroup>
      </MapContainer>
    </CustomMapStyled>
  );
};
