import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { turnBackgroundStrategiesFalseActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { Modal } from "react-bootstrap";
import { Details } from "../ReportsSteps/Step1Details/Details";
import { Devices } from "../ReportsSteps/Step2Devices/Devices";
import { Review } from "../ReportsSteps/Step3Review/Review";
import { LayoutGreyShadow } from "./NewReportModalStyled";
import styled from "styled-components";
import { Text } from "../../../styles/shared-styles/text-sizes";
import back from "../../../assets/new_icons/back.svg";
import smartecBackground from "../../../assets/new_icons/trama_forms_opacity.png";
import { ReactComponent as CheckIcon } from "../../../assets/new_icons/icon-selected.svg";
import { ReactComponent as CheckSelectedIcon } from "../../../assets/new_icons/check-circle.svg";

interface NewReportModalProps {
  showModal: boolean;
  setShowModal: any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1400px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 880px;
    width: 90%;
    background: white;
    border: 0px;
  }
`;

export const NewReportModal = ({ showModal, setShowModal }: NewReportModalProps) => {
  const dispatch = useAppDispatch();
  const [showPreventPopup, setShowPreventPopup] = useState(false)
  const [showDetails, setShowDetails] = useState(true);
  const [showDevices, setShowDevices] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [showStandard, setShowStandard] = useState(false);
  const [showSolar, setShowSolar] = useState(false);
  const [showHybrid, setShowHybrid] = useState(false);
  const [showRgb, setShowRgb] = useState(false);
  const [showSmartec, setShowSmartec] = useState(false);
  const [showShuncom, setShowShuncom] = useState(false);
  const [showSwitch, setShowSwitch] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const startDate = useAppSelector((state) => state.pdfReport.startDate);
  const endDate = useAppSelector((state) => state.pdfReport.endDate);
  const deviceLum = useAppSelector((state) => state.pdfReport.deviceLum);
  const deviceGate = useAppSelector((state) => state.pdfReport.deviceGate);
  const deviceSwitch = useAppSelector((state) => state.pdfReport.deviceSwitch);
  const lumTypeStandard = useAppSelector((state) => state.pdfReport.lumTypeStandard);
  const lumTypeSolar = useAppSelector((state) => state.pdfReport.lumTypeSolar);
  const lumTypeHybrid = useAppSelector((state) => state.pdfReport.lumTypeHybrid);
  const lumTypeRgb = useAppSelector((state) => state.pdfReport.lumTypeRgb);
  const gateTypeSmartec = useAppSelector((state) => state.pdfReport.gateTypeSmartec);
  const gateTypeShuncom = useAppSelector((state) => state.pdfReport.gateTypeShuncom);
  const { selectedDevicesStandard, selectedDevicesSolar, selectedDevicesHybrid, selectedDevicesRgb, selectedDevicesSmartec, selectedDevicesShuncom,
    selectedDevicesSwitch } = useAppSelector((state) => state.pdfReport);
  const background = useAppSelector((state) => state.graphics.backgroundStrategies);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const handlePreventPopup = () => {
    setShowPreventPopup(true);
  };

  const goToDetails = async () => {
    setShowDetails(true);
    setShowDevices(false);
    setShowReview(false)
    setErrorMessage(false);
  }

  const goToDevices = async () => {
    const today = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if ((start && start > today) || (end && end > today)) {
      setErrorMessage(true);
      return;
    } else if (start && end && start > end) {
      setErrorMessage(true);
      return;
    }

    if (startDate === "" || endDate === "" || (!deviceLum && !deviceGate && !deviceSwitch)) {
      setErrorMessage(true);
      setShowDetails(true);
      setShowDevices(false);
      setShowReview(false);
    } else {
      if (deviceLum && (!lumTypeStandard && !lumTypeSolar && !lumTypeHybrid && !lumTypeRgb)) {
        setErrorMessage(true);
        setShowDetails(true);
        setShowDevices(false);
        setShowReview(false);
      } else if (deviceGate && (!gateTypeSmartec && !gateTypeShuncom)) {
        setErrorMessage(true);
        setShowDetails(true);
        setShowDevices(false);
        setShowReview(false);
      } else if ((deviceLum && (lumTypeStandard || lumTypeSolar || lumTypeHybrid || lumTypeRgb)) || (deviceGate && (gateTypeSmartec || gateTypeShuncom)) || deviceSwitch) {
        setErrorMessage(false);
        setShowDetails(false);
        setShowDevices(true);
        setShowReview(false);

        if (lumTypeStandard) {
          setShowStandard(true);
          setErrorMessage(false);
        } else if (lumTypeSolar) {
          setShowStandard(false);
          setShowSolar(true);
          setErrorMessage(false);
        } else if (lumTypeHybrid) {
          setShowStandard(false);
          setShowSolar(false);
          setShowHybrid(true);
          setErrorMessage(false);
        } else if (lumTypeRgb) {
          setShowStandard(false);
          setShowSolar(false);
          setShowHybrid(false);
          setShowRgb(true);
          setErrorMessage(false);
        } else if (gateTypeSmartec) {
          setShowStandard(false);
          setShowSolar(false);
          setShowHybrid(false);
          setShowRgb(false);
          setShowSmartec(true);
          setErrorMessage(false);
        } else if (gateTypeShuncom) {
          setShowStandard(false);
          setShowSolar(false);
          setShowHybrid(false);
          setShowRgb(false);
          setShowSmartec(false);
          setShowShuncom(true);
          setErrorMessage(false);
        } else if (deviceSwitch) {
          setShowStandard(false);
          setShowSolar(false);
          setShowHybrid(false);
          setShowRgb(false);
          setShowSmartec(false);
          setShowShuncom(false);
          setShowSwitch (true);
          setErrorMessage(false);
        }
      }
    }
  };

  const goBetweenMapsAndToReview = async () => {
    if (showStandard) {
      if (selectedDevicesStandard.length > 0) {
        setShowStandard(false);
        setErrorMessage(false);
        if (lumTypeSolar) {
          setShowSolar(true)
        } else if (lumTypeHybrid) {
          setShowHybrid(true)
        } else if (lumTypeRgb) {
          setShowRgb(true)
        } else if (gateTypeSmartec) {
          setShowSmartec(true)
        } else if (gateTypeShuncom) {
          setShowShuncom(true)
        } else if (deviceSwitch) {
          setShowSwitch(true)
        } else {
          setShowDetails(false);
          setShowDevices(false);
          setShowReview(true);
        }
      } else {
        setErrorMessage(true)
      }
    } else if (showSolar) {
      if (selectedDevicesSolar.length > 0) {
        setShowSolar(false);
        setErrorMessage(false);
        if (lumTypeHybrid) {
          setShowHybrid(true)
        } else if (lumTypeRgb) {
          setShowRgb(true)
        } else if (gateTypeSmartec) {
          setShowSmartec(true)
        } else if (gateTypeShuncom) {
          setShowShuncom(true)
        } else if (deviceSwitch) {
          setShowSwitch(true)
        } else {
          setShowDetails(false);
          setShowDevices(false);
          setShowReview(true);
        }
      } else {
        setErrorMessage(true)
      }
    } else if (showHybrid) {
      if (selectedDevicesHybrid.length > 0) {
        setShowHybrid(false);
        setErrorMessage(false);
        if (lumTypeRgb) {
          setShowRgb(true)
        } else if (gateTypeSmartec) {
          setShowSmartec(true)
        } else if (gateTypeShuncom) {
          setShowShuncom(true)
        } else if (deviceSwitch) {
          setShowSwitch(true)
        } else {
          setShowDetails(false);
          setShowDevices(false);
          setShowReview(true);
        }
      } else {
        setErrorMessage(true)
      }
    } else if (showRgb) {
      if (selectedDevicesRgb.length > 0) {
        setShowRgb(false);
        setErrorMessage(false);
        if (gateTypeSmartec) {
          setShowSmartec(true)
        } else if (gateTypeShuncom) {
          setShowShuncom(true)
        } else if (deviceSwitch) {
          setShowSwitch(true)
        } else {
          setShowDetails(false);
          setShowDevices(false);
          setShowReview(true);
        }
      } else {
        setErrorMessage(true)
      }
    } else if (showSmartec) {
      if (selectedDevicesSmartec.length > 0) {
        setShowSmartec(false);
        setErrorMessage(false);
        if (gateTypeShuncom) {
          setShowShuncom(true)
        } else if (deviceSwitch) {
          setShowSwitch(true)
        } else {
          setShowDetails(false);
          setShowDevices(false);
          setShowReview(true);
        }
      } else {
        setErrorMessage(true)
      }
    } else if (showShuncom) {
      if (selectedDevicesShuncom.length > 0) {
        setShowShuncom(false);
        setErrorMessage(false);
        if (deviceSwitch) {
          setShowSwitch(true)
        } else {
          setShowDetails(false);
          setShowDevices(false);
          setShowReview(true);
        }
      } else {
        setErrorMessage(true)
      }
    } else if (showSwitch) {
      if (selectedDevicesSwitch.length > 0) {
        setErrorMessage(false);
        setShowSwitch(false);
        setShowDetails(false);
        setShowDevices(false);
        setShowReview(true);
      } else {
        setErrorMessage(true)
      }
    }
  };

  const manageGoBackFromDevices = () => {
    setErrorMessage(false);

    if (showStandard) {
      setShowStandard(false);
      setShowDetails(true);
      setShowDevices(false);
      setShowReview(false);
    } else if (showSolar) {
      setShowSolar(false);
      if (lumTypeStandard) {
        setShowStandard(true)
      } else {
        setShowDetails(true);
        setShowDevices(false);
        setShowReview(false);
      }
    } else if (showHybrid) {
      setShowHybrid(false);
      if (lumTypeSolar) {
        setShowSolar(true)
      } else if (lumTypeStandard) {
        setShowStandard(true)
      } else {
        setShowDetails(true);
        setShowDevices(false);
        setShowReview(false);
      }
    } else if (showRgb) {
      setShowRgb(false);
      if (lumTypeHybrid) {
        setShowHybrid(true)
      } else if (lumTypeSolar) {
        setShowSolar(true)
      } else if (lumTypeStandard) {
        setShowStandard(true)
      } else {
        setShowDetails(true);
        setShowDevices(false);
        setShowReview(false);
      }
    } else if (showSmartec) {
      setShowSmartec(false);
      if (lumTypeRgb) {
        setShowRgb(true)
      } else if (lumTypeHybrid) {
        setShowHybrid(true)
      } else if (lumTypeSolar) {
        setShowSolar(true)
      } else if (lumTypeStandard) {
        setShowStandard(true)
      } else {
        setShowDetails(true);
        setShowDevices(false);
        setShowReview(false);
      }
    } else if (showShuncom) {
      setShowShuncom(false);
      if (gateTypeSmartec) {
        setShowSmartec(true)
      } else if (lumTypeRgb) {
        setShowRgb(true)
      } else if (lumTypeHybrid) {
        setShowHybrid(true)
      } else if (lumTypeSolar) {
        setShowSolar(true)
      } else if (lumTypeStandard) {
        setShowStandard(true)
      } else {
        setShowDetails(true);
        setShowDevices(false);
        setShowReview(false);
      }
    } else if (showSwitch) {
      setShowSwitch(false);
      if (gateTypeShuncom) {
        setShowShuncom(true)
      } else if (gateTypeSmartec) {
        setShowSmartec(true)
      } else if (lumTypeRgb) {
        setShowRgb(true)
      } else if (lumTypeHybrid) {
        setShowHybrid(true)
      } else if (lumTypeSolar) {
        setShowSolar(true)
      } else if (lumTypeStandard) {
        setShowStandard(true)
      } else {
        setShowDetails(true);
        setShowDevices(false);
        setShowReview(false);
      }
    }
  };

  const manageGoBackFromReview = () => {
    setErrorMessage(false);
    if (deviceSwitch) {
      setShowSwitch(true);
      setShowDetails(false);
      setShowDevices(true);
      setShowReview(false);
    } else if (gateTypeShuncom) {
      setShowShuncom(true);
      setShowDetails(false);
      setShowDevices(true);
      setShowReview(false);
    } else if (gateTypeSmartec) {
      setShowSmartec(true);
      setShowDetails(false);
      setShowDevices(true);
      setShowReview(false);
    } else if (lumTypeRgb) {
      setShowRgb(true);
      setShowDetails(false);
      setShowDevices(true);
      setShowReview(false);
    } else if (lumTypeHybrid) {
      setShowHybrid(true);
      setShowDetails(false);
      setShowDevices(true);
      setShowReview(false);
    } else if (lumTypeSolar) {
      setShowSolar(true);
      setShowDetails(false);
      setShowDevices(true);
      setShowReview(false);
    } else if (lumTypeStandard) {
      setShowStandard(true);
      setShowDetails(false);
      setShowDevices(true);
      setShowReview(false);
    }
  };
  
  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      {showPreventPopup ?
        <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)",
          borderRadius: "14px", padding: "30px", display: "flex", flexDirection: "column", position: "absolute",  alignItems: "center",
          justifyContent: "center",  top: "362px", left: "407px", zIndex: 30
        }}>
          <Text>{t("Do you want to close this form? All the information will be lost")}</Text>
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {dispatch(turnBackgroundStrategiesFalseActionCreator());
              setShowModal(!showModal)}}>{t("Accept")}
            </button>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowPreventPopup(false)}}>{t("Cancel")}</button>
          </div>
        </div>
      :
        <></>
      }
      <Modal.Body style={{ pointerEvents: showPreventPopup ? "none" : "auto", background: "white", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px",
        paddingRight: "0px", borderTopRightRadius: "0.5rem", borderTopLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem", borderBottomLeftRadius: "0.5rem", display: "flex",
        justifyContent: "space-between"}}
      >
        <div style={{ borderRight: "1px solid rgba(71, 85, 105, 0.1)", background: "rgba(236, 236, 236, 0.92)", borderTopLeftRadius: "0.5rem", borderBottomLeftRadius: "0.5rem" }} >
          <div style={{ width: "280px", height: "100%", background: `url(${smartecBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", paddingTop: "54px", paddingLeft: "40px" }}>
              <Text fontWeight="700" marginBottom="5px" color={mainTextColor} style={{fontSize: "22px" }}>{t("newReport")}</Text>
              <div style={{display: "flex", flexDirection: "column", gap: "15px", marginTop: "60px"}}>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {!background && goToDetails()}}>
                  {showDevices || showReview ?
                    <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  :
                    <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  }
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("generalDetails")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("generalDetailsSideMenuReports")}</Text>
                  </div>
                </div>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {!background && goToDevices()}}>
                  {showReview ?
                    <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  :
                    <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  }
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("Devices")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("devicesConfigurationSideMenu")}</Text>
                  </div>
                </div>

                {!showDetails && lumTypeStandard &&
                  <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px", marginLeft: "25px" }} onClick={() => {!background && goToDevices()}}>
                    {showSolar || showHybrid || showRgb || showSmartec || showShuncom || showSwitch || showReview ?
                      <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    :
                      <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    }
                    <div style={{marginTop: "-3px"}}>
                      <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("Standard")}</Text>
                      <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("chooseStandard")}</Text>
                    </div>
                  </div>
                }
                {!showDetails && lumTypeSolar &&
                  <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px", marginLeft: "25px" }} onClick={() => {!background && goToDevices()}}>
                    {showHybrid || showRgb || showSmartec || showShuncom || showSwitch || showReview ?
                      <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    :
                      <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    }
                    <div style={{marginTop: "-3px"}}>
                      <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("Solar")}</Text>
                      <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("chooseSolar")}</Text>
                    </div>
                  </div>
                }
                {!showDetails && lumTypeHybrid &&
                  <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px", marginLeft: "25px" }} onClick={() => {!background && goToDevices()}}>
                    {showRgb || showSmartec || showShuncom || showSwitch || showReview ?
                      <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    :
                      <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    }
                    <div style={{marginTop: "-3px"}}>
                      <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("hybrid")}</Text>
                      <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("chooseHybrid")}</Text>
                    </div>
                  </div>
                }
                {!showDetails && lumTypeRgb &&
                  <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px", marginLeft: "25px" }} onClick={() => {!background && goToDevices()}}>
                    {showSmartec || showShuncom || showSwitch || showReview ?
                      <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    :
                      <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    }
                    <div style={{marginTop: "-3px"}}>
                      <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("RGB")}</Text>
                      <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("chooseRgb")}</Text>
                    </div>
                  </div>
                }
                {!showDetails && gateTypeSmartec &&
                  <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px", marginLeft: "25px" }} onClick={() => {!background && goToDevices()}}>
                    {showShuncom || showSwitch || showReview ?
                      <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    :
                      <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    }
                    <div style={{marginTop: "-3px"}}>
                      <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("smartec")}</Text>
                      <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("chooseSmartec")}</Text>
                    </div>
                  </div>
                }
                {!showDetails && gateTypeShuncom &&
                  <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px", marginLeft: "25px" }} onClick={() => {!background && goToDevices()}}>
                    {showSwitch || showReview ?
                      <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    :
                      <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    }
                    <div style={{marginTop: "-3px"}}>
                      <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("shuncom")}</Text>
                      <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("chooseShuncom")}</Text>
                    </div>
                  </div>
                }
                {!showDetails && deviceSwitch &&
                  <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px", marginLeft: "25px" }} onClick={() => {!background && goToDevices()}}>
                    {showReview ?
                      <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    :
                      <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                    }
                    <div style={{marginTop: "-3px"}}>
                      <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("switchboards")}</Text>
                      <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("chooseSwitch")}</Text>
                    </div>
                  </div>
                }

                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {!background && goBetweenMapsAndToReview()}}>
                  <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("Review")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("Verify all the information")}</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", width: "100%", padding: "8px 10px 0px 0px", flexDirection: "column", background: "#ffffff", borderTopRightRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem" }}
        >
          {background ? (
            <LayoutGreyShadow />
          ) : (
            <></>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {
              showDevices ?
                <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }} 
                  onClick={() => {manageGoBackFromDevices()}}
                >
                  <img src={back} height={12} alt="" style={{ opacity: 0.5, marginRight: "8px" }} />
                  {showStandard ?
                    <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralDetails")}</Text>
                  :
                    showSolar ?
                      lumTypeStandard ?
                        <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToStandard")}</Text>
                      :
                        <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralDetails")}</Text>
                    :
                      showHybrid ?
                        lumTypeSolar ?
                          <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToSolar")}</Text>
                        :
                          lumTypeStandard ?
                            <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToStandard")}</Text>
                          :
                            <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralDetails")}</Text>
                      :
                        showRgb ?
                          lumTypeHybrid ?
                            <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToHybrid")}</Text>
                          :
                            lumTypeSolar ?
                              <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToSolar")}</Text>
                            :
                              lumTypeStandard ?
                                <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToStandard")}</Text>
                              :
                                <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralDetails")}</Text>
                        :
                          showSmartec ?
                            lumTypeRgb ?
                              <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToRgb")}</Text>
                            :
                              lumTypeHybrid ?
                                <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToHybrid")}</Text>
                              :
                                lumTypeSolar ?
                                  <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToSolar")}</Text>
                                :
                                  lumTypeStandard ?
                                    <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToStandard")}</Text>
                                  :
                                    <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralDetails")}</Text>
                          :
                            showShuncom ?
                              gateTypeSmartec ?
                                <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToSmartec")}</Text>
                              :
                                lumTypeRgb ?
                                  <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToRgb")}</Text>
                                :
                                  lumTypeHybrid ?
                                    <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToHybrid")}</Text>
                                  :
                                    lumTypeSolar ?
                                      <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToSolar")}</Text>
                                    :
                                      lumTypeStandard ?
                                        <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToStandard")}</Text>
                                      :
                                        <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralDetails")}</Text>
                            :
                              showSwitch ?
                                gateTypeShuncom ?
                                  <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToShuncom")}</Text>
                                :
                                  gateTypeSmartec ?
                                    <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToSmartec")}</Text>
                                  :
                                    lumTypeRgb ?
                                      <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToRgb")}</Text>
                                    :
                                      lumTypeHybrid ?
                                        <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToHybrid")}</Text>
                                      :
                                        lumTypeSolar ?
                                          <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToSolar")}</Text>
                                        :
                                          lumTypeStandard ?
                                            <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToStandard")}</Text>
                                          :
                                            <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralDetails")}</Text>
                          :
                            <></>
                  }
                </div>
              : showReview ?
                  <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }}
                    onClick={() => {manageGoBackFromReview()}}
                  >
                    <img src={back} height={12} alt="" style={{ opacity: 0.5, marginRight: "8px" }} />
                    {deviceSwitch ?
                      <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralSwitch")}</Text>
                    :
                      gateTypeShuncom ?
                        <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToShuncom")}</Text>
                      :
                        gateTypeSmartec ?
                          <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToSmartec")}</Text>
                        :
                          lumTypeRgb ?
                            <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToRgb")}</Text>
                          :
                            lumTypeHybrid ?
                              <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToHybrid")}</Text>
                            :
                              lumTypeSolar ?
                                <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToSolar")}</Text>
                              :
                                lumTypeStandard ?
                                  <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToStandard")}</Text>
                                :
                                  <></>
                    }
                  </div>
                :
                  <div></div>
            }
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                background: "white",
                zIndex: 2
              }}
            />
          </div>
          {showDetails && 
            <Details showDevices={showDevices} goToDevices={goToDevices} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />}
          {showDevices && 
            <Devices showReview={showReview} goBetweenMapsAndToReview={goBetweenMapsAndToReview} showStandard={showStandard} showSolar={showSolar} showHybrid={showHybrid} showRgb={showRgb}
            showSmartec={showSmartec} showShuncom={showShuncom} errorMessage={errorMessage} setErrorMessage={setErrorMessage}
          />}
          {showReview && 
            <Review errorMessage={errorMessage} setErrorMessage={setErrorMessage} onHide={setShowModal} />
          }
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
