import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";
import { FilterByGroupReports } from "./FilterByGroupReports";
import { ReportsLeafletMap } from "../../ReportsMap/ReportsLeafletMap";
import { Review } from "../Step3Review/Review";
import { initialLampsGroup } from "../../../../utils/data";
import { CardContainer, NextButton } from "../ReportsStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface DevicesProps {
  showReview?: any;
  goBetweenMapsAndToReview?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
  showStandard?: boolean,
  showSolar?: boolean,
  showHybrid?: boolean,
  showRgb?: boolean,
  showSmartec?: boolean,
  showShuncom?: boolean,
  showSwitch?: boolean,
}

export const Devices = ({ showReview, goBetweenMapsAndToReview, errorMessage, setErrorMessage, showStandard, showSolar, showHybrid, showRgb, showSmartec, showShuncom,
    showSwitch }: DevicesProps) => {
  
  const [nodesGroup, setNodesGroup] = useState<Array<string>>(initialLampsGroup.group);

  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column", paddingLeft: "40px" }}>
      <div style={{ marginBottom: "16px" }}>
        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step2")}</Text>
        {showStandard ?
          <>
            <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("selectStandard")}</Text>
            <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("chooseStandard")}.</Text>
          </>
        :
          showSolar ?
            <>
              <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("selectSolar")}</Text>
              <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("chooseSolar")}.</Text>
            </>
          :
            showHybrid ?
              <>
                <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("selectHybrid")}</Text>
                <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("chooseHybrid")}.</Text>
              </>
            :
              showRgb ?
                <>
                  <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("selectRgb")}</Text>
                  <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("chooseRgb")}.</Text>
                </>
              :
                showSmartec ?
                  <>
                    <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("selectSmartec")}</Text>
                    <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("chooseSmartec")}.</Text>
                  </>
                :
                  showShuncom ?
                    <>
                      <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("selectShuncom")}</Text>
                      <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("chooseShuncom")}.</Text>
                    </>
                  :
                    showSwitch ?
                      <>
                        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("selectSwitch")}</Text>
                        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("chooseSwitch")}.</Text>
                      </>
                    :
                      <></>
        }
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ margin: "0 10px", height: "540px", width: "67%" }} className="position-relative mb-3">
          <ReportsLeafletMap isStandard={showStandard} isSolar={showSolar} isHybrid={showHybrid} isRgb={showRgb} isSmartec={showSmartec} isShuncom={showShuncom} isSwitch={showSwitch}
            showDevice={true} interactiveMap={true} fromGroupsPage={false} setErrorMessage={setErrorMessage}
          />
        </div>
        <div style={{ position: "relative", marginTop: "-30px", marginLeft: "-20px" }}>
          <FilterByGroupReports setNodesGroup={setNodesGroup} nodesGroup={nodesGroup}
            isStandard={showStandard} isSolar={showSolar} isHybrid={showHybrid} isRgb={showRgb} isSmartec={showSmartec} isShuncom={showShuncom} isSwitch={showSwitch}
          />
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "absolute", bottom: 30, left: "49%" }}>
        {errorMessage ?
          <Text marginBottom="0px" style={{display: "flex", justifyContent: "center", color: "red", marginTop: "57px"}}>{t("errorBlankInput")}</Text>  
        :
          <></>
        }
        <NextButton style={{ background: errorMessage ? accentButtonsColor+95 : accentButtonsColor, borderColor: errorMessage ? `1px solid ${accentButtonsColor+95}` : accentButtonsColor,
            justifySelf: "end", alignSelf: "center", width: "300px", marginTop: errorMessage ? "10px" : "57px", cursor: errorMessage ? "not-allowed" : "pointer" }}
          onClick={() => {
            if (!errorMessage) {
              goBetweenMapsAndToReview();
            }
          }}
        >
          {t("continue")}
        </NextButton>
        {showReview && <Review />}
        <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
          <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
          <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
          <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        </div>
      </div>
    </CardContainer>
  );
};
