import { z, object, string } from "zod";

export const userRegistrationSchema = (t:any) => object({
  first_name: string({
    required_error: ("First Name is required"),
  }).min(1, {
    message: t("errorFirstName"),
  })
    .refine(value => !/\d/.test(value), {
      message: t("errorFirstNameNumbers"),
    }),

  last_name: string().nullable() // Allow null as well as undefined
    .optional() // Mark as optional (not required)
    .refine(value => {
      if (value) {
        return !/\d/.test(value);
      }
      return true; // if value is null or undefined, it passes
    }, {
      message: t("errorLastNameNumbers"),
    }),
    
  phoneNumber: z.string().nullable().optional().refine(value => {
    // Check if value is null, undefined, or an empty string
    if (!value) return true;
    // Check if value is a string containing exactly 9 digits
    return /^\d{9}$/.test(value);
  }, {
    message: t("errorPhoneNumber"),
  }),

  email: string({
    required_error: ("Email is required"),
  }).min(1, {
    message: t("errorEmail"),
  }).email(t("errorEmailStructure")),

  password: z.string({
    required_error: "Password is required",
  }).min(8, {
    message: t("errorPasswordMin"),
  }).refine(value => /[A-Z]/.test(value), {
    message: t("errorPasswordUppercase"),
  }).refine(value => /\d/.test(value), {
    message: t("errorPasswordNum"),
  }),

  password_confirm: z.string({
    required_error: "Confirm Password is required",
  }).min(1, {
    message: t("errorConfirmPassword"),
  }).min(8, {
    message: t("errorConfirmPasswordMin"),
  })
}).refine(data => data.password === data.password_confirm, {
  message: t("errorPasswords"),
  path: ["password_confirm"],
});