import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import { CardContainerResponsePage } from "./ResponsePageStyled";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import { Text } from "../../styles/shared-styles/text-sizes";

export const NoActivePage = () => {
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  return (
    <CardContainerResponsePage>
      <div className="row text-center">
        <div className="col-12">
          <Text
            textType="title"
            color={accentButtonsColor}
            fontWeight="700"
            marginBottom="10px"
          >
            {t("inactiveUserTitle")}
          </Text>
          <Text textType="subTitle" color={accentButtonsColor} fontWeight="400" marginBottom="20px">
            {t("inactiveUserSubtitle")}
          </Text>
          <a href="/">
            <Button buttonType="primary" style={{ background: buttonsColor, color: accentTextColor }}>{t("goBackLogin")}</Button>
          </a>
        </div>
      </div>
    </CardContainerResponsePage>
  );
};
