import styled from "styled-components";

export const GroupSelectionPopupStyled = styled.section`
  position: absolute;
  z-index: 1;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);
  padding: 13px;
  border-radius: 14px;

  .popup {
    &__button-container {
      display: flex;
      justify-content: space-between;

      &__icon {
        font-size: 1.2rem;
        color: #686b6f;
        cursor: pointer;
      }
    }

    &__body-container {
      padding: 8px 16px;
    }

    &__title {
      font-weight: 600;
      font-size: 1.25rem;
      margin-bottom: 16px;
    }

    &__devices-selector-container {
      display: flex;
      width: 100%;
      gap: 14px;
      margin-bottom: 20px;
    }

    &__separator-container {
      display: flex;
      gap: 0;
      min-width: 100%;
      margin-bottom: 16px;
    }

    &__details-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
    }

    &__status-container {
      width: 100%;
      display: flex;
      gap: 15px;
      padding-left: 10px;
    }

    &__onoff-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #ffffff;
      border: 1px solid #c4c4c4;
      border-radius: 8px;
      height: 35px;
      padding: 0;
      margin-top: 5px;
    }

    &__link-container {
      cursor: pointer;
      position: absolute;
      margin-top: 1px;
      right: 50%;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 24px 10px 20px;
      gap: 4px;
      height: 45px;
      font-weight: 600;
      transform: translate(50%, 50%);
      color: #1b2559;
      box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
        0px 1px 3px rgba(0, 0, 0, 0.3);
    }

    &__separator {
      display: flex;
      width: 100%;
      border-top: 2px solid #d0cbd5;
      border-radius: 1px;
      margin: 24px 0;
    }

    &__bulk-actions-container {
      position: absolute;
      bottom: 15px; // esto, top
      right: -25px;
      width: 215px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.08),
          rgba(255, 255, 255, 0.08)
        ),
        #ffffff;
      box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15),
        0px 4px 4px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
      z-index: 9999;

      button {
        all: unset;
      }
    }
  }

  .devices-selector-container {
    &__device-selector {
      display: flex;
      align-items: center;
      padding: 4px 20px 4px 12px;
      gap: 10px;
      height: 30px;
      border-radius: 30px;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    &__device-selector--selected {
      color: white;
      background-color: #475569;
    }
  }

  .separator-container {
    &__start {
      display: flex;
      width: 15%;
      border-top: 2px solid #d0cbd5;
      border-radius: 1px;
    }

    &__icon {
      font-weight: lighter;
      color: #d0cbd5;
      display: flex;
      margin-left: -4px;
      margin-right: -4px;
      margin-top: -16px;
      transform: rotateZ(-90deg);
      font-size: 25px;
      border-radius: 1px;
    }

    &__end {
      display: flex;
      width: 85%;
      border-top: 2px solid #d0cbd5;
      border-radius: 1px;
    }
  }

  .status-container {
    &__icon {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: ${(props) => props.theme.smartecGreen};
      &--alarm {
        color: #cf2a2a;
        font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;
      color: ${(props) => props.theme.mainFontColor};
    }
  }

  .details-container {
    &__details {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 400;
      font-size: 14px;
    }

    &__icon {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 18px;
      padding-top: 2px;

      &--on {
        color: #87e897;
        font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
      }

      &--alarm {
        color: #cf2a2a;
        font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
      }
    }
  }

  .link-container {
    &__link {
      all: unset;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .bulk-actions-container {
    &__action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      isolation: isolate;
      padding-left: 15px;
      width: 100%;
      height: 56px;
      cursor: pointer;
    }

    &__action:hover {
      background-color: #1c1b1f11;
    }
  }

  .move {
    color: #bdbdbd;
    cursor: move;
  }
`;

export const GroupPopupHybridViewStyled = styled.section`
  position: absolute;
  z-index: 1;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);
  padding: 13px;
  padding-left: 18px;
  border-radius: 14px;

  .hybridpopup {
    &__block {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__title {
        width: 150px;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 0px;
        margin-right: -35px;
      }

      &__nodes {
        display: flex;
        align-items: center;
        margin-left: -35px;
        gap: 7px;

        &--icon {
          font-size: 18px;
        }

        &--text {
          font-size: 12px;
        }
      }

      &__close {
        align-self: flex-start;
        font-size: 18px;
        margin-top: -6px;
        cursor: pointer;
      }
    }

    &__bulk {
      &__icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        position: absolute;
        margin-top: -14px;
        right: 20%;
        border-radius: 40px;
        padding: 10px;
        height: 45px;
        font-weight: 600;
        transform: translate(50%, 15%);
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
          0px 1px 3px rgba(0, 0, 0, 0.3);
      }

      &__container {
        position: absolute;
        top: 50px;
        right: 80px;
        width: 215px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.08),
            rgba(255, 255, 255, 0.08)
          ),
          #ffffff;
        box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15),
          0px 4px 4px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        z-index: 9999;

        &--action {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          isolation: isolate;
          padding-left: 15px;
          width: 100%;
          height: 56px;
          cursor: pointer;
        }

        &--action:hover {
          background-color: #1c1b1f11;
        }
      }
    }
  }
`;
