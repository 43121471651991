import styled from "styled-components";

export const GatewayListingWrapper = styled.div`
  max-height: 440px;
  .luminaires-listings {
    width: 410px;
    overflow-y: auto;
  }
  .listing-header {
    position: sticky;
    top: 0;
    background: #ffffff;
    padding: 20px 0 10px 0;
    z-index: 2;
  }
`;

export const GatewayRoadWrapper = styled.div`
  .road-item {
    background-color: #e8f3fc;
    padding: 3px 8px;
    border-radius: 2px;
    width: 30%;
    font-size: 14px;
  }
`;
