import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { registerUserActionCreator } from "../../../../redux/slices/userRegistrationSlice/userRegistrationSlice";
import { items } from "../../../../utils/languages/changeLanguage";
import { useRoleMapping } from "../../../../utils/roleMapping";
import { IconStyled } from "../../../Header/HeaderStyled";
import { CardContainer, NextButton } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import languageIcon from "../../../../assets/new_icons/Language.svg";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface RolesProps {
  goToOrgs?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
}

export const Roles = ({ goToOrgs, errorMessage, setErrorMessage }: RolesProps) => {
  const dispatch = useAppDispatch();
  const roleMapping:any = useRoleMapping();
  const { Option } = Select;
  const user = useAppSelector((state) => state.userRegistration.user);
  const [selectedRole, setSelectedRole] = useState(user?.role_id ?? 1);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const [languageName, setLanguageName] = useState<null | string>();
  const [language, setLanguage] = useState<null | string>();
  const { t } = useTranslation();

  const handleRoleClick = (role: string) => {
    const roleId = roleMapping[role];
    setSelectedRole(roleId);
  };

  const languageOnChange = (value: string, item: any ) => {
    setLanguageName(value);
    setLanguage(item.item);
  };

  const handleRolesInfo = (e: any) => {
    try {
      dispatch(registerUserActionCreator({ role_id: selectedRole, user_language: language }))
      goToOrgs();
    } catch (error) {
      console.log(error)
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    dispatch(registerUserActionCreator({ role_id: selectedRole, user_language: language }))

    if (selectedRole || language) {
      setErrorMessage(false)
    }
  }, [selectedRole, language]);

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: "47px", paddingLeft: "40px" }}>
        <Text fontWeight="500" marginBottom="4px">{t("step2")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("roleAndLanguage")}</Text>
        <Text fontWeight="400" style={{fontSize: "12px"}}>{t("roleAndLanguageSubtitleUsers")}</Text>
        <div style={{ marginTop: "50px", marginBottom: selectedRole ? "56px" : "60px" }}>
          <Text color={mainTextColor} fontWeight="500" style={{ marginBottom: "4px", fontSize: "14px" }}>{t("newUserRole")}</Text>
          <Text style={{ marginBottom: "40px", fontSize: "12px" }}>{t("newUserRoleSubtitle")} <strong>{t("newUserRoleSubtitleBold")}</strong>.</Text>      
          <div className="mb-5" style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {Object.keys(roleMapping).map((role) => (
              <div key={role} className={`card px-3 py-2 `} onClick={() => handleRoleClick(role)}
                style={{ border: selectedRole === roleMapping[role] ? `2px solid ${accentButtonsColor}` : 'none', borderRadius: "10px", cursor: "pointer", boxShadow: "0 3px 6px #d6d5d1" }}
              >
                <Text fontWeight="600" style={{ fontSize: "14px" }}>{role}</Text>
              </div>
            ))}
          </div>
        </div>
        <div>
          <Text color={mainTextColor} fontWeight="500" style={{ marginBottom: "4px", fontSize: "14px" }}>{t("userLanguage")}</Text>
          <Text style={{ marginBottom: "40px", fontSize: "12px" }}>{t("userLanguageSubtitle")} <strong>{t("userLanguageSubtitleBold")}</strong>.</Text>   
          <div style={{ width: "27%", paddingRight: "12px", position: "relative" }}>
            <IconStyled src={languageIcon} style={{ position: "absolute", left: "3px", top: "50%", transform: "translateY(-50%)", zIndex: 1, opacity: 0.7 }} />
            <Select
              style={{ width: "100%" }}
              optionFilterProp="children"
              defaultValue={user?.language_name ?? ''}
              filterOption={filterOption}
              //onChange={languageOnChange}
              onChange={(value, item) => languageOnChange(value, item)}
              value={(() => {
                const selectedItem = items.find(item => item.id === user.user_language);
                return selectedItem ? t(selectedItem.label) : "";
              })() || t("selectLanguage")}
              className="my-select"
              popupClassName="custom-select-popup-roles"
            >
              {items.map((item, index) => (
                <Option key={index} value={item.label} item={item.id}>
                  {t(item.label)}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      {errorMessage ?
        <Text marginBottom="0px" color="red" style={{ display: "flex", justifySelf: "end", alignSelf: "center", marginTop: "189px" }}>{t("errorBlankInput")}</Text>  
      :
        <></>
      }
      <NextButton accentButtonsColor={accentButtonsColor} style={{ justifySelf: "end", alignSelf: "center", width: "340px", marginTop: errorMessage ? "16px": "226px" }} onClick={handleRolesInfo}>{t("continue")}</NextButton>
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        {/* <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} /> */}
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
      </div>
    </CardContainer>
  );
};
