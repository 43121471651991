import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { Text } from "../../../styles/shared-styles/text-sizes";
import activePower from "../../../assets/new_icons/Active-power.svg";
import co2 from "../../../assets/new_icons/Co2.svg";
import currency from "../../../assets/new_icons/Currency.svg";
import energyEco from "../../../assets/new_icons/Energy_eco.svg";
import tree from "../../../assets/new_icons/Arbol.svg";
import treeTwo from "../../../assets/new_icons/Arbol 2.svg";

export const ConsumptionsCard = () => {
  const nodes = useAppSelector((state) => state.devices.nodes);
  const [standard, setStandard] = useState(true);
  const [solar, setSolar] = useState(false);
  const [hybrid, setHybrid] = useState(false);
  const [gateways, setGateways] = useState(false);
  const [switchboard, setSwitchboard] = useState(false);
  const [rgb, setRgb] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const reportsInfo = useAppSelector((state) => state.reports);
  const orgInfo: any = useAppSelector((state) => state.selectedOrganization);

  let consumInfo = {
    activePower: "3",
    energyConsumed: standard ? reportsInfo.standardEnergyConsumed : "",
    energySaved: standard ? reportsInfo.standardEnergySaved : "",
  };

  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const { t } = useTranslation();

  const buttons = [
    reportsInfo.generalStandard && { label: t("standardLuminaires"), type: "standard" },
    reportsInfo.generalSolar && { label: t("solarLuminaires"), type: "solar" },
    reportsInfo.generalHybrid && { label: t("hybridLuminaires"), type: "hybrid" },
    reportsInfo.generalGateways && { label: t("Gateways"), type: "gateways" },
    reportsInfo.generalSwitchboards && { label: t("switchboards"), type: "switchboard" },
    reportsInfo.generalRgb && { label: t("rgbLuminaires"), type: "rgb" }
  ].filter(Boolean);

  const isActive = (type:string) => {
    switch (type) {
      case "standard":
        return standard;
      case "solar":
        return solar;
      case "hybrid":
        return hybrid;
      case "gateways":
        return gateways;
      case "switchboard":
        return switchboard;
      case "rgb":
        return rgb;
      default:
        return false;
    }
  }

  useEffect(() => {
    if (firstRender && nodes.length > 0 && nodes.every((node: any) => node.solar_id !== null)) {
      setSolar(true);
      setFirstRender(false);
    }
  }, [nodes]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        padding: "16px 24px 16px 0px",
        marginTop: "20px",
        border: "none",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        flexGrow: 1
      }}
    >
      <Text style={{ fontWeight: 500 }}>{t("consumsAverage")}</Text>
      <div>
        {buttons.map((button, index) => (
          <button
            key={button.type}
            onClick={() => {
              setStandard(button.type === "standard");
              setSolar(button.type === "solar");
              setHybrid(button.type === "hybrid");
              setGateways(button.type === "gateways");
              setSwitchboard(button.type === "switchboard");
              setRgb(button.type === "rgb");
            }}
            disabled={button.type !== "standard"}
            style={{
              background: isActive(button.type) ? accentButtonsColor : "transparent",
              color: isActive(button.type) ? "#ffffff" : "#99B2C6",
              border: "1px solid lightgray",
              borderRight: index === buttons.length - 1 ? "1px solid lightgray" : "none",
              padding: "10px 12px",
              fontSize: "12px",
              borderRadius: index === 0 ? "7px 0 0 7px" : index === buttons.length - 1 ? "0 7px 7px 0" : "0",
              fontWeight: button.type ? 700 : 500,
              cursor: button.type === "standard" ? "pointer" : "not-allowed"
            }}
          >
            {button.label}
          </button>
        ))}
        {/* {reportsInfo.generalStandard &&
          <button
            onClick={() => {setStandard(true); setSolar(false); setHybrid(false); setGateways(false); setSwitchboard(false); setRgb(false)}}
            style={{ background: standard ? accentButtonsColor : "transparent", color: standard ? "#ffffff" : "#99B2C6", border: "1px solid lightgray", borderRight: "none",
              padding: "10px 12px", borderRadius: "7px 0 0 7px", fontSize: "12px", fontWeight: standard ? 700 : 500
            }}
          >
            {t("standardLuminaires")}
          </button>
        }
        {reportsInfo.generalSolar &&
          <button
            onClick={() => {setStandard(false); setSolar(true); setHybrid(false); setGateways(false); setSwitchboard(false); setRgb(false)}}
            style={{ background: solar ? accentButtonsColor : "transparent", color: solar ? "#ffffff" : "#99B2C6", border: "1px solid lightgray", borderRight: "none", padding: "10px 12px",
              fontSize: "12px", borderRadius: "0px", fontWeight: solar ? 700 : 500
            }}
          >
            {t("solarLuminaires")}
          </button>
        }
        {reportsInfo.generalHybrid &&
          <button
            onClick={() => {setStandard(false); setSolar(false); setHybrid(true); setGateways(false); setSwitchboard(false); setRgb(false)}}
            style={{ background: hybrid ? accentButtonsColor : "transparent", color: hybrid ? "#ffffff" : "#99B2C6", border: "1px solid lightgray", borderRight: "none", padding: "10px 12px",
              fontSize: "12px", borderRadius: "0px", fontWeight: hybrid ? 700 : 500
            }}
          >
            {t("hybridLuminaires")}
          </button>
        }
        {reportsInfo.generalGateways &&
          <button
          onClick={() => {setStandard(false); setSolar(false); setHybrid(false); setGateways(true); setSwitchboard(false); setRgb(false)}}
            style={{ background: gateways ? accentButtonsColor : "transparent", color: gateways ? "#ffffff" : "#99B2C6", border: "1px solid lightgray", borderRight: "none",
              padding: "10px 12px", fontSize: "12px", borderRadius: "0px", fontWeight: gateways ? 700 : 500
            }}
          >
            {t("Gateways")}
          </button>
        }
        {reportsInfo.generalSwitchboards &&
          <button
            onClick={() => {setStandard(false); setSolar(false); setHybrid(false); setGateways(false); setSwitchboard(true); setRgb(false)}}
            style={{background: switchboard ? accentButtonsColor : "transparent", color: switchboard ? "#ffffff" : "#99B2C6", border: "1px solid lightgray", borderRight: "none",
              padding: "10px 12px", fontSize: "12px", borderRadius: "0px", fontWeight: switchboard ? 700 : 500
            }}
          >
            {t("switchboards")}
          </button>
        }
        {reportsInfo.generalRgb &&
          <button
            onClick={() => {setStandard(false); setSolar(false); setHybrid(false); setGateways(false); setSwitchboard(false); setRgb(true)}}
            style={{ background: rgb ? accentButtonsColor : "transparent", color: rgb ? "#ffffff" : "#99B2C6", border: "1px solid lightgray", padding: "10px 12px", fontSize: "12px",
              borderRadius: "0 7px 7px 0", fontWeight: rgb ? 700 : 500
            }}
          >
            {t("rgbLuminaires")}
          </button>
        } */}
      </div>
      <div style={{ display: "flex", gap: "30px", flexWrap: "wrap", width: "fit-content" }}>
        {/* <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px", margin: "0px" }}
        >
          <Text fontWeight="700" style={{ display: "flex", alignSelf: "start", marginTop: "12px", fontSize: "18px" }}>{t("activePowerAbr")}</Text>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <Text marginBottom="0px" fontWeight="700" style={{ fontSize: "28px" }}>
                {consumInfo && consumInfo.activePower !== "0.00" && consumInfo.activePower !== undefined ? consumInfo.activePower : 0}
              </Text>
              <Text marginBottom="0px">{t("average")}</Text>
            </div>
            <img src={activePower} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
          </div>
        </div> */}

        <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px" }}
        >
          <Text fontWeight="700" style={{ display: "flex", alignSelf: "start", marginTop: "12px", marginBottom: "6px", fontSize: "18px" }}>{t("energyAbr")}</Text>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
            <Text marginBottom="0px" fontWeight="700" color={supportTextColor} style={{ fontSize: "28px" }}>
              {consumInfo && consumInfo.energyConsumed !== 0 && consumInfo.energyConsumed !== undefined ? (consumInfo.energyConsumed / 1000)?.toFixed(2) : 0}
            </Text>
            <Text marginBottom="0px" color={supportTextColor}>{t("consumed")}</Text>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>
                {consumInfo && consumInfo.energySaved !== 0 && consumInfo.energySaved !== undefined ? (consumInfo.energySaved / 1000)?.toFixed(2) : 0}
              </Text>
              <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
            </div>
            <img src={energyEco} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
          </div>
        </div>

        <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px" }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
            <Text fontWeight="700" style={{ fontSize: "18px" }}>{t("cost")} (-)</Text>
            <Text fontWeight="400" style={{ fontSize: "12px" }}>{orgInfo.price} / kW</Text>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
            <Text marginBottom="0px" fontWeight="700" color={supportTextColor} style={{ fontSize: "28px" }}>
              {consumInfo && consumInfo.energyConsumed !== 0 && consumInfo.energyConsumed !== undefined ? (consumInfo.energyConsumed / 1000 * orgInfo.price).toFixed(2) : 0}
            </Text>
            <Text marginBottom="0px" color={supportTextColor}>{t("consumed")}</Text>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>
                {consumInfo && consumInfo.energySaved !== 0 && consumInfo.energySaved !== undefined ? (consumInfo.energySaved / 1000 * orgInfo.price).toFixed(2) : 0}
              </Text>
              <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
            </div>
            <img src={currency} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
          </div>
        </div>

        <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px" }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
            <Text fontWeight="700" style={{fontSize: "18px" }}>{t("co2Abr")}</Text>
            <Text fontWeight="400" style={{ fontSize: "12px" }}>{orgInfo.co2} / Kg</Text>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
            <Text marginBottom="0px" fontWeight="700" color={supportTextColor} style={{ fontSize: "28px" }}>
              {consumInfo && consumInfo.energyConsumed !== 0 && consumInfo.energyConsumed !== undefined ? (consumInfo.energyConsumed / 1000 * orgInfo.co2).toFixed(2) : 0}
            </Text>
            <Text marginBottom="0px" color={supportTextColor}>{t("consumed")}</Text>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>
                {consumInfo && consumInfo.energySaved !== 0 && consumInfo.energySaved !== undefined ? (consumInfo.energySaved / 1000 * orgInfo.co2).toFixed(2) : 0}
              </Text>
              <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
            </div>
            <img src={co2} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
          </div>
        </div>

        <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px" }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
            <Text fontWeight="700" style={{fontSize: "18px" }}>{t("treesEquiv")}</Text>
            <Text fontWeight="400" style={{ fontSize: "12px" }}>22Kg {t("co2Year")}</Text>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
            <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>
              {consumInfo && consumInfo.energySaved !== 0 && consumInfo.energySaved !== undefined ? (consumInfo.energySaved / 1000 * orgInfo.co2 / 22).toFixed(2) : 0}
            </Text>
            <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
          </div>
          <img src={treeTwo} style={{ width: "60px", height: "60px", alignSelf: "end" }} />
        </div>
      </div>
    </div>
  );
};
