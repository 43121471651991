import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NodeLumimairePage } from "../../../types/interfaces";

const initialState: NodeLumimairePage = {
  batt_level: 0,
  batt_status: "",
  sp_power: "",
  batt_voltage: "",
  dc_load_power: 0,
  date: "",
  lifetime: "",
  humanTime: "",
  energy_lifetime: "",
  case_name: "",
  creation_date: "",
  strategy_id: "",
  device_type: "",
  bri: 0,
  gateway_id: "",
  hops: 0,
  on: "",
  latitude: "",
  longitude: "",
  manufacturer_name: "",
  active: "",
  node_id: "",
  node_name: "",
  num_neighbours: 0,
  protocol_name: "",
  rgb: "",
  R: 0,
  G: 0,
  B: 0,
  rssi: 0,
  solar_id: null,
  traveltime: 0,
  update_date: "",
  online: "",
};

const manageNodeSlice = createSlice({
  name: "node",
  initialState: initialState,
  reducers: {
    saveNode: (previousData, action: PayloadAction<NodeLumimairePage>) => ({
      ...action.payload,
    }),
    
    updateStrategyId: (previousData, action: PayloadAction<NodeLumimairePage>) => ({
      ...previousData,
      strategy_id: action.payload.strategy_id,
    }),
    
    updateSolarConsums: (previousData:any, action: PayloadAction<NodeLumimairePage>) => ({
      ...previousData,
      batt_level: action.payload.batt_level,
      batt_status: action.payload.batt_status,
      sp_power: action.payload.sp_power,
      batt_voltage: action.payload.batt_voltage,
      dc_load_power: action.payload.dc_load_power,
      date: action.payload.date
    }),
    
    updateNodeStatusRedux: (state, action) => {
      // Check if the payload is defined and the node_id matches     
      if (action.payload && state.node_id === action.payload.node_id) {
        const values = action.payload.msg_type === 40 && action.payload.value.split(', ');
        const R = values && parseInt(values[0]);
        const G = values && parseInt(values[1]);
        const B = values && parseInt(values[2]);

        type MsgType = 1 | 2 | 3 | 4 | 40 | 41 | 300;
        const statusUpdates = {
          1: { on: "1", online: "1" }, // Node is on and online
          2: { on: "0", online: "1" }, // Node is off but online
          3: { on: "1", online: "1", bri: action.payload.value }, // Node is on, online, and brightness is updated
          4: { on: "0", online: "0" }, // Node is off and offline
          40: { on: "1", online: "1", R: R, G: G, B: B },
          41: { on: "0", online: "1" },
          300: { latitude: action.payload.value.lat, longitude: action.payload.value.lon },
        };

        // Apply the update based on msg_type, if it exists in the mapping
        const update = statusUpdates[action.payload.msg_type as MsgType];
        if (update) {
          return { ...state, ...update };
        }
      }
    },
  },
});

export const manageNodeReducer = manageNodeSlice.reducer;

export const {
  saveNode: saveNodeActionCreator,
  updateStrategyId: updateStrategyIdActionCreator,
  updateSolarConsums: updateSolarConsumsActionCreator,
  updateNodeStatusRedux: updateNodeStatusReduxActionCreator,
} = manageNodeSlice.actions;
