import styled from "styled-components";
import { BoxSizes } from "../../styles/shared-styles/box-sizes";

export const Text = styled.p`
  font-size: ${(props: {
    textType?: "title" | "subTitle" | "cardTitle" | "caption" | "base" | "";
    fontWeight?: string;
    color?: string;
    marginBottom?: string;
  }) =>
    props.textType === "title"
      ? "28px"
      : props.textType === "cardTitle"
      ? "24px"
      : props.textType === "subTitle"
      ? "18px"
      : props.textType === "base"
      ? "16px"
      : props.textType === "caption"
      ? "10px"
      : "14px"};
  color: ${(props) => props.color || "#475569"};
  font-weight: ${(props) => props.fontWeight || 400};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
`;

export const TextSpan = styled.span`
  font-size: ${(props: {
    textType?:
      | "title"
      | "subTitle"
      | "caption"
      | "date"
      | "alarm"
      | "Filtercaption"
      | "alarmDesc"
      | "";
    fontWeight?: string;
    color?: string;
    marginTop?: string;
    marginBottom?: string;
    display?: string;
  }) =>
    props.textType === "title"
      ? "28px"
      : props.textType === "subTitle"
      ? "18px"
      : props.textType === "caption"
      ? "10px"
      : props.textType === "date"
      ? "24px"
      : props.textType === "alarm"
      ? "16px"
      : props.textType === "Filtercaption"
      ? "12px"
      : "14px"};
  color: ${(props) => props.color || "#475569"};
  display: ${(props) =>
    props?.display
      ? props?.display
      : props.textType === "Filtercaption" || props.textType === "alarmDesc"
      ? "block"
      : ""};
  font-weight: ${(props) => props.fontWeight || 400};
  margin-bottom: ${(props) => props.marginBottom || ""};
  margin-top: ${(props) => props.marginTop || ""};
  text-align: ${(props) => (props.textType === "Filtercaption" ? "left" : "")};
  width: ${(props) => (props.textType === "alarmDesc" ? "600px" : "")};
`;

export const TextBox = styled(BoxSizes)`
  font-size: ${(props: {
    textType?: "title" | "subTitle" | "caption" | "";
    fontWeight?: string;
    color?: string;
    marginBottom?: string;
  }) =>
    props.textType === "title"
      ? "28px"
      : props.textType === "subTitle"
      ? "18px"
      : props.textType === "caption"
      ? "10px"
      : "14px"};
  color: ${(props) => props.color || "#475569"};
  font-weight: ${(props) => props.fontWeight || 400};
  margin-bottom: ${(props) => props.marginBottom || ""};
`;
