import { createSlice } from "@reduxjs/toolkit";
import { generateRandomHexCode } from "../../../utils/hexGenerator";
import { IUserRegistrationState } from "../../../types/interfaces";

const initialState: IUserRegistrationState = {
  user: {
    first_name: "",
    last_name: "",
    phoneNumber: "",
    email: "",
    password: "",
    password_confirm: "",
    user_language: "",
    role_id: "",
    organization_id: "",
    service_id: "",
    color: ""
  },
  organizationPermits: [],
  servicePermits: []
};

const userRegistrationSlice: any = createSlice({
  name: "userRegistration",
  initialState,
  reducers: {
    /* registerUser: (state, action) => {
      state.user.color = generateRandomHexCode();
      const payloadKeys = Object.keys(action.payload);
      // Iterate over keys in action.payload
      payloadKeys.forEach((key: any) => {
        // If the key exists in state.user, replace its value with the new value
        if (state.user.hasOwnProperty(key)) {
        // @ts-ignore
        state.user[key] = action.payload[key];
        } else {
          // If the key does not exist in state.user, add it to the user object
          state.user = { ...state.user, [key]: action.payload[key] };
        }
      });
    }, */
    
    registerUser: (previousData, action) => ({
      ...previousData,
      user: {
        ...previousData.user,
        ...action.payload,
        user_language: action.payload.user_language || previousData.user.user_language,
        role_id: action.payload.role_id || previousData.user.role_id,
        organization_id: action.payload.organization_id || previousData.user.organization_id,
        service_id: action.payload.service_id || previousData.user.service_id,
        color: previousData.user.color || generateRandomHexCode(),
      }
    }),

    setOrganizationPermits: (previousData, action) => ({
      ...previousData,
      organizationPermits: action.payload
    }),

    setServicePermits: (previousData, action) => ({
      ...previousData,
      servicePermits: action.payload
    }),

    resetRegisterUserInfo: (previousData) => ({
      ...previousData,
      user: {
        first_name: "",
        last_name: "",
        phoneNumber: "",
        email: "",
        password: "",
        password_confirm: "",
        user_language: "",
        role_id: "",
        organization_id: "",
        service_id: "",
        color: "",
      },
      organizationPermits: "",
      servicePermits: ""
    }),
  }
})

export const userRegistrationReducer = userRegistrationSlice.reducer;

export const {
  registerUser: registerUserActionCreator,
  setOrganizationPermits: setOrganizationPermitsActionCreator,
  setServicePermits: setServicePermitsActionCreator,
  resetRegisterUserInfo: resetRegisterUserInfoActionCreator
} = userRegistrationSlice.actions;
