import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PdfReport } from "../../../types/interfaces";

const initialState: PdfReport = {
  startDate: "",
  endDate: "",
  deviceLum: true,
  deviceGate: false,
  deviceSwitch: false,
  lumTypeStandard: true,
  lumTypeSolar: false,
  lumTypeHybrid: false,
  lumTypeRgb: false,
  gateTypeSmartec: false,
  gateTypeShuncom: false,
  selectedDevicesStandard: [],
  selectedDevicesSolar: [],
  selectedDevicesHybrid: [],
  selectedDevicesRgb: [],
  selectedDevicesSmartec: [],
  selectedDevicesShuncom: [],
  selectedDevicesSwitch: [],
  imgDevices: "",
  imgAlarms: "",
  imgUsers: "",
  resAlarmsPending: 0,
  resAlarmsSolved: 0,
  resAlarmsTotal: 0,
  resStandardTotalEnergy: "",
  resStandardTotalSavings: "",
};

const pdfReportSlice = createSlice({
  name: "node",
  initialState: initialState,
  reducers: {
    saveStartDate: (previousState, action) => ({
      ...previousState,
      startDate: action.payload
    }),

   saveEndDate: (previousState, action) => ({
      ...previousState,
      endDate: action.payload
    }),

    changeDeviceLum: (previousState) => ({
      ...previousState,
      deviceLum: !previousState.deviceLum
    }),

    changeDeviceGate: (previousState) => ({
      ...previousState,
      deviceGate: !previousState.deviceGate
    }),

    changeDeviceSwitch: (previousState) => ({
      ...previousState,
      deviceSwitch: !previousState.deviceSwitch
    }),

    changeLumTypeStandard: (previousState) => ({
      ...previousState,
      lumTypeStandard: !previousState.lumTypeStandard
    }),

    changeLumTypeSolar: (previousState) => ({
      ...previousState,
      lumTypeSolar: !previousState.lumTypeSolar
    }),

    changeLumTypeHybrid: (previousState) => ({
      ...previousState,
      lumTypeHybrid: !previousState.lumTypeHybrid
    }),

    changeLumTypeRgb: (previousState) => ({
      ...previousState,
      lumTypeRgb: !previousState.lumTypeRgb
    }),

    resetLumTypes: (previousState) => ({
      ...previousState,
      lumTypeStandard: false,
      lumTypeSolar: false,
      lumTypeHybrid: false,
      lumTypeRgb: false
    }),

    changeGateTypeSmartec: (previousState) => ({
      ...previousState,
      gateTypeSmartec: !previousState.gateTypeSmartec
    }),

    changeGateTypeShuncom: (previousState) => ({
      ...previousState,
      gateTypeShuncom: !previousState.gateTypeShuncom
    }),

    resetGateTypes: (previousState) => ({
      ...previousState,
      gateTypeSmartec: false,
      gateTypeShuncom: false
    }),

    setSelectedDevicesStandardList: (previousState, action) => ({
      ...previousState,
      selectedDevicesStandard: action.payload.selectedDevicesStandard
    }),

    setSelectedDevicesSolarList: (previousState, action) => ({
      ...previousState,
      selectedDevicesSolar: action.payload.selectedDevicesSolar
    }),

    setSelectedDevicesHybridList: (previousState, action) => ({
      ...previousState,
      selectedDevicesHybrid: action.payload.selectedDevicesHybrid
    }),

    setSelectedDevicesRgbList: (previousState, action) => ({
      ...previousState,
      selectedDevicesRgb: action.payload.selectedDevicesRgb
    }),

    resetSelectedStandard: (previousState) => ({
      ...previousState,
      selectedDevicesStandard: []
    }),

    resetSelectedSolar: (previousState) => ({
      ...previousState,
      selectedDevicesSolar: []
    }),

    resetSelectedHybrid: (previousState) => ({
      ...previousState,
      selectedDevicesHybrid: []
    }),

    resetSelectedRgb: (previousState) => ({
      ...previousState,
      selectedDevicesRgb: []
    }),

    setSelectedDevicesSmartecList: (previousState, action) => ({
      ...previousState,
      selectedDevicesSmartec: action.payload.selectedDevicesSmartec
    }),

    setSelectedDevicesShuncomList: (previousState, action) => ({
      ...previousState,
      selectedDevicesShuncom: action.payload.selectedDevicesShuncom
    }),

    resetSelectedSmartec: (previousState) => ({
      ...previousState,
      selectedDevicesSmartec: []
    }),

    resetSelectedShuncom: (previousState) => ({
      ...previousState,
      selectedDevicesShuncom: []
    }),

    setSelectedDevicesSwitchList: (previousState, action) => ({
      ...previousState,
      selectedDevicesSwitch: action.payload.selectedDevicesSwitch
    }),

    resetSelectedSwitch: (previousState) => ({
      ...previousState,
      selectedDevicesSwitch: []
    }),

    saveImageDevices: (previousState, action: any) => ({
      ...previousState,
      imgDevices: action.payload
    }),

    saveImageAlarms: (previousState, action: any) => ({
      ...previousState,
      imgAlarms: action.payload
    }),

    saveImageUsers: (previousState, action: any) => ({
      ...previousState,
      imgUsers: action.payload
    }),

    fillReportInfo: (previousState, action: PayloadAction<PdfReport>) => ({
      ...previousState,
      resAlarmsPending: action.payload.resAlarmsPending,
      resAlarmsSolved: action.payload.resAlarmsSolved,
      resAlarmsTotal: action.payload.resAlarmsTotal,
      resStandardTotalEnergy: action.payload.resStandardTotalEnergy,
      resStandardTotalSavings: action.payload.resStandardTotalSavings
    }),

    resetPdfInfo: (previousState) => ({
      ...previousState,
      startDate: "",
      endDate: "",
      deviceLum: true,
      deviceGate: false,
      deviceSwitch: false,
      lumTypeStandard: true,
      lumTypeSolar: false,
      lumTypeHybrid: false,
      lumTypeRgb: false,
      gateTypeSmartec: false,
      gateTypeShuncom: false,
      selectedDevicesStandard: [],
      selectedDevicesSolar: [],
      selectedDevicesHybrid: [],
      selectedDevicesRgb: [],
      selectedDevicesSmartec: [],
      selectedDevicesShuncom: [],
      selectedDevicesSwitch: [],
      imgDevices: "",
      imgAlarms: "",
      imgUsers: "",
      resAlarmsPending: 0,
      resAlarmsSolved: 0,
      resAlarmsTotal: 0,
      resStandardTotalEnergy: "",
      resStandardTotalSavings: "",
    }),
  },
});

export const pdfReportReducer = pdfReportSlice.reducer;

export const {
  saveStartDate: saveStartDateActionCreator,
  saveEndDate: saveEndDateActionCreator,
  changeDeviceLum: changeDeviceLumActionCreator,
  changeDeviceGate: changeDeviceGateActionCreator,
  changeDeviceSwitch: changeDeviceSwitchActionCreator,
  changeLumTypeStandard: changeLumTypeStandardActionCreator,
  changeLumTypeSolar: changeLumTypeSolarActionCreator,
  changeLumTypeHybrid: changeLumTypeHybridActionCreator,
  changeLumTypeRgb: changeLumTypeRgbActionCreator,
  resetLumTypes: resetLumTypesActionCreator,
  changeGateTypeSmartec: changeGateTypeSmartecActionCreator,
  changeGateTypeShuncom: changeGateTypeShuncomActionCreator,
  resetGateTypes: resetGateTypesActionCreator,
  setSelectedDevicesStandardList: setSelectedDevicesStandardListActionCreator,
  setSelectedDevicesSolarList: setSelectedDevicesSolarListActionCreator,
  setSelectedDevicesHybridList: setSelectedDevicesHybridListActionCreator,
  setSelectedDevicesRgbList: setSelectedDevicesRgbListActionCreator,
  resetSelectedStandard: resetSelectedStandardActionCreator,
  resetSelectedSolar: resetSelectedSolarActionCreator,
  resetSelectedHybrid: resetSelectedHybridActionCreator,
  resetSelectedRgb: resetSelectedRgbActionCreator,
  setSelectedDevicesSmartecList: setSelectedDevicesSmartecListActionCreator,
  setSelectedDevicesShuncomList: setSelectedDevicesShuncomListActionCreator,
  resetSelectedSmartec: resetSelectedSmartecActionCreator,
  resetSelectedShuncom: resetSelectedShuncomActionCreator,
  setSelectedDevicesSwitchList: setSelectedDevicesSwitchListActionCreator,
  resetSelectedSwitch: resetSelectedSwitchActionCreator,
  saveImageDevices: saveImageDevicesActionCreator,
  saveImageAlarms: saveImageAlarmsActionCreator,
  saveImageUsers: saveImageUsersActionCreator,
  fillReportInfo: fillReportInfoActionCreator,
  resetPdfInfo: resetPdfInfoActionCreator,
} = pdfReportSlice.actions;
