import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { store } from "../../redux/store";
import { getGroupsAction } from "../../redux/actions/groupsActions/groupsActions";
import { fetchUserProfileAction } from "../../redux/actions/profileActions/profileActions";
import { setFiltersActionCreator } from "../../redux/slices/actualFilterSlice/actualFilterSlice";
import { turnAllFalseActionCreator } from "../../redux/slices/graphicsSlice/graphicsSlice";
import { saveSelectedStrategyActionCreator } from "../../redux/slices/strategiesSlice/strategiesSlice";
import { setFirstRenderMapToTrueActionCreator, setIsOpenedDetailActionCreator, setIsOpenedGroupActionCreator, setIsFullViewActionCreator, turnRgbFalseActionCreator,
  setSelectedNodeIdActionCreator, setNewNodeLatLongActionCreator, setNodesInAreaActionCreator } from "../../redux/slices/uiSlice/uiSlice";
import authService from "../../services/authService";
import { useGroupsService } from "../../services/groupsService";
import { useLightingService } from "../../services/lightingService";
import mqttManagerService from '../../services/mqttManagerService';
import { useProfileService } from "../../services/profileService";
import { useOrganizationsService } from "../../services/selectedOrganizationService";
import InputForm from "../../components/Input/Input";
import { Container, LoginOverlay, LoginForm, LoginOverlayWhite, ButtonLogin, ImageLogo, LoginOverlayBlue } from "./LoginStyled";
import { TextBox, TextSpan } from "../../styles/shared-styles/text-sizes";
import { ButtonLoading } from "../../styles/shared-styles/buttons-sizes";
import balady from "../../assets/new_icons/balady.svg";
import mail from "../../assets/new_icons/mail.svg";
import locker_icon from "../../assets/new_icons/lock.svg";
import logo from "../../assets/new_icons/smartec-logo.svg";
import SmartecFullLogo from "../../assets/new_icons/Smartec-Full-Logo.svg"
import SmartecNewLogo from "../../assets/new_icons/Smartec-Icon-Logo.svg"
import SmartecNewFullLogo from "../../assets/new_icons/Smartec-Full-Logo.svg"

export const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [invalid, setInvalid] = useState(false);
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getUserProfile } = useProfileService();
  const { getAllNodes }: any = useLightingService();
  const { getSelectedOrganizationInfo }: any = useOrganizationsService();
  const { getGroups } = useGroupsService();
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  const checkEnter = (e: any) => {
    if (e.charCode === 13) {
      handleLogin();
    }
  };

  const backToInitialState = () => {
    dispatch(turnRgbFalseActionCreator());
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
    dispatch(turnAllFalseActionCreator());
    dispatch(setNodesInAreaActionCreator(false));
    dispatch(
      setFiltersActionCreator({
        all: true,
        area: false,
        on: false,
        off: false,
        disconnected: false,
        alarms: false,
        solar: false
      })
    );
    dispatch(setIsFullViewActionCreator());
    dispatch(setFirstRenderMapToTrueActionCreator());
    dispatch(setSelectedNodeIdActionCreator(""));
    dispatch(setNewNodeLatLongActionCreator(""));
    dispatch(saveSelectedStrategyActionCreator({ selectedStrategy: false }))
    //si tiene unos colores, se le envían esos params y sino, estos de aquí abajo
    //dispatch(updateColorsActionCreator({ navBarColor: "#FFFFFF", buttonsColor: "#FFC107", accentButtonsColor: "#1B2559", mainTextColor: "#475569", supportTextColor: "#A3AED0", accentTextColor: "#1B2559" }))
    //dispatch(updateColorsActionCreator({ navbarColor: "#046F6D", buttonsColor: "#046F6D", accentButtonsColor: "#046F6D", mainTextColor: "#3C3C3B", supportTextColor: "#046F6D", accentTextColor: "#FFFFFF" }))
  };

  const handleLogin = async () => {
    if ('caches' in window) {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
      //const cacheNamesAfter = await caches.keys();
    }

    localStorage.clear();
    sessionStorage.clear();
    
    setIsLoading(true);
    setInvalid(false);

    try {
      const res = await authService.smartecLogin(email, password);
      const token = res.result;
      const organizations = res.organizationsArray;
      console.log(organizations)
      const mqttManager = mqttManagerService.getInstance();
      if (res.client) {
        mqttManager.setClient(res.client);
      }
      if (res.handleOrganizationChange) {
        mqttManager.setHandleOrganizationChange(res.handleOrganizationChange);
      }

      // Updated code:
      // Check if the token is not null before proceeding.
      if (token) {
        // Login successful
        localStorage.setItem("token", token);
        const user = await getUserProfile(token);

        if (user?.user_active === "N") {
          navigate("/no-active");
          return;
        }

        //user.id 
        if (user) {
          user.id = res.userId;
          store.dispatch(fetchUserProfileAction(user));
        }

        const nodesFound = await getAllNodes(
          organizations[0].id,
          organizations[0].services[0].service_id,
          organizations,
          organizations[0].services
        );

        const orgInfo = await getSelectedOrganizationInfo(organizations[0].id, organizations[0].services[0].service_id);
        let getGroupsResponse = await getGroups(organizations[0].id, organizations[0].services[0].service_id);
        console.log(getGroupsResponse)
        if (getGroupsResponse) {
          store.dispatch(getGroupsAction(getGroupsResponse));
        } else {
          console.log("error in getGroups api");
          return false;
        }

        if (nodesFound === true && orgInfo === true) {
          backToInitialState();
          navigate("/dashboard");
        } else {
          alert("Error");
        }
      } else {
        // Invalid credentials or missing token
        setInvalid(true);
      }
    } catch (error) {
      console.error("Login error:", error);
      // Handle any errors that occurred during login
      setInvalid(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container className="login_container">
        <div className="row">
          <div className="col-6">
            {/* <LoginOverlay className="overlay" /> */}
            <LoginOverlayBlue className="overlay" />
            <img src={SmartecFullLogo} style={{ position: "absolute", top: 410, left: 135, width: "660px", height: "auto" }} />
          </div>
          <div className="col-6">
            <Container>
              <LoginOverlayWhite className="overlay" />
              <LoginForm padding="large" className="card" borderRadius="large">
                <div className="row">
                  <div className="col-12 ">
                    {/* <ImageLogo src={logo} /> */}
                    <ImageLogo src={SmartecNewLogo} />
                    {/* <ImageLogo src={balady} /> */}
                  </div>
                </div>
                <div className="col-12 text-left">
                  <TextBox
                    textType="title"
                    fontWeight="700"
                    marginBottom="32px"
                  >
                    {t("Log in to Smartec")}
                  </TextBox>
                </div>
                <InputForm
                  preffix_icon={mail}
                  inputType="email"
                  placeholder={t("Email")}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyPress={(e) => checkEnter(e)}
                />
                <InputForm
                  preffix_icon={locker_icon}
                  suffix_icon={mail}
                  inputType="password"
                  placeholder={t("password")}
                  marginBottom="small"
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={(e) => checkEnter(e)}
                />
                <TextSpan
                  marginBottom="medium"
                  style={{ textAlign: "right", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  {t("Forgot password?")}
                </TextSpan>
                {invalid ? (
                  <TextSpan
                    style={{ marginTop: "-20px", width: "200px" }}
                    color="red"
                  >
                    {t("Incorrect email or password")}
                  </TextSpan>
                ) : null}
                <div className="col-12 text-left">
                  {isLoading ? (
                    <ButtonLoading
                      style={{ marginTop: "16px", background: buttonsColor, color: accentTextColor }}
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {t("loading...")}
                    </ButtonLoading>
                  ) : (
                    <ButtonLogin
                    style={{ background: buttonsColor, color: accentTextColor }}
                      buttonType="primary"
                      onClick={() => handleLogin()}
                    >
                      {t("Log in")}
                    </ButtonLogin>
                  )}
                </div>
              </LoginForm>
            </Container>
          </div>
        </div>
      </Container>
    </>
  );
};
