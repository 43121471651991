import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Client } from "paho-mqtt";
import { store } from "../redux/store";
import { updateNodeDeviceStatusReduxActionCreator } from "../redux/slices/devices/filteredDevicesSlice";
import { updateNodeDeviceStatusGlobalReduxActionCreator } from "../redux/slices/devices/devicesSlice";
import { turnBackgroundErrorPopUpTrueActionCreator } from "../redux/slices/graphicsSlice/graphicsSlice";
import { deleteIdsActionCreator } from "../redux/slices/mqttSlice/mqttSlice";
import { updateNodeStatusReduxActionCreator, updateSolarConsumsActionCreator } from "../redux/slices/nodeSlice/manageNodeSlice";
import { getSolarBatteryActionCreator } from "../redux/slices/solar/solarParametersSlice";
import { getSolarStrategiesActionCreator } from "../redux/slices/solar/solarStrategiesSlice";
import { setIsStaticViewActionCreator } from "../redux/slices/uiSlice/uiSlice";
import { logout } from "../redux/slices/logoutSlice/logoutSlice";
import mqtt from 'mqtt';

function processResponse(orgId: string, nodeId: string, response: string) {
  try {
    const responseJSON = JSON.parse(response); //responseJSON.r = response responseJSON.o = order responseJSON.n = nodeId
    // Now that we have all the information, it's time to process the response
    // 1. Check if there everything went well
    if (responseJSON.r === 0) {
      // 2. If everything it's ok, update Redux store
    }

    // 3. Find in our calling stack if the nodeId of the response is generated by ours.
  } catch (error) {
    console.log(error);
  }
}

export function MyComponent() {
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    let mqttClient: any = null;
    const handleConnectionLost = () => {
      setIsConnected(false);
      navigate("/");
    };

    // Escuchar el evento personalizado
    window.addEventListener("mqttConnectionLost", handleConnectionLost);

    // Limpiar el oyente de eventos cuando se desmonte el componente
    return () => {
      window.removeEventListener("mqttConnectionLost", handleConnectionLost);
      if (mqttClient) {
        mqttClient.end();
        mqttClient = null;
      }
    };
  }, [navigate]);

  return null;
}

export function connectToMqtt(organizationIds: any[], userId: string, mqttToken: string) {
  // Variable para almacenar el tiempo de conexión
  let connectTime: number | null = null;

  // Crear cliente MQTT
  const client = mqtt.connect(process.env.REACT_APP_MQTT_WSS_N as string, {
    clientId: userId,
    username: "",
    password: mqttToken,
    /* username: process.env.REACT_APP_MQTT_CL_U,
    password: process.env.REACT_APP_MQTT_CL_P, */
    reconnectPeriod: 1000,
    /*ca: `-----BEGIN CERTIFICATE-----
    MIIFFjCCAv6gAwIBAgIRAJErCErPDBinU/bWLiWnX1owDQYJKoZIhvcNAQELBQAw
    TzELMAkGA1UEBhMCVVMxKTAnBgNVBAoTIEludGVybmV0IFNlY3VyaXR5IFJlc2Vh
    cmNoIEdyb3VwMRUwEwYDVQQDEwxJU1JHIFJvb3QgWDEwHhcNMjAwOTA0MDAwMDAw
    WhcNMjUwOTE1MTYwMDAwWjAyMQswCQYDVQQGEwJVUzEWMBQGA1UEChMNTGV0J3Mg
    RW5jcnlwdDELMAkGA1UEAxMCUjMwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEK
    AoIBAQC7AhUozPaglNMPEuyNVZLD+ILxmaZ6QoinXSaqtSu5xUyxr45r+XXIo9cP
    R5QUVTVXjJ6oojkZ9YI8QqlObvU7wy7bjcCwXPNZOOftz2nwWgsbvsCUJCWH+jdx
    sxPnHKzhm+/b5DtFUkWWqcFTzjTIUu61ru2P3mBw4qVUq7ZtDpelQDRrK9O8Zutm
    NHz6a4uPVymZ+DAXXbpyb/uBxa3Shlg9F8fnCbvxK/eG3MHacV3URuPMrSXBiLxg
    Z3Vms/EY96Jc5lP/Ooi2R6X/ExjqmAl3P51T+c8B5fWmcBcUr2Ok/5mzk53cU6cG
    /kiFHaFpriV1uxPMUgP17VGhi9sVAgMBAAGjggEIMIIBBDAOBgNVHQ8BAf8EBAMC
    AYYwHQYDVR0lBBYwFAYIKwYBBQUHAwIGCCsGAQUFBwMBMBIGA1UdEwEB/wQIMAYB
    Af8CAQAwHQYDVR0OBBYEFBQusxe3WFbLrlAJQOYfr52LFMLGMB8GA1UdIwQYMBaA
    FHm0WeZ7tuXkAXOACIjIGlj26ZtuMDIGCCsGAQUFBwEBBCYwJDAiBggrBgEFBQcw
    AoYWaHR0cDovL3gxLmkubGVuY3Iub3JnLzAnBgNVHR8EIDAeMBygGqAYhhZodHRw
    Oi8veDEuYy5sZW5jci5vcmcvMCIGA1UdIAQbMBkwCAYGZ4EMAQIBMA0GCysGAQQB
    gt8TAQEBMA0GCSqGSIb3DQEBCwUAA4ICAQCFyk5HPqP3hUSFvNVneLKYY611TR6W
    PTNlclQtgaDqw+34IL9fzLdwALduO/ZelN7kIJ+m74uyA+eitRY8kc607TkC53wl
    ikfmZW4/RvTZ8M6UK+5UzhK8jCdLuMGYL6KvzXGRSgi3yLgjewQtCPkIVz6D2QQz
    CkcheAmCJ8MqyJu5zlzyZMjAvnnAT45tRAxekrsu94sQ4egdRCnbWSDtY7kh+BIm
    lJNXoB1lBMEKIq4QDUOXoRgffuDghje1WrG9ML+Hbisq/yFOGwXD9RiX8F6sw6W4
    avAuvDszue5L3sz85K+EC4Y/wFVDNvZo4TYXao6Z0f+lQKc0t8DQYzk1OXVu8rp2
    yJMC6alLbBfODALZvYH7n7do1AZls4I9d1P4jnkDrQoxB3UqQ9hVl3LEKQ73xF1O
    yK5GhDDX8oVfGKF5u+decIsH4YaTw7mP3GFxJSqv3+0lUFJoi5Lc5da149p90Ids
    hCExroL1+7mryIkXPeFM5TgO9r0rvZaBFOvV2z0gp35Z0+L4WPlbuEjN/lxPFin+
    HlUjr8gRsI3qfJOQFy/9rKIJR0Y/8Omwt/8oTWgy1mdeHmmjk7j1nYsvC9JSQ6Zv
    MldlTTKB3zhThV1+XWYp6rjd5JW1zbVWEkLNxE7GJThEUG3szgBVGP7pSWTUTsqX
    nLRbwHOoq7hHwg==
    -----END CERTIFICATE-----`,
    protocol:'wss',
    key: `-----BEGIN PRIVATE KEY-----
    MIGHAgEAMBMGByqGSM49AgEGCCqGSM49AwEHBG0wawIBAQQg3CEpWuHLdjeTE9GH
    L4qqAYwh0v+HWDGEQepKDBV/yIihRANCAAT08OliepexFO0Ovq10rVOGxSiITHx1
    YIHVXyJ6dPhVnc/o2xn0mvaUSAFPbcb3yLCe3TK9B2e8GvJheVoCqvgk
    -----END PRIVATE KEY-----`,
    cert:`-----BEGIN CERTIFICATE-----
    MIIENjCCAx6gAwIBAgISA/zm/W0ZMUwSZ2MYaDL9QYbUMA0GCSqGSIb3DQEBCwUA
    MDIxCzAJBgNVBAYTAlVTMRYwFAYDVQQKEw1MZXQncyBFbmNyeXB0MQswCQYDVQQD
    EwJSMzAeFw0yNDA2MDQwNzU2NTRaFw0yNDA5MDIwNzU2NTNaMCUxIzAhBgNVBAMT
    Gm1xdHQuZGV2LnNhbHZpbGlnaHRpbmcuY29tMFkwEwYHKoZIzj0CAQYIKoZIzj0D
    AQcDQgAE9PDpYnqXsRTtDr6tdK1ThsUoiEx8dWCB1V8ienT4VZ3P6NsZ9Jr2lEgB
    T23G98iwnt0yvQdnvBryYXlaAqr4JKOCAhwwggIYMA4GA1UdDwEB/wQEAwIHgDAd
    BgNVHSUEFjAUBggrBgEFBQcDAQYIKwYBBQUHAwIwDAYDVR0TAQH/BAIwADAdBgNV
    HQ4EFgQUwP15Ak5uZ0jRgnI7ujnUvqsEqnIwHwYDVR0jBBgwFoAUFC6zF7dYVsuu
    UAlA5h+vnYsUwsYwVQYIKwYBBQUHAQEESTBHMCEGCCsGAQUFBzABhhVodHRwOi8v
    cjMuby5sZW5jci5vcmcwIgYIKwYBBQUHMAKGFmh0dHA6Ly9yMy5pLmxlbmNyLm9y
    Zy8wJQYDVR0RBB4wHIIabXF0dC5kZXYuc2FsdmlsaWdodGluZy5jb20wEwYDVR0g
    BAwwCjAIBgZngQwBAgEwggEEBgorBgEEAdZ5AgQCBIH1BIHyAPAAdgBIsONr2qZH
    NA/lagL6nTDrHFIBy1bdLIHZu7+rOdiEcwAAAY/idzfgAAAEAwBHMEUCIDNpLULS
    5EVW3z2iFm+DD9r82q200vwaEekTpFVv95NOAiEA1hZAWI8wfVrImeU2pu1mcT9w
    +g6tzkkwrRALgpVnjTkAdgDuzdBk1dsazsVct520zROiModGfLzs3sNRSFlGcR+1
    mwAAAY/idzfjAAAEAwBHMEUCIQDNG7xq3zQst487NofY4dUQkiMVYqGBQSCu19Q/
    CFb8bwIgZfIteXpKZEjpErGhS5Wi18mojz6Bk0m8bzztGpRg0AAwDQYJKoZIhvcN
    AQELBQADggEBAAAd7i76sDgQYssfWe9j5PZ6JQ5Ja9UHKwCoyDYjo1Fc66EOOwhu
    Dd2zc5SnXuKoNEWVgUvOMr8y4pG6Sh+20GkDE2T83xuYZjipZNsmFF0J5dM+XRwL
    CXdPuFSBmkUhL8v3MX2f1gPmiA1qYbjlKI1DaDahT1b2LQvQ7ldyTdJU/RoSNGxn
    XKzgduwbarvfsZS3UV5xUkjdrQGokKdjyNfcC3ANAIBBz5aRMzSvsszw/rOci1rf
    K7DNcxbeIMXjrb7/p/SU7yy6NqnzyNAmp8aZQiM5enOEaeAnRAhNYqdacmGVtodL
    yeeKFkRU3ogvAo6CJQnYqYShpZN9V3TGqDs=
    -----END CERTIFICATE-----`
    */
  });

  /* const client = mqtt.connect('/mqtt', {
    clientId: userId,
    reconnectPeriod: 1000,
    connectTimeout: 30 * 1000,
  }); */

  //hacer llamada fetch aquí dentro
  
  /* const client = mqtt.connect(process.env.REACT_APP_MQTT_WSS_N as string, {
    clientId: userId,
    reconnectPeriod: 1000,
    connectTimeout: 30 * 1000,
  }); */

  const messageOk = parseInt(process.env.REACT_APP_MQTT_OK as any);
  const messageDisconnected = parseInt(
    process.env.REACT_APP_MQTT_DIS as any
  );
  
  // Manejar el evento 'onConnectionLost'
  client.on('close', () => {
    console.log("Connection lost");
    if (connectTime) {
      const disconnectTime = Date.now();
      const duration = disconnectTime - connectTime; // Calcular la duración de la conexión
      if (duration < 5000) { // Si ha pasado menos de 5 segundos
        window.location.replace("/");
      }
    }
  });

  /* client.on('error', () => {
    console.log("Connection error");
    window.location.replace("/");
  }) */

  client.on('error', (err) => {
    console.log("Connection error");
    if (err && err.message) {
      // Comprobar el mensaje de error
      if (err.message.includes("Not authorized")) {
        // Manejo específico para el error de autorización
        alert("Connection failed: You are not authorized to connect. Please check your credentials.");
      } else {
        alert("Connection error: " + err.message);
      }
    }
    window.location.replace("/");
  });
 
  client.on('offline', () => {
    console.log("Connection offline");
    //window.location.replace("/");
  })

  // Manejar el evento 'onMessageArrived'
  client.on('message', (topic: any, message: any) => {
    const values = store.getState();
    const topicSplitted = topic.split("/");
    processResponse(topicSplitted[1], topicSplitted[2], message.toString());

    const luminaireResponse = JSON.parse(message.toString());
    if (luminaireResponse.result === messageOk) {
      if (luminaireResponse.msg_type === 109) {
        if (values.manageNode.node_id === luminaireResponse.node_id) {
          store.dispatch(updateSolarConsumsActionCreator(luminaireResponse.value))
        }
      } else if (luminaireResponse.msg_type === 80) {
        const res = luminaireResponse.value;
        if (values.manageNode.node_id === luminaireResponse.node_id) {
          store.dispatch(getSolarBatteryActionCreator({ cLimitVolt: res.climitvolt, cReturnVolt: res.creturnvolt, disVolt: res.disvolt, disReturnVolt: res.disreturnvolt, sysVolt: res.sysvolt, battType: res.batttype }))
        }
      } else if (luminaireResponse.msg_type === 81) {        
        const res = luminaireResponse.value;
        if (values.manageNode.node_id === luminaireResponse.node_id) {
          store.dispatch(getSolarStrategiesActionCreator({ intPower: res.intelligentPower, time1: res.time1, dim1: res.dim1, time2: res.time2, dim2: res.dim2, time3: res.time3,
            dim3: res.dim3, time4: res.time4, dim4: res.dim4, time5: res.time5, dim5: res.dim5, maxCurrent: res.maxCurrentLuminaire, voltDayNight: res.voltagePanelLimitBetweenDayAndNight }))
        }
      } else {
        store.dispatch(
          updateNodeDeviceStatusReduxActionCreator(luminaireResponse)
        );
        store.dispatch(
          updateNodeDeviceStatusGlobalReduxActionCreator(luminaireResponse)
        );
        store.dispatch(updateNodeStatusReduxActionCreator(luminaireResponse));
      }
      
      store.dispatch(setIsStaticViewActionCreator(true));
      const state = store.getState();
      state.mqtt.nodeId.forEach((node: string) => {
        if (node !== "" && node !== undefined) {
          if (node === luminaireResponse.node_id) {
            store.dispatch(deleteIdsActionCreator(luminaireResponse.node_id));
          }
        }
      });
    } else if (luminaireResponse.result === messageDisconnected) {
      //Siempre va a ser el nodo 100
      store.dispatch(turnBackgroundErrorPopUpTrueActionCreator());
    } else {
      // falta hacer que salte un mensaje de error cuando se borre el nodo de la lista
      store.dispatch(deleteIdsActionCreator(luminaireResponse.node_id));
      //es 0 por qué? el [] en la línea 105 era intencionado?
    }

    if (luminaireResponse.node_id === 0) {
      //Lo borramos de la lista
      if (luminaireResponse.result !== 0) console.log("");
      //Muestra alerta por pantalla
    }
  });


  function handleOrganizationChange(newOrgId:any, currentSubscribedId?:any) {
    // Desuscribir del topic actual si ya hay uno.
    if (currentSubscribedId) {
      const unsubscribeTopic = `front-msg/${currentSubscribedId}`;
      client.unsubscribe(unsubscribeTopic, (error) => {
        if (!error) {
          //console.log(`Desuscripción exitosa de ${unsubscribeTopic}`);
        } else {
          //console.error(`Error al desuscribir de ${unsubscribeTopic}`, error);
        }
      });
    }

    // Actualizar el id actual y suscribir al nuevo topic.
    currentSubscribedId = newOrgId;
    const subscribeTopic = `front-msg/${newOrgId}`;
    client.subscribe(subscribeTopic, {qos: 2}, (error) => {
      if (!error) {
        //console.log(`Suscripción exitosa a ${subscribeTopic}`);
      } else {
        //console.error(`Error al suscribir a ${subscribeTopic}`, error);
      }
    });
  }

  // Conectar al broker MQTT
  client.on('connect', () => {
    console.log("Connected to MQTT broker");
    connectTime = Date.now(); // Registrar el tiempo de conexión
    if (organizationIds.length > 0) {
      handleOrganizationChange(organizationIds[0].id);
    }
  });

  client.on('error',(err) => {
    console.log(err, "this is a error")
  })

  //return client;
  return {
    client,
    handleOrganizationChange
  };
}


// import { useNavigate } from "react-router-dom";
// import { useEffect } from "react";
// import { Client } from "paho-mqtt";
// import { store } from "../redux/store";
// import { updateNodeDeviceStatusReduxActionCreator } from "../redux/slices/devices/filteredDevicesSlice";
// import { updateNodeDeviceStatusGlobalReduxActionCreator } from "../redux/slices/devices/devicesSlice";
// import { turnBackgroundErrorPopUpTrueActionCreator } from "../redux/slices/graphicsSlice/graphicsSlice";
// import { deleteIdsActionCreator } from "../redux/slices/mqttSlice/mqttSlice";
// import { updateNodeStatusReduxActionCreator, updateSolarConsumsActionCreator } from "../redux/slices/nodeSlice/manageNodeSlice";
// import { getSolarBatteryActionCreator } from "../redux/slices/solar/solarParametersSlice";
// import { getSolarStrategiesActionCreator } from "../redux/slices/solar/solarStrategiesSlice";
// import { setIsStaticViewActionCreator } from "../redux/slices/uiSlice/uiSlice";
// import { logout } from "../redux/slices/logoutSlice/logoutSlice";

// function processResponse(orgId: string, nodeId: string, response: string) {
//   console.log("La Organización: ", orgId);
//   if (nodeId === undefined) console.log("Te has olvidado de poner el nodeId");
//   else console.log("El nodo: ", nodeId);
//   try {
//     const responseJSON = JSON.parse(response); //responseJSON.r = response responseJSON.o = order responseJSON.n = nodeId
//     // Now that we have all the information, it's time to process the response
//     // 1. Check if there everything went well
//     if (responseJSON.r === 0) {
//       // 2. If everything it's ok, update Redux store
//     }

//     // 3. Find in our calling stack if the nodeId of the response is generated by ours.
//   } catch (error) {
//     console.log("There is an error in the received information: ", error);
//   }
// }

// export function MyComponent() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const handleConnectionLost = () => {
//       console.log(
//         "Hemos entrado aquí porque hemos perdido la conexión con el broker"
//       );
//       navigate("/");
//     };

//     // Escuchar el evento personalizado
//     window.addEventListener("mqttConnectionLost", handleConnectionLost);

//     // Limpiar el oyente de eventos cuando se desmonte el componente
//     return () => {
//       window.removeEventListener("mqttConnectionLost", handleConnectionLost);
//     };
//   }, [navigate]);

//   // ...
// }

// export function connectToMqtt(organizationIds: any[], userId: string) {
//   // Crear cliente MQTT
//   const client = new Client(
//     process.env.REACT_APP_MQTT_BASE_URL!,
//     parseInt(process.env.REACT_APP_MQTT_P!),
//     userId
//   );
//   const messageOk = parseInt(process.env.REACT_APP_MQTT_OK as any);
//   const messageDisconnected = parseInt(
//     process.env.REACT_APP_MQTT_DIS as any
//   );
  
//   // Manejar el evento 'onConnectionLost'
//   client.onConnectionLost = (responseObject: any) => {
//     if (responseObject.errorCode !== 0) {
//       console.log("Connection lost:", responseObject.errorMessage);
//       window.location.replace("/");
//     }
//   };

//   // Manejar el evento 'onMessageArrived'
//   client.onMessageArrived = (message: any) => {
//     const values = store.getState();
//     console.log("el message", message);
//     const topicSplitted = message.destinationName.split("/");
//     processResponse(topicSplitted[1], topicSplitted[2], message.payloadString);

//     const luminaireResponse = JSON.parse(message.payloadString);
//     console.log("la res", messageDisconnected, luminaireResponse);
//     if (luminaireResponse.result === messageOk) {
      
//       console.log("es 0");
//       if (luminaireResponse.msg_type === 109) {
//         if (values.manageNode.node_id === luminaireResponse.node_id) {
//           store.dispatch(updateSolarConsumsActionCreator(luminaireResponse.value))
//         }
//       } else if (luminaireResponse.msg_type === 80) {
//         const res = luminaireResponse.value;
//         if (values.manageNode.node_id === luminaireResponse.node_id) {
//           store.dispatch(getSolarBatteryActionCreator({ cLimitVolt: res.climitvolt, cReturnVolt: res.creturnvolt, disVolt: res.disvolt, disReturnVolt: res.disreturnvolt,
//             sysVolt: res.sysvolt, battType: res.batttype }))
//         }
//       } else if (luminaireResponse.msg_type === 81) {        
//         const res = luminaireResponse.value;
//         if (values.manageNode.node_id === luminaireResponse.node_id) {
//           store.dispatch(getSolarStrategiesActionCreator({ intPower: res.intelligentPower, time1: res.time1, dim1: res.dim1, time2: res.time2, dim2: res.dim2, time3: res.time3,
//             dim3: res.dim3, time4: res.time4, dim4: res.dim4, time5: res.time5, dim5: res.dim5, maxCurrent: res.maxCurrentLuminaire, voltDayNight: res.voltagePanelLimitBetweenDayAndNight }))
//         }
//       } else {
//         store.dispatch(
//           updateNodeDeviceStatusReduxActionCreator(luminaireResponse)
//         );
//         store.dispatch(
//           updateNodeDeviceStatusGlobalReduxActionCreator(luminaireResponse)
//         );
//         store.dispatch(updateNodeStatusReduxActionCreator(luminaireResponse));
//       }
      
//       // if gw ..... if node .... Los mensajes será distintos y debo pensar en como gestionar esto. Quizás fijándome en el topic?
//       //Actualizar redux con el nuevo estado de la luminaria
    

//       /*   dispatch(loadDevicesActionCreator(devices));
//           dispatch(filteredAllDevicesActionCreator(devices)); */

//       store.dispatch(setIsStaticViewActionCreator(true));
//       const state = store.getState();
//       state.mqtt.nodeId.forEach((node: string) => {
//         if (node !== "" && node !== undefined) {
//           if (node === luminaireResponse.node_id) {
//             store.dispatch(deleteIdsActionCreator(luminaireResponse.node_id));
//           }
//         }
//       });
//     } else if (luminaireResponse.result === messageDisconnected) {
//       //Siempre va a ser el nodo 100
//       console.log("es 207");
//       store.dispatch(turnBackgroundErrorPopUpTrueActionCreator());
//     } else {
//       console.log("no es 0");
//       // falta hacer que salte un mensaje de error cuando se borre el nodo de la lista
//       store.dispatch(deleteIdsActionCreator(luminaireResponse.node_id));
//       //es 0 por qué? el [] en la línea 105 era intencionado?
//     }

//     if (luminaireResponse.node_id === 0) {
//       //Lo borramos de la lista
//       if (luminaireResponse.result !== 0) console.log("");
//       //Muestra alerta por pantalla
//     }
//   };

//   // Conectar al broker MQTT
//   /* client.connect({
//     userName: process.env.REACT_APP_MQTT_CL_U as string,
//     password: process.env.REACT_APP_MQTT_CL_P as string,
//     useSSL: true,
//     onSuccess: () => {
//       console.log("Connected to MQTT broker");
//       organizationIds.forEach((orgId) => {
//         console.log("la org: ", orgId.id);
//         // Suscribirse al topic 'front-msg/orgId'
//         client.subscribe(`front-msg/${orgId.id}`);
//       });
//     },
//   }); */

//   function handleOrganizationChange(newOrgId:any, currentSubscribedId?:any) {
//     // Desuscribir del topic actual si ya hay uno.
//     if (currentSubscribedId) {
//       const unsubscribeTopic = `front-msg/${currentSubscribedId}`;
//       client.unsubscribe(unsubscribeTopic, {
//         onSuccess: () => {
//           console.log(`Desuscripción exitosa de ${unsubscribeTopic}`);
//         },
//         onFailure: (error) => {
//           console.error(`Error al desuscribir de ${unsubscribeTopic}`, error);
//         }
//       });
//     }

//     // Actualizar el id actual y suscribir al nuevo topic.
//     currentSubscribedId = newOrgId;
//     const subscribeTopic = `front-msg/${newOrgId}`;
//     client.subscribe(subscribeTopic, {
//       onSuccess: () => {
//         console.log(`Suscripción exitosa a ${subscribeTopic}`);
//       },
//       onFailure: (error) => {
//         console.error(`Error al suscribir a ${subscribeTopic}`, error);
//       }
//     });
//   }

//   // Conectar al broker MQTT
//   client.connect({
//     userName: process.env.REACT_APP_MQTT_CL_U as string,
//     password: process.env.REACT_APP_MQTT_CL_P as string,
//     useSSL: true,
//     onSuccess: () => {
//       console.log("Connected to MQTT broker");
//       // Suscribirse inmediatamente al primer id de organización.
//       if (organizationIds.length > 0) {
//         handleOrganizationChange(organizationIds[0].id);
//       }
//     },
//   });

//   //return client;
//   return {
//     client,
//     handleOrganizationChange
//   };
// }
