import styled from "styled-components";
import { styledMainTheme } from "./styleMainTheme";

export const Button = styled.button`
  padding: 8px 48px;
  border-radius: 20px;
  background-color: ${(props: {
    buttonType: "primary" | "secondary" | "link" | "";
  }) =>
    props.buttonType === "primary"
      ? styledMainTheme.light.buttonMainColor
      : props.buttonType === "secondary"
      ? styledMainTheme?.light?.mainBackgroundColor
      : "red"};
  color: #1b2559;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  border: none;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
`;

export const ButtonLoading = styled.button`
  padding: 8px 48px;
  border-radius: 8px;
  background-color: rgb(255 193 7 / 43%);
  color: #333;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  border: none;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
`;

export const ButtonOutlined = styled.button`
  height: ${(props: { height?: string; radius?: string; padding?: string }) =>
    props.height || "auto"};
  background-color: #fff;
  border: 1px solid #1b2559;
  /* border-radius: 4px; */
  border-radius: 8px;
  padding: 8px 32px;
  font-size: 14px;
  color: #1b2559;
  font-weight: 500;
`;

export const ButtonOutlinedDisabled = styled.button`
  background-color: #fff;
  border: 1px solid #a3aed0;
  border-radius: 4px;
  padding: 8px 32px;
  font-size: 14px;
  color: #a3aed0;
  font-weight: 500;
  cursor: not-allowed !important;
`;
