import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { GeneralInfoChart } from "./GeneralInfoChart";
import { Text } from "../../../styles/shared-styles/text-sizes";

export const GeneralInfoCard = () => {
  const reportsInfo = useAppSelector((state) => state.reports);
  const { t } = useTranslation();

  return (
    <div
      className="card"
      style={{
        display: "flex",
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        minHeight: "307px",
        flexGrow: 1
      }}
    >
      <Text style={{ alignSelf: "flex-start", fontWeight: 500 }}>{t("generalInfo")}</Text>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", top: 142, marginLeft: 97, position: "absolute" }}>
        <Text style={{ fontSize: "12px", color: "#99B2C6", fontWeight: 500 }}>{t("total")}</Text>
        <Text color="#475569" style={{ fontSize: "20px", fontWeight: 700 }}>
          {reportsInfo.generalTotal}
        </Text>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "30px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "8px" }}>
        <div style={{ zIndex: 1 }}>
          <GeneralInfoChart />
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", width: "40%", marginRight: "20px" }}>
          {reportsInfo.generalStandard &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #1B2559", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("standardLuminaires")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.generalStandard}</Text>
            </div>
          }
          {reportsInfo.generalSolar &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #FFC107", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("solarLuminaires")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.generalSolar}</Text>
            </div>
          }
          {reportsInfo.generalHybrid &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #45D0EE", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("hybridLuminaires")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.generalHybrid}</Text>
            </div>
          }
          {reportsInfo.generalGateways &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #17181A60", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("Gateways")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.generalGateways}</Text>
            </div>
          }
          {reportsInfo.generalSwitchboards &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #A3AED0", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("switchboards")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.generalSwitchboards}</Text>
            </div>
          }
          {reportsInfo.generalRgb &&
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #F1F4F9", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("rgbLuminaires")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.generalRgb}</Text>
            </div>
          }
        </div>
      </div>
    </div>
  );
};
