import { useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppSelector } from "../../../../redux/hooks";
import { Modal } from "react-bootstrap";
import ConsumptionChart from "./ConsumptionChart";
import { Text } from "../../../../styles/shared-styles/text-sizes";

interface ConsumptionModalProps {
  showModal: boolean;
  setShowModal: any;
  toggleDataset: any;
  isVisible: any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1400px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 550px;
    width: 90%;
    background: white;
    border: 0px;
  }
`;

export const ConsumptionModal = ({ showModal, setShowModal, toggleDataset, isVisible }: ConsumptionModalProps) => {
  const [showPreventPopup, setShowPreventPopup] = useState(false);

  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  
  const handlePreventPopup = () => {
    setShowModal(!showModal)
  };

  const { t } = useTranslation();

  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      <Modal.Body style={{ pointerEvents: showPreventPopup ? "none" : "auto", background: "white", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px",
        paddingRight: "0px", borderTopRightRadius: "0.5rem", borderTopLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem", borderBottomLeftRadius: "0.5rem", display: "flex",
        flexDirection: "column"}}
      >
        <div style={{ display: "flex", width: "100%", padding: "8px 10px 0px 0px", flexDirection: "column", background: "#ffffff", borderTopRightRadius: "0.5rem",
          borderTopLeftRadius: "0.5rem", justifyContent: "flex-end" }}
        >
          <Modal.Header
            closeButton
            style={{
              borderBottom: "none",
              background: "white",
              zIndex: 2
            }}
          />
        </div>
        <div style={{ paddingLeft: "50px", paddingRight: "50px", display: "flex", flexDirection: "column" }}>
          <Text fontWeight="700" marginBottom="20px" color={mainTextColor} style={{fontSize: "22px" }}>{t("consumptionGraph")}</Text>
          <div style={{ display: "flex", /* gap: "80px", */ alignItems: "end" }}>
            <div style={{ display: "flex", gap: "14px" }}>
              <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDataset(0)}>
                <div style={{ border: "1px solid #16D764", backgroundColor: isVisible[0] ? "rgba(22, 215, 100, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("chargePower")}</Text>
              </div>
              <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDataset(1)}>
                <div style={{ border: "1px solid #00C7F2", backgroundColor: isVisible[1] ? "rgba(0, 199, 242, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("battVoltage")}</Text>
              </div>
              <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDataset(2)}>
                <div style={{ border: "1px solid #005882", backgroundColor: isVisible[2] ? "rgba(100, 149, 237, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("batt%")}</Text>
              </div>
              <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDataset(3)}>
              <div style={{ border: "1px solid #FFCB21", backgroundColor: isVisible[3] ? "rgba(255, 203, 33, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("activePower")}</Text>
              </div>
            </div>
            {/* <div style={{ display: "flex", gap: "16px", }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("startDate")}</Text>
                <DatePicker
                  className="datePickerSolar"
                  placeholderText={t("selectDate")}
                  selectsRange={false}
                  selected={startDate}
                  onChange={(date: any) => setStartDate(date)}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("endDate")}</Text>
                <DatePicker
                  className="datePickerSolar"
                  placeholderText={t("selectDate")}
                  selectsRange={false}
                  selected={endDate}
                  onChange={(date: any) => setEndDate(date)}
                />
              </div>
              <NextButton style={{ alignSelf: "end", width: "120px", padding: "8px 8px", fontSize: "12px", marginLeft: "6px", opacity: !startDate || !endDate ? 0.5 : 1,
                cursor: !startDate || !endDate ? "not-allowed" : "pointer", boxShadow: "0px 2px 4px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2)" }}
                onClick={() => {manageSolarInfo()}}
                disabled={!startDate || !endDate}
              >
                {t("viewData")}
              </NextButton>
            </div> */}
          </div>
          <div style={{ display: "flex", gap: "30px", flexWrap: "wrap", paddingBottom: "20px", paddingTop: "30px", justifyContent: "center", maxHeight: "630px", height: "400px" }}>
            <ConsumptionChart isVisible={isVisible} />
          </div>
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
