import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { Text } from "../../../styles/shared-styles/text-sizes";
import alarmTime from "../../../assets/new_icons/alarm-time.svg";
import arrowRight from "../../../assets/new_icons//arrow_right_full.svg";
import calendar from "../../../assets/new_icons/Calendar.svg";
import grayCircle from "../../../assets/new_icons/ellipse_grey.svg";
import noAlarm from "../../../assets/new_icons/noAlarm_edited.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";
import redCircle from "../../../assets/new_icons/ellipse_red.svg";
import yellowCircle from "../../../assets/new_icons/ellipse_yellow.svg";

interface AlarmsCardProps {}

export const AlarmsCard = ({}: AlarmsCardProps) => {
  const [iconOpacity, setIconOpacity] = useState(0.5);

  const navigate = useNavigate();
  const nodeAlarms = useAppSelector((state) => state.alarms.alarmsFromNode);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const { t } = useTranslation();

  return (
    <div
      className="card"
      style={{
        display: "flex",
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        flexGrow: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between"
        }}
      >
        <div>
          <Text style={{ fontWeight: 500 }}>{t("Alarms")}</Text>
        </div>
        {/* <div style={{ textAlign: "right", marginTop: "-2px" }}>
          <img src={plusCircle} height={16} style={{ opacity: iconOpacity, cursor: "pointer" }} onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)} />
        </div> */}
      </div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", marginTop: "30px", height: "100%" }}>
        {nodeAlarms.length > 0 ?
          <>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {nodeAlarms.map((alarm:any) => {
                let date = new Date(alarm.creation_date);

                let day:any = date.getDate();
                  day = (day < 10) ? '0' + day : day;
                let month:any = date.getMonth() + 1;
                  month = (month < 10) ? '0' + month : month;
                let year = date.getFullYear();
                let hours:any = date.getHours();
                  hours = (hours < 10) ? '0' + hours : hours;
                let minutes:any = date.getMinutes();
                  minutes = (minutes < 10) ? '0' + minutes : minutes;
                let seconds:any = date.getSeconds();
                  seconds = (seconds < 10) ? '0' + seconds : seconds;

                let formattedDate = day + '/' + month + '/' + year + ' - ' + hours + ':' + minutes + ':' + seconds;

                return (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                      <img src={calendar} height={12} />
                      <Text marginBottom="0px">{formattedDate}</Text>
                      <img src={alarm.type === "Low" ? grayCircle : alarm.type === "Moderate" ? yellowCircle : alarm.type === "Critical" ? redCircle : "" } />
                      <Text marginBottom="0px">{alarm.title}</Text>
                    </div>
                    {/* <img src={arrowRight} width={6} style={{ display: "flex", justifySelf: "end" }} /> */}
                  </div>
                )
              })}             
            </div>
           {/*  <button /* onClick={() => {navigate("/coming-soon")}}
              style={{ display: "flex", alignItems: "center", alignSelf: "end", backgroundColor: "#ffffff", color: mainTextColor, fontWeight: "700", fontSize: "14px",
                padding: "6px 18px", border: `1px solid ${mainTextColor}`, borderRadius: "64px", cursor: "pointer", width: "32%", marginTop: "10px" }}
            >
              <img src={alarmTime} height={12} style={{ marginRight: "12px" }} />
              {t("View all")}
            </button> */}
          </>
        :
          <img src={noAlarm} style={{ marginBottom: "32px" }} />
        }
      </div>
      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={() => {
            navigate("/disabled-demo-mode");
          }}
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#ffffff",
            color: "#475569",
            fontWeight: "700",
            fontSize: "14px",
            padding: "6px 16px",
            border: "1px solid #475569",
            borderRadius: "64px",
            cursor: "pointer",
          }}
        >
          <img src={alarmTime} height={12} style={{ marginRight: "12px" }} />
          {t("View historic")}
        </button>
      </div> */}
    </div>
  );
};
