import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { AlarmsChart } from "./AlarmsChart";
import { Text } from "../../../styles/shared-styles/text-sizes";

export const AlarmsCard = () => {
  const reportsInfo = useAppSelector((state) => state.reports);
  const { t } = useTranslation();

  return (
    <div
      className="card"
      style={{
        display: "flex",
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        minHeight: "307px",
        flexGrow: 1
      }}
    >
      <Text style={{ fontWeight: 500 }}>{t("Alarms")}</Text>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", top: 117, marginLeft: 130, position: "absolute" }}>
        <Text style={{ fontSize: "12px", color: "#99B2C6", fontWeight: 500 }}>{t("solved")}</Text>
        <Text color="#56C568" style={{ fontSize: "26px", fontWeight: 700 }}>{reportsInfo.alarmsTotal && reportsInfo.alarmsTotal > 0 ?
            ((reportsInfo.alarmsSolved / reportsInfo.alarmsTotal) * 100).toFixed(0) 
          :
            0}%
        </Text>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", zIndex: 1 }}>
        <AlarmsChart />
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px", paddingBottom: "8px", gap: "10px" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "70%" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div style={{ borderRadius: "10px", border: "2px solid #56C568", width: "11px", height: "11px", marginBottom: "1px" }} />
            <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("solved")}</Text>
          </div>
          <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.alarmsSolved}</Text>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "70%" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <div style={{ borderRadius: "10px", border: "2px solid #1B2559", width: "11px", height: "11px", marginBottom: "1px" }} />
            <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("pending")}</Text>
          </div>
          <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.alarmsPending}</Text>
        </div>
      </div>
    </div>
  );
};
