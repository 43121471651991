import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { Loader } from "../Loader/Loader";

interface LoaderModalProps {
  showLoader: boolean;
  background?: any;
}

/* const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1400px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 100px;
    width: 20%;
    background: transparent;
    border: 0px;
  }
`; */

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    background: ${props => props.background || 'transparent'};
    border-radius: 0px;
  }
  `;
  /* background: rgba(0, 0, 0, 0.5); */

export const LoaderModal = ({ showLoader, background }: LoaderModalProps) => {
  return (
    <CustomModal show={showLoader} centered background={background}>
      <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Loader height="auto" />
      </Modal.Body>
    </CustomModal>
  );
};
