import styled from "styled-components";

interface ButtonSolvedProps {
  buttonsColor: string;
  accentTextColor: string;
}

export const ButtonSolvedStyled = styled.button<ButtonSolvedProps>`
  padding: 8px 32px;
  background-color: ${props => props.buttonsColor};
  border: 1px solid ${props => props.buttonsColor};
  border-radius: 4px;
  color: ${props => props.accentTextColor};
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0px 1px 2px 0px #0000004d;
`;
