import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { managedUserServicesArray } from "../../../../types/interfaces";
import { registerUserActionCreator, setServicePermitsActionCreator } from "../../../../redux/slices/userRegistrationSlice/userRegistrationSlice";
import { CardContainer, NextButton } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { ServiceCard, ServiceList } from "../../UsersDetailsCard/Services/ServicesStyled";
import { CompanyProfile } from "../../UsersPageStyled";
import { Text, TextSpan } from "../../../../styles/shared-styles/text-sizes";
import companyFlag from "../../../../assets/new_icons/Company.svg";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface ServPermitProps {
  goToReview?: any;
  /* goToGroups?: any; */
  errorMessage?: boolean;
  setErrorMessage?: any;
}

export const ServicePermit = ({ goToReview, errorMessage, setErrorMessage }: ServPermitProps) => {
  const dispatch = useAppDispatch();
  const organizations = useAppSelector((state) => state.userRegistration.organizationPermits);
  //const orgImage = organizations.find((org: any) => org.selected)?.image;
  //cuando aceptemos seleccionar más de una org, revisar esto y manejarlo segun el selected en vez del orgId? Sea lo que sea, deberíamos usar solo una de las dos opciones (seguramente el id)
  const services = useAppSelector((state) => state.userRegistration.servicePermits);
  const selectedIds = organizations.filter((org:any) => org.selected).map((org:any) => org.id);
  const selectedServices = services.filter((serv:any) => selectedIds.includes(serv.orgId));
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const handleServicesInfo = (e: any) => {
    try {
      dispatch(registerUserActionCreator({ service_id: selectedServices[0].services[0].id }));
      goToReview();
    } catch (error) {
      console.log(error)
    }
  };

  //manejar el errorMessage en un futuro
  useEffect(() => {
    dispatch(registerUserActionCreator({ service_id: selectedServices[0].services[0].id }));
  }, []);

  useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = `
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${accentButtonsColor} !important;
        border-color: ${accentButtonsColor} !important;
        border-radius: 0px !important;
      }
      
      .ant-checkbox .ant-checkbox-inner {
        border: 2px solid ${accentButtonsColor} !important;
        border-radius: 0px !important;
      }
    `;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [accentButtonsColor]);

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: "47px", paddingLeft: "40px" }}>
        <Text fontWeight="500" marginBottom="4px">{t("step4")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("servicesPermits")}</Text>
        <Text fontWeight="400" style={{fontSize: "12px"}}>{t("orgPermitsSubtitle")}</Text>
      </div>
      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap", overflowY: "auto", paddingTop: "2px", paddingLeft: "70px", paddingRight: "50px", marginRight: "20px",
        maxHeight: "510px" }}
      >
        {/* maybe habrá que tocar esto cuando haya más de un servicio y gestionarlo distinto. Faltan los onChange en los checks */}
        {selectedServices?.map((service:any) => (
          <ServiceCard style={{ display: "flex", flexDirection: "column", width: "420px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "6px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "8px", marginBottom: "18px", borderBottom: "2px solid #E7E0EC", width: "100%" }}>
              <div>
                <CompanyProfile src={service.image ? `https://${service.image}` : companyFlag} />
                <TextSpan fontWeight="700" style={{ marginLeft: "12px" }}>{service.orgName}</TextSpan>
              </div>
              <div style={{ display: "flex", alignItems: "center", paddingRight: "16px" }}>
                <Checkbox
                  checked={service?.services?.every((serv: managedUserServicesArray) => serv.selected)}     
                />
                <span style={{ fontSize: "12px", marginLeft: "8px" }}>{t("allServices")}</span>
              </div>
            </div>
            <ServiceList style={{ alignContent: "start", justifyContent: "flex-start" }}>
              {Array.isArray(service?.services) &&
                service?.services.map((serv: managedUserServicesArray) => (
                  <div>
                    <Checkbox
                      checked={serv !== undefined && serv?.selected}
                    />
                    <span style={{ fontSize: "12px", marginLeft: "8px" }}>{serv.servName}</span>
                  </div>
              ))}
            </ServiceList>
          </ServiceCard>
        ))}
      </div>
      {errorMessage ?
        <Text marginBottom="0px" color="red" style={{ display: "flex", justifySelf: "end", alignSelf: "center", marginTop: "398px" }}>{t("errorBlankInput")}</Text>  
      :
        <></>
      }
      <NextButton accentButtonsColor={accentButtonsColor} style={{ justifySelf: "end", alignSelf: "center", width: "340px", marginTop: errorMessage ? "16px": "435px" }} onClick={handleServicesInfo}>{t("continue")}</NextButton>
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
        {/* <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} /> */}
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
      </div>
    </CardContainer>
  );
};
