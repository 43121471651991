import { useTranslation } from "react-i18next";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { changeBackgroundDashboardActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { useLightingService } from "../../../services/lightingService";
import { TextBox } from "../../../styles/shared-styles/text-sizes";
import { ReactComponent as Cross } from "../../../assets/new_icons/Cross.svg";

interface RGBPopUpProps {}

export const RGBPopUp = ({}: RGBPopUpProps) => {
  const [color, setColor] = useColor("#00ff25");
  const nodesInArea:any = useAppSelector((state) => state.devices.nodesInArea);
  const detailPopUp:any = useAppSelector((state) => state.ui.isOpenedDetail);
  const selectedRGBNode:any = useAppSelector((state) => state.ui.selectedRGBNode);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);

  const { sendCommandToLuminaire } = useLightingService();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const changeBackground = () => {
    dispatch(changeBackgroundDashboardActionCreator());
  };

  const handleRgb = async (order: number) => {
    let parseredColor = {
      r: Math.round(color.rgb.r),
      g: Math.round(color.rgb.g),
      b:Math.round(color.rgb.b)
    }

    const nodesToSwitch = nodesInArea.filter(
      (node:any) => node.online && (order === 1 ? !node.on : node.on)
    );

    try {
      if (detailPopUp) {
        let result: any = await sendCommandToLuminaire([selectedRGBNode], order, 0, parseredColor);
      } else {
        let result: any = await sendCommandToLuminaire(nodesToSwitch, order, 0, parseredColor);
      }
    } catch (error) {
      alert(error);
    }
  }

  return (
    <div className="card" style={{ display: "flex", alignItems: "center", padding: "16px", border: "none", background: "#F3F4F6", boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)", borderRadius: "14px", width: "600px", paddingBottom: "28px", paddingTop: "18px", justifyContent: "center", 
      }}
    >
      <Cross width={14} height={14} style={{ color: "#BDBDBD", cursor: "pointer", alignSelf: "end", marginRight: "0px" }} onClick={() => {changeBackground()}} />
      <div style={{ padding: "18px", width: "100%" }}>
        <ColorPicker height={200} color={color} onChange={setColor} hideAlpha={true} hideInput={["hsv"]} />
      </div>  
      <div style={{ cursor: "pointer", display: "flex", alignItems: "center", justifyContent: "center", padding: "8px 18px 8px 18px", height: "40px", width: "120px",
        background: color.hex, color: accentButtonsColor, boxShadow: "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)", marginTop: "10px", borderRadius: "8px" }}
        onClick={() => {handleRgb(40); changeBackground()}}
      >
        <TextBox fontWeight="500" color="#000000" style={{ fontSize: "16px" }}>{t("rgbAll")}</TextBox>
      </div>
   
    </div>
  );
};
