import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { resetStrategyActionCreator } from "../../../../redux/slices/strategiesSlice/strategiesSlice";
import { useStrategiesService } from "../../../../services/strategiesService";
import DimmingChartSolar from "../Step2Dimming/DimmingChartSolar";
import DimmingChartStandard from "../Step2Dimming/DimmingChartStandard";
import { parseLabelsAndData, getHoursAndValues, calculateEnergySaving } from "../../StrategiesLogic";
import { CardContainer, ButtonStrategy, NextButton } from "../StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import { ReactComponent as EnergyIcon } from "../../../../assets/new_icons/Energy.svg";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";
import sunriseIcon from "../../../../assets/new_icons/Sunrise.svg";
import sunsetIcon from "../../../../assets/new_icons/Sunset.svg";

interface ReviewProps {
  onHide?: any;
  setShowDetails?: any;
  setShowDimming?: any;
  showDimming?: any;
  setShowReview?: any;
  setNewChartDataStandard?: any;
  newChartDataStandard?: any;
  setNewChartDataSolar?: any;
  newChartDataSolar?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
}

export const Review = ({ onHide, setNewChartDataStandard, newChartDataStandard, setNewChartDataSolar, newChartDataSolar, setErrorMessage }: ReviewProps) => {
  const dispatch = useAppDispatch();
  const { postStrategy, postSolarStrategy, getStrategies } = useStrategiesService();
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const newStrategy = useAppSelector((state) => state.strategies);
  const name = useAppSelector((state) => state.strategies.name);
  const description = useAppSelector((state) => state.strategies.description);
  const color = useAppSelector((state) => state.strategies.color);
  const selectedTypeOption = useAppSelector((state) => state.strategies.type?.value);
  const value = useAppSelector((state) => state.strategies.saving);
  const astroDim = useAppSelector((state) => state.strategies.astroDim);
  const offsetSunset = useAppSelector((state) => state.strategies.offsetSunset);
  const offsetSunrise = useAppSelector((state) => state.strategies.offsetSunrise);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const sendInfo = async () => {
    if (selectedTypeOption === "standard") {
      await postStrategy(newStrategy.labels, newStrategy.data);
    } else {
      await postSolarStrategy(newStrategy.data);
    }
    dispatch(resetStrategyActionCreator());
    onHide();
  };

  useEffect(() => {
    if (newChartDataStandard && selectedTypeOption === "standard") {
      let parsedLabelsAndData = parseLabelsAndData(
        newChartDataStandard.labels,
        newChartDataStandard.datasets[0].data
      );
      let hoursWithValues = getHoursAndValues(
        parsedLabelsAndData[0],
        parsedLabelsAndData[1]
      );
      calculateEnergySaving(hoursWithValues);
    } else if (newChartDataStandard && selectedTypeOption === "solar") {
      let parsedLabelsAndData = parseLabelsAndData(
        newChartDataSolar.labels,
        newChartDataSolar.datasets[0].data
      );
      let hoursWithValues = getHoursAndValues(
        parsedLabelsAndData[0],
        parsedLabelsAndData[1]
      );
      calculateEnergySaving(hoursWithValues);
    }
  }, []);


  return (
    <CardContainer style={{ display: "flex", flexDirection: "column", paddingLeft: "40px" }}>
      <div style={{ marginBottom: "4px" }}>
        {selectedTypeOption === "standard" ?
          <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step3")}</Text>
        :
          <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step4")}</Text>
        }
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("reviewConfiguration")}</Text>
        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("reviewConfigurationSubtitleStrategies")}</Text>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: "32px" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "600px" }}>
          <Text marginBottom="0px" color={color.value} fontWeight="500" style={{fontSize: "18px"}}>{name}</Text>
          <Text textType="caption" marginBottom="0px" style={{fontSize: "12px", wordWrap: "break-word"}}>{description}</Text>
        </div>
        {selectedTypeOption === "standard" &&
          <NextButton style={{ padding: "3px 16px", paddingLeft: "6px", height: "39px", boxShadow: "none", background: color.value, border: "none", fontSize: "12px",
            cursor: "auto", marginBottom: "0px" }}
          >
            <EnergyIcon height={16} style={{ color: "white", marginTop: "-2px", marginLeft: "0px", paddingLeft: "0px" }} />
            {newChartDataStandard ? value : 0}{t("percentageEnergySaved").toUpperCase()}
          </NextButton>
        }
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "50px", marginTop: "20px" }}>
        {selectedTypeOption === "standard" &&
          <div style={{ display: "flex", alignItems: "center", gap: "18px" }}>
            <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px" }}>{t("astroDim")}</Text>
            <div style= {{ display: "flex", border: "1px solid #ddd", borderRadius: 5, justifyContent: "center", height: "30px", width: "100px", marginBottom: "1px",
              boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
            >
              <Text color={supportTextColor} style={{ fontSize: "14px", marginTop: "5px" }}>{astroDim ? t("yes").toUpperCase() : t("no").toUpperCase()}</Text>     
            </div>
          </div>
        }
        {astroDim ?
          <div style={{ display: "flex", gap: "18px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <img src={sunsetIcon} width={24} />
              <div style= {{ display: "flex", border: "1px solid #ddd", borderRadius: 5, justifyContent: "center", height: "30px", width: "110px", marginBottom: "1px",
                boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
              >
                { offsetSunset ?
                    <Text color={supportTextColor} style={{ fontSize: "14px", marginTop: "5px", }}>{offsetSunset} {t("minOffset").toUpperCase()}</Text>
                  :
                    <Text color={supportTextColor} style={{ fontSize: "14px", marginTop: "5px", }}>{t("noOffset").toUpperCase()}</Text>
                }
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <img src={sunriseIcon} width={24} />
              <div style= {{ display: "flex", border: "1px solid #ddd", borderRadius: 5, justifyContent: "center", height: "30px", width: "110px", marginBottom: "1px",
                boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
              >
                {offsetSunrise ?
                    <Text color={supportTextColor} style={{ fontSize: "14px", marginTop: "5px" }}>{offsetSunrise} {t("minOffset").toUpperCase()}</Text>
                  :
                    <Text color={supportTextColor} style={{ fontSize: "14px", marginTop: "5px" }}>{t("noOffset").toUpperCase()}</Text>
                }
              </div>
            </div>
          </div>
        :
          <></>
        }
      </div>
      {selectedTypeOption === "standard" ? 
        <DimmingChartStandard setNewChartDataStandard={setNewChartDataStandard} newChartDataStandard={newChartDataStandard} />
      :
        <DimmingChartSolar setNewChartDataSolar={setNewChartDataSolar} newChartDataSolar={newChartDataSolar} setErrorMessage={setErrorMessage} />
      }
      <ButtonStrategy buttonType="primary" style={{ background: buttonsColor, color: accentTextColor, justifySelf: "end", alignSelf: "center", width: "300px",
          marginTop: selectedTypeOption === "standard" ? "96px" : "66px", marginLeft: "-40px", padding: "8px 32px", lineHeight: "none"
        }}
        onClick={() => {sendInfo()}}
      >
        {t("create")}
      </ButtonStrategy>
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px", marginLeft: "-40px" }}>
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        {selectedTypeOption === "solar" && <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />}
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
      </div>
    </CardContainer>
  );
};
