import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Select } from "antd";
import { useAppSelector } from "../../../../redux/hooks";
import { Modal } from "react-bootstrap";
import { useLightingService } from "../../../../services/lightingService";
import { ButtonStrategy } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { solarBattNumericRegex } from "../../../../utils/regex";
import { Text } from "../../../../styles/shared-styles/text-sizes";

interface BatteryModalProps {
  showModal: boolean;
  setShowModal: any;
  selectedLamp: any;
}

interface DataToSend {
  cLimitVolt: number;
  cReturnVolt: number;
  disVolt: number;
  disReturnVolt: number;
  sysVolt: number;
  battType: number;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1400px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 650px;
    width: 60%;
    background: white;
    border: 0px;
  }
`;

export const BatteryConfigModal = ({ showModal, setShowModal, selectedLamp }: BatteryModalProps) => {
  const [showPreventPopup, setShowPreventPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cLimitVoltTemp, setCLimitVoltTemp] = useState("");
  const [cReturnVoltTemp, setCReturnVoltTemp] = useState("");
  const [disVoltTemp, setDisVoltTemp] = useState("");
  const [disReturnVoltTemp, setDisReturnVoltTemp] = useState("");
  const [sysVoltTemp, setSysVoltTemp] = useState("");
  const [battTypeTemp, setBattTypeTemp] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const { sendCommandToSolarBattery } = useLightingService();
  const cLimitVolt = useAppSelector((state) => state.solarParameters.cLimitVolt);
  const cReturnVolt = useAppSelector((state) => state.solarParameters.cReturnVolt);
  const disVolt = useAppSelector((state) => state.solarParameters.disVolt);
  const disReturnVolt = useAppSelector((state) => state.solarParameters.disReturnVolt);
  const sysVolt = useAppSelector((state) => state.solarParameters.sysVolt);
  const battType = useAppSelector((state) => state.solarParameters.battType);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  const systemOptions = [
    { value: 12, label: "12" },
    { value: 24, label: "24" },
  ];

  const batteryOptions = [
    { value: 10, label: t("leadAcidBattery") },
    { value: 11, label: t("lithiumBattery") },
  ];

  const handleSelectChangeSystem = (value:any) => {
    setSysVoltTemp(value);
  };

  const handleSelectChangeBattery = (value:any) => {
    setBattTypeTemp(value);
  };

  const manageSolarData = async () => {
    const dataToSend: DataToSend = {
      cLimitVolt: parseFloat(cLimitVoltTemp !== "" ? cLimitVoltTemp : cLimitVolt),
      cReturnVolt: parseFloat(cReturnVoltTemp !== "" ? cReturnVoltTemp: cReturnVolt),
      disVolt: parseFloat(disVoltTemp !== "" ? disVoltTemp : disVolt),
      disReturnVolt: parseFloat(disReturnVoltTemp !== "" ? disReturnVoltTemp : disReturnVolt),
      sysVolt: parseFloat(sysVoltTemp !== "" ? sysVoltTemp : sysVolt),
      battType: parseFloat(battTypeTemp !== "" ? battTypeTemp  : battType)
    }

    try {
      let data = await sendCommandToSolarBattery([selectedLamp], 82, dataToSend);

      if (data) {
        setSuccess(true);
      } else {
        setError(true);
      }

      setTimeout(() => {
        setSuccess(false);
        setError(false);
      }, 2000);
    } catch (error) {
      alert(error);
    }
  };

  const handlePreventPopup = () => {
    if (cLimitVoltTemp || cReturnVoltTemp || disVoltTemp || disReturnVoltTemp || sysVoltTemp || battTypeTemp) {
      setShowPreventPopup(true);
    } else {
      setShowModal(!showModal)
    }
  };

  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      {showPreventPopup ?
        <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)",
          borderRadius: "14px", padding: "30px", display: "flex", flexDirection: "column", position: "absolute",  alignItems: "center",
          justifyContent: "center", top: "255px", left: "198px", zIndex: 30
        }}>
          <Text>{t("Do you want to close this form? All the information will be lost")}</Text>
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowModal(!showModal)}}>{t("Accept")}</button>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowPreventPopup(false)}}>{t("Cancel")}</button>
          </div>
        </div>
      :
        <></>
      }
      <Modal.Body style={{ pointerEvents: showPreventPopup ? "none" : "auto", background: "white", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px",
        paddingRight: "0px", borderTopRightRadius: "0.5rem", borderTopLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem", borderBottomLeftRadius: "0.5rem", display: "flex",
        flexDirection: "column"}}
      >
        <div style={{ display: "flex", width: "100%", padding: "8px 10px 0px 0px", flexDirection: "column", background: "#ffffff", borderTopRightRadius: "0.5rem",
          borderTopLeftRadius: "0.5rem", justifyContent: "flex-end" }}
        >
          <Modal.Header
            closeButton
            style={{
              borderBottom: "none",
              background: "white",
              zIndex: 2
            }}
          />
        </div>
        <div style={{ paddingLeft: "50px", paddingRight: "50px", display: "flex", flexDirection: "column" }}>
          <div>
            <Text fontWeight="700" marginBottom="5px" color={mainTextColor} style={{fontSize: "22px" }}>{t("battConfigTitle")}</Text>
            <Text fontWeight="400" marginBottom="5px" color={mainTextColor} style={{fontSize: "16px" }}>{t("setDefaultStrategySubtitle")}</Text>
          </div>
          <div style={{ display: "flex", gap: "50px", flexWrap: "wrap", paddingTop: "40px", justifyContent: "center", height: "400px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "25px", marginRight: "20px" }}>
              <Text fontWeight="600" marginBottom="0px" color={accentButtonsColor} style={{ display: "flex", alignItems: "center", fontSize: "16px", height: "35px" }}>{t("chargeLimitVolt")}</Text>
              <Text fontWeight="600" marginBottom="0px" color={accentButtonsColor} style={{ display: "flex", alignItems: "center", fontSize: "16px", height: "35px" }}>{t("chargeReturnVolt")}</Text>
              <Text fontWeight="600" marginBottom="0px" color={accentButtonsColor} style={{ display: "flex", alignItems: "center", fontSize: "16px", height: "35px" }}>{t("disReturnVolt")}</Text>
              <Text fontWeight="600" marginBottom="0px" color={accentButtonsColor} style={{ display: "flex", alignItems: "center", fontSize: "16px", height: "35px" }}>{t("disVolt")}</Text>
              <Text fontWeight="600" marginBottom="0px" color={accentButtonsColor} style={{ display: "flex", alignItems: "center", fontSize: "16px", height: "35px" }}>{t("sysVolt")}</Text>
              <Text fontWeight="600" marginBottom="0px" color={accentButtonsColor} style={{ display: "flex", alignItems: "center", fontSize: "16px", height: "35px" }}>{t("battType")}</Text>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "25px", width: "20%" }}>
              <input className="form-control" maxLength={4} value={cLimitVoltTemp} onChange={(e) => solarBattNumericRegex.test(e.target.value) && setCLimitVoltTemp(e.target.value)}></input>
              <input className="form-control" maxLength={4} value={cReturnVoltTemp} onChange={(e) => solarBattNumericRegex.test(e.target.value) && setCReturnVoltTemp(e.target.value)}></input>
              <input className="form-control" maxLength={4} value={disReturnVoltTemp} onChange={(e) => solarBattNumericRegex.test(e.target.value) && setDisReturnVoltTemp(e.target.value)}></input>
              <input className="form-control" maxLength={4} value={disVoltTemp} onChange={(e) => solarBattNumericRegex.test(e.target.value) && setDisVoltTemp(e.target.value)}></input>
              <Select
                style={{ width: "100%" }}
                optionFilterProp="children"
                onChange={handleSelectChangeSystem}
                value={sysVoltTemp}
                options={systemOptions}
                className="selectSolar"
                popupClassName="selectSolar-dropdown"
              />
              <Select
                style={{ width: "100%" }}
                optionFilterProp="children"
                onChange={handleSelectChangeBattery}
                value={battTypeTemp}
                options={batteryOptions}
                className="selectSolar"
                popupClassName="selectSolar-dropdown"
              />
            </div>
          </div>
          {error ?
            <Text marginBottom="0px" color="red" style={{ display: "flex", alignSelf: "center", marginTop: "-1px" }}>{t("errorBlankInput")}</Text>
          :
            success ?
              <Text marginBottom="0px" color="green" style={{ display: "flex", alignSelf: "center", marginTop: "-1px" }}>{t("manageSolarDataOkMessage")}</Text>
            :
              <></>
          }
          <div style={{ display: "flex", gap: "36px", marginTop: error || success ? "10px" : "30px", justifyContent: "center" }}>
            <ButtonStrategy buttonType="primary" style={{ width: "24%", marginTop: "0px", marginLeft: "0px", padding: "8px 10px", lineHeight: "none",
              background: buttonsColor, color: accentTextColor,
              opacity:  selectedLamp.online === "1" && (cLimitVoltTemp !== "" || cReturnVoltTemp !== "" || disVoltTemp !== "" || disReturnVoltTemp !== "" || sysVoltTemp !== "" || battTypeTemp !== "") ? 1 : 0.5,
              cursor: selectedLamp.online === "1" && (cLimitVoltTemp !== "" || cReturnVoltTemp !== "" || disVoltTemp !== "" || disReturnVoltTemp !== "" || sysVoltTemp !== "" || battTypeTemp !== "") ? "" : "not-allowed" }}
              onClick={() => {(cLimitVoltTemp !== "" || cReturnVoltTemp !== "" || disVoltTemp !== "" || disReturnVoltTemp !== "" || sysVoltTemp !== "" || battTypeTemp !== "")
                && selectedLamp.online === "1" && manageSolarData()}
              } disabled={loading}
            >
                {t("saveData")}
            </ButtonStrategy>
          </div>
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
