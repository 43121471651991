import React, { useState } from "react";
import search from "../../../assets/new_icons/Search.svg";
import { useAppSelector } from "../../../redux/hooks";
import { TextSpan } from "../../../styles/shared-styles/text-sizes";
import OffBulb from "../../../assets/new_icons/OFF.svg";
import GatewayNode from "../../../assets/new_icons/Gateway nodePage.svg";
import ArrowRightFull from "../../../assets/new_icons/arrow_right_full.svg";
import bigPointIcon from "../../../assets/new_icons/big-point layout.svg";
import { ReactComponent as ID } from "../../../assets/new_icons/ID.svg"

export const GatewayListing = ({ selectedGroup, setSelectedGroup }: any) => {
  const groups = useAppSelector((state) => state.groups.groupsArr);
  const [searchGroup, setSearchGroup]: any = useState();

  const GatewayItem = ({ item }: any) => {
    const [isHovered, setIsHovered] = useState(false);

    let divStyle = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
      borderBottom: "1px solid #E7E0EC",
      cursor: "pointer",
      backgroundColor: isHovered
        ? "#f1f1f1"
        : item.groupId === selectedGroup?.groupId
        ? "#f1f1f1"
        : "",
    };

    return (
      <div
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
        onClick={() => {
          //   dispatch(saveUserPersonalInfoFromUsersListActionCreator(item));
          setSelectedGroup(item);
        }}
        style={divStyle}
      >
        <div
          style={{
            display: "flex",
            gap: "14px",
            paddingLeft: "6px",
          }}
        >
          <div>
            <TextSpan
              style={{ display: "block", marginBottom: 5 }}
              color="#475569"
              fontWeight="700"
            >
              {item?.groupName}
            </TextSpan>
            <div className="d-flex gap-3">
              <div className="d-flex gap-2">
                <TextSpan
                  className="d-flex align-items-center gap-1"
                  color="#a3aed0"
                  textType="caption"
                  //   style={{ alignSelf: "flex-end", paddingRight: "10px" }}
                >
                  <ID width={12} height={12} style={{ color: "#525252", filter: "opacity(0.5)", marginRight: "2px" }} />
                  451237429
                </TextSpan>
              </div>
              <div className="d-flex gap-2">
                <TextSpan
                  className="d-flex align-items-center gap-1"
                  color="#a3aed0"
                  textType="caption"
                  //   style={{ alignSelf: "flex-end", paddingRight: "10px" }}
                >
                  <img
                    style={{ filter: "opacity(0.5)", marginRight: "2px" }}
                    src={bigPointIcon}
                    height={12}
                  />
                  strategy name
                </TextSpan>
              </div>
              <div className="d-flex gap-2">
                <TextSpan
                  className="d-flex align-items-center"
                  color="#a3aed0"
                  textType="caption"
                  //   style={{ alignSelf: "flex-end", paddingRight: "10px" }}
                >
                  <img
                    style={{ filter: "opacity(0.5)", marginRight: "2px" }}
                    src={OffBulb}
                    height={12}
                  />
                  {item?.nodes?.length}
                </TextSpan>
              </div>
            </div>
          </div>
        </div>
        <span>
          <img
            style={{ filter: "opacity(0.5)", marginRight: "2px" }}
            src={ArrowRightFull}
            height={10}
          />
        </span>
      </div>
    );
  };

  return (
    <>
      <div>
        {groups
          ?.filter(
            (group: any) =>
              !searchGroup ||
              group.groupName
                ?.toLowerCase()
                .includes(searchGroup?.toLowerCase())
          )
          .map((item: any, index: number) => (
            <GatewayItem item={item} key={item.id} />
          ))}
      </div>
    </>
  );
};
