import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { changeBackgroundDashboardActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { useLightingService } from "../../../services/lightingService";
import { DimmingPopUpStyled } from "./DimmingPopUpStyled";
import { googleIcons } from "../../../utils/googleFontsIcons/icons";
import { Text, TextBox } from "../../../styles/shared-styles/text-sizes";

export const DimmingPopUp = () => {
  const [value, setValue] = useState(5);
  //node.dimming
  const [barColor, setBarColor] = useState("#d2d4d9");

  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const nodesInArea:any = useAppSelector((state) => state.devices.nodesInArea);
  const nodes:any = useAppSelector((state) => state.devices.nodes);
  const { sendCommandToLuminaire } = useLightingService();

  const { close, lightBulb } = googleIcons;

  const dispatch = useAppDispatch();
  const changeBackground = () => {
    dispatch(changeBackgroundDashboardActionCreator());
  };

  const handleChange = (event: any) => {
    const newValue = parseInt(event.target.value);
    setValue(newValue);
    setBarColor(
      `linear-gradient(to right, ${buttonsColor} ${newValue}%, #d2d4d9 ${newValue}%)`
    );
  };

  const handleDimming = () => {
    if (nodesInArea) {
      const nodesToSwitch = nodesInArea.filter(
        (node:any) => node.online
      );console.log("ya o no", nodesInArea, nodesToSwitch)
  
      sendCommandToLuminaire(nodesToSwitch, 3, value, 0);
    } else {
      const nodesToSwitch = nodes.filter(
        (node:any) => node.selected
      );
  
      sendCommandToLuminaire(nodesToSwitch, 3, value, 0);
    }
    changeBackground();
  };

  const { t } = useTranslation();

  return (
    <DimmingPopUpStyled buttonsColor={buttonsColor}>
      <div className="popup__button-container">
        <span
          onClick={() => {
            changeBackground();
          }}
          className="material-symbols-rounded popup__button-container__icon"
        >
          {close}
        </span>
      </div>
      <div className="popup__body-container">
        <Text
          fontWeight="700"
          marginBottom="8px"
          textType="subTitle"
          color="#000000"
        >
          {t("Dimming luminaires")}
        </Text>
        <Text
          fontWeight="400"
          marginBottom="18px"
          textType="caption"
          color="#000000"
          style={{ fontSize: "12px" }}
        >
          {t("Select the dimming you want to apply to the luminaires")}
        </Text>
        <div className="popup__body-container__box">
          <div
            className="popup__body-container__box__title"
            style={{ marginBottom: "8px" }}
          >
            <TextBox color={mainTextColor}>
              {t("Dimming")} {value}%
            </TextBox>
            <span className="material-symbols-rounded popup__body-container__box__title--icon">
              {lightBulb}
            </span>
          </div>
          <div>
            <input
              type="range"
              min="5"
              max="100"
              step="5"
              value={value}
              onChange={handleChange}
              className="popup__body-container__box__progress-bar"
              style={{ background: barColor }}
            />
            <div style={{ background: barColor }}></div>
          </div>
          <div className="popup__body-container__box__percentages">
            <TextBox textType="caption" color={mainTextColor}>
              {t("dimming5")}
            </TextBox>
            <TextBox textType="caption" color={mainTextColor}>
              {t("dimming50")}
            </TextBox>
            <TextBox textType="caption" color={mainTextColor}>
              {t("dimming100")}
            </TextBox>
          </div>
        </div>
        <div
          className="popup__body-container__button"
          onClick={() => {handleDimming()}}
        >
          <TextBox
            fontWeight="500"
            color={accentTextColor}
            style={{ fontSize: "16px" }}
          >
            {t("Dimming all")}
          </TextBox>
        </div>
      </div>
    </DimmingPopUpStyled>
  );
};
