import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { resetParametersActionCreator } from "../../../redux/slices/electricParametersSlice/electricParametersSlice";
  import { useConsumptionsService } from "../../../services/consumptionsService";
import ElectricalParametersChart from "./ElectricalParametersChart";
import { ElectricalParametersModal } from "./ElectricalParametersModal";
import { NextButton } from "../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import arrowBack from "../../../assets/new_icons/Arrow left.svg";
import arrowForward from "../../../assets/new_icons/Arrow right.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";

interface ElectricalParametersCardProps {
  selectedLamp: any,
}

export const ElectricalParametersCard = ({ selectedLamp }: ElectricalParametersCardProps) => {
  const dispatch = useAppDispatch();
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const [startDate, setStartDate]:any = useState();
  const [endDate, setEndDate]:any = useState();
  const [firstRender, setFirstRender] = useState(true);
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const [showActions, setShowActions] = useState(false);
  const [errorNoParams, setErrorNoParams] = useState(false);
  const [errorNoData, setErrorNoData] = useState(false);
  const [isVisibleDali, setIsVisibleDali] = useState([true, true, true, true]);
  const [isVisibleAllegro, setIsVisibleAllegro] = useState([true, true, true]);
  const [isVisibleDaliAllegro, setIsVisibleDaliAllegro] = useState([true, true, true, true, true]);
  const [isVisibleShuncom, setIsVisibleShuncom] = useState([true, true, true, true]);
  const [showModal, setShowModal] = useState(false);

  const { getDaliTwoDates, getAllegroTwoDates, getDaliAllegroTwoDates, getShuncomTwoDates } = useConsumptionsService();

  const hours = useAppSelector((state) => state.electricParameters.timestamps);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const { t } = useTranslation();

  const manageElectricalInfo = async (csv?:string) => {
    setErrorNoParams(false);
    /* if (startDate === null || endDate === null || (!currentSelected && !voltageSelected && !activePowerSelected && !powerGridVoltageSelected)) {
      setErrorNoParams(true)
    } */

    if (selectedLamp.manufacturer_name === "shuncom") {
      const result = await getShuncomTwoDates(selectedLamp.node_id, startDate, endDate, "shuncom", csv)
      if (!result) {
        setErrorNoData(true)
      }
    } else if (selectedLamp.protocol_name === "Allegro" || selectedLamp.protocol_name === "1-10") {
      /* if (startDate.getTime() !== endDate.getTime()) { */
        const result = await getAllegroTwoDates(selectedLamp.node_id, startDate, endDate, "allegro", csv)
        if (!result) {
          setErrorNoData(true)
        }
      /* } else if (startDate.getTime() === endDate.getTime()) {
        const result = await getAllegro(selectedLamp.node_id, startDate, "allegro")
        if (!result) {
          setErrorNoData(true)
        }
      } */
    } else if (selectedLamp.protocol_name === "Allegro-Dali") {
      /* if (startDate.getTime() !== endDate.getTime()) { */
        const result = await getDaliAllegroTwoDates(selectedLamp.node_id, startDate, endDate, "daliAllegro", csv)
        if (!result) {
          setErrorNoData(true)
        }
      /* } else if (startDate.getTime() === endDate.getTime()) {
        const result = await getDaliAllegro(selectedLamp.node_id, startDate, "daliAllegro")
        if (!result) {
          setErrorNoData(true)
        }
      } */
    } else {
      /* if (startDate.getTime() !== endDate.getTime()) { */
        const result = await getDaliTwoDates(selectedLamp.node_id, startDate, endDate, "dali", csv)
        if (!result) {
          setErrorNoData(true)
        }
      /* } else if (startDate.getTime() === endDate.getTime()) {
        const result = await getDali(selectedLamp.node_id, startDate, "dali")
        if (!result) {
          setErrorNoData(true)
        }
      } */
    }

    setTimeout(() => {
      setErrorNoParams(false);
      setErrorNoData(false);
    }, 2000);
  };

  const toggleDatasetDali = (index:any) => {
    setIsVisibleDali(prevState => prevState.map((item, idx) => idx === index ? !item : item));
  };

  const toggleDatasetAllegro = (index:any) => {
    setIsVisibleAllegro(prevState => prevState.map((item, idx) => idx === index ? !item : item));
  };

  const toggleDatasetDaliAllegro = (index:any) => {
    setIsVisibleDaliAllegro(prevState => prevState.map((item, idx) => idx === index ? !item : item));
  };

  const toggleDatasetShuncom = (index:any) => {
    setIsVisibleShuncom(prevState => prevState.map((item, idx) => idx === index ? !item : item));
  };

  useEffect(() => {
    if (firstRender) {
      dispatch(resetParametersActionCreator());
      /* if (selectedLamp.protocol_name === "Allegro" || selectedLamp.protocol_name === "1-10") {
        setIsVisibleAllegro([true, true, true])
      } else if (selectedLamp.protocol_name === "Allegro-Dali") {
        setIsVisibleDaliAllegro([true, true, true, true, true])
      } else {
        setIsVisibleDali([true, true, true, true])
      } */
      //dispatch(manageConsumptionSelectedActionCreator());
      //getLightTwoDates(selectedLamp.node_id, startDate, endDate, "current");
      //cuidado que esto está falseado

    }
    //que se parsee el time start y end de forma automática? En vez de hacerlo en el service
    setFirstRender(false)

  }, []);

  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        height: "100%"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Text color={mainTextColor} style={{ fontWeight: 500 }}>{t("Electrical parameters")}</Text>
        </div>
        <div style={{ textAlign: "right", marginTop: "-2px" }}>
          <img src={plusCircle} height={16} style={{ opacity: iconOpacity, cursor: "pointer" }} onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)} 
            onClick={() => {setShowActions(!showActions)}} />
          {showActions && (
            <article
              style={{
                position: "absolute",
                top: "42px",
                right: "24px",
                width: "180px",
                maxHeight: "224px",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                background: "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
                boxShadow: "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
                borderRadius: "4px",
                zIndex: 6
              }}
            >  
              <>
                <style>
                  {`
                    .org-span:hover {
                      background-color: #1c1b1f11;
                    }
                  `}
                </style>
                <span
                  className="org-span"
                  onClick={async () => {setShowActions(!showActions); setShowModal(true)}}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    isolation: "isolate",
                    paddingLeft: "15px",
                    paddingRight: "6px",
                    width: "100%",
                    minHeight: "56px",
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                >
                  {t("viewGraph")}
                </span>
                <span
                  className="org-span"
                  onClick={async () => {
                    if (hours.length > 0) {
                      setShowActions(!showActions);
                      manageElectricalInfo("csv")
                    }
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    isolation: "isolate",
                    paddingLeft: "15px",
                    paddingRight: "6px",
                    width: "100%",
                    minHeight: "56px",
                    cursor: hours.length > 0 ? "pointer" : "not-allowed",
                    textAlign: "left",
                  }}
                >
                  {t("downloadCSV")}
                </span>
              </>
            </article>
          )}
          {showModal && (
            <ElectricalParametersModal showModal={showModal} setShowModal={setShowModal} isVisibleDali={isVisibleDali} toggleDatasetDali={toggleDatasetDali}
              isVisibleAllegro={isVisibleAllegro} toggleDatasetAllegro={toggleDatasetAllegro} isVisibleDaliAllegro={isVisibleDaliAllegro} toggleDatasetDaliAllegro={toggleDatasetDaliAllegro}
              isVisibleShuncom={isVisibleShuncom} toggleDatasetShuncom={toggleDatasetShuncom} selectedLamp={selectedLamp}
            />     
          )}
        </div>
      </div>
      <div style={{ display: "flex", gap: "26px", marginTop: "2px" }}>
        {/* <div style={{ display: "flex", flexDirection: "column", gap: "4px", width: "25%" }}>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update:any) => {
              if (Array.isArray(update)) {
                setDates(update);
              } else {
                setDates([update, update]);
              }
            }}
          />
        </div> */}

        {selectedLamp.manufacturer_name === "shuncom" ?
          <div style={{ display: "flex", gap: "20px" }}>
            <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetShuncom(0)}>
              <div style={{ border: "1px solid #00C7F2", backgroundColor: isVisibleShuncom[0] ? "rgba(0, 199, 242, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("driverTemp")}</Text>
            </div>
            <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetShuncom(1)}>
              <div style={{ border: "1px solid #005882", backgroundColor: isVisibleShuncom[1] ? "rgba(100, 149, 237, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netVoltage")}</Text>
            </div>
            <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetShuncom(2)}>
              <div style={{ border: "1px solid #FFCB21", backgroundColor: isVisibleShuncom[2] ? "rgba(255, 203, 33, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("appPower")}</Text>
            </div>
            <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetShuncom(3)}>
              <div style={{ border: "1px solid #DC0124", backgroundColor: isVisibleShuncom[3] ? "rgba(220, 1, 36, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netCurrent")}</Text>
            </div>
          </div>
        :
          selectedLamp.protocol_name === "Allegro" || selectedLamp.protocol_name === "1-10" ?
            <div style={{ display: "flex", gap: "20px" }}>
              <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetAllegro(0)}>
                <div style={{ border: "1px solid #16D764", backgroundColor: isVisibleAllegro[0] ? "rgba(22, 215, 100, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netVoltage")}</Text>
              </div>
              <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetAllegro(1)}>
                <div style={{ border: "1px solid #005882", backgroundColor: isVisibleAllegro[1] ? "rgba(100, 149, 237, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("appPower")}</Text>
              </div>
              <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetAllegro(2)}>
                <div style={{ border: "1px solid #FFCB21", backgroundColor: isVisibleAllegro[2] ? "rgba(255, 203, 33, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netCurrent")}</Text>
              </div>
            </div>
          :
            selectedLamp.protocol_name === "Allegro-Dali" ?
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDaliAllegro(0)}>
                  <div style={{ border: "1px solid #16D764", backgroundColor: isVisibleDaliAllegro[0] ? "rgba(22, 215, 100, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("ledCurrent")}</Text>
                </div>
                <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDaliAllegro(1)}>
                  <div style={{ border: "1px solid #00C7F2", backgroundColor: isVisibleDaliAllegro[1] ? "rgba(0, 199, 242, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("driverTemp")}</Text>
                </div>
                <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDaliAllegro(2)}>
                  <div style={{ border: "1px solid #005882", backgroundColor: isVisibleDaliAllegro[2] ? "rgba(100, 149, 237, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netVoltage")}</Text>
                </div>
                <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDaliAllegro(3)}>
                  <div style={{ border: "1px solid #FFCB21", backgroundColor: isVisibleDaliAllegro[3] ? "rgba(255, 203, 33, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("appPower")}</Text>
                </div>
                <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDaliAllegro(4)}>
                  <div style={{ border: "1px solid #DC0124", backgroundColor: isVisibleDaliAllegro[4] ? "rgba(220, 1, 36, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netCurrent")}</Text>
                </div>
              </div>
            :
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDali(0)}>
                  <div style={{ border: "1px solid #16D764", backgroundColor: isVisibleDali[0] ? "rgba(22, 215, 100, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("ledCurrent")}</Text>
                </div>
                <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDali(1)}>
                  <div style={{ border: "1px solid #00C7F2", backgroundColor: isVisibleDali[1] ? "rgba(0, 199, 242, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("driverTemp")}</Text>
                </div>
                <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDali(2)}>
                  <div style={{ border: "1px solid #005882", backgroundColor: isVisibleDali[2] ? "rgba(100, 149, 237, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netVoltage")}</Text>
                </div>
                <div style={{ display: "flex", gap: "6px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDali(3)}>
                  <div style={{ border: "1px solid #FFCB21", backgroundColor: isVisibleDali[3] ? "rgba(255, 203, 33, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("appPower")}</Text>
                </div>
              </div>
        }
        <div style={{ display: "flex", flexDirection: "column", gap: "4px", marginLeft: "20px" }}>
          <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("sunsetDate")}</Text>
          <DatePicker
            className="datePickerElectrical"
            placeholderText={t("selectDate")}
            selectsRange={false}
            selected={startDate}
            onChange={(date: any) => setStartDate(date)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("sunriseDate")}</Text>
          <DatePicker
            className="datePickerElectrical"
            placeholderText={t("selectDate")}
            selectsRange={false}
            selected={endDate}
            onChange={(date: any) => setEndDate(date)}
          />
        </div>
        <NextButton accentButtonsColor={accentButtonsColor} style={{ width: "120px", padding: "8px 8px", fontSize: "12px", opacity: !startDate || !endDate ? 0.5 : 1,
          cursor: !startDate || !endDate ? "not-allowed" : "pointer", boxShadow: "0px 2px 4px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2)", alignSelf: "end", height: "36px" }}
          onClick={() => {manageElectricalInfo()}}
          disabled={!startDate || !endDate}
        >
          {t("viewData")}
        </NextButton>
      </div>
      {errorNoParams ?
        <div style={{ display: "flex", alignContent: "center", justifyContent: "center"}}>
          <Text marginBottom="0px" color="red" style={{ position: "absolute", textAlign: "center", marginTop: "6px" }}>{t("errorBlankInput")}</Text>
        </div>
      :
        errorNoData ?
          <div style={{ display: "flex", alignContent: "center", justifyContent: "center"}}>
            <Text marginBottom="0px" color="red" style={{ position: "absolute", textAlign: "center", marginTop: "6px" }}>{t("errorNoData")}</Text>
          </div>
        :
          <></>
      }
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "16px" }}>
        <ElectricalParametersChart isVisibleDali={isVisibleDali} isVisibleAllegro={isVisibleAllegro} isVisibleDaliAllegro={isVisibleDaliAllegro} isVisibleShuncom={isVisibleShuncom} selectedLamp={selectedLamp} />
      </div>
    </div>
  );
};
