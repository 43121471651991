import { useTranslation } from "react-i18next";

export function useRoleMapping() {
  const { t } = useTranslation();

  const roleMapping = {
    [t("owner")]: 1,
    [t("administrator")]: 2,
    [t("maintenance")]: 3,
    [t("observer")]: 4
  };

  return roleMapping;
};
