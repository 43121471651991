import { LatLng } from "leaflet";
import { useMemo, useRef } from "react";
import { Marker as IMarker } from "leaflet";
import { Marker } from "react-leaflet";
import { useAppDispatch } from "../../../redux/hooks";
import { turnAllFalseActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { errorToInitialStateActionCreator } from "../../../redux/slices/mqttSlice/mqttSlice";
import { Lamp } from "@/types/interfaces";
import { getGroupIcon } from "../../../utils/groupsIcon";

interface ReportsCustomMarkerProps {
  lamp: Lamp;
  selected: boolean;
  editable: boolean;
  center: LatLng;
  setMarker?: any;
  interactive?: boolean;
  filteredLamps?: any;
}

export const ReportsCustomMarker = ({ lamp, setMarker, selected, editable, center, interactive }: ReportsCustomMarkerProps) => {
  const lampRef = useRef<IMarker>(new IMarker(center));

  const icon = getGroupIcon(selected);
  const positioned = lamp.latitude ? true : false;
  const dispatch = useAppDispatch();
  const eventHandlers = useMemo(
    () => ({
      click() {
        setMarker(lamp);
        dispatch(turnAllFalseActionCreator());
        dispatch(errorToInitialStateActionCreator());
      },
      drag() {
        if (lampRef.current && positioned) {
          lamp.latitude = lampRef.current.getLatLng().lat.toString();
          lamp.longitude = lampRef.current.getLatLng().lng.toString();
        }
      },
    }),
    [lamp, setMarker, positioned, dispatch]
  );

  return (
    <>
      <Marker
        key={lamp.node_id}
        position={center}
        draggable={editable}
        riseOnHover
        eventHandlers={eventHandlers}
        riseOffset={100}
        zIndexOffset={selected || !positioned ? 150 : 0}
        icon={icon}
        interactive={interactive}
      ></Marker>
    </>
  );
};
