import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-draw/dist/leaflet.draw-src";
import L, { LatLng, LatLngBounds } from "leaflet";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setFiltersActionCreator } from "../../redux/slices/actualFilterSlice/actualFilterSlice";
import { loadNodesInAreaActionCreator } from "../../redux/slices/devices/devicesSlice";
import { filteredNodesInAreaActionCreator } from "../../redux/slices/devices/filteredDevicesSlice";
import { turnAllFalseActionCreator } from "../../redux/slices/graphicsSlice/graphicsSlice";
import { allowFlyModeActionCreator, setIsOpenedDetailActionCreator, setIsOpenedGroupActionCreator, setIsStaticViewActionCreator, setNodesInAreaActionCreator } from "../../redux/slices/uiSlice/uiSlice";

interface IProps {
  markers: any;
  nodes?: any;
  setNodesGroup: React.Dispatch<React.SetStateAction<string[]>>;
  nodesGroup: any;
  title: string;
}

export const DrawTool = ({ markers, nodes, setNodesGroup, nodesGroup, title }: IProps) => {
  const [includedNodes, setIncludedNodes]:any = useState();

  const map = useMap();
  const dispatch = useAppDispatch();
  const areaState = useAppSelector((state) => state.actualFilter.area);
  const groupPopUp = useAppSelector((state) => state.ui.isOpenedGroup);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const setZoom = () => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(true));
    dispatch(turnAllFalseActionCreator());
  };

  const pointInPolygon = (point: L.LatLng, vs: L.LatLng[]) => {
    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      const xi = vs[i].lng, yi = vs[i].lat;
      const xj = vs[j].lng, yj = vs[j].lat;
      
      const intersect = ((yi > point.lat) !== (yj > point.lat))
          && (point.lng < (xj - xi) * (point.lat - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;
    }
    return inside;
  };

  const handleShapeDrawn = (e: any) => {
    const drawnLayer = e.layer;
    const drawnPoints = drawnLayer.getLatLngs()[0];
    
    const includedMarkers = markers.filter((marker: any) => {
      if (marker.latitude && marker.longitude) {
        const markerPosition = L.latLng(marker.latitude, marker.longitude);
        return pointInPolygon(markerPosition, drawnPoints);
      }
      return false;
    });
  
    // Aquí puedes hacer lo que necesites con los marcadores incluidos
    console.log('Marcadores incluidos:', includedMarkers);

    if(includedMarkers) {
      setIncludedNodes(includedMarkers);
      dispatch(
        setFiltersActionCreator({
          all: true,
          area: true,
          on: false,
          off: false,
          disconnected: false,
          alarms: false,
          solar: false
        })
      );
  
      dispatch(setNodesInAreaActionCreator(true));
    }
  
    const nodesIds: Array<string> = [];
    includedMarkers.forEach((marker: any) => {
      nodesIds.push(marker.node_id);
    });
  
    setNodesGroup(nodesIds);
  
    dispatch(loadNodesInAreaActionCreator(includedMarkers));
    dispatch(filteredNodesInAreaActionCreator(includedMarkers));
    dispatch(setIsStaticViewActionCreator(false));
    dispatch(allowFlyModeActionCreator(true));
  };

  // código con tolerance
  /* const handleShapeDrawn = (e: any) => {
    const drawnBounds = e.layer.getBounds();
    
    // A cuanta más tolerancia, más cerca puedes crear el área sin que te coja los nodos que quedan fuera
    const tolerance = 20;
  
    const includedMarkers = markers.filter((marker: any) => {
      if (marker.latitude && marker.longitude) {
        const markerPosition = L.latLng(marker.latitude, marker.longitude);
        const isInBounds = drawnBounds.contains(markerPosition);
  
        const distanceToBoundary = Math.min(
          markerPosition.distanceTo(drawnBounds.getSouthWest()),
          markerPosition.distanceTo(drawnBounds.getNorthWest()),
          markerPosition.distanceTo(drawnBounds.getSouthEast()),
          markerPosition.distanceTo(drawnBounds.getNorthEast())
        );
  
        return isInBounds && distanceToBoundary > tolerance;
      }
      return false;
    });
  
    if(includedMarkers) {
      setIncludedNodes(includedMarkers);
      dispatch(
        setFiltersActionCreator({
          all: true,
          area: true,
          on: false,
          off: false,
          disconnected: false,
          alarms: false,
          solar: false
        })
      );
  
      dispatch(setNodesInAreaActionCreator(true));
    }
  
    const nodesIds: Array<string> = [];
    includedMarkers.forEach((marker: any) => {
      nodesIds.push(marker.node_id);
    });
  
    setNodesGroup(nodesIds);
  
    dispatch(loadNodesInAreaActionCreator(includedMarkers));
    dispatch(filteredNodesInAreaActionCreator(includedMarkers));
    dispatch(setIsStaticViewActionCreator(false));
    dispatch(allowFlyModeActionCreator(true));
  }; */

  // código sin tolerance por si hay que ir hacia atrás
  /* const handleShapeDrawn = (e: any) => {
    const drawnBounds = e.layer.getBounds();

    const includedMarkers = markers.filter((marker: any) => {
      if (marker.latitude && marker.longitude) {
        const markerPosition = L.latLng(marker.latitude, marker.longitude);
        return drawnBounds.contains(markerPosition);
      }
    });

    if(includedMarkers) {
      setIncludedNodes(includedMarkers)
      dispatch(
        setFiltersActionCreator({
          all: true,
          area: true,
          on: false,
          off: false,
          disconnected: false,
          alarms: false,
          solar: false
        })
      )

      dispatch(setNodesInAreaActionCreator(true));
    }

    const nodesIds: Array<string> = [];
    includedMarkers.forEach((marker:any) => {    
      nodesIds.push(marker.node_id)
    });
    
    setNodesGroup(nodesIds)

    dispatch(loadNodesInAreaActionCreator(includedMarkers));
    dispatch(filteredNodesInAreaActionCreator(includedMarkers));
    dispatch(setIsStaticViewActionCreator(false));
    dispatch(allowFlyModeActionCreator(true));
  }; */

  useEffect(() => {
    const drawOptions: any = {
      draw: {
        marker: false,
        polygon: { showArea: true },
        polyline: false,
        rectangle: false,
        circle: false,
        circlemarker: false,
      },
      edit: false,
    };
    
    const drawControl = new L.Control.Draw(drawOptions);
    map.addControl(drawControl);
    
    const button = document.querySelector('.leaflet-draw-draw-polygon');
    if (button) {
      button.setAttribute('data-text', title);
    }

    map.on('draw:drawstop', () => {
      if (button) {
        button.classList.remove('enabled');
      }
    });

    map.on('draw:drawstart', () => {
      const firstElement = document.querySelector('.leaflet-draw-actions li:first-child a');
      if (firstElement) firstElement.textContent = t("finish");
  
      const middleElement = document.querySelectorAll('.leaflet-draw-actions li:not(:first-child):not(:last-child) a');
      middleElement.forEach((element) => {
        element.textContent = t("deleteLastPoint");
      });
  
      const lastElement = document.querySelector('.leaflet-draw-actions li:last-child a');
      if (lastElement) lastElement.textContent = t("Cancel");

      if (button) {
        button.classList.add('enabled');
      }
    });

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          const shapeElement = document.querySelector('.leaflet-draw-tooltip-single span');
          if (shapeElement) shapeElement.textContent = t("startSelectArea");
        }
      });
    });
  
    observer.observe(document.body, { childList: true, subtree: true });

    map.on(L.Draw.Event.CREATED, handleShapeDrawn);

    if (areaState) {
      const southWest = new LatLng(
        includedNodes[0].latitude,
        includedNodes[0]?.longitude
      );

      const northEast = new LatLng(
        includedNodes[includedNodes?.length - 1]?.latitude,
        includedNodes[includedNodes?.length - 1]?.longitude
      );

      if (!groupPopUp) {
        map.flyToBounds(new LatLngBounds(southWest, northEast), {
          animate: true,
          maxZoom: 18,
          padding: [50, 50],
        });

        dispatch(
          setFiltersActionCreator({
            all: false,
            area: false,
            on: false,
            off: false,
            disconnected: false,
            alarms: false,
            solar: false
          })
        )
      }
      
      map.getZoom() > 7
        ? setZoom()
        : setTimeout(() => {
            setZoom();
          }, 2000);
    }

    return () => {
      map.removeControl(drawControl);
      map.off(L.Draw.Event.CREATED, handleShapeDrawn);
      observer.disconnect();
    };
  }, [map, markers, nodesGroup]);

  useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = `
      .leaflet-draw-actions li a {
        font-size: 14px !important;
        color: ${accentButtonsColor} !important;
        font-weight: bold;
        background-color: rgba(163, 174, 208);
      }

      .leaflet-draw-tooltip-single {
        font-size: 14px !important;
        color: ${accentButtonsColor} !important;
        font-weight: bold;
        background-color: rgba(163, 174, 208);
      }
    `;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [accentButtonsColor]);

  return null;
};
