import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Reports } from "../../../types/interfaces";

const initialState: Reports = {
  generalTotal: "",
  generalStandard: 0,
  generalSolar: 0,
  generalHybrid: 0,
  generalGateways: 0,
  generalSwitchboards: 0,
  generalRgb: 0,
  alarmsTotal: "",
  alarmsSolved: "",
  alarmsPending: "",
  usersTotal: "",
  usersOwner: "",
  usersAdmin: "",
  usersExternal: "",
  usersObserver: "",
  usersMaintenance: "",
  standardEnergyConsumed: 0,
  standardEnergySaved: 0,
};

const reportsSlice = createSlice({
  name: "node",
  initialState: initialState,
  reducers: {
    saveGeneralReportsInfo: (previousState, action: PayloadAction<Reports>) => ({
      ...previousState,
      ...action.payload
    }),

    saveStandardReportsInfo: (previousState, action: PayloadAction<Reports>) => ({
      ...previousState,
      ...action.payload
    })
  },
});

export const reportsReducer = reportsSlice.reducer;

export const {
  saveGeneralReportsInfo: saveGeneralReportsInfoActionCreator,
  saveStandardReportsInfo: saveStandardReportsInfoActionCreator,
} = reportsSlice.actions;
