import axios from "axios";

class LogoutService {

  public async logout(token: string): Promise<void> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_LOCAL_BASE_URL as string}/profile/logout`, {
        method: "PATCH",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response) {
        throw new Error("There is no response data.");
      }
    } catch (error) {
      throw new Error(
        "There was an error during logout. Please try again later."
      );
    }
  }
}

const logoutService = new LogoutService();
export default logoutService;
