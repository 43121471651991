type HandleOrganizationChangeFunction = (newOrgId: string, oldOrgId: string) => void;

class mqttManagerService {
  private static instance: mqttManagerService | null = null;
  private client: any;
  private handleOrganizationChange: HandleOrganizationChangeFunction | null = null;

  private constructor() {}

  public static getInstance(): mqttManagerService {
    if (!mqttManagerService.instance) {
      mqttManagerService.instance = new mqttManagerService();
    }
    return mqttManagerService.instance;
  }

  public setClient(client: any): void {
    this.client = client;
  }

  public setHandleOrganizationChange(func: HandleOrganizationChangeFunction): void {
    this.handleOrganizationChange = func;
  }

  public changeOrganization(newOrgId: string, oldOrgId: string): void {
    if (this.handleOrganizationChange) {
      this.handleOrganizationChange(newOrgId, oldOrgId);
    } else {
      console.error("HandleOrganizationChange function is not set.");
    }
  }
}

export default mqttManagerService;
