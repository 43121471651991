import { getGroupsActionCreator, updateGroupsArrDeleteActionCreator, updateGroupsArrNameOrDevicesActionCreator } from "../../slices/groupsSlice/groupsSlice";
import { loadDevicesActionCreator } from "../../slices/devices/devicesSlice";

export interface GroupInfo {
  groupId: number;
  groupName: string;
  star: string;
  nodes: any[];
}

export const getGroupsAction = (groups: GroupInfo | any) => async (dispatch: any) => {
  dispatch(getGroupsActionCreator({ groupsArr: groups }));
};

export const postGroupAction = (tempDevices: any) => async (dispatch: any) => {
  dispatch(loadDevicesActionCreator(tempDevices));
};

export const updateGroupsArrAction = (type: string, newInfo: any) => async (dispatch: any) => {
  if (type === "delete") {
    dispatch(updateGroupsArrDeleteActionCreator(newInfo));
  } else {
    dispatch(updateGroupsArrNameOrDevicesActionCreator(newInfo));
  }
};

export const addRemoveDeviceAction = (tempDevices: any) => async (dispatch: any) => {
  dispatch(loadDevicesActionCreator(tempDevices));
};
