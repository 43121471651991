import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectedOrganization } from "../../../types/interfaces";

const initialState: SelectedOrganization = {
  id: "",
  orgName: "",
  latitude: "",
  longitude: "",
  city: "",
  country: "",
  limitUsers: 0,
  limitGroups: 0,
  limitStrategies: 0,
  limitEvents: 0,
  currency: "",
  price: 0,
  co2: 0,
  img: "",
  navBarColor: "",
  buttonsColor: "",
  accentButtonsColor: "",
  mainTextColor: "",
  supportTextColor: "",
  accentTextColor: "",
  strategyColor1: "",
  strategyColor1Name: "",
  strategyColor2: "",
  strategyColor2Name: "",
  strategyColor3: "",
  strategyColor3Name: "",
  strategyColor4: "",
  strategyColor4Name: "",
  strategyColor5: "",
  strategyColor5Name: "",
};

const selectedOrganizationSlice = createSlice({
  name: "selectedOrganization",
  initialState: initialState,
  reducers: {
   saveSelectedOrgInfo: (previousData, action: PayloadAction<SelectedOrganization>) => ({
      ...previousData,
      id: action.payload.id,
      orgName: action.payload.orgName,
      latitude: action.payload.latitude,
      longitude: action.payload.longitude,
      city: action.payload.city,
      country: action.payload.country,
      limitUsers: action.payload.limitUsers,
      limitGroups: action.payload.limitGroups,
      limitStrategies: action.payload.limitStrategies,
      limitEvents: action.payload.limitEvents,
      currency: action.payload.currency,
      price: action.payload.price,
      co2: action.payload.co2,
      img: action.payload.img,
      navBarColor: action.payload.navBarColor,
      buttonsColor: action.payload.buttonsColor,
      accentButtonsColor: action.payload.accentButtonsColor,
      mainTextColor: action.payload.mainTextColor,
      supportTextColor: action.payload.supportTextColor,
      accentTextColor: action.payload.accentTextColor,
      strategyColor1: action.payload.strategyColor1,
      strategyColor1Name: action.payload.strategyColor1Name,
      strategyColor2: action.payload.strategyColor2,
      strategyColor2Name: action.payload.strategyColor2Name,
      strategyColor3: action.payload.strategyColor3,
      strategyColor3Name: action.payload.strategyColor3Name,
      strategyColor4: action.payload.strategyColor4,
      strategyColor4Name: action.payload.strategyColor4Name,
      strategyColor5: action.payload.strategyColor5,
      strategyColor5Name: action.payload.strategyColor5Name
    }),

  }
});

export const selectedOrganizationReducer = selectedOrganizationSlice.reducer;

export const {
  saveSelectedOrgInfo: saveSelectedOrgInfoActionCreator
  
} = selectedOrganizationSlice.actions;
