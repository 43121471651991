import { useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import i18n from "./i18n";
import { LatLng } from "leaflet";
import { useAppSelector, useAppDispatch } from "./redux/hooks";
import { RootState, store } from "./redux/store";
import { getGroupsAction } from "./redux/actions/groupsActions/groupsActions";
import { turnAllFalseActionCreator } from "./redux/slices/graphicsSlice/graphicsSlice";
import { errorToInitialStateActionCreator } from "./redux/slices/mqttSlice/mqttSlice";
import {
  setIsFullViewActionCreator, setIsListViewActionCreator, setIsHybridViewActionCreator, setIsOpenedDetailActionCreator, setIsOpenedGroupActionCreator,
  setFirstRenderMapToTrueActionCreator, setIsStaticViewActionCreator, turnRgbFalseActionCreator
} from "./redux/slices/uiSlice/uiSlice";
import { useGroupsService } from "./services/groupsService";
import { useLightingService } from "./services/lightingService";
import { useOrganizationsService } from "./services/selectedOrganizationService";
import { AlarmsPage } from "./components/AlarmsPage/AlarmsPage";
import { CustomMap } from "./components/CustomMaps/CustomMap/CustomMap";
import { DataCardList } from "./components/DataCardList/DataCardList";
import { DimmingPopUp } from "./components/PopUps/DimmingPopUp/DimmingPopUp";
import { ErrorPopUp } from "./components/ErrorPopUp/ErrorPopUp";
import { EventsPage } from "./components/EventsPage/EventsPage";
import { ForgotPasswordPage } from "./pages/ForgotPassword/ForgotPasswordPage";
import { ResetPasswordPage } from "./pages/ForgotPassword/ResetPasswordPage";
import { GatewayPage } from "./components/GatewayPage/GatewayPage";
import { GroupsPage } from "./components/GroupsPage/GroupsPage";
import { HybridMenuTable } from "./components/Lists/HybridMenu/HybridMenuTable";
import { Layout } from "./components/Layout/Layout";
import { ListTable } from "./components/Lists/ListMenu/ListTable";
import { LocalWeather } from "./components/LocalWeather/LocalWeather";
import { LuminairePage } from "./components/LuminairePage/LuminairePage";
import { OrganizationSettingsPage } from "./components/OrganizationSettingsPage/OrganizationSettingsPage";
import { ProfilePage } from "./components/ProfilePage/ProfilePage";
import { ReportsPage } from "./components/ReportsPage/ReportsPage";
import { RGBPopUp } from "./components/PopUps/RGBPopUp/RGBPopUp";
import { SillyMap } from "./components/CustomMaps/CustomMap/SillyMap";
import { StrategiesPage } from "./components/StrategiesPage/StrategiesPage";
import { UsersPage } from "./components/UsersPage/UsersPage";
import { Login } from "./pages/Login/loginPage";
import { NotFoundPage } from "./pages/ResponsePages/404page";
import { ErrorPage } from "./pages/ResponsePages/errorPage";
import { ComingSoon } from "./pages/ResponsePages/comingSoonPage";
import { DisabledPage } from "./pages/ResponsePages/401page";
import { NoActivePage } from "./pages/ResponsePages/noActivePage";
import { ThemeProvider } from "styled-components";
import { LayoutGreyShadow, LayoutMenu, LayoutFirstItem, LayoutSecondItem, LayoutThirdItem } from "./AppStyled";
import { styledMainTheme } from "./styles/shared-styles/styleMainTheme";
import "bootstrap/dist/css/bootstrap.min.css"; // import the Bootstrap CSS file
import "bootstrap/dist/js/bootstrap.js"; // import the Bootstrap JS file
import { ReactComponent as GridIcon } from "./assets/new_icons/grid.svg";
import { ReactComponent as ViewHybridIcon } from "./assets/new_icons/view-hybrid.svg";
import { ReactComponent as ViewListIcon } from "./assets/new_icons/view-list.svg";

import { AuthProvider } from "react-oidc-context";
import { SsoLogin } from "./pages/SsoLogin/SsoLoginPage";
import { MemoryMonitor } from './components/MemoryMonitor';

const initialPosition = new LatLng(0, 0);
const App = (): JSX.Element => {
  const darkTheme = false; //This variable will be changed for a ui state variable actualtheme
  const sessionId = localStorage.getItem("sessionId"); // esto habría que implementarlo?
  const theme = darkTheme ? styledMainTheme.dark : styledMainTheme.light;

  const mapFullSize = { width: "100%", height: "100%" };
  const mapHalfSize = { height: "100%" };

  const [center, setCenter] = useState(initialPosition);

  const dispatch = useAppDispatch();
  const { getAllNodes }: any = useLightingService();
  const { getSelectedOrganizationInfo }: any = useOrganizationsService();
  const { getGroups } = useGroupsService();

  const nodesInArea = useAppSelector((state) => state.ui.nodesInArea);
  const filteredNodes:any = useAppSelector((state) => state.filteredDevices.nodes);
  const nodesFromGroups = useAppSelector((state) => state.filteredDevices.nodesFromGroups);
  const allAreaNodes = useAppSelector((state) => state.devices.nodesInArea);
  const backgroundDashboard = useAppSelector((state) => state.graphics.backgroundDashboard);
  const backgroundErrorPopUp = useAppSelector((state) => state.graphics.backgroundErrorPopUp);
  const fullView = useAppSelector((state) => state.ui.isFullView);
  const hybridView = useAppSelector((state) => state.ui.isHybridView);
  const listView = useAppSelector((state) => state.ui.isListView);
  const allNodes: any = useAppSelector((state) => state.devices.nodes);
  const allDevices = useAppSelector((state) => state.filteredDevices);
  const filterSelected = useAppSelector((state) => state.actualFilter);
  const isRgbVisible = useAppSelector((state) => state.ui.rgb);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const reduxPersistOrgs = useAppSelector((state) => state.filteredDevices.organizations);
  const reduxPersistSelectedOrgId: any = useAppSelector((state) => state.filteredDevices.selectedOrganizationId);
  const reduxPersistServices = useAppSelector((state) => state.filteredDevices.services);
  const reduxPersistSelectedServiceId: any = useAppSelector((state) => state.filteredDevices.selectedServiceId);

  const [gridOpacity, setGridOpacity]: any = useState(fullView ? 1 : 0.2);
  const [hybridOpacity, setHybridOpacity] = useState(hybridView ? 1 : 0.2);
  const [listOpacity, setListOpacity] = useState(listView ? 1 : 0.2);

  const setStatesToFalse = () => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
    dispatch(turnAllFalseActionCreator());
    dispatch(errorToInitialStateActionCreator());
    dispatch(setFirstRenderMapToTrueActionCreator());
    dispatch(turnRgbFalseActionCreator());
  };

  const toggleFullViewVisibility = () => {
    setHybridOpacity(0.2);
    setListOpacity(0.2);
    setStatesToFalse();
    dispatch(setIsFullViewActionCreator());
  };

  const toggleListViewVisibility = () => {
    setHybridOpacity(0.2);
    setGridOpacity(0.2);
    setStatesToFalse();
    dispatch(setIsListViewActionCreator());
  };

  const toggleHybridViewVisibility = () => {
    setGridOpacity(0.2);
    setListOpacity(0.2);
    setStatesToFalse();
    dispatch(setIsHybridViewActionCreator());
  };
  
  const isBrowser = typeof window !== "undefined";

  const oidcConfig = {
    authority: process.env.REACT_APP_SSO_A as string,
    client_id: process.env.REACT_APP_SSO_C as string,
    // client_secret: ssoClientSecret,
    response_type: "code",
    redirect_uri: isBrowser ? window.location.origin : "http://localhost", // Default to localhost if not in browser
    scope: "openid profile email",
    post_logout_redirect_uri: process.env.REACT_APP_SSO_REDIRECT as string,
    automaticSilentRenew: false,
    loadUserInfo: true
  };

  /* window.addEventListener("load", async () => { 
    console.log("page is fully loaded", reduxNodes);
    const devices: Devices = {
      gateways: [],
      nodes: reduxNodes,
      organizations: reduxOrgs,
      selectedOrganizationId: reduxSelectedOrgId,
      services: reduxServices,
      selectedServiceId: reduxSelectedServiceId
    };
    console.log("Devices", devices);

    dispatch(loadDevicesActionCreator(devices));
    dispatch(filteredAllDevicesActionCreator(devices));
  }); */

  useEffect(() => {
    if (router.state.location.pathname !== "/") {
      const refresh = async () => {
        await getAllNodes(reduxPersistSelectedOrgId, reduxPersistSelectedServiceId, reduxPersistOrgs, reduxPersistServices);
        await getSelectedOrganizationInfo(reduxPersistSelectedOrgId, reduxPersistSelectedServiceId);
        let getGroupsResponse = await getGroups(reduxPersistSelectedOrgId, reduxPersistSelectedServiceId);
        if (getGroupsResponse) {
          store.dispatch(getGroupsAction(getGroupsResponse));
        } else {
          console.log("error in getGroups api");
          return false;
        }
      }
      refresh();
      //dispatch(setFiltersActionCreator({ all: false, area: false, on: false, off: false, disconnected: false, alarms: false }));
      dispatch(setIsOpenedDetailActionCreator(false));
      dispatch(setIsOpenedGroupActionCreator(false));
    }
  }, []);

  useEffect(() => {
    if (fullView) {
      setGridOpacity(1);
      setHybridOpacity(0.2);
      setListOpacity(0.2);
    }
    navigator.geolocation.getCurrentPosition((position) => {
      const center = new LatLng(
        position.coords.latitude,
        position.coords.longitude
      );
      setCenter(center);
    });
  }, [allNodes, fullView]);

  const user = useAppSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user) {
      const userLanguageCode = (function () {
        switch (user.language_name) {
          case "English":
            return "en";
            break;
          case "Spanish":
            return "es";
            break;
          case "French":
            return "fr";
            break;
          case "Kinyarwanda":
            return "rw";
            break;
          case "Swahili":
            return "sw";
            break;
          case "Arabic":
            return "ar";
            break;
          default:
            break;
        }
      })();

      // Set the language in Redux
      localStorage.setItem(
        "selectedLanguage",
        (userLanguageCode as string) || ""
      );
      // Change the language in the i18n
      i18n.changeLanguage(userLanguageCode);
    }
  }, [user]);

  useEffect(() => {
    document.documentElement.style.setProperty('--border-color-toolbar', accentButtonsColor);
  }, [accentButtonsColor]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
      /* element: <SsoLogin /> */
    },
    {
      path: "/dashboard",
      element: (
        <div>
          {backgroundDashboard ?
            isRgbVisible ?
              (
                <LayoutGreyShadow>
                  <RGBPopUp />
                </LayoutGreyShadow>
              )
              :
              (
                <LayoutGreyShadow>
                  <DimmingPopUp />
                </LayoutGreyShadow>
              )
            :
            (
              <></>
            )}
          {backgroundErrorPopUp ? (
            <LayoutGreyShadow>
              <ErrorPopUp />
            </LayoutGreyShadow>
          ) : (
            <></>
          )}
          <Layout>
            <div className="main-page">
              <DataCardList />
              <LayoutMenu>
                <LayoutFirstItem>
                  <ViewHybridIcon
                    height={26} width={26} style={{ color: supportTextColor, opacity: hybridOpacity }}
                    onMouseEnter={() => !hybridView ? setHybridOpacity(1) : <></>}
                    onMouseLeave={() => !hybridView ? setHybridOpacity(0.2) : <></>}
                    onClick={toggleHybridViewVisibility}
                  />
                  {/* <img
                    src={viewHybrid}
                    style={{ opacity: hybridOpacity }}
                    onMouseEnter={() => !hybridView ? setHybridOpacity(1) : <></>}
                    onMouseLeave={() => !hybridView ? setHybridOpacity(0.2) : <></>}
                    width={26}
                    height={26}
                    onClick={toggleHybridViewVisibility}
                  /> */}
                </LayoutFirstItem>
                <LayoutSecondItem>
                  <ViewListIcon
                    height={21} width={21} style={{ color: supportTextColor, opacity: listOpacity }}
                    onMouseEnter={() => !listView ? setListOpacity(1) : <></>}
                    onMouseLeave={() => !listView ? setListOpacity(0.2) : <></>
                    }
                    onClick={toggleListViewVisibility}
                  />
                  {/* <img
                    src={viewList}
                    style={{ opacity: listOpacity }}
                    onMouseEnter={() => !listView ? setListOpacity(1) : <></>}
                    onMouseLeave={() => !listView ? setListOpacity(0.2) : <></>}
                    width={21}
                    height={21}
                    onClick={toggleListViewVisibility}
                  /> */}
                </LayoutSecondItem>
                <LayoutThirdItem>
                  <GridIcon
                    height={15} width={15} style={{ color: supportTextColor, opacity: gridOpacity }}
                    onMouseEnter={() => !fullView ? setGridOpacity(1) : <></>}
                    onMouseLeave={() => !fullView ? setGridOpacity(0.2) : <></>}
                    onClick={toggleFullViewVisibility}
                  />
                  {/* <img
                    src={grid}
                    style={{ opacity: gridOpacity }}
                    onMouseEnter={() => !fullView ? setGridOpacity(1) : <></>}
                    onMouseLeave={() => !fullView ? setGridOpacity(0.2) : <></>}
                    width={15}
                    height={15}
                    onClick={toggleFullViewVisibility}
                  /> */}
                </LayoutThirdItem>
              </LayoutMenu>
              {allNodes.length > 0 ? (
                <>
                  {fullView && (
                    <>
                      <CustomMap mapSize={mapFullSize} center={center} />
                      <LocalWeather />
                    </>
                  )}
                  {listView && <ListTable />}
                  {hybridView && (
                    <>
                      <div className="row" style={{ minHeight: "84.2%" }}>
                        <div className="col-6">
                          <CustomMap center={center} mapSize={mapHalfSize} />
                          <LocalWeather />
                        </div>
                        <div className="col-6">
                          <HybridMenuTable />
                        </div>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {fullView && (
                    <>
                      <SillyMap mapSize={mapFullSize} center={center} />
                      <LocalWeather />
                    </>
                  )}
                  {listView && <ListTable />}
                  {hybridView && (
                    <>
                      <div className="row" style={{ minHeight: "84.2%" }}>
                        <div className="col-6">
                          <SillyMap center={center} mapSize={mapHalfSize} />
                          <LocalWeather />
                        </div>
                        <div className="col-6">
                          <HybridMenuTable />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </Layout>
        </div>
      ),
    },
    {
      path: "/luminaire/:nodeId",
      element: (
        <Layout>
          <LuminairePage />
        </Layout>
      ),
    },
    {
      path: "/strategies",
      element: (
        <Layout>
          <StrategiesPage />
        </Layout>
      ),
    },
    {
      path: "/profile",
      element: (
        <Layout>
          <ProfilePage />
        </Layout>
      ),
    },
    {
      path: "/forgot-password",
      element: <ForgotPasswordPage />,
    },
    {
      path: "/reset-password",
      element: <ResetPasswordPage />
    },
    {
      path: "/users",
      element: (
        <Layout>
          <UsersPage />
        </Layout>
      ),
    },
    {
      path: "/events",
      element: (
        <Layout>
          <EventsPage />
        </Layout>
      ),
    },
    {
      path: "/404",
      element: (
        <Layout>
          <NotFoundPage />
        </Layout>
      ),
    },
    {
      path: "/error",
      element: (
        <Layout>
          <ErrorPage />
        </Layout>
      ),
    },
    {
      path: "/coming-soon",
      element: (
        <Layout>
          <ComingSoon />
        </Layout>
      ),
    },
    {
      path: "/disabled-demo-mode",
      element: (
        <Layout>
          <DisabledPage />
        </Layout>
      ),
    },
    {
      path: "/no-active",
      element: (
        <NoActivePage />
      ),
    },
    {
      path: "/alarms",
      element: (
        <Layout>
          <AlarmsPage />
        </Layout>
      ),
    },
    {
      path: "/gateway",
      element: (
        <Layout>
          <GatewayPage />
        </Layout>
      ),
    },
    {
      path: "/organization-settings",
      element: (
        <Layout>
          <OrganizationSettingsPage />
        </Layout>
      ),
    },
    {
      path: "/groups",
      element: (
        <Layout>
          <GroupsPage />
        </Layout>
      ),
    },
    {
      path: "/reports",
      element: (
        <Layout>
          <ReportsPage />
        </Layout>
      ),
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <MemoryMonitor />
      <RouterProvider router={router} />
    </ThemeProvider>
  );

  //!SSO
  /* return (
    <ThemeProvider theme={theme}>
      <AuthProvider {...oidcConfig}>
        <RouterProvider router={router} />
      </AuthProvider>
    </ThemeProvider>
  ); */
};

export default App;
