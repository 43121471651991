import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Checkbox, Collapse, Empty } from "antd";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { managedUserGroupsArray } from "../../../../types/interfaces";
import { useUsersService } from "../../../../services/usersService";
import { IconStyled } from "../../../ProfilePage/ProfilePageStyled";
import { CompanyProfile, GroupList } from "../../UsersPageStyled";
import { ButtonOutlined } from "../../../../styles/shared-styles/buttons-sizes";
import { Text, TextSpan } from "../../../../styles/shared-styles/text-sizes";
import companyFlag from "../../../../assets/new_icons/Company.svg";
import groupsIcon from "../../../../assets/new_icons/Groups layout.svg";
import luminaireIcon from "../../../../assets/new_icons/luminaire detailCard.svg";

export interface GroupsProps {}

export const Groups = ({}: GroupsProps) => {
  const [dataToUpdate, setDataToUpdate] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorExistingData, setErrorExistingData] = useState<boolean>(false);
  const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  
  const { getManagedUserGroups, postManagedUserGroups } = useUsersService();
  const userRole = useAppSelector((state) => state.user.role_name);
  const userInfo = useAppSelector((state: RootState) => state.managedUser);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const [groupsFromRedux, setGroupsFromRedux] = useState(userInfo?.groups);
  const { Panel } = Collapse;
  const { t } = useTranslation();

  const handleDataToUpdate = (item:any, group:any, e: any) => {
    let obj = {
      orgId: item.orgId,
      servId: item.servId,
      groups: [{
        id: group.id,
        selected: e.target.checked
      }]
    }

    setDataToUpdate(prevData => [...prevData, obj]);
  };

  const handleUpdate = async (data:any) =>  {
    setIsLoading(true);
    let filteredArray:any = [];
    for (let i = 0; i < data.length; i++) {
      let orgId = data[i].orgId;
      let serviceId = data[i].serviceId;
      let groups = data[i].groups;

      let dataIndex = filteredArray.findIndex((item:any) => item.orgId === orgId && item.serviceId === serviceId);
      if (dataIndex === -1) {
        filteredArray.push({orgId: orgId, serviceId: serviceId, groups: []});
        dataIndex = filteredArray.length - 1;
      }

      for (let j = 0; j < groups.length; j++) {
        let groupId = groups[j].id;
        let selected = groups[j].selected;
        let groupIndex = filteredArray[dataIndex].groups.findIndex((group:any) => group.id === groupId);
        if (groupIndex !== -1) {
          filteredArray[dataIndex].groups[groupIndex].selected = selected;
        } else {
          filteredArray[dataIndex].groups.push({id: groupId, selected: selected});
        }
      }
    };

    let compareArrays = userInfo?.groups?.every((originalObj:any) => {
      let obj = filteredArray.find((filteredObj:any) => filteredObj.orgId === originalObj.orgId && filteredObj.serviceId === originalObj.serviceId);
      if (!obj || obj.groups.length !== originalObj.groups.length) {
        return false;
      }
      return originalObj.groups.every((originalGroup:any) => {
        let resultGroup = obj.groups.find((resGroup:any) => resGroup.id === originalGroup.id);
        return resultGroup && resultGroup.selected === originalGroup.selected;
      });
    });

    if (compareArrays) {
      setIsLoading(false);
      setErrorExistingData(true);
      return;
    };

    try {
      const token:any = Cookies.get("token");
      const response = await postManagedUserGroups(token, userInfo?.id, filteredArray);
      
      if (response) {
        setSuccess(true);
        await getManagedUserGroups(token, userInfo?.id, setIsLoading);
      } else {
        setIsLoading(false);
        setErrorUpdate(true);
      }

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      setIsLoading(false);
      setErrorUpdate(true);
    }
  };

  useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = `
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${accentButtonsColor} !important;
        border-color: ${accentButtonsColor} !important;
        border-radius: 0px !important;
      }
      
      .ant-checkbox .ant-checkbox-inner {
        border: 2px solid ${accentButtonsColor} !important;
        border-radius: 0px !important;
      }
    `;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [accentButtonsColor]);

  return (
    <div style={{ display: "flex", flexDirection: "column", paddingRight: "30px", paddingLeft: "10px",  height: "68.6%", position: "relative" }}>
      <Text color={mainTextColor} textType="subTitle" fontWeight="700" marginBottom="20px">{t("groupsPermits")}</Text>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px", overflowY: "auto", paddingTop: "2px", paddingLeft: "3px", paddingRight: "24px", paddingBottom: "3px", maxHeight: "350px" }}>
        {userInfo?.groups === null || userInfo?.groups?.length === 0 ? 
          //revisar este
          <div>   
            <Empty
              description={
                <TextSpan color={mainTextColor}>
                  {t("No groups available")}
                </TextSpan>
              }
            />
          </div>
      : 
        groupsFromRedux?.map((item:any, index:any) => (
          <Collapse bordered={false} expandIconPosition="end" size="small" style={{ width: "100%", background: "none", marginBottom: "6px", boxShadow: "0px 2px 5px 0px #00000026" }}>
            <Panel header={(
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                  <CompanyProfile src={item.image ? `https://${item.image}` : companyFlag} />
                  <div>
                    <Text marginBottom="4px" fontWeight="700">{item.orgName}</Text>
                    <div style={{ display: "flex", opacity: 0.7, gap: "14px" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IconStyled src={luminaireIcon} height={12} />
                        <Text marginBottom="0px" style={{ fontSize: "12px" }}>{item.servName}</Text>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <IconStyled src={groupsIcon} height={12} style={{ opacity: 0.6, marginTop: "1px" }} />
                        <Text marginBottom="0px" style={{ fontSize: "12px" }}>{item.groups.length}</Text>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", paddingRight: "16px" }} onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    checked={item?.groups?.every((group: managedUserGroupsArray) => group.selected)}
                    onChange={(e) => {
                      let updatedGroups = item?.groups.map((group: managedUserGroupsArray) => {
                        let newGroup = {...group, selected: e.target.checked};
                        handleDataToUpdate(item, newGroup, e);
                        return newGroup;
                      });
                      let updatedGroupsFromRedux = groupsFromRedux?.map((group: any) => {
                        if (group === item) {
                          return {...group, groups: updatedGroups};
                        }
                        return group;
                      });
                    
                      setGroupsFromRedux(updatedGroupsFromRedux);
                    }}
                  />
                  <span style={{ fontSize: "12px", marginLeft: "8px" }}>{t("fullAccess")}</span>
                </div>
              </div>
              )}
              key={index}
            >
              <GroupList style={{ borderTop: "2px solid #E7E0EC", paddingTop: "16px", marginTop: "0px", marginLeft: "0px" }}>
                {item.groups.length > 0 ?
                  (item.groups.map((group: managedUserGroupsArray) => (
                    <div>
                      <Checkbox
                        checked={group !== undefined && group?.selected}
                        onChange={(e) => { if (item?.groups.length >= 2) {
                          let updatedGroup = {...group, selected: e.target.checked};
                          handleDataToUpdate(item, updatedGroup, e);
                        
                          let updatedGroupsFromRedux = groupsFromRedux?.map((g: any) => {
                            if (g === item) {
                              let updatedGroups = g.groups.map((group: managedUserGroupsArray) => {
                                if (group.id === updatedGroup.id) {
                                  return updatedGroup;
                                }
                                return group;
                              });
                              return {...g, groups: updatedGroups};
                            }
                            return g;
                          });
                        
                          setGroupsFromRedux(updatedGroupsFromRedux);
                        }
                        }}
                      />
                      <TextSpan fontWeight="400" style={{ marginLeft: "8px" }}>{group.groupName}</TextSpan>
                    </div>
                  ))) 
                :
                  (
                    <div>{t("No groups")}</div>
                  )}
              </GroupList>
            </Panel>
          </Collapse>
        ))}
      </div>
      <div style={{ textAlign: "right", marginBottom: "-2px", alignSelf: "flex-end", position: "absolute", bottom: "0" }}>  
        {isLoading ? (
          <ButtonOutlined type="button" disabled style={{ color: accentButtonsColor, border: `1px solid ${accentButtonsColor}` }}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              {t("loading...")}
          </ButtonOutlined>
        ) : (
          <ButtonOutlined style={{ color: accentButtonsColor, border: `1px solid ${accentButtonsColor}`, cursor: userRole === "admin" && userInfo.role === "admin" ? "not-allowed" : "pointer" }}
            onClick={() => {
              if (userRole === "admin" && userInfo.role === "admin") {
              } else {
                if (dataToUpdate.length === 0) {
                  setErrorUpdate(true)
                } else {
                  handleUpdate(dataToUpdate)
                }
              }
            }}
          >
            {t("Update")}
          </ButtonOutlined>
        )}
      </div>
      <div style={{ height: '10px', marginBottom: "-27px", textAlign: "right", alignSelf: "flex-end", position: "absolute", bottom: "0" }}>
        {errorExistingData ?
          <Text marginBottom="0px" color="red">{t("errorExistingData")}</Text>
        :
          errorUpdate ?
            <Text marginBottom="0px" color="red">{t("errorUpdating")}</Text>
          : 
            success ?
              <Text marginBottom="0px" color="green">{t("updated")}</Text>
            :
              <></>
        }
      </div>
    </div>
  );
};
