import { Icon } from "leaflet";
import ON from "../assets/new_icons/ON for groups.svg";
import ONSelected from "../assets/new_icons/ON for groups selected.svg";

export const getGroupIcon = (selected: boolean) => {
  const shadowSize = [40, 40];
  const ICONS = new Icon({
    iconUrl: selected ? ONSelected : ON,
    iconSize: [20, 20],
    shadowSize: [shadowSize[0], shadowSize[1]],
  });

  return ICONS;
};
