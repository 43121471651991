import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";
import { useLightingService } from "../../../../services/lightingService";
import { BatteryConfigModal } from "./BatteryConfigModal";
import { CardSimple } from "../../LuminairePageStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import batteryConfigImg from "../../../../assets/new_icons/battery config.png";
import chargeLimit from "../../../../assets/new_icons/charge_limit_v.svg";
import chargeReturn from "../../../../assets/new_icons/charge_return_v.svg";
import discharge from "../../../../assets/new_icons/discharge_v.svg";
import dischargeReturn from "../../../../assets/new_icons/discharge_return_v.svg";
import plusCircle from "../../../../assets/new_icons/plusCircle.svg";

interface BatteryConfigProps {
  selectedLamp: any
}

export const BatteryConfig = ({ selectedLamp }: BatteryConfigProps) => {
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const [showActions, setShowActions] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { sendCommandToSolarBattery } = useLightingService();
  const userRole = useAppSelector((state) => state.user.role_name);
  const cLimitVolt = useAppSelector((state) => state.solarParameters.cLimitVolt);
  const cReturnVolt = useAppSelector((state) => state.solarParameters.cReturnVolt);
  const disVolt = useAppSelector((state) => state.solarParameters.disVolt);
  const disReturnVolt = useAppSelector((state) => state.solarParameters.disReturnVolt);
  const sysVolt = useAppSelector((state) => state.solarParameters.sysVolt);
  const battType = useAppSelector((state) => state.solarParameters.battType);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const { t } = useTranslation();

  const updateSolarData = async () => {
    try {
      let data = await sendCommandToSolarBattery([selectedLamp], 80);
    } catch (error) {
      alert(error);
    }
  }

  useEffect(() => {
    if (selectedLamp.online === "1") {
      updateSolarData();
    }
  }, [selectedLamp.node_id, selectedLamp.online]);

  return (
    <CardSimple style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>      
        <Text color={mainTextColor} style={{ fontWeight: 500 }}>{t("currentBatteryConfig")}</Text>
        {userRole !== "observer" && userRole !== "maintenance" && selectedLamp.online === "1" ?
          <div style={{ textAlign: "right", marginTop: "-2px" }}>
            <img
              src={plusCircle}
              height={16}
              style={{ opacity: iconOpacity, cursor: "pointer" }}
              onMouseEnter={() => setIconOpacity(1)}
              onMouseLeave={() => setIconOpacity(0.5)}
              onClick={() => {setShowActions(!showActions)}}
            />
            {showActions && (
              <article
                style={{
                  position: "absolute",
                  top: "42px",
                  right: "24px",
                  width: "180px",
                  maxHeight: "224px",
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  background: "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
                  boxShadow: "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
                  borderRadius: "4px",
                  zIndex: 6
                }}
              >  
                <>
                  <style>
                    {`
                      .org-span:hover {
                        background-color: #1c1b1f11;
                      }
                    `}
                  </style> 
                  <span
                    className="org-span"
                    onClick={async () => {setShowActions(!showActions); updateSolarData()}}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      isolation: "isolate",
                      paddingLeft: "15px",
                      paddingRight: "6px",
                      width: "100%",
                      minHeight: "56px",
                      cursor: "pointer",
                      textAlign: "left",
                    }}
                  >
                    {t("readData")}
                  </span>
                  <span
                    className="org-span"
                    onClick={async () => {setShowActions(!showActions); setShowModal(true)}}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      isolation: "isolate",
                      paddingLeft: "15px",
                      paddingRight: "6px",
                      width: "100%",
                      minHeight: "56px",
                      cursor: "pointer",
                      textAlign: "left",
                    }}
                  >
                    {t("editData")}
                  </span>
                </>
              </article>
            )}
          </div>
        :
          <></>
        }
        {showModal && (
          <BatteryConfigModal showModal={showModal} setShowModal={setShowModal} selectedLamp={selectedLamp} />     
        )}
      </div>
      <div style={{ marginTop: "10px" }}>
        <Text fontWeight="700" marginBottom="4px" style={{ marginTop: /* !selectedStrategy?.description ? "16px" :  */"0px" }}>Battery conf. Name</Text>
        <Text style={{ fontSize: "12px" }}>Short description of the battery configuration</Text>
      </div>
      <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "20px", marginTop: "16px" }}>
        <img src={batteryConfigImg} />
        <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <img src={chargeLimit} />
            <div>
              <Text fontWeight="700">{cLimitVolt || "-"}</Text>
              <Text color={supportTextColor} style={{ fontSize: "10px" }}>{t("chargeLimitVolt").toUpperCase()}</Text>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <img src={chargeReturn} />
            <div>
              <Text fontWeight="700">{cReturnVolt || "-"}</Text>
              <Text color={supportTextColor} style={{ fontSize: "10px" }}>{t("chargeReturnVolt").toUpperCase()}</Text>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <img src={dischargeReturn} />
            <div>
              <Text fontWeight="700">{disReturnVolt || "-"}</Text>
              <Text color={supportTextColor} style={{ fontSize: "10px" }}>{t("disReturnVolt").toUpperCase()}</Text>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <img src={discharge} />
            <div>
              <Text fontWeight="700">{disVolt || "-"}</Text>
              <Text color={supportTextColor} style={{ fontSize: "10px" }}>{t("disVolt").toUpperCase()}</Text>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px", marginTop: "10px" }}>
        <div style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
          <Text marginBottom="0" color={supportTextColor} style={{ fontSize: "10px" }}>{t("battType").toUpperCase()}:</Text>
          <Text marginBottom="0" style={{ fontSize: "10px" }}>{battType === 10 ? t("leadAcidBattery").toUpperCase() : battType === 11 ? t("lithiumBattery").toUpperCase() : "-"}</Text>
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
          <Text marginBottom="0" color={supportTextColor} style={{ fontSize: "10px" }}>{t("sysVolt").toUpperCase()}:</Text>
          <Text marginBottom="0" style={{ fontSize: "10px" }}>{sysVolt || "-"}</Text>
        </div>
      </div>      
    </CardSimple>
  );
};
