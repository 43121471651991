import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { resetGroupInfoActionCreator } from "../../../redux/slices/groupsSlice/groupsSlice";
import { updateStrategyIdActionCreator } from "../../../redux/slices/nodeSlice/manageNodeSlice";
import { useStrategiesService } from "../../../services/strategiesService";
import StrategiesChart from "../../StrategiesPage/StrategiesChart";
import StrategiesChartSolar from "../../StrategiesPage/StrategiesChartSolar";
import { NavLink } from "../../GroupsPage/GroupsDetailsCard/GroupsDetailsCardStyled";
import { ButtonStrategy } from "../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import luminairePower from "../../../assets/new_icons/lum_power.svg";
import maxConsumIcon from "../../../assets/new_icons/max_consumption.svg";
import photocell from "../../../assets/new_icons/photocell_volt.svg";
import sunrise from "../../../assets/new_icons/Sunrise - Filled.svg";
import sunset from "../../../assets/new_icons/Sunset - Filled.svg";
import { ReactComponent as EnergyIcon } from "../../../assets/new_icons/Energy.svg";

interface StrategiesListModalProps {
  showModal: boolean;
  setShowModal: any;
  selectedLamp?: any;
  selectedLampId?: string | undefined;
  selectedLamps?: Array<[]> | any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1400px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 880px;
    width: 90%;
    background: white;
    border: 0px;
  }
`;

export const StrategiesListModal = ({ showModal, setShowModal, selectedLamp, selectedLampId, selectedLamps }: StrategiesListModalProps) => {
  const [viewStandard, setViewStandard] = useState(true);
  const [viewSolar, setViewSolar] = useState(false);
  
  const { assignStrategy, assignSolarStrategy, getStrategies, getSolarStrategies } = useStrategiesService();
  const dispatch = useAppDispatch();
  const [showPreventPopup, setShowPreventPopup] = useState(false);
  const [selectedCard, setSelectedCard] = useState({
    index: "",
    state: false,
  });
  const [selectedCardGroupSolar, setSelectedCardGroupSolar] = useState({
    index: "",
    state: false,
  });
  const [selectedStrategyId, setSelectedStrategyId]: any = useState("");
  const [selectedStrategyIdGroupSolar, setSelectedStrategyIdGroupSolar]: any = useState("");
  const smartManagementOptions = ["OFF", "HIGH", "MEDIUM", "LOW", "AUTOMATIC"];

  const strategies = useAppSelector((state) => state.strategies.strategies);
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  const handlePreventPopup = () => {
    setShowPreventPopup(true);
  };

  const sendInfo = async () => {
    if (selectedLampId) {
      if (selectedLamp.solar_id) {
        await assignSolarStrategy([selectedLampId], parseInt(selectedStrategyId), selectedOrgId, selectedServiceId);
      } else {
        await assignStrategy([selectedLampId], parseInt(selectedStrategyId), selectedOrgId, selectedServiceId);
      }
      dispatch(updateStrategyIdActionCreator({ strategy_id: selectedStrategyId }))
    } else if (selectedLamps) {
      const solarIds:Array<[]> = [];
      const standardIds:Array<[]> = [];

      selectedLamps.forEach((node:any) => {
        if (node.solar_id !== null) {
          solarIds.push(node);
        } else {
          standardIds.push(node);
        }
      });

      if (solarIds.length > 0) {
        await assignSolarStrategy(solarIds, parseInt(selectedStrategyIdGroupSolar), selectedOrgId, selectedServiceId);
      }

      if (standardIds.length > 0) {
        await assignStrategy(standardIds, parseInt(selectedStrategyId), selectedOrgId, selectedServiceId);
      }
    }
    setShowModal(false);
  };

  useEffect(() => {
    if (selectedLamps?.length > 0 && selectedLamps?.every((node: any) => node.solar_id !== null)) {
      setViewSolar(true);
      setViewStandard(false);
    }
  }, [selectedLamps]);

  useEffect(() => {
    if (selectedLamps?.length > 0) {
      if (selectedCard.state && selectedLamps?.filter((node:any) => node.solar_id !== null).length > 0 && !selectedCardGroupSolar.state) {
        setViewStandard(false); setViewSolar(true)
      }

      if (selectedCardGroupSolar.state && selectedLamps?.filter((node:any) => node.solar_id === null).length > 0 && !selectedCard.state) {
        setViewStandard(true); setViewSolar(false)
      }
    }
  }, [selectedCard, selectedCardGroupSolar]);

  useEffect(() => {
    if (selectedLamps?.length > 0) {
      if (viewStandard) {
        getStrategies(selectedOrgId, selectedServiceId);
      } else if (viewSolar) {
        getSolarStrategies(selectedOrgId, selectedServiceId);
      }
    }
  }, [showModal, dispatch, selectedOrgId, selectedServiceId, viewStandard, viewSolar]);

  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      {showPreventPopup ?
        <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)",
          borderRadius: "14px", padding: "30px", display: "flex", flexDirection: "column", position: "absolute",  alignItems: "center",
          justifyContent: "center", top: "362px", left: "407px", zIndex: 30
        }}>
          <Text>{t("Do you want to close this form? All the information will be lost")}</Text>
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {dispatch(resetGroupInfoActionCreator()); setShowModal(!showModal)}}>{t("Accept")}</button>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowPreventPopup(false)}}>{t("Cancel")}</button>
          </div>
        </div>
      :
        <></>
      }
      <Modal.Body style={{ pointerEvents: showPreventPopup ? "none" : "auto", background: "white", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px",
        paddingRight: "0px", borderTopRightRadius: "0.5rem", borderTopLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem", borderBottomLeftRadius: "0.5rem", display: "flex",
        flexDirection: "column"}}
      >
        <div style={{ display: "flex", width: "100%", padding: "0px 10px 0px 0px", flexDirection: "column", background: "#ffffff", borderTopRightRadius: "0.5rem",
          borderTopLeftRadius: "0.5rem", justifyContent: "flex-end" }}
        >
          <Modal.Header
            closeButton
            style={{
              borderBottom: "none",
              background: "white",
              zIndex: 2
            }}
          />
        </div>
        <div style={{ paddingLeft: "50px", paddingRight: "50px", paddingTop: "10px", display: "flex", flexDirection: "column" }}>
          <div>
            <Text fontWeight="700" marginBottom="5px" color={mainTextColor} style={{ fontSize: "22px" }}>{t("setDefaultStrategyTitle")}</Text>
            <Text fontWeight="400" marginBottom="5px" color={mainTextColor} style={{ fontSize: "16px" }}>{t("setDefaultStrategySubtitle")}. {t("alertStrategies")}</Text>
          </div>
          {selectedLamps?.length === 0 && !selectedLampId &&
            <Text fontWeight="400" color="red" style={{ marginTop: "10px", fontSize: "16px" }}>{t("noDevicesAssignStrategy")}</Text>
          }
          {selectedLamps?.length > 0 &&
            <div className="nav nav-pills" style={{ marginBottom: "16px" }}>
              {selectedLamps?.filter((node:any) => node.solar_id === null).length > 0 &&
                <div className="nav-item">
                  <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${viewStandard ? "active" : ""}`} id="mainInfo-tab" data-bs-toggle="tab" type="button"
                    onClick={() => {setViewStandard(true); setViewSolar(false)}}
                  >
                    {t("Standard")}
                  </NavLink>
                </div>
              }
              {selectedLamps?.filter((node:any) => node.solar_id !== null).length > 0 &&
                <div className="nav-item">
                  <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${viewSolar ? "active" : ""}`} id="consumptions-tab" data-bs-toggle="tab" type="button"
                    onClick={() => {setViewStandard(false); setViewSolar(true)}}
                  >
                    {t("Solar")}
                  </NavLink>
                </div>
              }
            </div>
          }
          <div style={{ display: "flex", gap: "30px", flexWrap: "wrap", paddingBottom: "20px", paddingTop: "20px", justifyContent: "center", overflowY: "scroll",
            maxHeight: selectedLamps?.length === 0 && !selectedLampId ? "575px" : selectedLamps?.length > 0 ? "539px" : "630px" }}>
            {strategies && strategies?.map((item: any, index: any) => (
              <div
                key={index}
                className="card"
                style={{
                  padding: "24px",
                  border: selectedLamp || viewStandard ?
                      selectedCard.index === index && selectedCard.state ? `2px solid ${accentButtonsColor}` : "none"
                    :
                      selectedCardGroupSolar.index === index && selectedCardGroupSolar.state ? `2px solid ${accentButtonsColor}` : "none",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
                  backdropFilter: "blur(2px)",
                  borderRadius: "16px",
                  width: "31%",
                }}
                onClick={() => {
                  selectedLamp || viewStandard ?
                    setSelectedStrategyId(item.id)
                  :
                    setSelectedStrategyIdGroupSolar(item.id)
                  selectedLamp || viewStandard ?
                    selectedCard.index === index ? setSelectedCard({ index: index, state: !selectedCard.state }) : setSelectedCard({ index: index, state: true })
                  :
                    selectedCardGroupSolar.index === index ? setSelectedCardGroupSolar({ index: index, state: !selectedCardGroupSolar.state }) : setSelectedCardGroupSolar({ index: index, state: true })
                }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "4px", gap: "10px" }}>
                  <div style={{width: "50%"}}>
                    <Text fontWeight="700" marginBottom="2px">{item.strgy_name}</Text>
                  </div>
                  {viewSolar || (selectedLamp && selectedLamp?.solar_id !== null) ?
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", width: "70%" }}>
                      <div style={{ display: "flex", gap: "5px" }}> 
                        <Text marginBottom="0px" fontWeight="600">{t("smartManagement")}: </Text>
                        <Text marginBottom="0px">{smartManagementOptions[item.smart_management_id]}</Text>
                      </div>
                      <div style={{ display: "flex", gap: "16px" }}>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <img src={luminairePower} alt="" height={16} style={{ marginTop: "2px" }} />
                          <Text marginBottom="0px">{item.lum_power_w} W</Text>
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <img src={photocell} alt="" height={16} style={{ marginTop: "2px" }} />
                          <Text marginBottom="0px">{item.photocell_v} V</Text>
                        </div>
                      </div>      
                    </div>
                  : viewStandard || (selectedLamp && selectedLamp?.solar_id === null) ?
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", width: "50%" }}>
                        {item.time1 === "98:98" ?
                          <>
                            <div style={{ display: "flex", gap: "5px" }}> 
                              <Text marginBottom="0px" fontWeight="600">{t("astroDim")}: </Text>
                              <Text marginBottom="0px">{t("yes")}</Text>
                            </div>
                            <div style={{ display: "flex", gap: "16px" }}>
                              <div style={{ display: "flex", gap: "5px" }}>
                                <img src={sunset} alt="" height={16} />
                                <Text marginBottom="0px">{item.sunset_off} {t("min")}</Text>
                              </div>
                              <div style={{ display: "flex", gap: "5px" }}>
                                <img src={sunrise} alt="" height={16} />
                                <Text marginBottom="0px">{item.sunrise_off} {t("min")}</Text>
                              </div>
                            </div>
                          </>
                        :
                          <div style={{ display: "flex", gap: "5px" }}> 
                            <Text marginBottom="0px" fontWeight="600">{t("astroDim")}: </Text>
                            <Text marginBottom="0px">{t("no")}</Text>
                          </div>
                        }
                      </div>
                    :
                      <></>
                  }
                </div>
                <Text textType="caption" marginBottom="2px">{item.description}</Text>
                {viewSolar || (selectedLamp && selectedLamp?.solar_id !== null) ?
                  <StrategiesChartSolar
                    chartData={[item.dim1, item.dim2, item.dim3, item.dim4, item.dim5, item.time1, item.time2, item.time3, item.time4, item.time5]}
                    color={item.color}
                  />
                : viewStandard || (selectedLamp && selectedLamp?.solar_id === null) ?
                    <StrategiesChart
                      chartData={[item.dim1, item.dim2, item.dim3, item.dim4, item.dim5, item.dim6, item.dim7, item.time1, item.time2, item.time3, item.time4, item.time5, item.time6, item.time7]}
                      color={item.color}
                    />
                  :
                    <></>
                }
                {viewSolar || (selectedLamp && selectedLamp?.solar_id !== null) ?
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "8px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <img src={maxConsumIcon} alt="" height={16} style={{ marginBottom: "1px" }} />
                      <Text marginBottom="-2px" style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{t("maxConsumShort").toUpperCase()}</Text>
                      <Text marginBottom="-2px" style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{item.max_consum_wh} W</Text>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <Text marginBottom="-2px" style={{ color: item.color, fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{t("battConsumShort").toUpperCase()}</Text>
                      <Text marginBottom="-2px" style={{ color: item.color, fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{item.total_consum_wh} W</Text>
                    </div>
                  </div>
                : viewStandard || (selectedLamp && selectedLamp?.solar_id === null) ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8px", height: "20px" }}>
                      <EnergyIcon height={16} style={{ color: item.color }} />
                      <TextSpan color={item.color} fontWeight="500">{item.saving}{t("percentageEnergySaved")}</TextSpan>
                    </div>
                  :
                    <></>
                }
              </div>
            ))}
          </div>
          {/* arreglar el style */}
          <div style={{display: "flex", justifyContent: "center", alignContent: "flex-end"}}>
            <ButtonStrategy buttonType="primary" style={{ marginLeft: "0px", width: "300px", marginTop: "30px", padding: "8px 32px", lineHeight: "none", background: buttonsColor,
              opacity: selectedLamp || viewStandard ?
                  selectedCard.state ? 1 : 0.5
                :
                  selectedCardGroupSolar.state ? 1 : 0.5,
              cursor: selectedLamp || viewStandard ?
                  selectedCard.state ? "pointer" : "not-allowed"
                :
                  selectedCardGroupSolar.state ? "pointer" : "not-allowed",
              color: accentTextColor }}
              onClick={() => {
              const hasStandard = selectedLamps?.some((lamp:any) => lamp.solar_id === null);
              const hasSolar = selectedLamps?.some((lamp:any) => lamp.solar_id !== null);
              selectedLamp ?
                selectedCard.state && sendInfo()
              :
                hasStandard && hasSolar ?
                  selectedCard.state && selectedCardGroupSolar.state && sendInfo()
                :
                  hasStandard ?
                    selectedCard.state && sendInfo()
                  :
                    selectedCardGroupSolar.state && sendInfo()
              }}
            >
              {t("assign")}
            </ButtonStrategy>
          </div>
          {/* <ButtonStrategy buttonType="primary" style={{ opacity: !selectedCard.state ? 0.5 : 1, cursor: !selectedCard.state ? "not-allowed" : "pointer", justifyContent: "center",
            alignSelf: "center", width: "300px", marginTop: "30px", padding: "8px 32px", lineHeight: "none" }}
            onClick={() => {selectedCard.state === true ? sendInfo() : <></>}}
          >
            {t("assign")}
          </ButtonStrategy> */}
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
