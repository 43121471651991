import { Text } from "../../../styles/shared-styles/text-sizes";
import { CommonCardContainer } from "../../../styles/shared-styles/card-container";
import React from "react";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";
import alarmTime from "../../../assets/new_icons/alarm-time.svg";
import congratulationImg from "../../../assets/new_icons/noAlarm.svg";
import { ButtonOutlined } from "../../../styles/shared-styles/buttons-sizes";
import StarIcon from "./StarIcon";
import { GatewayRoadWrapper } from "../GatewayPageStyled";

export const GatewayFooter = () => {
  return (
    <div className="d-flex gap-4 mb-3">
      <CommonCardContainer
        padding="15px"
        className="luminaires-listings"
        style={{ width: 410 }}
      >
        <div className="listing-header d-flex align-items-center justify-content-between mb-3">
          <Text textType="base">Groups</Text>
          <button className="btn btn-link p-0">
            <img src={plusCircle} style={{ height: "24px" }} />
          </button>
        </div>
        <GatewayRoadWrapper className="d-flex gap-2 flex-wrap">
          {Array(11)
            ?.fill(0)
            .map((_, i) => (
              <div
                key={i}
                className="road-item d-flex align-items-center justify-content-center gap-2"
              >
                {i < 3 && <StarIcon />}
                Main Road
              </div>
            ))}
          <div className="road-item d-flex align-items-center justify-content-center gap-2">
            ...
          </div>
        </GatewayRoadWrapper>
      </CommonCardContainer>
      <CommonCardContainer
        padding="15px"
        className="luminaires-listings"
        style={{ width: 410 }}
      >
        <div className="listing-header d-flex align-items-center justify-content-between mb-3">
          <Text textType="base">Alarms</Text>
          <button className="btn btn-link p-0">
            <img src={plusCircle} style={{ height: "24px" }} />
          </button>
        </div>
        <div className="text-center">
          <img
            src={congratulationImg}
            alt="Congratulation image"
            className="mb-3"
          />
          <ButtonOutlined
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
            padding="2px 8px"
            radius="20px"
            height="30px"
          >
            <img src={alarmTime} height={15} alt="history" className="me-2" />{" "}
            View historic
          </ButtonOutlined>
        </div>
      </CommonCardContainer>
    </div>
  );
};
