import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { registerUserActionCreator, setOrganizationPermitsActionCreator } from "../../../../redux/slices/userRegistrationSlice/userRegistrationSlice";
import { IconStyled } from "../../../../components/LuminairePage/LuminairePageStyled";
import { CardContainer, NextButton } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { CompanyProfile, OrganizationCard } from "../../UsersPageStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import companyFlag from "../../../../assets/new_icons/Company.svg";
import map from "../../../../assets/new_icons/Map.svg";
import statusIcon from "../../../../assets/new_icons/Switch OFF.svg";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface OrgPermitProps {
  goToServices?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
}

export const OrgPermit = ({ goToServices, errorMessage, setErrorMessage }: OrgPermitProps) => {
  const organizations = useAppSelector((state) => state.userRegistration.organizationPermits);
  
  const [selectedOrgId, setSelectedOrgId] = useState(() => {
    const selectedOrg = organizations.find((org:any) => org.selected === true);
    return selectedOrg ? selectedOrg.id : null;
  });
  
  const dispatch = useAppDispatch();
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const parseOrgsList = (orgs:any, selectedId:any) => {
    return orgs.map((org:any) => ({
      ...org,
      selected: org.id === selectedId
    }));
  };

  const handleOrgsInfo = (e: any) => {
    if (selectedOrgId) {
      try {
        const orgsListParsed = parseOrgsList(organizations, selectedOrgId);
        dispatch(setOrganizationPermitsActionCreator(orgsListParsed));
        dispatch(registerUserActionCreator({ organization_id: selectedOrgId }));
        goToServices();
      } catch (error) {
        console.log(error)
      }
    } else {
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    const orgsListParsed = parseOrgsList(organizations, selectedOrgId);
    dispatch(setOrganizationPermitsActionCreator(orgsListParsed));
    dispatch(registerUserActionCreator({ organization_id: selectedOrgId }));

    if (selectedOrgId) {
      setErrorMessage(false)
    }
  }, [selectedOrgId]);

  useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = `
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${accentButtonsColor} !important;
        border-color: ${accentButtonsColor} !important;
        border-radius: 0px !important;
      }
      
      .ant-checkbox .ant-checkbox-inner {
        border: 2px solid ${accentButtonsColor} !important;
        border-radius: 0px !important;
      }
    `;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [accentButtonsColor]);

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: "47px", paddingLeft: "40px" }}>
        <Text fontWeight="500" marginBottom="4px">{t("step3")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("orgsPermits")}</Text>
        <Text fontWeight="400" style={{fontSize: "12px"}}>{t("orgPermitsSubtitle")}</Text>
      </div>
      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap", overflowY: "auto", paddingTop: "2px", paddingLeft: "70px", paddingRight: "50px", marginRight: "20px",
        maxHeight: "510px" }}
      >
        {organizations?.map((org:any) => (
          <OrganizationCard style={{ display: "flex", width: "401px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "6px", gap: "20px", alignItems: "center" }}>
            <CompanyProfile src={org.image ? `https://${org.image}` : companyFlag} />
            <div>
              <Text marginBottom="4px" fontWeight="600">{org.orgName}</Text>
              <div style={{ display: "flex", opacity: 0.7, gap: "14px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconStyled src={map} height={12} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{org.city ? org.city : "-"}, {org.country ? org.country : "-"}</Text>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <IconStyled src={statusIcon} height={12} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{org.active === "Y" ? t("yes") : t("no")}</Text>
                </div>
              </div>
            </div>
            <Checkbox
              key={org.index}
              checked={selectedOrgId === org.id}
              style={{ marginLeft: "auto" }}
              onChange={() => setSelectedOrgId(org.id)}
            />
          </OrganizationCard>
        ))}
      </div>
      {errorMessage ?
        <Text marginBottom="0px" color="red" style={{ display: "flex", justifySelf: "end", alignSelf: "center", marginTop: "24px" }}>{t("errorBlankInput")}</Text>  
      :
        <></>
      }
      <NextButton accentButtonsColor={accentButtonsColor} style={{ justifySelf: "end", alignSelf: "center", width: "340px", marginTop: errorMessage ? "16px": "61px" }} onClick={handleOrgsInfo}>{t("continue")}</NextButton>
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        {/* <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} /> */}
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
      </div>
    </CardContainer>
  );
};
