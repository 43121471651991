export const googleIcons = {
  lightBulb: "emoji_objects",
  powerSwitch: "power_settings_new",
  connection: "wifi",
  bolt: "bolt",
  sunny: "sunny",
  disconnection: "wifi_off",
  error: "error",
  move: "open_with",
  close: "close",
  arrowForward: "arrow_forward",
  location: "my_location",
  add: "add",
  selectArea: "select",
  sadFace: "sentiment_dissatisfied"
};
