import { useAppSelector } from "../../redux/hooks";
import "./Loader.css";

export const Loader = ({ width = "100%", height = "100vh" }: any) => {
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);

  const loaderStyle = {
    display: "flex",
    width: "50px",
    height: "50px",
    border: "3px solid #f3f3f3",
    borderTop: `3px solid ${buttonsColor}`,
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
    justifyContent: "center",
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: width, height: height }}>
      <div style={loaderStyle}></div>
    </div>
  );
};
