import { loadUserActionCreator, updatePersonalInfoActionCreator, updateDefaultLanguageActionCreator } from "../../slices/userSlice/userSlice";
import { items } from "../../../utils/languages/changeLanguage";

export const fetchUserProfileAction = (user: any) => async (dispatch: any) => {
  dispatch(loadUserActionCreator(user));
};

export const updateUserInfoAction = (firstName: string, lastName: string, phoneNumber: string) => async (dispatch: any) => {
  dispatch(updatePersonalInfoActionCreator({ first_name: firstName, last_name: lastName, phone_number: phoneNumber }));
};

export const updateLanguageAction = (languageId: number) => async (dispatch: any) => {
  items.map((item) => {
    if (item.id === languageId) {
      dispatch(updateDefaultLanguageActionCreator({ language_name: item.label }));
    }
  });
};
