import styled from "styled-components";

export const GroupSelect = styled.div`
  width: 350px;
  .ant-select {
    width: 100%;
    height: 100%;
    &-selector {
      border-radius: 16px !important;
      font-size: 24px !important;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
        0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    }
    &-arrow {
      color: #000;
      font-size: 17px;
    }
  }
`;

export const PopupContainer = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 215px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.08)
    ),
    rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 12px 6px,
    rgba(0, 0, 0, 0.3) 0px 4px 4px;
  border-radius: 4px;
  z-index: 9999;
  .bulk-actions-container__action {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;
    isolation: isolate;
    padding-left: 15px;
    width: 100%;
    height: 56px;
    cursor: pointer;
    &:hover {
      background-color: rgba(28, 27, 31, 0.067);
    }
  }
`;

export const GroupSearch = styled.div`
  cursor: default;
  marginbottom: 8px;
  position: relative;
  display: inline-block;
  width: 100%;
  alignself: flex-start;
  justifyself: flex-start;
  position: sticky;
  top: 0;
  background: #ffffff;
  padding: 0px 0 24px 0;
  z-index: 2;
`;

export const GroupLeafletMapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: absolute;
  z-index: 9;
  top: 25px;
  p {
    display: flex;
    align-items: center;
  }
  img {
    margin-right: 5px;
  }
  .top-left-map-section {
    display: flex;
    margin-left: 25px;
    gap: 20px;
    p {
      background: white;
      border-radius: 30px;
      border: 2px solid black;
      padding: 2px 10px;
      box-shadow: 0px 1px 3px 0px #0000004d;
      box-shadow: 0px 4px 8px 3px #00000026;
    }
  }
  .top-right-map-section {
    background: white;
    border-radius: 10px;
    padding: 15px;
    margin-right: 30px;
    box-shadow: 0px 1px 3px 0px #0000004d;
    box-shadow: 0px 4px 8px 3px #00000026;
    h6 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 0px;
      margin-top: 25px;
    }
  }
`;
