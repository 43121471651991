import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ElectricParameters } from "../../../types/interfaces";

const initialState: ElectricParameters = {
  ledCurrentDali: "",
  driverTempDali: "",
  netVoltageDali: "",
  apparentPowerDali: "",
  netVoltageAllegro: "",
  apparentPowerAllegro: "",
  netCurrentAllegro: "",
  ledCurrentDaliAllegro: "",
  driverTempDaliAllegro: "",
  netVoltageDaliAllegro: "",
  apparentPowerDaliAllegro: "",
  netCurrentDaliAllegro: "",
  driverTempShuncom: "",
  netVoltageShuncom: "",
  apparentPowerShuncom: "",
  netCurrentShuncom: "",
  timestamps: ""
};

const electricParametersSlice = createSlice({
  name: "electricParameters",
  initialState: initialState,
  reducers: {
    getDaliInfo: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      ledCurrentDali: action.payload.ledCurrentDali,
      driverTempDali: action.payload.driverTempDali,
      netVoltageDali: action.payload.netVoltageDali,
      apparentPowerDali: action.payload.apparentPowerDali,
      timestamps: action.payload.timestamps
    }),

    getAllegroInfo: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      netVoltageAllegro: action.payload.netVoltageAllegro,
      apparentPowerAllegro: action.payload.apparentPowerAllegro,
      netCurrentAllegro: action.payload.netCurrentAllegro,
      timestamps: action.payload.timestamps
    }),

    getDaliAllegroInfo: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      ledCurrentDaliAllegro: action.payload.ledCurrentDaliAllegro,
      driverTempDaliAllegro: action.payload.driverTempDaliAllegro,
      netVoltageDaliAllegro: action.payload.netVoltageDaliAllegro,
      apparentPowerDaliAllegro: action.payload.apparentPowerDaliAllegro,
      netCurrentDaliAllegro: action.payload.netCurrentDaliAllegro,
      timestamps: action.payload.timestamps
    }),

    getShuncomInfo: (previousData, action: PayloadAction<ElectricParameters>) => ({
      ...previousData,
      driverTempShuncom: action.payload.driverTempShuncom,
      netVoltageShuncom: action.payload.netVoltageShuncom,
      apparentPowerShuncom: action.payload.apparentPowerShuncom,
      netCurrentShuncom: action.payload.netCurrentShuncom,
      timestamps: action.payload.timestamps
    }),

    resetParameters: (previousData) => ({
      ...previousData,
      ledCurrentDali: "",
      driverTempDali: "",
      netVoltageDali: "",
      apparentPowerDali: "",
      netVoltageAllegro: "",
      apparentPowerAllegro: "",
      netCurrentAllegro: "",
      ledCurrentDaliAllegro: "",
      driverTempDaliAllegro: "",
      netVoltageDaliAllegro: "",
      apparentPowerDaliAllegro: "",
      netCurrentDaliAllegro: "",
      driverTempShuncom: "",
      netVoltageShuncom: "",
      apparentPowerShuncom: "",
      netCurrentShuncom: "",
      timestamps: ""
    }),
  },
});

export const electricParametersReducer = electricParametersSlice.reducer;

export const {
  getDaliInfo: getDaliInfoActionCreator,
  getAllegroInfo: getAllegroInfoActionCreator,
  getDaliAllegroInfo: getDaliAllegroInfoActionCreator,
  getShuncomInfo: getShuncomInfoActionCreator,
  resetParameters: resetParametersActionCreator
} = electricParametersSlice.actions;
