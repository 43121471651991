import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { Text } from "../../../styles/shared-styles/text-sizes";
import { CommonCardContainer } from "../../../styles/shared-styles/card-container";

export const Limitations = () => {
  const orgInfo: any = useAppSelector((state) => state.selectedOrganization);
  const { t } = useTranslation();

  return (
    <CommonCardContainer className="mb-4">
      <Text className="mb-4" textType="cardTitle" color="black">{t("limitations")}</Text>
      <div className="d-flex justify-content-between align-items-end">
        <div className="d-flex align-items-center gap-3">
          <div style={{ marginRight: "12px" }}>
            <Text marginBottom="8px">{t("Users")}</Text>
            <input className="form-control" style={{ width: "100px", textAlign: "center", cursor: "not-allowed" }} disabled value={orgInfo.limitUsers} />
          </div>
          <div style={{ marginRight: "12px" }}>
            <Text marginBottom="8px">{t("Groups")}</Text>
            <input className="form-control" style={{ width: "100px", textAlign: "center", cursor: "not-allowed" }} disabled value={orgInfo.limitGroups} />
          </div>
          <div style={{ marginRight: "12px" }}>
            <Text marginBottom="8px">{t("Strategies")}</Text>
            <input className="form-control" style={{ width: "100px", textAlign: "center", cursor: "not-allowed" }} disabled value={orgInfo.limitStrategies} />
          </div>
          <div style={{ marginRight: "12px" }}>
            <Text marginBottom="8px">{t("Events")}</Text>
            <input className="form-control" style={{ width: "100px", textAlign: "center", cursor: "not-allowed" }} disabled value={orgInfo.limitEvents} />
          </div>
        </div>
      </div>
    </CommonCardContainer>
  )
}
