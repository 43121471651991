import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

interface ExtendedPerformance extends Performance {
  memory?: {
    usedJSHeapSize: number;
    jsHeapSizeLimit: number;
    totalJSHeapSize: number;
  };
}

export const MemoryMonitor = () => {
  const devices = useSelector((state: RootState) => state.devices);
  const filteredDevices = useSelector((state: RootState) => state.filteredDevices);
  const mqtt = useSelector((state: RootState) => state.mqtt);
  const ui = useSelector((state: RootState) => state.ui);
  
  useEffect(() => {
    const logMemoryStats = () => {
      const performance = window.performance as ExtendedPerformance;
      const stats:any = {
        timestamp: new Date().toISOString(),
        devices: {
          nodesCount: devices.nodes?.length || 0,
          gatewaysCount: devices.gateways?.length || 0
        },
        filteredDevices: {
          nodesCount: filteredDevices.nodes?.length || 0,
          unpositionedNodesCount: filteredDevices.unpositionedNodes?.length || 0
        },
        mqtt: {
          nodeIdsCount: mqtt.nodeId?.length || 0
        },
        ui: {
          isFullView: ui.isFullView,
          isHybridView: ui.isHybridView,
          isListView: ui.isListView
        },
        memory: {
          usedJSHeapSize: performance?.memory?.usedJSHeapSize,
          jsHeapSizeLimit: performance?.memory?.jsHeapSizeLimit
        }
      };

      console.log('🔍 Memory Monitor Stats:', stats);
      
      // Alerta si el uso de memoria es alto
      if (performance?.memory) {
        const usedMemoryPercentage = (stats.memory.usedJSHeapSize / stats.memory.jsHeapSizeLimit) * 100;
        if (usedMemoryPercentage > 70) {
          console.warn(`⚠️ Alto uso de memoria: ${usedMemoryPercentage.toFixed(2)}%`);
        }
      }
    };

    // Ejecutar cada minuto
    const intervalId = setInterval(logMemoryStats, 60000);

    // Ejecutar inmediatamente al montar
    logMemoryStats();

    return () => clearInterval(intervalId);
  }, [devices, filteredDevices, mqtt, ui]);

  return null;
};
