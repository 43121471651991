import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { useOrganizationsService } from "../../../services/selectedOrganizationService";
import { ButtonOutlined } from "../../../styles/shared-styles/buttons-sizes";
import { CommonCardContainer } from "../../../styles/shared-styles/card-container";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import thumbnail from "../../../assets/new_icons/thumbnail.svg"

export const OrgImage = () => {
  const [selectedImage, setSelectedImage]:any = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [errorImage, setErrorImage]: any = useState(false);
  const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { updateOrgImage, getSelectedOrganizationInfo } = useOrganizationsService();
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId = useAppSelector((state) => state.devices.selectedServiceId);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const { t } = useTranslation();

  const handleImageChange = (event:any) => {
    const file = event.target.files[0];
    const maxSizeInMB = 0.15;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (file.size > maxSizeInBytes) {
      setErrorImage(true)
    } else {
      setSelectedImage(file);
    }

    setTimeout(() => {
      setErrorImage(false);
    }, 2000);
  };

  const handleImageUpload = async () => {
    setErrorUpdate(false);
    setSuccess(false);

    const formDataWithImage = new FormData();
    formDataWithImage.append('image', selectedImage);

    try {
      const res = await updateOrgImage(selectedOrgId, formDataWithImage);
      if (res) {
        const orgInfo = await getSelectedOrganizationInfo(selectedOrgId, selectedServiceId);
        if (orgInfo) {
          setSuccess(true)
        } else {
          setErrorUpdate(true)
        }
      } else {
        setErrorUpdate(true)
      }

      setTimeout(() => {
        setSuccess(false);
      }, 2000);
      
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  return (
    <CommonCardContainer className="mb-4">
      <Text className="mb-4" textType="cardTitle" color="black">{t("orgImage")}</Text>
      <div className="d-flex justify-content-between align-items-end">
        <div className="d-flex align-items-center gap-4 mb-5">
          {/* <img src={thumbnail} alt="logo" height={100} /> */}
          <div className="d-flex justify-content-between gap-3">
          <div style={{ position: 'relative', height: "80px", width: "80px", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center",
            border: `2px solid ${mainTextColor}` }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            {selectedImage ?
              <img src={URL.createObjectURL(selectedImage)} alt="Selected" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "cover", display: 'block' }} />
            :
              <img src={thumbnail} alt="logo" style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: '50%', objectFit: "cover" }} />
            }
            {selectedImage && (
              <div style={{  display: isHovered ? 'flex' : 'none', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white',
                fontSize: '30px', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', cursor: 'pointer' }}
                onClick={() => setSelectedImage(null)}
              >
                &times;
              </div>
            )}
          </div>
            <div>
              <TextSpan textType="Filtercaption" className="mb-1">{t("orgImageDescription")}</TextSpan>
              <div className="upload-section">
                <input type="file" className="input-file d-none" id="file" name="file" multiple data-multiple-caption="{count} files selected" onChange={handleImageChange} />
                <ButtonOutlined height="40px" className="position-relative" style={{ width: 130 }}>
                  <label style={{ cursor: "pointer" }} htmlFor="file" className="cursor-pointer position-absolute end-0 start-0 top-0 bottom-0 d-flex align-items-center justify-content-center">
                    {t("chooseFile")}
                  </label>
                </ButtonOutlined>
                {selectedImage ?
                  <Text>{selectedImage.name}</Text>
                :
                  <Text>{t("noFile")}</Text>
                }
              </div>
              {errorImage ?
                <Text marginBottom="0px" color="red">{t("errorImageLimit")}</Text>
              :
                <></>
              }
            </div>
          </div>
        </div>
        <ButtonOutlined height="40px" style={{ alignSelf: "flex-end" }} onClick={handleImageUpload}>{t("Update")}</ButtonOutlined>
      </div>
      <div style={{ height: '10px', textAlign: "right", alignSelf: "end" }}>
        {errorUpdate ? (
            <Text marginBottom="0px" color="red">{t("errorUpdating")}</Text>
          ) :
            success ? (
              <Text marginBottom="0px" color="green">{t("updated")}</Text>
            ) : (
              <></>
        )}
      </div>
    </CommonCardContainer>
  )
}
