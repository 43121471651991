import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { resetPdfInfoActionCreator } from "../../redux/slices/reportsSlice/pdfReportSlice";
import { useReportsService } from "../../services/reportsService";
import { AlarmsCard } from "./AlarmsCard/AlarmsCard";
import { ConsumptionsCard } from "./ConsumptionsCard/ConsumptionsCard";
import { GeneralInfoCard } from "./GeneralInfoCard/GeneralInfoCard";
import { LifetimeCard } from "./LifetimeCard/LifetimeCard";
import { LoaderModal } from "../LoaderModal/LoaderModal";
import { NewReportModal } from "./NewReportModal/NewReportModal";
import { UsersCard } from "./UsersCard/UsersCard";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import { ReactComponent as PlusIcon } from "../../assets/new_icons/plus.svg";

export const ReportsPage = () => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useAppDispatch();
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const { getGeneralReports, getStandardReports } = useReportsService();
  const userRole = useAppSelector((state) => state.user.role_name);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  const handleShowModal = () => {
    dispatch(resetPdfInfoActionCreator());
    setShowModal(!showModal);
  };
 
  useEffect(() => {
    const fetchManageReportsData = async () => {
      try {
        setLoading(true);
        await getGeneralReports(selectedOrgId, selectedServiceId);
        await getStandardReports(selectedOrgId, selectedServiceId);
        
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching reports:", error);
      }
    };

    fetchManageReportsData();
  }, []);

  return (
    <div className="container-fluid" style={{ pointerEvents: "auto", marginTop: "40px", paddingRight: "0px", paddingLeft: "0px", position: "relative", zIndex: 2 }}>
      {loading ?
        <LoaderModal showLoader={loading} />
      :
        <>
          <div style={{ display: "flex", alignItems: "center", justifyContent:"space-between", marginBottom: "24px", marginRight: "8px" }}>
            <div>
              <Text textType="title" fontWeight="700" marginBottom="8px">
                {t("generalReport")}
              </Text>
              <Text textType="">{t("generalReportSubtitle")}</Text>
            </div>
            {userRole === "owner" || userRole === "admin" ?
              <div className="col-2" style={{ textAlign: "right" }}>
                <Button buttonType="primary"
                  onClick={() => {handleShowModal()}}
                  style={{ background: buttonsColor, padding: "8px", width: "160px", cursor: "pointer" }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "4px" }}>
                    <PlusIcon height={30} width={30} style={{ color: accentTextColor, marginRight: "4px" }} />
                    <TextSpan color= {accentTextColor} fontWeight="500" style={{ marginRight: "6px" }}>{t("newReport")}</TextSpan>
                  </div>
                </Button>
              </div>
            :
              <></>
            }
            {showModal && (
              <NewReportModal showModal={showModal} setShowModal={setShowModal} />
            )}
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <div style={{ width: "40%" }}>
              <GeneralInfoCard />
            </div>
            {/* <div style={{ width: "20%" }}>
              <LifetimeCard />
            </div> */}
            <div style={{ width: "20%" }}>
              <AlarmsCard />
            </div>
            <div style={{ width: "20%" }}>
              <UsersCard />
            </div>
          </div>
          <ConsumptionsCard />
        </>
      }
    </div>
  );
};
