import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { setSelectedDevicesStandardListActionCreator, setSelectedDevicesSolarListActionCreator, setSelectedDevicesHybridListActionCreator, setSelectedDevicesRgbListActionCreator,
  setSelectedDevicesSmartecListActionCreator, setSelectedDevicesShuncomListActionCreator, setSelectedDevicesSwitchListActionCreator } from "../../../../redux/slices/reportsSlice/pdfReportSlice";
import { FilterByGroupStyled } from "../../../FilterByGroup/FilterByGroupStyled";
import search from "../../../../assets/new_icons/Search.svg";

interface FilterByGroupReportsProps {
  setNodesGroup: React.Dispatch<React.SetStateAction<string[]>>;
  nodesGroup: any;
  isStandard?: boolean;
  isSolar?: boolean;
  isHybrid?: boolean;
  isRgb?: boolean;
  isSmartec?: boolean;
  isShuncom?: boolean;
  isSwitch?: boolean;
}

export const FilterByGroupReports = ({ setNodesGroup, nodesGroup, isStandard, isSolar, isHybrid, isRgb, isSmartec, isShuncom, isSwitch }: FilterByGroupReportsProps) => {  
  const [showList, setShowList] = useState(false);
  const [searchGroup, setSearchGroup]:any = useState();
  const [groupsList, setGroupsList]:any = useState();
  
  const dispatch = useAppDispatch();
  const { selectedDevicesStandard, selectedDevicesSolar, selectedDevicesHybrid, selectedDevicesRgb, selectedDevicesSmartec, selectedDevicesShuncom,
    selectedDevicesSwitch } = useAppSelector((state) => state.pdfReport);
  const groups = useAppSelector((state) => state.groups.groupsArr);
  const [checked, setChecked] = useState(groups.reduce((acc:any, group:any) => ({ ...acc, [group.groupId]: false }), {}));
  const count = Object.values(checked).filter(Boolean).length;
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  // cuando haya más de un tipo de nodo, revisar esto, sobre todo por los states del checklist
  const addOrDeleteNodesToMap = async (newNodes: any, state: boolean) => {
    if (isStandard) {
      const filteredNodes = newNodes.filter((node:any) => !node.solar_id);
      if (state) {
        if (selectedDevicesStandard.length === 0) {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            newSelectedDevices.push(device.node_id)
          })
          dispatch(setSelectedDevicesStandardListActionCreator({ selectedDevicesStandard: [...selectedDevicesStandard, ...newSelectedDevices] }));
        } else {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            // Verifica si el node_id de filteredNodes no está en selectedDevicesStandard
            const alreadySelected = selectedDevicesStandard.some((id:any) => id === device.node_id);
            
            if (!alreadySelected) {
              // Si no está ya seleccionado, lanza el dispatch
              newSelectedDevices.push(device.node_id)
            }
            dispatch(setSelectedDevicesStandardListActionCreator({ selectedDevicesStandard: [...selectedDevicesStandard, ...newSelectedDevices] }));
          });
        }
      } else {
        const newSelectedDevices:Array<any> = selectedDevicesStandard.filter(
          (id: any) => !filteredNodes.some((device: any) => device.node_id === id)
        );
        /* filteredNodes.forEach((device:any) => {
          const remainingSelected = !selectedDevicesStandard.some((id: any) => id === device.node_id);
          
          
          if (remainingSelected) {
            newSelectedDevices.push(device.nodeId)
          }
        }); */

        dispatch(setSelectedDevicesStandardListActionCreator({ selectedDevicesStandard: newSelectedDevices }));
      }
    } else if (isSolar) {
      const filteredNodes = newNodes.filter((node:any) => node.solar_id);
      if (state) {
        if (selectedDevicesSolar.length === 0) {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            newSelectedDevices.push(device.node_id)
          })
          dispatch(setSelectedDevicesSolarListActionCreator({ selectedDevicesSolar: [...selectedDevicesSolar, ...newSelectedDevices] }));
        } else {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            const alreadySelected = selectedDevicesSolar.some((id:any) => id === device.node_id);
            
            if (!alreadySelected) {
              newSelectedDevices.push(device.node_id)
            }
            dispatch(setSelectedDevicesSolarListActionCreator({ selectedDevicesSolar: [...selectedDevicesSolar, ...newSelectedDevices] }));
          });
        }
      } else {
        const newSelectedDevices:Array<any> = selectedDevicesSolar.filter(
          (id: any) => !filteredNodes.some((device: any) => device.node_id === id)
        );
        dispatch(setSelectedDevicesSolarListActionCreator({ selectedDevicesSolar: newSelectedDevices }));
      }
    } else if (isHybrid) {
      const filteredNodes = newNodes.filter((node:any) => node.hybrid);
      if (state) {
        if (selectedDevicesHybrid.length === 0) {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            newSelectedDevices.push(device.node_id)
          })
          dispatch(setSelectedDevicesHybridListActionCreator({ selectedDevicesHybrid: [...selectedDevicesHybrid, ...newSelectedDevices] }));
        } else {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            const alreadySelected = selectedDevicesHybrid.some((id:any) => id === device.node_id);
            
            if (!alreadySelected) {
              newSelectedDevices.push(device.node_id)
            }
            dispatch(setSelectedDevicesHybridListActionCreator({ selectedDevicesHybrid: [...selectedDevicesHybrid, ...newSelectedDevices] }));
          });
        }
      } else {
        const newSelectedDevices:Array<any> = selectedDevicesHybrid.filter(
          (id: any) => !filteredNodes.some((device: any) => device.node_id === id)
        );
        dispatch(setSelectedDevicesHybridListActionCreator({ selectedDevicesHybrid: newSelectedDevices }));
      }
    } else if (isRgb) {
      const filteredNodes = newNodes.filter((node:any) => node.rgb === "Y");
      if (state) {
        if (selectedDevicesRgb.length === 0) {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            newSelectedDevices.push(device.node_id)
          })
          dispatch(setSelectedDevicesRgbListActionCreator({ selectedDevicesRgb: [...selectedDevicesRgb, ...newSelectedDevices] }));
        } else {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            const alreadySelected = selectedDevicesRgb.some((id:any) => id === device.node_id);
            
            if (!alreadySelected) {
              newSelectedDevices.push(device.node_id)
            }
            dispatch(setSelectedDevicesRgbListActionCreator({ selectedDevicesRgb: [...selectedDevicesRgb, ...newSelectedDevices] }));
          });
        }
      } else {
        const newSelectedDevices:Array<any> = selectedDevicesRgb.filter(
          (id: any) => !filteredNodes.some((device: any) => device.node_id === id)
        );
        dispatch(setSelectedDevicesRgbListActionCreator({ selectedDevicesRgb: newSelectedDevices }));
      }
    } else if (isSmartec) {
      const filteredNodes = newNodes.filter((node:any) => node.smartec);
      if (state) {
        if (selectedDevicesSmartec.length === 0) {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            newSelectedDevices.push(device.node_id)
          })
          dispatch(setSelectedDevicesSmartecListActionCreator({ selectedDevicesSmartec: [...selectedDevicesSmartec, ...newSelectedDevices] }));
        } else {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            const alreadySelected = selectedDevicesSmartec.some((id:any) => id === device.node_id);
            
            if (!alreadySelected) {
              newSelectedDevices.push(device.node_id)
            }
            dispatch(setSelectedDevicesSmartecListActionCreator({ selectedDevicesSmartec: [...selectedDevicesSmartec, ...newSelectedDevices] }));
          });
        }
      } else {
        const newSelectedDevices:Array<any> = selectedDevicesSmartec.filter(
          (id: any) => !filteredNodes.some((device: any) => device.node_id === id)
        );
        dispatch(setSelectedDevicesSmartecListActionCreator({ selectedDevicesSmartec: newSelectedDevices }));
      }
    } else if (isShuncom) {
      const filteredNodes = newNodes.filter((node:any) => node.shuncom);
      if (state) {
        if (selectedDevicesShuncom.length === 0) {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            newSelectedDevices.push(device.node_id)
          })
          dispatch(setSelectedDevicesShuncomListActionCreator({ selectedDevicesShuncom: [...selectedDevicesShuncom, ...newSelectedDevices] }));
        } else {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            const alreadySelected = selectedDevicesShuncom.some((id:any) => id === device.node_id);
            
            if (!alreadySelected) {
              newSelectedDevices.push(device.node_id)
            }
            dispatch(setSelectedDevicesShuncomListActionCreator({ selectedDevicesShuncom: [...selectedDevicesShuncom, ...newSelectedDevices] }));
          });
        }
      } else {
        const newSelectedDevices:Array<any> = selectedDevicesShuncom.filter(
          (id: any) => !filteredNodes.some((device: any) => device.node_id === id)
        );
        dispatch(setSelectedDevicesShuncomListActionCreator({ selectedDevicesShuncom: newSelectedDevices }));
      }
    } else if (isSwitch) {
      const filteredNodes = newNodes.filter((node:any) => node.switch);
      if (state) {
        if (selectedDevicesSwitch.length === 0) {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            newSelectedDevices.push(device.node_id)
          })
          dispatch(setSelectedDevicesSwitchListActionCreator({ selectedDevicesSwitch: [...selectedDevicesSwitch, ...newSelectedDevices] }));
        } else {
          const newSelectedDevices:Array<any> = [];
          filteredNodes.forEach((device:any) => {
            const alreadySelected = selectedDevicesSwitch.some((id:any) => id === device.node_id);
            
            if (!alreadySelected) {
              newSelectedDevices.push(device.node_id)
            }
            dispatch(setSelectedDevicesSwitchListActionCreator({ selectedDevicesSwitch: [...selectedDevicesSwitch, ...newSelectedDevices] }));
          });
        }
      } else {
        const newSelectedDevices:Array<any> = selectedDevicesSwitch.filter(
          (id: any) => !filteredNodes.some((device: any) => device.node_id === id)
        );
        dispatch(setSelectedDevicesSwitchListActionCreator({ selectedDevicesSwitch: newSelectedDevices }));
      }
    }
  }

  useEffect(() => {
    const handleClickOutsideGroups = (event: MouseEvent) => {
      if (
        showList &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("filter__title-container") &&
        !event.target.classList.contains("title-container__title") &&
        !event.target.classList.contains("material-symbols-rounded") &&
        !event.target.classList.contains("picture-class")
      ) {
        setShowList(!showList);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideGroups);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideGroups);
    };
  }, [showList]);

  useEffect(() => {
    let groupListTemp:any = [];
    if(groups.length !== 0) {
      groups.map((group:any, i:any) => {
        let shouldPush = false;

        if (isStandard) {
          shouldPush = group.nodes.some((el: any) => !el.solar_id);
        } else if (isSolar) {
          shouldPush = group.nodes.some((el: any) => el.solar_id);
        } else if (isHybrid) {
          shouldPush = group.nodes.some((el: any) => el.hybrid);
        } else if (isRgb) {
          shouldPush = group.nodes.some((el: any) => el.rgb === "Y");
        } else if (isSmartec) {
          shouldPush = group.nodes.some((el: any) => el.smartec);
        } else if (isShuncom) {
          shouldPush = group.nodes.some((el: any) => el.shuncom);
        } else if (isSwitch) {
          shouldPush = group.nodes.some((el: any) => el.switch);
        }
    
        if (shouldPush) {
          groupListTemp.push(group);
        }

        if (i === groups.length - 1) {
          setGroupsList(groupListTemp);
        }
      })
    }
  }, [isStandard, isSolar, isHybrid, isRgb, isSmartec, isShuncom, isSwitch]);

  useEffect(() => {
    if ((isStandard && selectedDevicesStandard.length === 0) || (isSolar && selectedDevicesSolar.length === 0) || (isHybrid && selectedDevicesHybrid.length === 0) ||
    (isRgb && selectedDevicesRgb.length === 0) || (isSmartec && selectedDevicesSmartec.length === 0) || (isSmartec && selectedDevicesSmartec.length === 0) ||
    (isSwitch && selectedDevicesSwitch.length === 0)) {
      setChecked(groups.reduce((acc: any, group: any) => ({ ...acc, [group.groupId]: false }), {}));
    }
  }, [selectedDevicesStandard, selectedDevicesSolar, selectedDevicesHybrid, selectedDevicesRgb, selectedDevicesSmartec, selectedDevicesShuncom, selectedDevicesSwitch]);

  return (
    <FilterByGroupStyled>
      <div onClick={(e) => { !Object.values(checked).some(value => value === true) && e.stopPropagation(); setShowList(!showList) }}
        className="filter__title-container"
      >
        <span className="title-container__title">
          {t("Groups")} {count !== 0 && !showList && `(${count})`}
        </span>
        <span className="material-symbols-rounded title-container__icon">
          {showList ? "expand_less" : "expand_more"}
        </span>
      </div>
      {showList && groupsList ?
        groupsList.length > 0 ?
          <article>
            <div style={{ cursor: "default", paddingLeft: "15px", paddingRight: "15px", marginBottom: "8px", position: 'relative', display: 'inline-block',  width: "100%" }}>
              <img src={search} width={16} style={{ position: 'absolute', left: '20px', top: '50%', transform: 'translateY(-50%)' }}/>
              <input type="text" placeholder="Search" value={searchGroup} onChange={(e) => setSearchGroup(e.target.value)} style={{ border: "none", background: "rgba(189, 189, 189, 0.2)",
                paddingLeft: "43px", width: "100%", borderRadius: "4px"}}
              />
            </div>
            <div style={{maxHeight: "502px", overflowY: "auto", width: "100%" }}>
            {groupsList && (
              <>
                <style>
                  {`
                    .group-span:hover {
                      background-color: #1c1b1f11;
                    }
                  `}
                </style>
                <span
                  className="group-span"    
                  key="all-groups"
                  onClick={() => {
                    const allChecked = Object.values(checked).every(val => val);
                    setChecked((prevChecked:any) => {
                      const newChecked:any = {};
                      let allNodes:any = [];

                      groupsList.forEach((group:any) => {
                        newChecked[group.groupId] = !allChecked;
                        allNodes = allNodes.concat(group.nodes);
                      });

                      const uniqueNodes = allNodes.reduce((acc: any[], current: any) => {
                        const isDuplicate = acc.some(node => node.node_id === current.node_id);
                        if (!isDuplicate) {
                          acc.push(current);
                        }
                        return acc;
                      }, []);

                      addOrDeleteNodesToMap(uniqueNodes, !allChecked);

                      return newChecked;
                    });
                  }}
                  style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", isolation: "isolate", paddingLeft: "15px", paddingRight: "15px",
                    minHeight: "56px", cursor: "pointer", textAlign: "left", backgroundColor: Object.keys(checked).length > 0 && Object.values(checked).every(val => val) ? "#1c1b1f11" : "",
                  }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <input
                      type="checkbox"
                      color="#ff0000"
                      checked={Object.keys(checked).length > 0 && Object.values(checked).every(val => val)}
                      className="form-check-input"
                      id="all"
                      style={{ width: "16px", height: "16px", backgroundColor: Object.keys(checked).length > 0 && Object.values(checked).every(val => val) ? accentButtonsColor : "#FFFFFF" }}
                    />
                    {t("All").toUpperCase()}
                  </div>
                </span>
              </>
            )}
            {(searchGroup ? groupsList.filter((group:any) => group.groupName?.toLowerCase().includes(searchGroup?.toLowerCase())) : groupsList).map((group: any, index: any) => (
              <>
                <style>
                  {`
                    .group-span:hover {
                      background-color: #1c1b1f11;
                    }
                  `}
                </style>
                <span
                  className="group-span"
                  key={group.groupId}
                  onClick={() => {
                    setChecked((prevChecked:any) => {
                      const newChecked = {...prevChecked, [group.groupId]: !prevChecked[group.groupId]};
                      addOrDeleteNodesToMap(group.nodes, newChecked[group.groupId]);
                      return newChecked;
                    });
                  }}
                  style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", isolation: "isolate", paddingLeft: "15px", paddingRight: "15px",
                    minHeight: "56px", cursor: "pointer", textAlign: "left", backgroundColor: checked[group.groupId] ? "#1c1b1f11" : "" }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    <input
                      type="checkbox"
                      color="#ff0000"
                      checked={checked[group.groupId] || false}
                      className="form-check-input"
                      id={group.groupId}
                      style={{ width: "16px", height: "16px", backgroundColor: checked[group.groupId] ? accentButtonsColor : "#FFFFFF" }}
                    />
                    {group.groupName}
                  </div>
                </span>
              </>
            ))}
            </div>
          </article>
        :
          groupsList.length === 0 ?
            <span
              className="group-span"    
              key="all-groups"
              style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", isolation: "isolate", paddingLeft: "15px", paddingRight: "15px",
                minHeight: "56px", textAlign: "left" }}
            >
              <>{t("No groups")}</>
            </span>
          :
            <></>
      :
        <></>
      }
    </FilterByGroupStyled>
  );
};
