import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  registerables,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import 'chart.js/auto';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ...registerables
);

export const UsersChart = () => {
  const userRole = useAppSelector((state) => state.user.role_name);
  const reportsInfo = useAppSelector((state) => state.reports);
  const { t } = useTranslation();
  
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      maintainAspectRatio: false,
    },
    cutout: '85%'
  };
  
  const labels = userRole === "owner" ?
    [t("owner"), t("admin"), t("observer"), t("maintenance"), t("external")]
  :
    [t("admin"), t("observer"), t("maintenance"), t("external")]
  const colors = userRole === "owner" ?
    ["#1B2559", "#FBBC05", "#45D0EE", "#444444", "#A3AED0"]
  :
    ["#FBBC05", "#45D0EE", "#444444", "#A3AED0"]
  
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: ` ${t("total")}`,
        data: userRole === "owner" ?
          [reportsInfo.usersOwner, reportsInfo.usersAdmin, reportsInfo.usersObserver, reportsInfo.usersMaintenance, reportsInfo.usersExternal]
        :
          [reportsInfo.usersAdmin, reportsInfo.usersObserver, reportsInfo.usersMaintenance, reportsInfo.usersExternal],
        backgroundColor: colors,
        weight: 0.5
      }
    ],
  };

  return (
    <div style={{ width: "150px", height: "150px" }}>
      <Doughnut options={options} data={data} />
    </div>
  )
};
