import { useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend,
  registerables,
} from "chart.js";
import { Line } from "react-chartjs-2";
import 'chart.js/auto';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend,
  zoomPlugin,
  ...registerables
);

function ElectricalParametersChart({ isVisibleDali, isVisibleAllegro, isVisibleDaliAllegro, isVisibleShuncom, selectedLamp }:any) {
  const ledCurrentDali = useAppSelector((state) => state.electricParameters.ledCurrentDali);
  const driverTempDali = useAppSelector((state) => state.electricParameters.driverTempDali);
  const netVoltageDali = useAppSelector((state) => state.electricParameters.netVoltageDali);
  const apparentPowerDali = useAppSelector((state) => state.electricParameters.apparentPowerDali);
  const netVoltageAllegro = useAppSelector((state) => state.electricParameters.netVoltageAllegro);
  const apparentPowerAllegro = useAppSelector((state) => state.electricParameters.apparentPowerAllegro);
  const netCurrentAllegro = useAppSelector((state) => state.electricParameters.netCurrentAllegro);
  const ledCurrentDaliAllegro = useAppSelector((state) => state.electricParameters.ledCurrentDaliAllegro);
  const driverTempDaliAllegro = useAppSelector((state) => state.electricParameters.driverTempDaliAllegro);
  const netVoltageDaliAllegro = useAppSelector((state) => state.electricParameters.netVoltageDaliAllegro);
  const apparentPowerDaliAllegro = useAppSelector((state) => state.electricParameters.apparentPowerDaliAllegro);
  const netCurrentDaliAllegro = useAppSelector((state) => state.electricParameters.netCurrentDaliAllegro);
  const driverTempShuncom = useAppSelector((state) => state.electricParameters.driverTempShuncom);
  const netVoltageShuncom = useAppSelector((state) => state.electricParameters.netVoltageShuncom);
  const apparentPowerShuncom = useAppSelector((state) => state.electricParameters.apparentPowerShuncom);
  const netCurrentShuncom = useAppSelector((state) => state.electricParameters.netCurrentShuncom);
  const hours = useAppSelector((state) => state.electricParameters.timestamps);

  const chartRef:any = useRef(null);
  const { t } = useTranslation();

  type ScaleOptions = {
    grid: {
      borderColor: string;
      color: string;
    };
    title: {
      display: boolean;
      text: (ctx: any) => string;
    };
  };

  const scaleOptions: ScaleOptions = {
    grid: {
      borderColor: "#ccc",
      color: "rgba(0, 0, 0, 0.1)",
    },
    title: {
      display: false,
      text: (ctx: any) => "Static Title",
    },
  };

  const scales = {
    x: {
      type: "category",
      ...scaleOptions,
    },
    y: {
      type: "linear",
      ticks: {
        callback: (val: any, index: any, ticks: any) =>
          index === 0 || index === ticks.length - 1 ? null : val,
      },
      ...scaleOptions,
    },
  };

  const options:object = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
        align: 'start' as const,
        display: false,
        labels: {
          usePointStyle: true,
          pointStyle: 'rect', /* 'circle', 'rect', 'rectRounded' */
          /* padding: 12, */
          boxHeight: 12,
          font: {
            size: 16
          }
        }
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: "x" as const,
          drag: {
            enabled: true,
          },
        },
      },
      tooltip: {
        mode: 'index', // Muestra todos los valores en el índice
        intersect: false, // Permite que el tooltip se muestre incluso si no se está directamente sobre un punto
        callbacks: {
          label: (tooltipItem: any) => {
            const label = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw; // Valor del punto
            return `${label}: ${value.toFixed(2)}`; // Formato del tooltip limitado a 2 decimales
          },
        },
      },
    },
    scales: scales as any,
  };

  const data = {
    labels: hours,
    datasets: selectedLamp.manufacturer_name === "shuncom" ?
      [
        {
          fill: true,
          label: t("driverTemp"),
          data: driverTempShuncom,
          borderColor: "#00C7F2",
          backgroundColor: "rgba(0, 199, 242, 0.4)",
          lineTension: 0,
          pointRadius: 0,
          borderWidth: 1,
          hidden: !isVisibleShuncom[0]
        },
        {
          fill: true,
          label: t("netVoltage"),
          data: netVoltageShuncom,
          borderColor: "#005882",
          backgroundColor: "rgba(100, 149, 237, 0.4)",
          stepped: true,
          pointRadius: 0,
          borderWidth: 1,
          hidden: !isVisibleShuncom[1]
        },
        {
          fill: true,
          label: t("appPower"),
          data: apparentPowerShuncom,
          borderColor: "#FFCB21",
          backgroundColor: "rgba(255, 203, 33, 0.4)",
          lineTension: 0.4,
          pointRadius: 0,
          borderWidth: 1,
          hidden: !isVisibleShuncom[2]
        },
        {
          fill: true,
          label: t("netCurrent"),
          data: netCurrentShuncom,
          borderColor: "#DC0124",
          backgroundColor: "rgba(220, 1, 36, 0.4)",
          lineTension: 0.4,
          pointRadius: 0,
          borderWidth: 1,
          hidden: !isVisibleShuncom[3]
        },
      ]
    : selectedLamp.protocol_name === "Allegro" || selectedLamp.protocol_name === "1-10" ?
        [
          {
            fill: true,
            label: t("netVoltage"),
            data: netVoltageAllegro,
            borderColor: "#16D764",
            backgroundColor: "rgba(22, 215, 100, 0.4)",
            lineTension: 0.4,
            pointRadius: 0,
            borderWidth: 1,
            hidden: !isVisibleAllegro[0]
          },
          {
            fill: true,
            label: t("appPower"),
            data: apparentPowerAllegro,
            borderColor: "#005882",
            backgroundColor: "rgba(100, 149, 237, 0.4)",
            stepped: true,
            pointRadius: 0,
            borderWidth: 1,
            hidden: !isVisibleAllegro[1]
          },
          {
            fill: true,
            label: t("netCurrent"),
            data: netCurrentAllegro,
            borderColor: "#FFCB21",
            backgroundColor: "rgba(255, 203, 33, 0.4)",
            lineTension: 0.4,
            pointRadius: 0,
            borderWidth: 1,
            hidden: !isVisibleAllegro[2]
          },
        ]
      : selectedLamp.protocol_name === "Allegro-Dali" ?
          [
            {
              fill: true,
              label: t("ledCurrent"),
              data: ledCurrentDaliAllegro,
              borderColor: "#16D764",
              backgroundColor: "rgba(22, 215, 100, 0.4)",
              lineTension: 0.4,
              pointRadius: 0,
              borderWidth: 1,
              hidden: !isVisibleDaliAllegro[0]
            },
            {
              fill: true,
              label: t("driverTemp"),
              data: driverTempDaliAllegro,
              borderColor: "#00C7F2",
              backgroundColor: "rgba(0, 199, 242, 0.4)",
              lineTension: 0,
              pointRadius: 0,
              borderWidth: 1,
              hidden: !isVisibleDaliAllegro[1]
            },
            {
              fill: true,
              label: t("netVoltage"),
              data: netVoltageDaliAllegro,
              borderColor: "#005882",
              backgroundColor: "rgba(100, 149, 237, 0.4)",
              stepped: true,
              pointRadius: 0,
              borderWidth: 1,
              hidden: !isVisibleDaliAllegro[2]
            },
            {
              fill: true,
              label: t("appPower"),
              data: apparentPowerDaliAllegro,
              borderColor: "#FFCB21",
              backgroundColor: "rgba(255, 203, 33, 0.4)",
              lineTension: 0.4,
              pointRadius: 0,
              borderWidth: 1,
              hidden: !isVisibleDaliAllegro[3]
            },
            {
              fill: true,
              label: t("netCurrent"),
              data: netCurrentDaliAllegro,
              borderColor: "#DC0124",
              backgroundColor: "rgba(220, 1, 36, 0.4)",
              lineTension: 0.4,
              pointRadius: 0,
              borderWidth: 1,
              hidden: !isVisibleDaliAllegro[4]
            },
          ]
        :
          [
            {
              fill: true,
              label: t("ledCurrent"),
              data: ledCurrentDali,
              borderColor: "#16D764",
              backgroundColor: "rgba(22, 215, 100, 0.4)",
              lineTension: 0.4,
              pointRadius: 0,
              borderWidth: 1,
              hidden: !isVisibleDali[0]
            },
            {
              fill: true,
              label: t("driverTemp"),
              data: driverTempDali,
              borderColor: "#00C7F2",
              backgroundColor: "rgba(0, 199, 242, 0.4)",
              lineTension: 0,
              pointRadius: 0,
              borderWidth: 1,
              hidden: !isVisibleDali[1]
            },
            {
              fill: true,
              label: t("netVoltage"),
              data: netVoltageDali,
              borderColor: "#005882",
              backgroundColor: "rgba(100, 149, 237, 0.4)",
              stepped: true,
              pointRadius: 0,
              borderWidth: 1,
              hidden: !isVisibleDali[2]
            },
            {
              fill: true,
              label: t("appPower"),
              data: apparentPowerDali,
              borderColor: "#FFCB21",
              backgroundColor: "rgba(255, 203, 33, 0.4)",
              lineTension: 0.4,
              pointRadius: 0,
              borderWidth: 1,
              hidden: !isVisibleDali[3]
            },
          ],
  };

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const chartInstance = chartRef.current.chartInstance;

      chartInstance.data = data;
      chartInstance.options = options;

      chartInstance.update();
    }
  }, [data, ledCurrentDali, driverTempDali, netVoltageDali, apparentPowerDali, netVoltageAllegro, apparentPowerAllegro, netCurrentAllegro, ledCurrentDaliAllegro, driverTempDaliAllegro, netVoltageDaliAllegro, apparentPowerDaliAllegro, netCurrentDaliAllegro,
    driverTempShuncom, netVoltageShuncom, apparentPowerShuncom, netCurrentShuncom]);

  
  return (//294     252      206
    <Line width={900} height={265} options={options} data={data} />
  )
}

export default ElectricalParametersChart;
