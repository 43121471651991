import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { saveSolarParamsActionCreator } from "../../../../redux/slices/strategiesSlice/strategiesSlice";
import { Dimming } from "../Step2Dimming/Dimming";
import { numericRegex, solarBattNumericRegex } from "../../../../utils/regex";
import { InputField, CardContainer, NextButton } from "../StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import luminairePower from "../../../../assets/new_icons/lum_power.svg";
import photocell from "../../../../assets/new_icons/photocell_volt.svg";
import singleLed from "../../../../assets/new_icons/single_led.svg";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface ParamsProps {
  setShowDetails?: any;
  setShowParams?: any;
  showParams?: any;
  showDimming?: any;
  setShowDimming?: any;
  goToDimming?: any;
  errorMessage?: boolean;
  setErrorMessage?: any
};

export const Params = ({ showDimming, goToDimming, errorMessage, setErrorMessage }: ParamsProps) => {
  const dispatch = useAppDispatch();
  const strategyInfo = useAppSelector((state) => state.strategies);
  const [ledsNumber, setLedsNumber] =  useState(strategyInfo.ledsNumber);
  const [ledVolt, setLedVolt] =  useState(strategyInfo.ledVolt);
  const [lumPower, setLumPower] =  useState(strategyInfo.lumPower);
  const [photoVolt, setPhotoVolt] =  useState(strategyInfo.photoVolt);
  
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const { t } = useTranslation();
  let aa = t("hola")

  useEffect(() => {
    if (ledsNumber && ledVolt && lumPower && photoVolt) {
      setErrorMessage(false)
    };
    
    dispatch(saveSolarParamsActionCreator({ ledsNumber: ledsNumber, ledVolt: ledVolt, lumPower: lumPower, photoVolt: photoVolt }));
  }, [ledsNumber, ledVolt, lumPower, photoVolt]);

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column", paddingLeft: "40px" }}>
      <div style={{ marginBottom: "50px" }}>
        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step2")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("paramsConfig")}</Text>
        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("paramsConfigSubtitleStrategies")}</Text>
      </div>
      <div style={{ marginBottom: "40px"}}>
        <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px" }}>{t("configLedParamsTitle")}</Text>
        <Text textType="caption" fontWeight="400" marginBottom="0px" style={{ fontSize: "12px" }}>{t("configLedParamsSubtitle")}</Text>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" , alignContent: "center", paddingRight: "40px" }}>
        <div style={{ display: "flex", flexDirection: "row", alignSelf: "center", gap: "20px" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", gap: "20px" }}>
            <Text textType="caption" style={{ fontSize: "14px" }}>{t("ledsNumber")}</Text>
            <Text textType="caption" style={{ fontSize: "14px" }}>{t("voltLedSingle")}</Text>
            <Text textType="caption" style={{ fontSize: "14px" }}>{t("lumPowerAbr")}</Text>
            <Text textType="caption" style={{ fontSize: "14px" }}>{t("photocellVoltAbr")}</Text>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <InputField maxLength={2} value={ledsNumber} onChange={(e) => numericRegex.test(e.target.value) && setLedsNumber(e.target.value)}
              style={{ height: "30px", width: "70px", padding: "8px" }}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputField maxLength={4} value={ledVolt} onChange={(e) => solarBattNumericRegex.test(e.target.value) && setLedVolt(e.target.value)} 
                style={{ height: "30px", width: "70px", padding: "8px" }}
              />
              <span style={{ marginLeft: "-20px", fontSize: "12px", color: "#A3AED0" }}>V</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputField maxLength={3} value={lumPower} onChange={(e) => numericRegex.test(e.target.value) && setLumPower(e.target.value)}
                style={{ height: "30px", width: "70px", padding: "8px" }}
              />
              <span style={{ marginLeft: "-20px", fontSize: "12px", color: "#A3AED0" }}>W</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputField maxLength={2} value={photoVolt} onChange={(e) => numericRegex.test(e.target.value) && setPhotoVolt(e.target.value)}
                style={{ height: "30px", width: "70px", padding: "8px" }}
              />
              <span style={{ marginLeft: "-20px", fontSize: "12px", color: "#A3AED0" }}>V</span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "end", gap: "30px" }}>
            <img src={singleLed} width={20} />
            <img src={luminairePower} width={20} />
            <img src={photocell} width={20} style={{ marginBottom: "4px" }} />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignSelf: "center", gap: "10px" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", gap: "20px" }}>
            <Text textType="caption" color={supportTextColor} style={{ fontSize: "14px" }}>{t("voltLedStrip").toUpperCase()}</Text>
            <Text textType="caption" color={supportTextColor} style={{ fontSize: "14px" }}>{t("currentA").toUpperCase()}</Text>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div style={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
              <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={ledVolt} />
              <span style={{ marginLeft: "-20px", fontSize: "12px" }}>v</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", marginRight: "12px" }}>
              <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={lumPower} />
              <span style={{ marginLeft: "-20px", fontSize: "12px" }}>w</span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", gap: "20px" }}>
            <Text textType="caption" style={{ fontSize: "14px" }}>x</Text>
            <Text textType="caption" style={{ fontSize: "14px" }}>/</Text>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={ledsNumber} />
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }}
                value={
                  ledVolt && ledsNumber ? 
                    (ledVolt * ledsNumber) % 1 !== 0 ? (ledVolt * ledsNumber).toFixed(2) : ledVolt * ledsNumber
                  : ""
                }
              />
              <span style={{ marginLeft: "-14px", fontSize: "12px" }}>v</span>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", gap: "20px" }}>
            <Text textType="caption" style={{ fontSize: "14px" }}>=</Text>
            <Text textType="caption" style={{ fontSize: "14px" }}>=</Text>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }}
                value={
                  ledVolt && ledsNumber ? 
                    (ledVolt * ledsNumber) % 1 !== 0 ? (ledVolt * ledsNumber).toFixed(2) : ledVolt * ledsNumber
                  : ""
                }
              />
              <span style={{ marginLeft: "-14px", fontSize: "12px" }}>v</span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }}
                value={
                  lumPower && ledVolt && ledsNumber ? 
                    (lumPower / (ledVolt * ledsNumber)).toFixed(2)
                  : ""
                }
              />
              <span style={{ marginLeft: "-14px", fontSize: "12px" }}>v</span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", backgroundColor: "#FF000040", border: "2px solid #FF0000", borderRadius: "6px", marginTop: "40px", width: "70%", padding: "8px", gap: "20px",
        alignItems: "center", paddingLeft: "12px", paddingRight: "12px" }}
      >
        <Text fontWeight="700" style={{ color: "#FF0000" }}>{t("warning").toUpperCase()}:</Text>
        <Text style={{ fontSize: "12px" }}>{t("warningText")}</Text>
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignSelf: "center", justifySelf: "center", width: "300px", marginBottom: errorMessage ? "11px" : "32px", marginRight: "40px" }}>
        {
          errorMessage ?
            <Text marginBottom="0px" style={{display: "flex",  justifyContent: "center", color: "red", marginTop: "172px"}}>{t("errorBlankInput")}</Text>  
          :
            <></>
        }
        <NextButton style={{ background: errorMessage ? accentButtonsColor+95 : accentButtonsColor, borderColor: errorMessage ? `1px solid ${accentButtonsColor+95}` : accentButtonsColor,
          justifySelf: "end", alignSelf: "center", width: "300px", marginTop: errorMessage ? "10px" : "203px", cursor: errorMessage ? "not-allowed" : "pointer" }}
          onClick={() => {
            if (!errorMessage) {
              goToDimming()
            }
          }}
        >
          {t("continue")}
        </NextButton>
        {showDimming && <Dimming />}
        <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
          <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
          <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
          <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
          <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        </div>
      </div>
    </CardContainer>
  );
};
