import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { resetSolarStrategiesActionCreator } from "../../../redux/slices/solar/solarStrategiesSlice";
import { saveSelectedStrategyActionCreator, deleteSelectedStrategyActionCreator } from "../../../redux/slices/strategiesSlice/strategiesSlice";
import CurrentStrategyChartSolar from "./CurrentStrategyChartSolar";
import CurrentStrategyChartStandard from "./CurrentStrategyChartStandard";
import { StrategiesListModal } from "./StrategiesListModal";
import { Text, TextBox, TextSpan } from "../../../styles/shared-styles/text-sizes";
import maxConsumIcon from "../../../assets/new_icons/max_consumption.svg";
import plusCircle from "../../../assets/new_icons/plusCircle.svg";
import { ReactComponent as EnergyIcon } from "../../../assets/new_icons/Energy.svg";

interface CurrentStrategyProps {
  selectedLamp: any;
  selectedLampId: string | undefined;
  selectedLampStrategyId: string,
  viewState: any;
}

export const CurrentStrategy = ({ selectedLamp, selectedLampId, selectedLampStrategyId, viewState }: CurrentStrategyProps) => {
  const [showActions, setShowActions] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const strategies = useAppSelector((state) => state.strategies.strategies);
  const selectedStrategy = useAppSelector((state) => state.strategies.selectedStrategy);
  const userRole = useAppSelector((state) => state.user.role_name);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const { t } = useTranslation();
  
  /* strategies.map((strategy:any) => {
    if (selectedLampStrategyId === null) {
      return dispatch(saveSelectedStrategyActionCreator({ selectedStrategy: false }))
    }

    if (strategy.id === selectedLampStrategyId) {
      return dispatch(saveSelectedStrategyActionCreator({ selectedStrategy: strategy }))
    }
  }) */

  useEffect(() => {
    if (selectedLampStrategyId === null) {
      dispatch(saveSelectedStrategyActionCreator({ selectedStrategy: false }));
    } else {
      const selectedStrategy = strategies?.find((strategy:any) => strategy.id === selectedLampStrategyId);
      if (selectedStrategy) {
        dispatch(saveSelectedStrategyActionCreator({ selectedStrategy }));
      }
    }
  }, [/* selectedLampStrategyId, strategies, dispatch,  */selectedLamp, strategies]);

  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        height: "100%",
        flexGrow: 1
      }}
    >
      <div className="row">
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Text style={{ fontWeight: 500 }}>{t("currentStrategy")}</Text>
          </div>
          {/* <div style={{ textAlign: "right", marginTop: "-2px" }}>
            <img src={plusCircle} height={16} onClick={() => {navigate("/strategies", {state: {selectedLampId, viewState}})}} style={{ opacity: iconOpacity, cursor: "pointer" }}
              onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)}
            />
          </div> */}
          {userRole !== "observer" && userRole !== "maintenance" && selectedLamp.manufacturer_name !== "shuncom" && selectedLamp.online === "1" ?
            <div style={{ textAlign: "right", marginTop: "-2px" }}>
              <img src={plusCircle} height={16} onClick={() => {setShowActions(!showActions)}}
                style={{ cursor: selectedLamp.online === "1" ? "pointer" : "not-allowed", opacity: iconOpacity }}
                onMouseEnter={() => setIconOpacity(1)} onMouseLeave={() => setIconOpacity(0.5)}
              />
              {showActions && (
                <article
                  style={{
                    position: "absolute",
                    top: "42px",
                    right: "24px",
                    width: "240px",
                    maxHeight: "224px",
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
                    boxShadow: "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
                    borderRadius: "4px",
                    zIndex: 6
                  }}
                >  
                  <>
                    <style>
                      {`
                        .org-span:hover {
                          background-color: #1c1b1f11;
                        }
                      `}
                    </style>
                    <span
                      className="org-span"
                      onClick={async () => {setShowActions(!showActions); dispatch(resetSolarStrategiesActionCreator()); setShowModal(true)}}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        isolation: "isolate",
                        paddingLeft: "15px",
                        paddingRight: "6px",
                        width: "100%",
                        minHeight: "56px",
                        cursor: "pointer",
                        textAlign: "left",
                      }}
                    >
                      {t("changeDefaultStrategy")}
                    </span>
                    {/* {selectedStrategy && (
                      <span
                        className="org-span"
                        onClick={async () => {setShowActions(!showActions); dispatch(deleteSelectedStrategyActionCreator())}}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          isolation: "isolate",
                          paddingLeft: "15px",
                          paddingRight: "6px",
                          width: "100%",
                          minHeight: "56px",
                          cursor: "pointer",
                          textAlign: "left",
                        }}
                      >
                        {t("removeDefaultStrategy")}
                      </span>
                      )
                    } */}
                  </>
          




                  {/* {strategies && strategies?.map((strategy: any) => (
                    <>
                      <style>
                        {`
                          .org-span:hover {
                            background-color: #1c1b1f11;
                          }
                        `}
                      </style>
                      <span
                        className="org-span"
                        key={strategy.id}
                        onClick={async () => {setShowStrategies(!showStrategies); dispatch(saveSelectedStrategyActionCreator({ selectedStrategy: strategy }))}}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          isolation: "isolate",
                          paddingLeft: "15px",
                          paddingRight: "6px",
                          width: "100%",
                          minHeight: "56px",
                          cursor: "pointer",
                          textAlign: "left",
                        }}
                      >
                        {strategy?.strgy_name}
                      </span>
                    </>
                  ))} */}
                </article>
              )}
              {showModal && (
                <StrategiesListModal showModal={showModal} setShowModal={setShowModal} selectedLamp={selectedLamp} selectedLampId={selectedLampId} />
              )}
            </div>
          :
            <></>
          }
        </div>
        <div style={{ marginTop: "-8px" }}>
          <Text fontWeight="700" marginBottom="4px" style={{ marginTop: !selectedStrategy?.description ? "16px" : "0px" }}>
            {selectedStrategy?.strgy_name}
          </Text>
          <Text style={{ fontSize: "12px" }}>
            {selectedStrategy?.description}
          </Text>
        </div>
      </div>
      {strategies ? 
        selectedStrategy ?
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {selectedLamp.solar_id === null ?
                <CurrentStrategyChartStandard />
              :
                <CurrentStrategyChartSolar />
              }
            </div>
            {selectedLamp.solar_id === null ?
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "8px",
                  height: "20px",
                }}
              >
                <EnergyIcon height={14} style={{ color: selectedStrategy?.color || "rgb(135, 203, 232)" }} />
                <TextSpan color={selectedStrategy?.color || "rgb(135, 203, 232)"} fontWeight="500">
                  {selectedStrategy?.saving || 0}{t("percentageEnergySaved")}
                </TextSpan>
              </div>
            :
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "8px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <img src={maxConsumIcon} alt="" height={16} style={{ marginBottom: "1px" }} />
                  <Text marginBottom="-2px" style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{t("maxConsumShort").toUpperCase()}</Text>
                  <Text marginBottom="-2px" style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{selectedStrategy?.max_consum_wh} W</Text>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <Text marginBottom="-2px" style={{ color: selectedStrategy?.color, fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{t("battConsumShort").toUpperCase()}</Text>
                  <Text marginBottom="-2px" style={{ color: selectedStrategy?.color, fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{selectedStrategy?.total_consum_wh} W</Text>
                </div>
              </div>
            }
          </>
        :
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "absolute", top: 140, left: 50 }}>
            <Text>{t("noStrategyAssigned")}</Text>
          </div>
      :
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px"}}>
          <Text>{t("noStrategyCreated")}</Text>
          <button
            style={{
              cursor: "pointer",
              display: "flex",
              marginTop: "10px",
              borderRadius: "8px",
              alignItems: "center",
              padding: "8px",
              justifyContent: "center",
              height: "26px",
              background: buttonsColor,
              color: accentTextColor,
              border: "none",
              boxShadow:
                "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
              }}       
              onClick={() => {navigate("/strategies", {state: {selectedLampId, viewState}})}}
            >
              <TextBox fontWeight="500" color="#000000" style={{ fontSize: "10px" }}>
                {t("createYourOwnOne")}
              </TextBox>
          </button>
        </div>
      }





      {/* {strategies && selectedStrategy ? 
        <>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CurrentStrategyChart />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "8px",
              height: "20px",
            }}
          >
            <EnergyIcon height={14} style={{ color: selectedStrategy?.color || "rgb(135, 203, 232)" }} />
            <TextSpan color={selectedStrategy?.color || "rgb(135, 203, 232)"} fontWeight="500">
              {selectedStrategy?.saving || 0}{txt.percentageEnergySaved}
            </TextSpan>
          </div>
        </>
      :
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px"}}>
          <Text>Don't have any strategy yet?</Text>
          <button
            style={{
              cursor: "pointer",
              display: "flex",
              marginTop: "10px",
              borderRadius: "8px",
              alignItems: "center",
              padding: "8px",
              justifyContent: "center",
              height: "26px",
              background: "#ffc107",
              border: "none",
              boxShadow:
                "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
              }}
              
              onClick={() => {navigate("/strategies", {state: {selectedLampId, viewState}})}}
            >
              <TextBox fontWeight="500" color="#000000" style={{ fontSize: "10px" }}>
                Create your own one
              </TextBox>
          </button>
        </div>
      } */}
    </div>
  );
};
