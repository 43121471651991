import { store } from '../redux/store';
import { fillReportInfoActionCreator } from '../redux/slices/reportsSlice/pdfReportSlice';
import { saveGeneralReportsInfoActionCreator, saveStandardReportsInfoActionCreator } from '../redux/slices/reportsSlice/reportsSlice';
import { Reports } from "../types/interfaces";

export const useReportsService = () => {
  const getGeneralReports = async (orgId: number, serviceId: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_REPORTS_G as string}${serviceId}/${orgId}`, {credentials: 'include'});
      const generalInfoParsed = await response.json();

      const generalInfo: Reports = {
        generalTotal: generalInfoParsed.general.total,
        generalStandard: generalInfoParsed.general.devices.standard,
        generalSolar: generalInfoParsed.general.devices.solar,
        generalHybrid: generalInfoParsed.general.devices.hybrid,
        generalGateways: generalInfoParsed.general.devices.gateways,
        generalSwitchboards: generalInfoParsed.general.devices.switchboards,
        generalRgb: generalInfoParsed.general.devices.rgb,
        alarmsTotal: generalInfoParsed.alarms.total,
        alarmsSolved: generalInfoParsed.alarms.solved,
        alarmsPending: generalInfoParsed.alarms.pending,
        usersTotal: generalInfoParsed.users.total,
        usersOwner: generalInfoParsed.users.roles.owners,
        usersAdmin: generalInfoParsed.users.roles.admin,
        usersExternal: generalInfoParsed.users.roles.external,
        usersObserver: generalInfoParsed.users.roles.observer,
        usersMaintenance: generalInfoParsed.users.roles.maintenance,
      };

      store.dispatch(saveGeneralReportsInfoActionCreator(generalInfo));

      return true;
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  const getStandardReports = async (orgId: number, serviceId: number) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_REPORTS_G as string}${serviceId}/${orgId}/standard`, {credentials: 'include'});
      const standardInfoParsed = await response.json();

      const standardInfo: Reports = {
        standardEnergyConsumed: standardInfoParsed.energyConsumed,
        standardEnergySaved: standardInfoParsed.energySaved
      };

      store.dispatch(saveStandardReportsInfoActionCreator(standardInfo));

      return true;
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  const postReportInfo = async (orgId: number, serviceId: number) => {
    const values = store.getState();

    const body = {
      start_date: values.pdfReport.startDate,
      end_date: values.pdfReport.endDate,
      luminaires: {
        standard: values.pdfReport.selectedDevicesStandard,
        solar: values.pdfReport.selectedDevicesSolar,
        hybrid: values.pdfReport.selectedDevicesHybrid,
        rgb: values.pdfReport.selectedDevicesRgb
      },
      gateways: {
        smartec: values.pdfReport.selectedDevicesSmartec,
        shuncom: values.pdfReport.selectedDevicesShuncom
      },
      switchboards: values.pdfReport.selectedDevicesSwitch
    }

    try {
      let res = await fetch(`${process.env.REACT_APP_API_REPORTS_G as string}${serviceId}/${orgId}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        },
      });

      const reportInfo = await res.json();
      if (!reportInfo) {
        return false;
      } else {
        store.dispatch(fillReportInfoActionCreator({ resAlarmsPending: parseInt(reportInfo.alarms.pending, 10), resAlarmsSolved: parseInt(reportInfo.alarms.solved, 10),
          resAlarmsTotal: parseInt(reportInfo.alarms.total, 10), resStandardTotalEnergy: reportInfo.luminaires.standard.totalEnergy, resStandardTotalSavings: reportInfo.luminaires.standard.totalSavings }))
        return reportInfo;
      }
    } catch (error) {
      console.log('El error: ', error);
      return false;
    }
  };

  return {
    getGeneralReports,
    getStandardReports,
    postReportInfo
  };
};


