import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import dragData from "chartjs-plugin-dragdata";
import { ThemeProvider } from "styled-components";
import { changeBackgroundStrategiesActionCreator, turnBackgroundStrategiesFalseActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { changeStrategyChartActionCreator, changeAstroDimActionCreator, saveIndexActionCreator, turnAstroDimTrueActionCreator, saveOffsetValuesActionCreator }
  from "../../../redux/slices/strategiesSlice/strategiesSlice";
import { getHoursAndValues, calculateEnergySaving } from "../StrategiesLogic";
import { hexToRgb } from "../../../utils/auxiliaryFunctions";
import { dimmingRegex, hourRegex, minuteRegex, numericRegex, offsetRegex } from "../../../utils/regex";
import { Input } from "../../Input/InputStyled";
import { SwitchComponent } from "../StrategiesSteps/Step2Dimming/DimmingStyled";
import { InputField, NextButton } from "../StrategiesSteps/StrategiesStepsStyled";
import { styledMainTheme } from "../../../styles/shared-styles/styleMainTheme";
import { Text } from "../../../styles/shared-styles/text-sizes";
import { ReactComponent as EnergyIcon } from "../../../assets/new_icons/Energy.svg";
import clock from "../../../assets/new_icons/Clock.svg";
import deleteIcon from "../../../assets/new_icons/Delete.svg";
import editIcon from "../../../assets/new_icons/Edit.svg";
import plus from "../../../assets/new_icons/plus.svg";
import sunsetIcon from "../../../assets/new_icons/Sunset.svg";
import sunriseIcon from "../../../assets/new_icons/Sunrise.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  dragData,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface EditChartStandardProps {
  item: any;
  setErrorSunsetOffsetValue?: any,
  errorSunsetOffsetValue?: any,
  setErrorSunriseOffsetValue?: any,
  errorSunriseOffsetValue?: any
}

function EditChartStandard({ item, setErrorSunsetOffsetValue, errorSunsetOffsetValue, setErrorSunriseOffsetValue, errorSunriseOffsetValue }: EditChartStandardProps) {
  const dispatch = useAppDispatch();
  const astroDim = useAppSelector((state) => state.strategies.astroDim);
  const indexRedux = useAppSelector((state) => state.strategies.index);
  const background = useAppSelector((state) => state.graphics.backgroundStrategies);
  const strategy = useAppSelector((state) => state.strategies);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const { t } = useTranslation();

  const [sunsetOffsetValue, setSunsetOffsetValue] = useState(item.sunset_off);
  const [sunriseOffsetValue, setSunriseOffsetValue] = useState(item.sunrise_off);
  const [dimmingsList, setDimmingsList]: any = useState();
  const [selectedCard, setSelectedCard]: any = useState();
  const [hourFromInput, setHourFromInput] = useState("");
  const [minuteFromInput, setMinuteFromInput] = useState("");
  const [dimmingFromInput, setDimmingFromInput] = useState("");
  const [hoursList, setHoursList]: any = useState();
  const [hourFromList, setHourFromList]: any = useState();
  const [minuteFromList, setMinuteFromList]: any = useState();
  const savingValue = useAppSelector((state) => state.strategies.saving);
  const [errorInvalidData, setErrorInvalidData] = useState(false);
  const [errorExistingPoint, setErrorExistingPoint] = useState(false);

  const theme = {
    primaryColor: styledMainTheme?.light?.smartecGreen,
  };

  const times = [item.time1, item.time2, item.time3, item.time4, item.time5, item.time6, item.time7];
  let labels : any = [];

  for (let i = 0; i < times.length; i++) {
    if (times[i] === '254:254') {
      if (times[i-1] === "99:99") {
        labels[labels.length - 1] = t("Sunrise");
        break;
      }
      break;
    }
    
    if (times[i] === "98:98") {
      labels.push(t("Sunset"));
    } else {
      labels.push(times[i]);
    }
  }

  const dimmings = [
    item.dim1,
    item.dim2,
    item.dim3,
    item.dim4,
    item.dim5,
    item.dim6,
    item.dim7,
  ];
  let newData = dimmings.slice(0, labels.length);

  const [itemData, setItemData] = useState({
    labels: strategy?.labels || labels,
    datasets: strategy?.data || [
      {
        label: "Standard",
        data: newData,
        borderColor: item.color,
        backgroundColor: item.color + "80",
        fill: true,
        stepped: true,
        pointRadius: 5,
        pointBackgroundColor: "white",
        showLine: true,
        spanGaps: true,
      },
    ],
  });

  const totalPoints = itemData.datasets[0].data?.length;

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
      },
      dragData: {
        round: 0,
        onDragEnd: (value: any, datasetIndex: any) => {
          setItemData((data) => {
            data.datasets[0].data[value] = datasetIndex;

            return {
              ...data,
              datasets: data.datasets.map((dataset: any) => ({
                ...dataset,
              })),
            };
          });
        },
      },
    },
    scales: {
      y: {
        display: true,
        suggestedMax: 100,
        suggestedMin: 0,
        ticks: {
          stepSize: 25,
        },
      },
      x: {
        display: true,
        ticks: {
          stepSize: 25,
          maxTicks: 3,
        },
      },
    },
    dragData: true,
  };

  const handleOffsetInputsChange = (e: any, type: string) => {
    if (offsetRegex.test(e.target.value) && (e.target.value !== "00" && e.target.value !== "-0")) {
      if(e.target.value.includes("-")) {
        if (e.target.value.length <= 3) {
          const numericValue = parseInt(e.target.value, 10);
          if (numericValue >= -60 || e.target.value === "-") {
            if (type === "sunset") {
              setSunsetOffsetValue(e.target.value);
              setErrorSunsetOffsetValue(false);
            } else if (type === "sunrise") {
              setSunriseOffsetValue(e.target.value);
              setErrorSunriseOffsetValue(false);
            }
          } else {
            if (type === "sunset") {
              setErrorSunsetOffsetValue(true);
            } else if (type === "sunrise") {
              setErrorSunriseOffsetValue(true);
            }
          }
        } else {
          if (type === "sunset") {
            setErrorSunsetOffsetValue(true);
          } else if (type === "sunrise") {
            setErrorSunriseOffsetValue(true);
          }
        }
      } else {
        if (e.target.value.length <= 2) {
          if (e.target.value >= 1 && e.target.value <= 60) {
            if (type === "sunset") {
              setSunsetOffsetValue(e.target.value);
              setErrorSunsetOffsetValue(false);
            } else if (type === "sunrise") {
              setSunriseOffsetValue(e.target.value);
              setErrorSunriseOffsetValue(false);
            }
          } else {
            if (type === "sunset") {
              setErrorSunsetOffsetValue(true);
            } else if (type === "sunrise") {
              setErrorSunriseOffsetValue(true);
            }
          }
        } else {
          if (type === "sunset") {
            setErrorSunsetOffsetValue(true);
          } else if (type === "sunrise") {
            setErrorSunriseOffsetValue(true);
          }
        }
      } 
    } else {
      e.target.value = "";
    }

    if (e.target.value === "") {
      if (type === "sunset") {
        setErrorSunsetOffsetValue(false);
      } else if (type === "sunrise") {
        setErrorSunriseOffsetValue(false);
      }
    }
  };

  const formatTimeforList = async (index: any) => {
    const [hours, minutes] = itemData.labels[index].split(':');
    setHourFromList(hours);
    setMinuteFromList(minutes);
  };

  const handleHoursInputsChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (numericRegex.test(e.target.value)) {
      if (e.target.value.length === 2) {
        if (type === "hour" && hourRegex.test(e.target.value)) {
          setHourFromInput(e.target.value);
        } else if (type === "minute" && minuteRegex.test(e.target.value)) {
          setMinuteFromInput(e.target.value);
        } else {
          e.target.value = "";
        }
      } else if (e.target.value.length <= 2) {
        if (type === "hour") {
          setHourFromInput(e.target.value);
        } else if (type === "minute") {
          setMinuteFromInput(e.target.value);
        }
      } else {
        e.target.value = "";
      }
    } else {
      e.target.value = "";
    }

    if (e.target.value === "") {
      if (type === "hour") {
        setHourFromInput("");
      } else if (type === "minute") {
        setMinuteFromInput("");
      }
    }
  };

  const handleDimmingInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (dimmingRegex.test(e.target.value)) {
      setDimmingFromInput(e.target.value);
    } else {
      e.target.value = "";
    }

    if (e.target.value === "") {
      setDimmingFromInput("")
    }
  };

  const orderLabel = (value: string, labels: any) => {
    const [hours, minutes] = value.split(':');
    let numericValue = parseInt(hours) + (minutes ? parseInt(minutes) / 60 : 0);

    if (numericValue < parseInt(labels[0])) {
      numericValue += 24;
    }

    let index = 0;
    while (index < labels.length) {
      const [currentHours, currentMinutes] = labels[index].split(':');
      let currentNumericValue = parseInt(currentHours) + (currentMinutes ? parseInt(currentMinutes) / 60 : 0);

      if (currentNumericValue < parseInt(labels[0])) {
        currentNumericValue += 24;
      }

      if (currentNumericValue > numericValue) {
        break;
      }

      index++;
    }

    return index
  };

  const addData = (hour: string, minute: string) => {
    if (hour.length === 2 && minute.length === 2 && dimmingFromInput.length !== 0) {
      if (totalPoints !== 7) {
        let value = [hour, minute].join(":")
        handleAddOrDeleteData(value, "addData")
      }
    } else {
      setErrorInvalidData(true)
    }
  };

  const deleteData = (index: any) => {
    let value = hoursList[index]
    handleAddOrDeleteData(value, "deleteData")
  };

  const handleAddOrDeleteData = (value: string, action: string) => {
    if (value === "12:00" || value === "11:59") {
      setErrorExistingPoint(true);
      return
    }

    let newLabel = [...itemData.labels];
    let newData = [...itemData.datasets];

    if (astroDim) {
      newLabel[0] = "12:00";
      newLabel[newLabel.length-1] = "11:59";
    };
    
    let index = orderLabel(value, newLabel);

    if (action === "addData") {
      if (itemData.labels.includes(value) && newData[0].data[index-1] !== undefined) {
        setErrorExistingPoint(true)
      } else if (itemData.labels.includes(value) && newData[0].data[index-1] === undefined) {
        newData[0].data[index-1] = dimmingFromInput;
      } else if (!itemData.labels.includes(value)) {
        newData[0].data.splice(index, 0, dimmingFromInput);
        newLabel = newLabel.slice();
        newLabel.splice(index, 0, value.toString());
      }
    }

    if (action === "deleteData") {
      newData[0].data.splice(index-1, 1);
      let tempNewLabel = newLabel;
      newLabel = tempNewLabel.filter((label:any) => label !== value)
    }

    if (astroDim) {
      newLabel[0] = t("Sunset");
      newLabel[newLabel.length-1] = t("Sunrise");
    };

    setItemData({
      labels: newLabel,
      datasets: newData.map((dataset:any) => ({
        ...dataset,   
      }))
    });

    setItemData({
      labels: newLabel,
      datasets: newData.map((dataset:any) => ({
        ...dataset,   
      }))
    });
  };

  const handleEditData = (oldIndex: any) => {
    if (astroDim) {
      hoursList[0] = "12:00";
      hoursList[hoursList.length - 1] = "11:59";
    }

    let oldValue = hoursList[oldIndex];
    const [oldHours, oldMinutes] = oldValue.split(':');

    let oldPosition: any;
    if (oldMinutes === "00") {
      itemData.labels.map((dataset: any, index: any) => {
        if (oldHours === dataset) {
          oldPosition = index;
        }
      });
    } else {
      itemData.labels.map((dataset: any, index: any) => {
        if (oldValue === dataset) {
          oldPosition = index;
        }
      });
    }

    let newLabel = [...itemData.labels];
    let newData = [...itemData.datasets];
    let newValue: any;

    if (astroDim) {
      newLabel[0] = "12:00";
      newLabel[newLabel.length-1] = "11:59";
    };

    if (hourFromInput.length === 2 && minuteFromInput.length === 2 && dimmingFromInput.length !== 0 ) {
      newValue = [hourFromInput, minuteFromInput].join(":")

      let existingIndex: any;
      newLabel.map((dataset: any, index: any) => {
        if (newValue === dataset) {
          existingIndex = index;
        }
      });

      if (newData[0].data[existingIndex] !== undefined && newData[0].data[existingIndex] === dimmingFromInput) {
        setErrorExistingPoint(true);
        return;
      }

      newData[0].data.splice(oldPosition, 1);
      let tempNewLabel = newLabel;
      newLabel = tempNewLabel.filter((label:any) => label !== oldValue)

      let index = orderLabel(newValue, newLabel);

      if (newLabel.includes(newValue)) {
        newData[0].data[index-1] = dimmingFromInput;
      } else if (!newLabel.includes(newValue)) {
        newData[0].data.splice(index, 0, dimmingFromInput);
        newLabel = newLabel.slice();
        newLabel.splice(index, 0, newValue.toString());
      }
    } else {
      setErrorInvalidData(true)
    }

    if (astroDim) {
      newLabel[0] = t("Sunset");
      newLabel[newLabel.length-1] = t("Sunrise");
    };

    setItemData({
      labels: newLabel,
      datasets: newData.map((dataset:any) => ({
        ...dataset,   
      }))
    });
  };

  const handleAstroDIM = () => {
    let newLabels = [...strategy.labels];
    if (astroDim) {
      dispatch(changeAstroDimActionCreator());
      newLabels[0] = "12:00";
      newLabels[newLabels.length-1] = "11:59";

      setItemData({
        labels: newLabels,
        datasets: itemData.datasets,
      });
    } else if (!astroDim) {
      dispatch(changeAstroDimActionCreator());
      newLabels[0] = t("Sunset");
      newLabels[newLabels.length-1] = t("Sunrise");
     
      setItemData({
        labels: newLabels,
        datasets: itemData.datasets,
      });
    }
  };

  const resetStates = () => {
    setHourFromInput("");
    setMinuteFromInput("");
    setDimmingFromInput("");
    setSelectedCard(false);
    dispatch(turnBackgroundStrategiesFalseActionCreator());
  }

  useEffect(() => {
    if (itemData.labels[0] === t("Sunset")) {
      dispatch(turnAstroDimTrueActionCreator());
    }

    const newArray = itemData.datasets[0].data.map((element:any) => (element === undefined ? "" : element));
    let lastDimmingPoint = newArray.lastIndexOf(newArray.filter((element:any) => element !== null)[newArray.filter((element:any) => element !== null).length-1]);
    newArray[lastDimmingPoint] = 0;
    console.log(itemData.labels, newArray)

    dispatch(changeStrategyChartActionCreator({labels: itemData.labels, data: newArray}));

    if (astroDim) {
      dispatch(saveOffsetValuesActionCreator({ offsetSunset: sunsetOffsetValue === "0" ? "" : sunsetOffsetValue, offsetSunrise: sunriseOffsetValue === "0" ? "" : sunriseOffsetValue }))
    } else {
      setSunsetOffsetValue(""); setSunriseOffsetValue(""); //quizás esta línea no cal?
      dispatch(saveOffsetValuesActionCreator({ offsetSunset: "", offsetSunrise: "" }))
    }

    let dimmingsArr : any = [];
    let hoursArr : any = [];
    itemData.datasets[0].data.map((point: any, index: number) => {
      if (point != null && point != undefined) {
        dimmingsArr.push(point);
        hoursArr.push(itemData.labels[index])
      }
    })
    dimmingsArr[dimmingsArr.length - 1] = 0;

    hoursArr?.forEach((hour: any, index: any) => {
      if (!hour.includes(':')) {
        hoursArr[index] += ':00';
      }
    })

    setDimmingsList(dimmingsArr);
    setHoursList(hoursArr);

    if (selectedCard === 0 || selectedCard) {
      setHourFromInput(hourFromList);
      setMinuteFromInput(minuteFromList);
      setDimmingFromInput(dimmingsList[selectedCard]);
      if (astroDim) {
        if (selectedCard === 0) {
          setHourFromInput("12");
          setMinuteFromInput("00");
        } else if (selectedCard === selectedCard.length -1) {
          setHourFromInput("11");
          setMinuteFromInput("59");
        }
      }
    }

    let hoursWithValues = getHoursAndValues(
      itemData.labels,
      itemData.datasets[0].data
    );
    calculateEnergySaving(hoursWithValues);
  }, [itemData, astroDim, sunsetOffsetValue, sunriseOffsetValue, selectedCard]);

  return (
    <div style={{ paddingRight: "22px", paddingLeft: "20px" }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "74px" }}>
        <div>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "0px", gap: "18px" }}>
            <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px" }}>{t("activateAstroDIM")}</Text>
            {astroDim ? 
              <ThemeProvider theme={theme}>
                <SwitchComponent
                  checked
                  checkedChildren="On" 
                  unCheckedChildren="Off"
                  onClick={() => {handleAstroDIM(); setErrorSunsetOffsetValue(false); setErrorSunriseOffsetValue(false)}}
                  />
              </ThemeProvider>
            :
              <SwitchComponent checkedChildren="On" unCheckedChildren="Off" checked={false} onClick={() => {handleAstroDIM()}} />
            }
          </div> 
          {astroDim ?
            <>
              <Text textType="caption" fontWeight="400" marginBottom="-2px" style={{fontSize: "12px"}}>{t("activatedAstroDIMTitle")}</Text>
              <Text textType="caption" fontWeight="400" marginBottom="0px" style={{fontSize: "12px"}}>{t("activatedAstroDIMSubtitle")}<strong>{t("activatedAstroDIMSubtitleBold")}</strong>.</Text>
            </>
          :
            <></>
          }
        </div>
        {astroDim ? 
          <div style={{ display: "flex", flexDirection: "column", alignSelf: "flex-start" }}>
            <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
              <div style={{ display: "flex", gap: "8px", alignItems: "end", width: "130px" }}>
                <img src={sunsetIcon} width={24} style={{marginBottom: "4px"}} />
                <div>
                  <Text textType="caption" marginBottom="2px" color={supportTextColor} style={{fontSize: "12px"}}>{t("sunsetOffset").toUpperCase()}</Text>
                  <InputField style={{ height: "30px", color: errorSunsetOffsetValue ? "red" : "#A3AED0" }} defaultValue={sunsetOffsetValue} onChange={(e) => {handleOffsetInputsChange(e, "sunset")}} placeholder={t("max 60 min")} />
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "end", width: "130px" }}>
                <img src={sunriseIcon} width={24} style={{marginBottom: "4px"}} />
                <div>
                  <Text textType="caption" marginBottom="2px" color={supportTextColor} style={{fontSize: "12px"}}>{t("sunriseOffset").toUpperCase()}</Text>
                  <InputField style={{ height: "30px", color: errorSunriseOffsetValue ? "red" : "#A3AED0" }} defaultValue={sunriseOffsetValue} onChange={(e) => {handleOffsetInputsChange(e, "sunrise")}} placeholder={t("max 60 min")} />
                </div>
              </div>
            </div>
            {errorSunsetOffsetValue ?
                <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px", alignSelf: "flex-end" }}>{t("errorSunsetOffsetValue")}</Text>
              :
                <></>
            }
            {errorSunriseOffsetValue ?
                <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px", alignSelf: "flex-end" }}>{t("errorSunriseOffsetValue")}</Text>
              :
                <></>
            }
          </div>
        :
          <></>
        }
      </div>
      <div style={{ marginTop: "20px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text textType="caption" fontWeight="500" marginBottom="0px" color={supportTextColor} style={{ alignSelf: "end", fontSize: "14px", marginRight: "11px" }}>{totalPoints}/7 {t("times")}</Text>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px" }}>{t("dimmingConfigurationSideMenu")}</Text>
              <Text textType="caption" fontWeight="400" marginBottom="0px" style={{fontSize: "12px"}}>{t("dimmingConfigurationBoxes")}</Text>
            </div>
            <button
              style={{ display: "flex", alignItems: "center", backgroundColor: totalPoints === 7 ? "#e3e1e1" : "#ffffff", color: mainTextColor, fontSize: "12px", paddingRight: "14px",
                paddingLeft: "5px", border: `1px solid ${mainTextColor}`, borderRadius: "64px", cursor: totalPoints === 7 ? "not-allowed" : "pointer"
              }}
              onClick={() => {dispatch(changeBackgroundStrategiesActionCreator()); setErrorInvalidData(false); setErrorExistingPoint(false)}}
            >
              <img src={plus} height={26} style={{ marginRight: "2px" }} />
              <Text textType="caption" fontWeight="500" marginBottom="0px" style={{fontSize: "12px"}}>{t("addTime")}</Text>
            </button>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px", marginBottom: "25px" }}>
        <div style={{ display: "flex", gap: "31px" }}>
          {itemData.datasets[0].data?.map((point: any, index: number) => {
            return (
              <div key={index} style={{ display: "flex", flexDirection: "column", width: "118px", gap: "4px", marginLeft: index === itemData.datasets[0].data.length - 1 ? "auto" : "0" }}>
                <div style= {{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  {index === 0 ?
                    <Text fontWeight="500" color={mainTextColor} style={{marginBottom: "0px", fontSize: "14px"}}>{t("Start").toUpperCase()}</Text>
                  : index === itemData.datasets[0].data.length - 1 ?
                      <Text fontWeight="500" color={mainTextColor} style={{marginBottom: "0px", fontSize: "14px"}}>{t("end").toUpperCase()}</Text>
                    :
                      index === 1 ?
                        <Text fontWeight="500" color={mainTextColor} style={{marginBottom: "0px", fontSize: "14px"}}>{t("time2").toUpperCase()}</Text>
                      :
                        index === 2 ?
                          <Text fontWeight="500" color={mainTextColor} style={{marginBottom: "0px", fontSize: "14px"}}>{t("time3").toUpperCase()}</Text>
                        :
                          index === 3 ?
                            <Text fontWeight="500" color={mainTextColor} style={{marginBottom: "0px", fontSize: "14px"}}>{t("time4").toUpperCase()}</Text>
                          :
                            index === 4 ?
                              <Text fontWeight="500" color={mainTextColor} style={{marginBottom: "0px", fontSize: "14px"}}>{t("time5").toUpperCase()}</Text>
                            :
                              index === 5 ?
                                <Text fontWeight="500" color={mainTextColor} style={{marginBottom: "0px", fontSize: "14px"}}>{t("time6").toUpperCase()}</Text>
                              :
                                <></>
                  }
                  { index === itemData.datasets[0].data.length - 1 ? 
                      astroDim ?
                        <></>
                      :
                        <img src={editIcon} height={8} style={{ marginRight: "2px", opacity: 0.5, cursor: "pointer" }} onClick={() => {dispatch(saveIndexActionCreator({ index: index }));
                          setErrorInvalidData(false); setErrorExistingPoint(false); dispatch(changeBackgroundStrategiesActionCreator()); setSelectedCard(index); formatTimeforList(index)}}
                        />
                    :
                      <img src={editIcon} height={8} style={{ marginRight: "2px", opacity: 0.5, cursor: "pointer" }} onClick={() => {dispatch(saveIndexActionCreator({ index: index }));
                        setErrorInvalidData(false); setErrorExistingPoint(false); dispatch(changeBackgroundStrategiesActionCreator()); setSelectedCard(index); formatTimeforList(index)}}
                      />
                  }
                  { index !== 0 && index !== itemData.datasets[0].data.length - 1 ? 
                      <img src={deleteIcon} height={8} style={{ marginLeft: "-26px", opacity: 0.5, cursor: "pointer" }} onClick={async() => {deleteData(index)}} />
                    :
                      <></>
                  }         
                </div>
                <div style= {{ display: "flex", border: "1px solid #ddd", borderRadius: 5, justifyContent: "center", height: "30px", marginBottom: "1px",
                  boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
                >
                  {astroDim ?
                    index === 0 ?
                      <Text style={{ fontSize: "18px", marginTop: "2px", color: supportTextColor }}>{t("Sunset").toUpperCase()}</Text>
                    : index === itemData.labels.length - 1 ?
                        <Text style={{ fontSize: "18px", marginTop: "2px", color: supportTextColor }}>{t("Sunrise").toUpperCase()}</Text>    
                      :
                        <Text style={{ fontSize: "18px", marginTop: "2px", color: supportTextColor }}>{itemData.labels[index]}</Text>
                  :
                    <Text style={{ fontSize: "18px", marginTop: "2px", color: supportTextColor }}>{itemData.labels[index]}</Text>
                  }
                </div>
                <div style= {{ display: "flex", border: "1px solid #ddd", borderRadius: 5, justifyContent: "center", height: "30px",
                  boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
                >
                  {index === itemData.datasets[0].data.length - 1 && astroDim ?
                      <Text style={{ fontSize: "18px", marginTop: "2px", color: supportTextColor }}>{t("OFF")}</Text>
                    :
                      <Text style={{ fontSize: "18px", marginTop: "2px", color: supportTextColor }}>{itemData.datasets[0].data[index]}%</Text>
                  }
                </div>
              </div>
            )
          })}
          {background ? 
            <div style={{ background: "#FFFFFF", zIndex: 6, position: "absolute", left: 440, top: 223, width: "220px", height: selectedCard !== itemData.datasets[0].data.length - 1 ?
              selectedCard === 0 && astroDim ? "201px" : "290px" : "201px", borderRadius: "0.5rem", padding: "20px", paddingTop: "16px", paddingBottom: "16px" }}
            >
              {selectedCard === 0 || selectedCard ?
                selectedCard === 0 ?
                  <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("modify").toUpperCase()} {t("Start").toUpperCase()}</Text>
                : selectedCard === itemData.datasets[0].data.length - 1 ?
                    <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("modify").toUpperCase()} {t("end").toUpperCase()}</Text>
                  :
                    selectedCard === 1 ?
                      <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("modify").toUpperCase()} {t("time2").toUpperCase()}</Text>
                    :
                      selectedCard === 2 ?
                        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("modify").toUpperCase()} {t("time3").toUpperCase()}</Text>
                      :
                        selectedCard === 3 ?
                          <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("modify").toUpperCase()} {t("time4").toUpperCase()}</Text>
                        :
                          selectedCard === 4 ?
                            <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("modify").toUpperCase()} {t("time5").toUpperCase()}</Text>
                          :
                            selectedCard === 5 ?
                              <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("modify").toUpperCase()} {t("time6").toUpperCase()}</Text>
                            :
                              <></>
                
              :
                <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("addTime").toUpperCase()}</Text>
              }
              {astroDim && selectedCard === 0 ?
                <></>
              :
                <div style={{ display: "flex", marginTop: "20px", marginBottom: "16px", alignItems: "center", justifyContent: "center", gap: "10px"}}>
                  <div>
                    <Input
                      style={{border: "none", borderRadius: 0, width: "76px", height: "60px", background: hexToRgb(supportTextColor), textAlign: "center", fontSize: "30px",
                        color: accentButtonsColor, fontWeight: "500"
                      }}
                      defaultValue={selectedCard === 0 || selectedCard ? hourFromList : ""}
                      onChange={(e) => {handleHoursInputsChange(e, "hour")}}
                    />
                    <Text fontWeight="400" style={{marginBottom: "4px", fontSize: "12px"}}>{t("Hour")}</Text>
                  </div>
                  <div style={{fontWeight: 700, fontSize: "30px", marginBottom: "20px"}}>:</div>
                  <div>
                    <Input
                      style={{border: "none", borderRadius: 0, width: "76px", height: "60px", /* background: "rgba(0, 0, 0, 0.1)", */ background: hexToRgb(supportTextColor),
                        textAlign: "center", fontSize: "30px", color: accentButtonsColor, fontWeight: "500"
                      }}
                      defaultValue={selectedCard === 0 || selectedCard ? minuteFromList : ""}
                      onChange={(e) => {handleHoursInputsChange(e, "minute")}}
                    />
                    <Text fontWeight="400" style={{marginBottom: "4px", fontSize: "12px"}}>{t("Minute")}</Text>
                  </div>
                </div>
              }
              {selectedCard !== itemData.datasets[0].data.length - 1 ?
                <>
                  <Input
                    style={{border: "none", borderRadius: 0, width: "180px", height: "60px", background: hexToRgb(supportTextColor), textAlign: "center", fontSize: "30px",
                      color: accentButtonsColor, fontWeight: "500", marginTop: selectedCard === 0 && astroDim ? "20px" : "0px"
                    }}
                    defaultValue={itemData.datasets[0].data[selectedCard]}
                    onChange={(e) => {handleDimmingInputChange(e)}}
                  />
                  <Text fontWeight="400" style={{marginBottom: "4px", fontSize: "12px"}}>{t("Dimming")} %</Text>
                </>
              :
                <></>
              }
              <div style={{ display: "flex", marginTop: "20px", alignItems: "center", justifyContent: "space-between" }}>
                <img src={clock} height={20} style={{ opacity: 0.7, cursor: "pointer" }} />
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <Text marginBottom="0px" fontWeight="500" style={{ color: supportTextColor, cursor: "pointer", fontSize: "16px" }} onClick={() => {resetStates()}}>{t("Cancel").toUpperCase()}</Text>
                  <NextButton accentButtonsColor={accentButtonsColor} style={{ padding: "3px 16px", height: "30px", cursor: "pointer", boxShadow: "none" }} onClick={() => {
                    selectedCard === 0 || selectedCard ?
                      handleEditData(indexRedux)
                    :
                      addData(hourFromInput, minuteFromInput)
                   
                    resetStates()}}
                  >
                    {t("ok")}
                  </NextButton>
                </div>
              </div>
            </div>
          :
            <></>
        }
        </div>
      </div>
      <div style={{ display: "flex", flexDirection:"column", marginTop: "0px", alignItems: "center", justifyContent: "center"}}>
        {
          totalPoints === 7 ?
            <Text textType="caption" fontWeight="500" marginBottom="5px" style={{ color: "red", fontSize: "14px" }}>{t("errorStrategyLimit")}</Text>
          :
            <></>
        }
        {
          errorInvalidData ?
            <Text textType="caption" fontWeight="500" marginBottom="5px" style={{ color: "red", fontSize: "14px" }}>{t("errorBlankInput")}</Text>
          :
            <></>
        }
        {
          errorExistingPoint ?
            <Text textType="caption" fontWeight="500" marginBottom="5px" style={{ color: "red", fontSize: "14px" }}>{t("errorExistingPoint")}</Text>
          :
            <></>
        }
      </div>
      <NextButton style={{ padding: "3px 16px", paddingLeft: "6px", height: "39px", boxShadow: "none", background: item.color, border: "none", fontSize: "12px",
        cursor: "auto", marginBottom: "12px" }}
      >
        <EnergyIcon height={16} style={{ color: "white", marginTop: "-2px", marginLeft: "0px", paddingLeft: "0px" }} />
        {savingValue || item.saving}{t("percentageEnergySaved").toUpperCase()}
      </NextButton>
      <div style={{ overflow: "auto" }}>
        <Line height={90} options={options} data={itemData} />
      </div>
    </div>
  )
}

export default EditChartStandard;
