import styled from "styled-components";

interface DimmingPopUpProps {
  buttonsColor: string;
}

export const DimmingPopUpStyled = styled.section<DimmingPopUpProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 13px;
  width: 380px;
  height: fit-content;
  border-radius: 14px;
  background-color: #F3F4F6;
  //#d2d4d9
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);

  .popup {
    &__button-container {
      display: flex;
      align-self: flex-end;

      &__icon {
        font-size: 1.2rem;
        color: #686b6f;
        cursor: pointer;
      }
    }

    &__body-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 14px 18px;
      padding-top: 2px;

      &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 13px;
        width: 310px;
        height: fit-content;
        border-radius: 10px;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

        &__title {
          display: flex;
          justify-content: space-between;
          width: 286px;
          margin-bottom: 10px;

          &--icon {
            font-size: 1.2rem;
          }  
        }

        &__progress-bar {
          width: 280px;
          height: 12px;
          margin-top: 6px;
          margin-bottom: 6px;
          -webkit-appearance: none;
          border-radius: 10px;
          position: relative;
          cursor: pointer;

          ::-webkit-slider-thumb {
            -webkit-appearance: none;
            width: 20px;
            height: 20px;
            background-color: ${props => props.buttonsColor};
            cursor: pointer;
            border-radius: 50%;
            box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
              0px 1px 3px rgba(0, 0, 0, 0.3);
          }
        }

        &__percentages {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 286px;
          height: 14px;
          margin-bottom: 4px;
        }
      }

      &__button {
        cursor: pointer;
        display: flex;        
        margin-top: 20px;
        border-radius: 8px;
        align-items: center;
        padding: 8px 18px 8px 18px;
        gap: 8px;
        height: 40px;
        font-weight: 600;
        background: ${props => props.buttonsColor};
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
          0px 1px 3px rgba(0, 0, 0, 0.3);
      }
    }
  }
`;
