import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import authService from "../../services/authService";
import InputForm from "../../components/Input/Input";
import { mailRegex } from "../../utils/regex";
import { ButtonLogin, Container, ImageLogo, LoginForm, LoginOverlay, LoginOverlayWhite } from "../Login/LoginStyled";
import { ButtonLoading } from "../../styles/shared-styles/buttons-sizes";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import logo from "../../assets/new_icons/smartec-logo.svg";
import mail from "../../assets/new_icons/mail.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/new_icons/Arrow left dynamic.svg";

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);

  const navigate = useNavigate();
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  const handleReset = async () => {
    setErrorUpdate(false);
    setIsLoading(true);

    const res = await authService.smartecForgotPassword(email);

    if (res?.data) {
      setReset(true);
      setIsLoading(false);
    } else {
      setErrorUpdate(true);
      setIsLoading(false);
    }
  };

  const checkEnter = (e: any) => {
    if (e.charCode === 13) {
      handleReset();
    }
  };

  return (
    <>
      <Container className="login_container">
        <div className="row">
          <div className="col-6">
            <LoginOverlay className="overlay" />
          </div>
          <div className="col-6">
            <Container>
              <LoginOverlayWhite className="overlay" />
              <LoginForm padding="large" className="card" borderRadius="large">
                <div className="col-12" style={{ display: "flex", marginTop: "10px", marginBottom: "10px" }}>
                  <ArrowLeftIcon
                    height={16} style={{ color: "#525252", position: "absolute", top: reset ? "-35.2vh" : "-27.7vh", bottom: "10vh", left: "-21vh", width: "50px", height: "50px", cursor: "pointer",
                      borderRadius: "50%", background: "white", padding: "17px", boxShadow:"0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                  <ImageLogo style={{ top: reset ? "-35vh" : "-27.5vh" }} src={logo} />
                </div>
                {!reset ?
                  <>
                    <div className="col-12 text-left">
                      <Text textType="title" fontWeight="700" marginBottom="10px">
                        {t("Forgot password?")}
                      </Text>
                      <Text fontWeight="400" marginBottom="28px" color="#475569">
                        {t("forgotPasswordText")}
                      </Text>
                      <InputForm preffix_icon={mail} inputType="email" placeholder={t("Email")} onChange={(e) => {setError(false); setEmail(e.target.value)}}
                        onKeyPress={(e) => {mailRegex.test(email) ? checkEnter(e) : setError(true)}}
                      />
                    </div>
                    {error ?
                      <TextSpan style={{ marginTop: "-6px", width: "400px" }} color="red">{t("Incorrect email or password")}</TextSpan>
                    : 
                      errorUpdate ?
                        <TextSpan style={{ marginTop: "-6px", width: "400px" }} color="red">{t("errorUpdating")}</TextSpan>
                      :
                        <></>
                    }
                    <div className="col-12 text-left">
                      {isLoading ? (
                        <ButtonLoading
                          style={{ marginTop: error || errorUpdate ? "11px" : "26px", background: buttonsColor, color: accentTextColor }}
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {t("loading...")}
                        </ButtonLoading>
                      ) : (
                        <ButtonLogin
                          style={{ marginTop: error || errorUpdate ? "11px" : "26px", background: buttonsColor, color: accentTextColor }}
                          buttonType="primary"
                          onClick={() => {mailRegex.test(email) ? handleReset() : setError(true)}}
                        >
                          {t("resetInstructions")}
                        </ButtonLogin>
                      )}
                    </div>
                  </>
                :
                  <div className="col-12 text-left">
                    <Text textType="title" fontWeight="700" marginBottom="10px">
                      {t("checkEmailTitle")}
                    </Text>
                    <Text fontWeight="400" marginBottom="28px" color="#475569">
                      {t("checkEmailSubtitle")}
                    </Text>
                  </div>
                }
              </LoginForm>
            </Container>
          </div>
        </div>
      </Container>
    </>
  );
};
