import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { turnBackgroundStrategiesFalseActionCreator } from "../../redux/slices/graphicsSlice/graphicsSlice";
import { resetStrategyActionCreator } from "../../redux/slices/strategiesSlice/strategiesSlice";
import { setStrategiesVisibleActionCreator } from "../../redux/slices/uiSlice/uiSlice";
import { useStrategiesService } from "../../services/strategiesService";
import { NewStrategyModal } from "./NewStrategyModal/NewStrategyModal";
import { EditStrategyModal } from "./EditStrategyModal/EditStrategyModal";
import StrategiesChart from "./StrategiesChart";
import StrategiesChartSolar from "./StrategiesChartSolar";
import { NavLink } from "../GroupsPage/GroupsDetailsCard/GroupsDetailsCardStyled";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import lightBulb from "../../assets/new_icons/luminaire detailCard.svg";
import luminairePower from "../../assets/new_icons/lum_power.svg";
import maxConsumIcon from "../../assets/new_icons/max_consumption.svg";
import photocell from "../../assets/new_icons/photocell_volt.svg";
import plusCircle from "../../assets/new_icons/plusCircle.svg";
import plusIcon from "../../assets/new_icons/plus.svg";
import sun from "../../assets/new_icons/sun.svg";
import sunrise from "../../assets/new_icons/Sunrise - Filled.svg";
import sunset from "../../assets/new_icons/Sunset - Filled.svg";
import { ReactComponent as EnergyIcon } from "../../assets/new_icons/Energy.svg";
import { ReactComponent as PlusIcon } from "../../assets/new_icons/plus.svg";

interface StrategiesPageCard {}

export const StrategiesPage = ({}: StrategiesPageCard) => {
  const dispatch = useAppDispatch();
  const strategies = useAppSelector((state) => state.strategies.strategies);
  const nodes = useAppSelector((state) => state.devices.nodes);
  const selectedOrgId: any = useAppSelector(
    (state) => state.devices.selectedOrganizationId
  );
  const selectedServiceId: any = useAppSelector(
    (state) => state.devices.selectedServiceId
  );
  const { getStrategies, deleteStrategy, getSolarStrategies, deleteSolarStrategy } = useStrategiesService();
  const userRole = useAppSelector((state) => state.user.role_name);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const [viewStandard, setViewStandard] = useState(true);
  const [viewSolar, setViewSolar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showActions, setShowActions] = useState({
    index: "",
    state: false,
  });
  const [showEditModal, setShowEditModal] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [editChart, setEditChart] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [saveId, setSaveId]: any = useState();
  const [saveName, setSaveName]: any = useState("");
  const [name, setName]: any = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [firstRender, setFirstRender] = useState(true);
  const smartManagementOptions = ["OFF", "HIGH", "MEDIUM", "LOW", "AUTOMATIC"];

  const handleShowModal = () => {
    dispatch(resetStrategyActionCreator());
    dispatch(turnBackgroundStrategiesFalseActionCreator());
    setShowModal(!showModal);
  };

  const handleClick = (event:any) => {
    setPosition({ top: event.clientY - 64, left: event.clientX - 270 });
  };

  const handleShowActions = (index: string, event: any) => {
    event.preventDefault();
    //event.nativeEvent.stopImmediatePropagation();
    setShowActions({ index: index, state: !showActions.state });
    setEditDetails(false);
    setEditChart(false);
  };

  const handleShowEditModal = () => {
    dispatch(resetStrategyActionCreator());
    dispatch(turnBackgroundStrategiesFalseActionCreator());
    setShowEditModal(!showEditModal);
    setShowActions({ index: showActions.index, state: false });
  };

  // REVISAR ESTO
  /* window.addEventListener('popstate', () => {
    if (nodeId) {
      navigate(`/luminaire/${nodeId}`, {state: {viewState}})
    } else {
      navigate("/dashboard")
    }
  }); */
  /* if (strategies) {

    dispatch(resetStrategyActionCreator());
  } */

  const deleteAndGetStrategy = async (id: number) => {
    if (viewStandard) {
      await deleteStrategy(id);
    } else {
      await deleteSolarStrategy(id);
    }
    resetStates();
    await getStrategies(selectedOrgId, selectedServiceId);
  };

  const resetStates = () => {
    setShowDeletePopup(false);
    setShowActions({ index: "", state: false });
    setSaveId();
    setSaveName("");
    setName("");
    setErrorMessage(false);
  };

  const handleStrategyName = (e: any) => {
    const newName = e.target.value;
    setName(newName);
  };

  useEffect(() => {
    //creo que no es necesario el firstRender, comprobar post feria con una instalación full solar con una build en produ (oyo y lagos)
    if (firstRender && nodes.length > 0 && nodes.every((node: any) => node.solar_id !== null)) {
      setViewSolar(true);
      setViewStandard(false);
      setFirstRender(false);
    }
  }, [nodes]);

  useEffect(() => {
    dispatch(setStrategiesVisibleActionCreator());
    
    if (viewStandard) {
      getStrategies(selectedOrgId, selectedServiceId);
    } else if (viewSolar) {
      getSolarStrategies(selectedOrgId, selectedServiceId);
    }

    const handleClickOutsideShowAction = (event: MouseEvent) => {
      if (
        showActions &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setShowActions({ index: "", state: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutsideShowAction);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideShowAction);
    };    
  }, [showModal, showEditModal, dispatch, selectedOrgId, selectedServiceId, showActions, viewStandard, viewSolar]);

  return (
    <>
      <div className="container-fluid" style={{ pointerEvents: showDeletePopup ? "none" : "auto", marginTop: "40px", paddingRight: "0px", paddingLeft: "0px", position: "relative", zIndex: 2 }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent:"space-between", marginBottom: "24px", marginRight: "8px" }}>
          <div>
            <Text textType="title" fontWeight="700" marginBottom="8px">
              {t("Your lighting strategies")}
            </Text>
            <Text textType="">{t("Short description about what is lighting strategies and how can help with the energy savings")}</Text>
          </div>
          {userRole === "owner" || userRole === "admin" ?
            <div className="col-2" style={{ textAlign: "right" }}>
              <Button buttonType={strategies.length === 20 ? "secondary" : "primary"}
                onClick={() => {strategies.length !== 20 ? handleShowModal() : <></>}}
                style={{ background: strategies.length === 20 ? buttonsColor+60 : buttonsColor, padding: "8px",
                  width: "180px", cursor: strategies.length === 20 ? "not-allowed" : "pointer"
                }}
              >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "4px" }}>
                  <PlusIcon height={30} width={30} style={{ color: accentTextColor, marginRight: "4px" }} />
                  <TextSpan color= {accentTextColor} fontWeight="500" style={{ marginRight: "6px" }}>{t("New strategy")}</TextSpan>
                </div>
              </Button>
            </div>
          :
            <></>
          }
          {strategies.length === 20 ? (
            <Text style={{ marginTop: "6px" }}>{t("You have reached the strategies' limit")}</Text>
          ) : (
            <></>
          )}
          {showModal && (
            <NewStrategyModal showModal={showModal} setShowModal={setShowModal} />
          )}
        </div>
        <div className="nav nav-pills" style={{ marginBottom: "26px" }}>
          {nodes.filter((node:any) => node.solar_id === null).length > 0 &&
            <div className="nav-item">
              <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${viewStandard ? "active" : ""}`} id="mainInfo-tab" data-bs-toggle="tab" type="button"
                onClick={() => {setViewStandard(true); setViewSolar(false)}}
              >
                {t("Standard")}
              </NavLink>
            </div>
          }
          {nodes.filter((node:any) => node.solar_id !== null).length > 0 &&
            <div className="nav-item">
              <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${viewSolar ? "active" : ""}`} id="consumptions-tab" data-bs-toggle="tab" type="button"
                onClick={() => {setViewStandard(false); setViewSolar(true)}}
              >
                {t("Solar")}
              </NavLink>
            </div>
          }
        </div>
        <div style={{ display: "flex", flexDirection: "row", gap: "21px", flexWrap: "wrap", marginBottom: "20px" }}> 
          {strategies && strategies?.map((item: any, index: any) => (
            <div 
            key={item.id}
              className="card"
              style={{ padding: "24px", border: "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)",
                borderRadius: "16px", width: "24%" }}
            >
              <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", marginBottom: "8px", position: "relative" }}>
                {showActions.state && showActions.index === index && (
                  <article
                    style={{
                      position: "absolute",
                      top: 20,
                      right: 0,
                      width: "190px",
                      maxHeight: "336px",
                      overflowY: "auto",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      background: "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
                      boxShadow: "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
                      borderRadius: "4px",
                      zIndex: 3
                      }}
                    >
                    <>
                      <style>
                        {`
                        .strategy-span:hover {
                          background-color: #1c1b1f11;
                        }
                      `}
                      </style>
                      <span
                        className="strategy-span"
                        onClick={() => {handleShowEditModal(); setEditDetails(true)}}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          isolation: "isolate",
                          paddingLeft: "15px",
                          width: "100%",
                          minHeight: "56px",
                          cursor: userRole === "maintenance" || userRole === "observer" ? "not-allowed" : "pointer",
                          textAlign: "left",
                        }}
                      >
                        {t("Edit information")}
                      </span>
                      <span
                        className="strategy-span"
                        //si al final hacemos el edit en dos pasos, en este onClick meter si es viewStandard y si es que sí, que haga todo esto y si es que no,
                        //mandarlo a otro componente? tendré que crear otro usestate
                        onClick={() => {handleShowEditModal(); setEditChart(true)}}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          isolation: "isolate",
                          paddingLeft: "15px",
                          width: "100%",
                          minHeight: "56px",
                          cursor: userRole === "maintenance" || userRole === "observer" ? "not-allowed" : "pointer",
                          textAlign: "left",
                        }}
                      >
                        {t("Edit graphic")}
                      </span>
                      <span
                        className="strategy-span"
                        onClick={() => {setShowDeletePopup(true); setSaveId(strategies[showActions.index].id); setSaveName(strategies[showActions.index].strgy_name)}}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          isolation: "isolate",
                          paddingLeft: "15px",
                          width: "100%",
                          minHeight: "56px",
                          cursor: userRole === "maintenance" || userRole === "observer" ? "not-allowed" : "pointer",
                          textAlign: "left",
                        }}
                      >
                        {t("Delete")}
                      </span>
                    </>
                  </article>
                )}
                <div className="col-6" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: "6px",
                  marginTop: "-1px" }}
                >
                  <img
                    src={viewStandard ? lightBulb : sun}
                    height={16}
                    style={{ marginLeft: "-4px", marginBottom: "4px", opacity: 0.5 }}
                  />
                  <TextSpan color="#A3AED0" textType="caption">
                    {item.value || 0}
                  </TextSpan>
                </div>
                {userRole !== "maintenance" && userRole !== "observer" ?
                  <div style={{ textAlign: "right", marginTop: "-2px", display: "flex", gap: "10px" }}>
                    <img
                      src={plusCircle}
                      height={20}
                      alt=""
                      className="icon-class"
                      onClick={(event) => { handleClick(event); handleShowActions(index, event); }}
                      style={{ cursor: "pointer", opacity: 0.5 }}
                    />
                  </div>
                :
                  <></>
                }
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "4px", gap: "10px" }}>
                <div style={{width: "50%"}}>
                  <Text fontWeight="700" marginBottom="2px">{item.strgy_name}</Text>
                </div>
                {viewStandard ?
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", width: "50%" }}>
                    {item.time1 === "98:98" ?
                      <>
                        <div style={{ display: "flex", gap: "5px" }}> 
                          <Text marginBottom="0px" fontWeight="600">{t("astroDim")}: </Text>
                          <Text marginBottom="0px">{t("yes")}</Text>
                        </div>
                        <div style={{ display: "flex", gap: "16px" }}>
                        <img src={sunset} alt="" height={16} />
                          <div style={{ display: "flex", gap: "5px" }}>
                            <img src={sunrise} alt="" height={16} />
                            <Text marginBottom="0px">{item.sunrise_off} {t("min")}</Text>
                          </div>
                        </div>
                      </>
                    :
                      <div style={{ display: "flex", gap: "5px" }}> 
                        <Text marginBottom="0px" fontWeight="600">{t("astroDim")}: </Text>
                        <Text marginBottom="0px">{t("no")}</Text>
                      </div>
                    }
                  </div>
                :
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", width: "50%" }}>
                    <div style={{ display: "flex", gap: "5px" }}> 
                      <Text marginBottom="0px" fontWeight="600">{t("smartManagement")}: </Text>
                      <Text marginBottom="0px">{smartManagementOptions[item.smart_management_id]}</Text>
                    </div>
                    <div style={{ display: "flex", gap: "16px" }}>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <img src={luminairePower} alt="" height={16} style={{ marginTop: "2px" }} />
                        <Text marginBottom="0px">{item.lum_power_w} W</Text>
                      </div>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <img src={photocell} alt="" height={16} style={{ marginTop: "2px" }} />
                        <Text marginBottom="0px">{item.photocell_v} V</Text>
                      </div>
                    </div>      
                  </div>
                }
              </div>
              <Text color="#A3AED0" textType="caption" marginBottom="2px">{item.description}</Text>
              {viewStandard ?
                <StrategiesChart
                  chartData={[item.dim1, item.dim2, item.dim3, item.dim4, item.dim5, item.dim6, item.dim7, item.time1, item.time2, item.time3, item.time4, item.time5, item.time6, item.time7]}
                  color={item.color}
                />
              :
                <StrategiesChartSolar
                  chartData={[item.dim1, item.dim2, item.dim3, item.dim4, item.dim5, item.time1, item.time2, item.time3, item.time4, item.time5]}
                  color={item.color}
                />
              }
              {viewStandard ?
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "8px", height: "20px" }}>
                  <EnergyIcon height={16} style={{ color: item.color }} />
                  <TextSpan color={item.color} fontWeight="500">{item.saving}{t("percentageEnergySaved")}</TextSpan>
                </div>
              :
              /* style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }} */
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "8px" }}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <img src={maxConsumIcon} alt="" height={16} style={{ marginBottom: "1px" }} />
                    <Text marginBottom="-2px" style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{t("maxConsumShort").toUpperCase()}</Text>
                    <Text marginBottom="-2px" style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{item.max_consum_wh} W</Text>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <Text marginBottom="-2px" style={{ color: item.color, fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{t("battConsumShort").toUpperCase()}</Text>
                    <Text marginBottom="-2px" style={{ color: item.color, fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{item.total_consum_wh} W</Text>
                  </div>
                </div>
              }
            </div>  
          ))} 
          {showEditModal && (
            <EditStrategyModal
              tempItem={strategies[showActions.index]}
              showEditModal={showEditModal}
              handleShowEditModal={handleShowEditModal}
              editDetails={editDetails}
              editChart={editChart}
              viewStandard={viewStandard}
            />
          )}     
        </div>
      </div>
      {showDeletePopup && (
        <div style={{ display: "flex", position: "fixed", alignItems: "center", justifyContent: "center", top: "50%", height: "100vh",
          left: "50%", zIndex: 4, transform: "translate(-50%, -50%)" }}
        >
          <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)",
            backdropFilter: "blur(2px)", borderRadius: "14px", padding: "30px", display: "flex", flexDirection: "column",
            alignItems: "center", justifyContent: "center" }}
          >
            <Text>{t("Do you want to delete this strategy? Please write the name of the strategy to delete it")}</Text>
            <input
              placeholder={"Strategy's name"}
              value={name}
              onChange={handleStrategyName}
            />
            {errorMessage ? (
              <Text
                marginBottom="0px"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "6px",
                }}
              >
                {t("The strategy's name is not correct")}
              </Text>
            ) : (
              <></>
            )}
            <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
              <button
                style={{
                  padding: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  borderRadius: "14px",
                }}
                onClick={() => {
                  if (name === saveName) {
                    deleteAndGetStrategy(saveId);
                  } else {
                    setErrorMessage(true);
                  }
                }}
              >
                {t("Accept")}
              </button>
              <button
                style={{
                  padding: "8px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  borderRadius: "14px",
                }}
                onClick={() => {
                  resetStates();
                }}
              >
                {t("Cancel")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
