import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Groups } from "../../../types/interfaces";

const initialState: Groups = {
  groupsArr: [],
  name: "",
  selectedGroup: [],
  selectedDevices: [],
  removedDevices: [],
  /* //este se usa en Events, mirar si es lo mismo o cambiar el nombre de este param
  selectedGroup: [], */
};

const groupsSlice = createSlice({
  name: "groups",
  initialState: initialState,
  reducers: {
    getGroups: (previousData, action: PayloadAction<Groups>) => ({
      ...previousData,
      groupsArr: action.payload.groupsArr
    }),

    resetGroups: (previousData) => ({
      ...previousData,
      groupsArr: [],
      name: "",
      selectedDevices: [],
      selectedDevicesList: [],
      removedDevices: []
    }),

    setSelectGroup: (previousData, action: PayloadAction<Groups>) => ({
      ...previousData,
      selectedGroup: action.payload.selectedGroup
    }),

    saveGroupInfo: (previousData, action: PayloadAction<Groups>) => ({
      ...previousData,
      name: action.payload.name
    }),

    resetGroupInfo: (previousData) => ({
      ...previousData,
      name: "",
      selectedDevices: [],
      removedDevices: [],
    }),

    setSelectedDevicesList: (previousData, action: PayloadAction<Groups>) => ({
      ...previousData,
      selectedDevices: action.payload.selectedDevices
    }),

    setRemovedDevicesList: (previousData, action: PayloadAction<Groups>) => ({
      ...previousData,
      removedDevices: action.payload.removedDevices
    }),

    /* //este es para events
    addGroupIds: (previousData, action: PayloadAction<Groups>) => ({
      ...previousData,
      selectedGroup: action.payload.selectedGroup
    }), */

    updateGroupsArrDelete: (previousState, action: PayloadAction<Groups>) => {
      const updatedGroups = previousState.groupsArr.filter((group:any) =>
        group.groupId !== action.payload
      );
    
      return {
        ...previousState,
        groupsArr: updatedGroups,
      };
    },

    updateGroupsArrNameOrDevices: (previousState, action: PayloadAction<Groups> | any) => {
      const updatedGroups = previousState.groupsArr.map((group: any) => 
        group.groupId === action.payload[0].groupId ? { ...group, groupName: action.payload[0].groupName, nodes: action.payload[0].nodes } : group
      );

      /* const updatedGroups = previousState.groupsArr.map((group: any) => {
        console.log("Procesando grupo:", group.groupId, action.payload[0].groupId);
        if (group.groupId === action.payload[0].groupId) {
          console.log("Grupo a modificar:", group);
          return { ...group, groupName: action.payload[0].groupName };
        }
        return group;
      }); */
      const updatedSelectedGroup = previousState.selectedGroup.groupId === action.payload[0].groupId
      ? { ...previousState.selectedGroup, groupName: action.payload[0].groupName, nodes: action.payload[0].nodes }
      : previousState.selectedGroup;

      return {
        ...previousState,
        groupsArr: updatedGroups,
        selectedGroup: updatedSelectedGroup
      };
    },
  },
});

export const groupsReducer = groupsSlice.reducer;

export const {
  getGroups: getGroupsActionCreator,
  resetGroups: resetGroupsActionCreator,
  setSelectGroup: setSelectGroupActionCreator,
  saveGroupInfo: saveGroupInfoActionCreator,
  resetGroupInfo: resetGroupInfoActionCreator,
  setSelectedDevicesList: setSelectedDevicesListActionCreator,
  setRemovedDevicesList: setRemovedDevicesListActionCreator,
  //addGroupIds: addGroupIdsActionCreator,
  updateGroupsArrDelete: updateGroupsArrDeleteActionCreator,
  updateGroupsArrNameOrDevices: updateGroupsArrNameOrDevicesActionCreator
} = groupsSlice.actions;
