import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { NodeLumimairePage } from "../../../types/interfaces";
import { useLightingService } from "../../../services/lightingService";
import { loadNodesInAreaActionCreator } from "../../../redux/slices/devices/devicesSlice";
import { filteredNodesInAreaActionCreator } from "../../../redux/slices/devices/filteredDevicesSlice";
import { setNodesInAreaActionCreator, setIsFullViewActionCreator, setFirstRenderMapToTrueActionCreator, setIsOpenedDetailActionCreator, setIsOpenedGroupActionCreator, turnRgbFalseActionCreator } from "../../../redux/slices/uiSlice/uiSlice";
import styled from "styled-components";
import { IconStyled } from "../LuminairePageStyled";
import { CenteredTitle } from "../../StrategiesPage/NewStrategyModal/NewStrategyModalStyled";
import { InputField, CardContainer, ButtonStrategy } from "../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Button } from "../../../styles/shared-styles/buttons-sizes";
import { TextSpan, Text } from "../../../styles/shared-styles/text-sizes";
import Alarm from "../../../assets/new_icons/Alarm.svg";
import connectedIcon from "../../../assets/new_icons/Connected.svg";
import disconnectedIcon from "../../../assets/new_icons/Node disconnected.svg";
import gateway from "../../../assets/new_icons/Gateway nodePage.svg";
import hops from "../../../assets/new_icons/Hops.svg";
import luminaireIcon from "../../../assets/new_icons/Luminaires.svg";
import neighbours from "../../../assets/new_icons/Neighbours.svg";
import nodeType from "../../../assets/new_icons/Type.svg";
import OFF from "../../../assets/new_icons/OFF.svg";
import ON from "../../../assets/new_icons/ON.svg";
import rgb from "../../../assets/new_icons/ellipse_blue.svg";
import rssi from "../../../assets/new_icons/rssi.svg";
import solar from "../../../assets/new_icons/Solar.svg";
import travelTime from "../../../assets/new_icons/Traveltime.svg";
import { ReactComponent as PlusIcon } from "../../../assets/new_icons/plus.svg";
import { ReactComponent as ID } from "../../../assets/new_icons/ID.svg";
import { turnAllFalseActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { errorToInitialStateActionCreator } from "../../../redux/slices/mqttSlice/mqttSlice";

interface ViewCameraProps {
  selectedLamp?: NodeLumimairePage | any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 500px;
  }

  .modal-content {
    padding-bottom: 0px;
    background: white;
    border: 0px;
  }
`;
  
export const ViewCamera = ({ selectedLamp }: ViewCameraProps) => {
  const [showActions, setShowActions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [nodeName, setNodeName] = useState(selectedLamp?.node_name);

  const { sendCommandToLuminaire, changeNodeName, getNodeInfo, getAllNodes, updateLatLong } = useLightingService();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const organizations = useAppSelector((state) => state.devices.organizations);
  const services = useAppSelector((state) => state.devices.services);
  const nodesInArea = useAppSelector((state) => state.ui.nodesInArea);
  const userRole = useAppSelector((state) => state.user.role_name);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const { t } = useTranslation();

  const handleShowModal = () => {
    setShowModal(!showModal);
    setNodeName(selectedLamp?.node_name);
  };

  const handleNodeName = async () => {
    const res = await changeNodeName(nodeName, selectedLamp?.node_id, selectedOrgId, selectedServiceId)
    if (res) {
      await getNodeInfo(selectedLamp?.node_id);
      getAllNodes(selectedOrgId, selectedServiceId, organizations, services);
      if (nodesInArea) {
        dispatch(setNodesInAreaActionCreator(false))
        dispatch(loadNodesInAreaActionCreator([]));
        dispatch(filteredNodesInAreaActionCreator([]));
      }
      handleShowModal();
    }
  };

  const handleDelete = async () => {
    await updateLatLong(selectedLamp?.node_id, selectedOrgId, selectedServiceId, null, null);
    dispatch(setFirstRenderMapToTrueActionCreator());
    getAllNodes(selectedOrgId, selectedServiceId, organizations, services);
    dispatch(setIsFullViewActionCreator());
    navigate("/dashboard");  
    setShowActions(false);
  };

  useEffect(() => {
    setNodeName(selectedLamp?.node_name);
  }, [selectedLamp?.node_name]);

  useEffect(() => {
    const handleClickOutsideShowAction = (event: MouseEvent) => {
      if (
        showActions &&
        event.target instanceof Element &&
        !event.target.closest("article") &&
        !event.target.classList.contains("icon-class")
      ) {
        setShowActions(!showActions);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideShowAction);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideShowAction);
    };    
  }, [showActions]);

  return (
    <div className="row" style={{ marginBottom: "16px", alignItems: "center" }}>
      <div className="col-1">
        <div
          className="profile-picture-container"
          style={{
            borderRadius: "50%",
            width: "96px",
            height: "96px",
            overflow: "hidden",
          }}
        >
          <img
            src={luminaireIcon}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="Profile Picture"
            className="img-fluid"
          />
          {/* <span
            style={{
              position: "absolute",
              top: 200,
              left: 190,
              padding: "8px 16px",
              backgroundColor: "#fff",
              borderRadius: "30px",
              cursor: "pointer",
              boxShadow:
                "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
            }}
          >
            <img
              src={videoIcon}
              style={{
                height: "16px",
              }}
            />
          </span> */}
        </div>
      </div>
      <div
        className="col-9"
        style={{ paddingLeft: "10px", alignItems: "center" }}
      >
        <div style={{ marginBottom: "10px" }}>
          <TextSpan textType="title" fontWeight="700">
            {selectedLamp?.node_name}
          </TextSpan>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "10px",
            marginBottom: "5px",
            marginLeft: "3px",
            gap: "20px",
          }}
        >
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled
              src={
                selectedLamp?.online === "1" ? connectedIcon : disconnectedIcon
              }
              height={18}
            />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.online === "1"
                ? t("Connected")
                : t("Disconnected")}
            </TextSpan>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled
              src={
                selectedLamp?.on === "1" && selectedLamp?.online === "1"
                  ? ON
                  : OFF
              }
              height={18}
              style={{ marginBottom: "4px" }}
            />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.on === "1" && selectedLamp?.online === "1"
                ? t("ON")
                : t("OFF")}
            </TextSpan>
          </div>
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled src={nodeType} height={18} />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.device_type}
            </TextSpan>
          </div>
          {selectedLamp?.solar_id ?
            <div className="d-flex align-items-center" style={{ gap: "5px" }}>
              <IconStyled src={solar} height={18} />
              <TextSpan style={{ marginRight: "32px" }}>{t("Solar")}</TextSpan>
            </div>
          : selectedLamp?.rgb ?
              <div className="d-flex align-items-center" style={{ gap: "5px" }}>
                <IconStyled src={rgb} height={18} />
                <TextSpan style={{ marginRight: "32px" }}>{t("RGB")}</TextSpan>
              </div>
            :
              <></>
          }
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <ID width={18} height={18} style={{ color: "#525252", marginRight: "4px" }} />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.node_id}
            </TextSpan>
          </div>
          {userRole === "owner" || userRole === "admin" ?
            <>
              <div className="d-flex align-items-center" style={{ gap: "5px" }}>
                <IconStyled src={gateway} height={18} />
                <TextSpan style={{ marginRight: "32px" }}>
                  {selectedLamp?.gateway_id}
                </TextSpan>
              </div>
              <div className="d-flex align-items-center" style={{ gap: "5px" }}>
                <IconStyled src={travelTime} height={18} />
                <TextSpan style={{ marginRight: "32px" }}>
                  {selectedLamp?.traveltime || "-"}
                </TextSpan>
              </div>
              <div className="d-flex align-items-center" style={{ gap: "5px" }}>
                <IconStyled src={neighbours} height={18} />
                <TextSpan style={{ marginRight: "32px" }}>
                  {selectedLamp?.num_neighbours || "-"}
                </TextSpan>
              </div>
              <div className="d-flex align-items-center" style={{ gap: "5px" }}>
                <IconStyled src={rssi} height={18} />
                <TextSpan style={{ marginRight: "32px" }}>
                  {selectedLamp?.rssi || "-"}
                </TextSpan>
              </div>
              <div className="d-flex align-items-center" style={{ gap: "5px" }}>
                <IconStyled src={hops} height={18} />
                <TextSpan style={{ marginRight: "32px" }}>
                  {selectedLamp?.hops || "-"}
                </TextSpan>
              </div>
            </>
          :
            <></>
          }
          <div className="d-flex align-items-center" style={{ gap: "5px" }}>
            <IconStyled src={Alarm} height={18} />
            <TextSpan style={{ marginRight: "32px" }}>
              {selectedLamp?.alarm}
            </TextSpan>
          </div>
        </div>
      </div>
      <div className="col-2" style={{ textAlign: "right" }}>
        {/* TODO: Add the + Actions when the page will be designed and linkend */}
        <Button buttonType="primary" style={{ width: "110px", background: buttonsColor,  paddingLeft: "0px", paddingRight: "0px" }} onClick={() => {setShowActions(!showActions)}}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "4px" }}>
            <PlusIcon height={30} width={30} style={{ color: accentTextColor }} />
            <TextSpan
              color={accentTextColor}
              fontWeight="500"
              style={{ marginRight: "6px" }}
            >
              {t("Actions")}
            </TextSpan>
          </div>
        </Button>
        {showActions &&
          <article
            style={{
              position: "absolute",
              top: 175,
              right: 112,
              width: "190px",
              maxHeight: "336px",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              background: "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
              boxShadow: "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
              borderRadius: "4px",
              zIndex: 3
              }}
          >
            {selectedLamp?.solar_id &&
              <>
                <style>
                  {`
                  .actions-span:hover {
                    background-color: #1c1b1f11;
                  }
                `}
                </style>
                <span
                  className="actions-span"
                  onClick={() => {
                    sendCommandToLuminaire([selectedLamp], 109, 0, 0);
                    setShowActions(false);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    isolation: "isolate",
                    paddingLeft: "15px",
                    width: "100%",
                    minHeight: "56px",
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                >
                  {t("updateData")}
                </span>
              </>
            }
            <>
              <style>
                {`
                .actions-span:hover {
                  background-color: #1c1b1f11;
                }
              `}
              </style>
              <span
                className="actions-span"
                onClick={() => {
                  setShowModal(true);
                  setShowActions(false);
                }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  isolation: "isolate",
                  paddingLeft: "15px",
                  width: "100%",
                  minHeight: "56px",
                  cursor: "pointer",
                  textAlign: "left",
                }}
              >
                {t("changeName")}
              </span>          
            </>   
            {selectedLamp.manufacturer_name !== "shuncom" && selectedLamp.online === "1" ?
              <>
                <style>
                  {`
                  .actions-span:hover {
                    background-color: #1c1b1f11;
                  }
                `}
                </style>
                <span
                  className="actions-span"
                  onClick={() => {
                    sendCommandToLuminaire([selectedLamp], 27, 0, 0);
                    setShowActions(false);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    isolation: "isolate",
                    paddingLeft: "15px",
                    width: "100%",
                    minHeight: "56px",
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                >
                  {t("resetNode")}
                </span>          
              </>
            :
              <></>
            }
            <>
              <style>
                {`
                .actions-span:hover {
                  background-color: #1c1b1f11;
                }
              `}
              </style>
              <span
                className="actions-span"
                onClick={() => {handleDelete()}}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  isolation: "isolate",
                  paddingLeft: "15px",
                  width: "100%",
                  minHeight: "56px",
                  cursor: "pointer",
                  textAlign: "left",
                }}
              >
                {t("deleteNode")}
              </span>          
            </>
          </article>
        }
        {showModal ?
          <CustomModal show={showModal} onHide={handleShowModal} centered >
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                padding: "20px 20px 0 20px",
                background: "white",
                zIndex: 2
              }}
            ></Modal.Header>
            <Modal.Body style={{ background: "white", marginBottom: "10px", paddingTop: "0px", paddingLeft: "30px", paddingRight: "30px" }}>
              <div>
                <CenteredTitle>
                  <Text fontWeight="600" color={mainTextColor} style={{fontSize: "22px"}}>{t("editNodeName")}</Text>
                </CenteredTitle>
                <div className="col-12">
                  <CardContainer>
                    <div className="col-12" style={{ marginBottom: "32px", textAlign: "left" }}>
                      <Text textType="caption" color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("name")}</Text>
                      <InputField value={nodeName} onChange={(e) => setNodeName(e.target.value)} maxLength={100} placeholder={t("name")} />
                    </div>
                  </CardContainer>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ButtonStrategy buttonType="primary" style={{background: nodeName ? buttonsColor : buttonsColor+60, color: accentTextColor, width: "300px",
                  cursor: nodeName ? "pointer" : "not-allowed", padding: "8px 32px", lineHeight: "none", marginBottom: "8px", marginLeft: "0px"
                  }}
                  onClick={() => {handleNodeName()}}
                >
                  {t("Send")}
                </ButtonStrategy>
              </div>
            </Modal.Body>
          </CustomModal>
        :
          <></>
        }
      </div>
    </div>
  );
};
