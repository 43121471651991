import styled from "styled-components";
enum Props {
  small = "small",
  medium = "medium",
  large = "large",
}

export const Paddings = styled.div`
  padding: ${(props: { padding?: string }) =>
    props.padding === Props.small
      ? "8px"
      : props.padding === Props.medium
      ? "32px"
      : props.padding === Props.large
      ? "32px"
      : 0};
`;

export const Margins = styled(Paddings)`
  margin-top: ${(props: { marginTop?: string; marginBottom?: string }) =>
    props.marginTop === "small"
      ? "8px"
      : props.marginTop === "medium"
      ? "16px"
      : props.marginTop === "large"
      ? "32px"
      : "0"};

  margin-bottom: ${(props) =>
    props.marginBottom === "small"
      ? "8px"
      : props.marginBottom === "medium"
      ? "16px"
      : props.marginBottom === "large"
      ? "32px"
      : "0"};
`;

export const BorderRadius = styled(Margins)`
  border-radius: ${(props: { borderRadius?: string }) =>
    props.borderRadius === "small"
      ? "4px"
      : props.borderRadius === "medium"
      ? "8px"
      : props.borderRadius === "large"
      ? "16px"
      : "0"};
`;

export const BoxSizes = styled(BorderRadius)``;
