import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { store } from "../../../redux/store";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { addRemoveDeviceAction, updateGroupsArrAction } from "../../../redux/actions/groupsActions/groupsActions";
import { setRemovedDevicesListActionCreator } from "../../../redux/slices/groupsSlice/groupsSlice";
import { useGroupsService } from "../../../services/groupsService";
import { GroupsLeafletMap } from "../GroupsMap/GroupsLeafletMap";
import styled from "styled-components";
import { CenteredTitle } from "../../StrategiesPage/NewStrategyModal/NewStrategyModalStyled";
import { CardContainer, ButtonStrategy } from "../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../styles/shared-styles/text-sizes";

interface EditGroupDevicesProps {
  showEditGroupDevices: boolean;
  setShowEditGroupDevices: any;
}

export const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1090px;
  }
  .modal-content {
    padding-bottom: 0px;
    background: white;
    border: 0px;
  }
`;

export const EditGroupDevices = ({ showEditGroupDevices, setShowEditGroupDevices }: EditGroupDevicesProps) => {
  const [showPreventPopup, setShowPreventPopup] = useState(false);

  const { addRemoveDevice, getGroupInfo } = useGroupsService();
  const dispatch = useAppDispatch();
  const { selectedGroup, selectedDevices, removedDevices } = useAppSelector((state) => state.groups);
  const { groupId } = selectedGroup;
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const { t } = useTranslation();

  const handlePreventPopup = () => {
    setShowPreventPopup(true);
  };

  const modalAccept = async () => {
    dispatch(setRemovedDevicesListActionCreator({ removedDevices: [] }));
    setShowEditGroupDevices(false);
  };

  const sendInfo = async () => {
    const payload = {
      group_id: groupId,
      add_devices: selectedDevices.map((devices: any) => devices.node_id),
      remove_devices: removedDevices.map((devices: any) => devices.node_id),
    };
    
    const addRemoveDeviceResponse = await addRemoveDevice(payload, selectedOrgId, selectedServiceId);

    if (addRemoveDeviceResponse) {
      store.dispatch(addRemoveDeviceAction(addRemoveDeviceResponse));
      let getGroupResponse = await getGroupInfo(selectedGroup.groupId, selectedOrgId, selectedServiceId);
      dispatch(setRemovedDevicesListActionCreator({ removedDevices: [] }));
      if (getGroupResponse) {
        store.dispatch(updateGroupsArrAction("name", getGroupResponse))
        setShowEditGroupDevices(false);
      }
    } else {
      setShowEditGroupDevices(true);
      console.log("error in postGroup api");
      return false;
    }
  };

  useEffect(() => {
  }, [showEditGroupDevices]);

  return (
    <CustomModal show={showEditGroupDevices} onHide={handlePreventPopup} centered>
      {showPreventPopup ? (
        <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "14px", padding: "30px",
          display: "flex", flexDirection: "column", position: "absolute", alignItems: "center", justifyContent: "center", top: "362px", left: "407px", zIndex: 30 }}
        >
          <Text>
            {t("Do you want to close this form? All the information will be lost")}
          </Text>
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button style={{ padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={modalAccept}>
              {t("Accept")}
            </button>
            <button style={{ padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowPreventPopup(false)}}>
              {t("Cancel")}
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
      <Modal.Header
        closeButton
        style={{
          borderBottom: "none",
          padding: "20px 20px 0 20px",
          background: "white",
          zIndex: 2
        }}
      ></Modal.Header>
      <Modal.Body
        style={{ background: "white", marginBottom: "10px", paddingTop: "0px", paddingLeft: "30px", paddingRight: "30px" }}
      >
        <CenteredTitle>
          <Text fontWeight="600" color={mainTextColor} style={{ fontSize: "22px", marginBottom: "30px", }}>{t("editGroupDevices")}</Text>
        </CenteredTitle>
        <CardContainer style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ margin: "0 27px", height: "600px", width: "95%" }} className="position-relative mb-3">
            <GroupsLeafletMap showDevice={true} interactiveMap={true} showIcon={true} fromGroupsPage={false} />
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
            <ButtonStrategy buttonType="primary" style={{background: buttonsColor, color: accentTextColor, width: "300px", cursor: "pointer", padding: "8px 32px", lineHeight: "none",
              marginBottom: "8px", marginLeft: "0px", marginTop: "20px" }} onClick={() => {sendInfo()}}
            >
              {t("Send")}
            </ButtonStrategy>
          </div>
        </CardContainer>
      </Modal.Body>
    </CustomModal>
  )
};
