import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { saveStrategyInfoActionCreator } from "../../../redux/slices/strategiesSlice/strategiesSlice";
import { useStrategiesService } from "../../../services/strategiesService";
import EditChartSolar from "./EditChartSolar";
import EditChartStandard from "./EditChartStandard";
import { LayoutGreyShadow } from "./EditStrategyModalStyled";
import { CenteredTitle } from "../NewStrategyModal/NewStrategyModalStyled";
import { InputField, TextAreaField, CardContainer, NextButton, ButtonStrategy } from "../StrategiesSteps/StrategiesStepsStyled";
import styled from "styled-components";
import { Text } from "../../../styles/shared-styles/text-sizes";
import ellipseBlack from "../../../assets/new_icons/ellipse_black.svg";
import ellipseBlue from "../../../assets/new_icons/ellipse_blue.svg";
import ellipseGreen from "../../../assets/new_icons/ellipse_green.svg";
import ellipseRed from "../../../assets/new_icons/ellipse_red.svg";
import ellipseYellow from "../../../assets/new_icons/ellipse_yellow.svg";

interface NewStrategyModalProps {
  tempItem: any;
  showEditModal: boolean;
  handleShowEditModal: () => void;
  editDetails: boolean;
  editChart: boolean;
  viewStandard: boolean;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1090px;
  }

  .modal-content {
    padding-bottom: 0px;
    background: white;
    border: 0px;
  }
`;

export const EditStrategyModal = ({ tempItem, showEditModal, handleShowEditModal, editDetails, editChart, viewStandard }: NewStrategyModalProps) => {
  const strategyInfo = useAppSelector((state) => state.strategies);
  const { getStrategies, editStrategyText, editStrategyChart, editSolarStrategyText, editSolarStrategyChart } = useStrategiesService();
  const dispatch = useAppDispatch();
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const background = useAppSelector((state) => state.graphics.backgroundStrategies);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);

  const [item, setItem] = useState(tempItem);
  const [nameInput, setNameInput] = useState(item?.strgy_name);
  const [selectedOptionInput, setSelectedOptionInput]: any = useState();
  const [selectedOption, setSelectedOption]: any = useState();
  const [descriptionInput, setDescriptionInput] = useState(item?.description);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorSunsetOffsetValue, setErrorSunsetOffsetValue] = useState(false);
  const [errorSunriseOffsetValue, setErrorSunriseOffsetValue] = useState(false);
  const { t } = useTranslation();

  const options = [
    { value: "#56C568", label: t("Green"), icon: ellipseGreen },
    { value: "#D51A52", label: t("Red"), icon: ellipseRed },
    { value: "#FBBC05", label: t("Yellow"), icon: ellipseYellow },
    { value: "#1C1B1F", label: t("Black"), icon: ellipseBlack },
    { value: "#87CBE8", label: t("Blue"), icon: ellipseBlue },
  ];

  const formatOptionLabel = ({ value, label, icon }: any) => (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <img src={icon} alt="" style={{ marginRight: "8px", marginTop: "1px" }} />
      <Text textType="caption" color="#959595" marginBottom="0px" style={{ fontSize: "14px" }}>{label}</Text>
    </div>
  );

  const changeDetails = async () => {
    if (nameInput !== "" && selectedOptionInput !== undefined) {
      if (!selectedOption) {
        if (viewStandard) {
          await editStrategyText(nameInput, selectedOptionInput.value, descriptionInput, item?.id);
        } else {
          await editSolarStrategyText(nameInput, selectedOptionInput.value, descriptionInput, item?.id);
        }
      } else {
        if (viewStandard) {
          await editStrategyText(nameInput, selectedOption.value, descriptionInput, item?.index);      
        } else {
          await editSolarStrategyText(nameInput, selectedOption.value, descriptionInput, item?.id);
        }
      }
      await getStrategies(selectedOrgId, selectedServiceId);
      handleShowEditModal();
    } else {
      setErrorMessage(true);
    }
  };

  const changeChart = async () => {
    if (viewStandard) {
      await editStrategyChart(strategyInfo.labels, strategyInfo.data, item?.id);
    } else {
      await editSolarStrategyChart(strategyInfo.labels, strategyInfo.data, item?.id);
    }
    handleShowEditModal();
  };

  useEffect(() => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === item?.color) {
        setSelectedOptionInput(options[i]);
        break;
      }
    }
    dispatch(
      saveStrategyInfoActionCreator({
        name: nameInput,
        color: selectedOption,
        description: descriptionInput,
      })
    );
  }, [dispatch, nameInput, selectedOption, descriptionInput]);

  return (
    <CustomModal show={showEditModal} onHide={handleShowEditModal} centered>
      <Modal.Header
        closeButton
        style={{
          borderBottom: "none",
          padding: "20px 20px 0 20px",
          background: "white",
          zIndex: 2
        }}
      ></Modal.Header>
      <Modal.Body style={{ background: "white", marginBottom: "10px", paddingTop: "0px", paddingLeft: "30px", paddingRight: "30px" }}>
        {
          editDetails && (
            <div>
              <CenteredTitle>
                <Text fontWeight="600" color={mainTextColor} style={{fontSize: "22px"}}>{t("editStrategy")}</Text>
              </CenteredTitle>
              <div className="col-12">
                <CardContainer>
                  <div className="row" style={{ marginBottom: "16px", textAlign: "left" }}>
                    <div className="col-6">
                      <Text textType="caption" color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("name")}</Text>
                      <InputField value={nameInput} onChange={(e) => setNameInput(e.target.value)} placeholder={t("name")} />
                    </div>
                    <div className="col-6">
                      <Text textType="caption" color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("color")}</Text>
                      <Select
                        value={selectedOption ? selectedOption : selectedOptionInput}
                        onChange={setSelectedOption}
                        options={options}
                        formatOptionLabel={formatOptionLabel}
                        placeholder={t("selectColor")}
                        styles={{
                          control: (baseStyles, state) => { 
                            state.theme.colors.primary = 'none';
                            return {
                              ...baseStyles,
                              cursor: "pointer",
                              backgroundColor: "white",
                              color: "#212529",
                              maxHeight: "36px",
                              minHeight: "36px",
                              fontSize: "12px",
                              fontWeight: "400",
                              borderRadius: "0.375rem",
                              border: state.isFocused ? "2px solid" : "1px solid #ced4da",
                              '&:hover': {
                                borderColor: 'none',
                              }
                            }
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12" style={{ marginBottom: errorMessage ? "11px" : "32px", textAlign: "left" }}>
                    <Text textType="caption" color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("description")}</Text>
                    <TextAreaField value={descriptionInput} maxLength={250} onChange={(e) => setDescriptionInput(e.target.value)} placeholder={t("description")} />
                  </div>
                  {
                    errorMessage ?
                      <Text marginBottom="0px" style={{display: "flex",  justifyContent: "center"}}>{t("errorBlankInput")}</Text>  
                    :
                      <></>
                  }
                </CardContainer>
              </div>
            </div>
          )
        }
        {
          editChart && (
          <>
              {background ? (
                <LayoutGreyShadow style={{ height: viewStandard ? "106%" : "107.7%"}} />
            ) : (
              <></>
            )}
              <CenteredTitle>
                <Text fontWeight="600" style={{fontSize: "22px"}}>{t("editStrategy")}</Text>
              </CenteredTitle>
              <div className="col-12">
                <CardContainer>
                  <div className="row" style={{ marginBottom: "16px", textAlign: "left" }}>
                    {viewStandard ?
                      <EditChartStandard item={item} setErrorSunsetOffsetValue={setErrorSunsetOffsetValue} errorSunsetOffsetValue={errorSunsetOffsetValue}
                        setErrorSunriseOffsetValue={setErrorSunriseOffsetValue} errorSunriseOffsetValue={errorSunriseOffsetValue}
                      />
                    :
                      <EditChartSolar item={item} />
                  }
                  </div>
                  
                </CardContainer>
                {errorMessage ? (
                  <Text marginBottom="0px" style={{ display: "flex", justifyContent: "center" }}>
                    {t("errorBlankInput")}
                  </Text>
                ) : (
                  <></>
                  )}
              </div>
            {/* <div style={{ display: "flex", justifyContent: "center" }}>
                <NextButton onClick={() => {changeDetails()}}>{t(txt.send)}</NextButton>
              </div> */}
          </>
        )}   
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ButtonStrategy buttonType="primary" style={{background: errorSunsetOffsetValue || errorSunriseOffsetValue ? buttonsColor+60 : buttonsColor, color: accentTextColor, width: "300px",
              cursor: errorSunsetOffsetValue || errorSunriseOffsetValue ? "not-allowed" : "pointer", padding: "8px 32px", lineHeight: "none", marginBottom: editDetails ? "8px" : "2px",
              marginLeft: "0px"
            }}
            onClick={() => {
              if (!errorSunsetOffsetValue && !errorSunriseOffsetValue) {
                editDetails ? changeDetails() : changeChart()
              }
            }}
          >
            {t("Send")}
          </ButtonStrategy>
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
