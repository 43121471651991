import React from "react";

const StarIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6484 6.29391L11.8084 5.87391L9.91836 1.42391C9.57836 0.613906 8.41836 0.613906 8.07836 1.42391L6.18836 5.88391L1.35836 6.29391C0.478364 6.36391 0.118364 7.46391 0.788364 8.04391L4.45836 11.2239L3.35836 15.9439C3.15836 16.8039 4.08836 17.4839 4.84836 17.0239L8.99836 14.5239L13.1484 17.0339C13.9084 17.4939 14.8384 16.8139 14.6384 15.9539L13.5384 11.2239L17.2084 8.04391C17.8784 7.46391 17.5284 6.36391 16.6484 6.29391ZM8.99836 12.6539L5.23836 14.9239L6.23836 10.6439L2.91836 7.76391L7.29836 7.38391L8.99836 3.35391L10.7084 7.39391L15.0884 7.77391L11.7684 10.6539L12.7684 14.9339L8.99836 12.6539Z"
        fill="#475569"
      />
    </svg>
  );
};

export default StarIcon;