import { useState } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import { store } from "../../redux/store";
import { updateUserInfoAction } from "../../redux/actions/profileActions/profileActions";
import { useProfileService } from "../../services/profileService";
import { phoneNumberRegex, alphabeticRegex } from "../../utils/regex";
import { CardStyled } from "./ProfilePageStyled";
import { ButtonOutlined } from "../../styles/shared-styles/buttons-sizes";
import { Text } from "../../styles/shared-styles/text-sizes";

interface UserProps {
  first_name: string;
  last_name: string;
  phone_number: string;
}

interface PersonalInfoProps {
  user: UserProps;
}

export const PersonalInfo = ({ user }: PersonalInfoProps) => {
  const [firstName, setFirstName] = useState(user?.first_name);
  const [lastName, setLastName] = useState(user?.last_name);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone_number || "");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorFirstName, setErrorFirstName] = useState<boolean>(false);
  const [errorLastName, setErrorLastName] = useState<boolean>(false);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState<boolean>(false);
  const [errorExistingData, setErrorExistingData] = useState<boolean>(false);
  const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { updatePersonalInfo } = useProfileService();
  const userFirstName = useAppSelector((state) => state.user.first_name);
  const userLastName = useAppSelector((state) => state.user.last_name);
  const userPhoneNumber = useAppSelector((state) => state.user.phone_number);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const handleUpdatePersonalInformation = async () => {
    setIsLoading(true);
    setErrorPhoneNumber(false);
    setErrorUpdate(false);
    setSuccess(false);

    try {
      const token:any = Cookies.get("token");
      const response = await updatePersonalInfo(token, firstName, lastName, phoneNumber);
      setIsLoading(false);

      if (response) {
        store.dispatch(updateUserInfoAction(firstName, lastName, phoneNumber));
        setSuccess(true);
      } else {
        setErrorUpdate(true);
      }

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      setIsLoading(false);
      setErrorUpdate(true);
    }
  };

  return (
    <>
      <CardStyled>
        <Text textType="subTitle" fontWeight="700" color={`background: ${mainTextColor}`}>
          {t("personalInformation")}
        </Text>
        <div style={{ display: "flex", marginBottom: "16px" }}>
          <div style={{ width: "50%", paddingRight: "12px" }}>
            <Text marginBottom="8px">{t("firstName")}*</Text>
            <input
              className={`form-control ${!firstName ? "error_border" : ""}`}
              value={firstName}
              onChange={(e) => {setFirstName(e.target.value); setErrorFirstName(false); setErrorExistingData(false); setErrorUpdate(false)}}
            />
          </div>
          <div style={{ width: "50%", paddingLeft: "12px" }}>
            <Text marginBottom="8px">{t("lastName")}</Text>
            <input
              className={`form-control`}
              value={lastName}
              onChange={(e) => {setLastName(e.target.value); setErrorLastName(false); setErrorExistingData(false); setErrorUpdate(false)}}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
          <div style={{ width: "50%", paddingRight: "12px" }}>
            <Text marginBottom="8px">{t("phoneNumber")}</Text>
            <input
              className="form-control"
              value={phoneNumber}
              onChange={(e) => {setPhoneNumber(e.target.value); setErrorPhoneNumber(false); setErrorExistingData(false); setErrorUpdate(false)}}
            />
          </div>
          <div style={{ alignSelf: "flex-end" }}>
            {isLoading ? (
              <ButtonOutlined type="button" disabled style={{ border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }}>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                  {t("loading...")}
              </ButtonOutlined>
            ) : (
              <ButtonOutlined style={{ border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }} onClick={() => {
                if (phoneNumber && !(/^\s/.test(phoneNumber))) {
                  if (!phoneNumberRegex.test(phoneNumber)) {   
                    setErrorPhoneNumber(true)
                    return
                  }
                } else if (phoneNumber && (/^\s/.test(phoneNumber))) {
                  setErrorUpdate(true)
                  return
                }

                if (!(/^\s/.test(firstName)) && !(/^\s/.test(lastName)) && firstName !== "") {
                  if (alphabeticRegex.test(firstName) && alphabeticRegex.test(lastName)) {
                    if (firstName === userFirstName && lastName === userLastName && phoneNumber === userPhoneNumber) {
                      setErrorExistingData(true);
                    } else {
                      handleUpdatePersonalInformation();
                    }
                  } else if (!alphabeticRegex.test(firstName)) {
                    setErrorFirstName(true);
                  } else if (!alphabeticRegex.test(lastName)) {
                    setErrorLastName(true);
                  }
                } else {
                  setErrorUpdate(true)
                }
              }}>
                {t("updateName")}
              </ButtonOutlined>
            )}
          </div>
        </div>
        <div style={{ height: '10px', textAlign: "right", alignSelf: "end" }}>
          {(/^\s/.test(firstName) || /^\s/.test(lastName) || firstName === "") ? (
            <Text marginBottom="0px" color="red">{t("errorBlankInput")}</Text>
          ) :
            errorFirstName ? (
              <Text marginBottom="0px" color="red">{t("errorFirstName")}</Text>
          ) :
              errorLastName ? (
                <Text marginBottom="0px" color="red">{t("errorLastName")}</Text>
              ) :
                errorPhoneNumber ? (
                  <Text marginBottom="0px" color="red">{t("errorPhoneNumber")}</Text>
              ) :
                  errorExistingData ? (
                    <Text marginBottom="0px" color="red">{t("errorExistingData")}</Text>
                  ) :
                    errorUpdate ? (
                      <Text marginBottom="0px" color="red">{t("errorUpdating")}</Text>
                    ) :
                      success ? (
                        <Text marginBottom="0px" color="green">{t("updated")}</Text>
                      ) : (
                        <></>
          )}
        </div>
      </CardStyled>
    </>
  );
};
