import { useState } from "react";
import { pdf } from '@react-pdf/renderer';
import { useTranslation } from "react-i18next";
import { store } from "../../../../redux/store";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { resetPdfInfoActionCreator } from "../../../../redux/slices/reportsSlice/pdfReportSlice";
import { useReportsService } from "../../../../services/reportsService";
import { AlarmsChartPdf } from "../../../ReportsPage/PdfCreation/AlarmsChartPdf";
import { GeneralInfoChartPdf } from "../../../ReportsPage/PdfCreation/GeneralInfoChartPdf";
import { UsersChartPdf } from "../../../ReportsPage/PdfCreation/UsersChartPdf";
import { PdfReact } from "../../../ReportsPage/PdfCreation/PdfReact";
import { CardContainer, ButtonStrategy } from "../ReportsStepsStyled";
import { OrganizationCard } from "../../../../components/UsersPage/UsersPageStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";
import rgb from "../../../../assets/new_icons/RGB.svg";
import standard from "../../../../assets/new_icons/OFF.svg";
import sun from "../../../../assets/new_icons/sun.svg";

interface ReviewProps {
  errorMessage?: boolean;
  setErrorMessage?: any;
  onHide?: any;
}

export const Review = ({ errorMessage, setErrorMessage, onHide }: ReviewProps) => {
  const [loading, setLoading] = useState(false);
  const [hideCharts, setHideCharts] = useState(false);

  const { getGeneralReports, getStandardReports, postReportInfo } = useReportsService();
  const dispatch = useAppDispatch();
  const values = store.getState();
  const startDate = useAppSelector((state) => state.pdfReport.startDate);
  const endDate = useAppSelector((state) => state.pdfReport.endDate);
  const lumTypeStandard = useAppSelector((state) => state.pdfReport.lumTypeStandard);
  const lumTypeSolar = useAppSelector((state) => state.pdfReport.lumTypeSolar);
  const lumTypeHybrid = useAppSelector((state) => state.pdfReport.lumTypeHybrid);
  const lumTypeRgb = useAppSelector((state) => state.pdfReport.lumTypeRgb);
  const gateTypeSmartec = useAppSelector((state) => state.pdfReport.gateTypeSmartec);
  const gateTypeShuncom = useAppSelector((state) => state.pdfReport.gateTypeShuncom);
  const deviceSwitch = useAppSelector((state) => state.pdfReport.deviceSwitch);
  const { selectedDevicesStandard, selectedDevicesSolar, selectedDevicesHybrid, selectedDevicesRgb, selectedDevicesSmartec, selectedDevicesShuncom,
    selectedDevicesSwitch } = useAppSelector((state) => state.pdfReport);
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const handleDownloadPdf = async () => {
    setLoading(true);
    
    try {
      await getGeneralReports(selectedOrgId, selectedServiceId);
      await getStandardReports(selectedOrgId, selectedServiceId);

      setHideCharts(true);
      
      const blob:any = await generatePdfBlob();
      const url = URL.createObjectURL(blob);
      // Descargar automáticamente
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'informe.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove(); // Eliminar el enlace después de hacer clic
      setHideCharts(false);
    } catch (error) {
      console.error('Error al hacer las llamadas al backend', error);
    } finally {
      setLoading(false);
    }
  };

  const generatePdfBlob = async () => {
    const delay = (ms:any) => new Promise(resolve => setTimeout(resolve, ms));
    // Introduce un retraso de 5 segundos
    await delay(5000);
    const doc = <PdfReact />; // Tu componente PDF
    const pdfInstance = pdf(doc);
    const blob = await pdfInstance.toBlob(); // Convierte el PDF a Blob
    return blob;
  };

  const manageReportInfo = async () => {
    setErrorMessage(false);
    const result = await postReportInfo(selectedOrgId, selectedServiceId);

    if (!result) {
      setErrorMessage(true)
    } else {
      await handleDownloadPdf();
      dispatch(resetPdfInfoActionCreator());
      onHide();
    }
  };

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column", paddingLeft: "40px" }}>
      {hideCharts ?
        <div style={{ visibility: "hidden", position: 'absolute', width: '1px', height: '1px', overflow: 'hidden' }}>
          <GeneralInfoChartPdf values={values} />
          <AlarmsChartPdf values={values} />
          <UsersChartPdf values={values} />
        </div>
      :
        <></>
      }
      <div style={{ marginBottom: "100px" }}>
        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step3")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("reviewChoices")}</Text>
        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("reviewChoicesSubtitle")}</Text>
        <div style={{ display: "flex", flexDirection: "column", marginTop: "40px", paddingBottom: "10px" }}>
          <Text color={mainTextColor} fontWeight="500" marginBottom="20px">{t("generalDetails")}</Text>   
          <div style={{ display: "flex", gap: "30px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text textType="caption" color={supportTextColor} marginBottom="8px" style={{ fontSize: "12px" }}>{t("from").toUpperCase()}</Text>
              <OrganizationCard style={{ display: "flex", gap: "14px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "6px", alignItems: "center", justifyContent: "center",
                width: "140px" }}
              >
                <Text fontWeight="500">
                  {startDate}
                </Text> 
              </OrganizationCard>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text textType="caption" color={supportTextColor} marginBottom="8px" style={{ fontSize: "12px" }}>{t("to").toUpperCase()}</Text>
              <OrganizationCard style={{ display: "flex", gap: "14px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "6px", alignItems: "center", justifyContent: "center",
                width: "140px" }}
              >
                <Text fontWeight="500">
                  {endDate}
                </Text> 
              </OrganizationCard>
            </div>
          </div>  
        </div>
        <div style={{ display: "flex", flexDirection: "column", marginTop: "30px", paddingBottom: "10px" }}>
          <Text color={mainTextColor} fontWeight="500" marginBottom="20px">{t("devicesSelected")}</Text>
          <div style={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>   
            {lumTypeStandard &&
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text textType="caption" color={supportTextColor} marginBottom="8px" style={{ fontSize: "12px" }}>{t("standardLuminaires").toUpperCase()}</Text>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "center" }}>
                  <img src={standard} width={24} />
                  <OrganizationCard style={{ display: "flex", gap: "14px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "0px", alignItems: "center", justifyContent: "center",
                    width: "100px" }}
                  >
                    <Text fontWeight="500">
                      {selectedDevicesStandard.length}
                    </Text> 
                  </OrganizationCard>
                </div>

              </div>
            }
            {lumTypeSolar &&
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text textType="caption" color={supportTextColor} marginBottom="8px" style={{ fontSize: "12px" }}>{t("solarLuminaires").toUpperCase()}</Text>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "center" }}>
                  <img src={sun} width={24} />
                  <OrganizationCard style={{ display: "flex", gap: "14px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "0px", alignItems: "center", justifyContent: "center",
                    width: "100px" }}
                  >
                    <Text fontWeight="500">
                      {selectedDevicesSolar.length}
                    </Text> 
                  </OrganizationCard>
                </div>

              </div>
            }
            {lumTypeHybrid &&
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text textType="caption" color={supportTextColor} marginBottom="8px" style={{ fontSize: "12px" }}>{t("hybridLuminaires").toUpperCase()}</Text>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "center" }}>
                  <img src={sun} width={24} />
                  <OrganizationCard style={{ display: "flex", gap: "14px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "0px", alignItems: "center", justifyContent: "center",
                    width: "100px" }}
                  >
                    <Text fontWeight="500">
                      {selectedDevicesHybrid.length}
                    </Text> 
                  </OrganizationCard>
                </div>

              </div>
            }
            {lumTypeRgb &&
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text textType="caption" color={supportTextColor} marginBottom="8px" style={{ fontSize: "12px" }}>{t("rgbLuminaires").toUpperCase()}</Text>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "center" }}>
                  <img src={rgb} width={24} />
                  <OrganizationCard style={{ display: "flex", gap: "14px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "0px", alignItems: "center", justifyContent: "center",
                    width: "100px" }}
                  >
                    <Text fontWeight="500">
                      {selectedDevicesRgb.length}
                    </Text> 
                  </OrganizationCard>
                </div>

              </div>
            }
            {gateTypeSmartec &&
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text textType="caption" color={supportTextColor} marginBottom="8px" style={{ fontSize: "12px" }}>{t("smartecGateways").toUpperCase()}</Text>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "center" }}>
                  <img src={sun} width={24} />
                  <OrganizationCard style={{ display: "flex", gap: "14px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "0px", alignItems: "center", justifyContent: "center",
                    width: "100px" }}
                  >
                    <Text fontWeight="500">
                      {selectedDevicesSmartec.length}
                    </Text> 
                  </OrganizationCard>
                </div>

              </div>
            }
            {gateTypeShuncom &&
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text textType="caption" color={supportTextColor} marginBottom="8px" style={{ fontSize: "12px" }}>{t("shuncomGateways").toUpperCase()}</Text>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "center" }}>
                  <img src={sun} width={24} />
                  <OrganizationCard style={{ display: "flex", gap: "14px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "0px", alignItems: "center", justifyContent: "center",
                    width: "100px" }}
                  >
                    <Text fontWeight="500">
                      {selectedDevicesShuncom.length}
                    </Text> 
                  </OrganizationCard>
                </div>

              </div>
            }
            {deviceSwitch &&
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text textType="caption" color={supportTextColor} marginBottom="8px" style={{ fontSize: "12px" }}>{t("switchboards").toUpperCase()}</Text>
                <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center", justifyContent: "center" }}>
                  <img src={sun} width={24} />
                  <OrganizationCard style={{ display: "flex", gap: "14px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "0px", alignItems: "center", justifyContent: "center",
                    width: "100px" }}
                  >
                    <Text fontWeight="500">
                      {selectedDevicesSwitch.length}
                    </Text> 
                  </OrganizationCard>
                </div>

              </div>
            }
          </div>  
        </div>
      </div>
      {errorMessage ?
        <Text marginBottom="0px" style={{display: "flex", justifyContent: "center", color: "red", marginTop: "186px" }}>{t("errorNoDataDevices")}</Text>  
      :
        <></>
      }
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "absolute", bottom: 30, left: "49%" }}>
        {loading ? (
          <ButtonStrategy buttonType="primary" disabled style={{ background: buttonsColor, color: accentTextColor, justifySelf: "end", alignSelf: "center", width: "300px",
            marginTop: errorMessage ? "10px" : "96px", marginLeft: "0px", padding: "8px 32px", lineHeight: "none" }}
          >
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              {t("loading...")}
          </ButtonStrategy>
        ) : (
          <ButtonStrategy buttonType="primary" style={{ background: buttonsColor, color: accentTextColor, justifySelf: "end", alignSelf: "center", width: "300px",
            marginTop: errorMessage ? "10px" : "96px", marginLeft: "0px", padding: "8px 32px", lineHeight: "none" }}
            onClick={() => {manageReportInfo()}}
          >
            {t("create")}
          </ButtonStrategy>
        )}
        <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
          <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
          <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
          <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
        </div>
      </div>
    </CardContainer>
  );
};
