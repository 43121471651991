import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NodeDevice, UiData } from "../../../types/interfaces";
import { getAllpositions } from "../../../utils/auxiliaryFunctions";
import { Language } from "../../../utils/languages/languages";

const initialState: UiData = {
  isLoading: false,
  actualLanguage: "english",
  selectedNodeId: "",
  selectedRGBNode: "",
  newNodeLatLong: "",
  selectedBoundsToFly: {
    northEast: { latitude: 0, longitude: 0 },
    southWest: { latitude: 0, longitude: 0 },
  },
  allowFlyMode: true,
  nodesInArea: false,
  firstRenderMap: true,
  isOpenedDetail: false,
  isOpenedGroup: false,
  isStaticView: false,
  isFullView: true,
  isListView: false,
  isHybridView: false,
  isSideMenuVisible: true,
  isHomeVisible: true,
  isAlarmsVisible: false,
  isGroupsVisible: false,
  isStrategiesVisible: false,
  isEventsVisible: false,
  isReportsVisible: false,
  rgb: false
};

const uiDataSlice = createSlice({
  name: "uiData",
  initialState: initialState,
  reducers: {
    openLoadingModal: (previousUiData: UiData) => ({
      ...previousUiData,
      isLoading: true,
    }),

    closeLoadingModal: (previousUiData: UiData) => ({
      ...previousUiData,
      isLoading: false,
    }),

    setActualLanguage: (previousUiData, action: PayloadAction<Language>) => ({
      ...previousUiData,
      actualLanguage: action.payload,
    }),

    setSelectedNodeId: (previousUiData: UiData, action) => ({
      ...previousUiData,
      selectedNodeId: action.payload,
    }),

    setSelectedRGBNode: (previousUiData: UiData, action) => ({
      ...previousUiData,
      selectedRGBNode: action.payload,
    }),

    setNewNodeLatLong: (previousUiData: UiData, action) => ({
      ...previousUiData,
      newNodeLatLong: action.payload,
    }),

    setBoundsToFly: (
      previousData,
      action: PayloadAction<Array<NodeDevice>> | any
    ) => {
      const positions = getAllpositions(action.payload);

      return {
        ...previousData,
        selectedBoundsToFly: {
          northEast: {
            latitude: positions[positions.length - 1].latitude,
            longitude: positions[positions.length - 1].longitude,
          },
          southWest: {
            latitude: positions[0].latitude,
            longitude: positions[0].longitude,
          },
        },
      };
    },

    setSelectedBoundsToFly: (prevData, action) => ({
      ...prevData,
      selectedBoundsToFly: action.payload,
    }),

    allowFlyMode: (previousUiData, action: PayloadAction<boolean>) => ({
      ...previousUiData,
      allowFlyMode: action.payload,
    }),

    setNodesInArea: (previousUiData, action: PayloadAction<boolean>) => ({
      ...previousUiData,
      nodesInArea: action.payload,
    }),

    setIsOpenedDetail: (previousUiData, action: PayloadAction<boolean>) => ({
      ...previousUiData,
      isOpenedDetail: action.payload,
    }),

    setIsOpenedGroup: (previousUiData, action: PayloadAction<boolean>) => ({
      ...previousUiData,
      isOpenedGroup: action.payload,
    }),

    setIsStaticView: (previousUiData, action: PayloadAction<boolean>) => ({
      ...previousUiData,
      isStaticView: action.payload,
    }),

    setIsFullView: (previousUiData) => ({
      ...previousUiData,
      isFullView: true,
      isListView: false,
      isHybridView: false
    }),

    setIsListView: (previousUiData) => ({
      ...previousUiData,
      isFullView: false,
      isListView: true,
      isHybridView: false
    }),

    setIsHybridView: (previousUiData) => ({
      ...previousUiData,
      isFullView: false,
      isListView: false,
      isHybridView: true
    }),

    setViewsToFalse: (previousUiData) => ({
      ...previousUiData,
      isFullView: false,
      isListView: false,
      isHybridView: false
    }),

    changeSideMenu: (previousUiData) => ({
      ...previousUiData,
      isSideMenuVisible: !previousUiData.isSideMenuVisible
    }),

    setHomeVisible: (previousUiData) => ({
      ...previousUiData,
      isHomeVisible: true,
      isAlarmsVisible: false,
      isGroupsVisible: false,
      isStrategiesVisible: false,
      isEventsVisible: false,
      isReportsVisible: false
    }),

    setAlarmsVisible: (previousUiData) => ({
      ...previousUiData,
      isHomeVisible: false,
      isAlarmsVisible: true,
      isGroupsVisible: false,
      isStrategiesVisible: false,
      isEventsVisible: false,
      isReportsVisible: false
    }),

    setGroupsVisible: (previousUiData) => ({
      ...previousUiData,
      isHomeVisible: false,
      isAlarmsVisible: false,
      isGroupsVisible: true,
      isStrategiesVisible: false,
      isEventsVisible: false,
      isReportsVisible: false
    }),

    setStrategiesVisible: (previousUiData) => ({
      ...previousUiData,
      isHomeVisible: false,
      isAlarmsVisible: false,
      isGroupsVisible: false,
      isStrategiesVisible: true,
      isEventsVisible: false,
      isReportsVisible: false
    }),

    setEventsVisible: (previousUiData) => ({
      ...previousUiData,
      isHomeVisible: false,
      isAlarmsVisible: false,
      isGroupsVisible: false,
      isStrategiesVisible: false,
      isEventsVisible: true,
      isReportsVisible: false
    }),

    setReportsVisible: (previousUiData) => ({
      ...previousUiData,
      isHomeVisible: false,
      isAlarmsVisible: false,
      isGroupsVisible: false,
      isStrategiesVisible: false,
      isEventsVisible: false,
      isReportsVisible: true
    }),

    setLayoutFalse: (previousUiData) => ({
      ...previousUiData,
      isHomeVisible: false,
      isAlarmsVisible: false,
      isGroupsVisible: false,
      isStrategiesVisible: false,
      isEventsVisible: false,
      isReportsVisible: false
    }),

    setFirstRenderMapToTrue: (previousUiData) => ({
      ...previousUiData,
      firstRenderMap: true
    }),

    setFirstRenderMapToFalse: (previousUiData) => ({
      ...previousUiData,
      firstRenderMap: false
    }),

    changeRgb: (previousUiData) => ({
      ...previousUiData,
      rgb: !previousUiData.rgb
    }),

    turnRgbFalse: (previousUiData) => ({
      ...previousUiData,
      rgb: false
    }),
  },
});

export const uiDataReducer = uiDataSlice.reducer;

export const {
  openLoadingModal: openLoadingModalActionCreator,
  closeLoadingModal: closeLoadingModalActionCreator,
  setActualLanguage: setActualLanguageActionCreator,
  setSelectedNodeId: setSelectedNodeIdActionCreator,
  setSelectedRGBNode: setSelectedRGBNodeActionCreator,
  setNewNodeLatLong: setNewNodeLatLongActionCreator,
  setBoundsToFly: setBoundsToFlyActionCreator,
  setSelectedBoundsToFly: setSelectedBoundsToFlyActionCreator,
  allowFlyMode: allowFlyModeActionCreator,
  setNodesInArea: setNodesInAreaActionCreator,
  setIsOpenedDetail: setIsOpenedDetailActionCreator,
  setIsOpenedGroup: setIsOpenedGroupActionCreator,
  setIsStaticView: setIsStaticViewActionCreator,
  setIsFullView: setIsFullViewActionCreator,
  setIsListView: setIsListViewActionCreator,
  setIsHybridView: setIsHybridViewActionCreator,
  setViewsToFalse: setViewsToFalseActionCreator,
  changeSideMenu: changeSideMenuActionCreator,
  setHomeVisible: setHomeVisibleActionCreator,
  setAlarmsVisible: setAlarmsVisibleActionCreator,
  setGroupsVisible: setGroupsVisibleActionCreator,
  setStrategiesVisible: setStrategiesVisibleActionCreator,
  setEventsVisible: setEventsVisibleActionCreator,
  setReportsVisible: setReportsVisibleActionCreator,
  setLayoutFalse: setLayoutFalseActionCreator,
  setFirstRenderMapToTrue: setFirstRenderMapToTrueActionCreator,
  setFirstRenderMapToFalse: setFirstRenderMapToFalseActionCreator,
  changeRgb: changeRgbActionCreator,
  turnRgbFalse: turnRgbFalseActionCreator
} = uiDataSlice.actions;
