import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import dragData from "chartjs-plugin-dragdata";
import { changeBackgroundStrategiesActionCreator, turnBackgroundStrategiesFalseActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { changeStrategyChartActionCreator, saveConsumsActionCreator, saveIndexActionCreator, saveSmartManagementActioncreator, saveSolarParamsActionCreator } from "../../../redux/slices/strategiesSlice/strategiesSlice";
import { hexToRgb } from "../../../utils/auxiliaryFunctions";
import { dimmingRegex, numericRegex } from "../../../utils/regex";
import { Input } from "../../Input/InputStyled";
import { InputField, NextButton } from "../StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../styles/shared-styles/text-sizes";
import clock from "../../../assets/new_icons/Clock.svg";
import deleteIcon from "../../../assets/new_icons/Delete.svg";
import editIcon from "../../../assets/new_icons/Edit.svg";
import plus from "../../../assets/new_icons/plus.svg";
import luminairePower from "../../../assets/new_icons/lum_power.svg";
import maxConsumIcon from "../../../assets/new_icons/max_consumption.svg";
import photocell from "../../../assets/new_icons/photocell_volt.svg";
import smartIcon from "../../../assets/new_icons/smart-management.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  dragData,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface EditChartSolarProps {
  item: any;
}

function EditChartSolar({ item }: EditChartSolarProps) {
  const strategy = useAppSelector((state) => state.strategies);
  const [dimmingsList, setDimmingsList]: any = useState();
  const [hoursList, setHoursList]: any = useState();
  const [selectedCard, setSelectedCard]: any = useState();
  const [hourFromInput, setHourFromInput] = useState("");
  const [dimmingFromInput, setDimmingFromInput] = useState("");
  const [totalTime1, setTotalTime1] = useState(0);
  const [totalTime2, setTotalTime2] = useState(0);
  const [totalTime3, setTotalTime3] = useState(0);
  const [totalTime4, setTotalTime4] = useState(0);
  const [totalTime5, setTotalTime5] = useState(0);
  const [errorInvalidData, setErrorInvalidData] = useState(false);

  const dispatch = useAppDispatch();
  const background = useAppSelector((state) => state.graphics.backgroundStrategies);
  const totalConsumption = useAppSelector((state) => state.strategies.totalConsum);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const { t } = useTranslation();

  const times = [item.time1, item.time2, item.time3, item.time4, item.time5];
  const dimmings = [item.dim1, item.dim2, item.dim3, item.dim4, item.dim5];
  const filteredTimes =  times.filter(time => time !== 0);
  const filteredDimmings = dimmings.slice(0, filteredTimes.length);
  let dimmingsArr:any = [];
  let newIndex:any;

  for (let i = 0; i < filteredTimes.length; i++) {
    const tempArr:any = [null, null, null, null, null, null, null, null, null, null, null, null, null];
    if (filteredTimes[i] != 0 && filteredTimes[i] !== null && filteredTimes[i] !== undefined) {
      if (i === 0) {
        tempArr[i] = filteredDimmings[i];
        tempArr[i + filteredTimes[i]] = filteredDimmings[i];
        newIndex = i + filteredTimes[i];
      } else {
        tempArr[newIndex] = filteredDimmings[i];
        tempArr[newIndex + filteredTimes[i]] = filteredDimmings[i];
        newIndex = newIndex + filteredTimes[i]
      }

      dimmingsArr.push(tempArr);
    } else {
      break;
    }
  }

  const datasets:any = [];
  dimmingsArr.map((list:any) => {
    datasets.push({
      label: t("Solar"),
      data: list,
      borderColor: item.color,
      backgroundColor: item.color,
      borderWidth: 10,
      pointRadius: 5,
      pointBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointBorderColor: item.color,
      fill: false,
      spanGaps: true,
    })
  })

  const [itemData, setItemData] = useState({
    labels: strategy?.labels || ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    datasets: strategy?.data || datasets
  });
  
  const totalPoints = itemData.datasets.length;

  const options:any = {
    scales: {
      y: {
        suggestedMax: 100,
        suggestedMin: 0,
        ticks: {
          stepSize: 25,
          backdropPadding: 10,
        },
      },
      x: {
        title: {
          display: false,
        },
      },
    },
  
    plugins: {
      legend: {
        display: false,
      },
    },
    animation: false,
  };

  const handleHoursInputsChange = (e: React.ChangeEvent<HTMLInputElement> | any) => {
    if (numericRegex.test(e.target.value)) {
      if (e.target.value.length <= 2) {
        if (e.target.value >= 13 || e.target.value === "00") {
          e.target.value = "";
        } else {
          setHourFromInput(e.target.value);
        }
      } else {
        e.target.value = "";
      }
    } else {
      e.target.value = "";
    }
  };

  const handleDimmingInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (dimmingRegex.test(e.target.value)) {
      setDimmingFromInput(e.target.value);
    } else {
      e.target.value = "";
    }
  };

  const addData = () => {
    if ((hourFromInput.length === 2 || hourFromInput.length === 1) && hourFromInput !== "0" && dimmingFromInput.length !== 0) {
      if (totalPoints !== 5) {
        handleData(parseInt(hourFromInput), "addData")
      }
    } else {
      setErrorInvalidData(true)
    }
  };

  const editData = () => {
    const hours = hourFromInput || hoursList[selectedCard];
    const dimming = dimmingFromInput || dimmingsList[selectedCard];
    const sum = hoursList.reduce((acc:any, val:number) => acc + val, 0);

    if ((hours.toString().length === 2 || hours.toString().length === 1) && hours !== "0" && dimming.toString().length !== 0) {
      if (sum - parseInt(hoursList[selectedCard]) + parseInt(hours) <= 12) {
        handleData(parseInt(hours), "editData", parseInt(dimming))
      } else {
        setErrorInvalidData(true)
      }
    } else {
      setErrorInvalidData(true)
    }
  };

  const handleData = (value: number, action: string, dimming?: number | any) => {
    let newData = itemData.datasets.map((dataset:any) => ({
      ...dataset,
      data: [...dataset.data]
    }));
    
    if (action === "addData") {
      const newArr:any = [null, null, null, null, null, null, null, null, null, null, null, null, null];
      const sum = hoursList.reduce((acc:any, val:number) => acc + val, 0);
      if (sum === 12 || sum + value > 12) {
        setErrorInvalidData(true)
        return;
      }

      let secondIndex = sum + value;
      newArr[sum] = parseInt(dimmingFromInput);
      newArr[secondIndex] = parseInt(dimmingFromInput);

      if (!newData[0].data.find((value:any) => value !== null)) {
        newData = [{
          label: t("Solar"),
          data: newArr,
          borderColor: item.color,
          backgroundColor: item.color,
          borderWidth: 10,
          pointRadius: 5,
          pointBorderWidth: 2,
          pointBackgroundColor: 'white',
          pointBorderColor: item.color,
          fill: false,
          spanGaps: true,
        }];
      } else {
        newData.push({
          label: t("Solar"),
          data: newArr,
          borderColor: item.color,
          backgroundColor: item.color,
          borderWidth: 10,
          pointRadius: 5,
          pointBorderWidth: 2,
          pointBackgroundColor: 'white',
          pointBorderColor: item.color,
          fill: false,
          spanGaps: true,
        })
      }
    }

    if (action === "deleteData") {
      const removedData = newData.splice(value, 1)[0];

      const removedIndexs:any = [];
      removedData.data.forEach((value: any, i: number) => {
        if (value !== null) {
          removedIndexs.push(i);
        }
      });

      newData.forEach((dataset: any, index: number) => {
        let indexsFromMap:any = [];
        let valueFromMap:any;
        const tempArr:any = [null, null, null, null, null, null, null, null, null, null, null, null, null];
        if (index >= value) {
          dataset.data.forEach((tempValue: any, i: number) => {
            if (tempValue !== null) {
              indexsFromMap.push(i);
              valueFromMap = tempValue;
            }
          });

          if (index === value) {
            tempArr[removedIndexs[0]] = parseInt(valueFromMap);
            tempArr[removedIndexs[0] + hoursList[index+1]] = parseInt(valueFromMap);
          } else if (index > value) {
            let lastDatasetIndexs:any = [];
            newData[index-1].data.forEach((tempValue: any, i: number) => {
              if (tempValue !== null) {
                lastDatasetIndexs.push(i);
              }
            });

            tempArr[lastDatasetIndexs[1]] = parseInt(valueFromMap);
            tempArr[lastDatasetIndexs[1] + hoursList[index+1]] = parseInt(valueFromMap);
          }
        return {
          ...dataset.data = tempArr,
        };
      }});
    }

    if (action === "editData") {
      const dataToChange = newData[selectedCard].data;

      const oldIndexs:any = [];
      dataToChange.forEach((value: any, i: number) => {
        if (value !== null) {
          oldIndexs.push(i);
        }
      });

      let secondIndex = oldIndexs[0] + value;

      newData.forEach((dataset: any, index: number) => {
        let indexsFromMap:any = [];
        let valueFromMap:any;
        const tempArr:any = [null, null, null, null, null, null, null, null, null, null, null, null, null];
        if (index >= selectedCard) {
          dataset.data.forEach((tempValue: any, i: number) => {
            if (tempValue !== null) {
              indexsFromMap.push(i);
              valueFromMap = tempValue;
            }
          });

          if (index === selectedCard) {
            tempArr[oldIndexs[0]] = parseInt(dimming);
            tempArr[secondIndex] = parseInt(dimming);
          } else if (index > selectedCard) {
            let lastDatasetIndexs:any = [];
            newData[index-1].data.forEach((tempValue: any, i: number) => {
              if (tempValue !== null) {
                lastDatasetIndexs.push(i);
              }
            });

            tempArr[lastDatasetIndexs[1]] = parseInt(valueFromMap);
            tempArr[lastDatasetIndexs[1] + hoursList[index]] = parseInt(valueFromMap);
          }
        return {
          ...dataset.data = tempArr,
        };
      }});
    }
    
    setItemData({
      labels: itemData.labels,
      datasets: newData,
    });
  };

  const resetStates = () => {
    setHourFromInput("");
    setDimmingFromInput("");
    setSelectedCard(false);
    dispatch(turnBackgroundStrategiesFalseActionCreator());
  };

  useEffect(() => {
    const newArray = itemData.datasets.map((element: any) => ({...element, data: [...element.data]}));
    dispatch(changeStrategyChartActionCreator({labels: itemData.labels, data: newArray}));
    //REVISAR ESTO

    /* let dimmingsArr : any = [];
    itemData.datasets.map((el: any) => {
      const firstNonNullValue = el.data.find((value: any) => value !== null);
      
      if (firstNonNullValue !== undefined) {
        dimmingsArr.push(firstNonNullValue);
      }
    });

    let hoursArr : any = [];
    const countConsecutiveWithNulls = () => {
      itemData.datasets.forEach((el:any) => {
        let count = 0;
        let sequenceStarted = false;
        const targetValue = el.data.find((value:any) => value !== null);
    
        if (targetValue !== undefined) {
          el.data.forEach((value:any) => {
            if (value === targetValue) {
              if (sequenceStarted) {
                hoursArr.push(count);
                count = 1;
              } else {
                sequenceStarted = true;
                count = 1;
              }
            } else if (sequenceStarted) {
              count++;
            }
          });
        }
      });
    };
    
    countConsecutiveWithNulls();     */

    const dimmingsArr: any = [];
    const hoursArr: any = [];
    
    newArray.forEach((el: any) => {
      const firstNonNullValue = el.data.find((value: any) => value !== null);
      if (firstNonNullValue !== undefined) {
        dimmingsArr.push(firstNonNullValue);
      }

      let count = 0;
      let sequenceStarted = false;
      const targetValue = el.data.find((value: any) => value !== null);

      if (targetValue !== undefined) {
        el.data.forEach((value: any) => {
          if (value === targetValue) {
            if (sequenceStarted) {
              hoursArr.push(count);
              count = 1;
            } else {
              sequenceStarted = true;
              count = 1;
            }
          } else if (sequenceStarted) {
            count++;
          }
        });
      }
    });

    setDimmingsList(dimmingsArr);
    setHoursList(hoursArr);
  }, [itemData, selectedCard]);

  useEffect(() => {
    if (dimmingsList?.length > 0) {
      if (item.lum_power_w && dimmingsList[0]) {
        const res = (item.lum_power_w * dimmingsList[0] / 100) * hoursList[0];
        setTotalTime1(res)
      } else (
        setTotalTime1(0)
      )
  
      if (item.lum_power_w && dimmingsList[1]) {
        const res = (item.lum_power_w * dimmingsList[1] / 100) * hoursList[1];
        setTotalTime2(res)
      } else (
        setTotalTime2(0)
      )
  
      if (item.lum_power_w && dimmingsList[2]) {
        const res = (item.lum_power_w * dimmingsList[2] / 100) * hoursList[2];
        setTotalTime3(res)
      } else (
        setTotalTime3(0)
      )
  
      if (item.lum_power_w && dimmingsList[3]) {
        const res = (item.lum_power_w * dimmingsList[3] / 100) * hoursList[3];
        setTotalTime4(res)
      } else (
        setTotalTime4(0)
      )
  
      if (item.lum_power_w && dimmingsList[4]) {
        const res = (item.lum_power_w * dimmingsList[4] / 100) * hoursList[4];
        setTotalTime5(res)
      } else (
        setTotalTime5(0)
      )
      dispatch(saveSolarParamsActionCreator({ ledsNumber: item.n_led_strip, ledVolt: item.single_led_v, lumPower: item.lum_power, photoVolt: item.photocell_v }));
      dispatch(saveSmartManagementActioncreator({ smartManagement: item.smart_management_id }))
      dispatch(saveConsumsActionCreator({ totalConsum: totalTime1 + totalTime2 + totalTime3 + totalTime4 + totalTime5, maxConsum: item.max_consum_wh }));
    }
  }, [dimmingsList, hoursList, totalTime1, totalTime2, totalTime3, totalTime4, totalTime5]);

  return (
    <div style={{ paddingRight: "22px", paddingLeft: "20px" }}>
      {/* <div style={{ display: "flex", alignItems: "center", height: "64px", gap: "14px", marginTop: "10px" }}>              
        <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px" }}>{t("limitParams")}</Text>      
        <div style={{ display: "flex", alignSelf: "flex-start", justifyContent: "flex-end", gap: "10px" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "start", width: "120px" }}>
            <Text textType="caption" marginBottom="2px" color={supportTextColor} style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>
              {t("photocellVolt").toUpperCase()}
            </Text>
            <InputField style={{ height: "30px", width: "110px", padding: "8px 24px 8px 8px" }} defaultValue={photoVolt} />
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "end", width: "150px" }}>
            <img src={luminairePower} width={24} style={{marginBottom: "4px"}} />
            <div>
              <Text textType="caption" marginBottom="2px" color={supportTextColor} style={{ /* fontSize: "12px", fontVariant: "small-caps", textTransform: "lowercase"
                fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>
                {t("voltLedSingle").toUpperCase()}
              </Text>
              <div style={{ position: "relative", width: "110px" }}>
                <InputField style={{ height: "30px", width: "110px", padding: "8px 24px 8px 8px" }} defaultValue={photoVolt} />
                <div style={{ position: "absolute", top: 0, right: "8px", height: "100%", display: "flex", alignItems: "center", fontSize: "14px", color: "#A3AED0" }}>V</div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "8px", alignItems: "end", width: "160px" }}>
            <img src={smartIcon} width={24} style={{marginBottom: "4px"}} />
            <div>
              <Text textType="caption" marginBottom="2px" color={supportTextColor} style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{t("smartManagement").toUpperCase()}</Text>
              <Select
                style={{ width: "110px" }}
                optionFilterProp="children"
                onChange={handleSelectIntPower}
                value={smartManagement}
                options={smartManagementOptions}
                className="smartManage"
                popupClassName="smartManage-dropdown"
              />
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "end", width: "150px" }}>
            <img src={luminairePower} width={24} style={{marginBottom: "4px"}} />
            <div>
              <Text textType="caption" marginBottom="2px" color={supportTextColor} style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{t("lumPower").toUpperCase()}</Text>
              <div style={{ position: "relative", width: "110px" }}>
                <InputField style={{ height: "30px", width: "110px", padding: "8px 24px 8px 8px" }} defaultValue={item.lum_power_w} />
                <div style={{ position: "absolute", top: 0, right: "8px", height: "100%", display: "flex", alignItems: "center", fontSize: "14px", color: "#A3AED0" }}>W</div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "8px", alignItems: "end", width: "150px" }}>
            <img src={maxConsumIcon} width={24} style={{marginBottom: "4px"}} />
            <div>
              <Text textType="caption" marginBottom="2px" color={supportTextColor} style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{t("maxConsum").toUpperCase()}</Text>
              <div style={{ position: "relative", width: "110px" }}>
                <InputField style={{ height: "30px", width: "110px", padding: "8px 24px 8px 8px" }} defaultValue={maxConsum}
                  onChange={(e) => numericRegex.test(e.target.value) && setMaxConsum(e.target.value)}
                />
                <div style={{ position: "absolute", top: 0, right: "8px", height: "100%", display: "flex", alignItems: "center", fontSize: "14px", color: "#A3AED0" }}>W</div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", gap: "8px", alignItems: "end", width: "150px" }}>
            <img src={photocell} width={24} style={{marginBottom: "4px"}} />
            <div>
              <Text textType="caption" marginBottom="2px" color={supportTextColor} style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em" }}>{t("photocellVolt").toUpperCase()}</Text>
              <div style={{ position: "relative", width: "110px" }}>
                <InputField style={{ height: "30px", width: "110px", padding: "8px 24px 8px 8px" }} defaultValue={photoVolt} />
                <div style={{ position: "absolute", top: 0, right: "8px", height: "100%", display: "flex", alignItems: "center", fontSize: "14px", color: "#A3AED0" }}>V</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div style={{ marginTop: "20px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Text textType="caption" fontWeight="500" marginBottom="0px" color={supportTextColor} style={{ alignSelf: "end", fontSize: "14px", marginRight: "11px" }}>{totalPoints}/5 {t("times")}</Text>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "14px" }}>{t("dimmingConfigurationSideMenu")}</Text>
              <Text textType="caption" fontWeight="400" marginBottom="0px" style={{ fontSize: "12px" }}>{t("dimmingConfigurationBoxes")}</Text>
            </div>
            <button
              style={{ display: "flex", alignItems: "center", backgroundColor: totalPoints === 5 || (hoursList && hoursList.reduce((acc:any, val:number) => acc + val, 0) === 12) ? "#e3e1e1" : "#ffffff",
                color: mainTextColor, fontSize: "12px", paddingRight: "14px", paddingLeft: "5px", border: `1px solid ${mainTextColor}`, borderRadius: "64px",
                cursor: totalPoints === 5 || (hoursList && hoursList.reduce((acc:any, val:number) => acc + val, 0) === 12) ? "not-allowed" : "pointer"
              }}
              onClick={() => {
                if (totalPoints !== 5 && (hoursList && hoursList.reduce((acc:any, val:number) => acc + val, 0) !== 12)) {
                  dispatch(changeBackgroundStrategiesActionCreator()); setErrorInvalidData(false);
                }
              }}
            >
              <img src={plus} height={26} style={{ marginRight: "2px" }} />
              <Text textType="caption" fontWeight="500" marginBottom="0px" style={{ fontSize: "12px" }}>{t("addTime")}</Text>
            </button>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px", marginBottom: "25px" }}>
        <div style={{ display: "flex", gap: "20px" }}>
          {dimmingsList?.map((point: any, index: number) => {
            return (
              <div key={index} style={{ display: "flex", flexDirection: "column", width: "110px", gap: "4px", marginLeft: "0" }}>
                <div style= {{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  {index === 0 ?
                    <Text fontWeight="500" color={mainTextColor} style={{marginBottom: "0px", fontSize: "14px"}}>{t("time1").toUpperCase()}</Text>
                  : 
                    index === 1 ?
                      <Text fontWeight="500" color={mainTextColor} style={{marginBottom: "0px", fontSize: "14px"}}>{t("time2").toUpperCase()}</Text>
                    :
                      index === 2 ?
                        <Text fontWeight="500" color={mainTextColor} style={{marginBottom: "0px", fontSize: "14px"}}>{t("time3").toUpperCase()}</Text>
                      :
                        index === 3 ?
                          <Text fontWeight="500" color={mainTextColor} style={{marginBottom: "0px", fontSize: "14px"}}>{t("time4").toUpperCase()}</Text>
                        :
                          index === 4 ?
                            <Text fontWeight="500" color={mainTextColor} style={{marginBottom: "0px", fontSize: "14px"}}>{t("time5").toUpperCase()}</Text>
                          :
                            <></>
                  }
                  <>
                    <img src={editIcon} height={8} style={{ marginRight: "2px", opacity: 0.5, cursor: "pointer" }} onClick={() => { dispatch(saveIndexActionCreator({ index: index }));
                      setErrorInvalidData(false); dispatch(changeBackgroundStrategiesActionCreator()); setSelectedCard(index)}}
                    />
                    {dimmingsList?.length !== 1 ?
                      <img src={deleteIcon} height={8} style={{ marginLeft: "-26px", opacity: 0.5, cursor: "pointer" }} onClick={async() => {handleData(index, "deleteData")}} />
                    :
                      <></>
                    }
                  </>
                </div>
                <div style= {{ display: "flex", border: "1px solid #ddd", borderRadius: 5, justifyContent: "center", height: "30px", marginBottom: "1px",
                  boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
                >
                  <Text style={{ fontSize: "18px", marginTop: "2px", color: supportTextColor }}>{hoursList[index]} {hoursList[index] === 1 ? t("hour").toLowerCase() : t("hours")}</Text>
                </div>
                <div style= {{ display: "flex", border: "1px solid #ddd", borderRadius: 5, justifyContent: "center", height: "30px",
                  boxShadow: "0px 0px 8px 3px rgba(0, 0, 0, 0), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
                >
                  <Text style={{ fontSize: "18px", marginTop: "2px", color: supportTextColor }}>{dimmingsList[index]}%</Text>
                </div>
              </div>
            )
          })}
          {background ? 
            <div style={{ background: "#FFFFFF", zIndex: 6, position: "absolute", left: 435, top: 140, width: "220px", height: "290px", borderRadius: "0.5rem", padding: "20px",
              paddingTop: "16px", paddingBottom: "16px" }}
            >
              {selectedCard === 0 || selectedCard ?
                selectedCard === 0 ?
                  <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("modify").toUpperCase()} {t("time1").toUpperCase()}</Text>
                :
                  selectedCard === 1 ?
                    <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("modify").toUpperCase()} {t("time2").toUpperCase()}</Text>
                  :
                    selectedCard === 2 ?
                      <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("modify").toUpperCase()} {t("time3").toUpperCase()}</Text>
                    :
                      selectedCard === 3 ?
                        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("modify").toUpperCase()} {t("time4").toUpperCase()}</Text>
                      :
                        selectedCard === 4 ?
                          <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("modify").toUpperCase()} {t("time5").toUpperCase()}</Text>
                        :
                          <></>
                
              :
                <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "12px"}}>{t("addTime").toUpperCase()}</Text>
              }
              <div style={{ display: "flex", flexDirection: "column", marginTop: "20px", alignItems: "center", justifyContent: "center"}}>
                <Input
                  style={{border: "none", borderRadius: 0, width: "76px", height: "60px", background: hexToRgb(supportTextColor), textAlign: "center", fontSize: "30px",
                    color: accentButtonsColor, fontWeight: "500"
                  }}
                  defaultValue={hoursList[selectedCard]}
                  onChange={(e) => {handleHoursInputsChange(e)}}
                />
                <Text fontWeight="400" style={{marginBottom: "4px", fontSize: "12px"}}>{t("totalHours")}</Text>
                <Input
                  style={{border: "none", borderRadius: 0, width: "180px", height: "60px", background: hexToRgb(supportTextColor), textAlign: "center", fontSize: "30px",
                    color: accentButtonsColor, fontWeight: "500", marginTop: "16px"
                  }}
                  defaultValue={dimmingsList[selectedCard]}
                  onChange={(e) => {handleDimmingInputChange(e)}}
                />
                <Text fontWeight="400" style={{marginBottom: "4px", fontSize: "12px", alignSelf: "flex-start" }}>{t("Dimming")} %</Text>
              </div>
              <div style={{ display: "flex", marginTop: "16px", alignItems: "center", justifyContent: "space-between" }}>
                <img src={clock} height={20} style={{ opacity: 0.7, cursor: "pointer" }} />
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <Text marginBottom="0px" fontWeight="500" style={{ color: supportTextColor, cursor: "pointer", fontSize: "16px" }} onClick={() => {resetStates()}}>{t("Cancel").toUpperCase()}</Text>
                  <NextButton accentButtonsColor={accentButtonsColor} style={{ padding: "3px 16px", height: "30px", cursor: "pointer", boxShadow: "none" }} onClick={() => {
                    selectedCard === 0 || selectedCard ?
                      editData()
                    :
                      addData()
                  
                    resetStates()}}
                  >
                    {t("ok")}
                  </NextButton>
                </div>
              </div>
            </div>
          :
            <></>
        }
        </div>
      </div>
      <div style={{ display: "flex", flexDirection:"column", marginTop: "0px", alignItems: "center", justifyContent: "center"}}>
        {
          totalPoints === 5 ?
            <Text textType="caption" fontWeight="500" marginBottom="5px" style={{ fontSize: "14px" }}>{t("errorStrategyLimit")}</Text>
          :
            <></>
        }
        {
           errorInvalidData ?
            <Text  marginBottom="6px" fontWeight="500" style={{ fontSize: "14px", color: "red", marginTop: "-27px" }}>{t("errorBlankInput")}</Text>
          :
            <></>
        }
      </div>

      <div style={{ display:"flex", overflow: "hidden", gap: "20px" }}>
        <div style={{ width: "500px", height: "240px" }}>
          <Line options={options} data={itemData} />
        </div>
        <div style={{ marginTop: "-6px" }}>
          <Text textType="caption" marginBottom="2px" style={{ fontSize: "0.6em", textTransform: "uppercase", letterSpacing: "0.05em", marginTop: "4px" }}>{t("battConsumVer").toUpperCase()}</Text>       
          <div style={{ display: "flex", flexDirection: "row", alignSelf: "center", gap: "10px", marginTop: "10px" }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", gap: "10px" }}>
              {hoursList?.length >= 1 && <Text textType="caption" color={supportTextColor} style={{ fontSize: "14px" }}>{t("time1").toUpperCase()}</Text>}
              {hoursList?.length >= 2 && <Text textType="caption" color={supportTextColor} style={{ fontSize: "14px" }}>{t("time2").toUpperCase()}</Text>}
              {hoursList?.length >= 3 && <Text textType="caption" color={supportTextColor} style={{ fontSize: "14px" }}>{t("time3").toUpperCase()}</Text>}
              {hoursList?.length >= 4 && <Text textType="caption" color={supportTextColor} style={{ fontSize: "14px" }}>{t("time4").toUpperCase()}</Text>}
              {hoursList?.length >= 5 && <Text textType="caption" color={supportTextColor} style={{ fontSize: "14px" }}>{t("time5").toUpperCase()}</Text>}
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {hoursList?.length >= 1 &&
                <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }}
                  value={(item.lum_power_w * dimmingsList[0] / 100) % 1 !== 0 ? (item.lum_power_w * dimmingsList[0] / 100).toFixed(2) + " W" : item.lum_power_w * dimmingsList[0] / 100 + " W"}
                />
              }
              {hoursList?.length >= 2 && 
                <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }}
                  value={(item.lum_power_w * dimmingsList[1] / 100) % 1 !== 0 ? (item.lum_power_w * dimmingsList[1] / 100).toFixed(2) + " W" : item.lum_power_w * dimmingsList[1] / 100 + " W"}
                />
              }
              {hoursList?.length >= 3 &&
                <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }}
                  value={(item.lum_power_w * dimmingsList[2] / 100) % 1 !== 0 ? (item.lum_power_w * dimmingsList[2] / 100).toFixed(2) + " W" : item.lum_power_w * dimmingsList[2] / 100 + " W"}
                />
              }
              {hoursList?.length >= 4 &&
                <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }}
                  value={(item.lum_power_w * dimmingsList[3] / 100) % 1 !== 0 ? (item.lum_power_w * dimmingsList[3] / 100).toFixed(2) + " W" : item.lum_power_w * dimmingsList[3] / 100 + " W"}
                />
              }
              {hoursList?.length === 5 &&
                <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }}
                  value={(item.lum_power_w * dimmingsList[4] / 100) % 1 !== 0 ? (item.lum_power_w * dimmingsList[4] / 100).toFixed(2) + " W" : item.lum_power_w * dimmingsList[4] / 100 + " W"}
                />
                }
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", gap: "10px" }}>
              {hoursList?.length >= 1 && <Text textType="caption" style={{ fontSize: "14px" }}>x</Text>}
              {hoursList?.length >= 2 && <Text textType="caption" style={{ fontSize: "14px" }}>x</Text>}
              {hoursList?.length >= 3 && <Text textType="caption" style={{ fontSize: "14px" }}>x</Text>}
              {hoursList?.length >= 4 && <Text textType="caption" style={{ fontSize: "14px" }}>x</Text>}
              {hoursList?.length === 5 && <Text textType="caption" style={{ fontSize: "14px" }}>x</Text>}
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              {hoursList?.length >= 1 &&
                <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={hoursList[0] + " H"} />
              }
              {hoursList?.length >= 2 &&
                <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={hoursList[1] + " H"} />
              }
              {hoursList?.length >= 3 &&
                <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={hoursList[2] + " H"} />
              }
              {hoursList?.length >= 4 &&
                <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={hoursList[3] + " H"} />
              }
              {hoursList?.length === 5 &&
                <InputField disabled style={{ height: "30px", width: "80px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={hoursList[4] + " H"} />
              }
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", gap: "10px" }}>
              {hoursList?.length >= 1 && <Text textType="caption" style={{ fontSize: "14px" }}>=</Text>}
              {hoursList?.length >= 2 && <Text textType="caption" style={{ fontSize: "14px" }}>=</Text>}
              {hoursList?.length >= 3 && <Text textType="caption" style={{ fontSize: "14px" }}>=</Text>}
              {hoursList?.length >= 4 && <Text textType="caption" style={{ fontSize: "14px" }}>=</Text>}
              {hoursList?.length === 5 && <Text textType="caption" style={{ fontSize: "14px" }}>=</Text>}
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginRight: "12px" }}>
              {hoursList?.length >= 1 &&
                <InputField disabled style={{ height: "30px", width: "95px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={totalTime1 + " WH"} />}
              {hoursList?.length >= 2 &&
                <InputField disabled style={{ height: "30px", width: "95px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={totalTime2 + " WH"} />
              }
              {hoursList?.length >= 3 &&
                <InputField disabled style={{ height: "30px", width: "95px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={totalTime3 + " WH"} />
              }
              {hoursList?.length >= 4 &&
                <InputField disabled style={{ height: "30px", width: "95px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={totalTime4 + " WH"} />
              }
              {hoursList?.length === 5 &&
                <InputField disabled style={{ height: "30px", width: "95px", padding: "8px", opacity: 0.5, textAlign: "center" }} value={totalTime5 + " WH"} />
              }
            </div>
          </div>  
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "4px", position: "absolute", right: 56 }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", gap: "10px" }}>
          <Text textType="caption" style={{ fontSize: "14px" }}>{t("total").toUpperCase()}</Text>
          <InputField disabled style={{ color: dimmingsList?.length > 0 ? totalConsumption > item.max_consum_wh ? "red" : totalConsumption > item.max_consum_wh - 10 ? "#FFC107" : "green"  : "",
              border: dimmingsList?.length > 0 ? totalConsumption > item.max_consum_wh ? "2px solid red" : totalConsumption > item.max_consum_wh - 10 ? "2px solid #FFC107" : "2px solid green" : "",
              height: "30px", width: "95px", padding: "8px", textAlign: "center"
            }}
            value={totalConsumption % 1 !== 0 ?
              totalConsumption.toFixed(2) + " WH"
            :
            totalConsumption + " WH"}
          />
        </div>
        {dimmingsList?.length > 0 ?
            totalConsumption > item.max_consum_wh ?
              <Text fontWeight="500" style={{ display: "flex", justifyContent: "center", color: "red" }}>{t("errorTotalMaxConsum")}</Text>
          :
            totalConsumption > item.max_consum_wh - 10 ?
              <Text fontWeight="500" style={{ display: "flex", justifyContent: "center", color: "#FFC107" }}>{t("okTotalMaxConsum")}</Text>
            :
              <Text fontWeight="500" style={{ display: "flex", justifyContent: "center", color: "green" }}>{t("okTotalMaxConsum")}</Text>
        :
          <></>
        }
      </div>
    </div>
  )
}

export default EditChartSolar;
