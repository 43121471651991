import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { changeStrategyChartActionCreator, saveStrategyInfoActionCreator } from "../../../../redux/slices/strategiesSlice/strategiesSlice";
import { useStrategiesService } from "../../../../services/strategiesService";
import { Dimming } from "../Step2Dimming/Dimming";
import { InputField, TextAreaField, CardContainer, NextButton } from "../StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import ellipseBlack from "../../../../assets/new_icons/ellipse_black.svg";
import ellipseBlue from "../../../../assets/new_icons/ellipse_blue.svg";
import ellipseGreen from "../../../../assets/new_icons/ellipse_green.svg";
import ellipseRed from "../../../../assets/new_icons/ellipse_red.svg";
import ellipseYellow from "../../../../assets/new_icons/ellipse_yellow.svg";
import luminaire from "../../../../assets/new_icons/luminaire detailCard.svg";
import sun from "../../../../assets/new_icons/sun.svg";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface NameProps {
  showDimming?: any;
  goToDimming?: any;
  showParams?: any;
  goToParams?: any;
  errorMessage?: boolean;
  setErrorMessage?: any
  errorDuplicate?: boolean;
  setErrorDuplicate?: any;
};

export const Details = ({ showDimming, goToDimming, showParams, goToParams, errorMessage, setErrorMessage, errorDuplicate, setErrorDuplicate }: NameProps) => {
  const strategyInfo = useAppSelector((state) => state.strategies);
  const [name, setName] = useState(strategyInfo.name);
  const [description, setDescription] = useState(strategyInfo.description);
  const [selectedColorOption, setSelectedColorOption]: any = useState(strategyInfo.color);
  const [selectedTypeOption, setSelectedTypeOption]: any = useState(strategyInfo.type);
  
  const dispatch = useAppDispatch();
  const { getStrategies, getSolarStrategies } = useStrategiesService();
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const colorOptions = [
    { label: t("selectColor") },
    { value: "#56C568", label: t("Green"), icon: ellipseGreen },
    { value: "#D51A52", label: t("Red"), icon: ellipseRed },
    { value: "#FBBC05", label: t("Yellow"), icon: ellipseYellow },
    { value: "#1C1B1F", label: t("Black"), icon: ellipseBlack },
    { value: "#87CBE8", label: t("Blue"), icon: ellipseBlue },
  ];

  const typeOptions = [
    { label: t("selectType") },
    { value: "standard", label: t("Standard"), icon: luminaire },
    { value: "solar", label: t("Solar"), icon: sun },
  ];

  const formatOptionLabel = ({ value, label, icon }: any) => (
    <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={() => {dispatch(changeStrategyChartActionCreator({labels: "", data: ""}))}}>
      {icon && <img src={icon} alt="" style={{ marginRight: "8px", marginTop: "1px", width: "12px", height: "13px" }} /> }
      <Text textType="caption" color="#959595" marginBottom="0px" style={{ fontSize: "14px" }}>{label}</Text>
    </div>
  );

  useEffect(() => {
    if (name && selectedColorOption && selectedTypeOption) {
      setErrorMessage(false);
      setErrorDuplicate(false);
    };

    if (selectedTypeOption.value === "standard") {
      getStrategies(selectedOrgId, selectedServiceId, "newstr");
    } else if (selectedTypeOption.value === "solar") {
      getSolarStrategies(selectedOrgId, selectedServiceId, "newstr");
    }
    
    dispatch(saveStrategyInfoActionCreator({ name: name, color: selectedColorOption, type: selectedTypeOption, description: description }));
  }, [name, selectedColorOption, selectedTypeOption, description]);

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: "146px", paddingLeft: "40px" }}>
        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step1")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("generalDetails")}</Text>
        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("generalDetailsSubtitleStrategies")}</Text>
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignSelf: "center", justifySelf: "center", width: "300px", marginBottom: errorMessage || errorDuplicate ? "11px" : "32px" }}>
        <Text textType="caption" color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("name")}*</Text>
        <InputField value={name} onChange={(e) => setName(e.target.value)} maxLength={50} placeholder={t("name")} />
        <Text textType="caption" color={mainTextColor} marginBottom="2px" style={{marginTop: "14px", fontSize: "14px"}}>{t("color")}*</Text>
        <Select
          value={selectedColorOption ? selectedColorOption : colorOptions[0]}
          onChange={setSelectedColorOption}
          options={colorOptions}
          formatOptionLabel={formatOptionLabel}
          placeholder={t("selectColor")}
          styles={{
            control: (baseStyles, state) => { 
              state.theme.colors.primary = 'none';
              return {
                ...baseStyles,
                cursor: "pointer",             
                backgroundColor: "#fff",
                color: "#212529",
                maxHeight: "36px",
                minHeight: "36px",
                fontSize: "14px",
                fontWeight: "400",
                borderRadius: "0.375rem",
                border: state.isFocused ? "2px solid" : "1px solid #ced4da",
                '&:hover': {
                  borderColor: 'none',
                }
              }
            },
          }}
        />
        <Text textType="caption" color={mainTextColor} marginBottom="2px" style={{marginTop: "14px", fontSize: "14px"}}>{t("type")}*</Text>
        <Select
          value={selectedTypeOption ? selectedTypeOption : typeOptions[0]}
          onChange={setSelectedTypeOption}
          options={typeOptions}
          formatOptionLabel={formatOptionLabel}
          placeholder={t("selectType")}
          styles={{
            control: (baseStyles, state) => { 
              state.theme.colors.primary = 'none';
              return {
                ...baseStyles,
                cursor: "pointer",             
                backgroundColor: "#fff",
                color: "#212529",
                maxHeight: "36px",
                minHeight: "36px",
                fontSize: "14px",
                fontWeight: "400",
                borderRadius: "0.375rem",
                border: state.isFocused ? "2px solid" : "1px solid #ced4da",
                '&:hover': {
                  borderColor: 'none',
                }
              }
            },
          }}
        />
        <Text textType="caption" color={mainTextColor} marginBottom="2px" style={{marginTop: "14px", fontSize: "14px"}}>{t("description")} {t("optional")}</Text>
        <TextAreaField value={description} onChange={(e) => setDescription(e.target.value)} maxLength={250} placeholder={t("description")} />
        {
          errorMessage ?
            <Text marginBottom="0px" style={{display: "flex",  justifyContent: "center", color: "red", marginTop: "6px"}}>{t("errorBlankInput")}</Text>  
          :
            errorDuplicate ?
              <Text marginBottom="0px" style={{display: "flex",  justifyContent: "center", color: "red", marginTop: "6px"}}>{t("errorDuplicatedName")}</Text>
            :
              <></>
        }
      </div>
      <NextButton style={{ background: errorMessage || errorDuplicate ? accentButtonsColor+95 : accentButtonsColor, borderColor: errorMessage || errorDuplicate ?
        `1px solid ${accentButtonsColor+95}` : accentButtonsColor, justifySelf: "end", alignSelf: "center", width: "300px", marginTop: errorMessage || errorDuplicate ? "86px" : "92px",
        cursor: errorMessage || errorDuplicate ? "not-allowed" : "pointer" }}
        onClick={() => {
          if (!errorMessage && !errorDuplicate) {
            selectedTypeOption.value === "standard" ? goToDimming() : goToParams();
          }
        }}
      >
        {t("continue")}
      </NextButton>
      {showDimming && <Dimming />}
      {/* {showDimming ?
        selectedTypeOption.value === "standard" ? 
          <DimmingStandard />
        :
          <DimmingSolar />
      :
        <></>
      } */}
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
        <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
        {selectedTypeOption.value === "solar" && <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />}
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
      </div>
    </CardContainer>
  );
};
