import styled from "styled-components";

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-right: 32px;
`;

export const IconStyled = styled.img`
  margin-right: 8px;
`;

export const CardStyled = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  border: none;
  padding: 32px;
  border-radius: 16px;
`;
