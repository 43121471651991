import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setIsFullViewActionCreator, setFirstRenderMapToTrueActionCreator } from "../../redux/slices/uiSlice/uiSlice";
import { CardContainerResponsePage } from "./ResponsePageStyled";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import { Text } from "../../styles/shared-styles/text-sizes";
import commingSoon from "../../assets/new_icons/coming_soon.png";

export const ComingSoon = () => {
  const dispatch = useAppDispatch();
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  return (
    <CardContainerResponsePage>
      <div className="row text-center">
        <div className="col-12">
          <img src={commingSoon} />
          <Text
            textType="title"
            color={accentButtonsColor}
            fontWeight="700"
            marginBottom="10px"
          >
            {t("comingSoonTitle")}
          </Text>
          <Text textType="subTitle" color={accentButtonsColor} fontWeight="400" marginBottom="20px">
            {t("comingSoonSubtitle")}
          </Text>
          <a href="/dashboard">
            <Button buttonType="primary" style={{ background: buttonsColor, color: accentTextColor }}
              onClick={() => {dispatch(setIsFullViewActionCreator()); dispatch(setFirstRenderMapToTrueActionCreator())}}>
                {t("goBackDashboard")}
            </Button>
          </a>
        </div>
      </div>
    </CardContainerResponsePage>
  );
};
