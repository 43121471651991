import redEllipse from "../../assets/new_icons/ellipse_red.svg";
import yellowEllipse from "../../assets/new_icons/ellipse_yellow.svg";
import greyEllipse from "../../assets/new_icons/ellipse_grey.svg";

type AlarmStatus = "Critical" | "Moderate" | "Low";
interface IProps {
  status: AlarmStatus;
}

export const GetBadge = ({ status }: IProps) => {
  const badgeMap = {
    Critical: redEllipse,
    Moderate: yellowEllipse,
    Low: greyEllipse,
  };
  return (
    <img src={badgeMap[status]} style={{ marginRight: "4px" }} alt="status" />
  );
};
