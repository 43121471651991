interface UserAvatarProps {
  firstName: string;
  profileColor: string;
  type: string;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ firstName, profileColor, type }) => {
  const avatarStyle: React.CSSProperties = {
    backgroundColor: profileColor || "#000000",
    width: type === "list" ? "36px" : type === "header" ? "40px" : "72px",
    height: type === "list" ? "36px" : type === "header" ? "40px" : "72px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: type === "list" ? "16px" : type === "header" ? "20px" : "30px",
    fontWeight: "bold",
    color: "#ffffff",
    padding: type === "list" ? "16px 0px" : "",
  };
  const firstLetter: string = firstName.charAt(0).toUpperCase();
  
  return <div style={avatarStyle}>{firstLetter}</div>;
};
