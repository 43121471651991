import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CSSProperties } from 'react';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setSelectGroupActionCreator, setSelectedDevicesListActionCreator } from "../../../redux/slices/groupsSlice/groupsSlice";
import { MainInfo } from "./MainInfo";
import { Consumptions } from "./Consumptions";
import { NavLink, Structure } from "./GroupsDetailsCardStyled";
import { CardContainer, EmptyCardContainer } from "../../UsersPage/UsersPageStyled";
import { Text } from "../../../styles/shared-styles/text-sizes";
import leftHand from "../../../assets/new_icons/left-hand.svg";
import { ReactComponent as Cross } from "../../../assets/new_icons/Cross.svg";

interface GroupsDetailsCardProps {
  setLoading: any;
}

interface ReactComponent extends CSSProperties {
  '--svg-background'?: string;
  '--svg-details'?: string;
}

export const GroupsDetailsCard = ({ setLoading }: GroupsDetailsCardProps) => {
  const [viewMainInfo, setViewMainInfo] = useState<boolean>(true);
  const [viewConsumptions, setViewConsumptions] = useState<boolean>(false);
  
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state) => state.groups.groupsArr);
  const selectedGroup = useAppSelector((state) => state.groups.selectedGroup);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const { t } = useTranslation();

  useEffect(() => {
    setViewMainInfo(true);
    setViewConsumptions(false);
  }, [selectedGroup]);

  return (
    <div>
      {selectedGroup.length !== 0 ? (
        <CardContainer style={{ display: "flex", flexDirection: "column",  padding: "14px", paddingRight: "10px" }}>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <Text textType="title">{selectedGroup.groupName}</Text>
            <button style={{ outline: "none", border: 0, background: "transparent", display: "flex" }}
              onClick={() => {dispatch(setSelectGroupActionCreator({ selectedGroup: [] })); dispatch(setSelectedDevicesListActionCreator({ selectedDevices: [] }))}}
            >
              <Cross width={10} height={10} style={{ color: "#525252" }} />
            </button>
          </div>
          <div className="nav nav-pills" style={{ marginBottom: "26px" }}>
            <div className="nav-item">
              <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${viewMainInfo ? "active" : ""}`} id="mainInfo-tab" data-bs-toggle="tab" type="button"
                onClick={() => {setViewMainInfo(true); setViewConsumptions(false)}}
              >
                {t("mainInfo")}
              </NavLink>
            </div>
            <div className="nav-item">
              <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${viewConsumptions ? "active" : ""}`} id="consumptions-tab" data-bs-toggle="tab" type="button"
                onClick={() => {setViewConsumptions(true); setViewMainInfo(false)}}
              >
                {t("consumptions")}
              </NavLink>
            </div>
          </div>
          {viewMainInfo ?
            <Structure>
              <MainInfo setLoading={setLoading} />
            </Structure>
          :
            <Structure>
              <Consumptions setLoading={setLoading} />
            </Structure>
          }
        </CardContainer>
      ) 
      : 
      (
        <EmptyCardContainer>
          {groups.length === 0 ? 
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Text color={mainTextColor} textType="title" fontWeight="700" marginBottom="0">
                {t("noGroupsTitle")}
              </Text>
              <Text textType="caption" color={mainTextColor} marginBottom="0">
                {t("noGroupsSubtitle")}
              </Text>
            </div>
          :  
            <div style={{ display: "flex", gap: "14px" }}>
              <img src={leftHand} width={60} />
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Text color={mainTextColor} textType="title" fontWeight="700" marginBottom="0">
                  {t("selectGroupForInfoTitle")}
                </Text>
                <Text textType="caption" color={mainTextColor} marginBottom="0">
                  {t("selectGroupForInfoSubtitle")}
                </Text>
              </div>
            </div>
          }
        </EmptyCardContainer>
      )}
    </div>
  );
};
