import { MapContainer, TileLayer } from "react-leaflet";
import { LatLng } from "leaflet";
import { Marker } from "react-leaflet";
import { useEffect, useRef, } from "react";
import { useAppSelector  } from "../../../redux/hooks";
import { getIcon } from "../../../utils/icons";
import { CustomMapStyled } from "./CustomMapStyled";
import "../../../styles/index.css";

interface MiniMapProps {
  center: LatLng;
  mapSize: React.CSSProperties;
  selectedLamp: any
}

export const MiniMap = ({ center, mapSize, selectedLamp }: MiniMapProps) => {
  const mapRef = useRef<any>(null);
  const fullView = useAppSelector((state) => state.ui.isFullView);
  const hybridView = useAppSelector((state) => state.ui.isHybridView);

  let latitude = Number.parseFloat(selectedLamp.latitude);
  let longitude = Number.parseFloat(selectedLamp.longitude);
  const lampStatus = selectedLamp.online === "1" ? (selectedLamp.on === "1" ? "on" : "off") : "disconnected";
  //const icon = getIcon(lampStatus, node.selected);
  const icon = getIcon(lampStatus, false);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView([latitude, longitude]);
    }
  }, [latitude, longitude, selectedLamp]);
  
  return (
    <CustomMapStyled id="map-container" style={!fullView && !hybridView ? { position: "absolute", top: 0, left: 0, width: "100%", height: "100%" } : { position: "relative" }}>
      <MapContainer
        className="my-custom-map"
        center={center}
        zoom={17}
        scrollWheelZoom={false}
        zoomControl={false}
        dragging={false}
        attributionControl={false}
        ref={mapRef}
      >
        <TileLayer
          key={"map layer"}
          //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          url={`https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}`}
        />
        <Marker
          key={selectedLamp.node_id}
          position={[latitude, longitude]}
          riseOnHover
          riseOffset={100}
          icon={icon}
        />
      </MapContainer>
    </CustomMapStyled>
  );
};
