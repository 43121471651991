import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CSSProperties } from 'react';
import { notification } from "antd";
import { store } from "../../../redux/store";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateGroupsArrAction } from "../../../redux/actions/groupsActions/groupsActions";
import { setSelectGroupActionCreator, setSelectedDevicesListActionCreator } from "../../../redux/slices/groupsSlice/groupsSlice";
import { useGroupsService } from "../../../services/groupsService";
import { EditGroupDevices } from "../EditGroupModal/EditGroupDevices";
import { EditGroupName } from "../EditGroupModal/EditGroupName";
import { GroupsLeafletMap } from "../GroupsMap/GroupsLeafletMap";
import { PopupContainer } from "../GroupsPageStyled";
import { Text } from "../../../styles/shared-styles/text-sizes";
import { ReactComponent as PencilIcon } from "../../../assets/new_icons/pencil-btn.svg";

interface MainInfoProps {
  setLoading: any;
}

interface ReactComponent extends CSSProperties {
  '--svg-background'?: string;
  '--svg-details'?: string;
}

export const MainInfo = ({ setLoading }: MainInfoProps) => {
  const [bulkActions, setBulkActions] = useState(false);
  const [showEditName, setShowEditName] = useState(false);
  const [showEditGroupDevices, setShowEditGroupDevices] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  
  const dispatch = useAppDispatch();
  const { deleteGroup } = useGroupsService();
  const userRole = useAppSelector((state) => state.user.role_name);
  const selectedOrgId: any = useAppSelector((state) => state.filteredDevices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.filteredDevices.selectedServiceId);
  const selectedGroup = useAppSelector((state) => state.groups.selectedGroup);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  const PencilIconStyle: ReactComponent = {
    cursor: "pointer",
    '--svg-background': buttonsColor,
    '--svg-details': accentTextColor,
    right: 15,
    bottom: 5,
    position: "absolute"
  };

  const openEditModal = () => {
    setShowEditGroupDevices(true);
    dispatch(setSelectedDevicesListActionCreator({ selectedDevices: [...selectedGroup.nodes] }));
    setBulkActions(false);
  };

  const handleDeleteGroup = async () => {
    setLoading(true);
    const res: any = await deleteGroup(selectedGroup.groupId, selectedOrgId, selectedServiceId);
    if (res) {
      setShowDeletePopup(false);
      notification.success({ message: "Group deleted successfully", description: null });
      store.dispatch(updateGroupsArrAction("delete", selectedGroup.groupId))
      dispatch(setSelectGroupActionCreator({ selectedGroup: [] }));
    };

    setLoading(false);
  };

  useEffect(() => {
    setBulkActions(false);
    setShowDeletePopup(false);
    setShowEditName(false);
  }, [selectedGroup]);

  return (
    <>
      <GroupsLeafletMap showDevice={false} interactiveMap={false} fromGroupsPage={true} showIcon={true} />
      {userRole === "owner" || userRole === "admin" ?
        <PencilIcon className="custom-icon" height={48} style={PencilIconStyle} onClick={() => setBulkActions(!bulkActions)} />
      :
        <></>
      }
      {bulkActions && (
        <PopupContainer>
          <span onClick={() => {setShowEditName(true); setShowDeletePopup(false); setBulkActions(false)}} id="on" className="bulk-actions-container__action">
            {t("editGroupName")}
          </span>
          <span onClick={openEditModal} id="off" className="bulk-actions-container__action">
            {t("addRemoveDevices")}
          </span>
          <span onClick={() => {setShowEditName(false); setShowDeletePopup(true); setBulkActions(false)}} id="dimming" className="bulk-actions-container__action">
            {t("deleteGroup")}
          </span>
        </PopupContainer>
      )}
      {showEditName &&
        <EditGroupName showEditName={showEditName} setShowEditName={setShowEditName} selectedGroup={selectedGroup} setLoading={setLoading} />
      }
      {showEditGroupDevices && (
        <EditGroupDevices showEditGroupDevices={showEditGroupDevices} setShowEditGroupDevices={setShowEditGroupDevices} />
      )}
      {showDeletePopup && (
        <div style={{ display: "flex",  position: "fixed", alignItems: "center", justifyContent: "center", top: "50%", height: "100vh", left: "50%", zIndex: 4,
          transform: "translate(-50%, -50%)" }}
        >
          <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "14px", padding: "30px" }}>
            <Text>{t("deleteGroupPopUpTitle")}</Text>
            <div style={{ display: "flex", marginTop: "16px", justifyContent: "space-between", paddingLeft: "10px", paddingRight: "10px" }}>
              <button style={{ padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {handleDeleteGroup()}}>
                {t("Accept")}
              </button>
              <button style={{ padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowDeletePopup(false)}}>
                {t("Cancel")}
              </button>
            </div>
          </div>
        </div>
      )} 
    </>
  );
};
