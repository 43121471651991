import styled from "styled-components";

interface NavLinkProps {
  accentButtonsColor: string;
}

export const InputContainer = styled.input`
  width: 100%;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  &:focus {
    border: 1px solid #ccc;
    border-radius: 4px !important;
  }
`;

export const Icon = styled.span`
  position: absolute;
  top: 0;
  right: 4px;
  bottom: 0;
  background-color: transparent;
  padding: 0;
  border: none;
  z-index: 10;
`;

export const NavLink = styled.a<NavLinkProps>`
  font-size: 14px !important;
  border-radius: 0 !important;
  border-bottom: 2px solid ${props => props.accentButtonsColor + 30} !important;
  padding: 16px 32px 16px 16px !important;
  &.active {
    background-color: transparent !important;
    font-weight: 700 !important;
    border-bottom: 2px solid ${props => props.accentButtonsColor} !important;
    border-radius: 0 !important;
  }
`;

export const Structure = styled.div`
  max-height: 602px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 3px;
  }
`;
