import { createSlice } from "@reduxjs/toolkit";
import { Graphics } from "../../../types/interfaces";

const initialState: Graphics = {
  backgroundDashboard: false,
  backgroundStrategies: false,
  backgroundErrorPopUp: false,
  organizationsPopUp: false,
};

const graphicsSlice = createSlice({
  name: "graphics",
  initialState: initialState,
  reducers: {
    changeBackgroundDashboard: (previousState: Graphics) => ({
      ...previousState,
      backgroundDashboard: !previousState.backgroundDashboard,
    }),

    changeBackgroundStrategies: (previousState: Graphics) => ({
      ...previousState,
      backgroundStrategies: !previousState.backgroundStrategies,
    }),

    turnBackgroundStrategiesFalse: (previousState: Graphics) => ({
      ...previousState,
      backgroundStrategies: false,
    }),

    turnBackgroundErrorPopUpTrue: (previousState: Graphics) => ({
      ...previousState,
      backgroundErrorPopUp: true,
    }),

    turnBackgroundErrorPopUpFalse: (previousState: Graphics) => ({
      ...previousState,
      backgroundErrorPopUp: false,
    }),

    changeOrgsPopUp: (previousState: Graphics) => ({
      ...previousState,
      organizationsPopUp: !previousState.organizationsPopUp,
    }),

    /* changeOrgsPopUp: (previousState: Graphics, action: any) => ({
      ...previousState,
      ...action.payload,
      background: previousState.background,
      organizationsPopUp: action.payload
    }), */

    turnAllFalse: (previousState: Graphics) => ({
      ...previousState,
      backgroundDashboard: false,
      backgroundStrategies: false,
      backgroundErrorPopUp: false,
      organizationsPopUp: false,
    }),
  },
});

export const graphicsReducer = graphicsSlice.reducer;

export const {
  changeBackgroundDashboard: changeBackgroundDashboardActionCreator,
  changeBackgroundStrategies: changeBackgroundStrategiesActionCreator,
  turnBackgroundStrategiesFalse: turnBackgroundStrategiesFalseActionCreator,
  turnBackgroundErrorPopUpTrue: turnBackgroundErrorPopUpTrueActionCreator,
  turnBackgroundErrorPopUpFalse: turnBackgroundErrorPopUpFalseActionCreator,
  changeOrgsPopUp: changeOrgsPopUpActionCreator,
  turnAllFalse: turnAllFalseActionCreator,
} = graphicsSlice.actions;
