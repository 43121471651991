import styled from "styled-components";

export const OrganizationWrapper = styled.div`
  max-width: 60%;
  width: 100%;
  margin: 0 auto;
  /* margin-top: 50px; */
  /* devolver a 50 cuando haya más widgets? */
  margin-top: 30px;
  .org-subheading {
    display: flex;
    opacity: 0.7;
    gap: 35px;
    img {
      filter: brightness(0);
      margin-right: 10px;
    }
    &_item {
      display: flex;
      align-items: center;
    }
  }
  .upload-section {
    background: #f8fcff;
    border-radius: 5px;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  .palette-wrapper {
    .preview {
      flex-grow: 1;
      height: 220px;
      max-width: 50%;
      border: 1px solid #a3aed0;
      border-radius: 8px;
      .indicator {
        background-color: #a3aed0;
        margin: 0 3px;
        color: #ffffff;
        padding: 0 6px;
        border-radius: 100%;
      }
      .vr {
        height: 100%;
        color: #a3aed0 !important;
        opacity: 1;
      }
      .line {
        height: 20px;
        width: 70px;
        background-color: #a3aed0;
        border-radius: 10px;
      }
      .lineBlank {
        height: 20px;
        width: 70px;
        border: 1px solid #a3aed0;
        border-radius: 10px;
      }
    }
  }
  .default-colors {
    height: 24px;
    width: 24px;
  }
`;

export const DefaultColor = styled.div`
  span {
    background-color: ${(props) => props.color};
    height: 24px;
    width: 24px;
    border-radius: 100%;
    display: block;
  }
  cursor: pointer;
  padding: 2px;
  border-radius: 100%;
  border: 1px solid #a3aed0;
  &:hover,
  &.active {
    box-shadow: 0 0 5px 2px rgba(163, 174, 208, 0.5);
  }
`;
