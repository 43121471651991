import { useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useAppSelector } from "../../redux/hooks";
import { useProfileService } from "../../services/profileService";
import InputForProfile from "../Input/InputForProfile";
import { validPassword } from "../../utils/regex";
import { CardStyled } from "./ProfilePageStyled";
import { ButtonOutlined } from "../../styles/shared-styles/buttons-sizes";
import { Text } from "../../styles/shared-styles/text-sizes";
import mail from "../../assets/new_icons/mail.svg";

interface PasswordInfoProps {}

export const PasswordInformation = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorBlankInput, setErrorBlankInput] = useState<boolean>(false);
  const [errorSamePassword, setErrorSamePassword] = useState<boolean>(false);
  const [errorInvalidPassword, setErrorInvalidPassword] = useState<boolean>(false);
  const [errorNewPassword, setErrorNewPassword] = useState<boolean>(false);
  const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { updatePassword } = useProfileService();
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const handleUpdatePassword = async () => {
    if (!validPassword.test(newPassword)) {
      setErrorInvalidPassword(true);
      return;
    }
    setIsLoading(true);
    setErrorUpdate(false);
    setSuccess(false);

    try {
      const token:any = Cookies.get("token");
      const response = await updatePassword(token, currentPassword, newPassword, confirmPassword);
      setIsLoading(false);

      if (response) {
        setSuccess(true);
      } else {
        setErrorUpdate(true);
      }

      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    } catch (error: any) {
      setIsLoading(false);
      setErrorUpdate(true);
    }
  };

  return (
    <>
      <CardStyled>
        <Text textType="subTitle" fontWeight="700" color={`background: ${mainTextColor}`}>{t("password")}</Text>
        <div className="row" style={{ marginBottom: "6.5px" }}>
          <div className="col-6">
            <Text marginBottom="8px">{t("Current Password")}*</Text>
            <InputForProfile
              value={currentPassword}
              suffix_icon={mail}
              inputType="password"
              error={!currentPassword && (errorBlankInput || errorSamePassword) || (currentPassword && (errorBlankInput || errorSamePassword))}
              marginBottom="small"
              onChange={(e) => {setCurrentPassword(e.target.value); setErrorBlankInput(false); setErrorSamePassword(false)}}
            />
          </div>
        </div>
        <div className="row" style={{ marginBottom: "16px" }}>
          <div className="col-6">
            <Text marginBottom="8px">{t("New Password")}*</Text>
            <InputForProfile
              value={newPassword}
              suffix_icon={mail}
              inputType="password"
              error={!newPassword && (errorBlankInput || errorSamePassword || errorNewPassword) || (newPassword && (errorBlankInput || errorSamePassword || errorNewPassword))}
              marginBottom="small"
              onChange={(e) => {setNewPassword(e.target.value); setErrorBlankInput(false); setErrorSamePassword(false); setErrorInvalidPassword(false); setErrorNewPassword(false)}}
            />
          </div>
          <div className="col-6">
            <Text marginBottom="8px">{t("Confirm Password")}*</Text>
            <InputForProfile
              value={confirmPassword}
              suffix_icon={mail}
              inputType="password"
              error={!confirmPassword && (errorBlankInput || errorNewPassword) || (confirmPassword && (errorBlankInput || errorNewPassword))}
              marginBottom="small"
              onChange={(e) => {setConfirmPassword(e.target.value); setErrorBlankInput(false); setErrorNewPassword(false)}}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "16px" }}>
          {isLoading ?
            <ButtonOutlined type="button" disabled style={{ border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }}>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                {t("loading...")}
            </ButtonOutlined>
          :
          <ButtonOutlined style={{ border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }} onClick={() => {
            if (currentPassword && !(/^\s/.test(currentPassword)) && newPassword && !(/^\s/.test(newPassword)) && confirmPassword && !(/^\s/.test(confirmPassword))) {
              if (currentPassword === newPassword) {
                setErrorSamePassword(true)
              } else if (newPassword !== confirmPassword) {
                setErrorNewPassword(true)
              } else {
                handleUpdatePassword()
              }             
            } else if (!currentPassword || !newPassword || !confirmPassword || (/^\s/.test(currentPassword)) || (/^\s/.test(newPassword)) || (/^\s/.test(confirmPassword))) {
              setErrorBlankInput(true)
            }
            }}>
              {t("Update Password")}
            </ButtonOutlined>
          }
        </div>
        <div style={{ height: '10px', textAlign: "right", alignSelf: "end" }}>
          {errorBlankInput ?
            <Text marginBottom="0px" color="red">{t("errorBlankInput")}</Text>
          :
            errorSamePassword ?
              <Text marginBottom="0px" color="red">{t("errorSamePassword")}</Text>
            :
              errorInvalidPassword ?
                <Text marginBottom="0px" color="red">{t("errorInvalidPassword")}</Text>
              :
                errorNewPassword ?
                  <Text marginBottom="0px" color="red">{t("errorNewPassword")}</Text>
                :
                  errorUpdate ?
                    <Text marginBottom="0px" color="red">{t("errorUpdating")}</Text>
                  :
                    success ?
                      <Text marginBottom="0px" color="green">{t("updated")}</Text>
                    :
                      <></>
          }
        </div>
      </CardStyled>
    </>
  );
};
