import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { useTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { store } from '../../../redux/store';
import co2 from "../../../assets/new_icons/pdfImages/Co2.png";
import currency from "../../../assets/new_icons/pdfImages/Currency.png";
import energyEco from "../../../assets/new_icons/pdfImages/Energy_eco.png";
import logo from "../../../assets/new_icons/pdfImages/Smartec-Report-Logo.png";
import newLogo from "../../../assets/new_icons/pdfImages/Smartec-Full-Logo.png";
import newIcon from "../../../assets/new_icons/pdfImages/Smartec-Icon-Logo.png";
import noAlarm from "../../../assets/new_icons/pdfImages/noAlarm.png";
import trama from "../../../assets/new_icons/trama_forms.png";

export const PdfReact = () => {
  const values = store.getState();
  const { t } = useTranslation();

  const styles = StyleSheet.create({
    page: {
      position: 'relative',
      backgroundColor: '#FFFFFF',
      padding: 0,
      width: '100%',
      height: '100%',
    },
    backgroundContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
    },
    backgroundLines: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
    },
    headerContainer: {
      position: 'relative',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    logo: {
      marginBottom: 10,
      width: 260,
      height: 'auto',
      objectFit: 'contain',
    },
    subtitle: {
      fontSize: 14,
      textAlign: 'center',
      color: '#666',
      marginTop: 10,
    },
    footer: {
      position: 'absolute',
      bottom: 30,
      right: 30,
      fontSize: 12,
      color: '#2B2B2B',
      textAlign: 'right',
    },
    page2: {
      position: 'relative',
      backgroundColor: '#FFFFFF',
      width: '100%',
      height: '100%',
    },
    sidebar: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 30,
      height: '100%',
      backgroundColor: values.selectedOrganization.buttonsColor, // Color amarillo
    },
    content: {
      marginLeft: 50,
      paddingTop: 30,
      paddingRight: 80,
      paddingBottom: 30,
      paddingLeft: 0,
      width: '100%',
      height: '100%',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 20,
    },
    dateRange: {
      fontSize: 10,
      textAlign: 'left',
      marginBottom: 10,
      color: 'rgba(0, 0, 0, 0.5)'
    },
    logo2: {
      /* width: 104, */
      width: "auto",
      height: 30,
      marginTop: "-10px",
      right: 10,
    },
    title: {
      fontSize: 24,
      marginTop: 10,
      marginBottom: 26,
      fontWeight: 300,
    },
    list: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 8,
    },
    listItemText: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom: 8,
    },
    listText: {
      fontSize: 12,
      marginBottom: 4
    },
    lineText: {
      borderBottomWidth: 0.5,
      borderBottomColor: 'black',
      width: 470,
    },
    listItemPage: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      marginBottom: 8,
    },
    listPage: {
      fontSize: 12,
      justifySelf: "end",
      marginBottom: 4
    },
    linePage: {
      borderBottomWidth: 0.5,
      borderBottomColor: 'black',
      width: 26,
    },
    pageNumberContainer: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start', // Alinea el número y el recuadro amarillo verticalmente
      justifyContent: 'flex-end', // Alinea ambos elementos a la derecha
      gap: "2px",
      bottom: 15,
      right: 80,
    },
    pageNumber: {
      fontSize: 10,
      textAlign: 'right',
      color: '#666',
    },
    yellowBox: {
      width: 4,
      height: 9.6,
      backgroundColor: values.selectedOrganization.buttonsColor,
    },
    footerEnd: {
      display: "flex",
      flexDirection: "column",
      position: 'absolute',
      bottom: 30,
      left: 30,
      textAlign: 'left',
    },
  });

  return (
    <Document>
      <Page size="A4" style={{ position: 'relative', backgroundColor: "#FFFFFF", padding: 0, width: '100%', height: '100%' }}>
        {/* Contenedor de la imagen de fondo */}
        {/* <View style={styles.backgroundContainer}>
          <Image style={{ width: '100%', height: '100%', opacity: 0.65 }} src={trama} />
        </View>
        <View style={styles.headerContainer}>
          <Image style={styles.logo} src={logo} />
          <Text style={styles.subtitle}>{t("consumReport").toUpperCase()}</Text>
        </View> */}
        <View style={styles.headerContainer}>
          <Image style={styles.logo} src={newLogo} />
        </View>
        {/* Footer */}
        <Text style={styles.footer}>Salvi Lighting{"\n"}{values.pdfReport.startDate} - {values.pdfReport.endDate}</Text>
      </Page>




      {/* PÁG 2 */}
      <Page size="A4" style={styles.page2}>
        {/* <View style={styles.sidebar}>
          <Image style={{ width: "100%", height: "100%", objectFit: "cover", opacity: 0.65 }} src={trama} />
        </View> */}
        <View style={styles.sidebar} />
        <View style={styles.content}>
          <View style={styles.header}>
            <Text style={styles.dateRange}>Salvi Lighting{"\n"}{values.pdfReport.startDate} - {values.pdfReport.endDate}</Text>
            {/* <Image src={logo} style={styles.logo2} /> */}
            <Image src={newIcon} style={styles.logo2} />
          </View>
          <Text style={styles.title}>{t("content").toUpperCase()}</Text>
          <View style={styles.list}>
            <View style={styles.listItemText}>
              <Text style={styles.listText}>{t("generalInfo")}</Text>
              <View style={styles.lineText} />
            </View>
            <View style={styles.listItemPage}>
              <Text style={styles.listPage}>03</Text>
              <View style={styles.linePage} />
            </View>
          </View>
          <View style={styles.list}>
            <View style={styles.listItemText}>
              <Text style={styles.listText}>{t("standardLuminaires")}</Text>
              <View style={styles.lineText} />
            </View>
            <View style={styles.listItemPage}>
              <Text style={styles.listPage}>04</Text>
              <View style={styles.linePage} />
            </View>
          </View>
          <View style={styles.list}>
            <View style={styles.listItemText}>
              <Text style={styles.listText}>{t("luminairesList")}</Text>
              <View style={styles.lineText} />
            </View>
            <View style={styles.listItemPage}>
              <Text style={styles.listPage}>05</Text>
              <View style={styles.linePage} />
            </View>
          </View>
          {/* Número de página */}
          <View style={styles.pageNumberContainer}>
            <Text style={styles.pageNumber}>02</Text>
            <View style={styles.yellowBox} />
          </View>
        </View>
      </Page>




      {/* PÁG 3 */}
      <Page size="A4" style={styles.page2}>
        <View style={styles.sidebar} />
        <View style={styles.content}>
          <View style={styles.header}>
            <Text style={styles.dateRange}>Salvi Lighting{"\n"}{values.pdfReport.startDate} - {values.pdfReport.endDate}</Text>
            <Image src={newIcon} style={styles.logo2} />
          </View>
          <Text style={{ fontSize: 24, marginTop: 20, marginBottom: 36, fontWeight: 300 }}>{t("generalInfo").toUpperCase()}</Text>
          <Text style={{ alignSelf: "flex-start", fontWeight: 700, fontSize: 12, marginBottom: "10px" }}>{t("Devices")}</Text>
          {/* <View style={{ display: "flex", padding: "24px", height: "45%", borderRadius: "16px", minHeight: "200px", borderWidth: 1, borderColor: '#F5F5F5', backgroundColor: '#FFFFFF' }}>
            <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
              <View style={{ display: "flex", justifyContent: "center", marginLeft: "-10px" }}>
                <View style={{ display: "flex", flexDirection: "column", alignItems: "center", top: 72, marginLeft: 78, position: "absolute" }}>
                  <Text style={{ fontSize: "12px", color: "#99B2C6", fontWeight: 500 }}>{t("total")}</Text>
                  <Text color="#475569" style={{ fontSize: "20px", fontWeight: 700 }}>
                    {values.reports.generalTotal}
                  </Text>
                </View>
                <View style={{ zIndex: 1, width: "180px", height: "180px", alignSelf: "center" }}>
                  {values.reports.imgDevices && (
                    <View style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Image src={values.reports.imgDevices} style={{ width: "180px", height: "180px" }} />
                    </View>
                  )}
                </View>
              </View>
              <View style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "60%", height: "100%", marginRight: "-10px" }}>
                {values.reports.generalStandard &&
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", flexGrow: 1 }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                      <View style={{ borderRadius: "10px", border: "2px solid #1B2559", width: "11px", height: "11px", marginBottom: "1px" }} />
                      <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("standardLuminaires")}</Text>
                    </View>
                    <Text style={{ fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.generalStandard}</Text>
                  </View>
                }
                {values.reports.generalSolar &&
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", flexGrow: 1 }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                      <View style={{ borderRadius: "10px", border: "2px solid #FFC107", width: "11px", height: "11px", marginBottom: "1px" }} />
                      <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("solarLuminaires")}</Text>
                    </View>
                    <Text style={{ fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.generalSolar}</Text>
                  </View>
                }
                {values.reports.generalHybrid &&
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", flexGrow: 1 }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                      <View style={{ borderRadius: "10px", border: "2px solid #45D0EE", width: "11px", height: "11px", marginBottom: "1px" }} />
                      <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("hybridLuminaires")}</Text>
                    </View>
                    <Text style={{ fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.generalHybrid}</Text>
                  </View>
                }
                {values.reports.generalGateways &&
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", flexGrow: 1 }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                      <View style={{ borderRadius: "10px", border: "2px solid #17181A60", width: "11px", height: "11px", marginBottom: "1px" }} />
                      <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("Gateways")}</Text>
                    </View>
                    <Text style={{ fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.generalGateways}</Text>
                  </View>
                }
                {values.reports.generalSwitchboards &&
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", flexGrow: 1 }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                      <View style={{ borderRadius: "10px", border: "2px solid #A3AED0", width: "11px", height: "11px", marginBottom: "1px" }} />
                      <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("switchboards")}</Text>
                    </View>
                    <Text style={{ fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.generalSwitchboards}</Text>
                  </View>
                }
                {values.reports.generalRgb &&
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", flexGrow: 1 }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                      <View style={{ borderRadius: "10px", border: "2px solid #F1F4F9", width: "11px", height: "11px", marginBottom: "1px" }} />
                      <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("rgbLuminaires")}</Text>
                    </View>
                    <Text style={{ fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.generalRgb}</Text>
                  </View>
                }
              </View>
            </View>
          </View> */}
          <View style={{ display: "flex", flexDirection: "row", padding: "24px", height: "45%", borderRadius: "16px", minHeight: "200px", borderWidth: 1, borderColor: '#F5F5F5', backgroundColor: '#FFFFFF', justifyContent: "space-between", alignItems: "center" }}>
            <View style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", width: "40%" }}>
              <View style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "absolute", top: 72, textAlign: "center" }}>
                <Text style={{ fontSize: "12px", color: "#99B2C6", fontWeight: 400 }}>{t("total")}</Text>
                <Text color="#475569" style={{ fontSize: "20px", fontWeight: 700 }}>
                  {values.pdfReport.selectedDevicesStandard.length + values.pdfReport.selectedDevicesSolar.length + values.pdfReport.selectedDevicesHybrid.length +
                  values.pdfReport.selectedDevicesSmartec.length + values.pdfReport.selectedDevicesSwitch.length + values.pdfReport.selectedDevicesRgb.length}
                </Text>
              </View>
              <View style={{ zIndex: 1, width: "180px", height: "180px", alignSelf: "center" }}>
                {values.pdfReport.imgDevices && (
                  <View style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Image src={values.pdfReport.imgDevices} style={{ width: "180px", height: "180px" }} />
                  </View>
                )}
              </View>
            </View>
            <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", width: "60%", height: "100%", marginLeft: "24px" }}>
              {values.pdfReport.selectedDevicesStandard.length > 0 &&
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                    <View style={{ borderRadius: "10px", border: "2px solid #1B2559", width: "11px", height: "11px" }} />
                    <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("standardLuminaires")}</Text>
                  </View>
                  <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.pdfReport.selectedDevicesStandard.length}</Text>
                </View>
              }
              {values.pdfReport.selectedDevicesSolar.length > 0 &&
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                    <View style={{ borderRadius: "10px", border: "2px solid #FFC107", width: "11px", height: "11px" }} />
                    <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("solarLuminaires")}</Text>
                  </View>
                  <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.pdfReport.selectedDevicesSolar.length}</Text>
                </View>
              }
              {values.pdfReport.selectedDevicesHybrid.length > 0 &&
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                    <View style={{ borderRadius: "10px", border: "2px solid #45D0EE", width: "11px", height: "11px" }} />
                    <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("hybridLuminaires")}</Text>
                  </View>
                  <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.pdfReport.selectedDevicesHybrid.length}</Text>
                </View>
              }
              {values.pdfReport.selectedDevicesSmartec.length > 0 &&
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                    <View style={{ borderRadius: "10px", border: "2px solid #17181A60", width: "11px", height: "11px" }} />
                    <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("Gateways")}</Text>
                  </View>
                  <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.pdfReport.selectedDevicesSmartec.length}</Text>
                </View>
              }
              {values.pdfReport.selectedDevicesSwitch.length > 0 &&
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                    <View style={{ borderRadius: "10px", border: "2px solid #A3AED0", width: "11px", height: "11px" }} />
                    <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("switchboards")}</Text>
                  </View>
                  <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.pdfReport.selectedDevicesSwitch.length}</Text>
                </View>
              }
              {values.pdfReport.selectedDevicesRgb.length > 0 &&
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                    <View style={{ borderRadius: "10px", border: "2px solid #F1F4F9", width: "11px", height: "11px" }} />
                    <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("rgbLuminaires")}</Text>
                  </View>
                  <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.pdfReport.selectedDevicesRgb.length}</Text>
                </View>
              }
            </View>
          </View>

          <View style={{ display: "flex", flexDirection: "row", marginTop: "30px", gap: "20px" }}>
            <View style={{ display: "flex", flexDirection: "column", width: "50%", height: "100%" }}>
              <Text style={{ alignSelf: "flex-start", fontWeight: 700, fontSize: 12, marginBottom: "10px" }}>{t("Alarms")}</Text>
              <View style={{ display: "flex", flexDirection: "column", padding: "24px", borderRadius: "16px", height: "75%", borderWidth: 1, borderColor: '#F5F5F5', backgroundColor: '#FFFFFF', justifyContent: "space-between" }}>
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
                  <View style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "absolute", top: 72, textAlign: "center" }}>
                    {values.pdfReport.resAlarmsTotal !== 0 &&
                      <Text style={{ fontSize: "12px", color: "#99B2C6", fontWeight: 400 }}>{t("total")}</Text>
                    }
                    {values.pdfReport.resAlarmsTotal && values.pdfReport.resAlarmsTotal !== 0 &&
                      <Text style={{ fontSize: "20px", color: "#56C568", fontWeight: 700 }}>
                        {((values.pdfReport.resAlarmsSolved / values.pdfReport.resAlarmsTotal) * 100).toFixed(0) + "%"}
                      </Text>
                    }
                  </View>
                  <View style={{ zIndex: 1, width: "180px", height: "180px", alignSelf: "center" }}>
                    {values.pdfReport.resAlarmsTotal === 0 ?
                      <View style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Image src={noAlarm} style={{ marginTop: "70px" }} />
                      </View>
                    :
                      values.pdfReport.imgAlarms && (
                        <View style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <Image src={values.pdfReport.imgAlarms} style={{ width: "180px", height: "180px" }} />
                        </View>
                      )
                    }
                  </View>
                </View>
                <View style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", justifyContent: "space-between", gap: "10px" }}>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                      <View style={{ borderRadius: "10px", border: "2px solid #56C568", width: "11px", height: "11px", marginBottom: "1px" }} />
                      <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("solved")}</Text>
                    </View>
                    <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.pdfReport.resAlarmsSolved}</Text>
                  </View>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                      <View style={{ borderRadius: "10px", border: "2px solid #1B2559", width: "11px", height: "11px", marginBottom: "1px" }} />
                      <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("pending")}</Text>
                    </View>
                    <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.pdfReport.resAlarmsPending}</Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ display: "flex", flexDirection: "column", width: "50%", height: "100%" }}>
              <Text style={{ alignSelf: "flex-start", fontWeight: 700, fontSize: 12, marginBottom: "10px" }}>{t("Users")}</Text>
              <View style={{ display: "flex", flexDirection: "column", padding: "24px", borderRadius: "16px", height: "75%", borderWidth: 1, borderColor: '#F5F5F5', backgroundColor: '#FFFFFF', justifyContent: "space-between" }}>
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative" }}>
                  <View style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "absolute", top: 72, textAlign: "center" }}>
                    <Text style={{ fontSize: "12px", color: "#99B2C6", fontWeight: 400 }}>{t("total")}</Text>
                    <Text color="#475569" style={{ fontSize: "20px", fontWeight: 700 }}>
                      {values.reports.usersTotal}
                    </Text>
                  </View>
                  <View style={{ zIndex: 1, width: "180px", height: "180px", alignSelf: "center" }}>
                    {values.pdfReport.imgUsers && (
                      <View style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Image src={values.pdfReport.imgUsers} style={{ width: "180px", height: "180px" }} />
                      </View>
                    )}
                  </View>
                </View>
                {values.user.role_name === "owner" ?
                  <View style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", justifyContent: "space-between", gap: "10px" }}>
                 {/*  <View style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "20px", paddingBottom: "8px", gap: "10px" }}>  */}
                    <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}>
                      <View style={{ borderRadius: "10px", border: "2px solid #1B2559", width: "11px", height: "11px", marginBottom: "1px" }} />
                      <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("owner")}</Text>
                      <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.usersOwner}</Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}>
                        <View style={{ borderRadius: "10px", border: "2px solid #FBBC05", width: "11px", height: "11px", marginBottom: "1px" }} />
                        <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("admin")}</Text>
                        <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.usersAdmin}</Text>
                      </View>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}>
                        <View style={{ borderRadius: "10px", border: "2px solid #45D0EE", width: "11px", height: "11px", marginBottom: "1px" }} />
                        <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("observer")}</Text>
                        <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.usersObserver}</Text>
                      </View>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", gap: "16px" }}>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}>
                        <View style={{ borderRadius: "10px", border: "2px solid #444444", width: "11px", height: "11px", marginBottom: "1px" }} />
                        <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("maintenance")}</Text>
                        <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.usersMaintenance}</Text>
                      </View>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "8px" }}>
                        <View style={{ borderRadius: "10px", border: "2px solid #A3AED0", width: "11px", height: "11px", marginBottom: "1px" }} />
                        <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("external")}</Text>
                        <Text style={{ fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.usersExternal}</Text>
                      </View>
                    </View>
                  </View>
                :
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between", gap: "10px" }}>
                  {/* <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "20px", paddingBottom: "8px", gap: "14px" }}> */}
                    <View style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%", marginLeft: "-10px" }}>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", }}>
                        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                          <View style={{ borderRadius: "10px", border: "2px solid #FBBC05", width: "11px", height: "11px", marginBottom: "1px" }} />
                          <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("admin")}</Text>
                        </View>
                        <Text style={{ alignSelf: "flex-end", fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.usersAdmin}</Text>
                      </View>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                          <View style={{ borderRadius: "10px", border: "2px solid #45D0EE", width: "11px", height: "11px", marginBottom: "1px" }} />
                          <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("observer")}</Text>
                        </View>
                        <Text style={{ alignSelf: "flex-end", fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.usersObserver}</Text>
                      </View>
                    </View>
                    <View style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%", marginRight: "-10px" }}>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", }}>
                        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                          <View style={{ borderRadius: "10px", border: "2px solid #444444", width: "11px", height: "11px", marginBottom: "1px" }} />
                          <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("maintenance")}</Text>
                        </View>
                        <Text style={{ alignSelf: "flex-end", fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.usersMaintenance}</Text>
                      </View>
                      <View style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px" }}>
                          <View style={{ borderRadius: "10px", border: "2px solid #A3AED0", width: "11px", height: "11px", marginBottom: "1px" }} />
                          <Text style={{ fontSize: "10px", fontWeight: 400, color: "#99B2C6" }}>{t("external")}</Text>
                        </View>
                        <Text style={{ alignSelf: "flex-end", fontSize: "10px", fontWeight: 700, color: "#99B2C6" }}>{values.reports.usersExternal}</Text>
                      </View>
                    </View>
                  </View>
                }                
              </View>
            </View>
          </View>
          
          {/* Número de página */}
          <View style={styles.pageNumberContainer}>
            <Text style={styles.pageNumber}>03</Text>
            <View style={styles.yellowBox} />
          </View>
        </View>
      </Page>




      {/* PÁG 4 */}
      <Page size="A4" style={styles.page2}>
        <View style={styles.sidebar} />
        <View style={styles.content}>
          <View style={styles.header}>
            <Text style={styles.dateRange}>Salvi Lighting{"\n"}{values.pdfReport.startDate} - {values.pdfReport.endDate}</Text>
            <Image src={newIcon} style={styles.logo2} />
          </View>
          <Text style={{ fontSize: 24, marginTop: 10, marginBottom: 36, fontWeight: 300 }}>{t("standardLuminaires").toUpperCase()}</Text>
          <Text style={{ alignSelf: "flex-start", fontWeight: 700, fontSize: 12, marginBottom: "5.5px", marginTop: "-10px" }}>{t("Devices")}: {values.pdfReport.selectedDevicesStandard.length + values.pdfReport.selectedDevicesSolar.length + values.pdfReport.selectedDevicesHybrid.length +
            values.pdfReport.selectedDevicesSmartec.length + values.pdfReport.selectedDevicesSwitch.length + values.pdfReport.selectedDevicesRgb.length} | {t("from")} {values.pdfReport.startDate} {t("to").toLowerCase()} {values.pdfReport.endDate}
          </Text>
          <View style={{ display: "flex", flexDirection: "column", gap: "30px", flexWrap: "wrap" }}>
            <View style={{ display: "flex", flexDirection: "column", padding: "22px", borderRadius: "16px", borderWidth: 1, borderColor: '#F5F5F5', backgroundColor: '#FFFFFF', justifyContent: "space-between", width: "246px", height: "218px" }}>
              <Text style={{ display: "flex", alignSelf: "flex-start", fontSize: "18px", fontWeight: 700 }}>{t("energyAbr")}</Text>
              <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                <Text style={{ fontSize: "28px", fontWeight: 700, marginBottom: "0px", color: values.selectedOrganization.supportTextColor }}>
                  {values.pdfReport.resStandardTotalEnergy !== 0 && values.pdfReport.resStandardTotalEnergy !== undefined ? (values.pdfReport.resStandardTotalEnergy / 1000)?.toFixed(2) : 0}
                </Text>
                <Text style={{ fontSize: "14px", marginBottom: "0px", color: values.selectedOrganization.supportTextColor }}>{t("consumed")}</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <Text style={{ fontSize: "28px", marginBottom: "0px", fontWeight: 700, color: "#56C568" }}>
                    {values.pdfReport.resStandardTotalSavings !== 0 && values.pdfReport.resStandardTotalSavings !== undefined ? (values.pdfReport.resStandardTotalSavings / 1000)?.toFixed(2) : 0}
                  </Text>
                  <Text style={{ fontSize: "14px", marginBottom: "0px", color: "#56C568" }}>{t("saved")}</Text>
                </View>
                <Image src={energyEco} style={{ width: "30px", height: "30px", paddingLeft: "1px", paddingTop: "1px", alignSelf: "flex-end" }} />
              </View>
            </View>

            <View style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
              <View style={{ display: "flex", flexDirection: "column", padding: "22px", borderRadius: "16px", borderWidth: 1, borderColor: '#F5F5F5', backgroundColor: '#FFFFFF', justifyContent: "space-between", width: "246px", height: "218px" }}>
                <View style={{ display: "flex", flexDirection: "column", alignSelf: "flex-start" }}>
                  <Text style={{ fontSize: "18px", fontWeight: 700 }}>{t("cost")} (-)</Text>
                  <Text style={{ fontSize: "12px", fontWeight: 400, marginTop: "2px" }}>{values.selectedOrganization.price} / kW</Text>
                </View>
                {/* <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                  <Text style={{ fontSize: "28px", fontWeight: 700, marginBottom: "0px", color: values.selectedOrganization.supportTextColor }}>
                    {values.pdfReport.resStandardTotalEnergy !== 0 && values.pdfReport.resStandardTotalEnergy !== undefined ? (values.pdfReport.resStandardTotalEnergy / 1000 * values.selectedOrganization.price).toFixed(2) : 0}
                  </Text>
                  <Text style={{ fontSize: "14px", marginBottom: "0px", color: values.selectedOrganization.supportTextColor }}>{t("consumed")}</Text>
                </View> */}
                <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                  <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <Text style={{ fontSize: "28px", marginBottom: "0px", fontWeight: 700, color: "#56C568" }}>
                      {values.pdfReport.resStandardTotalSavings !== 0 && values.pdfReport.resStandardTotalSavings !== undefined ? (values.pdfReport.resStandardTotalSavings / 1000 * values.selectedOrganization.price).toFixed(2) : 0}
                    </Text>
                    <Text style={{ fontSize: "14px", marginBottom: "0px", color: "#56C568" }}>{t("saved")}</Text>
                  </View>
                  <Image src={currency} style={{ width: "30px", height: "30px", alignSelf: "flex-end" }} />
                </View>
              </View>

              <View style={{ display: "flex", flexDirection: "column", padding: "22px", borderRadius: "16px", borderWidth: 1, borderColor: '#F5F5F5', backgroundColor: '#FFFFFF', justifyContent: "space-between", width: "246px", height: "218px" }}>
                <View style={{ display: "flex", flexDirection: "column", alignSelf: "flex-start" }}>
                  <Text style={{fontSize: "18px", fontWeight: 700 }}>{t("co2Abr")}</Text>
                  <Text style={{ fontSize: "12px", fontWeight: 400, marginTop: "2px" }}>{values.selectedOrganization.co2} / Kg</Text>
                </View>
                {/* <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                  <Text style={{ fontSize: "28px", fontWeight: 700, marginBottom: "0px", color: values.selectedOrganization.supportTextColor }}>
                    {values.pdfReport.resStandardTotalEnergy !== 0 && values.pdfReport.resStandardTotalEnergy !== undefined ? (values.pdfReport.resStandardTotalEnergy / 1000 * values.selectedOrganization.co2).toFixed(2) : 0}
                  </Text>
                  <Text style={{ fontSize: "14px", marginBottom: "0px", color: values.selectedOrganization.supportTextColor }}>{t("consumed")}</Text>
                </View> */}
                <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                  <View style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    <Text style={{ fontSize: "28px", marginBottom: "0px", fontWeight: 700, color: "#56C568" }}>
                      {values.pdfReport.resStandardTotalSavings !== 0 && values.pdfReport.resStandardTotalSavings !== undefined ? (values.pdfReport.resStandardTotalSavings / 1000 * values.selectedOrganization.co2).toFixed(2) : 0}
                    </Text>
                    <Text style={{ fontSize: "14px", marginBottom: "0px", color: "#56C568" }}>{t("saved")}</Text>
                  </View>
                  <Image src={co2} style={{ width: "30px", height: "30px", paddingRight: "1px", alignSelf: "flex-end" }} />
                </View>
              </View>
            </View>
          </View>
          
          {/* Número de página */}
          <View style={styles.pageNumberContainer}>
            <Text style={styles.pageNumber}>04</Text>
            <View style={styles.yellowBox} />
          </View>
        </View>
      </Page>




      {/* PÁG 5 */}
      <Page size="A4" style={{ position: 'relative', backgroundColor: '#FFFFFF', width: '100%', height: '100%' }}>
        <View style={styles.sidebar} />
        <View style={styles.content}>
          <View style={styles.header}>
            <Text style={styles.dateRange}>Salvi Lighting{"\n"}{values.pdfReport.startDate} - {values.pdfReport.endDate}</Text>
            <Image src={newIcon} style={styles.logo2} />
          </View>
          <Text style={{ fontSize: 24, marginTop: 10, marginBottom: 36, fontWeight: 300 }}>{t("luminairesList").toUpperCase()}</Text>
          <View style={{ display: "flex", flexDirection: "column" }}>
            {values.pdfReport.selectedDevicesStandard.map((node:any, index:number) => (
              <Text key={index} style={{ fontWeight: 700, fontSize: 12, marginBottom: "4px" }}>{node}</Text> 
            ))}
          </View>
          
          {/* Número de página */}
          <View style={styles.pageNumberContainer}>
            <Text style={styles.pageNumber}>05</Text>
            <View style={styles.yellowBox} />
          </View>
        </View>
      </Page>




      {/* PÁG 6 */}
      <Page size="A4" style={styles.page}>
        <View style={{ position: 'absolute', top: 30, left: 44 }}>
          <Image src={newIcon} style={styles.logo2} />
        </View>
        <View style={styles.backgroundContainer}>
          {/* <Image style={{ width: '100%', height: '100%', opacity: 0.65, transform: 'scaleX(-1)' }} src={trama} /> */}
        </View>
        {/* Footer */}
        <View style={styles.footerEnd}>
          <Text style={{ color: "#666", fontSize: 12, marginBottom: "2px" }}>{t("reportConsum").toUpperCase()}</Text>
          <Text style={{ color: "#2B2B2B", fontSize: 12 }}>Salvi Lighting{"\n"}{values.pdfReport.startDate} - {values.pdfReport.endDate}</Text>
        </View>
      </Page>
    </Document>
  );
};

const mapStateToProps = (state: any) => ({
  datos: state.devices,
});

export default connect(mapStateToProps)(PdfReact);
