import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { Modal } from "react-bootstrap";
import { resetGroupInfoActionCreator, setSelectedDevicesListActionCreator } from "../../../redux/slices/groupsSlice/groupsSlice";
import { Details } from "../GroupsSteps/Step1/Details";
import { Devices } from "../GroupsSteps/Step2/Devices";
import { Review } from "../GroupsSteps/Step3/Review";
import { Text } from "../../../styles/shared-styles/text-sizes";
import back from "../../../assets/new_icons/back.svg";
import { ReactComponent as CheckIcon } from "../../../assets/new_icons/icon-selected.svg";
import { ReactComponent as CheckSelectedIcon } from "../../../assets/new_icons/check-circle.svg";
import smartecBackground from "../../../assets/new_icons/trama_forms_opacity.png";

interface NewGroupModalProps {
  showModal: boolean;
  setShowModal: any;
  currentRows?: any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1400px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 880px;
    width: 90%;
    background: white;
    border: 0px;
  }
`;

export const NewGroupModal = ({ showModal, setShowModal, currentRows }: NewGroupModalProps) => {
  const [isNewGroup, setIsNewGroup] = useState(true);
  const dispatch = useAppDispatch();
  const [showPreventPopup, setShowPreventPopup] = useState(false)
  const [showDetails, setShowDetails] = useState(true);
  const [showDevices, setShowDevices] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const name = useAppSelector((state) => state.groups.name);
  const selectedDevices = useAppSelector((state) => state.groups.selectedDevices);
  const nodesInArea:any = useAppSelector((state) => state.devices.nodesInArea);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const { t } = useTranslation();

  const handlePreventPopup = () => {
    setShowPreventPopup(true);
  };

  const goToDetails = async () => {
    setShowDetails(true);
    setShowDevices(false);
    setShowReview(false)
  }

  const goToDevices = async () => {
    if (name === "") {
      setErrorMessage(true);
      setShowDetails(true);
      setShowDevices(false);
      setShowReview(false);
    } else {
      if (nodesInArea && nodesInArea.length !== 0) {
        dispatch(setSelectedDevicesListActionCreator({ selectedDevices: nodesInArea }));
      } else if (currentRows && currentRows.length !== 0) {
        dispatch(setSelectedDevicesListActionCreator({ selectedDevices: currentRows }));
      }
      setErrorMessage(false);
      setShowDetails(false);
      setShowDevices(true);
      setShowReview(false);
    }
  };

  const goToReview = async () => {
    if (name === "") {
      setErrorMessage(true);
      setShowDetails(true);
      setShowDevices(false);
      setShowReview(false);
    } else if (selectedDevices.length === 0) {
      setErrorMessage(true);
      setShowDetails(false);
      setShowDevices(true);
      setShowReview(false);
    } else {
      setErrorMessage(false);
      setShowDetails(false);
      setShowDevices(false);
      setShowReview(true);
    }
  };

  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      {showPreventPopup ?
        <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "14px", padding: "30px",
          display: "flex", flexDirection: "column", position: "absolute",  alignItems: "center", justifyContent: "center",  top: "362px", left: "407px", zIndex: 3 }}
        >
          <Text>{t("Do you want to close this form? All the information will be lost")}</Text>
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {dispatch(resetGroupInfoActionCreator()); setShowModal(!showModal)}}>{t("Accept")}</button>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowPreventPopup(false)}}>{t("Cancel")}</button>
          </div>
        </div>
      :
        <></>
      }
      <Modal.Body style={{ pointerEvents: showPreventPopup ? "none" : "auto", background: "white", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px",
        paddingRight: "0px", borderTopRightRadius: "0.5rem", borderTopLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem", borderBottomLeftRadius: "0.5rem", display: "flex",
        justifyContent: "space-between"}}
      >
        <div style={{ borderRight: "1px solid rgba(71, 85, 105, 0.1)", background: "rgba(236, 236, 236, 0.92)", borderTopLeftRadius: "0.5rem", borderBottomLeftRadius: "0.5rem" }} >
          <div style={{ width: "280px", height: "100%", background: `url(${smartecBackground})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", paddingTop: "54px", paddingLeft: "40px" }}>
              <Text fontWeight="700" marginBottom="5px" color={mainTextColor} style={{fontSize: "22px" }}>{t("New group")}</Text>
              <div style={{display: "flex", flexDirection: "column", gap: "15px", marginTop: "60px"}}>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {goToDetails()}}
                >
                  {showDevices || showReview ?
                    <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  :
                    <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  }
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("generalDetails")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("generalDetailsSideMenuGroups")}</Text>
                  </div>
                </div>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {goToDevices()}}>
                  {showReview ?
                    <CheckSelectedIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  :
                    <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  }
                  <div style={{marginTop: "-3px"}}>
                    <Text color="#1B2559" fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("Devices")}</Text>
                    <Text color="#475569" fontWeight="500" style={{fontSize: "10px"}}>{t("devicesConfigurationSideMenu")}</Text>
                  </div>
                </div>
                <div style={{display: "flex", cursor: "pointer", alignItems: "flex-start", gap: "8px"}} onClick={() => {goToReview()}}>
                  <CheckIcon height={16} width={16} style={{ color: accentButtonsColor }} />
                  <div style={{marginTop: "-3px"}}>
                    <Text color={accentButtonsColor} fontWeight="700" style={{marginBottom: "2px", fontSize: "14px"}}>{t("Review")}</Text>
                    <Text color={mainTextColor} fontWeight="500" style={{fontSize: "10px"}}>{t("Verify all the information")}</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", width: "100%", padding: "8px 10px 0px 0px", flexDirection: "column", background: "#ffffff", borderTopRightRadius: "0.5rem",
          borderBottomRightRadius: "0.5rem" }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {
               showDevices ?
                <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }} 
                  onClick={() => {setShowDetails(true); setShowDevices(false); setShowReview(false)}}
                >
                  <img src={back} height={12} alt="" style={{ marginRight: "8px" }} />
                  <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToGeneralDetails")}</Text>
                </div>
              : showReview ?
                  <div style={{ paddingLeft: "20px", paddingTop: "10px", cursor: "pointer", display: "inline-flex", alignItems: "center" }}
                    /* onClick={() => {setShowDetails(false); setShowDevices(true); setShowReview(false)}} */
                    onClick={() => {setShowDetails(false); setShowDevices(true); setShowReview(false)}}
                  >
                    <img src={back} height={12} alt="" style={{ opacity: 0.5, marginRight: "8px" }} />
                    {/* <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToDevices")}</Text> */}
                    <Text color="#525252" style={{ opacity: 0.5, fontSize: "12px", marginBottom: "0px" }}>{t("backToDevices")}</Text>
                  </div>
                :
                  <div></div>
            }
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                background: "white",
                zIndex: 2
              }}
            />
          </div>
          {showDetails && 
            <Details showDevices={showDevices} goToDevices={goToDevices} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />}
         {showDevices && 
            <Devices isNewGroup={isNewGroup} goToReview={goToReview} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />}
          {showReview && 
            <Review onHide={setShowModal} isNewGroup={isNewGroup} errorMessage={errorMessage} setErrorMessage={setErrorMessage} />}
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
