import { useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { Modal } from "react-bootstrap";
import ElectricalParametersChart from "./ElectricalParametersChart";
import styled from "styled-components";
import { Text } from "../../../styles/shared-styles/text-sizes";

interface ConsumptionModalProps {
  showModal: boolean;
  setShowModal: any;
  isVisibleDali?: any;
  toggleDatasetDali?: any;
  isVisibleAllegro?: any;
  toggleDatasetAllegro?: any
  isVisibleDaliAllegro?: any;
  toggleDatasetDaliAllegro?: any;
  isVisibleShuncom?: any;
  toggleDatasetShuncom?: any;
  selectedLamp: any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1400px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 550px;
    width: 90%;
    background: white;
    border: 0px;
  }
`;

export const ElectricalParametersModal = ({ showModal, setShowModal, isVisibleDali, toggleDatasetDali, isVisibleAllegro, toggleDatasetAllegro, isVisibleDaliAllegro,
  toggleDatasetDaliAllegro, isVisibleShuncom, toggleDatasetShuncom, selectedLamp }: ConsumptionModalProps) => {
  const [showPreventPopup, setShowPreventPopup] = useState(false);

  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  
  const handlePreventPopup = () => {
    setShowModal(!showModal)
  };

  const { t } = useTranslation();

  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      <Modal.Body style={{ pointerEvents: showPreventPopup ? "none" : "auto", background: "white", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px",
        paddingRight: "0px", borderTopRightRadius: "0.5rem", borderTopLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem", borderBottomLeftRadius: "0.5rem", display: "flex",
        flexDirection: "column"}}
      >
        <div style={{ display: "flex", width: "100%", padding: "8px 10px 0px 0px", flexDirection: "column", background: "#ffffff", borderTopRightRadius: "0.5rem",
          borderTopLeftRadius: "0.5rem", justifyContent: "flex-end" }}
        >
          <Modal.Header
            closeButton
            style={{
              borderBottom: "none",
              background: "white",
              zIndex: 2
            }}
          />
        </div>
        <div style={{ paddingLeft: "50px", paddingRight: "50px", display: "flex", flexDirection: "column" }}>
          <Text fontWeight="700" marginBottom="20px" color={mainTextColor} style={{fontSize: "22px" }}>{t("electricalParamsGraph")}</Text>
          {selectedLamp.manufacturer_name === "shuncom" ?
            <div style={{ display: "flex", gap: "4px" }}>
              <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetShuncom(0)}>
                <div style={{ border: "1px solid #00C7F2", backgroundColor: isVisibleShuncom[0] ? "rgba(0, 199, 242, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("driverTemp")}</Text>
              </div>
              <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetShuncom(1)}>
                <div style={{ border: "1px solid #005882", backgroundColor: isVisibleShuncom[1] ? "rgba(100, 149, 237, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netVoltage")}</Text>
              </div>
              <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetShuncom(2)}>
                <div style={{ border: "1px solid #FFCB21", backgroundColor: isVisibleShuncom[2] ? "rgba(255, 203, 33, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("appPower")}</Text>
              </div>
              <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetShuncom(3)}>
                <div style={{ border: "1px solid #DC0124", backgroundColor: isVisibleShuncom[3] ? "rgba(220, 1, 36, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netCurrent")}</Text>
              </div>
            </div>
          :
            selectedLamp.protocol_name === "Allegro" || selectedLamp.protocol_name === "1-10" ?
              <div style={{ display: "flex", gap: "4px" }}>
                <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetAllegro(0)}>
                  <div style={{ border: "1px solid #16D764", backgroundColor: isVisibleAllegro[0] ? "rgba(22, 215, 100, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netVoltage")}</Text>
                </div>
                <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetAllegro(1)}>
                  <div style={{ border: "1px solid #005882", backgroundColor: isVisibleAllegro[1] ? "rgba(100, 149, 237, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("appPower")}</Text>
                </div>
                <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetAllegro(2)}>
                  <div style={{ border: "1px solid #FFCB21", backgroundColor: isVisibleAllegro[2] ? "rgba(255, 203, 33, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                  <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netCurrent")}</Text>
                </div>
              </div>
            :
              selectedLamp.protocol_name === "Allegro-Dali" ?
                <div style={{ display: "flex", gap: "4px" }}>
                  <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDaliAllegro(0)}>
                    <div style={{ border: "1px solid #16D764", backgroundColor: isVisibleDaliAllegro[0] ? "rgba(22, 215, 100, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                    <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("ledCurrent")}</Text>
                  </div>
                  <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDaliAllegro(1)}>
                    <div style={{ border: "1px solid #00C7F2", backgroundColor: isVisibleDaliAllegro[1] ? "rgba(0, 199, 242, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                    <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("driverTemp")}</Text>
                  </div>
                  <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDaliAllegro(2)}>
                    <div style={{ border: "1px solid #005882", backgroundColor: isVisibleDaliAllegro[2] ? "rgba(100, 149, 237, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                    <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netVoltage")}</Text>
                  </div>
                  <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDaliAllegro(3)}>
                    <div style={{ border: "1px solid #FFCB21", backgroundColor: isVisibleDaliAllegro[3] ? "rgba(255, 203, 33, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                    <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("appPower")}</Text>
                  </div>
                  <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDaliAllegro(4)}>
                    <div style={{ border: "1px solid #DC0124", backgroundColor: isVisibleDaliAllegro[4] ? "rgba(220, 1, 36, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                    <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netCurrent")}</Text>
                  </div>
                </div>
              :
                <div style={{ display: "flex", gap: "4px" }}>
                  <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDali(0)}>
                    <div style={{ border: "1px solid #16D764", backgroundColor: isVisibleDali[0] ? "rgba(22, 215, 100, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                    <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("ledCurrent")}</Text>
                  </div>
                  <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDali(1)}>
                    <div style={{ border: "1px solid #00C7F2", backgroundColor: isVisibleDali[1] ? "rgba(0, 199, 242, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                    <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("driverTemp")}</Text>
                  </div>
                  <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDali(2)}>
                    <div style={{ border: "1px solid #005882", backgroundColor: isVisibleDali[2] ? "rgba(100, 149, 237, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                    <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("netVoltage")}</Text>
                  </div>
                  <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDatasetDali(3)}>
                    <div style={{ border: "1px solid #FFCB21", backgroundColor: isVisibleDali[3] ? "rgba(255, 203, 33, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
                    <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("appPower")}</Text>
                  </div>
                </div>
          }
          <div style={{ display: "flex", gap: "30px", flexWrap: "wrap", paddingBottom: "20px", paddingTop: "30px", justifyContent: "center", maxHeight: "630px", height: "400px" }}>
            <ElectricalParametersChart isVisibleDali={isVisibleDali} isVisibleAllegro={isVisibleAllegro} isVisibleDaliAllegro={isVisibleDaliAllegro} isVisibleShuncom={isVisibleShuncom} selectedLamp={selectedLamp} />
          </div>
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
