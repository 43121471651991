import CryptoJS from 'crypto-js';
import { configureStore, combineReducers, ThunkAction, Action } from "@reduxjs/toolkit";
import { filterReducer } from "./slices/actualFilterSlice/actualFilterSlice";
import { devicesReducer } from "./slices/devices/devicesSlice";
import { filteredDevicesReducer } from "./slices/devices/filteredDevicesSlice";
import { graphicsReducer } from "./slices/graphicsSlice/graphicsSlice";
import { manageNodeReducer } from "./slices/nodeSlice/manageNodeSlice";
import { strategiesReducer } from "./slices/strategiesSlice/strategiesSlice";
import { uiDataReducer } from "./slices/uiSlice/uiSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore, createTransform } from "redux-persist";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { mqttReducer } from "./slices/mqttSlice/mqttSlice";
import userReducer from "./slices/userSlice/userSlice";
import thunk, { ThunkMiddleware } from "redux-thunk";
import logoutReducer from "./slices/logoutSlice/logoutSlice";
import languageReducer from "./slices/languageSlice/languageSlice";
import { managedUsersListReducer } from "./slices/managedUsers/managedUsersListSlice";
import { managedUserReducer } from "./slices/managedUsers/managedUserSlice";
import { selectedOrganizationReducer } from "./slices/selectedOrganizationSlice/selectedOrganizationSlice";
import { electricParametersReducer } from "./slices/electricParametersSlice/electricParametersSlice";
import { groupsReducer } from "./slices/groupsSlice/groupsSlice";
import { alarmsReducer } from "./slices/alarmsSlice/alarmsSlice";
import { solarParametersReducer } from "./slices/solar/solarParametersSlice";
import { solarStrategiesReducer } from "./slices/solar/solarStrategiesSlice";
import { userRegistrationReducer } from "./slices/userRegistrationSlice/userRegistrationSlice";
import { reportsReducer } from './slices/reportsSlice/reportsSlice';
import { pdfReportReducer } from './slices/reportsSlice/pdfReportSlice';

const encrypt = (inboundState:any, key:any) => CryptoJS.AES.encrypt(JSON.stringify(inboundState), key).toString();
const decrypt = (outboundState:any, key:any) => JSON.parse(CryptoJS.AES.decrypt(outboundState, key).toString(CryptoJS.enc.Utf8));

const encryptTransform = createTransform(
  (inboundState, key) => encrypt(inboundState, process.env.REACT_APP_SMARTEC as string),
  (outboundState, key) => decrypt(outboundState, process.env.REACT_APP_SMARTEC as string)
);

//funciona pero da muchos errores de persistpartial
const subsetTransform = createTransform(
  // transformar en
  (inboundState:any, key) => {
    const { nodes, gateways, ...rest } = inboundState; // Excluye 'nodes'
    return {
      ...rest,
      /* gateways: inboundState.gateways,
      nodes: inboundState.nodes, */
      organizations: inboundState.organizations,
      selectedOrganizationId: inboundState.selectedOrganizationId,
      services: inboundState.services,
      selectedServiceId: inboundState.selectedServiceId,
    };
  },
  // transformar fuera
  (outboundState, key) => {
    return outboundState
  },
  // aplicar esta transformación a los siguientes reducers
 /*  { whitelist: ['devices', 'filteredDevices'] } */
);

const persistConfig:any = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  transforms: [subsetTransform, encryptTransform],
  blacklist: ["userRegistration"], // No persistir los nodos
  throttle: 1000, // Limitar la frecuencia de guardado
};

/* const persistConfig:any = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  transforms: [subsetTransform],
  blacklist: ["userRegistration"]
}; */

const rootReducer:any = combineReducers({
  actualFilter: filterReducer,
  devices: devicesReducer,
  filteredDevices: filteredDevicesReducer,
  ui: uiDataReducer,
  graphics: graphicsReducer,
  mqtt: mqttReducer,
  user: userReducer,
  strategies: strategiesReducer,
  logout: logoutReducer,
  language: languageReducer,
  managedUsersList: managedUsersListReducer,
  managedUser: managedUserReducer,
  manageNode: manageNodeReducer,
  selectedOrganization: selectedOrganizationReducer,
  electricParameters: electricParametersReducer,
  groups: groupsReducer,
  alarms: alarmsReducer,
  solarParameters: solarParametersReducer,
  solarStrategies: solarStrategiesReducer,
  userRegistration: userRegistrationReducer,
  reports: reportsReducer,
  pdfReport: pdfReportReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store:any = configureStore({
  //reducer: rootReducer,
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: false, 
    }),
  devTools: process.env.REACT_APP_LOCAL_BASE_URL as string !== 'https://smartec.salvi.es' && process.env.REACT_APP_LOCAL_BASE_URL as string !== 'https://beta.salvilighting.com',
  // middleware: [thunk as ThunkMiddleware],
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

// In the updated configuration I provided, the thunk middleware is used
// with the correct type (ThunkMiddleware), and we can remove the AppThunk
//  type definition because it's already included in the redux-thunk package.
// The ThunkMiddleware type handles the correct typing for thunks,
//  and we no longer need to define it separately.

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// CÓDIGO CON DATA QUE NO PERSISTE
/*import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { filterReducer } from "./slices/actualFilterSlice/actualFilterSlice";
import { devicesReducer } from "./slices/devices/devicesSlice";
import { filteredDevicesReducer } from "./slices/devices/filteredDevicesSlice";
import { uiDataReducer } from "./slices/uiSlice/uiSlice";

export const store = configureStore({
  reducer: {
    devices: devicesReducer,
    filteredDevices: filteredDevicesReducer,
    ui: uiDataReducer,
    actualFilter: filterReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;*/
