import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import { useTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

interface StrategiesChartSolarProps {
  chartData: any;
  color: string;
}

function StrategiesChartSolar({ chartData, color }: StrategiesChartSolarProps) {
  const { t } = useTranslation();
  
  const times = [chartData[5], chartData[6], chartData[7], chartData[8], chartData[9]];
  const dimmings = [chartData[0], chartData[1], chartData[2], chartData[3], chartData[4]];
  const filteredTimes =  times.filter(time => time !== 0);
  const filteredDimmings = dimmings.slice(0, filteredTimes.length);
  let dimmingsArr:any = [];
  let newIndex:any;

  for (let i = 0; i < filteredTimes.length; i++) {
    const tempArr:any = [null, null, null, null, null, null, null, null, null, null, null, null, null];
    if (filteredTimes[i] != 0 && filteredTimes[i] !== null && filteredTimes[i] !== undefined) {
      if (i === 0) {
        tempArr[i] = filteredDimmings[i];
        tempArr[i + filteredTimes[i]] = filteredDimmings[i];
        newIndex = i + filteredTimes[i];
      } else {
        tempArr[newIndex] = filteredDimmings[i];
        tempArr[newIndex + filteredTimes[i]] = filteredDimmings[i];
        newIndex = newIndex + filteredTimes[i]
      }

      dimmingsArr.push(tempArr);
    } else {
      break;
    }
  }

  const datasets:any = [];
  dimmingsArr.map((list:any) => {
    datasets.push({
      label: t("Solar"),
      data: list,
      borderColor: color,
      backgroundColor: color,
      borderWidth: 10,
      pointRadius: 5,
      pointBorderWidth: 2,
      pointBackgroundColor: 'white',
      pointBorderColor: color,
      fill: false,
      spanGaps: true,
    })
  })

  const data = {
    labels: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
    datasets: datasets,
  };

  const options:any = {
    scales: {
      y: {
        suggestedMax: 100,
        suggestedMin: 0,
        ticks: {
          stepSize: 25,
          backdropPadding: 10,
        },
      },
      x: {
        title: {
          display: false,
        },
      },
    },
  
    plugins: {
      legend: {
        display: false,
      },
    },
    animation: false,
  };

  return (
    <div style={{ height: "100%", width: "100%", overflow: "hidden", display: "flex", alignContent: "flex-end" }}>
      <Line options={options} data={data} />
    </div>
  );
}

export default StrategiesChartSolar;
