import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "antd";
import { store } from "../../../redux/store";
import { useAppSelector } from "../../../redux/hooks";
import { getGroupsAction, updateGroupsArrAction } from "../../../redux/actions/groupsActions/groupsActions";
import { CardContainer } from "../../../styles/shared-styles/card-container";
import { Text } from "../../../styles/shared-styles/text-sizes";
import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "../../../styles/shared-styles/buttons-sizes";
import { useGroupsService } from "../../../services/groupsService";
import { ButtonStrategy } from "../../../components/StrategiesPage/StrategiesSteps/StrategiesStepsStyled";

interface GroupUpdateModalProps {
  showEditName: boolean;
  //   handleShowEditModal: () => void;
  selectedGroup: any;
  setShowEditName: (arg0: boolean) => void;
  setLoading: any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 500px;
  }
  .modal-content {
    padding-bottom: 0px;
    background: white;
    border: 0px;
  }
`;

export const EditGroupName = ({ showEditName, setShowEditName, selectedGroup, setLoading }: GroupUpdateModalProps) => {
  const selectedGroupName = useAppSelector((state) => state.groups.selectedGroup.groupName);
  const [groupName, setGroupName] = useState(selectedGroupName);

  const { getGroupInfo, updateGroupName } = useGroupsService();
  const selectedOrgId: any = useAppSelector((state) => state.filteredDevices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.filteredDevices.selectedServiceId);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  const handleUpdateGroup = async () => {
    setLoading(true);
    const payload = {
      group_name: groupName,
      id: selectedGroup.groupId,
    };
    const res: any = await updateGroupName(payload, selectedOrgId, selectedServiceId);
    if (res) {
      let getGroupResponse = await getGroupInfo(selectedGroup.groupId, selectedOrgId, selectedServiceId, setLoading);
      if (getGroupResponse) {
        store.dispatch(updateGroupsArrAction("name", getGroupResponse))
      }
    }
    setLoading(false);
  };

  return (
    <CustomModal show={showEditName} onHide={() => setShowEditName(false)} centered>
      <Modal.Header
        closeButton
        style={{
          borderBottom: "none",
          padding: "20px 2 0px 0 20px",
          background: "white",
          zIndex: 2,
        }}
      ></Modal.Header>
      <Modal.Body
        style={{
          background: "white",
          marginBottom: "10px",
          paddingTop: "0px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        {selectedGroup?.groupId && (
          <div>
            <Text marginBottom="20" fontWeight="600" style={{ fontSize: "22px", textAlign: "center" }}>{t("editGroupName")}</Text>
            <div style={{ marginBottom: "16px", textAlign: "left" }}>
              <Text textType="caption" color="#475569" marginBottom="5px" style={{ fontSize: "14px" }}>{t("groupName")}</Text>
              <Input defaultValue={selectedGroup?.groupName} onChange={(e) => setGroupName(e.target.value)} />
            </div>
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ButtonStrategy buttonType="primary" style={{background: buttonsColor, color: accentTextColor, width: "300px", cursor: "pointer", padding: "8px 32px", lineHeight: "none",
            marginBottom: "8px", marginLeft: "0px", marginTop: "20px" }} onClick={handleUpdateGroup}
          >
            {t("Send")}
          </ButtonStrategy>
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
