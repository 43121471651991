import React from "react";
import back from "../../assets/new_icons/back.svg";
import { Text, TextSpan } from "../../styles/shared-styles/text-sizes";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import plusIcon from "../../assets/new_icons/plus_blue.svg";
import { useTranslation } from "react-i18next";
import gatewayLogo from "../../assets/new_icons/Gateways.svg";
import connectedIcon from "../../assets/new_icons/Connected.svg";
import typeIcon from "../../assets/new_icons/Type.svg";
import alertIcon from "../../assets/new_icons/Alarm.svg";
import plusCircle from "../../assets/new_icons/plusCircle.svg";
import { GatewayListingWrapper } from "./GatewayPageStyled";
import { CommonCardContainer } from "../../styles/shared-styles/card-container";
import { GatewayListing } from "./GatewayListing/GatewayListing";
import { GatewayFooter } from "./GatewayFooter/GatewayFooter";
import { GatewayMap } from "./GatewayMap/GatewayMap";
import { ReactComponent as ID } from "../../assets/new_icons/ID.svg"

export const GatewayPage = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-4">
      <div className="mb-2">
        <img src={back} alt="back" style={{ marginRight: "10px" }} />
        <TextSpan color="#bdbdbd" display="inline" textType="Filtercaption">
          Back to Overview
        </TextSpan>
      </div>
      <div
        className="row"
        // style={{ marginBottom: "44px", justifyContent: "flex-end" }}
      >
        <div className="col-9">
          <div className="d-flex align-items-center gap-4 mb-5">
            <img src={gatewayLogo} alt="logo" height={100} />
            <div>
              <Text className="mb-3" textType="title">
                GW456
              </Text>
              <div className="d-flex gap-5 mb-3">
                <div className="d-flex gap-2 align-items-center">
                  <img src={connectedIcon} height={18} className="" />
                  <Text color="black" marginBottom="0px">
                    Connected
                  </Text>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <img src={typeIcon} height={18} className="" />
                  <Text color="black" marginBottom="0px">
                    Gateway
                  </Text>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <ID width={18} height={18} style={{ color: "#525252" }} />
                  <Text color="black" marginBottom="0px">
                    31415
                  </Text>
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <img src={alertIcon} height={18} className=" filter-invert" />
                  <Text color="black" marginBottom="0px">
                    2
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-3"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button buttonType="primary" onClick={() => {}}>
            <span>
              <img
                src={plusIcon}
                style={{ height: "16px", marginRight: "8px" }}
              />
            </span>
            {t("Actions")}
          </Button>
        </div>
      </div>
      <GatewayListingWrapper className="d-flex mb-4">
        <div className=" map flex-grow-1">
          <GatewayMap />
        </div>
        <CommonCardContainer
          className="luminaires-listings"
          style={{ paddingTop: 0 }}
        >
          <div className="listing-header d-flex align-items-center justify-content-between mb-3">
            <Text>Luminaires</Text>
            <button className="btn btn-link p-0">
              <img src={plusCircle} style={{ height: "24px" }} />
            </button>
          </div>
          <GatewayListing />
        </CommonCardContainer>
      </GatewayListingWrapper>
      <GatewayFooter />
    </div>
  );
};
