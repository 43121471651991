import { parseISO, format } from 'date-fns';
//import { toZonedTime, format as tzFormat } from 'date-fns-tz';
import { store } from '../../../redux/store';
import { getDaliInfoActionCreator, getAllegroInfoActionCreator, getDaliAllegroInfoActionCreator, getShuncomInfoActionCreator } from '../../../redux/slices/electricParametersSlice/electricParametersSlice';
import { getSolarConsumptionActionCreator } from '../../../redux/slices/solar/solarParametersSlice';

//this variable is the parsed seconds taking into account the point generation rate (in the node, a single point is generated every 15 minutes, so 900 seconds)
// cambiaremos esta variable y será dinámica, hacer cuando esté hecho en back
let resultData: { strategy: number; x: number; y: number; }[] = [];

export const calculateParameters = async (obj: any, parameterType: string, numDays: number, graphicDateCursorInSeconds: number, csv?: string) => {
  console.log("lo que llega de back", obj)
  let totalNumChartPoints = csv ? (numDays - 1) * 1440 : 240;
  let secondsPerDay = 86400;

  if (obj.length === 0) {
    return;
  };

  let totalChartNumSeconds = secondsPerDay * (numDays - 1);
  let pointInterval = csv ? 60 : totalChartNumSeconds / totalNumChartPoints; //Distance in seconds between points

  let finishStrategyTime: any = [];
  let distanceBetweenPointsInSeconds: any = [];
  let numPointsArr:any = [];
  obj.map((item: any, index: any) => {
    //const numPoints = (item.num_points || item.num_points === 0 ) || (item.numPointsT || item.numPointsT === 0);
    const numPoints = (item.num_points !== undefined) ? item.num_points : ((item.numPointsT !== undefined) ? item.numPointsT : undefined);
    numPointsArr.push(numPoints);
    
    distanceBetweenPointsInSeconds.push(item.freq_gener_point || item.freq_gener_point === 0 || item.freqGenerPoint || item.freqGenerPoint === 0);
    //let itemDate:any = new Date(item.date);
    let dateToParse:any = new Date(item.date);

    let localOffset = dateToParse.getTimezoneOffset() * 60000; 
    let localTime = dateToParse.getTime();
    let itemDate = new Date(localTime + localOffset);
    
    finishStrategyTime.push(itemDate.getTime() / 1000)
  });
  
  let strategyIndex = 0;
  let consumptionsDaliLedCurrent: any = [];
  let consumptionsDaliDriverTemp: any = [];
  let consumptionsDaliNetVoltage: any = [];
  let consumptionsDaliApparentPower: any = [];
  let consumptionsSolarChargePower: any = [];
  let consumptionsSolarBatteryVoltage: any = [];
  let consumptionsSolarBatteryPercentage: any = [];
  let consumptionsSolarActivePower: any = [];
  let consumptionsAllegroNetVoltage: any = [];
  let consumptionsAllegroApparentPower: any = [];
  let consumptionsAllegroNetCurrent: any = [];
  let consumptionsDaliAllegroLedCurrent: any = [];
  let consumptionsDaliAllegroDriverTemp: any = [];
  let consumptionsDaliAllegroNetVoltage: any = [];
  let consumptionsDaliAllegroApparentPower: any = [];
  let consumptionsDaliAllegroNetCurrent: any = [];
  let consumptionsShuncomDriverTemp: any = [];
  let consumptionsShuncomNetVoltage: any = [];
  let consumptionsShuncomApparentPower: any = [];
  let consumptionsShuncomNetCurrent: any = [];
  let humanFormatHours: any = [];
  let totalSeconds = distanceBetweenPointsInSeconds[strategyIndex];

  let secondsWhenStrategyStarts = finishStrategyTime[strategyIndex] - numPointsArr[strategyIndex] * totalSeconds;
  
  for (let i = 0; i < totalNumChartPoints; i++) {
    // dali params
    let coeff3DaliLedCurrent: any = 0;
    let coeff2DaliLedCurrent: any = 0;
    let coeff1DaliLedCurrent: any = 0;
    let coeff0DaliLedCurrent: any = 0;
    let coeff3DaliDriverTemp: any = 0;
    let coeff2DaliDriverTemp: any = 0;
    let coeff1DaliDriverTemp: any = 0;
    let coeff0DaliDriverTemp: any = 0;
    let coeff3DaliNetVoltage: any = 0;
    let coeff2DaliNetVoltage: any = 0;
    let coeff1DaliNetVoltage: any = 0;
    let coeff0DaliNetVoltage: any = 0;
    let coeff3DaliApparentPower: any = 0;
    let coeff2DaliApparentPower: any = 0;
    let coeff1DaliApparentPower: any = 0;
    let coeff0DaliApparentPower: any = 0;

    // solar params
    let coeff4SolarPannelChargePower: any = 0;
    let coeff3SolarPannelChargePower: any = 0;
    let coeff2SolarPannelChargePower: any = 0;
    let coeff1SolarPannelChargePower: any = 0;
    let coeff0SolarPannelChargePower: any = 0;
    let coeff4SolarBattVolt: any = 0;
    let coeff3SolarBattVolt: any = 0;
    let coeff2SolarBattVolt: any = 0;
    let coeff1SolarBattVolt: any = 0;
    let coeff0SolarBattVolt: any = 0;
    let coeff4SolarBattPercentage: any = 0;
    let coeff3SolarBattPercentage: any = 0;
    let coeff2SolarBattPercentage: any = 0;
    let coeff1SolarBattPercentage: any = 0;
    let coeff0SolarBattPercentage: any = 0;
    let coeff4SolarActivePower: any = 0;
    let coeff3SolarActivePower: any = 0;
    let coeff2SolarActivePower: any = 0;
    let coeff1SolarActivePower: any = 0;
    let coeff0SolarActivePower: any = 0;

    // allegro params
    let coeff3AllegroNetVoltage: any = 0;
    let coeff2AllegroNetVoltage: any = 0;
    let coeff1AllegroNetVoltage: any = 0;
    let coeff0AllegroNetVoltage: any = 0;
    let coeff3AllegroApparentPower: any = 0;
    let coeff2AllegroApparentPower: any = 0;
    let coeff1AllegroApparentPower: any = 0;
    let coeff0AllegroApparentPower: any = 0;
    let coeff3AllegroNetCurrent: any = 0;
    let coeff2AllegroNetCurrent: any = 0;
    let coeff1AllegroNetCurrent: any = 0;
    let coeff0AllegroNetCurrent: any = 0;

    // dali allegro params
    let coeff3DaliAllegroLedCurrent: any = 0;
    let coeff2DaliAllegroLedCurrent: any = 0;
    let coeff1DaliAllegroLedCurrent: any = 0;
    let coeff0DaliAllegroLedCurrent: any = 0;
    let coeff3DaliAllegroDriverTemp: any = 0;
    let coeff2DaliAllegroDriverTemp: any = 0;
    let coeff1DaliAllegroDriverTemp: any = 0;
    let coeff0DaliAllegroDriverTemp: any = 0;
    let coeff3DaliAllegroNetVoltage: any = 0;
    let coeff2DaliAllegroNetVoltage: any = 0;
    let coeff1DaliAllegroNetVoltage: any = 0;
    let coeff0DaliAllegroNetVoltage: any = 0;
    let coeff3DaliAllegroApparentPower: any = 0;
    let coeff2DaliAllegroApparentPower: any = 0;
    let coeff1DaliAllegroApparentPower: any = 0;
    let coeff0DaliAllegroApparentPower: any = 0;
    let coeff3DaliAllegroNetCurrent: any = 0;
    let coeff2DaliAllegroNetCurrent: any = 0;
    let coeff1DaliAllegroNetCurrent: any = 0;
    let coeff0DaliAllegroNetCurrent: any = 0;

    // shuncom params
    let coeff3ShuncomDriverTemp: any = 0;
    let coeff2ShuncomDriverTemp: any = 0;
    let coeff1ShuncomDriverTemp: any = 0;
    let coeff0ShuncomDriverTemp: any = 0;
    let coeff3ShuncomNetVoltage: any = 0;
    let coeff2ShuncomNetVoltage: any = 0;
    let coeff1ShuncomNetVoltage: any = 0;
    let coeff0ShuncomNetVoltage: any = 0;
    let coeff3ShuncomApparentPower: any = 0;
    let coeff2ShuncomApparentPower: any = 0;
    let coeff1ShuncomApparentPower: any = 0;
    let coeff0ShuncomApparentPower: any = 0;
    let coeff3ShuncomNetCurrent: any = 0;
    let coeff2ShuncomNetCurrent: any = 0;
    let coeff1ShuncomNetCurrent: any = 0;
    let coeff0ShuncomNetCurrent: any = 0;


    let pointValueDaliLedCurrent:any;
    let pointValueDaliDriverTemp:any;
    let pointValueDaliNetVoltage:any;
    let pointValueDaliApparentPower:any;
    let pointValueSolarChargePower:any;
    let pointValueSolarBatteryVoltage;
    let pointValueSolarBatteryPercentage;
    let pointValueSolarActivePower;
    let pointValueAllegroNetVoltage:any;
    let pointValueAllegroApparentPower:any;
    let pointValueAllegroNetCurrent:any;
    let pointValueDaliAllegroLedCurrent:any;
    let pointValueDaliAllegroDriverTemp:any;
    let pointValueDaliAllegroNetVoltage:any;
    let pointValueDaliAllegroApparentPower:any;
    let pointValueDaliAllegroNetCurrent:any;
    let pointValueShuncomDriverTemp:any;
    let pointValueShuncomNetVoltage:any;
    let pointValueShuncomApparentPower:any;
    let pointValueShuncomNetCurrent:any;

    //NOTA PARA MI: se ha cambiado ligeramente la forma de calcular para captar cuando, de golpe (lo más probable), haya un apagado. En el anterior while se hacían los cálculos y
    // entonces se sumaba un punto en el strategyIndex, pero si lo sumamos antes de empezar los cálculos, trabajamos con el bloque de estrategia en curso, y así podemos calcular
    // cuando empieza dicho bloque de estrategia, ya que el graphicDateCursorInSeconds no siempre va a ir a la par que el secondsWhenStrategyStarts
    while (graphicDateCursorInSeconds > finishStrategyTime[strategyIndex] && strategyIndex < finishStrategyTime.length-1) {
      strategyIndex++;
      secondsWhenStrategyStarts = finishStrategyTime[strategyIndex] - numPointsArr[strategyIndex] * totalSeconds;
    }

    if (secondsWhenStrategyStarts - distanceBetweenPointsInSeconds[strategyIndex] > graphicDateCursorInSeconds || (graphicDateCursorInSeconds > finishStrategyTime[strategyIndex] && strategyIndex <= finishStrategyTime.length-1)) {
      if (parameterType === "solar") {
        consumptionsSolarChargePower.push('');
        consumptionsSolarBatteryVoltage.push('');
        consumptionsSolarBatteryPercentage.push('');
        consumptionsSolarActivePower.push('');
      } else if (parameterType === "allegro") {
        consumptionsAllegroNetVoltage.push('');
        consumptionsAllegroApparentPower.push('');
        consumptionsAllegroNetCurrent.push('');
      } else if (parameterType === "daliAllegro") {
        consumptionsDaliAllegroLedCurrent.push('');
        consumptionsDaliAllegroDriverTemp.push('');
        consumptionsDaliAllegroNetVoltage.push('');
        consumptionsDaliAllegroApparentPower.push('');
        consumptionsDaliAllegroNetCurrent.push('');
      } else if (parameterType === "shuncom") {
        consumptionsShuncomDriverTemp.push('');
        consumptionsShuncomNetVoltage.push('');
        consumptionsShuncomApparentPower.push('');
        consumptionsShuncomNetCurrent.push('');
      } else {
        consumptionsDaliLedCurrent.push('');
        consumptionsDaliDriverTemp.push('');
        consumptionsDaliNetVoltage.push('');
        consumptionsDaliApparentPower.push('');
      }
      const day = new Date(graphicDateCursorInSeconds * 1000);
      const dayOptions:any = { month: '2-digit', day: 'numeric' };
      const hourOptions:any = { hour: '2-digit', minute: '2-digit' };
      const formattedDay = day.toLocaleDateString('es-ES', dayOptions);
      const formattedHour = day.toLocaleTimeString('es-ES', hourOptions);
      humanFormatHours.push(formattedDay + " - " + formattedHour);
    } else {
      switch (parameterType) {
        case "dali":
          coeff3DaliLedCurrent = obj[strategyIndex].cur_pcb_c3;
          coeff2DaliLedCurrent = obj[strategyIndex].cur_pcb_c2;
          coeff1DaliLedCurrent = obj[strategyIndex].cur_pcb_c1;
          coeff0DaliLedCurrent = obj[strategyIndex].cur_pcb_c0;

          coeff3DaliDriverTemp = obj[strategyIndex].temp_driv_c3;
          coeff2DaliDriverTemp = obj[strategyIndex].temp_driv_c3;
          coeff1DaliDriverTemp = obj[strategyIndex].temp_driv_c1;
          coeff0DaliDriverTemp = obj[strategyIndex].temp_driv_c0;

          coeff3DaliNetVoltage = obj[strategyIndex].vol_net_c3;
          coeff2DaliNetVoltage = obj[strategyIndex].vol_net_c2;
          coeff1DaliNetVoltage = obj[strategyIndex].vol_net_c1;
          coeff0DaliNetVoltage = obj[strategyIndex].vol_net_c0;

          coeff3DaliApparentPower = obj[strategyIndex].appw_net_c3;
          coeff2DaliApparentPower = obj[strategyIndex].appw_net_c2;
          coeff1DaliApparentPower = obj[strategyIndex].appw_net_c1;
          coeff0DaliApparentPower = obj[strategyIndex].appw_net_c0;
          
          break;    
        case "solar":
          coeff4SolarPannelChargePower = obj[strategyIndex].c4PanelChargePower;
          coeff3SolarPannelChargePower = obj[strategyIndex].c3PanelChargePower;
          coeff2SolarPannelChargePower = obj[strategyIndex].c2PanelChargePower;
          coeff1SolarPannelChargePower = obj[strategyIndex].c1PanelChargePower;
          coeff0SolarPannelChargePower = obj[strategyIndex].c0PanelChargePower;
          
          coeff4SolarBattVolt = obj[strategyIndex].c4solarBattVolt;
          coeff3SolarBattVolt = obj[strategyIndex].c3solarBattVolt;
          coeff2SolarBattVolt = obj[strategyIndex].c2solarBattVolt;
          coeff1SolarBattVolt = obj[strategyIndex].c1solarBattVolt;
          coeff0SolarBattVolt = obj[strategyIndex].c0solarBattVolt;

          coeff4SolarBattPercentage = obj[strategyIndex].c4SOC;
          coeff3SolarBattPercentage = obj[strategyIndex].c3SOC;
          coeff2SolarBattPercentage = obj[strategyIndex].c2SOC;
          coeff1SolarBattPercentage = obj[strategyIndex].c1SOC;
          coeff0SolarBattPercentage = obj[strategyIndex].c0SOC;

          coeff4SolarActivePower = obj[strategyIndex].c4LuminairePowerConsumption;
          coeff3SolarActivePower = obj[strategyIndex].c3LuminairePowerConsumption;
          coeff2SolarActivePower = obj[strategyIndex].c2LuminairePowerConsumption;
          coeff1SolarActivePower = obj[strategyIndex].c1LuminairePowerConsumption;
          coeff0SolarActivePower = obj[strategyIndex].c0LuminairePowerConsumption;
    
          break;
        case "allegro":
          coeff3AllegroNetVoltage = obj[strategyIndex].vol_net_c3;
          coeff2AllegroNetVoltage = obj[strategyIndex].vol_net_c2;
          coeff1AllegroNetVoltage = obj[strategyIndex].vol_net_c1;
          coeff0AllegroNetVoltage = obj[strategyIndex].vol_net_c0;

          coeff3AllegroApparentPower = obj[strategyIndex].appw_net_c3;
          coeff2AllegroApparentPower = obj[strategyIndex].appw_net_c2;
          coeff1AllegroApparentPower = obj[strategyIndex].appw_net_c1;
          coeff0AllegroApparentPower = obj[strategyIndex].appw_net_c0;

          coeff3AllegroNetCurrent = obj[strategyIndex].cur_net_c3;
          coeff2AllegroNetCurrent = obj[strategyIndex].cur_net_c2;
          coeff1AllegroNetCurrent = obj[strategyIndex].cur_net_c1;
          coeff0AllegroNetCurrent = obj[strategyIndex].cur_net_c0;
    
          break;
        
        case "daliAllegro":
          coeff3DaliAllegroLedCurrent = obj[strategyIndex].cur_pcb_c3;
          coeff2DaliAllegroLedCurrent = obj[strategyIndex].cur_pcb_c2;
          coeff1DaliAllegroLedCurrent = obj[strategyIndex].cur_pcb_c1;
          coeff0DaliAllegroLedCurrent = obj[strategyIndex].cur_pcb_c0;

          coeff3DaliAllegroDriverTemp = obj[strategyIndex].temp_driv_c3;
          coeff2DaliAllegroDriverTemp = obj[strategyIndex].temp_driv_c3;
          coeff1DaliAllegroDriverTemp = obj[strategyIndex].temp_driv_c1;
          coeff0DaliAllegroDriverTemp = obj[strategyIndex].temp_driv_c0;

          coeff3DaliAllegroNetVoltage = obj[strategyIndex].vol_net_c3;
          coeff2DaliAllegroNetVoltage = obj[strategyIndex].vol_net_c2;
          coeff1DaliAllegroNetVoltage = obj[strategyIndex].vol_net_c1;
          coeff0DaliAllegroNetVoltage = obj[strategyIndex].vol_net_c0;

          coeff3DaliAllegroApparentPower = obj[strategyIndex].appw_net_c3;
          coeff2DaliAllegroApparentPower = obj[strategyIndex].appw_net_c2;
          coeff1DaliAllegroApparentPower = obj[strategyIndex].appw_net_c1;
          coeff0DaliAllegroApparentPower = obj[strategyIndex].appw_net_c0;

          coeff3DaliAllegroNetCurrent = obj[strategyIndex].cur_net_c3;
          coeff2DaliAllegroNetCurrent = obj[strategyIndex].cur_net_c2;
          coeff1DaliAllegroNetCurrent = obj[strategyIndex].cur_net_c1;
          coeff0DaliAllegroNetCurrent = obj[strategyIndex].cur_net_c0;
          
          break;
        
        case "shuncom":
          coeff3ShuncomDriverTemp = obj[strategyIndex].temp_driv_c3;
          coeff2ShuncomDriverTemp = obj[strategyIndex].temp_driv_c3;
          coeff1ShuncomDriverTemp = obj[strategyIndex].temp_driv_c1;
          coeff0ShuncomDriverTemp = obj[strategyIndex].temp_driv_c0;

          coeff3ShuncomNetVoltage = obj[strategyIndex].vol_net_c3;
          coeff2ShuncomNetVoltage = obj[strategyIndex].vol_net_c2;
          coeff1ShuncomNetVoltage = obj[strategyIndex].vol_net_c1;
          coeff0ShuncomNetVoltage = obj[strategyIndex].vol_net_c0;

          coeff3ShuncomApparentPower = obj[strategyIndex].appw_net_c3;
          coeff2ShuncomApparentPower = obj[strategyIndex].appw_net_c2;
          coeff1ShuncomApparentPower = obj[strategyIndex].appw_net_c1;
          coeff0ShuncomApparentPower = obj[strategyIndex].appw_net_c0;

          coeff3ShuncomNetCurrent = obj[strategyIndex].cur_net_c3;
          coeff2ShuncomNetCurrent = obj[strategyIndex].cur_net_c2;
          coeff1ShuncomNetCurrent = obj[strategyIndex].cur_net_c1;
          coeff0ShuncomNetCurrent = obj[strategyIndex].cur_net_c0;
          
          break;  
        default:
      };

      if (parameterType === "solar") {
        if (coeff0SolarPannelChargePower === null) coeff0SolarPannelChargePower = 0;
        if (coeff1SolarPannelChargePower === null) coeff1SolarPannelChargePower = 0;
        if (coeff2SolarPannelChargePower === null) coeff2SolarPannelChargePower = 0;
        if (coeff3SolarPannelChargePower === null) coeff3SolarPannelChargePower = 0;
        if (coeff4SolarPannelChargePower === null) coeff4SolarPannelChargePower = 0;

        if (coeff0SolarBattVolt === null) coeff0SolarBattVolt = 0;
        if (coeff1SolarBattVolt === null) coeff1SolarBattVolt = 0;
        if (coeff2SolarBattVolt === null) coeff2SolarBattVolt = 0;
        if (coeff3SolarBattVolt === null) coeff3SolarBattVolt = 0;
        if (coeff4SolarBattVolt === null) coeff4SolarBattVolt = 0;

        if (coeff0SolarBattPercentage === null) coeff0SolarBattPercentage = 0;
        if (coeff1SolarBattPercentage === null) coeff1SolarBattPercentage = 0;
        if (coeff2SolarBattPercentage === null) coeff2SolarBattPercentage = 0;
        if (coeff3SolarBattPercentage === null) coeff3SolarBattPercentage = 0;
        if (coeff4SolarBattPercentage === null) coeff4SolarBattPercentage = 0;

        if (coeff0SolarActivePower === null) coeff0SolarActivePower = 0;
        if (coeff1SolarActivePower === null) coeff1SolarActivePower = 0;
        if (coeff2SolarActivePower === null) coeff2SolarActivePower = 0;
        if (coeff3SolarActivePower === null) coeff3SolarActivePower = 0;
        if (coeff4SolarActivePower === null) coeff4SolarActivePower = 0;
      } else if (parameterType === "allegro") {
        if (coeff0AllegroNetVoltage === null) coeff0AllegroNetVoltage = 0;
        if (coeff1AllegroNetVoltage === null) coeff1AllegroNetVoltage = 0;
        if (coeff2AllegroNetVoltage === null) coeff2AllegroNetVoltage = 0;
        if (coeff3AllegroNetVoltage === null) coeff3AllegroNetVoltage = 0;

        if (coeff0AllegroApparentPower === null) coeff0AllegroApparentPower = 0;
        if (coeff1AllegroApparentPower === null) coeff1AllegroApparentPower = 0;
        if (coeff2AllegroApparentPower === null) coeff2AllegroApparentPower = 0;
        if (coeff3AllegroApparentPower === null) coeff3AllegroApparentPower = 0;

        if (coeff0AllegroNetCurrent === null) coeff0AllegroNetCurrent = 0;
        if (coeff1AllegroNetCurrent === null) coeff1AllegroNetCurrent = 0;
        if (coeff2AllegroNetCurrent === null) coeff2AllegroNetCurrent = 0;
        if (coeff3AllegroNetCurrent === null) coeff3AllegroNetCurrent = 0;
      } else if (parameterType === "daliAllegro") {
        if (coeff0DaliAllegroLedCurrent === null) coeff0DaliAllegroLedCurrent = 0;
        if (coeff1DaliAllegroLedCurrent === null) coeff1DaliAllegroLedCurrent = 0;
        if (coeff2DaliAllegroLedCurrent === null) coeff2DaliAllegroLedCurrent = 0;
        if (coeff3DaliAllegroLedCurrent === null) coeff3DaliAllegroLedCurrent = 0;

        if (coeff0DaliAllegroDriverTemp === null) coeff0DaliAllegroDriverTemp = 0;
        if (coeff1DaliAllegroDriverTemp === null) coeff1DaliAllegroDriverTemp = 0;
        if (coeff2DaliAllegroDriverTemp === null) coeff2DaliAllegroDriverTemp = 0;
        if (coeff3DaliAllegroDriverTemp === null) coeff3DaliAllegroDriverTemp = 0;

        if (coeff0DaliAllegroNetVoltage === null) coeff0DaliAllegroNetVoltage = 0;
        if (coeff1DaliAllegroNetVoltage === null) coeff1DaliAllegroNetVoltage = 0;
        if (coeff2DaliAllegroNetVoltage === null) coeff2DaliAllegroNetVoltage = 0;
        if (coeff3DaliAllegroNetVoltage === null) coeff3DaliAllegroNetVoltage = 0;

        if (coeff0DaliAllegroApparentPower === null) coeff0DaliAllegroApparentPower = 0;
        if (coeff1DaliAllegroApparentPower === null) coeff1DaliAllegroApparentPower = 0;
        if (coeff2DaliAllegroApparentPower === null) coeff2DaliAllegroApparentPower = 0;
        if (coeff3DaliAllegroApparentPower === null) coeff3DaliAllegroApparentPower = 0;

        if (coeff0DaliAllegroNetCurrent === null) coeff0DaliAllegroNetCurrent = 0;
        if (coeff1DaliAllegroNetCurrent === null) coeff1DaliAllegroNetCurrent = 0;
        if (coeff2DaliAllegroNetCurrent === null) coeff2DaliAllegroNetCurrent = 0;
        if (coeff3DaliAllegroNetCurrent === null) coeff3DaliAllegroNetCurrent = 0;
      } else if (parameterType === "shuncom") {
        if (coeff0ShuncomDriverTemp === null) coeff0ShuncomDriverTemp = 0;
        if (coeff1ShuncomDriverTemp === null) coeff1ShuncomDriverTemp = 0;
        if (coeff2ShuncomDriverTemp === null) coeff2ShuncomDriverTemp = 0;
        if (coeff3ShuncomDriverTemp === null) coeff3ShuncomDriverTemp = 0;

        if (coeff0ShuncomNetVoltage === null) coeff0ShuncomNetVoltage = 0;
        if (coeff1ShuncomNetVoltage === null) coeff1ShuncomNetVoltage = 0;
        if (coeff2ShuncomNetVoltage === null) coeff2ShuncomNetVoltage = 0;
        if (coeff3ShuncomNetVoltage === null) coeff3ShuncomNetVoltage = 0;

        if (coeff0ShuncomApparentPower === null) coeff0ShuncomApparentPower = 0;
        if (coeff1ShuncomApparentPower === null) coeff1ShuncomApparentPower = 0;
        if (coeff2ShuncomApparentPower === null) coeff2ShuncomApparentPower = 0;
        if (coeff3ShuncomApparentPower === null) coeff3ShuncomApparentPower = 0;

        if (coeff0ShuncomNetCurrent === null) coeff0ShuncomNetCurrent = 0;
        if (coeff1ShuncomNetCurrent === null) coeff1ShuncomNetCurrent = 0;
        if (coeff2ShuncomNetCurrent === null) coeff2ShuncomNetCurrent = 0;
        if (coeff3ShuncomNetCurrent === null) coeff3ShuncomNetCurrent = 0;
      } else {
        if (coeff0DaliLedCurrent === null) coeff0DaliLedCurrent = 0;
        if (coeff1DaliLedCurrent === null) coeff1DaliLedCurrent = 0;
        if (coeff2DaliLedCurrent === null) coeff2DaliLedCurrent = 0;
        if (coeff3DaliLedCurrent === null) coeff3DaliLedCurrent = 0;

        if (coeff0DaliDriverTemp === null) coeff0DaliDriverTemp = 0;
        if (coeff1DaliDriverTemp === null) coeff1DaliDriverTemp = 0;
        if (coeff2DaliDriverTemp === null) coeff2DaliDriverTemp = 0;
        if (coeff3DaliDriverTemp === null) coeff3DaliDriverTemp = 0;

        if (coeff0DaliNetVoltage === null) coeff0DaliNetVoltage = 0;
        if (coeff1DaliNetVoltage === null) coeff1DaliNetVoltage = 0;
        if (coeff2DaliNetVoltage === null) coeff2DaliNetVoltage = 0;
        if (coeff3DaliNetVoltage === null) coeff3DaliNetVoltage = 0;

        if (coeff0DaliApparentPower === null) coeff0DaliApparentPower = 0;
        if (coeff1DaliApparentPower === null) coeff1DaliApparentPower = 0;
        if (coeff2DaliApparentPower === null) coeff2DaliApparentPower = 0;
        if (coeff3DaliApparentPower === null) coeff3DaliApparentPower = 0;
      }
  
      let x = 1 + (graphicDateCursorInSeconds - secondsWhenStrategyStarts) / totalSeconds;
      //Mirar si tiene sentido. Es una chapuza que hemos hecho pero tal vez hay que dejarla por si acaso pasa algo raro.
      //if (x > numPointsArr[strategyIndex]) x = numPointsArr[strategyIndex];

      if (parameterType === "solar") {
        //if else aquí
        pointValueSolarChargePower = parseFloat(coeff4SolarPannelChargePower) * Math.pow(x,  4) + parseFloat(coeff3SolarPannelChargePower) * Math.pow(x, 3) + parseFloat(coeff2SolarPannelChargePower) * Math.pow(x, 2) + parseFloat(coeff1SolarPannelChargePower) * Math.pow(x, 1) + parseFloat(coeff0SolarPannelChargePower);
        consumptionsSolarChargePower.push(pointValueSolarChargePower <= 0 ? 0 : pointValueSolarChargePower);

        pointValueSolarBatteryVoltage = parseFloat(coeff4SolarBattVolt) * Math.pow(x,  4) + parseFloat(coeff3SolarBattVolt) * Math.pow(x, 3) + parseFloat(coeff2SolarBattVolt) * Math.pow(x, 2) + parseFloat(coeff1SolarBattVolt) * Math.pow(x, 1) + parseFloat(coeff0SolarBattVolt);
        consumptionsSolarBatteryVoltage.push(pointValueSolarBatteryVoltage <= 0 ? 0 : pointValueSolarBatteryVoltage);

        pointValueSolarBatteryPercentage = parseFloat(coeff4SolarBattPercentage) * Math.pow(x,  4) + parseFloat(coeff3SolarBattPercentage) * Math.pow(x, 3) + parseFloat(coeff2SolarBattPercentage) * Math.pow(x, 2) + parseFloat(coeff1SolarBattPercentage) * Math.pow(x, 1) + parseFloat(coeff0SolarBattPercentage);
        consumptionsSolarBatteryPercentage.push(pointValueSolarBatteryPercentage <= 0 ? 0 : pointValueSolarBatteryPercentage);

        pointValueSolarActivePower = parseFloat(coeff4SolarActivePower) * Math.pow(x,  4) + parseFloat(coeff3SolarActivePower) * Math.pow(x, 3) + parseFloat(coeff2SolarActivePower) * Math.pow(x, 2) + parseFloat(coeff1SolarActivePower) * Math.pow(x, 1) + parseFloat(coeff0SolarActivePower);
        consumptionsSolarActivePower.push(pointValueSolarActivePower <= 0 ? 0 : pointValueSolarActivePower);
      } else if (parameterType === "allegro") {
        pointValueAllegroNetVoltage = parseFloat(coeff3AllegroNetVoltage) * Math.pow(x, 3) + parseFloat(coeff2AllegroNetVoltage) * Math.pow(x, 2) + parseFloat(coeff1AllegroNetVoltage) * Math.pow(x, 1) + parseFloat(coeff0AllegroNetVoltage);
        consumptionsAllegroNetVoltage.push(pointValueAllegroNetVoltage <= 0 ? 0 : pointValueAllegroNetVoltage);
        
        pointValueAllegroApparentPower = parseFloat(coeff3AllegroApparentPower) * Math.pow(x, 3) + parseFloat(coeff2AllegroApparentPower) * Math.pow(x, 2) + parseFloat(coeff1AllegroApparentPower) * Math.pow(x, 1) + parseFloat(coeff0AllegroApparentPower);
        consumptionsAllegroApparentPower.push(pointValueAllegroApparentPower <= 0 ? 0 : pointValueAllegroApparentPower);
        
        pointValueAllegroNetCurrent = parseFloat(coeff3AllegroNetCurrent) * Math.pow(x, 3) + parseFloat(coeff2AllegroNetCurrent) * Math.pow(x, 2) + parseFloat(coeff1AllegroNetCurrent) * Math.pow(x, 1) + parseFloat(coeff0AllegroNetCurrent);
        consumptionsAllegroNetCurrent.push(pointValueAllegroNetCurrent <= 0 ? 0 : pointValueAllegroNetCurrent);
      } else if (parameterType === "daliAllegro") {
        pointValueDaliAllegroLedCurrent = parseFloat(coeff3DaliAllegroLedCurrent) * Math.pow(x, 3) + parseFloat(coeff2DaliAllegroLedCurrent) * Math.pow(x, 2) + parseFloat(coeff1DaliAllegroLedCurrent) * Math.pow(x, 1) + parseFloat(coeff0DaliAllegroLedCurrent);
        consumptionsDaliAllegroLedCurrent.push(pointValueDaliAllegroLedCurrent <= 0 ? 0 : pointValueDaliAllegroLedCurrent);

        pointValueDaliAllegroDriverTemp = parseFloat(coeff3DaliAllegroDriverTemp) * Math.pow(x, 3) + parseFloat(coeff2DaliAllegroDriverTemp) * Math.pow(x, 2) + parseFloat(coeff1DaliAllegroDriverTemp) * Math.pow(x, 1) + parseFloat(coeff0DaliAllegroDriverTemp);
        consumptionsDaliAllegroDriverTemp.push(pointValueDaliAllegroDriverTemp <= 0 ? 0 : pointValueDaliAllegroDriverTemp);

        pointValueDaliAllegroNetVoltage = parseFloat(coeff3DaliAllegroNetVoltage) * Math.pow(x, 3) + parseFloat(coeff2DaliAllegroNetVoltage) * Math.pow(x, 2) + parseFloat(coeff1DaliAllegroNetVoltage) * Math.pow(x, 1) + parseFloat(coeff0DaliAllegroNetVoltage);
        consumptionsDaliAllegroNetVoltage.push(pointValueDaliAllegroNetVoltage <= 0 ? 0 : pointValueDaliAllegroNetVoltage);

        pointValueDaliAllegroApparentPower = parseFloat(coeff3DaliAllegroApparentPower) * Math.pow(x, 3) + parseFloat(coeff2DaliAllegroApparentPower) * Math.pow(x, 2) + parseFloat(coeff1DaliAllegroApparentPower) * Math.pow(x, 1) + parseFloat(coeff0DaliAllegroApparentPower);
        consumptionsDaliAllegroApparentPower.push(pointValueDaliAllegroApparentPower <= 0 ? 0 : pointValueDaliAllegroApparentPower);
        
        pointValueDaliAllegroNetCurrent = parseFloat(coeff3DaliAllegroNetCurrent) * Math.pow(x, 3) + parseFloat(coeff2DaliAllegroNetCurrent) * Math.pow(x, 2) + parseFloat(coeff1DaliAllegroNetCurrent) * Math.pow(x, 1) + parseFloat(coeff0DaliAllegroNetCurrent);
        consumptionsDaliAllegroNetCurrent.push(pointValueDaliAllegroNetCurrent <= 0 ? 0 : pointValueDaliAllegroNetCurrent);
      } else if (parameterType === "shuncom") {
        pointValueShuncomDriverTemp = parseFloat(coeff3ShuncomDriverTemp) * Math.pow(x, 3) + parseFloat(coeff2ShuncomDriverTemp) * Math.pow(x, 2) + parseFloat(coeff1ShuncomDriverTemp) * Math.pow(x, 1) + parseFloat(coeff0ShuncomDriverTemp);
        consumptionsShuncomDriverTemp.push(pointValueShuncomDriverTemp <= 0 ? 0 : pointValueShuncomDriverTemp);

        pointValueShuncomNetVoltage = parseFloat(coeff3ShuncomNetVoltage) * Math.pow(x, 3) + parseFloat(coeff2ShuncomNetVoltage) * Math.pow(x, 2) + parseFloat(coeff1ShuncomNetVoltage) * Math.pow(x, 1) + parseFloat(coeff0ShuncomNetVoltage);
        consumptionsShuncomNetVoltage.push(pointValueShuncomNetVoltage <= 0 ? 0 : pointValueShuncomNetVoltage);

        pointValueShuncomApparentPower = parseFloat(coeff3ShuncomApparentPower) * Math.pow(x, 3) + parseFloat(coeff2ShuncomApparentPower) * Math.pow(x, 2) + parseFloat(coeff1ShuncomApparentPower) * Math.pow(x, 1) + parseFloat(coeff0ShuncomApparentPower);
        consumptionsShuncomApparentPower.push(pointValueShuncomApparentPower <= 0 ? 0 : pointValueShuncomApparentPower);
        
        pointValueShuncomNetCurrent = parseFloat(coeff3ShuncomNetCurrent) * Math.pow(x, 3) + parseFloat(coeff2ShuncomNetCurrent) * Math.pow(x, 2) + parseFloat(coeff1ShuncomNetCurrent) * Math.pow(x, 1) + parseFloat(coeff0ShuncomNetCurrent);
        consumptionsShuncomNetCurrent.push(pointValueShuncomNetCurrent <= 0 ? 0 : pointValueShuncomNetCurrent);
      } else {
        pointValueDaliLedCurrent = parseFloat(coeff3DaliLedCurrent) * Math.pow(x, 3) + parseFloat(coeff2DaliLedCurrent) * Math.pow(x, 2) + parseFloat(coeff1DaliLedCurrent) * Math.pow(x, 1) + parseFloat(coeff0DaliLedCurrent);
        consumptionsDaliLedCurrent.push(pointValueDaliLedCurrent <= 0 ? 0 : pointValueDaliLedCurrent);

        pointValueDaliDriverTemp = parseFloat(coeff3DaliDriverTemp) * Math.pow(x, 3) + parseFloat(coeff2DaliDriverTemp) * Math.pow(x, 2) + parseFloat(coeff1DaliDriverTemp) * Math.pow(x, 1) + parseFloat(coeff0DaliDriverTemp);
        consumptionsDaliDriverTemp.push(pointValueDaliDriverTemp <= 0 ? 0 : pointValueDaliDriverTemp);

        pointValueDaliNetVoltage = parseFloat(coeff3DaliNetVoltage) * Math.pow(x, 3) + parseFloat(coeff2DaliNetVoltage) * Math.pow(x, 2) + parseFloat(coeff1DaliNetVoltage) * Math.pow(x, 1) + parseFloat(coeff0DaliNetVoltage);
        consumptionsDaliNetVoltage.push(pointValueDaliNetVoltage <= 0 ? 0 : pointValueDaliNetVoltage);

        pointValueDaliApparentPower = parseFloat(coeff3DaliApparentPower) * Math.pow(x, 3) + parseFloat(coeff2DaliApparentPower) * Math.pow(x, 2) + parseFloat(coeff1DaliApparentPower) * Math.pow(x, 1) + parseFloat(coeff0DaliApparentPower);
        consumptionsDaliApparentPower.push(pointValueDaliApparentPower <= 0 ? 0 : pointValueDaliApparentPower);
      }

      const day = new Date(graphicDateCursorInSeconds * 1000);
      const dayOptions:any = { month: '2-digit', day: 'numeric' };
      const hourOptions:any = { hour: '2-digit', minute: '2-digit' };
      const formattedDay = day.toLocaleDateString('es-ES', dayOptions);
      const formattedHour = day.toLocaleTimeString('es-ES', hourOptions);
      humanFormatHours.push(formattedDay + " - " + formattedHour);

      if (parameterType === "solar") {
        resultData.push({strategy: strategyIndex, x: x, y: pointValueSolarChargePower})
      } else if (parameterType === "allegro") {
        resultData.push({strategy: strategyIndex, x: x, y: pointValueAllegroNetVoltage})
      } else if (parameterType === "daliAllegro") {
        resultData.push({strategy: strategyIndex, x: x, y: pointValueDaliAllegroLedCurrent})
      } else if (parameterType === "shuncom") {
        resultData.push({strategy: strategyIndex, x: x, y: pointValueShuncomDriverTemp})
      } else {
        resultData.push({strategy: strategyIndex, x: x, y: pointValueDaliLedCurrent})
      }
    }
    graphicDateCursorInSeconds += pointInterval;
  }

  if (parameterType === "solar") {
    createSolarParameterData(consumptionsSolarChargePower, consumptionsSolarBatteryVoltage, consumptionsSolarBatteryPercentage, consumptionsSolarActivePower, humanFormatHours, parameterType, csv)
  } else if (parameterType === "allegro") {
    createAllegroParameterData(consumptionsAllegroNetVoltage, consumptionsAllegroApparentPower, consumptionsAllegroNetCurrent, humanFormatHours, parameterType, csv)
  } else if (parameterType === "daliAllegro") {
    createDaliAllegroParameterData(consumptionsDaliAllegroLedCurrent, consumptionsDaliAllegroDriverTemp, consumptionsDaliAllegroNetVoltage, consumptionsDaliAllegroApparentPower, consumptionsDaliAllegroNetCurrent, humanFormatHours, parameterType, csv)
  } else if (parameterType === "shuncom") {
    createShuncomParameterData(consumptionsShuncomDriverTemp, consumptionsShuncomNetVoltage, consumptionsShuncomApparentPower, consumptionsShuncomNetCurrent, humanFormatHours, parameterType, csv)
  } else {
    createDaliParameterData(consumptionsDaliLedCurrent, consumptionsDaliDriverTemp, consumptionsDaliNetVoltage, consumptionsDaliApparentPower, humanFormatHours, parameterType, csv)
  }
}

const handleDownload = (data:any, parameterType: any) => {
  const formatData = (data: any[][]) => {
    return data.map(row =>
      row.map(item => typeof item === 'number' ? item.toFixed(2) : item)
    );
  };

  const transpose = (data: any[][]) => {
    const maxLength = Math.max(...data.map(row => row.length));
    return Array.from({ length: maxLength }, (_, colIndex) =>
      data.map(row => row[colIndex] || "")
    );
  };

  const columnHeaders = parameterType === "solar" ?
    ["Date - Hour", "Charge power", "Battery voltage", "Battery %", "Active power"]
  : parameterType === "allegro" ?
      ["Date - Hour", "Net voltage (V)", "Apparent power (W)", "Net current (mA)"]
    : parameterType === "daliAllegro" ?
        ["Date - Hour", "Led current (mA)", "Driver temp (ºC)", "Net voltage (V)", "Apparent power (W)", "Net current (mA)"]
      : parameterType === "shuncom" ?
          ["Date - Hour", "Driver temp (ºC)", "Net voltage (V)", "Apparent power (W)", "Net current (mA)"]
        :
          ["Date - Hour", "Led current (mA)", "Driver temp (ºC)", "Net voltage (V)", "Apparent power (W)"]

  const formattedData = formatData(data);
  const transposedData = transpose(formattedData);
  transposedData.unshift(columnHeaders);

  const csvContent = transposedData.map((e:any) => e.join(";")).join("\r\n");
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'consumptions.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const createDaliParameterData = async (parametersDaliLedCurrent: any, parametersDaliDriverTemp: any, parametersDaliNetVoltage: any, parametersDaliApparentPower: any, hours: any, parameterType?: string, csv?: string) => {
  if (csv) {
    let data = [hours, parametersDaliLedCurrent, parametersDaliDriverTemp, parametersDaliNetVoltage, parametersDaliApparentPower]
    handleDownload(data, parameterType);
  } else {
    store.dispatch(getDaliInfoActionCreator({ ledCurrentDali: parametersDaliLedCurrent, driverTempDali: parametersDaliDriverTemp, netVoltageDali: parametersDaliNetVoltage,
      apparentPowerDali: parametersDaliApparentPower, timestamps: hours }))
  }
};

export const createSolarParameterData = async (parametersSolarChargePower: any, parametersSolarBatteryVoltage: any, parametersSolarBatteryPercentage: any, parametersSolarActivePower: any, hours: any, parameterType?: string, csv?: string) => {
  if (csv) {
    let data = [hours, parametersSolarChargePower, parametersSolarBatteryVoltage, parametersSolarBatteryPercentage, parametersSolarActivePower]
    handleDownload(data, parameterType);
  } else {
    store.dispatch(getSolarConsumptionActionCreator({ chargePower: parametersSolarChargePower, batteryVoltage: parametersSolarBatteryVoltage, batteryPercentage: parametersSolarBatteryPercentage,
      activePower: parametersSolarActivePower, timestamps: hours }))
  }
};

export const createAllegroParameterData = async (parametersAllegroNetVoltage: any, parametersAllegroApparentPower: any, parametersAllegroNetCurrent: any, hours: any, parameterType?: string, csv?: string) => {
  if (csv) {
    let data = [hours, parametersAllegroNetVoltage, parametersAllegroApparentPower, parametersAllegroNetCurrent]
    handleDownload(data, parameterType);
  } else {
    store.dispatch(getAllegroInfoActionCreator({ netVoltageAllegro: parametersAllegroNetVoltage, apparentPowerAllegro: parametersAllegroApparentPower, netCurrentAllegro: parametersAllegroNetCurrent,
      timestamps: hours }))
  }
};

export const createDaliAllegroParameterData = async (parametersDaliAllegroLedCurrent: any, parametersDaliAllegroDriverTemp: any, parametersDaliAllegroNetVoltage: any, parametersDaliAllegroApparentPower: any, parametersDaliAllegroNetCurrent: any, hours: any, parameterType?: string, csv?: string) => {
  if (csv) {
    let data = [hours, parametersDaliAllegroLedCurrent, parametersDaliAllegroDriverTemp, parametersDaliAllegroNetVoltage, parametersDaliAllegroApparentPower, parametersDaliAllegroNetCurrent]
    handleDownload(data, parameterType);
  } else {
    store.dispatch(getDaliAllegroInfoActionCreator({ ledCurrentDaliAllegro: parametersDaliAllegroLedCurrent, driverTempDaliAllegro: parametersDaliAllegroDriverTemp, netVoltageDaliAllegro: parametersDaliAllegroNetVoltage,
      apparentPowerDaliAllegro: parametersDaliAllegroApparentPower, netCurrentDaliAllegro: parametersDaliAllegroNetCurrent, timestamps: hours }))
  }
};

export const createShuncomParameterData = async (parametersShuncomDriverTemp: any, parametersShuncomNetVoltage: any, parametersShuncomApparentPower: any, parametersShuncomNetCurrent: any, hours: any, parameterType?: string, csv?: string) => {
  if (csv) {
    let data = [hours, parametersShuncomDriverTemp, parametersShuncomNetVoltage, parametersShuncomApparentPower, parametersShuncomNetCurrent]
    handleDownload(data, parameterType);
  } else {
    store.dispatch(getShuncomInfoActionCreator({ driverTempShuncom: parametersShuncomDriverTemp, netVoltageShuncom: parametersShuncomNetVoltage, apparentPowerShuncom: parametersShuncomApparentPower,
      netCurrentShuncom: parametersShuncomNetCurrent, timestamps: hours }))
  }
};
  
export const createParameterData = async (parameters: any, hours: any, parameterType: string) => {
  let labels: any = [];

  for (let i = 0; i < hours.length; i++) {
    if (i % 12 === 0 && i !== 0) {
      labels.push(hours[i]);
    } else if (i === 0) {
      labels.push(hours[i]);
    } else if (i === hours.length - 1) {
      labels.push(hours[i]);
    } else {
      labels.push("");
    }
  }

  /* Los números que dividen a 240 de manera exacta, es decir, los divisores de 240, son:
    1
    2
    3
    4
    5
    6
    8
    10
    12
    15
    16
    20
    24
    30
    40
    48
    60
    80
    120
    240
  */
};
