import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { RootState, store } from "../../redux/store";
import { fetchAlarms } from "../../redux/actions/alarmsActions/alarmsActions";
import { loadNodesInAreaActionCreator } from "../../redux/slices/devices/devicesSlice";
import { filteredNodesInAreaActionCreator } from "../../redux/slices/devices/filteredDevicesSlice";
import { setSelectGroupActionCreator, setSelectedDevicesListActionCreator, setRemovedDevicesListActionCreator } from "../../redux/slices/groupsSlice/groupsSlice";
import {changeSideMenuActionCreator, setAlarmsVisibleActionCreator, setGroupsVisibleActionCreator, setEventsVisibleActionCreator, setIsFullViewActionCreator,
  setReportsVisibleActionCreator, setFirstRenderMapToTrueActionCreator, setIsOpenedDetailActionCreator, setIsOpenedGroupActionCreator, turnRgbFalseActionCreator
} from "../../redux/slices/uiSlice/uiSlice";
import { useAlarmsService } from "../../services/alarmService";
import { useReportsService } from "../../services/reportsService";
import { Header } from "../Header/Header";
import { ContainerMain, Sidemenu, SidemenuLogo, SidemenuTopIcon, SidemenuIcon, SidemenuDownIcon, Headertest, ContainerChildren, ChildrenComponent, HeadertestFullView,
  ContainerChildrenFullView } from "./LayoutStyled";
import balady from "../../assets/new_icons/balady.svg";
import defaultProfile from "../../assets/new_icons/default-profile.png";
import events from "../../assets/new_icons/clock layout.svg";
import groups from "../../assets/new_icons/Groups layout.svg";
import moon from "../../assets/moon.svg";
import SmartecNewLogo from "../../assets/new_icons/Smartec-Icon-Logo.svg"
import SmartecSmallLogo from "../../assets/new_icons/S-Smartec.svg";
import { ReactComponent as AlarmIcon } from "../../assets/new_icons/Alarm layout.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/new_icons/Arrow left dynamic.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/new_icons/Arrow right dynamic.svg";
import { ReactComponent as GridIcon } from "../../assets/new_icons/grid.svg";
import { ReactComponent as GroupsIcon } from "../../assets/new_icons/Groups layout.svg";
import { ReactComponent as ReportsIcon } from "../../assets/new_icons/Reports.svg";
import { ReactComponent as StrategiesIcon } from "../../assets/new_icons/big-point layout.svg";

interface LayoutProps {
  children: JSX.Element | JSX.Element[];
  style?: object;
}

export const Layout = ({ children }: LayoutProps): JSX.Element => {
  const alerts = false;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getAlarms } = useAlarmsService();
  const { getGeneralReports, getStandardReports } = useReportsService();
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const isSideMenuVisible = useAppSelector((state) => state.ui.isSideMenuVisible);
  const isHomeVisible = useAppSelector((state) => state.ui.isHomeVisible);
  const isAlarmsVisible = useAppSelector((state) => state.ui.isAlarmsVisible);
  const isGroupsVisible = useAppSelector((state) => state.ui.isGroupsVisible);
  const isStrategiesVisible = useAppSelector((state) => state.ui.isStrategiesVisible);
  const isReportsVisible = useAppSelector((state) => state.ui.isReportsVisible);
  const isEventsVisible = useAppSelector((state) => state.ui.isEventsVisible);
  const selectedNodeId = useAppSelector((state) => state.manageNode.node_id);
  const user = useAppSelector((state: RootState) => state.user);
  const navBarColor = useAppSelector((state: RootState) => state.selectedOrganization.navBarColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;
  const isStrategiesPage = pathname === "/strategies";
  const isReportsPage = pathname === "/reports";
  const isLuminairePage = pathname === `/luminaire/${selectedNodeId}`;
  const isOrgSettingsPage = pathname === "/organization-settings";
  const isProfilePage = pathname === "/profile";

  const [dashboardOpacity, setDashboardOpacity]: any = useState(isHomeVisible ? 1 : 0.2);
  const [alarmsOpacity, setAlarmsOpacity] = useState(isAlarmsVisible ? 1 : 0.2);
  const [groupsOpacity, setGroupsOpacity] = useState(isGroupsVisible ? 1 : 0.2);
  const [strategiesOpacity, setStrategiesOpacity] = useState(isStrategiesVisible ? 1 : 0.2);
  const [eventsOpacity, setEventsOpacity] = useState(isEventsVisible ? 1 : 0.2);
  const [reportsOpacity, setReportsOpacity] = useState(isReportsVisible ? 1 : 0.2);
  const [moonOpacity, setMoonOpacity] = useState(0.2);
  const [loading, setLoading] = useState(false);

  const closePopUps = () => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
  };

  const goToAlarms = async () => {
    try {
      const token:any = Cookies.get("token");
      setLoading(true);
      let response = await getAlarms(selectedOrgId, selectedServiceId, token, setLoading);
      if (response) {
        store.dispatch(fetchAlarms(response));
      } else {
        store.dispatch(fetchAlarms([]));
      }
    } catch (error) {
      console.error("Error fetching alarm data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setDashboardOpacity(isHomeVisible ? 1 : 0.2);
    setAlarmsOpacity(isAlarmsVisible ? 1 : 0.2);
    setGroupsOpacity(isGroupsVisible ? 1 : 0.2);
    setStrategiesOpacity(isStrategiesVisible ? 1 : 0.2);
    setEventsOpacity(isEventsVisible ? 1 : 0.2);
    setReportsOpacity(isReportsVisible ? 1 : 0.2);
  }, [
    isHomeVisible,
    isAlarmsVisible,
    isGroupsVisible,
    isStrategiesVisible,
    isEventsVisible,
  ]);

  useEffect(() => {
    const token = Cookies.get("token");

    if (!token) {
      navigate("/");
    }
  }, []);
  //-----------------------------

  useEffect(() => {
    const selectedLanguage = localStorage.getItem("selectedLanguage");

    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, [i18n]);

  return (
    <ContainerMain style={{ height: isStrategiesPage ? "calc(150vh - 70px)" : isOrgSettingsPage ? "calc(167.6vh - 70px)" /* "calc(100vh - 70px)" */ : isLuminairePage ?
      "calc(120vh - 70px)" : isProfilePage ? "calc(130.5vh - 70px)" : "calc(100vh - 70px)" }}
    >
      {isSideMenuVisible ? (
        <>
          <Sidemenu style={{ background: navBarColor }}>
            <SidemenuLogo
              title={t("Dashboard")}
              onClick={async () => {
                closePopUps();
                dispatch(setIsFullViewActionCreator());
                dispatch(setFirstRenderMapToTrueActionCreator());
                dispatch(turnRgbFalseActionCreator());
                navigate("/dashboard");
              }}
              style={{ cursor: "pointer" }}
              /* src={SmartecSmallLogo} */
              src={SmartecNewLogo}
              /* src={balady} */
            />
            <SidemenuTopIcon>
              <GridIcon
                height={16} style={{ color: supportTextColor, cursor: "pointer", opacity: dashboardOpacity, margin: "48px 22px", display: "block" }}
                title={t("Dashboard")}
                onClick={() => {
                  dispatch(turnRgbFalseActionCreator());
                  dispatch(setIsFullViewActionCreator());
                  dispatch(setFirstRenderMapToTrueActionCreator());
                  navigate("/dashboard");
                }}
                onMouseEnter={() => !isHomeVisible ? setDashboardOpacity(1) : <></>}
                onMouseLeave={() => !isHomeVisible ? setDashboardOpacity(0.2) : <></>}
              />
              <AlarmIcon
                height={18} style={{ color: supportTextColor, cursor: "pointer", opacity: alarmsOpacity, margin: "48px 15px", display: "block" }}
                title={t("Alarms")}
                onClick={async () => {
                  closePopUps();
                  /* const token:any = Cookies.get("token");
                  await getAlarms(selectedOrgId, selectedServiceId, token, setLoading); */
                  await goToAlarms();
                  dispatch(setAlarmsVisibleActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/alarms");
                }}
                onMouseEnter={() => !isAlarmsVisible ? setAlarmsOpacity(1) : <></>}
                onMouseLeave={() => !isAlarmsVisible ? setAlarmsOpacity(0.2) : <></>}
              />
              {/* <SidemenuIcon
                title={t("Dashboard")}
                onClick={() => {
                  dispatch(turnRgbFalseActionCreator());
                  dispatch(setIsFullViewActionCreator());
                  dispatch(setFirstRenderMapToTrueActionCreator());
                  navigate("/dashboard");
                }}
                style={{ cursor: "pointer", opacity: dashboardOpacity }}
                src={grid}
                onMouseEnter={() => !isHomeVisible ? setDashboardOpacity(1) : <></>}
                onMouseLeave={() => !isHomeVisible ? setDashboardOpacity(0.2) : <></>}
              />
              <SidemenuIcon
                title={t("Alarms")}
                onClick={async () => {
                  closePopUps();
                  /* const token:any = Cookies.get("token");
                  await getAlarms(selectedOrgId, selectedServiceId, token, setLoading);
                  await goToAlarms();
                  dispatch(setAlarmsVisibleActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/alarms");
                }}
                style={{ cursor: "pointer", opacity: alarmsOpacity }}
                src={alarm}
                onMouseEnter={() => !isAlarmsVisible ? setAlarmsOpacity(1) : <></>}
                onMouseLeave={() => !isAlarmsVisible ? setAlarmsOpacity(0.2) : <></>}
              /> */}
              <GroupsIcon
                height={18} style={{ color: supportTextColor, cursor: "pointer", opacity: groupsOpacity, margin: "48px 21px", display: "block" }}
                title={t("Groups")}
                onClick={async () => {
                  closePopUps();
                  dispatch(setSelectGroupActionCreator({ selectedGroup: [] }));
                  dispatch(setRemovedDevicesListActionCreator({ removedDevices: [] }));
                  dispatch(loadNodesInAreaActionCreator([]));
                  dispatch(filteredNodesInAreaActionCreator([]));
                  dispatch(setGroupsVisibleActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/groups");
                }}
                onMouseEnter={() => !isGroupsVisible ? setGroupsOpacity(1) : <></>}
                onMouseLeave={() => !isGroupsVisible ? setGroupsOpacity(0.2) : <></>}
              />
              <StrategiesIcon
                height={16} style={{ color: supportTextColor, cursor: "pointer", opacity: strategiesOpacity, margin: "48px 22px", display: "block" }}
                title={t("Strategies")}
                onClick={async () => {
                  closePopUps();
                  dispatch(setIsFullViewActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/strategies");
                }}
                onMouseEnter={() => !isStrategiesVisible ? setStrategiesOpacity(1) : <></>}
                onMouseLeave={() => !isStrategiesVisible ? setStrategiesOpacity(0.2) : <></>}
              />
              {/* <SidemenuIcon
                title={t("Strategies")}
                onClick={async () => {
                  closePopUps();
                  await getStrategies(selectedOrgId, selectedServiceId);
                  dispatch(setIsFullViewActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/strategies");
                  }}
                style={{ cursor: "pointer", opacity: strategiesOpacity }}
                src={strategies}
                onMouseEnter={() => !isStrategiesVisible ? setStrategiesOpacity(1) : <></>}
                onMouseLeave={() => !isStrategiesVisible ? setStrategiesOpacity(0.2) : <></>}
              /> */}
              {/* <SidemenuIcon
                title={t("Events")}
                onClick={() => {
                  closePopUps();
                  dispatch(setEventsVisibleActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/events");
                  }}
                style={{ cursor: "pointer", opacity: eventsOpacity }}
                src={events}
                onMouseEnter={() => !isEventsVisible ? setEventsOpacity(1) : <></>}
                onMouseLeave={() => !isEventsVisible ? setEventsOpacity(0.2) : <></>}
              /> */}
              <ReportsIcon
                height={17} style={{ color: supportTextColor, cursor: "pointer", opacity: reportsOpacity, margin: "48px 15px", display: "block" }}
                title={t("reports")}
                onClick={async () => {
                  closePopUps();
                  dispatch(setIsFullViewActionCreator());
                  dispatch(setReportsVisibleActionCreator());
                  dispatch(turnRgbFalseActionCreator());
                  navigate("/reports");
                }}
                onMouseEnter={() => !isReportsVisible ? setReportsOpacity(1) : <></>}
                onMouseLeave={() => !isReportsVisible ? setReportsOpacity(0.2) : <></>}
              />
            </SidemenuTopIcon>
            <SidemenuDownIcon>
              {/* <SidemenuIcon title={txt.darkLight} src={moon} style={{ cursor: "pointer", opacity: moonOpacity }}
                onMouseEnter={() => moonOpacity === 0.2 ? setMoonOpacity(1) : <></>} onMouseLeave={() => moonOpacity === 1 ? setMoonOpacity(0.2) : <></>}
              /> */}
              <ArrowLeftIcon
                height={16} style={{ color: supportTextColor, cursor: "pointer", margin: "48px 14px", display: "block" }}
                title={t("Expand/Decrease")}
                onClick={() => {
                  closePopUps();
                  dispatch(changeSideMenuActionCreator());
                }}
              />
              {/* <SidemenuIcon
                title={t("Expand/Decrease")}
                src={chevronLeft}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  closePopUps();
                  dispatch(changeSideMenuActionCreator());
                }}
              /> */}
            </SidemenuDownIcon>
          </Sidemenu>
          <Headertest>
            <Header
              alerts={alerts}
              profileImg={defaultProfile}
              user={user}
            ></Header>
          </Headertest>
          <ContainerChildren>
            <ChildrenComponent>{children}</ChildrenComponent>
          </ContainerChildren>
        </>
      ) : (
        <>
          <SidemenuDownIcon
            style={{ height: "108px", left: "-18px", zIndex: 3 }}
          >
            <ArrowRightIcon
              height={16} style={{ color: accentButtonsColor, cursor: "pointer", margin: "48px 22px", display: "block", width: "50px", height: "50px", borderRadius: "50%",
                background: "white", padding: "17px", boxShadow: "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
              title={t("Expand/Decrease")}
              onClick={() => {
                closePopUps();
                dispatch(changeSideMenuActionCreator());
              }}
            />
            {/* <SidemenuIcon
              src={chevronRight}
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                background: "white",
                cursor: "pointer",
                padding: "17px",
                boxShadow:
                  "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)"
              }}
              onClick={() => {
                closePopUps();
                dispatch(changeSideMenuActionCreator());
              }}
            /> */}
          </SidemenuDownIcon>
          <HeadertestFullView>
            <Header
              alerts={alerts}
              profileImg={defaultProfile}
              user={user}
            ></Header>
          </HeadertestFullView>
          <ContainerChildrenFullView style={{ paddingLeft: "30px" }}>
            <ChildrenComponent>{children}</ChildrenComponent>
          </ContainerChildrenFullView>
        </>
      )}
    </ContainerMain>
  );
};
