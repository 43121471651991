import styled from "styled-components";
import { BoxSizes } from "../../styles/shared-styles/box-sizes";
import { styledMainTheme } from "../../styles/shared-styles/styleMainTheme";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  display: grid;
  /* align-content: center; */
  background-size: cover;
  background-position: center;
`;

export const LoginOverlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
`;

export const LoginOverlayWhite = styled.div`
  background-color: rgba(255, 255, 255, 0.774);
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
`;

export const LoginOverlayBlue = styled.div`
  background-color: #0500FF;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
`;

export const LoginForm = styled(BoxSizes)`
  width: 50%;
  margin: auto;
  padding: 36px;
  border: none;
  background-color: transparent;

  @media screen and (max-width: 1180px) {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const ButtonLogin = styled.button`
  padding: 8px 48px;
  margin-top: 16px;
  border-radius: 8px;
  background-color: ${(props: {
    buttonType: "primary" | "secondary" | "link" | "";
  }) =>
    props.buttonType === "primary"
      ? styledMainTheme.light.buttonMainColor
      : props.buttonType === "secondary"
      ? styledMainTheme?.light?.mainBackgroundColor
      : "red"};
  color: #1b2559;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  outline: none;
  border: none;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px rgba(0, 0, 0, 0.3);
`;

export const ImageLogo = styled.img`
  position: absolute;
  top: -28vh;
  bottom: 10vh;
  /* left: 55vh; */
  left: 70vh;
  height: 42px;
`;
