import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SolarParameters } from "../../../types/interfaces";

const initialState: SolarParameters = {
  chargePower: "",
  batteryVoltage: "",
  batteryPercentage: "",
  activePower: "",
  timestamps: "",
  cLimitVolt: "",
  cReturnVolt: "",
  disVolt: "",
  disReturnVolt: "",
  sysVolt: "",
  battType: ""
};

const solarParametersSlice = createSlice({
  name: "solarParameters",
  initialState: initialState,
  reducers: {
    getSolarConsumption: (previousData, action: PayloadAction<SolarParameters>) => ({
      ...previousData,
      chargePower: action.payload.chargePower,
      batteryVoltage: action.payload.batteryVoltage,
      batteryPercentage: action.payload.batteryPercentage,
      activePower: action.payload.activePower,
      timestamps: action.payload.timestamps
    }),

    resetSolarParametersConsumption: (previousData) => ({
      ...previousData,
      chargePower: "",
      batteryVoltage: "",
      batteryPercentage: "",
      activePower: "",
      timestamps: ""
    }),

    getSolarBattery: (previousData, action: PayloadAction<SolarParameters>) => ({
      ...previousData,
      cLimitVolt: action.payload.cLimitVolt,
      cReturnVolt: action.payload.cReturnVolt,
      disVolt: action.payload.disVolt,
      disReturnVolt: action.payload.disReturnVolt,
      sysVolt: action.payload.sysVolt,
      battType: action.payload.battType
    }),

    resetSolarParametersBattery: (previousData) => ({
      ...previousData,
      cLimitVolt: "",
      cReturnVolt: "",
      disVolt: "",
      disReturnVolt: "",
      sysVolt: "",
      battType: ""
    }),
  },
});

export const solarParametersReducer = solarParametersSlice.reducer;

export const {
  getSolarConsumption: getSolarConsumptionActionCreator,
  resetSolarParametersConsumption: resetSolarParametersConsumptionActionCreator,
  getSolarBattery: getSolarBatteryActionCreator,
  resetSolarParametersBattery: resetSolarParametersBatteryActionCreator,
} = solarParametersSlice.actions;
