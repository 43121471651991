import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import styled from "styled-components";
import { useAppSelector } from "../../../redux/hooks";
import { ButtonStrategy } from "../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../styles/shared-styles/text-sizes";

interface PlatformPaletteModalProps {
  showModal: boolean;
  setShowModal: any;
  colorToChange: any;
  openNavBarColor: boolean | any;
  setOpenNavBarColor: any;
  openButtonsColor: boolean | any;
  setOpenButtonsColor: any;
  openAccentButtonsColor: boolean | any;
  setOpenAccentButtonsColor: any;
  openMainTextColor: boolean | any;
  setOpenMainTextColor: any;
  openSupportTextColor: boolean | any;
  setOpenSupportTextColor: any;
  openAccentTextColor: boolean | any;
  setOpenAccentTextColor: any;
  setTempNavBarColor: any;
  setTempButtonsColor: any;
  setTempAccentButtonsColor: any;
  setTempMainTextColor: any;
  setTempSupportTextColor: any;
  setTempAccentTextColor: any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 900px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 740px;
    width: 90%;
    background: white;
    border: 0px;
  }
`;

export const PlatformPaletteModal = ({ showModal, setShowModal, colorToChange, openNavBarColor, setOpenNavBarColor, openButtonsColor, setOpenButtonsColor, openAccentButtonsColor, setOpenAccentButtonsColor,
  openMainTextColor, setOpenMainTextColor, openSupportTextColor, setOpenSupportTextColor, openAccentTextColor, setOpenAccentTextColor, setTempNavBarColor, setTempButtonsColor,
  setTempAccentButtonsColor, setTempMainTextColor, setTempSupportTextColor, setTempAccentTextColor }: PlatformPaletteModalProps) => {
  
  const [color, setColor] = useColor(colorToChange);
  const [showPreventPopup, setShowPreventPopup] = useState(false);

  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  const handlePreventPopup = () => {
    setShowPreventPopup(true);
  };

  const resetStates = () => {
    setOpenNavBarColor(false);
    setOpenButtonsColor(false);
    setOpenAccentButtonsColor(false);
    setOpenMainTextColor(false);
    setOpenSupportTextColor(false);
    setOpenAccentTextColor(false);
    setShowModal(!showModal)
  };

  const sendInfo = async () => {
    if (openNavBarColor) {
      setTempNavBarColor(color.hex)
    } else if (openButtonsColor) {
      setTempButtonsColor(color.hex)
    } else if (openAccentButtonsColor) {
      setTempAccentButtonsColor(color.hex)
    } else if (openMainTextColor) {
      setTempMainTextColor(color.hex)
    } else if (openSupportTextColor) {
      setTempSupportTextColor(color.hex)
    } else if (openAccentTextColor) {
      setTempAccentTextColor(color.hex)
    }

    resetStates();
  };

  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      {showPreventPopup ?
        <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)",
          borderRadius: "14px", padding: "30px", display: "flex", flexDirection: "column", position: "absolute",  alignItems: "center",
          justifyContent: "center", top: "300px", left: "182px", zIndex: 30
        }}>
          <Text>{t("Do you want to close this form? All the information will be lost")}</Text>
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {resetStates()}}>{t("Accept")}</button>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowPreventPopup(false)}}>{t("Cancel")}</button>
          </div>
        </div>
      :
        <></>
      }
      <Modal.Body style={{ pointerEvents: showPreventPopup ? "none" : "auto", background: "white", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px",
        paddingRight: "0px", borderTopRightRadius: "0.5rem", borderTopLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem", borderBottomLeftRadius: "0.5rem", display: "flex",
        flexDirection: "column"}}
      >
        <div style={{ display: "flex", width: "100%", padding: "8px 10px 0px 0px", flexDirection: "column", background: "#ffffff", borderTopRightRadius: "0.5rem",
          borderTopLeftRadius: "0.5rem", justifyContent: "flex-end" }}
        >
          <Modal.Header
            closeButton
            style={{
              borderBottom: "none",
              background: "white",
              zIndex: 2
            }}
          />
        </div>
        <div style={{ paddingLeft: "50px", paddingRight: "50px", paddingTop: "10px", display: "flex", flexDirection: "column" }}>
          <div>
            <Text fontWeight="700" marginBottom="5px" color={mainTextColor} style={{fontSize: "22px" }}>{t("setDefaultPaletteTitle")}</Text>
            <Text fontWeight="400" marginBottom="5px" color={mainTextColor} style={{fontSize: "16px" }}>{t("setDefaultPaletteSubtitle")}</Text>
          </div>
          <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
            <ColorPicker height={260} color={color} onChange={setColor} hideAlpha={true} hideInput={["hsv"]} />
          </div>
          <div style={{display: "flex", justifyContent: "center", alignContent: "flex-end"}}>
            <ButtonStrategy buttonType="primary" style={{ marginLeft: "0px", cursor: "pointer", width: "300px", marginTop: "30px", padding: "8px 32px", lineHeight: "none",
              background: buttonsColor, color: accentTextColor }}
              onClick={() => {sendInfo()}}
            >
              {t("assign")}
            </ButtonStrategy>
          </div>
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
