import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import authService from "../../services/authService";
import InputForm from "../../components/Input/Input";
import { validPassword } from "../../utils/regex";
import { Container, LoginOverlay, LoginForm, LoginOverlayWhite, ButtonLogin, ImageLogo } from "../Login/LoginStyled";
import { ButtonLoading } from "../../styles/shared-styles/buttons-sizes";
import { Text, TextBox, TextSpan } from "../../styles/shared-styles/text-sizes";
import check from "../../assets/new_icons/check-password.svg";
import locker_icon from "../../assets/new_icons/lock.svg";
import logo from "../../assets/new_icons/smartec-logo.svg";
import mail from "../../assets/new_icons/mail.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/new_icons/Arrow left dynamic.svg";

export const ResetPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorInvalidPassword, setErrorInvalidPassword] = useState<boolean>(false);
  const [errorNewPassword, setErrorNewPassword] = useState<boolean>(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  const handleReset = async () => {
    if (!validPassword.test(password)) {
      setErrorInvalidPassword(true);
      return;
    } else if(password !== confirmPassword) {
      setErrorNewPassword(true);
      return;
    }

    setIsLoading(true);
    const res = await authService.smartecResetPassword(password, confirmPassword, searchParams.get('token'));
      if (res?.data) {
        setReset(true);
        setIsLoading(false);
      } else {
        setErrorUpdate(true);
        setIsLoading(false);
      }
  };

  const checkEnter = (e: any) => {
    if (e.charCode === 13) {
      handleReset();
    }
  };

  return (
    <>
      <Container className="login_container">
        <div className="row">
          <div className="col-6">
            <LoginOverlay className="overlay" />
          </div>
          <div className="col-6">
            <Container>
              <LoginOverlayWhite className="overlay" />
              {!reset ?
                <LoginForm padding="large" className="card" borderRadius="large">
                  <div className="row">
                    <div className="col-12 ">
                      <ImageLogo src={logo} />
                    </div>
                  </div>
                  <div className="col-12 text-left">
                    <TextBox
                      textType="title"
                      fontWeight="700"
                      marginBottom="32px"
                    >
                      {t("resetPassword")}
                    </TextBox>
                  </div>
                  <InputForm
                    preffix_icon={locker_icon}
                    suffix_icon={mail}
                    inputType="password"
                    placeholder={t("New Password")}
                    marginBottom="small"
                    onChange={(e) => {setPassword(e.target.value); setErrorInvalidPassword(false); setErrorNewPassword(false)}}
                    onKeyPress={(e) => {checkEnter(e)}}
                  />
                  <InputForm
                    preffix_icon={locker_icon}
                    suffix_icon={mail}
                    inputType="password"
                    placeholder={t("Confirm Password")}
                    marginBottom="small"
                    onChange={(e) => {setConfirmPassword(e.target.value); setErrorNewPassword(false)}}
                    onKeyPress={(e) => {checkEnter(e)}}
                  />                  
                  {errorInvalidPassword ?
                    <TextSpan style={{ marginTop: "-6px", width: "400px" }} color="red">{t("errorInvalidPassword")}</TextSpan>
                  :
                    errorNewPassword ?
                      <TextSpan style={{ marginTop: "-6px", width: "400px" }} color="red">{t("errorNewPassword")}</TextSpan>
                    :
                      errorUpdate ?
                        <TextSpan style={{ marginTop: "-6px", width: "400px" }} color="red">{t("errorUpdating")}</TextSpan>
                      : 
                        <></>
                  }
                  <div className="col-12 text-left">
                    {isLoading ? (
                      <ButtonLoading
                        style={{ marginTop: errorInvalidPassword || errorNewPassword || errorUpdate ? "11px" : "26px", background: buttonsColor, color: accentTextColor }}
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {t("loading...")}
                      </ButtonLoading>
                    ) : (
                      <ButtonLogin
                      style={{ marginTop: errorInvalidPassword || errorNewPassword || errorUpdate ? "11px" : "26px", background: buttonsColor, color: accentTextColor }}
                        buttonType="primary"
                        onClick={() => {handleReset()}}
                      >
                        {t("resetPassword")}
                      </ButtonLogin>
                    )}
                  </div>
                </LoginForm>
              :
                <LoginForm padding="large" className="card" borderRadius="large">
                  <div className="col-12" style={{ display: "flex", marginTop: "10px", marginBottom: "10px" }}>
                    <ArrowLeftIcon
                      height={16} style={{ color: "#525252", position: "absolute", top: "-31.1vh", bottom: "10vh", left: "-21vh", width: "50px", height: "50px", cursor: "pointer",
                        borderRadius: "50%", background: "white", padding: "17px", boxShadow:"0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)" }}
                      onClick={() => {
                        navigate("/");
                      }}
                    />
                    <ImageLogo style={{ top: "-30.9vh" }} src={logo} />
                  </div>
                  <div className="col-12 text-left" style={{ display: "flex", gap: "16px", marginLeft: "-46px" }}>
                    <img src={check} style={{ alignSelf: "start", marginTop: "4px" }} />
                    <div>
                      <Text textType="title" fontWeight="700" marginBottom="10px">
                        {t("passwordResetTitle")}
                      </Text>
                      <Text fontWeight="400" marginBottom="28px" color="#475569">
                        {t("passwordResetSubtitle")}
                      </Text>
                    </div>
                  </div>
                  <div className="col-12 text-left">
                    {isLoading ? (
                      <ButtonLoading
                        style={{ marginTop: "26px", background: buttonsColor, color: accentTextColor }}
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        {t("loading...")}
                      </ButtonLoading>
                    ) : (
                      <ButtonLogin
                      style={{ marginTop: "26px", background: buttonsColor, color: accentTextColor }}
                        buttonType="primary"
                        onClick={() => {navigate("/")}}
                      >
                        {t("returnLogin")}
                      </ButtonLogin>
                    )}
                  </div>
                </LoginForm>
              }
            </Container>
          </div>
        </div>
      </Container>
    </>
  );
};
