import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setSelectedDevicesListActionCreator, setSelectGroupActionCreator, setRemovedDevicesListActionCreator } from "../../../redux/slices/groupsSlice/groupsSlice";
import { GroupsScroll } from "./GroupsListStyled";
import { GroupSearch } from "../GroupsPageStyled";
import { CardContainer } from "../../UsersPage/UsersPageStyled";
import { TextSpan } from "../../../styles/shared-styles/text-sizes";
import arrowRightFull from "../../../assets/new_icons/arrow_right_full.svg";
import offBulb from "../../../assets/new_icons/OFF.svg";
import search from "../../../assets/new_icons/Search.svg";

export const GroupsList = () => {
  const [searchGroup, setSearchGroup]: any = useState();

  const dispatch = useAppDispatch();
  const allNodes = useAppSelector((state) => state.devices.nodes);
  const groups = useAppSelector((state) => state.groups.groupsArr);
  const selectedGroup = useAppSelector((state) => state.groups.selectedGroup);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const { t } = useTranslation();

  const GroupItem = ({ item }: any) => {
    const [isHovered, setIsHovered] = useState(false);

    let divStyle = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: "10px",
      paddingBottom: "10px",
      borderBottom: "1px solid #E7E0EC",
      cursor: "pointer",
      backgroundColor: isHovered ? "#f1f1f1" : item.groupId === selectedGroup.groupId ? "#f1f1f1" : "",
    };

    return (
      <div onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} onClick={() => {dispatch(setSelectGroupActionCreator({ selectedGroup: item }));
        dispatch(setRemovedDevicesListActionCreator({ removedDevices: [] }))}} style={divStyle}
      >
        <div style={{ display: "flex", gap: "14px", paddingLeft: "6px" }}>
          <div>
            <TextSpan color={mainTextColor} fontWeight="700" style={{ display: "block", marginBottom: 5 }}>
              {item?.groupName}
            </TextSpan>
            <div className="d-flex gap-2">
              <TextSpan style={{ display: "flex" }} textType="caption">
                <img style={{ filter: "opacity(0.5)", marginRight: "2px" }} src={offBulb} height={12} />
                {item?.nodes?.length}
              </TextSpan>
            </div>
          </div>
        </div>
        <img style={{ filter: "opacity(0.5)", marginRight: "2px", paddingRight: "10px" }} src={arrowRightFull} height={10} />
      </div>
    );
  };

  useEffect(() => {
    if (selectedGroup.nodes) {
      dispatch(setSelectedDevicesListActionCreator({ selectedDevices: [...selectedGroup.nodes] }))
    }
  }, [selectedGroup, allNodes]);

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column", width: "350px", padding: "14px", paddingRight: "10px" }}>
      <GroupSearch>
        <img src={search} width={16} style={{ position: "absolute", left: "10px", top: "19%" }} />
        <input type="text" style={{ border: "none", background: "rgba(189, 189, 189, 0.2)", paddingLeft: "35px", width: "100%", height: "40px", borderRadius: "4px" }}
          placeholder={`${groups?.length} ${t("Groups").toLowerCase()}`}
          value={searchGroup}
          onChange={(e) => setSearchGroup(e.target.value)}
        />
      </GroupSearch>
      <GroupsScroll>
        {groups ?.filter((group: any) => !searchGroup || group.groupName?.toLowerCase().includes(searchGroup?.toLowerCase())).map((item: any, index: number) => (
          <GroupItem item={item} key={item.id} />
        ))}
      </GroupsScroll>
    </CardContainer>
  );
};
