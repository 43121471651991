import { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { resetSolarParametersConsumptionActionCreator } from "../../../../redux/slices/solar/solarParametersSlice";
import ConsumptionChart from "./ConsumptionChart";
import { ConsumptionModal } from "./ConsumptionModal";
import { useConsumptionsService } from "../../../../services/consumptionsService";
import { NextButton } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import plusCircle from "../../../../assets/new_icons/plusCircle.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ConsumptionProps {
  selectedLamp: any
}

export const Consumption = ({ selectedLamp }: ConsumptionProps) => {
  const [firstRender, setFirstRender] = useState(true);
  const [startDate, setStartDate]:any = useState();
  const [endDate, setEndDate]:any = useState();
  const [iconOpacity, setIconOpacity] = useState(0.5);
  const [showActions, setShowActions] = useState(false);
  const [errorNoData, setErrorNoData] = useState(false);
  const [isVisible, setIsVisible] = useState([true, true, true, true]);
  const [showModal, setShowModal] = useState(false);
  
  const { getSolarTwoDates } = useConsumptionsService();
  const hours = useAppSelector((state) => state.solarParameters.timestamps);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const manageSolarInfo = async (csv?:string) => {
    /* if (startDate.getTime() !== endDate.getTime()){ */
      //revisar eso de "solar"

      const result = await getSolarTwoDates(selectedLamp.node_id, startDate, endDate, "solar", csv)
      if (!result) {
        setErrorNoData(true)
      }
   /*  } else if (startDate.getTime() === endDate.getTime()) {
      const result = await getSolar(selectedLamp.node_id, startDate, "solar")
      console.log(result)
      if (!result) {
        setErrorNoData(true)
      }
    } */

    setTimeout(() => {
      setErrorNoData(false);
    }, 2000);
  };

  const toggleDataset = (index:any) => {
    setIsVisible(prevState => prevState.map((item, idx) => idx === index ? !item : item));
  };

  useEffect(() => {
    if (firstRender) {
      dispatch(resetSolarParametersConsumptionActionCreator());
    }
    setFirstRender(false)
  }, []);

  return (
    <div
      className="card"
      style={{
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        height: "100%",
        //height: "300px"
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          marginBottom: "4px"
        }}
      >
        <div>
          <Text color={mainTextColor} style={{ fontWeight: 500 }}>{t("Consumption")}</Text>
        </div>
        <div style={{ display: "flex", /* gap: "80px", */ alignItems: "end" }}>
          {/* <div style={{ display: "flex", gap: "16px" }}>
            <div style={{ display: "flex", gap: "4px", alignItems: "center"  }}>
              <div style={{ border: "1px solid #16D764", backgroundColor: "rgba(22, 215, 100, 0.4)", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px">{t("pannel")}</Text>
            </div>
            <div style={{ display: "flex", gap: "4px", alignItems: "center"  }}>
              <div style={{ border: "1px solid #00C7F2", backgroundColor: "rgba(0, 199, 242, 0.4)", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px">{t("Battery")}</Text>
            </div>
            <div style={{ display: "flex", gap: "4px", alignItems: "center"  }}>
              <div style={{ border: "1px solid #FFCB21", backgroundColor: "rgba(255, 203, 33, 0.4)", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px">{t("Luminaire")}</Text>
            </div>
          </div> */}
          <div style={{ display: "flex", gap: "4px", marginRight: "20px" }}>
            <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDataset(0)}>
              <div style={{ border: "1px solid #16D764", backgroundColor: isVisible[0] ? "rgba(22, 215, 100, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "10px" }}>{t("chargePower")}</Text>
            </div>
            <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDataset(1)}>
              <div style={{ border: "1px solid #00C7F2", backgroundColor: isVisible[1] ? "rgba(0, 199, 242, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "10px" }}>{t("battVoltage")}</Text>
            </div>
            <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDataset(2)}>
              <div style={{ border: "1px solid #005882", backgroundColor: isVisible[2] ? "rgba(100, 149, 237, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "10px" }}>{t("batt%")}</Text>
            </div>
            <div style={{ display: "flex", gap: "4px", alignItems: "center", cursor: "pointer" }} onClick={() => toggleDataset(3)}>
              <div style={{ border: "1px solid #FFCB21", backgroundColor: isVisible[3] ? "rgba(255, 203, 33, 0.4)" : "transparent", borderRadius: "10px", width: "17px", height: "17px" }} />
              <Text marginBottom="0px" style={{ fontSize: "10px" }}>{t("activePower")}</Text>
            </div>
          </div>
          <div style={{ display: "flex", gap: "16px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("sunsetDate")}</Text>
              <DatePicker
                className="datePickerSolar"
                placeholderText={t("selectDate")}
                selectsRange={false}
                selected={startDate}
                onChange={(date: any) => setStartDate(date)}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Text marginBottom="0px" style={{ fontSize: "12px" }}>{t("sunriseDate")}</Text>
              <DatePicker
                className="datePickerSolar"
                placeholderText={t("selectDate")}
                selectsRange={false}
                selected={endDate}
                onChange={(date: any) => setEndDate(date)}
              />
            </div>
            <NextButton accentButtonsColor={accentButtonsColor} style={{ alignSelf: "end", width: "120px", padding: "8px 8px", fontSize: "12px", marginLeft: "6px", opacity: !startDate || !endDate ? 0.5 : 1,
              cursor: !startDate || !endDate ? "not-allowed" : "pointer", boxShadow: "0px 2px 4px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2)" }}
              onClick={() => {manageSolarInfo()}}
              disabled={!startDate || !endDate}
            >
              {t("viewData")}
            </NextButton>
          </div>
        </div>
        <div style={{ textAlign: "right", marginTop: "-2px" }}>
          <img
            src={plusCircle}
            height={16}
            style={{ opacity: iconOpacity, cursor: "pointer" }}
            onMouseEnter={() => setIconOpacity(1)}
            onMouseLeave={() => setIconOpacity(0.5)}
            onClick={() => {setShowActions(!showActions)}}
          />
          {showActions && (
            <article
              style={{
                position: "absolute",
                top: "42px",
                right: "24px",
                width: "180px",
                maxHeight: "224px",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                background: "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff",
                boxShadow: "0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3)",
                borderRadius: "4px",
                zIndex: 6
              }}
            >  
              <>
                <style>
                  {`
                    .org-span:hover {
                      background-color: #1c1b1f11;
                    }
                  `}
                </style>
                <span
                  className="org-span"
                  onClick={async () => {setShowActions(!showActions); setShowModal(true)}}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    isolation: "isolate",
                    paddingLeft: "15px",
                    paddingRight: "6px",
                    width: "100%",
                    minHeight: "56px",
                    cursor: "pointer",
                    textAlign: "left",
                  }}
                >
                  {t("viewGraph")}
                </span>
                <span
                  className="org-span"
                  onClick={async () => {
                    if (hours.length > 0) {
                      setShowActions(!showActions);
                      manageSolarInfo("csv");
                    }
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    isolation: "isolate",
                    paddingLeft: "15px",
                    paddingRight: "6px",
                    width: "100%",
                    minHeight: "56px",
                    cursor: hours.length > 0 ? "pointer" : "not-allowed",
                    textAlign: "left",
                  }}
                >
                  {t("downloadCSV")}
                </span>
              </>
            </article>
          )}
          {showModal && (
            <ConsumptionModal showModal={showModal} setShowModal={setShowModal} isVisible={isVisible} toggleDataset={toggleDataset} />     
          )}
        </div>
      </div>
      {errorNoData ?
          <Text marginBottom="0px" color="red" style={{ textAlign: "center" }}>{t("errorNoData")}</Text>
        :
          <></>
      }
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ConsumptionChart isVisible={isVisible} />
      </div>
    </div>
  );
};
