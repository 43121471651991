import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { ConsumptionConfig } from "./OrganizationSettingsForm/ConsumptionConfig";
import { Limitations } from "./OrganizationSettingsForm/Limitations";
import { MainInfo } from "./OrganizationSettingsForm/MainInfo";
import { OrgImage } from "./OrganizationSettingsForm/OrgImage";
import { PlatformPalette } from "./OrganizationSettingsForm/PlatformPalette";
import { StrategiesPalette } from "./OrganizationSettingsForm/StrategiesPalette";
import { OrganizationWrapper } from "./OrganizationSettingsPageStyled";
import { Text } from "../../styles/shared-styles/text-sizes";
import company from "../../assets/new_icons/Company.svg";
import companyBalady from "../../assets/new_icons/Company balady.svg";
import map from "../../assets/new_icons/Map.svg";
import { ReactComponent as ID } from "../../assets/new_icons/ID.svg"

export const OrganizationSettingsPage = () => {
  const orgInfo: any = useAppSelector((state) => state.selectedOrganization);
  const userRole = useAppSelector((state) => state.user.role_name);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  console.log(`URL de la imagen: https://${orgInfo.img}`);
  return (
    <OrganizationWrapper>
      <div className="d-flex align-items-center gap-4 mb-5">
        <div style={{ height: "80px", width: "80px", overflow: "hidden", borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center",
          border: `1px solid ${mainTextColor}` }}
        >
          <img src={orgInfo.img ? `https://${orgInfo.img}` : company} /* src={orgInfo.img ? `https://${orgInfo.img}` : companyBalady} */ alt="logo" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
        </div>
        <div>
          <Text color={mainTextColor} className="mb-3" fontWeight="500" textType="title">{orgInfo.orgName}</Text>
          <div className="org-subheading mb-3">
            <div className="org-subheading_item">
              <ID width={18} height={18} className="filter-invert" style={{ color: "#525252", marginRight: "10px" }} />
              <Text color={mainTextColor} fontWeight="500" marginBottom="0px">{orgInfo.id}</Text>
            </div>
            <div className="org-subheading_item">
              <img src={map} height={18} className="" style={{ opacity: 0.7 }} />
              <Text color={mainTextColor} fontWeight="500" marginBottom="0px">{orgInfo.city}, {orgInfo.country}</Text>
            </div>
          </div>
        </div>
      </div>
      <MainInfo />
      <ConsumptionConfig />
      <OrgImage />
      <PlatformPalette />
      {/* <StrategiesPalette /> */}
      <Limitations />
    </OrganizationWrapper>
  );
};
