import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { Text } from "../../../styles/shared-styles/text-sizes";
import co2 from "../../../assets/new_icons/Co2.svg";
import currency from "../../../assets/new_icons/Currency.svg";
import energyEco from "../../../assets/new_icons/Energy_eco.svg";
import timestamp from "../../../assets/new_icons/Timestamp.svg";

interface TotalsCardProps {
  selectedLamp: any
}

export const TotalsCard = ({ selectedLamp }: TotalsCardProps) => {
  const [connectionDate, setConnectionDate] = useState("");
  
  const orgInfo: any = useAppSelector((state) => state.selectedOrganization);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedLamp.start_lifetime !== "-") {
      const [year, month, day] = selectedLamp.start_lifetime.split('-');
      setConnectionDate(`${day}/${month}/${year}`);
    } else {
      setConnectionDate("-");
    }
  }, [selectedLamp]);

  return (
    <div style={{ display: "flex", gap: "30px", flexWrap: "wrap", width: "fit-content" }}>

      <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "20px", border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "312px", height: "228px" }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
          <Text fontWeight="700" style={{fontSize: "18px" }}>{t("lifetime")}</Text>
          <Text fontWeight="400" style={{ fontSize: "12px" }}>{t("firstConnection")}: {connectionDate}</Text>
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
          <Text marginBottom="0px" fontWeight="700" style={{ fontSize: "28px" }}>{selectedLamp.humanTime.years} {t("years")}</Text>
          <Text marginBottom="0px">{selectedLamp.humanTime.months} {t("months")} {selectedLamp.humanTime.days} {t("days")}</Text>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Text marginBottom="0px" fontWeight="700" color={supportTextColor} style={{ fontSize: "28px" }}>{selectedLamp.lifetime}</Text>
            <Text marginBottom="0px" color={supportTextColor}>{t("runningHours")}</Text>
          </div>
          <img src={timestamp} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
        </div>
      </div>

      <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "20px", border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "312px", height: "228px" }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
          <Text fontWeight="700" style={{fontSize: "18px" }}>{t("energy")}</Text>
          <Text fontWeight="400" style={{ fontSize: "12px" }}>({t("kwh")})</Text>
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
          <Text marginBottom="0px" fontWeight="700" style={{ fontSize: "28px" }}>{(selectedLamp.energy_lifetime / 1000).toFixed(2)}</Text>
          <Text marginBottom="0px">{t("consumed")}</Text>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>{(selectedLamp.saving_lifetime / 1000).toFixed(2)}</Text>
            <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
          </div>
          <img src={energyEco} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
        </div>
      </div>

      <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "20px", border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "312px", height: "228px" }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
          <Text fontWeight="700" style={{ fontSize: "18px" }}>{t("cost")} (-)</Text>
          <Text fontWeight="400" style={{ fontSize: "12px" }}>{orgInfo.price} / kW</Text>
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
          <Text marginBottom="0px" fontWeight="700" style={{ fontSize: "28px" }}>{(selectedLamp.energy_lifetime / 1000 * orgInfo.price).toFixed(2)}</Text>
          <Text marginBottom="0px">{t("consumed")}</Text>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>{(selectedLamp.saving_lifetime / 1000 * orgInfo.price).toFixed(2)}</Text>
            <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
          </div>
          <img src={currency} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
        </div>
      </div>

      <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "20px", border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "312px", height: "228px" }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
          <Text fontWeight="700" style={{fontSize: "18px" }}>{t("co2Abr")}</Text>
          <Text fontWeight="400" style={{ fontSize: "12px" }}>{orgInfo.co2} / Kg</Text>
        </div>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
          <Text marginBottom="0px" fontWeight="700" style={{ fontSize: "28px" }}>{(selectedLamp.energy_lifetime / 1000 * orgInfo.co2).toFixed(2)}</Text>
          <Text marginBottom="0px">{t("consumed")}</Text>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>{(selectedLamp.saving_lifetime / 1000 * orgInfo.co2).toFixed(2)}</Text>
            <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
          </div>
          <img src={co2} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
        </div>
      </div>
    </div>
  );
};
