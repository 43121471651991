import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { Review } from "../Step3/Review";
import { CardContainer, NextButton } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import { GroupsLeafletMap } from "../../GroupsMap/GroupsLeafletMap";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface DevicesProps {
  isNewGroup?: boolean;
  goToReview?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
}

export const Devices = ({ isNewGroup, goToReview, errorMessage, setErrorMessage }: DevicesProps) => {  
  const dispatch = useAppDispatch();
  const selectedDevices = useAppSelector((state) => state.groups.selectedDevices);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedDevices) {
      setErrorMessage(false)
    };
  }, [selectedDevices]);

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: "36px", paddingLeft: "40px", marginLeft: "10px" }}>
        <Text fontWeight="500" style={{ marginBottom: "4px", fontSize: "14px" }}>
          {t("step2")}
        </Text>
        <Text fontWeight="600" marginBottom="0px" style={{ fontSize: "22px" }}>
          {t("Group Devices")}
        </Text>
        <Text textType="caption" fontWeight="400" style={{ fontSize: "12px" }}>
          {t("Give an event the name you prefer. This will help you identify it better.")} 
        </Text>
      </div>
      <div style={{ margin: "0 27px", height: "520px", width: "95%" }} className="position-relative mb-3">
        <GroupsLeafletMap isNewGroup={isNewGroup} showDevice={true} interactiveMap={true} showIcon={true} fromGroupsPage={false} />
      </div>
      {
        errorMessage ?
          <Text marginBottom="0px" style={{display: "flex",  justifyContent: "center", color: "red"}}>{t("errorBlankInput")}</Text>  
        :
          <></>
      }
      <NextButton style={{ background: errorMessage ? accentButtonsColor+95 : accentButtonsColor, borderColor: errorMessage ? `1px solid ${accentButtonsColor+95}` : accentButtonsColor,
        justifySelf: "end", alignSelf: "center", width: "300px", marginTop: errorMessage ? "15px" : "36px", cursor: errorMessage ?"not-allowed" : "pointer" }}
        onClick={() => {
          if (!errorMessage) {
            goToReview()
          }
        }}
      >
        {t("continue")}
      </NextButton>
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
      </div>
    </CardContainer>
  );
};
