import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { store } from "../../redux/store";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getGroupsAction } from "../../redux/actions/groupsActions/groupsActions";
import { resetGroupInfoActionCreator } from "../../redux/slices/groupsSlice/groupsSlice";
import { useGroupsService } from "../../services/groupsService";
import { DataCard } from "../DataCard/DataCard";
import { GroupsDetailsCard } from "./GroupsDetailsCard/GroupsDetailsCard";
import { GroupsList } from "./GroupsList/GroupsList";
import { LoaderModal } from "../LoaderModal/LoaderModal";
import { NewGroupModal } from "./NewGroupModal/NewGroupModal";
import { GroupSelect } from "./GroupsPageStyled";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import { TextSpan, Text } from "../../styles/shared-styles/text-sizes";
import groupIcon from "../../assets/new_icons/Groups.svg";
import { ReactComponent as PlusIcon } from "../../assets/new_icons/plus.svg";

export const GroupsPage = () => {
  const [loading, setLoading] = useState(false);
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  
  const dispatch = useAppDispatch();
  const { getGroups } = useGroupsService();
  const user = useAppSelector((state) => state.user);
  const selectedOrgId: any = useAppSelector((state) => state.filteredDevices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.filteredDevices.selectedServiceId);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchManageGroups = async () => {
      try {
        setLoading(true);
        let response = await getGroups(selectedOrgId, selectedServiceId, setLoading);
        if (response) {
          store.dispatch(getGroupsAction(response));
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching groups:", error);
      }
    };

    fetchManageGroups();
  }, []);

  return (
    <div className="container-fluid" style={{ marginTop: "40px", paddingRight: "0px", paddingLeft: "0px", position: "relative", zIndex: 2 }}>
      {loading ?
        <LoaderModal showLoader={loading} />
      :
        <>
          {showGroupsModal && (
            <NewGroupModal
              showModal={showGroupsModal}
              setShowModal={setShowGroupsModal}
            />
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: "24px" }}>
            <div>
              <Text textType="title" fontWeight="700" marginBottom="8px">
                {t("manageYourGroups")}
              </Text>
              <Text textType="">{t("manageYourGroupsSubtitle")}</Text>
            </div>
            {/* <TextSpan textType="alarm" color="#A3AED0" style={{ marginBottom: 10, display: "block" }}>
              Organization
            </TextSpan>
            <div className="d-flex gap-4">
              <GroupSelect>
                <Select
                  size="large"
                  //   onChange={handleLanguage}
                  //   value={language}
                  defaultValue={"all"}
                  //   className="my-select"
                  options={[
                    {
                      label: "All",
                      value: "all",
                    },
                  ]}
                />
              </GroupSelect>
              <DataCard
                title={t("totalGroups")}
                content={groups?.length}
                mainIcon={groupIcon}
              /> 
            </div>*/}
            {user.role_name === "owner" || user.role_name === "admin" ?
              <Button buttonType="primary" style={{ background: buttonsColor, color: accentTextColor, width: "150px", paddingLeft: "0px", paddingRight: "4px" }}
                onClick={() => {dispatch(resetGroupInfoActionCreator()); setShowGroupsModal(!showGroupsModal)}}
              >
                <PlusIcon height={30} width={30} style={{ color: accentTextColor, marginBottom: "1.5px", marginRight: "4px" }} />
                {t("New group")}
              </Button>
            :
              <></>
            }
          </div>
          <div style={{ display: "flex", gap: "25px" }}>
            <div style={{ width: "350px" }}>
              <GroupsList />
            </div>
            <div style={{ width: "1425px" }}>
              <GroupsDetailsCard setLoading={setLoading} />
            </div>
          </div>
        </>
      }
    </div>
  );
};
