import {useState} from 'react'
import { useTranslation } from "react-i18next";
import { notification } from "antd";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { resetRegisterUserInfoActionCreator } from "../../../../redux/slices/userRegistrationSlice/userRegistrationSlice";
import { useUsersService } from "../../../../services/usersService";
import { items } from "../../../../utils/languages/changeLanguage";
import { useRoleMapping } from "../../../../utils/roleMapping";
import { UserAvatar } from "../../../../utils/reusableComponents";
import { CardContainer, ButtonStrategy } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { CompanyProfile, OrganizationCard } from "../../UsersPageStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import companyFlag from "../../../../assets/new_icons/Company.svg";
import LightBulb from "../../../../assets/new_icons/light-bulb-shine-alternate.svg";
import mail from "../../../../assets/new_icons/mail.svg";
import phone from "../../../../assets/new_icons/Phone.svg";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface ReviewProps {
  onHide?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
}

export const Review = ({ onHide, errorMessage, setErrorMessage }: ReviewProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.userRegistration.user);
  const organizations = useAppSelector((state) => state.userRegistration.organizationPermits);
  const services = useAppSelector((state) => state.userRegistration.servicePermits);
  const roleMapping:any = useRoleMapping();
  const { registerNewUser } = useUsersService();
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const sendInfo = async () => {
    const data = await registerNewUser();
    if (data) {
      setErrorMessage(false);
      dispatch(resetRegisterUserInfoActionCreator())
       notification.success({
        message: t("registerOk"),
        description: null
      });
      onHide();
    } else {
      setErrorMessage(true);
    }
  };

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: "47px", paddingLeft: "40px" }}>
        <Text fontWeight="500" marginBottom="4px">{t("step5")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("reviewInfo")}</Text>
        <Text fontWeight="400" style={{fontSize: "12px"}}>{t("reviewInfoSubtitle")}</Text>
      </div>
      <div style={{ display: "flex", justifyContent: "start", paddingLeft: "40px", paddingRight: "50px",  gap: "30px", paddingTop: "10px", paddingBottom: "10px" }}>
        <div>
          <Text color={mainTextColor} fontWeight="500" marginBottom="26px">{t("generalDetails")}</Text>
          <OrganizationCard style={{ display: "flex", gap: "14px", paddingLeft: "10px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "6px", alignItems: "center" }}>
            <UserAvatar firstName={user.first_name} profileColor={user.color} type={"header"} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text fontWeight="600" marginBottom="6px" style={{ fontSize: "18px" }}>
                {user.first_name} {user.last_name}
              </Text>
              <div style={{ display: "flex", gap: "14px" }}>
                <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                  <img src={mail} width={16} style={{ opacity: 0.5 }} />
                  <Text style={{ fontSize: "10px", opacity: 0.8 }}>
                    {user.email}
                  </Text>
                </div>
                <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                  <img src={phone} width={13} style={{ opacity: 0.5 }} />
                  {user.phoneNumber ?
                    <Text style={{ fontSize: "10px", opacity: 0.8 }}>
                      {user.phoneNumber}
                    </Text>
                  :
                    <div style={{ fontSize: "10px", opacity: 0.8 }}>-</div>
                  }
                </div>
              </div>
            </div>
          </OrganizationCard>
        </div>
        <div>
          <Text color={mainTextColor} fontWeight="500" marginBottom="26px">{t("Role")}</Text>
          <OrganizationCard style={{ display: "flex", gap: "14px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "6px", alignItems: "center" }}>
            <Text fontWeight="500" style={{ opacity: 0.8 }}>
              {Object.keys(roleMapping).find(key => roleMapping[key] === user?.role_id)?.toUpperCase()}
            </Text> 
          </OrganizationCard>
        </div>
        <div>
          <Text color={mainTextColor} fontWeight="500" marginBottom="26px">{t("language")}</Text>
          <OrganizationCard style={{ display: "flex", gap: "14px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "6px", alignItems: "center" }}>
            <Text fontWeight="500" style={{ opacity: 0.8 }}>
              {(() => {
                const selectedItem = items.find(item => item.id === user.user_language);
                return selectedItem ? t(selectedItem.label).toUpperCase() : "";
              })()}
            </Text> 
          </OrganizationCard>
        </div>  
      </div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", paddingLeft: "40px", paddingRight: "50px", gap: "20px", paddingTop: "10px", paddingBottom: "10px" }}>
        <Text color={mainTextColor} fontWeight="500" marginBottom="6px">{t("permissions")}</Text>
        {organizations?.filter((org:any) => org.selected).map((org:any) => (
          <OrganizationCard style={{ display: "flex", width: "401px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "6px", gap: "20px", alignItems: "center" }}>
            <CompanyProfile src={org.image ? `https://${org.image}` : companyFlag} />
            <div>
              <Text marginBottom="4px" fontWeight="600">{org.orgName}</Text>
              <div style={{ display: "flex", opacity: 0.8, gap: "14px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={LightBulb} width={20} />
                  {services?.filter((service:any) => service.orgId === user.organization_id).map((serv:any) => (
                    <Text style={{ fontSize: "10px" }}>{serv.services[0].servName}</Text>
                  ))}
                </div>
              </div>
            </div>
          </OrganizationCard>
        ))}
      </div>
      {errorMessage ?
        <Text marginBottom="0px" color="red" style={{ display: "flex", justifySelf: "end", alignSelf: "center", marginTop: "251px" }}>{t("errorNewUser")}</Text>  
      :
        <></>
      }
      <ButtonStrategy buttonType="primary" style={{ position: 'absolute', justifySelf: "end", alignSelf: "center", width: "340px", bottom: "84px", marginLeft: "0px", marginTop: "0px",
        background: buttonsColor, color: accentTextColor }}
        onClick={() => {sendInfo()}}
      >
        {t("create")}
      </ButtonStrategy>
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: errorMessage ? "70px" : "342px" }}>
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        {/* <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} /> */}
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
      </div>
    </CardContainer>
  );
};
