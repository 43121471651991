import styled from "styled-components";

interface NavLinkProps {
  accentButtonsColor: string;
}

export const NavLink = styled.a<NavLinkProps>`
  font-size: 14px !important;
  border-radius: 0 !important;
  border-bottom: 2px solid ${props => props.accentButtonsColor + 30} !important;
  padding: 16px 32px 16px 16px !important;
  &.active {
    background-color: transparent !important;
    font-weight: 700 !important;
    border-bottom: 2px solid ${props => props.accentButtonsColor} !important;
    border-radius: 0 !important;
  }
`;

export const Structure = styled.div`
  position: relative;
  height: 100%;
  margin-bottom: 1rem;
`;
