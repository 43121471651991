import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks";
import { CardContainerResponsePage } from "./ResponsePageStyled";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import { Text } from "../../styles/shared-styles/text-sizes";
import error401 from "../../assets/new_icons/401-unauthorized.png";

export const DisabledPage = () => {
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  return (
    <CardContainerResponsePage>
      <div className="row text-center">
        <div className="col-12">
          <img src={error401} />
          <Text
            textType="title"
            color={accentButtonsColor}
            fontWeight="700"
            marginBottom="20px"
          >
            {t("disabledDemo")}
          </Text>
          <a href="/dashboard">
            <Button buttonType="primary" style={{ background: buttonsColor, color: accentTextColor }}>{t("goBackDashboard")}</Button>
          </a>
        </div>
      </div>
    </CardContainerResponsePage>
  );
};
