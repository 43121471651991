export const styledMainTheme = {
  light: {
    mainTitleColor: "#475569",
    mainBackgroundColor: "#FFF",
    titleFontColor: "#A3AED0",
    contentFontColor: "#1B2559",
    mainIconColor: "#BDBDBD",
    mainIconSize: "1.5rem",
    iconColor: "#1B2559",
    iconBackgroundColor: "rgba(163, 174, 208, 0.15)",
    mainFont: "Noto Sans",
    mainFontSize: "1.5rem",
    secondFontSize: "0.75rem",
    cardTitleFontSize: "0.875rem",
    cardContentFontSize: "1.75rem",
    weatherTimeFontSize: "2.75rem",
    smartecGreen: "#56c568",
    buttonMainColor: "#FFC107",
  },

  dark: {},
};
