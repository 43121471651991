import styled from "styled-components";

export const LayoutGreyShadow = styled.div`
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  order: 1;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const LayoutMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 9px;
  margin-bottom: 3px;
`;

export const LayoutFirstItem = styled.div`
  /*margin-top: 5.2px;*/
  margin-top: 2px;
  margin-right: -3px;
  cursor: pointer;
  fill: red;
`;

export const LayoutSecondItem = styled.div`
  margin: 6px;
  margin-top: 2px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  fill: red;
`;

export const LayoutThirdItem = styled.div`
  margin: 6px;
  margin-top: 2px;
  margin-right: -2px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
