import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Button, message, Pagination } from "antd";
import type { PaginationProps } from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { store } from "../../../redux/store";
import { fetchAlarmsFromNode } from "../../../redux/actions/alarmsActions/alarmsActions";
import { setFiltersActionCreator } from "../../../redux/slices/actualFilterSlice/actualFilterSlice";
import { filterConnectedDevicesActionCreator, filteredAllDevicesActionCreator } from "../../../redux/slices/devices/filteredDevicesSlice";
import { resetParametersActionCreator } from "../../../redux/slices/electricParametersSlice/electricParametersSlice";
import { changeBackgroundDashboardActionCreator } from "../../../redux/slices/graphicsSlice/graphicsSlice";
import { resetGroupInfoActionCreator } from "../../../redux/slices/groupsSlice/groupsSlice";
import { errorToInitialStateActionCreator } from "../../../redux/slices/mqttSlice/mqttSlice";
import { resetSolarParametersConsumptionActionCreator } from "../../../redux/slices/solar/solarParametersSlice";
import { setIsOpenedDetailActionCreator, setIsOpenedGroupActionCreator, setIsStaticViewActionCreator, setViewsToFalseActionCreator, setSelectedNodeIdActionCreator,
  setNewNodeLatLongActionCreator } from "../../../redux/slices/uiSlice/uiSlice";
import { useAlarmsService } from "../../../services/alarmService";
import { useLightingService } from "../../../services/lightingService";
import { useStrategiesService } from "../../../services/strategiesService";
import { NewGroupModal } from "../../GroupsPage/NewGroupModal/NewGroupModal";
import { StrategiesListModal } from "../../LuminairePage/CurrentStrategy/StrategiesListModal";
import { ConsumptionsModal } from "../../PopUps/ConsumptionsPopUp/ConsumptionsModal";
import { checkAll, onMasterCheck, onItemCheck, makeMenusVisible, makeIconsVisible, changeOrder, changeState, checkSelected } from "../ListsLogic";
import { ErrorPopUp, Scroll, Space, IconStyled, SwitchComponent, ListPopUp } from "../ListsStyled";
import { ThemeProvider } from "styled-components";
import { styledMainTheme } from "../../../styles/shared-styles/styleMainTheme";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import { googleIcons } from "../../../utils/googleFontsIcons/icons";
import addLocation from "../../../assets/new_icons/add_location.svg";
import Arrow_right_full from "../../../assets/new_icons/arrow_right_full.svg";
import AZ from "../../../assets/new_icons/A-Z.svg";
import connectedIcon from "../../../assets/new_icons/Connected.svg";
import dimming from "../../../assets/new_icons/Dimming.svg";
import disconnectedIcon from "../../../assets/new_icons/Node disconnected.svg";
import doubleArrow from "../../../assets/new_icons/Filter double arrow.svg";
import ellipseGreen from "../../../assets/new_icons/ellipse_green.svg";
import ellipseRed from "../../../assets/new_icons/ellipse_red.svg";
import ellipseYellow from "../../../assets/new_icons/ellipse_yellow.svg";
import groupsIcon from "../../../assets/new_icons/Groups.svg";
import OFF from "../../../assets/new_icons/OFF.svg";
import ON from "../../../assets/new_icons/ON.svg";
import Options from "../../../assets/new_icons/Options.svg";
import refresh from "../../../assets/new_icons/list_refresh.svg";
import switchOff from "../../../assets/new_icons/Switch OFF.svg";
import switchOn from "../../../assets/new_icons/Switch ON.svg";
import turnOn from "../../../assets/new_icons/Switch ON.svg";
import turnOff from "../../../assets/new_icons/Switch OFF.svg";
import ZA from "../../../assets/new_icons/Z-A.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/new_icons/Arrow left dynamic.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/new_icons/Arrow right dynamic.svg";
import { ReactComponent as Cross } from "../../../assets/new_icons/Cross.svg";
import { ReactComponent as GroupsIcon } from "../../../assets/new_icons/Groups layout.svg";
import { ReactComponent as ReportsIcon } from "../../../assets/new_icons/Reports.svg";
import { ReactComponent as StrategiesIcon } from "../../../assets/new_icons/big-point layout.svg";

const theme = {
  primaryColor: styledMainTheme?.light?.smartecGreen,
};

export const HybridMenuTable = () => {
  const [masterChecked, setMasterChecked] = useState<boolean>(false);
  const [blocked, setBlocked] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState("");
  const [showGroupsModal, setShowGroupsModal] = useState(false);
  const [showStrategiesModal, setShowStrategiesModal] = useState(false);
  const [showConsumptionsModal, setShowConsumptionsModal] = useState(false);

  //esto quizás no?
  const [selectedList, setSelectedList] = useState([]);

  // usestates for menus
  const [nameOrder, setNameOrder] = useState(false);
  const [connectionOrder, setConnectionOrder] = useState(false);
  const [statusOrder, setStatusOrder] = useState(false);
  const [alarmOrder, setAlarmOrder] = useState(false);
  const [groupsOrder, setGroupsOrder] = useState(false);

  // usestates for icons
  const [nameAZ, setNameAZ] = useState(false);
  const [nameZA, setNameZA] = useState(false);
  const [connected, setConnected] = useState(false);
  const [disconnected, setDisconnected] = useState(false);
  const [on, setOn] = useState(false);
  const [off, setOff] = useState(false);
  const [clear, setClear] = useState(false);
  const [alarm, setAlarm] = useState(false);
  const [groupsAZ, setGroupsAZ] = useState(false);
  const [groupsZA, setGroupsZA] = useState(false);
  const [groups, setGroups] = useState(false);
  
  const dispatch = useAppDispatch();
  const { getAllNodes, sendCommandToLuminaire, getNodeInfo, updateLatLong } = useLightingService();
  const { getStrategies } = useStrategiesService();
  const { getNodeAlarms } = useAlarmsService();
  const navigate = useNavigate();
  const nodesInArea = useAppSelector((state) => state.ui.nodesInArea);
  const devices = useAppSelector((state) => state.devices);
  const { nodes } = useAppSelector((state) => state.devices);
  const filteredNodes = useAppSelector((state) => state.filteredDevices.nodes);
  const filteredAreaNodes = useAppSelector((state) => state.filteredDevices.nodesInArea);
  const mqttError = useAppSelector((state) => state.mqtt.error);
  const groupsList = useAppSelector((state) => state.groups.groupsArr);
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const organizations = useAppSelector((state) => state.devices.organizations);
  const services = useAppSelector((state) => state.devices.services);
  const newNodeLatLong: any = useAppSelector((state) => state.ui.newNodeLatLong);
  const userRole = useAppSelector((state) => state.user.role_name);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const { close, sadFace } = googleIcons;

  const [list, setList]: any = useState(nodesInArea ? filteredAreaNodes : filteredNodes);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(200);
  const indexOfLastRow = currentPage * pageSize;
  const indexOfFirstRow = indexOfLastRow - pageSize;
  const currentRows = list.slice(indexOfFirstRow, indexOfLastRow);
  const { t } = useTranslation();

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <a>
          <ArrowLeftIcon height={12} style={{ color: "#525252" }} />
        </a>
      );
    }
    if (type === "next") {
      return (
        <a>
          <ArrowRightIcon height={12} style={{ color: "#525252" }} />
        </a>
      );
    }
    return originalElement;
  };

  const [itemsPerPage] = useState(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = list.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(list.length / itemsPerPage);

  const handleClickSingle = async (node: any, order: number) => {
    dispatch(setIsStaticViewActionCreator(true));
    try {
      let data: any = sendCommandToLuminaire([node], order, 0, 0);
    } catch (error) {
      alert(error);
    }
  };

  const handleClickMultiple = async (nodes: any, order: number) => {
    dispatch(setIsStaticViewActionCreator(true));
    let newArr: any = [];
    nodes.find((node: any) => {
      if (node.selected === true && node.online === 1) {
        newArr.push(node);
      }
    });

    try {
      if (newArr.length > 0) {
        sendCommandToLuminaire(newArr, order, 0, 0);
      }
    } catch (error) {
      alert(error);
    }
  };

  const changeBackground = (nodes: any) => {
    let newArr: any = [];
    nodes.find((node: any) => {
      if (node.selected === true && node.online === 1) {
        newArr.push(node);
      }
    });

    if (newArr.length > 0) {
      dispatch(changeBackgroundDashboardActionCreator());
    }
  };

  const handleClose = () => {
    dispatch(setIsOpenedDetailActionCreator(false));
    dispatch(setIsOpenedGroupActionCreator(false));
    dispatch(setIsStaticViewActionCreator(true));
    //setSelectedMarker(initialLamp.node_id);
  };

  const handleClickViewDetails = async (device: any) => {
    handleClose();
    //let viewState = "hybrid";
    localStorage.setItem('viewState', 'hybrid');
    /* await getNodeInfo(device.node_id);
    await getStrategies(selectedOrgId, selectedServiceId);
    try {
      const token:any = Cookies.get("token");
      let response = await getNodeAlarms(selectedOrgId, selectedServiceId, device.node_id, token)
      if (response) {
        store.dispatch(fetchAlarmsFromNode(response));
      }
    } catch (error) {
      console.error("Error fetching alarm data:", error);
    }
    dispatch(resetParametersActionCreator());
    dispatch(resetSolarParametersConsumptionActionCreator()); */
    dispatch(setViewsToFalseActionCreator());
    //navigate(`/luminaire/${device.node_id}`, { state: { viewState } });
    navigate(`/luminaire/${device.node_id}`);
  };

  const resetFilters = () => {
    dispatch(filteredAllDevicesActionCreator(devices));
    makeIconsVisible(
      "",
      setNameAZ,
      setNameZA,
      setConnected,
      setDisconnected,
      setOn,
      setOff,
      setClear,
      setAlarm,
      setGroupsAZ,
      setGroupsZA,
      setGroups
    );
    dispatch(
      setFiltersActionCreator({
        all: true,
        area: false,
        on: false,
        off: false,
        disconnected: false,
        alarms: false,
        solar: false
      })
    );
  };

  const updateMapLocation = async (id: string, organizationId: number, serviceId: number) => {
    if (!newNodeLatLong) {
      return message.error("Please select a position on the map to continue.");
    }

    await updateLatLong(id, organizationId, serviceId, newNodeLatLong.lat, newNodeLatLong.lng);
    await getAllNodes(selectedOrgId, selectedServiceId, organizations, services);
    setBlocked(false);
    setSelectedNodeId("");
    dispatch(setSelectedNodeIdActionCreator(""));
  };

  //Código para actualizar la posición del nodo en lista sin que revienten los filtros
  /* const updateMapLocation = async (id: string, organizationId: number, serviceId: number) => {
    if (!newNodeLatLong) {
      return message.error("Por favor, seleccione una posición en el mapa para continuar.");
    }
 
    await updateLatLong(id, organizationId, serviceId, newNodeLatLong.lat, newNodeLatLong.lng);
   
    // En lugar de recargar todos los nodos, actualizamos solo el nodo modificado
    const updatedNodes = list.map((node: any) =>
      node.node_id === id
        ? { ...node, latitude: newNodeLatLong.lat, longitude: newNodeLatLong.lng }
        : node
    );
   
    // Actualizamos la lista manteniendo los filtros actuales
    setList(updatedNodes);
   
    // Actualizamos el estado global si es necesario
    if (nodesInArea) {
      dispatch(filteredNewOrderAreaDevicesActionCreator(updatedNodes));
    } else {
      dispatch(filteredNewOrderDevicesActionCreator(updatedNodes));
    }
 
    setBlocked(false);
    setSelectedNodeId("");
    dispatch(setSelectedNodeIdActionCreator(""));
  }; */

  const handleFilters = () => {
    if (connected) {
      changeState({connection: 1, status: !on && !off ? null : on && !off ? 1 : 0, alarms: !clear && !alarm ? null : clear && !alarm ? 0 : 1}, nodes);
    }
    if (disconnected) {
      changeState({connection: 0, status: !on && !off ? null : on && !off ? 1 : 0, alarms: !clear && !alarm ? null : clear && !alarm ? 0 : 1}, nodes);
    }

    if (on) {
      changeState({connection: !connected && !disconnected ? null : connected && !disconnected ? 1: 0, status: 1, alarms: !clear && !alarm ? null : clear && !alarm ? 0 : 1}, nodes);
    }
    if (off) {
      changeState({connection: !connected && !disconnected ? null : connected && !disconnected ? 1: 0, status: 0, alarms: !clear && !alarm ? null : clear && !alarm ? 0 : 1}, nodes);
    }

    if (clear) {
      changeState({connection: !connected && !disconnected ? null : connected && !disconnected ? 1: 0, status: !on && !off ? null : on && !off ? 1 : 0, alarms: 0}, nodes);
    }
     if (alarm) {
      changeState({connection: !connected && !disconnected ? null : connected && !disconnected ? 1: 0, status: !on && !off ? null : on && !off ? 1 : 0, alarms: 1}, nodes);
    }

    if(!connected && !disconnected && !on && !off && !clear && !alarm) {
      dispatch(filteredAllDevicesActionCreator(devices));
    }
  };

  useEffect(() => {
    handleFilters();
  }, [connected, disconnected, on, off, clear, alarm]);

  useEffect(() => {
    setList(nodesInArea ? filteredAreaNodes : filteredNodes);
    onMasterCheck(currentRows, setMasterChecked);

    const handleClickOutsideMenu = (event: MouseEvent) => {
      const target = event.target as Element;

      if (target && !target.closest("thead")) {
        setNameOrder(false);
        setConnectionOrder(false);
        setStatusOrder(false);
        setAlarmOrder(false);
        setGroupsOrder(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideMenu);
    };
  }, [nodesInArea, filteredNodes, filteredAreaNodes, devices, list, nameOrder, connectionOrder, statusOrder, alarmOrder, groupsOrder, currentRows]);

  console.log("la lista", list)

  return (
    <>
    {/* commented code, we will manage this error in a different way */}
      {/* <div
        style={{
          zIndex: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          marginTop: "200px",
        }}
      >
        {mqttError && (
          <ErrorPopUp>
            <div className="errorpopup__button-container">
              <span
                onClick={() => {
                  dispatch(errorToInitialStateActionCreator());
                }}
                className="material-symbols-rounded errorpopup__button-container__icon"
              >
                {close}
              </span>
            </div>
            <div className="errorpopup__message-container">
              <span
                className="material-symbols-rounded errorpopup__message-container__icon"
                style={{ color: "#CF2A2A", marginRight: "5px" }}
              >
                {sadFace}
              </span>
              <TextSpan color="#CF2A2A">{t(txt.errorConnection)}</TextSpan>
            </div>
          </ErrorPopUp>
        )}
      </div> */}
      <div
        style={{ display: "flex", alignItems: "center", gap: "15px", zIndex: 2, position: "absolute", top: checkSelected(currentRows) && userRole !== "observer" ? "104px" : "105px",
          /* right: "613px" */ right: checkSelected(currentRows) && userRole !== "observer" ? "518px" : "804px",
        }}
      >
        <input
          type="checkbox"
          className="form-check-input"
          checked={masterChecked}
          id="mastercheck"
          onChange={(e) => checkAll(e, currentRows)}
          style={{ cursor: "pointer", width: "16px", height: "16px", backgroundColor: masterChecked ? accentButtonsColor : "#FFFFFF" }}
        />
        <IconStyled
          src={refresh}
          style={{ cursor: "pointer", marginRight: "0px", marginTop: "5px", width: "15px", height: "15px" }}
          title={t("Refresh")}
        />
        <Cross width={13} height={13} title={t("Clear all filters")} style={{ color: "#525252", cursor: "pointer", marginTop: "5px" }} onClick={() => {resetFilters()}} />
        {checkSelected(currentRows) && userRole !== "observer" ? (
          <>
            <Text marginBottom="0" style={{ marginLeft: "2px", marginRight: "7px" }}>
              |
            </Text>
            <IconStyled
              src={turnOn}
              onClick={() => {
                handleClickMultiple(currentRows, 1);
              }}
              style={{ cursor: "pointer", marginRight: "12px", marginTop: "4px", width: "15px", height: "15px" }}
              title={t("Switch ON")}
            />
            <IconStyled
              src={turnOff}
              onClick={() => {
                handleClickMultiple(currentRows, 2);
              }}
              style={{ cursor: "pointer", marginRight: "12px", marginTop: "4px", width: "16px", height: "16px" }}
              title={t("Switch OFF")}
            />
            <IconStyled
              src={dimming}
              onClick={() => {
                changeBackground(currentRows);
              }}
              style={{ cursor: "pointer", marginRight: "12px", marginTop: "4px", width: "16px", height: "16px" }}
              title={t("Dimming")}
            />
            <GroupsIcon
              onClick={() => {
                dispatch(resetGroupInfoActionCreator());
                setShowGroupsModal(!showGroupsModal);
              }}
              style={{ color: "#525252", cursor: "pointer", marginRight: "12px", marginTop: "4px", width: "15px", height: "15px" }}
              title={t("createGroup")}
            />
            {showGroupsModal && (<NewGroupModal showModal={showGroupsModal} setShowModal={setShowGroupsModal} currentRows={currentRows.filter((node:any) => node.selected)} />)}
            <StrategiesIcon
              onClick={async() => {
                await getStrategies(selectedOrgId, selectedServiceId);
                setShowStrategiesModal(!showStrategiesModal);
              }}
              style={{ color: "#525252", cursor: "pointer", marginRight: "12px", marginTop: "4px", width: "15px", height: "15px" }}
              title={t("assignStrategy")}
            />
            {showStrategiesModal && (<StrategiesListModal showModal={showStrategiesModal} setShowModal={setShowStrategiesModal}
                selectedLamps={currentRows.filter((node:any) => node.selected && node.online === 1)/* .map((node: any) => node.node_id) */}
              />
            )}
            <ReportsIcon
              onClick={() => {
                setShowConsumptionsModal(!showConsumptionsModal);
              }}
              style={{ color: "#525252", cursor: "pointer", marginRight: "12px", marginTop: "4px", width: "15px", height: "15px" }}
              title={t("consumptions")}
            />
            {showConsumptionsModal && (<ConsumptionsModal showModal={showConsumptionsModal} setShowModal={setShowConsumptionsModal} currentRows={currentRows.filter((node:any) => node.selected)} />)}
          </>
        ) : (
          <></>
        )}
        {/* <IconStyled
          src={groupsIcon}
          style={{ cursor: "pointer", marginRight: "0px", marginTop: "2px", width: "16px", height: "16px" }}
          title={t(txt.newGroup)}
        /> */}
      </div>
      {/* <Scroll style={{ width: "49.2%", position: "absolute", zIndex: 1 }}> */}
      <div style={{ paddingRight: 3 }}>
        {/* </div><div style={{ paddingRight: 10 }}> */}
        <table
          className="table table-borderless"
          style={{ fontSize: "12px", width: "100%", minHeight: "300px" }}
        >
          <thead style={{ boxShadow: "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)", top: 0, zIndex: 1, background: "white", display: "flex" }}>
            <tr style={{ position: "relative" }}>
              <th scope="col" style={{ width: "35px" }}></th>
              <th scope="col" style={{ cursor: "pointer", width: 133.5, position: "relative" }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  className="icon-class"
                  onClick={() => {
                    dispatch(setIsOpenedDetailActionCreator(false));
                    setNameOrder(!nameOrder);
                    makeMenusVisible(
                      "name",
                      setNameOrder,
                      setConnectionOrder,
                      setStatusOrder,
                      setAlarmOrder,
                      setGroupsOrder
                    );
                  }}
                >
                  <TextSpan fontWeight="700">{t("Name")}</TextSpan>
                  {nameAZ ? (
                    <IconStyled
                      style={{ marginRight: -11, width: "16px", height: "16px" }}
                      src={AZ}
                    />
                  ) : nameZA ? (
                    <IconStyled
                      style={{ marginRight: -11, width: "16px", height: "16px" }}
                      src={ZA}
                    />
                  ) : (
                    <></>
                  )}
                  <IconStyled src={doubleArrow} />
                </div>
                {nameOrder && (
                  <article>
                    <ListPopUp style={{ right: 5, top: 30, position: "absolute" }}>
                    {/* <ListPopUp style={{ right: 40, top: 37, position: "absolute" }} > */}
                      {nameAZ || nameZA ?
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            setNameAZ(false);
                            setNameZA(false);
                            setNameOrder(false);
                          }}
                        >
                          <Cross width={13} height={13} style={{ color: "#BDBDBD", marginLeft: "16px", marginRight: "10px",  marginTop: "3px" }} />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t("Clear filter")}
                          </span>
                        </div>
                      :
                        <></>
                      }
                      <div
                        className="listpopup__block"
                        style={{ width: 140 }}
                        onClick={() => {
                          changeOrder(list, 1, nameOrder, groupsOrder);
                          setNameAZ(true);
                          setNameZA(false);
                          setNameOrder(false);
                        }}
                      >
                        <IconStyled
                          style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px", marginTop: "3px" }}
                          src={AZ}
                        />
                        <span className="listpopup__block__text">
                          {t("From A to Z")}
                        </span>
                      </div>
                      <div
                        className="listpopup__block"
                        style={{ width: 140, paddingBottom: "18px", height: "60px" }}
                        onClick={() => {
                          changeOrder(list, 2, nameOrder, groupsOrder);
                          setNameAZ(false);
                          setNameZA(true);
                          setNameOrder(false);
                        }}
                      >
                        <IconStyled
                          style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px", marginTop: "3px" }}
                          src={ZA}
                        />
                        <span className="listpopup__block__text">
                          {t("From Z to A")}
                        </span>
                      </div>
                    </ListPopUp>
                  </article>
                )}
              </th>
              <th scope="col" style={{ cursor: "pointer", width: 133.5, position: "relative" }} >
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  className="icon-class"
                  onClick={() => {
                    dispatch(setIsOpenedDetailActionCreator(false));
                    setConnectionOrder(!connectionOrder);
                    makeMenusVisible(
                      "connection",
                      setNameOrder,
                      setConnectionOrder,
                      setStatusOrder,
                      setAlarmOrder,
                      setGroupsOrder
                    );
                  }}
                >
                  <TextSpan fontWeight="700">{t("Connection")}</TextSpan>
                  {connected ? (
                    <IconStyled
                      style={{ marginRight: -11, width: "16px", height: "16px" }}
                      src={connectedIcon}
                    />
                  ) : disconnected ? (
                    <IconStyled
                      style={{ marginRight: -11, width: "16px", height: "16px" }}
                      src={disconnectedIcon}
                    />
                  ) : (
                    <></>
                  )}
                  <IconStyled src={doubleArrow} />
                </div>
                {connectionOrder && (
                  <article>
                    {/* <ListPopUp style={{ right: 30, top: 37, position: "absolute" }}> */}
                    <ListPopUp style={{ right: 5, top: 30, position: "absolute" }}>
                      {connected || disconnected ?
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            setConnected(false);
                            setDisconnected(false);
                            setConnectionOrder(false);
                          }}
                        >
                          <Cross width={13} height={13} style={{ color: "#BDBDBD", marginLeft: "16px", marginRight: "10px",  marginTop: "3px" }} />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t("Clear filter")}
                          </span>
                        </div>
                      :
                        <></>
                      }
                      {list.some((item:any) => item.online === 1) ?
                        <div
                          className="listpopup__block"
                          style={{ width: 140, paddingBottom: list.some((item:any) => item.online === 0 ) ? "" : "18px", height: list.some((item:any) => item.online === 0 ) ? "" : "60px" }}
                          onClick={() => {      
                            setConnectionOrder(false);
                            setConnected(true);
                            setDisconnected(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px", marginTop: "3px" }}
                            src={connectedIcon}
                          />
                          <span className="listpopup__block__text">
                            {t("Connected")}
                          </span>
                        </div>
                      :
                        <></>
                      }
                      {list.some((item:any) => item.online === 0) ?
                        <div
                          className="listpopup__block"
                          style={{ width: 140, paddingBottom: "18px", height: "60px" }}
                          onClick={() => {
                            setConnectionOrder(false);
                            setConnected(false);
                            setDisconnected(true);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px", marginTop: "3px" }}
                            src={disconnectedIcon}
                          />
                          <span className="listpopup__block__text">
                            {t("Disconnected")}
                          </span>
                        </div>
                      :
                        <></>
                      }
                    </ListPopUp>
                  </article>
                )}
              </th>
              <th
                scope="col"
                style={{ cursor: "pointer", width: 133.5, position: "relative" }}>
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  className="icon-class"
                  onClick={() => {
                    dispatch(setIsOpenedDetailActionCreator(false));
                    setStatusOrder(!statusOrder);
                    makeMenusVisible(
                      "status",
                      setNameOrder,
                      setConnectionOrder,
                      setStatusOrder,
                      setAlarmOrder,
                      setGroupsOrder
                    );
                  }}
                >
                  <TextSpan fontWeight="700">{t("Status")}</TextSpan>
                  {on ? (
                    <IconStyled
                      style={{ marginRight: -11, width: "16px", height: "16px" }}
                      src={switchOn}
                    />
                  ) : off ? (
                    <IconStyled
                      style={{ marginRight: -11, width: "16px", height: "16px" }}
                      src={switchOff}
                    />
                  ) : (
                    <></>
                  )}
                  <IconStyled src={doubleArrow} />
                </div>
                {statusOrder && (
                  <article>
                    {/* <ListPopUp style={{ right: 38, top: 37, position: "absolute" }}> */}
                    <ListPopUp style={{ right: 5, top: 30, position: "absolute" }}>
                      {on || off ?
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            setOn(false);
                            setOff(false);
                            setStatusOrder(false);
                          }}
                        >
                          <Cross width={13} height={13} style={{ color: "#BDBDBD", marginLeft: "16px", marginRight: "10px",  marginTop: "3px" }} />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t("Clear filter")}
                          </span>
                        </div>
                      :
                        <></>
                      }
                      {list.some((item:any) => item.on === 1) ?
                        <div
                          className="listpopup__block"
                          style={{ width: 140, paddingBottom: list.some((item:any) => item.on === 0 ) ? "" : "18px", height: list.some((item:any) => item.on === 0 ) ? "" : "60px" }}
                          onClick={() => {
                            setOn(true);
                            setOff(false);
                            setStatusOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px", marginTop: "3px" }}
                            src={switchOn}
                          />
                          <span className="listpopup__block__text">
                            {t("ON")}
                          </span>
                        </div>
                      :
                        <></>
                      }
                      {list.some((item:any) => item.on === 0) ?
                        <div
                          className="listpopup__block"
                          style={{ width: 140, paddingBottom: "18px", height: "60px" }}
                          onClick={() => {
                            setOn(false);
                            setOff(true);
                            setStatusOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px", marginTop: "3px" }}
                            src={switchOff}
                          />
                          <span className="listpopup__block__text">
                            {t("OFF")}
                          </span>
                        </div>
                      :
                        <></>
                      }
                    </ListPopUp>
                  </article>
                )}
              </th>
              <th scope="col"
                style={{ cursor: "pointer", width: 133.5, position: "relative" }}>
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  className="icon-class"
                  onClick={() => {
                    dispatch(setIsOpenedDetailActionCreator(false));
                    setAlarmOrder(!alarmOrder);
                    makeMenusVisible(
                      "alarm",
                      setNameOrder,
                      setConnectionOrder,
                      setStatusOrder,
                      setAlarmOrder,
                      setGroupsOrder
                    );
                  }}
                >
                  <TextSpan fontWeight="700">{t("Alarms")}</TextSpan>
                  {clear ? (
                    <IconStyled
                      style={{ marginRight: -11 }}
                      src={ellipseGreen}
                    />
                  ) : alarm ? (
                      <IconStyled
                        style={{ marginRight: -11 }}
                        src={ellipseRed}
                      />
                  ) : (
                    <></>
                  )}
                  <IconStyled src={doubleArrow} />
                </div>
                {alarmOrder && (
                  <article>
                    {/* <ListPopUp style={{ right: 38, top: 37, position: "absolute" }}> */}
                    <ListPopUp style={{ right: 5, top: 30, position: "absolute" }}>
                      {clear || alarm ?
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            setClear(false);
                            setAlarm(false);
                            setAlarmOrder(false);
                          }}
                        >
                          <Cross width={13} height={13} style={{ color: "#BDBDBD", marginLeft: "14px", marginRight: "10px",  marginTop: "3px" }} />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t("Clear filter")}
                          </span>
                        </div>
                      :
                        <></>
                      }
                      {list.some((item:any) => item.alarm_status === 0) ?
                        <div
                          className="listpopup__block"
                          style={{ width: 140, paddingBottom: list.some((item:any) => item.alarm_status === 1) ? "" : "18px", height: list.some((item:any) => item.alarm_status === 1) ? "" : "60px" }}
                          onClick={() => {
                            setClear(true);
                            setAlarm(false);
                            setAlarmOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 16, marginRight: 10, marginTop: "3px" }}
                            src={ellipseGreen}
                          />
                          <span className="listpopup__block__text">
                            {t("Clear")}
                          </span>
                        </div>
                      :
                        <></>
                      }
                      {list.some((item:any) => item.alarm_status === 1) ?
                        <div
                          className="listpopup__block"
                          style={{ width: 140, paddingBottom: "18px", height: "60px" }}
                          onClick={() => {
                            setClear(false);
                            setAlarm(true);
                            setAlarmOrder(false);
                          }}
                        >
                          <IconStyled
                            style={{ marginLeft: 15, marginRight: 10, marginTop: "3px" }}
                            src={ellipseRed}
                          />
                          <span className="listpopup__block__text">
                            {t("alarm")}
                          </span>
                        </div>
                      :
                        <></>
                      }
                    </ListPopUp>
                  </article>
                )}
              </th>
              <th
                scope="col"
                style={{ cursor: "pointer", width: 133.5, position: "relative" }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  className="icon-class"
                  onClick={() => {
                    dispatch(setIsOpenedDetailActionCreator(false));
                    setGroupsOrder(!groupsOrder);
                    makeMenusVisible(
                      "groups",
                      setNameOrder,
                      setConnectionOrder,
                      setStatusOrder,
                      setAlarmOrder,
                      setGroupsOrder
                    );
                  }}
                >
                  <TextSpan fontWeight="700">{t("Groups")}</TextSpan>
                  {groupsAZ ? (
                    <IconStyled
                      style={{ marginRight: -11, width: "16px",  height: "16px" }}
                      src={AZ}
                    />
                  ) : groupsZA ? (
                    <IconStyled
                      style={{ marginRight: -11, width: "16px", height: "16px" }}
                      src={ZA}
                    />
                  ) : groups ? (
                    <IconStyled
                      style={{ marginRight: -11 }}
                      src={ellipseYellow}
                    />
                  ) : (
                    <></>
                  )}
                  <IconStyled src={doubleArrow} />
                </div>
                {groupsOrder && (
                  <article>
                    {/* <ListPopUp style={{ right: 38, top: 37, position: "absolute" }}> */}
                    <ListPopUp style={{ right: 5, top: 30, position: "absolute" }}>
                      {groupsAZ || groupsZA || groups ?
                        <div
                          className="listpopup__block"
                          style={{ width: 140 }}
                          onClick={() => {
                            setGroupsAZ(false);
                            setGroupsZA(false);
                            setGroups(false);
                            setGroupsOrder(false);
                          }}
                        >
                          <Cross width={13} height={13} style={{ color: "#BDBDBD", marginLeft: "16px", marginRight: "10px",  marginTop: "3px" }} />
                          <span
                            className="listpopup__block__text"
                            style={{ color: "#BDBDBD" }}
                          >
                            {t("Clear filter")}
                          </span>
                        </div>
                      :
                        <></>
                      }
                      <div
                        className="listpopup__block"
                        style={{ width: 140 }}
                        onClick={() => {
                          /* changeOrder(list, 1, nameOrder, groupsOrder);
                          makeIconsVisible(
                            "groupsAZ",
                            setNameAZ,
                            setNameZA,
                            setConnected,
                            setDisconnected,
                            setOn,
                            setOff,
                            setClear,
                            setAlarm,
                            setGroupsAZ,
                            setGroupsZA,
                            setGroups
                          ); */
                          setGroupsOrder(false);
                        }}
                      >
                        <IconStyled
                          style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px", marginTop: "3px" }}
                          src={AZ}
                        />
                        <span className="listpopup__block__text">
                          {t("From A to Z")}
                        </span>
                      </div>
                      <div
                        className="listpopup__block"
                        style={{ width: 140 }}
                        onClick={() => {
                          /* changeOrder(list, 2, nameOrder, groupsOrder);
                          makeIconsVisible(
                            "groupsZA",
                            setNameAZ,
                            setNameZA,
                            setConnected,
                            setDisconnected,
                            setOn,
                            setOff,
                            setClear,
                            setAlarm,
                            setGroupsAZ,
                            setGroupsZA,
                            setGroups
                          ); */
                          setGroupsOrder(false);
                        }}
                      >
                        <IconStyled
                          style={{ marginLeft: 15, marginRight: 10, width: "16px", height: "16px", marginTop: "3px" }}
                          src={ZA}
                        />
                        <span className="listpopup__block__text">
                          {t("From Z to A")}
                        </span>
                      </div>
                      <div
                        className="listpopup__block"
                        style={{ width: 140, paddingBottom: "18px", height: "60px" }}
                        onClick={() => {
                          /* changeOrder(list, 1, nameOrder, groupsOrder);
                          makeIconsVisible(
                            "groups",
                            setNameAZ,
                            setNameZA,
                            setConnected,
                            setDisconnected,
                            setOn,
                            setOff,
                            setClear,
                            setAlarm,
                            setGroupsAZ,
                            setGroupsZA,
                            setGroups
                          ); */
                          setGroupsOrder(false);
                        }}
                      >
                        <IconStyled
                          style={{ marginLeft: 15, marginRight: 10, marginTop: "3px" }}
                          src={ellipseYellow}
                        />
                        <span className="listpopup__block__text">
                          {t("Groups")}
                        </span>
                      </div>
                    </ListPopUp>
                  </article>
                )}
              </th>
              <th scope="col" style={{ width: 133.5, position: "relative" }}>
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                  className="icon-class"
                >
                  <TextSpan fontWeight="700">{t("location")}</TextSpan>
                  <IconStyled src={doubleArrow} />
                </div>
              </th>
              <th scope="col" style={{ textAlign: "center", width: "34px" }}>
                <img src={Options} height={12} style={{ cursor: "pointer" }} alt={"options"} />
              </th>
            </tr>
          </thead>
          <Scroll style={{ position: "relative", overflowX: "hidden", zIndex: 1 }}>
            <tbody style={{ display: "flex", flexDirection: "column" }} >
              {/* nodesInArea ? filteredAreaNodes :  */ currentRows.map((device: any) => (
                <>
                  <tr>
                    <Space />
                  </tr>
                  <tr key={device.node_id}>
                    <td scope="row" style={{ width: "35px" }}>
                      <input
                        type="checkbox"
                        checked={device.selected === true ? true : false}
                        className="form-check-input"
                        id={`rowcheck${device.node_id}`}
                        onChange={(e: any) => onItemCheck(e, device, currentRows)}
                        style={{ cursor: "pointer", width: "16px", height: "16px", backgroundColor: device.selected === true ? accentButtonsColor : "#FFFFFF" }}
                      />
                    </td>
                    <td style={{ width: 133.5 }}>
                      <TextSpan>{device.name}</TextSpan>
                    </td>
                    <td style={{ width: 133.5 }}>
                      {device.online === 0 ? (
                        <>
                          <IconStyled src={disconnectedIcon} style={{ marginTop: "-3px" }} />
                          <TextSpan>{t("Disconnected")}</TextSpan>
                        </>
                      ) : (
                        <>
                          <IconStyled src={connectedIcon} style={{ marginTop: "-3px" }} />
                          <TextSpan>{t("Connected")}</TextSpan>
                        </>
                      )}
                    </td>
                    <td style={{ width: 133.5 }}>
                      {userRole === "observer" ?
                        device.on === 0 ?
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <IconStyled src={OFF} style={{ marginTop: "-1px" }} />
                            <TextSpan>{t("OFF")}</TextSpan>
                          </div>
                        :
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <IconStyled src={ON} style={{ marginTop: "-1px" }} />
                            <TextSpan>{t("ON")}</TextSpan>
                          </div>
                      :
                        device.online === 0 ?
                          <SwitchComponent
                            checkedChildren="On"
                            unCheckedChildren="Off"
                            checked={false}
                            disabled
                          />
                        : device.on === 0 ?
                            <SwitchComponent
                              checkedChildren="On"
                              unCheckedChildren="Off"
                              checked={false}
                              style={{ background: "#475569", opacity: 0.9 }}
                              onClick={() => handleClickSingle(device, 1)}
                            />
                          :
                            <ThemeProvider theme={theme}>
                              <SwitchComponent
                                checked
                                checkedChildren="On"
                                unCheckedChildren="Off"
                                onClick={() => handleClickSingle(device, 2)}
                              />
                            </ThemeProvider>
                      }
                    </td>
                    <td style={{ width: 133.5 }}>
                      <>
                        {device.alarm_status === 0 ? (
                          <>
                            <IconStyled src={ellipseGreen} style={{ marginTop: "-3px" }} />
                            <TextSpan>{t("Clear")}</TextSpan>
                          </>
                        ) : device.alarm_status === 1 ? (
                          <>
                            <IconStyled src={ellipseRed} style={{ marginTop: "-3px" }} />
                            <TextSpan> {t("alarm")}</TextSpan>
                          </>
                        ) : device.alarm_status === 2 ? (
                          <>
                            <IconStyled src={ellipseYellow} style={{ marginTop: "-3px" }} />
                            <TextSpan>{t("Moderate")}</TextSpan>
                          </>
                        ) : (
                          <>
                            <IconStyled src={ellipseGreen} style={{ marginTop: "-3px" }} />
                            <TextSpan>{t("Clear")}</TextSpan>
                          </>
                        )}
                      </>
                    </td>
                    {/* <td>
                      <TextSpan>
                        {device.groups
                          .map((id: any) => {
                            let device = groupsList.find((item: any) => {
                              return item.groupId.toString() === id;
                            });
                            return device ? device.groupName : "";
                          })
                          .join(", ")}
                      </TextSpan>
                    </td>*/}
                    <td style={{ width: 133.5 }}>
                      <TextSpan>
                        {
                          (() => {
                            let deviceGroupNames = device.groups
                              .reduce((acc:any, id:any) => {
                                let device = groupsList.find((item:any) => item.groupId.toString() === id);
                                if (device) {
                                  acc.push(device.groupName);
                                }
                                return acc;
                              }, []);

                            if (deviceGroupNames.length > 0) {
                              const itemsToShow = deviceGroupNames.slice(0, 2);
                              const hasMoreItems = deviceGroupNames.length > 2;
                              return (
                                <>
                                  {itemsToShow.map((groupName: string) =>
                                    <div
                                      style={{
                                        //display: "flex",
                                        display: "inline-block",
                                        gap: "6px",
                                        alignItems: "center",
                                        marginBottom: "8px",
                                        marginLeft: "3px",
                                        marginRight: "3px",
                                        padding: "4px 12px",
                                        borderRadius: "2px",
                                        background: accentButtonsColor === "#046F6D" ? accentButtonsColor + 10 : accentButtonsColor + 30,
                                        filter: accentButtonsColor === "#046F6D" ? "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))" : "saturate(300%) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))",
                                        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)"
                                      }}
                                      /* style={{
                                        display: "inline-block", // Añade esta línea
                                        gap: "6px",
                                        alignItems: "center",
                                        margin: "8px 4px",
                                        padding: "4px 12px",
                                        borderRadius: "2px",
                                        background: "#E8F3FC",
                                        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
                                        filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))",
                                      }} */
                                    >
                                      <TextSpan>{groupName}</TextSpan>
                                    </div>
                                  )}
                                  {hasMoreItems && 
                                    <div
                                      title={deviceGroupNames.slice(2).join(', ')}
                                      style={{
                                        display: "inline-block",
                                        gap: "6px",
                                        alignItems: "center",
                                        marginBottom: "8px",
                                        marginLeft: "3px",
                                        marginRight: "3px",
                                        padding: "4px 12px",
                                        borderRadius: "2px",
                                        background: accentButtonsColor === "#046F6D" ? accentButtonsColor + 10 : accentButtonsColor + 30,
                                        filter: accentButtonsColor === "#046F6D" ? "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))" : "saturate(300%) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))",
                                        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)"
                                        //filter: "drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.1))",
                                      }}
                                    >
                                      <TextSpan>...</TextSpan>
                                    </div>
                                  }
                                </>
                              );
                            } else {
                              return (
                                <></>
                              )
                            }
                          })()
                        }
                      </TextSpan>
                    </td>
                    <td style={{ width: 133.5 }}>
                      {selectedNodeId === device.node_id ? 
                        <div style={{ display: "flex", gap: "10px" }}>
                          <Button onClick={() => {console.log(device); updateMapLocation(device.node_id, device.organizationId, device.service)}}>{t("Update")}</Button>
                          <Button onClick={() => {setBlocked(false); setSelectedNodeId(""); dispatch(setSelectedNodeIdActionCreator(""))}}>{t("Cancel")}</Button>
                        </div>
                      :
                        device.latitude && device.longitude ?
                          <div style={{ display: "flex", gap: "6px", alignItems: "center" }}>
                            <Text marginBottom="0px" style={{ width: "90px" }}>{device.latitude}, {device.longitude}</Text>
                            {/* <Button disabled={blocked}>
                              <img src={addLocation} height={12} style={{ cursor: "pointer" }} alt={"addLocation"} onClick={() => {dispatch(setIsOpenedDetailActionCreator(false)); setBlocked(true); setSelectedNodeId(device.node_id); dispatch(setNewNodeLatLongActionCreator("")); dispatch(setSelectedNodeIdActionCreator(device.node_id))}} />
                            </Button> */}
                            {userRole !== "observer" && userRole !== "maintenance" ?
                              <img src={addLocation} height={12} style={{ cursor: blocked ? "not-allowed" : "pointer" }}  alt={"addLocation"} onClick={() => {if (!blocked) {dispatch(setIsOpenedDetailActionCreator(false));
                                setBlocked(true); setSelectedNodeId(device.node_id); dispatch(setNewNodeLatLongActionCreator("")); dispatch(setSelectedNodeIdActionCreator(device.node_id))}}} />
                            :
                              <></>
                            }
                          </div>
                        :
                          userRole !== "observer" && userRole !== "maintenance" ?
                            <Button disabled={blocked} onClick={() => {dispatch(setIsOpenedDetailActionCreator(false)); setBlocked(true); setSelectedNodeId(device.node_id);
                              dispatch(setNewNodeLatLongActionCreator("")); dispatch(setSelectedNodeIdActionCreator(device.node_id))}}
                            >
                              {t("assignLocation")}
                            </Button>
                          :
                            <></>
                          
                      }
                    </td>
                    <td style={{ textAlign: "center", width: "34px" }}>
                      <img
                        src={Arrow_right_full}
                        height={12}
                        alt={"arrow right"}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleClickViewDetails(device);
                        }}
                      />
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Scroll>
        </table>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", backgroundColor: supportTextColor + 20, padding: "6px" }}>
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={list.length}  // Total de elementos
            onChange={handlePageChange}  // Función que maneja el cambio de página/tamaño
            showSizeChanger  // Muestra opciones para cambiar el tamaño de página
            pageSizeOptions={list.length > 200 ? ["200", String(list.length)] : ["200"]}  // Opciones de tamaño de página
            showTotal={(total, range) => `${range[0]}-${range[1]} ${t("of")} ${total} ${t("elements")}`}  // Muestra el total de elementos
            itemRender={itemRender}  // Personalización de los botones "anterior" y "siguiente"
            className="custom-pagination"
            locale={{ items_per_page: `${t("page")}` }}
          />
        </div>
      </div>
    </>
  );
};
