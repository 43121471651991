import styled from "styled-components";

export const DataCardStyled = styled.section`
  display: flex;
  flex-direction: row;
  padding: 12px;
  gap: 14px;
  width: fit-content;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  background-color: white;
  height: 74px;
`;

export const IconsDataCardStyled = styled.section`
  alignSelf: center;
  min-height: 96px;

  .data-card {
    &__main-icon {
      &--alert {
        background: linear-gradient(145.39deg, #ff8686 5.72%, #ff3333 93.06%);
        color: #ff3333;
      }
    }

    &__alert-icon {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
    }
  }
`;

export const TextDataCardStyled = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
  padding-top: 12px;
  padding-bottom: 6px;
`;

export const ArrowDataCardStyled = styled.section`
  align-self: flex-end;
`;
