import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { userRegistrationSchema } from "../../../../schema/userRegistrationSchema";
import { registerUserActionCreator } from "../../../../redux/slices/userRegistrationSlice/userRegistrationSlice";
import InputForProfile from "../../../../components/Input/InputForProfile";
import { CardContainer, NextButton } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import mail from "../../../../assets/new_icons/mail.svg";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface DetailsProps {
  goToRoles?: any;
  errorMessage?: boolean;
  setErrorMessage?: any;
}

export const Details = ({ goToRoles, errorMessage, setErrorMessage }: DetailsProps) => {
  const user = useAppSelector((state) => state.userRegistration.user);
  const [formData, setFormData] = useState({
    first_name: user?.first_name ?? "",
    last_name: user?.last_name ?? "",
    phoneNumber: user?.phoneNumber ?? "",
    email: user?.email ?? "",
    password: user?.password ?? "",
    password_confirm: user?.password_confirm ?? "",
  });
  const [formErrors, setFormErrors] = useState({
    first_name: "",
    last_name: "",
    phoneNumber: "",
    email: "",
    password: "",
    password_confirm: "",
  });
  const [submitError, setSubmitError] = useState("");

  const dispatch = useAppDispatch();
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const handleChange = (field: any, value: any) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
    setFormErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  };

  const handleDetailsInfo = (e: any) => {
    e.preventDefault();

    try {
      userRegistrationSchema(t).parse(formData);
      dispatch(registerUserActionCreator(formData))
      goToRoles();
    } catch (error) {
      if (error instanceof z.ZodError) {
        const errors = {};
        error.errors.forEach((err) => {
          // @ts-ignore
          errors[err.path[0]] = err.message;
        });
        // @ts-ignore
        setFormErrors(errors);
      } else {
        setSubmitError("An error occurred during form validation.");
      }
    }
  };

  useEffect(() => {
    dispatch(registerUserActionCreator(formData))

    if (formData.first_name || formData.email || formData.password || formData.password_confirm) {
      setErrorMessage(false)
    }
  }, [formData]);

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ marginBottom: "47px", paddingLeft: "40px" }}>
        <Text fontWeight="500" marginBottom="4px">{t("step1")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("generalDetails")}</Text>
        <Text fontWeight="400" style={{fontSize: "12px"}}>{t("generalDetailsSubtitleUsers")}</Text>
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignSelf: "center", justifySelf: "center", width: "340px", marginBottom: formErrors ? "11px" : "32px" }}>
        <div style={{ marginBottom: formErrors?.first_name ? "0px" : "21px" }}>
          <Text color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("firstName")}*</Text>
          <input
            className={`form-control ${formErrors?.first_name ? "error_border" : ""}`}
            value={formData.first_name}
            onChange={(e) => handleChange("first_name", e.target.value)}
            placeholder={t("firstName")}
            maxLength={50}
          />
          {formErrors?.first_name && (
            <Text marginBottom="0px" style={{ display: "flex", justifyContent: "left", color: "red" }}>{formErrors?.first_name}</Text>
          )}
        </div>
        <div style={{ marginBottom: formErrors?.last_name ? "0px" : "21px" }}>
          <Text color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("lastName")}</Text>
          <input
            className={`form-control ${formErrors?.last_name ? "error_border" : ""}`}
            value={formData.last_name}
            onChange={(e) => handleChange("last_name", e.target.value)}
            placeholder={t("lastName")}
            maxLength={50}
          />
          {formErrors?.last_name && (
            <Text marginBottom="0px" style={{ display: "flex", justifyContent: "left", color: "red" }}>{formErrors?.last_name}</Text>
          )}
        </div>
        <div style={{ marginBottom: formErrors?.phoneNumber ? "0px" : "21px" }}>
          <Text color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("phoneNumber")}</Text>
          <input
            className={`form-control ${formErrors?.phoneNumber ? "error_border" : ""}`}
            value={formData?.phoneNumber}
            onChange={(e) => handleChange("phoneNumber", e.target.value)}
            placeholder={"+(___) ___-____-____"}
            maxLength={50}
          />
          {formErrors?.phoneNumber && (
            <Text marginBottom="0px" style={{ display: "flex", justifyContent: "left", color: "red" }}>{formErrors?.phoneNumber}</Text>
          )}
        </div>
        <div style={{ marginBottom: formErrors?.email ? "0px" : "21px" }}>
          <Text color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("Email")}*</Text>
          <input
            className={`form-control ${formErrors?.email ? "error_border" : ""}`}
            value={formData?.email}
            onChange={(e) => handleChange("email", e.target.value)}
            placeholder={t("Email")}
            maxLength={50}
          />
          {formErrors?.email && (
            <Text marginBottom="0px" style={{ display: "flex", justifyContent: "left", color: "red" }}>{formErrors?.email}</Text>
          )}
        </div>
        <div style={{ marginBottom: formErrors?.password ? "0px" : "21px" }}>
          <Text color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("password")}*</Text>
          <InputForProfile
            suffix_icon={mail}
            placeholder={t("password")}
            inputType="password"
            error={formErrors?.password}
            marginBottom="small"
            value={formData?.password}
            onChange={(e) => handleChange("password", e.target.value)}
          />
          {formErrors?.password && (
            <Text marginBottom="0px" style={{ display: "flex", justifyContent: "left", color: "red" }}>{formErrors?.password}</Text>
          )}
        </div>
        <div style={{ marginBottom: formErrors?.password_confirm ? "0px" : "21px" }}>
          <Text color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{t("Confirm Password")}*</Text>
          <InputForProfile
            suffix_icon={mail}
            placeholder={t("Confirm Password")}
            inputType="password"
            error={formErrors?.password_confirm}
            marginBottom="small"
            value={formData?.password_confirm}
            onChange={(e) => handleChange("password_confirm", e.target.value)}
          />
          {formErrors?.password_confirm && (
            <Text marginBottom="0px" style={{ display: "flex", justifyContent: "left", color: "red" }}>{formErrors?.password_confirm}</Text>
          )}
        </div>
      </div>
      {errorMessage ?
        <Text marginBottom="0px" color="red" style={{ display: "flex", justifySelf: "end", alignSelf: "center", marginTop: "33px" }}>{t("errorBlankInput")}</Text>  
      :
        <></>
      }
      <NextButton accentButtonsColor={accentButtonsColor} style={{ justifySelf: "end", alignSelf: "center", width: "340px", marginTop:  errorMessage ? "16px" : "70px" }} onClick={handleDetailsInfo}>{t("continue")}</NextButton>
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px" }}>
        <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        {/* <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} /> */}
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
      </div>
    </CardContainer>
  );
};
