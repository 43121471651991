import { useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";
import zoomPlugin from "chartjs-plugin-zoom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend,
  registerables,
} from "chart.js";
import { Line } from "react-chartjs-2";
import 'chart.js/auto';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  Legend,
  zoomPlugin,
  ...registerables
);

{/* <div style={{ height: "207px", width: "430px", display: "flex" }}>
  <Line options={options} data={data} style={{flexGrow: 1}} />
</div> */}

function ConsumptionChart({ isVisible }:any) {
  const chargePower = useAppSelector((state) => state.solarParameters.chargePower);
  const batteryVoltage = useAppSelector((state) => state.solarParameters.batteryVoltage);
  const batteryPercentage = useAppSelector((state) => state.solarParameters.batteryPercentage);
  const activePower = useAppSelector((state) => state.solarParameters.activePower);
  const hours = useAppSelector((state) => state.solarParameters.timestamps);

  const chartRef:any = useRef(null);
  const { t } = useTranslation();

  type ScaleOptions = {
    grid: {
      borderColor: string;
      color: string;
    };
    title: {
      display: boolean;
      text: (ctx: any) => string;
    };
  };

  const scaleOptions: ScaleOptions = {
    grid: {
      borderColor: "#ccc",
      color: "rgba(0, 0, 0, 0.1)",
    },
    title: {
      display: false,
      text: (ctx: any) => "Static Title",
    },
  };

  const scales = {
    x: {
      type: "category",
      ...scaleOptions,
    },
    y: {
      type: "linear",
      ticks: {
        callback: (val: any, index: any, ticks: any) =>
          index === 0 || index === ticks.length - 1 ? null : val,
      },
      ...scaleOptions,
    },
  };

  const options:object = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
        align: 'start' as const,
        display: false,
        labels: {
          usePointStyle: true,
          pointStyle: 'rect', /* 'circle', 'rect', 'rectRounded' */
          /* padding: 12, */
          boxHeight: 12,
          font: {
            size: 16
          }
        }
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          mode: "x" as const, // Specify 'x' as a constant type,
          drag: {
            enabled: true,
          },
        },
      },
      tooltip: {
        mode: 'index', // Muestra todos los valores en el índice
        intersect: false, // Permite que el tooltip se muestre incluso si no se está directamente sobre un punto
        callbacks: {
          label: (tooltipItem: any) => {
            const label = tooltipItem.dataset.label || '';
            const value = tooltipItem.raw; // Valor del punto
            return `${label}: ${value.toFixed(2)}`; // Formato del tooltip limitado a 2 decimales
          },
        },
      },
    },
    scales: scales as any,
  };

  const data = {
    labels: hours,
    datasets: [
      {
        fill: true,
        label: t("chargePower"),
        data: chargePower,
        borderColor: "#16D764",
        backgroundColor: "rgba(22, 215, 100, 0.4)",
        lineTension: 0.4,
        pointRadius: 0,
        borderWidth: 1,
        hidden: !isVisible[0]
      },
      {
        fill: true,
        label: t("battVoltage"),
        data: batteryVoltage,
        borderColor: "#00C7F2",
        backgroundColor: "rgba(0, 199, 242, 0.4)",
        lineTension: 0,
        pointRadius: 0,
        borderWidth: 1,
        hidden: !isVisible[1]
      },
      {
        fill: true,
        label: t("batt%"),
        data: batteryPercentage,
        borderColor: "#005882",
        backgroundColor: "rgba(100, 149, 237, 0.4)",
        stepped: true,
        pointRadius: 0,
        borderWidth: 1,
        hidden: !isVisible[2]
      },
      {
        fill: true,
        label: t("activePower"),
        data: activePower,
        borderColor: "#FFCB21",
        backgroundColor: "rgba(255, 203, 33, 0.4)",
        lineTension: 0.4,
        pointRadius: 0,
        borderWidth: 1,
        hidden: !isVisible[3]
      },
    ],
  };

  console.log("la data", data)

  //en vez de useeffect

  /* function updateChart() {
    if (chartRef && chartRef.current) {
      const chartInstance = chartRef.current.chartInstance;

      chartInstance.data = data;
      chartInstance.options = options;

      chartInstance.update();
    }
  }

  function toggleDataset(index) {
    const chartInstance = chartRef.current.chartInstance;
    const dataset = chartInstance.data.datasets[index];
    dataset.hidden = !dataset.hidden;
    updateChart();
  } */

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const chartInstance = chartRef.current.chartInstance;

      chartInstance.data = data;
      chartInstance.options = options;

      chartInstance.update();
    }
  }, [data, chargePower, batteryVoltage, batteryPercentage, activePower]);

  //width 630
  return (
    //width 56
    <Line width={850} height={190} options={options} data={data} />
  )
}

export default ConsumptionChart;
