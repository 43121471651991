import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { UsersChart } from "./UsersChart";
import { Text } from "../../../styles/shared-styles/text-sizes";

export const UsersCard = () => {
  const userRole = useAppSelector((state) => state.user.role_name);
  const reportsInfo = useAppSelector((state) => state.reports);
  const { t } = useTranslation();

  return (
    <div
      className="card"
      style={{
        display: "flex",
        padding: "16px 24px",
        border: "none",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)",
        backdropFilter: "blur(2px)",
        borderRadius: "16px",
        minHeight: "307px",
        flexGrow: 1
      }}
    >
      <Text style={{ fontWeight: 500 }}>{t("Users")}</Text>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", top: 117, marginLeft: 142, position: "absolute" }}>
        <Text style={{ fontSize: "12px", color: "#99B2C6", fontWeight: 500 }}>{t("total")}</Text>
        <Text color="#56C568" style={{ fontSize: "26px", fontWeight: 700 }}>{reportsInfo.usersTotal}</Text>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "30px", zIndex: 1 }}>
        <UsersChart />
      </div>
      {userRole === "owner" ?
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "20px", paddingBottom: "8px", gap: "10px" }}> 
          <div style={{ display: "flex", gap: "16px" }}>
            <div style={{ display: "flex", gap: "8px" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #1B2559", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("owner")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.usersOwner}</Text>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #FBBC05", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("admin")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.usersAdmin}</Text>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #45D0EE", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("observer")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.usersObserver}</Text>
            </div>
          </div>
          <div style={{ display: "flex", gap: "16px" }}>
            <div style={{ display: "flex", gap: "8px" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #444444", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("maintenance")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.usersMaintenance}</Text>
            </div>
            <div style={{ display: "flex", gap: "8px" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #A3AED0", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("external")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.usersExternal}</Text>
            </div>
          </div>
        </div>
      :
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px", paddingBottom: "8px", gap: "10px" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "40%" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #FBBC05", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("admin")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.usersAdmin}</Text>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #45D0EE", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("observer")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.usersObserver}</Text>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "40%" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #444444", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("maintenance")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.usersMaintenance}</Text>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <div style={{ borderRadius: "10px", border: "2px solid #A3AED0", width: "11px", height: "11px", marginBottom: "1px" }} />
                <Text style={{ fontSize: "12px", fontWeight: 500, color: "#99B2C6" }}>{t("external")}</Text>
              </div>
              <Text style={{ alignSelf: "end", fontSize: "12px", fontWeight: 700, color: "#99B2C6" }}>{reportsInfo.usersExternal}</Text>
            </div>
          </div>
        </div>
      }
    </div>
  );
};
