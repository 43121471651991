import { useState } from "react";
import DatePicker from 'react-datepicker';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { useConsumptionsService } from "../../../services/consumptionsService";
import { NextButton } from "../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text, TextSpan } from "../../../styles/shared-styles/text-sizes";
import activePower from "../../../assets/new_icons/Active-power.svg";
import co2 from "../../../assets/new_icons/Co2.svg";
import currency from "../../../assets/new_icons/Currency.svg";
import energyEco from "../../../assets/new_icons/Energy_eco.svg";
import gateway from "../../../assets/new_icons/Gateway nodePage.svg";
import luminaire from "../../../assets/new_icons/luminaire detailCard.svg";
import sun from "../../../assets/new_icons/sun.svg";

interface ConsumptionsProps {
  setLoading: any
}

export const Consumptions = ({ setLoading }: ConsumptionsProps) => {
  //implementar el loading?
  const [selectedStandard, setSelectedStandard]:any = useState(true);
  const [selectedSolar, setSelectedSolar]:any = useState(false);
  const [selectedGateway, setSelectedGateway]:any = useState(false);
  const [startDate, setStartDate]:any = useState();
  const [endDate, setEndDate]:any = useState();
  const [result, setResult] = useState(false);
  const [consumInfo, setConsumInfo]:any = useState({});

  const { getStandardGroup } = useConsumptionsService();
  const groupInfo = useAppSelector((state) => state.groups.selectedGroup);
  const orgInfo: any = useAppSelector((state) => state.selectedOrganization);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const { t } = useTranslation();

  const handleConsumptionInfo = async () => {
    if (selectedStandard) {
      let ids: Array<string> = [];
      groupInfo.nodes.filter((node:any) => {
        if (node.solar_id === null) {
          ids.push(node.node_id)
        }
      })

      const standardResponse = await getStandardGroup(ids, startDate, endDate);
      setConsumInfo(standardResponse);
      setResult(true);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "35px", paddingRight: "10px", paddingLeft: "10px" }}>
      <div>
        <Text color={mainTextColor} fontWeight="500" style={{ fontSize: "16px", marginBottom: "14px" }}>{t("selectDevice")}</Text>
        <div style={{ display: "flex", gap: "40px" }}>
          {groupInfo.nodes.filter((node:any) => node.solar_id === null).length !== 0 &&
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", gap: "14px", width: "230px", borderRadius: "16px", height: "74px",
              cursor: "pointer", boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)", border: selectedStandard ? `2px solid ${accentButtonsColor}` : "" }}
              onClick={() => {setSelectedStandard(true); setSelectedSolar(false); setSelectedGateway(false)}}
            >
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                <Text marginBottom="0px" fontWeight="700" style={{ fontSize: "28px", margin: "0px" }}>{groupInfo.nodes.filter((node:any) => node.solar_id === null).length}</Text>
                <Text marginBottom="10px">{t("standardLuminaires")}</Text>
              </div>
              <img src={luminaire} style={{ width: "30px", height: "30px", alignSelf: "end" }} />  
            </div>
          }
          {/* {groupInfo.nodes.filter((node:any) => node.solar_id !== null).length !== 0 &&
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", gap: "14px", width: "230px", borderRadius: "16px", height: "74px",
              cursor: "pointer", boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)", border: selectedSolar ? `2px solid ${accentButtonsColor}` : "" }}
              onClick={() => {setSelectedStandard(false); setSelectedSolar(true); setSelectedGateway(false)}}
            >
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                <Text marginBottom="0px" fontWeight="700" style={{ fontSize: "28px" }}>{groupInfo.nodes.filter((node:any) => node.solar_id !== null).length}</Text>
                <Text marginBottom="10px">{t("solarLuminaires")}</Text>
              </div>
              <img src={sun} style={{ width: "30px", height: "30px", alignSelf: "end" }} />  
            </div>
          } */}
          {/* falta la comprobación de que no haya 0 gateways */}
          {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", gap: "14px", width: "230px", borderRadius: "16px", height: "74px",
            cursor: "pointer", boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)", border: selectedStandard ? `1px solid ${accentButtonsColor}` : "" }}
            onClick={() => {setSelectedStandard(false); setSelectedSolar(false); setSelectedGateway(true)}}
          >
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
              <Text marginBottom="0px" fontWeight="700" style={{ fontSize: "28px" }}>40</Text>
              <Text marginBottom="10px">{t("Gateways")}</Text>
            </div>
            <img src={gateway} style={{ width: "30px", height: "30px", alignSelf: "end" }} />  
          </div> */}
        </div>
      </div>

      <div>
        <Text color={mainTextColor} fontWeight="500" style={{ fontSize: "16px", marginBottom: "14px" }}>{t("selectConsumDates")}</Text>
        <div style={{ display: "flex", gap: "16px" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Text marginBottom="0px">{t("startDate")}</Text>
            <DatePicker
              className="datePickerConsumption"
              placeholderText={t("selectDate")}
              selectsRange={false}
              selected={startDate}
              onChange={(date: any) => setStartDate(date)}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Text marginBottom="0px">{t("endDate")}</Text>
            <DatePicker
              className="datePickerConsumption"
              placeholderText={t("selectDate")}
              selectsRange={false}
              selected={endDate}
              onChange={(date: any) => setEndDate(date)}
            />
          </div>
          <NextButton accentButtonsColor={accentButtonsColor} style={{ boxShadow: "0px 2px 4px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2)", alignSelf: "end", width: "140px",
            padding: "8px 8px", marginLeft: "10px", height: "42px", opacity: (!selectedStandard && !selectedSolar && !selectedGateway) || !startDate || !endDate ? 0.5 : 1,
            cursor: (!selectedStandard && !selectedSolar && !selectedGateway) || !startDate || !endDate ? "not-allowed" : "pointer" }}
            onClick={() => {(selectedStandard || selectedSolar || selectedGateway) && startDate && endDate && handleConsumptionInfo()}}
            disabled={!startDate || !endDate}
          >
            {t("viewData")}
          </NextButton>
        </div>
      </div>

      {selectedStandard && result ?
        <div>
          <Text color={mainTextColor} fontWeight="500" style={{ fontSize: "16px", marginBottom: "14px" }}>{t("consumsAverage")}</Text>
          <div style={{ display: "flex", gap: "30px", flexWrap: "wrap", width: "fit-content" }}>
            {/* <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px", margin: "0px" }}
            >
              <Text fontWeight="700" style={{ display: "flex", alignSelf: "start",  marginTop: "12px", fontSize: "18px" }}>{t("activePowerAbr")}</Text>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <Text marginBottom="0px" fontWeight="700" style={{ fontSize: "28px" }}>
                    {consumInfo && consumInfo.activePower !== "0.00" && consumInfo.activePower !== undefined ? consumInfo.activePower : 0}
                  </Text>
                  <Text marginBottom="0px">{t("average")}</Text>
                </div>
                <img src={activePower} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
              </div>
            </div> */}

            <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px" }}
            >
              <Text fontWeight="700" style={{ display: "flex", alignSelf: "start", marginTop: "12px", marginBottom: "6px", fontSize: "18px" }}>{t("energyAbr")}</Text>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                <Text marginBottom="0px" fontWeight="700" color={supportTextColor} style={{ fontSize: "28px" }}>
                  {consumInfo && consumInfo.energyConsumed !== 0 && consumInfo.energyConsumed !== undefined ? (consumInfo.energyConsumed / 1000)?.toFixed(2) : 0}
                </Text>
                <Text marginBottom="0px" color={supportTextColor}>{t("consumed")}</Text>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>
                    {consumInfo && consumInfo.energySaved !== 0 && consumInfo.energySaved !== undefined ? (consumInfo.energySaved / 1000)?.toFixed(2) : 0}
                  </Text>
                  <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
                </div>
                <img src={energyEco} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
              </div>
            </div>

            <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px" }}
            >
              <div style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
                <Text fontWeight="700" style={{ fontSize: "18px" }}>{t("cost")} (-)</Text>
                <Text fontWeight="400" style={{ fontSize: "12px" }}>{orgInfo.price} / kW</Text>
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                <Text marginBottom="0px" fontWeight="700" color={supportTextColor} style={{ fontSize: "28px" }}>
                  {consumInfo && consumInfo.energyConsumed !== 0 && consumInfo.energyConsumed !== undefined ? (consumInfo.energyConsumed / 1000 * orgInfo.price).toFixed(2) : 0}
                </Text>
                <Text marginBottom="0px" color={supportTextColor}>{t("consumed")}</Text>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>
                    {consumInfo && consumInfo.energySaved !== 0 && consumInfo.energySaved !== undefined ? (consumInfo.energySaved / 1000 * orgInfo.price).toFixed(2) : 0}
                  </Text>
                  <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
                </div>
                <img src={currency} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
              </div>
            </div>

            <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
              boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px" }}
            >
              <div style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
                <Text fontWeight="700" style={{fontSize: "18px" }}>{t("co2Abr")}</Text>
                <Text fontWeight="400" style={{ fontSize: "12px" }}>{orgInfo.co2} / Kg</Text>
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                <Text marginBottom="0px" fontWeight="700" color={supportTextColor} style={{ fontSize: "28px" }}>
                  {consumInfo && consumInfo.energyConsumed !== 0 && consumInfo.energyConsumed !== undefined ? (consumInfo.energyConsumed / 1000 * orgInfo.co2).toFixed(2) : 0}
                </Text>
                <Text marginBottom="0px" color={supportTextColor}>{t("consumed")}</Text>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>
                    {consumInfo && consumInfo.energySaved !== 0 && consumInfo.energySaved !== undefined ? (consumInfo.energySaved / 1000 * orgInfo.co2).toFixed(2) : 0}
                  </Text>
                  <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
                </div>
                <img src={co2} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
              </div>
            </div>
          </div>
        </div>
      :
        <></>
      }
    </div>
  );
};
