import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { setIsFullViewActionCreator, setIsHybridViewActionCreator, setIsListViewActionCreator } from "../../redux/slices/uiSlice/uiSlice";
import { CardContainerResponsePage } from "./ResponsePageStyled";
import { Button } from "../../styles/shared-styles/buttons-sizes";
import { Text } from "../../styles/shared-styles/text-sizes";
import notFound from "../../assets/new_icons/404.svg";

export const NotFoundPage = () => {
  const dispatch = useAppDispatch();
  const viewState = localStorage.getItem('viewState');
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  const manageView = () => {
    if (viewState === "full") {
      dispatch(setIsFullViewActionCreator());
    } else if (viewState === "hybrid") {
      dispatch(setIsHybridViewActionCreator());
    } else if (viewState === "list") {
      dispatch(setIsListViewActionCreator());
    }
  };

  return (
    <CardContainerResponsePage>
      <div className="row text-center">
        <div className="col-12">
          <img src={notFound} />
          <Text
            textType="title"
            color={accentButtonsColor}
            fontWeight="700"
            marginBottom="10px"
          >
            {t("notFoundTitle")}
          </Text>
          <Text textType="subTitle" color={accentButtonsColor} fontWeight="400" marginBottom="20px">
            {t("notFoundSubtitle")}
          </Text>
          <a href="/dashboard">
            <Button buttonType="primary" onClick={() => {manageView()}} style={{ background: buttonsColor, color: accentTextColor }}>{t("goBackDashboard")}</Button>
          </a>
        </div>
      </div>
    </CardContainerResponsePage>
  );
};
