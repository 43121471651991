import Cookies from "js-cookie";
import { store } from '../redux/store';
import { saveSelectedOrgInfoActionCreator } from "../redux/slices/selectedOrganizationSlice/selectedOrganizationSlice";

export const useOrganizationsService = () => {
  const token = Cookies.get("token");

  const getSelectedOrganizationInfo = async (orgId: number, serviceId: number): Promise<boolean> => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_ORG_I as string}${orgId}&servId=${serviceId}`, {credentials: 'include'});
        const orgInfo = await response.json();

        store.dispatch(saveSelectedOrgInfoActionCreator({ 
          id: orgInfo.id,
          orgName: orgInfo.organization_name,
          latitude: orgInfo.latitude,
          longitude: orgInfo.longitude,
          city: orgInfo.city,
          country: orgInfo.country,
          limitUsers: orgInfo.limit_users,
          limitGroups: orgInfo.limit_groups,
          limitStrategies: orgInfo.limit_strategies,
          limitEvents: orgInfo.limit_events,
          currency: orgInfo.currency,
          price: orgInfo.price,
          co2: orgInfo.co2,
          img: orgInfo.image,
          navBarColor: orgInfo.navbar_color || "#FFFFFF",
          buttonsColor: orgInfo.buttons_color || "#FFC107",
          accentButtonsColor: orgInfo.accentButton_color || "#1B2559",
          mainTextColor: orgInfo.main_text || "#475569",
          supportTextColor: orgInfo.support_text || "#434F72",
          accentTextColor: orgInfo.accent_text || "#1B2559"
          /* navBarColor: orgInfo.navbar_color || "#046F6D",
          buttonsColor: orgInfo.buttons_color || "#046F6D",
          accentButtonsColor: orgInfo.accentButton_color || "#046F6D",
          mainTextColor: orgInfo.main_text || "#3C3C3B",
          supportTextColor: orgInfo.support_text || "#046F6D",
          accentTextColor: orgInfo.accent_text || "#FFFFFF" */,
          strategyColor1: orgInfo.strg1_color,
          strategyColor1Name: orgInfo.strg1_name,
          strategyColor2: orgInfo.strg2_color,
          strategyColor2Name: orgInfo.strg2_name,
          strategyColor3: orgInfo.strg3_color,
          strategyColor3Name: orgInfo.strg3_name,
          strategyColor4: orgInfo.strg4_color,
          strategyColor4Name: orgInfo.strg4_name,
          strategyColor5: orgInfo.strg5_color,
          strategyColor5Name: orgInfo.strg5_name,
        }));
  
        return true;
      } catch (error){
        console.log(error);
        return false;

      }
    };

  const updateOrgName = async (orgId: string, name: string) => {

    const body = {
      "name": name
    }

    try {
      let response = await fetch(`${process.env.REACT_APP_API_ORG_N_U as string}${orgId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const updateOrgConsumption = async (orgId: string, /* currency: string, */ price: number, emissions: number) => {
    const values = store.getState();
    
    const body = {
      /* "currency": currency || values.selectedOrganization.currency, */
      "price": price || values.selectedOrganization.price,
      "co2": emissions || values.selectedOrganization.emissions
    }
    
    try {
      let response = await fetch(`${process.env.REACT_APP_API_ORG_I_U as string}${orgId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const updateOrgImage = async (orgId: string, image: any) => {    
    try {
      let response = await fetch(`${process.env.REACT_APP_API_ORG_P_U as string}${orgId}`, {
        method: "POST",
        credentials: "include",
        body: image,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const data = await response.json();
      if (data.success) {
        console.log('Image uploaded successfully:', data.imageUrl);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const updatePlatformPalette = async (orgId: string, navBarColor: string, buttonsColor: string, accentButtonsColor: string, mainTextColor: string, supportTextColor: string,
    accenTextColor: string) => {

    const body = {
      "navbar_color": navBarColor,
      "buttons_color": buttonsColor,
      "accentButton_color": accentButtonsColor,
      "main_text": mainTextColor,
      "support_text": supportTextColor,
      "accent_text": accenTextColor
    }

    try {
      let response = await fetch(`${process.env.REACT_APP_API_ORG_I_U as string}${orgId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      });

      await response.json();
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  const updateStrategiesPalette = async (orgId: string, strategyColor1: string, strategyColor1Name: string, strategyColor2: string, strategyColor2Name: string, strategyColor3: string,
    strategyColor3Name: string, strategyColor4: string, strategyColor4Name: string, strategyColor5: string, strategyColor5Name: string) => {

    const body = {
      "strg1_color": strategyColor1,
      "strg1_name": strategyColor1Name,
      "strg2_color": strategyColor2,
      "strg2_name": strategyColor2Name,
      "strg3_color": strategyColor3,
      "strg3_name": strategyColor3Name,
      "strg4_color": strategyColor4,
      "strg4_name": strategyColor4Name,
      "strg5_color": strategyColor5,
      "strg5_name": strategyColor5Name,
    }

    try {
      let response = await fetch(`${process.env.REACT_APP_API_ORG_I_U as string}${orgId}`, {
        method: "PATCH",
        credentials: "include",
        body: JSON.stringify(body),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      });

      const aa = await response.json();

      console.log(aa)
    } catch (error) {
      console.log("El error: ", error);
      return false;
    }
  };

  return {
    getSelectedOrganizationInfo,
    updateOrgName,
    updateOrgConsumption,
    updateOrgImage,
    updatePlatformPalette,
    updateStrategiesPalette
  };
};
