import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useAppSelector } from "../../../redux/hooks";
import { RootState, store } from "../../../redux/store";
import { getManagedUserPersonalInfoAction } from "../../../redux/actions/usersActions/usersActions";
import { useUsersService } from "../../../services/usersService";
import { Groups } from "./Groups/Groups";
import { LoaderModal } from "../../../components/LoaderModal/LoaderModal";
import { Organizations } from "./Organizations/Organizations";
import { PersonalInformation } from "./PersonalInformation/PersonalInformation";
import { Services } from "./Services/Services";
import { UserAvatar } from "../../../utils/reusableComponents";
import { IconStyled, ListItem } from "../../ProfilePage/ProfilePageStyled";
import { CardContainer, EmptyCardContainer } from "../UsersPageStyled";
import { NavLink } from "../UsersList/UsersStructureStyled";
import { TextSpan, Text } from "../../../styles/shared-styles/text-sizes";
import mail from "../../../assets/new_icons/mail.svg";
import languageIcon from "../../../assets/new_icons/Language.svg";
import leftHand from "../../../assets/new_icons/left-hand.svg";
import userImage from "../../../assets/new_icons/user2.svg";

interface UsersDetailsCardProps {}

export const UsersDetailsCard = ({} : UsersDetailsCardProps) => {
  const [personalInfo, setPersonalInfo] = useState<boolean>(true);
  const [orgs, setOrgs] = useState<boolean>(false);
  const [services, setServices] = useState<boolean>(false);
  const [groups, setGroups] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const token = Cookies.get("token");
  const { getManagedUserPersonalInfo, getManagedUserOrgs, getManagedUserServices, getManagedUserGroups } = useUsersService();
  const userInfo = useAppSelector((state: RootState) => state.managedUser);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const getNavLinkStyle = (loading: boolean) => ({
    pointerEvents: loading ? ("none" as const) : ("auto" as const),
    cursor: loading ? "not-allowed" : "pointer",
  });

  useEffect(() => {
    /* if (userInfo.id) {
      setLoading(true);
      getManagedUserPersonalInfo(token, userInfo.id, setLoading);
    }
    setPersonalInfo(true);
    setOrgs(false);
    setServices(false);
    setGroups(false); */

    const fetchData = async () => {
      if (userInfo.id) {
        setLoading(true);
        const { user } = await getManagedUserPersonalInfo(token, userInfo.id, setLoading);
        if (user) {
          store.dispatch(getManagedUserPersonalInfoAction(user));
        }
      }
      setPersonalInfo(true);
      setOrgs(false);
      setServices(false);
      setGroups(false);
    }

    fetchData();
  }, [userInfo.id]);

  return (
    <>
      {userInfo.id ? (
        <CardContainer>
          <>
            <div style={{ display: "flex", gap: "30px", marginBottom: "20px", alignItems: "center" }}>
              <div>
                <UserAvatar firstName={userInfo.name} profileColor={userInfo.color} type={"card"} />
              </div>
              <div>
                <Text textType="subTitle" fontWeight="700">
                  {userInfo.name} {userInfo.surname}
                </Text>
                <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ListItem>
                    <IconStyled src={userImage} height={16} />
                    <TextSpan>{userInfo.role}</TextSpan>
                  </ListItem>
                  <ListItem>
                    <IconStyled src={mail} height={20} />
                    <TextSpan>{userInfo.email}</TextSpan>
                  </ListItem>
                  <ListItem>
                    <IconStyled src={languageIcon} height={18} />
                    <TextSpan>
                      {userInfo.language === "English" ? t("English") : userInfo.language === "Spanish" ? t("Spanish") : userInfo.language === "Catalan" ? t("Catalan") : 
                      userInfo.language === "French" ? t("French") : userInfo.language === "Italian" ? t("Italian") : userInfo.language === "Kinyarwanda" ? t("Kinyarwanda") : 
                      userInfo.language === "Swahili" ? t("Swahili") : ""}
                    </TextSpan>
                  </ListItem>
                </div>
                </div>
              </div>
            </div>
            <div className="nav nav-pills">
              <div style={{ display: "flex", marginBottom: "26px" }}>
                <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${personalInfo ? "active" : ""}`} style={getNavLinkStyle(loading)} id="personalinfo-tab" data-bs-toggle="tab" type="button"
                  onClick={async() => {setLoading(true); await getManagedUserPersonalInfo(token, userInfo.id, setLoading); setPersonalInfo(true); setOrgs(false); setServices(false);
                  setGroups(false)}}
                >
                  {t("Personal Information")}
                </NavLink>
                <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${orgs ? "active" : ""}`} style={getNavLinkStyle(loading)} id="orgs-tab" data-bs-toggle="tab" type="button"
                  onClick={async() => {setLoading(true); await getManagedUserOrgs(token, userInfo.id, setLoading); setPersonalInfo(false); setOrgs(true); setServices(false);
                  setGroups(false)}}
                >
                  {t("Organizations")}
                </NavLink>
                <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${services ? "active" : ""}`} style={getNavLinkStyle(loading)} id="services-tab" data-bs-toggle="tab" type="button"
                  onClick={async() => {setLoading(true); await getManagedUserServices(token, userInfo.id, setLoading); setPersonalInfo(false); setOrgs(false); setServices(true);
                  setGroups(false)}}
                >
                  {t("Services")}
                </NavLink>
                <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${groups ? "active" : ""}`} style={getNavLinkStyle(loading)} id="groups-tab" data-bs-toggle="tab" type="button"
                  onClick={async() => {setLoading(true); await getManagedUserGroups(token, userInfo.id, setLoading); setPersonalInfo(false); setOrgs(false); setServices(false);
                  setGroups(true)}}
                >
                  {t("Groups")}
                </NavLink>
              </div>
            </div>
            {loading ? (
              <LoaderModal showLoader={loading} />
            ) : personalInfo ? (
              <PersonalInformation />
            ) : orgs ? (
              <Organizations />
            ) : services ? (
              <Services />
            ) : groups ? (
              <Groups />
            ) : (
              <></>
            )}
          </>
        </CardContainer>
      ) 
      : 
      (
        <EmptyCardContainer>
          <div style={{ display: "flex", gap: "14px" }}>
            <img src={leftHand} width={60} />
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Text color={mainTextColor} textType="title" fontWeight="700" marginBottom="0">
                {t("selectUserForInfoTitle")}
              </Text>
              <Text textType="caption" color={mainTextColor} marginBottom="0">
                {t("selectUserForInfoSubtitle")}
              </Text>
            </div>
          </div>
        </EmptyCardContainer>
      )}
    </>
  );
};
