import { useState, useEffect } from "react";
import DatePicker from 'react-datepicker';
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { resetGroupInfoActionCreator } from "../../../redux/slices/groupsSlice/groupsSlice";
import { useConsumptionsService } from "../../../services/consumptionsService";
import { NextButton } from "../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../styles/shared-styles/text-sizes";
import { NavLink } from "../../GroupsPage/GroupsDetailsCard/GroupsDetailsCardStyled";
import activePower from "../../../assets/new_icons/Active-power.svg";
import co2 from "../../../assets/new_icons/Co2.svg";
import currency from "../../../assets/new_icons/Currency.svg";
import energyEco from "../../../assets/new_icons/Energy_eco.svg";

interface StrategiesListModalProps {
  showModal: boolean;
  setShowModal: any;
  currentRows?: any;
}

const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 1400px;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    height: 750px;
    width: 90%;
    background: white;
    border: 0px;
  }
`;

export const ConsumptionsModal = ({ showModal, setShowModal, currentRows }: StrategiesListModalProps) => {
  const [showPreventPopup, setShowPreventPopup] = useState(false);
  const [selectedStandard, setSelectedStandard]:any = useState(true);
  const [selectedSolar, setSelectedSolar]:any = useState(false);
  const [selectedGateway, setSelectedGateway]:any = useState(false);
  const [selected, setSelected]:any = useState(false);
  const [startDate, setStartDate]:any = useState();
  const [endDate, setEndDate]:any = useState();
  const [result, setResult] = useState(false);
  const [consumInfo, setConsumInfo]:any = useState({});

  const { getStandardGroup } = useConsumptionsService();
  const dispatch = useAppDispatch();
  const nodesInArea:any = useAppSelector((state) => state.devices.nodesInArea);
  const areaSelected:any = useAppSelector((state) => state.ui.nodesInArea);
  const nodesInGroup:any = useAppSelector((state) => state.filteredDevices.nodesFromGroups);
  const orgInfo: any = useAppSelector((state) => state.selectedOrganization);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const { t } = useTranslation();

  const handleConsumptionInfo = async () => {
    if (selectedStandard) {
      let ids: Array<string> = [];
      if (areaSelected) {
        nodesInArea.filter((node:any) => {
          if (node.solar_id === null) {
            ids.push(node.node_id)
          }
        })
      } else if (currentRows) {
        currentRows.filter((node:any) => {
          if (node.solar_id === null) {
            ids.push(node.node_id)
          }
        })
      } else {
        nodesInGroup.filter((node:any) => {
          if (node.solar_id === null) {
            ids.push(node.node_id)
          }
        })
      }

      const standardResponse = await getStandardGroup(ids, startDate, endDate);
      setConsumInfo(standardResponse);
      setResult(true);
    }
  };

  const handlePreventPopup = () => {
    setShowPreventPopup(true);
  };

  useEffect(() => {
    if (currentRows?.filter((node:any) => node.solar_id === null).length === 0 &&
    nodesInGroup?.filter((node:any) => node.solar_id === null).length === 0) {
      setSelected(false);
    } else {
      setSelected(true);
    }

    if (areaSelected) {
      if (nodesInArea?.filter((node:any) => node.solar_id === null).length === 0) {
        setSelected(false);
      } else {
        setSelected(true);
      }
    } else if (currentRows) {
      if (currentRows?.filter((node:any) => node.solar_id === null).length === 0) {
        setSelected(false);
      } else {
        setSelected(true);
      }
    } else {
      if (nodesInGroup?.filter((node:any) => node.solar_id === null).length === 0) {
        setSelected(false);
      } else {
        setSelected(true);
      }
    }
  }, [selectedStandard, selectedSolar, selectedGateway]);

  return (
    <CustomModal show={showModal} onHide={handlePreventPopup} centered>
      {showPreventPopup ?
        <div style={{ background: "rgba(255, 255, 255, 0.8)", boxShadow: "0px 2px 5px 2px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)",
          borderRadius: "14px", padding: "30px", display: "flex", flexDirection: "column", position: "absolute",  alignItems: "center",
          justifyContent: "center", top: "304px", left: "407px", zIndex: 30
        }}>
          <Text>{t("Do you want to close this form? All the information will be lost")}</Text>
          <div style={{ display: "flex", gap: "30px", marginTop: "16px" }}>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {dispatch(resetGroupInfoActionCreator()); setShowModal(!showModal)}}>{t("Accept")}</button>
            <button style={{padding: "8px", paddingLeft: "16px", paddingRight: "16px", borderRadius: "14px" }} onClick={() => {setShowPreventPopup(false)}}>{t("Cancel")}</button>
          </div>
        </div>
      :
        <></>
      }
      <Modal.Body style={{ pointerEvents: showPreventPopup ? "none" : "auto", background: "white", paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px",
        paddingRight: "0px", borderTopRightRadius: "0.5rem", borderTopLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem", borderBottomLeftRadius: "0.5rem", display: "flex",
        flexDirection: "column"}}
      >
        <div style={{ display: "flex", width: "100%", padding: "8px 10px 0px 0px", flexDirection: "column", background: "#ffffff", borderTopRightRadius: "0.5rem",
          borderTopLeftRadius: "0.5rem", justifyContent: "flex-end" }}
        >
          <Modal.Header
            closeButton
            style={{
              borderBottom: "none",
              background: "white",
              zIndex: 2
            }}
          />
        </div>
        <div style={{ paddingLeft: "50px", paddingRight: "50px", paddingTop: "10px", display: "flex", flexDirection: "column" }}>
          <div>
            <Text fontWeight="700" marginBottom="5px" color={mainTextColor} style={{fontSize: "22px" }}>{t("Consumption")}</Text>
            <Text fontWeight="400" marginBottom="20px" color={mainTextColor} style={{fontSize: "16px" }}>{t("consumsReport")}</Text>
          </div>
          <div className="nav nav-pills" style={{ marginBottom: "26px" }}>
            {areaSelected ?
              nodesInArea.filter((node:any) => node.solar_id === null).length !== 0 &&
                <div className="nav-item">
                  <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${selectedStandard ? "active" : ""}`} id="mainInfo-tab" data-bs-toggle="tab" type="button"
                    onClick={() => {setSelectedStandard(true); setSelectedSolar(false); setSelectedGateway(false)}}
                  >
                    {t("standardLuminaires")}
                  </NavLink>
                </div>
              
              /* {nodesInArea.filter((node:any) => node.solar_id !== null).length !== 0 &&
                <div className="nav-item">
                  <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${selectedSolar ? "active" : ""}`} id="consumptions-tab" data-bs-toggle="tab" type="button"
                    onClick={() => {setSelectedStandard(false); setSelectedSolar(true); setSelectedGateway(false)}}
                  >
                    {t("solarLuminaires")}
                  </NavLink>
                </div>
              } */
            :
              currentRows ?
                currentRows.filter((node:any) => node.solar_id === null).length !== 0 &&
                  <div className="nav-item">
                    <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${selectedStandard ? "active" : ""}`} id="mainInfo-tab" data-bs-toggle="tab" type="button"
                      onClick={() => {setSelectedStandard(true); setSelectedSolar(false); setSelectedGateway(false)}}
                    >
                      {t("standardLuminaires")}
                    </NavLink>
                  </div>
                
                /* {currentRows.filter((node:any) => node.solar_id !== null).length !== 0 &&
                  <div className="nav-item">
                    <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${selectedSolar ? "active" : ""}`} id="consumptions-tab" data-bs-toggle="tab" type="button"
                      onClick={() => {setSelectedStandard(false); setSelectedSolar(true); setSelectedGateway(false)}}
                    >
                      {t("solarLuminaires")}
                    </NavLink>
                  </div>
                } */
              :
                nodesInGroup.filter((node:any) => node.solar_id === null).length !== 0 &&
                  <div className="nav-item">
                    <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${selectedStandard ? "active" : ""}`} id="mainInfo-tab" data-bs-toggle="tab" type="button"
                      onClick={() => {setSelectedStandard(true); setSelectedSolar(false); setSelectedGateway(false)}}
                    >
                      {t("standardLuminaires")}
                    </NavLink>
                  </div>
                
                /* {nodesInGroup.filter((node:any) => node.solar_id !== null).length !== 0 &&
                  <div className="nav-item">
                    <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${selectedSolar ? "active" : ""}`} id="consumptions-tab" data-bs-toggle="tab" type="button"
                      onClick={() => {setSelectedStandard(false); setSelectedSolar(true); setSelectedGateway(false)}}
                    >
                      {t("solarLuminaires")}
                    </NavLink>
                  </div>
                } */
            }
            {/* falta la comprobación de que no haya 0 gateways */}
            {/* <div className="nav-item">
              <NavLink accentButtonsColor={accentButtonsColor} className={`nav-link ${selectedGateway ? "active" : ""}`} id="consumptions-tab" data-bs-toggle="tab" type="button"
                onClick={() => {setSelectedStandard(false); setSelectedSolar(false); setSelectedGateway(true)}}
              >
                {t("Gateways")}
              </NavLink>
            </div> */}
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "35px", paddingRight: "10px", paddingLeft: "10px" }}>
            {selectedStandard ?
              <Text marginBottom="0px" fontWeight="500" style={{ margin: "0px" }}>{t("devicesSelected")}: 
                {areaSelected ?
                  nodesInArea.filter((node:any) => node.solar_id === null).length
                : 
                  currentRows ?
                    currentRows.filter((node:any) => node.solar_id === null).length
                  :
                    nodesInGroup.filter((node:any) => node.solar_id === null).length      
              }
              </Text>
            :
              selectedSolar ?
                <Text marginBottom="0px" fontWeight="500" style={{ margin: "0px" }}>{t("devicesSelected")}: 
                  {areaSelected ?
                    nodesInArea.filter((node:any) => node.solar_id !== null).length
                  :
                    currentRows ?
                      currentRows.filter((node:any) => node.solar_id !== null).length
                    :
                      nodesInGroup.filter((node:any) => node.solar_id !== null).length
                  }
                </Text>
              :
                <Text marginBottom="0px" fontWeight="500" style={{ margin: "0px" }}>{t("devicesSelected")}: 
                  {/* {areaSelected ?
                    nodesInArea.filter((node:any) => node.solar_id === null).length}
                  : currentRows ?
                      currentRows.filter((node:any) => node.solar_id === null).length
                    :
                      nodesInGroup.filter((node:any) => node.solar_id === null).length
                  } */}
                </Text>
            }
            <div>
              <Text color={mainTextColor} fontWeight="500" style={{ fontSize: "16px", marginBottom: "14px" }}>{t("selectConsumDates")}</Text>
              <div style={{ display: "flex", gap: "16px" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                  <Text marginBottom="0px">{t("startDate")}</Text>
                  <DatePicker
                    className="datePickerConsumption"
                    placeholderText={t("selectDate")}
                    selectsRange={false}
                    selected={startDate}
                    onChange={(date: any) => setStartDate(date)}
                    disabled={!selected}
                  />
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                  <Text marginBottom="0px">{t("endDate")}</Text>
                  <DatePicker
                    className="datePickerConsumption"
                    placeholderText={t("selectDate")}
                    selectsRange={false}
                    selected={endDate}
                    onChange={(date: any) => setEndDate(date)}
                    disabled={!selected}
                  />
                </div>
                <NextButton accentButtonsColor={accentButtonsColor} style={{ boxShadow: "0px 2px 4px 1px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2)", alignSelf: "end", width: "140px",
                  padding: "8px 8px", marginLeft: "10px", height: "42px", opacity: (!selectedStandard && !selectedSolar && !selectedGateway) || !startDate || !endDate || !selected ? 0.5 : 1,
                  cursor: (!selectedStandard && !selectedSolar && !selectedGateway) || !startDate || !endDate || !selected ? "not-allowed" : "pointer" }}
                  onClick={() => {(selectedStandard || selectedSolar || selectedGateway) && startDate && endDate && selected && handleConsumptionInfo()}}
                  disabled={!startDate || !endDate}
                >
                  {t("viewData")}
                </NextButton>
              </div>
              {!selected &&
                <Text color="red" style={{ fontSize: "14px", marginTop: "14px" }}>{t("noDevicesViewConsums")}</Text>
              }
              
            </div>

            {selectedStandard && result ?
              <div>
                <Text color={mainTextColor} fontWeight="500" style={{ fontSize: "16px", marginBottom: "14px" }}>{t("consumsAverage")}</Text>
                <div style={{ display: "flex", gap: "30px", flexWrap: "wrap", width: "fit-content" }}>
                  {/* <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px", margin: "0px" }}
                  >
                    <Text fontWeight="700" style={{ display: "flex", alignSelf: "start",  marginTop: "12px", fontSize: "18px" }}>{t("activePowerAbr")}</Text>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <Text marginBottom="0px" fontWeight="700" style={{ fontSize: "28px" }}>
                          {consumInfo && consumInfo.activePower !== "0.00" && consumInfo.activePower !== undefined ? consumInfo.activePower : 0}
                        </Text>
                        <Text marginBottom="0px">{t("average")}</Text>
                      </div>
                      <img src={activePower} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
                    </div>
                  </div> */}

                  <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px" }}
                  >
                    <Text fontWeight="700" style={{ display: "flex", alignSelf: "start", marginTop: "12px", marginBottom: "6px", fontSize: "18px" }}>{t("energyAbr")}</Text>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                      <Text marginBottom="0px" fontWeight="700" color={supportTextColor} style={{ fontSize: "28px" }}>
                        {consumInfo && consumInfo.energyConsumed !== 0 && consumInfo.energyConsumed !== undefined ? (consumInfo.energyConsumed / 1000)?.toFixed(2) : 0}
                      </Text>
                      <Text marginBottom="0px" color={supportTextColor}>{t("consumed")}</Text>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>
                          {consumInfo && consumInfo.energySaved !== 0 && consumInfo.energySaved !== undefined ? (consumInfo.energySaved / 1000)?.toFixed(2) : 0}
                        </Text>
                        <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
                      </div>
                      <img src={energyEco} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
                    </div>
                  </div>

                  <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px" }}
                  >
                    <div style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
                      <Text fontWeight="700" style={{ fontSize: "18px" }}>{t("cost")} (-)</Text>
                      <Text fontWeight="400" style={{ fontSize: "12px" }}>{orgInfo.price} / kW</Text>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                      <Text marginBottom="0px" fontWeight="700" color={supportTextColor} style={{ fontSize: "28px" }}>
                        {consumInfo && consumInfo.energyConsumed !== 0 && consumInfo.energyConsumed !== undefined ? (consumInfo.energyConsumed / 1000 * orgInfo.price).toFixed(2) : 0}
                      </Text>
                      <Text marginBottom="0px" color={supportTextColor}>{t("consumed")}</Text>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>
                          {consumInfo && consumInfo.energySaved !== 0 && consumInfo.energySaved !== undefined ? (consumInfo.energySaved / 1000 * orgInfo.price).toFixed(2) : 0}
                        </Text>
                        <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
                      </div>
                      <img src={currency} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
                    </div>
                  </div>

                  <div className="card" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", padding: "14px 20px", border: "none",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px", flexGrow: 1, width: "246px", height: "218px" }}
                  >
                    <div style={{ display: "flex", flexDirection: "column", alignSelf: "start" }}>
                      <Text fontWeight="700" style={{fontSize: "18px" }}>{t("co2Abr")}</Text>
                      <Text fontWeight="400" style={{ fontSize: "12px" }}>{orgInfo.co2} / Kg</Text>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%" }}>
                      <Text marginBottom="0px" fontWeight="700" color={supportTextColor} style={{ fontSize: "28px" }}>
                        {consumInfo && consumInfo.energyConsumed !== 0 && consumInfo.energyConsumed !== undefined ? (consumInfo.energyConsumed / 1000 * orgInfo.co2).toFixed(2) : 0}
                      </Text>
                      <Text marginBottom="0px" color={supportTextColor}>{t("consumed")}</Text>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <Text marginBottom="0px" fontWeight="700" color="#56C568" style={{ fontSize: "28px" }}>
                          {consumInfo && consumInfo.energySaved !== 0 && consumInfo.energySaved !== undefined ? (consumInfo.energySaved / 1000 * orgInfo.co2).toFixed(2) : 0}
                        </Text>
                        <Text marginBottom="0px" color="#56C568">{t("saved")}</Text>
                      </div>
                      <img src={co2} style={{ width: "30px", height: "30px", alignSelf: "end" }} />
                    </div>
                  </div>
                </div>
              </div>
            :
              <></>
            }
          </div>
        </div>
      </Modal.Body>
    </CustomModal>
  );
};
