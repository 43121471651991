import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import { store } from "../../../../redux/store";
import { getGroupsAction, postGroupAction } from "../../../../redux/actions/groupsActions/groupsActions";
import { setSelectGroupActionCreator, resetGroupInfoActionCreator } from "../../../../redux/slices/groupsSlice/groupsSlice";
import { useGroupsService } from "../../../../services/groupsService";
import { GroupsLeafletMap } from "../../GroupsMap/GroupsLeafletMap";
import { LoaderModal } from "../../../../components/LoaderModal/LoaderModal";
import { LogoStyled } from "../../../Header/HeaderStyled";
import { CardContainer, ButtonStrategy } from "../../../StrategiesPage/StrategiesSteps/StrategiesStepsStyled";
import { Text } from "../../../../styles/shared-styles/text-sizes";
import company from "../../../../assets/new_icons/Company.svg";
import luminaireIcon from "../../../../assets/new_icons/luminaire detailCard.svg";
import { ReactComponent as StepDotIcon } from "../../../../assets/new_icons/step - dot.svg";
import { ReactComponent as StepLineIcon } from "../../../../assets/new_icons/step - line.svg";

interface ReviewProps {
  onHide?: any;
  isNewGroup?: boolean;
  errorMessage?: boolean;
  setErrorMessage?: any;
}

export const Review = ({ onHide, isNewGroup, errorMessage, setErrorMessage }: ReviewProps) => {
  const [showLoader, setShowLoader] = useState(false);

  const { getGroups, postGroup }: any = useGroupsService();
  const dispatch = useAppDispatch();
  const groupsArr = useAppSelector((state) => state.groups.groupsArr);
  const name = useAppSelector((state) => state.groups.name);
  const orgImg: any = useAppSelector((state) => state.selectedOrganization.img);
  const orgName = useAppSelector((state) => state.selectedOrganization.orgName);
  const services = useAppSelector((state) => state.devices.services);
  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId: any = useAppSelector((state) => state.devices.selectedServiceId);
  const serviceName = services?.map((service:any, index:any) => {
    if (service.service_id === selectedServiceId) {
      return service.service_name
    }
  })
  const selectedDevices = useAppSelector((state) => state.groups.selectedDevices);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  const checkGroupsAndDevices = (name:any, groupsArr:any, selectedDevices:any) => {
    // Verificar si el nombre coincide con algún groupName en groupsArr
    for (let group of groupsArr) {
      if (group.groupName === name) {
        return false;
      }
    }

    const selectedNodeIds = new Set(selectedDevices.map((device:any) => device.node_id));
    // Verificar si los nodos de selectedDevices coinciden exactamente con los nodos de algún grupo en groupsArr
    for (let group of groupsArr) {
      const groupNodeIds = new Set(group.nodes.map((node:any) => node.node_id));
  
      if (selectedNodeIds.size === groupNodeIds.size && [...selectedNodeIds].every(node_id => groupNodeIds.has(node_id))) {
        return false;
      }
    }
    // Si no encontró ninguna coincidencia en ambos bucles, retorna true
    return true;
  };

  const sendInfo = async () => {
    setShowLoader(true);

    const isAvailable = checkGroupsAndDevices(name, groupsArr, selectedDevices);

    if (isAvailable) {
      let postGroupResponse = await postGroup(selectedOrgId, selectedServiceId, setShowLoader);
      if (postGroupResponse) {
        dispatch(setSelectGroupActionCreator({ selectedGroup: [] }))
        store.dispatch(postGroupAction(postGroupResponse));
      } else {
        setShowLoader(false);
        console.log("error in postGroup api");
        return false;
      }
      let getGroupsResponse = await getGroups(selectedOrgId, selectedServiceId, setShowLoader);
      if (getGroupsResponse) {
        store.dispatch(getGroupsAction(getGroupsResponse));
      } else {
        setShowLoader(false);
        console.log("error in getGroups api");
        return false;
      }
      dispatch(resetGroupInfoActionCreator());
      onHide();
    } else {
      setErrorMessage(true);
      setShowLoader(false);
      return;
    }
  };

  return (
    <CardContainer style={{ display: "flex", flexDirection: "column", paddingLeft: "40px" }}>
      <LoaderModal showLoader={showLoader} background={"rgba(0, 0, 0, 0.5)"} />
      <div style={{ marginBottom: "4px" }}>
        <Text fontWeight="500" style={{marginBottom: "4px", fontSize: "14px"}}>{t("step3")}</Text>
        <Text fontWeight="600" marginBottom="0px" style={{fontSize: "22px"}}>{t("reviewConfiguration")}</Text>
        <Text textType="caption" fontWeight="400" style={{fontSize: "12px"}}>{t("reviewConfigurationSubtitleGroups")}</Text>
      </div>
      <div style={{display: "flex", gap: "20px", marginBottom: "20px"}}>
        <div style={{width: "350px" }}>
          <Text fontWeight="600" color={mainTextColor} marginBottom="10px" style={{fontSize: "14px"}}>{t("groupName")}</Text>
          <div className="card" style={{ padding: "14px", border: "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px",
            justifyContent: "center", height: "71%" }}
          >
            <Text fontWeight="600" color={mainTextColor} marginBottom="0px" style={{fontSize: "14px"}}>{name}</Text>         
          </div>
        </div>
        <div style={{width: "350px" }}>
          <Text fontWeight="600" color={mainTextColor} marginBottom="10px" style={{fontSize: "14px"}}>{t("organizationAndService")}</Text>
          <div className="card" style={{ padding: "14px", border: "none", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", backdropFilter: "blur(2px)", borderRadius: "10px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <LogoStyled src={orgImg ? `https://${orgImg}` : company} alt="smartec logo" />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text fontWeight="600" color={mainTextColor} marginBottom="2px" style={{fontSize: "14px"}}>{orgName}</Text>
                <div style={{ display: "flex", gap: "6px", opacity: 0.4 }}>
                  <img src={luminaireIcon} width={16} />
                  <Text fontWeight="400" marginBottom="0px" style={{fontSize: "10px", marginTop: "2px"}}>{serviceName}</Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Text fontWeight="600" color={mainTextColor} marginBottom="10px" style={{fontSize: "14px"}}>{t("devicesSelected")}</Text>
      <div style={{ display: "flex", alignContent: "center", justifyContent: "space-between", gap: "20px", height: "406px", width: "96%" }}>
        <div style={{  height: "398px", width: "95%" }} className="position-relative mb-3">
          <GroupsLeafletMap isNewGroup={isNewGroup} showDevice={false} showIcon={false} fromGroupsPage={false} fromReviewPage={true} />
        </div>
        <article style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.15)", borderRadius: "4px", width: "28%",
          background: "linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #ffffff", overflowY: "auto" }}
        >
          {selectedDevices?.map((device:any, index:any) => (
              <>
                <style>
                  {`
                    .org-span:hover {
                      background-color: #1c1b1f11;
                    }
                  `}
                </style>
                <span className="org-span" key={index} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", isolation: "isolate",
                  paddingLeft: "15px", paddingRight: "6px", width: "100%", minHeight: "56px", cursor: "pointer", textAlign: "left" }}
                >
                  <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
                    <img src={luminaireIcon} style={{ marginBottom: "1px" }} />
                    {device.name}
                  </div>
                </span>
              </>
            ))
          }
        </article>
      </div>
      {
        errorMessage ?
          <Text marginBottom="0px" style={{display: "flex",  justifyContent: "center", color: "red", marginTop: "6px"}}>{t("errorCreatingGroup")}</Text>  
        :
          <></>
      }
      <ButtonStrategy buttonType="" style={{justifySelf: "end", alignSelf: "center", width: "300px", marginTop: errorMessage ? "15px" : "42px", marginLeft: "-40px", padding: "8px 32px",
        lineHeight: "none", background: buttonsColor, color: accentTextColor, transition: "margin-top ease-in-out"
      }} onClick={() => {sendInfo()}}
      >
        {t("create")}
      </ButtonStrategy>
      <div style={{ display: "flex", gap: "10px", alignSelf: "center", marginTop: "20px", marginLeft: "-40px" }}>
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepDotIcon height={22} width={8} style={{ color: accentButtonsColor, opacity: 0.3 }} />
        <StepLineIcon height={22} width={22} style={{ color: accentButtonsColor }} />
      </div>
    </CardContainer>
  );
};
