import { useEffect, useState, useCallback } from "react";
import { useMapEvents } from "react-leaflet";
import { LatLng, LatLngBounds } from "leaflet";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { allowFlyModeActionCreator } from "../../../redux/slices/uiSlice/uiSlice";
import { Lamp } from "../../../types/interfaces";
import "../../../styles/index.css";

interface ReportsMapEventHandlerProps {
  setMapBounds: React.Dispatch<React.SetStateAction<number>>;
  lamps: Array<Lamp>;
}

export const ReportsMapEventHandler = ({ setMapBounds, lamps }: ReportsMapEventHandlerProps) => {
  const [firstRender, setFirstRender] = useState(true);

  const dispatch = useAppDispatch();
  const { allowFlyMode, selectedBoundsToFly: positionsToFly } = useAppSelector((state) => state.ui);
  const staticView = useAppSelector((state) => state.ui.isStaticView);

  const map = useMapEvents({
    load: () => {},
    locationfound: (event) => map.flyTo(event.latlng),

    zoomend: () => {
      /* dispatch(setIsStaticViewActionCreator(false)); */
      setMapBounds(map.getZoom());
      dispatch(allowFlyModeActionCreator(false));
      map.dragging.enable();
      // if (map.getZoom() !== 2) {
      // map.dragging.enable();
      // } else {
      //   map.dragging.disable();
      // }
    },
  });

  const flyToPosition = useCallback(
    async (lamps: any) => {
      if (lamps) {
        const latitudeArray = lamps.map((lamp: any) => lamp.latitude);
        const longitudeArray = lamps.map((lamp: any) => lamp.longitude);

        const minLat = Math.min(...latitudeArray);
        const maxLat = Math.max(...latitudeArray);
        const minLng = Math.min(...longitudeArray);
        const maxLng = Math.max(...longitudeArray);

        const southWest = new LatLng(minLat, minLng);
        const northEast = new LatLng(maxLat, maxLng);
        
        map.flyToBounds(new LatLngBounds(southWest, northEast), {
          animate: true,
          maxZoom: 18,
          padding: [50, 50],
        });
      }
    },
    [positionsToFly, map]
  );

  useEffect(() => {
    if (firstRender) {
      dispatch(allowFlyModeActionCreator(true));
      if (allowFlyMode && staticView) {
        flyToPosition(lamps);
      }
    }
    setFirstRender(false)
  }, [flyToPosition, lamps, allowFlyMode]);

  return null;
};
