import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Devices, Lamp } from "../../../types/interfaces";

const initialState: Devices = {
  gateways: [],
  nodes: [],
  rgbNodes: [],
  nodesInArea: [],
  organizations: [],
  selectedOrganizationId: 0,
  services: [],
  selectedServiceId: 0
};

const devicesSlice = createSlice({
  name: "devices",
  initialState: initialState,
  reducers: {
    loadAllDevices: (previousState, action: PayloadAction<Devices>) => ({
      ...action.payload,
    }),

    loadNodesInArea: (previousState, action) => ({
      ...previousState,
      nodesInArea: action.payload
    }),

    /* saveNodes: (previousState, action: PayloadAction<Devices>) => ({
      ...previousState,
      nodes: [...previousState.nodes, ...action.payload.nodes]
    }), */

    updateNodeDeviceStatusGlobalRedux: (previousState:any, action:any) => {
      const { node_id, msg_type, value } = action.payload;

      const updatedNodes = previousState.nodes.map((node:any) => {
        if (node.node_id === node_id) {
          switch (msg_type) {
            case 2:
              return { ...node, on: 0, online: 1 };
            case 1:
              return { ...node, on: 1, online: 1 };
            case 3:
              return { ...node, on: 1, bri: value, online: 1 };
            case 4:
              return { ...node, on: 0, online: 0 };
            case 300:
              return { ...node, latitude: value.lat, longitude: value.lon };
            default:
              return node;
          } 
        }
        return node;
      });

      const updatedNodesRgb = previousState.rgbNodes && previousState.rgbNodes.length > 0 ? 
        previousState.rgbNodes.map((node:any) => {
          if (node.node_id === node_id) {
            switch (msg_type) {
              case 41:
                return { ...node, on: 0, online: 1 };
              case 40:
                const values = value.split(', ');
                const R = parseInt(values[0]);
                const G = parseInt(values[1]);
                const B = parseInt(values[2]);
  
                return { ...node, on: 1, R: R, G: G, B: B, online: 1 };
              case 4:
                return { ...node, on: 0, online: 0 };
              default:
                return node;
            } 
          }
          return node;
        }) : previousState.rgbNodes;

      const updatedAreaNodes = previousState.nodesInArea && previousState.nodesInArea.length > 0 ? 
        previousState.nodesInArea.map((node:any) => {
          if (node.node_id === node_id) {
            switch (msg_type) {
              case 2:
                return { ...node, on: 0, online: 1 };
              case 1:
                return { ...node, on: 1, online: 1 };
              case 3:
                return { ...node, on: 1, bri: value, online: 1 };
              case 4:
                return { ...node, on: 0, online: 0 };
              case 300:
                return { ...node, latitude: value.lat, longitude: value.lon };
              default:
                return node;
            } 
          }
          return node;
        }) : previousState.nodesInArea;

      return { ...previousState, nodes: updatedNodes, rgbNodes: updatedNodesRgb, nodesInArea: updatedAreaNodes };

      /* const { node_id, msg_type, value } = action.payload;

      // Access the correct node_id property from the state structure
      const currentNode = previousState.nodes.find(
        (node: any) => node.node_id === node_id
      );

      if (currentNode) {
        const statusUpdates: {
          [key: number]: { on: number; online: number; bri?: any };
        } = {
          1: { on: 1, online: 1 },
          2: { on: 0, online: 1 },
          3: { on: 1, online: 1, bri: value },
          4: { on: 0, online: 0 },
        };

        const update = statusUpdates[msg_type];
        if (update) {
          // Update the current node with the new status
          const updatedNode = { ...currentNode, ...update };

          // Update the nodes array in the state with the updated node
          const updatedNodes = previousState.nodes.map((node: any) =>
            node.node_id === node_id ? updatedNode : node
          );

          // Return the updated state with the modified nodes array
          return { ...previousState, nodes: updatedNodes };
        } 
      }

      return previousState;*/
    },

    /* updateNodeDeviceStatusGlobalRedux: (previousState, action) => {
      const nodeToUpdate = previousState.nodes.find(
        (node) => node.node_id === action.payload.node_id
      );

      console.log("payload redux glob")
      console.log(action.payload)

      previousState.nodes.forEach((node) => {
        if (nodeToUpdate !== undefined) {
          if (node.node_id === nodeToUpdate.node_id) {
            if(action.payload.msg_type === 2 || action.payload.msg_type === 41) {
              node.on = 0;
              node.online = 1;
            }
            else if(action.payload.msg_type === 1 || action.payload.msg_type === 40) {
              node.on = 1;
              node.online = 1;
            }
            else if(action.payload.msg_type === 3) {
              node.on = 1;
              node.bri = action.payload.value;
              node.online = 1;
            }
            else if(action.payload.msg_type === 4) {
              node.on = 0;
              node.online = 0;
            }
          }
        }  
      });
    }, */

    updateNodeDeviceStatusGlobal: (previousState, action: PayloadAction<Lamp>) => {
      const { node_id, on, selected, bri } = action.payload;

      const updatedNodes = previousState.nodes.map((node) => {
        if (node.node_id === node_id) {
          return {
            ...node,
            on,
            selected,
            bri,
          };
        }
        return node;
      });

      const updatedAreaNodes = previousState.nodesInArea && previousState.nodesInArea.length > 0 ? 
        previousState.nodesInArea.map((node:any) => {
          if (node.node_id === node_id) {
            return {
              ...node,
              on,
              selected,
              bri,
            };
          }
          return node;
        }) : previousState.nodesInArea;

      return { ...previousState, nodes: updatedNodes, nodesInArea: updatedAreaNodes };
    },

    /* updateNodeDeviceStatusGlobal: (previousState, action: PayloadAction<Lamp>) => {
      const nodeToUpdate = previousState.nodes.findIndex(
        (node) => node.node_id === action.payload.node_id
      );

      previousState.nodes.forEach((node, index) => {
        if (index === nodeToUpdate) {
          node.on = action.payload.on;
          node.selected = action.payload.selected;
          node.bri = action.payload.bri;
        }
      });
    }, */
  },
});

export const devicesReducer = devicesSlice.reducer;

export const {
  loadAllDevices: loadDevicesActionCreator,
  loadNodesInArea: loadNodesInAreaActionCreator,
 /*saveNodes: saveNodesActionCreator,*/
  updateNodeDeviceStatusGlobalRedux: updateNodeDeviceStatusGlobalReduxActionCreator,
  updateNodeDeviceStatusGlobal: updateNodeDeviceStatusGlobalActionCreator
} = devicesSlice.actions;
