import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../redux/hooks";
import { useOrganizationsService } from "../../../services/selectedOrganizationService"
import { ButtonOutlined } from "../../../styles/shared-styles/buttons-sizes";
import { CommonCardContainer } from "../../../styles/shared-styles/card-container";
import { Text } from "../../../styles/shared-styles/text-sizes";
import map from "../../../assets/new_icons/Map.svg";

export const MainInfo = () => {
  const orgInfo: any = useAppSelector((state) => state.selectedOrganization);
  const [name, setName] = useState(orgInfo.orgName);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorExistingData, setErrorExistingData] = useState<boolean>(false);
  const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const selectedOrgId: any = useAppSelector((state) => state.devices.selectedOrganizationId);
  const selectedServiceId = useAppSelector((state) => state.devices.selectedServiceId);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { updateOrgName, getSelectedOrganizationInfo } = useOrganizationsService();
  const { t } = useTranslation();

  const handleUpdateName = async () => {
    setIsLoading(true);
    setErrorUpdate(false);
    setSuccess(false);

    try {
      await updateOrgName(selectedOrgId, name);
      const newOrgInfo = await getSelectedOrganizationInfo(selectedOrgId, selectedServiceId);
      setIsLoading(false);

      if (newOrgInfo) {
        setSuccess(true);
      } else {
        setErrorUpdate(true);
      }

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      setIsLoading(false);
      setErrorUpdate(true);
    }
  };

  return (
    <CommonCardContainer className="mb-4">
      <Text className="mb-4" textType="cardTitle" color="black">{t("mainInfo")}</Text>
      <div className="mb-4" style={{ width: "50%", paddingRight: "12px" }}>
        <Text marginBottom="8px">{t("orgName")}</Text>
        <input className="form-control" value={name} onChange={(e) => {setName(e.target.value); setErrorExistingData(false); setErrorUpdate(false)}} />
      </div>
      <div className="d-flex justify-content-between" style={{ }}>
        <div>
          <Text marginBottom="8px">{t("location")}</Text>
          <div className="d-flex align-items-center">
            <img style={{ opacity: 0.6 }} src={map} height={15} className="me-2" />
            <Text color={supportTextColor} fontWeight="500" marginBottom="0px">{orgInfo.city}, {orgInfo.country}</Text>
          </div>
        </div>
        {isLoading ? (
          <ButtonOutlined type="button" disabled height="40px" style={{ alignSelf: "flex-end", border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              {t("loading...")}
          </ButtonOutlined>
        ) : (
          <ButtonOutlined height="40px" style={{ alignSelf: "flex-end", border: `1px solid ${accentButtonsColor}`, color: accentButtonsColor }} onClick={() => {
            if (name && !(/^\s/.test(name))) {
              if (name === orgInfo.orgName) {
                setErrorExistingData(true);
              } else {
                handleUpdateName();
              }
            } else if (name && (/^\s/.test(name))) {
              setErrorUpdate(true)
              return
            }
          }}>
            {t("Update")}
          </ButtonOutlined>
        )}
      </div>
      <div style={{ height: '10px', textAlign: "right", alignSelf: "end" }}>
        {(/^\s/.test(name) || name === "") ? (
          <Text marginBottom="0px" color="red">{t("errorBlankInput")}</Text>
        ) : 
          errorExistingData ? (
            <Text marginBottom="0px" color="red">{t("errorExistingData")}</Text>
          ) :
            errorUpdate ? (
              <Text marginBottom="0px" color="red">{t("errorUpdating")}</Text>
            ) :
              success ? (
                <Text marginBottom="0px" color="green">{t("updated")}</Text>
              ) : (
                <></>
        )}
      </div>
    </CommonCardContainer>
  )
}
