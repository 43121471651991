import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { turnBackgroundErrorPopUpFalseActionCreator } from "../../redux/slices/graphicsSlice/graphicsSlice";
import { ErrorPopUpStyled } from "./ErrorPopUpStyled";
import { Text, TextBox } from "../../styles/shared-styles/text-sizes";
import { ReactComponent as Cross } from "../../assets/new_icons/Cross.svg";

export const ErrorPopUp = () => {
  const dispatch = useAppDispatch();
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const { t } = useTranslation();

  const changeBackground = () => {
    dispatch(turnBackgroundErrorPopUpFalseActionCreator());
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      changeBackground();
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <ErrorPopUpStyled>
      <div className="popup__button-container">
        <Cross width={12} height={12} style={{ color: "#525252", cursor: "pointer", marginRight: "0px" }} onClick={() => {changeBackground()}} />
      </div>
      <div className="popup__body-container">
        <Text
          fontWeight="600"
          marginBottom="8px"
          textType="subTitle"
          color="#000000"
          style={{ marginTop: "6px", fontSize: "18px" }}
        >
          {t("alertWind")}
        </Text>
        <div
        style={{ background: buttonsColor }}
          className="popup__body-container__button"
          onClick={() => {changeBackground()}}
        >
          <TextBox
            fontWeight="500"
            color={accentTextColor}
            style={{ fontSize: "16px" }}
          >
            {t("Accept")}
          </TextBox>
        </div>
      </div>
    </ErrorPopUpStyled>
  );
};
