import { useState, useEffect } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { LatLng } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { setSelectedDevicesStandardListActionCreator, setSelectedDevicesSolarListActionCreator, setSelectedDevicesHybridListActionCreator, setSelectedDevicesRgbListActionCreator,
  setSelectedDevicesSmartecListActionCreator, setSelectedDevicesShuncomListActionCreator, setSelectedDevicesSwitchListActionCreator } from "../../../redux/slices/reportsSlice/pdfReportSlice";
import { ReportsCustomMarker } from "./ReportsCustomMarker";
import { ReportsMapEventHandler } from "./ReportsMapEventHandler";
import { CustomMapStyled } from "../../CustomMaps/CustomMap/CustomMapStyled";
import { GroupLeafletMapHeader } from "../../GroupsPage/GroupsPageStyled";
import "../../../styles/index.css";
import Gateway from "../../../assets/new_icons/Gateway nodePage.svg";
import Luminaire from "../../../assets/new_icons/luminaire detailCard.svg";

interface ReportsModalMapProps {
  isStandard?: boolean;
  isSolar?: boolean;
  isHybrid?: boolean;
  isRgb?: boolean;
  isSmartec?: boolean;
  isShuncom?: boolean;
  isSwitch?: boolean;
  isNewGroup?: boolean | any;
  showDevice?: boolean | any;
  interactiveMap?: boolean | any;
  fromGroupsPage?: boolean | any;
  fromReviewPage?: boolean | any;
  setErrorMessage?: any;
}

export const ReportsLeafletMap = ({ isStandard, isSolar, isHybrid, isRgb, isSmartec, isShuncom, isSwitch, isNewGroup, showDevice, interactiveMap, fromGroupsPage, fromReviewPage,
    setErrorMessage }: ReportsModalMapProps) => {
  const allDevices = useAppSelector((state) => state.devices);
  const standard = allDevices.nodes.filter((node:any) => node.solar_id === null);
  const solar = allDevices.nodes.filter((node:any) => node.solar_id !== null);
  const hybrid = allDevices.nodes.filter((node:any) => node.hybrid === "Y");
  const rgb = allDevices.rgbNodes;
  const smartec = allDevices.gateways.filter((gate:any) => gate.type === "smartec");
  const shuncom = allDevices.gateways.filter((gate:any) => gate.type === "shuncom");
  const switchboard = allDevices.switchboards;
  const { selectedDevicesStandard, selectedDevicesSolar, selectedDevicesHybrid, selectedDevicesRgb, selectedDevicesSmartec, selectedDevicesShuncom,
    selectedDevicesSwitch } = useAppSelector((state) => state.pdfReport);

  const [map, setMap] = useState(0);
  const dispatch = useAppDispatch();
  const setSelectedLamp = (device: any) => {
    console.log(device)
    setErrorMessage(false);
    let isSelected;

    if (isStandard) {
      isSelected = selectedDevicesStandard.some((id: any) => device.node_id === id);
    } else if (isSolar) {
      isSelected = selectedDevicesSolar.some((id: any) => device.node_id === id);
    } else if (isHybrid) {
      isSelected = selectedDevicesHybrid.some((id: any) => device.node_id === id);
    } else if (isRgb) {
      isSelected = selectedDevicesRgb.some((id: any) => device.node_id === id);
    } else if (isSmartec) {
      isSelected = selectedDevicesSmartec.some((id: any) => device.node_id === id);
    } else if (isShuncom) {
      isSelected = selectedDevicesShuncom.some((id: any) => device.node_id === id);
    } else if (isSwitch) {
      isSelected = selectedDevicesSwitch.some((id: any) => device.node_id === id);
    }

    if (!isSelected) {
      if (isStandard) {
        dispatch(setSelectedDevicesStandardListActionCreator({ selectedDevicesStandard: [...selectedDevicesStandard, device.node_id] }));
      } else if (isSolar) {
        dispatch(setSelectedDevicesSolarListActionCreator({ selectedDevicesSolar: [...selectedDevicesSolar, device.node_id] }));
      } else if (isHybrid) {
        dispatch(setSelectedDevicesHybridListActionCreator({ selectedDevicesHybrid: [...selectedDevicesHybrid, device.node_id] }));
      } else if (isRgb) {
        dispatch(setSelectedDevicesRgbListActionCreator({ selectedDevicesRgb: [...selectedDevicesRgb, device.node_id] }));
      } else if (isSmartec) {
        dispatch(setSelectedDevicesSmartecListActionCreator({ selectedDevicesSmartec: [...selectedDevicesSmartec, device.node_id] }));
      } else if (isShuncom) {
        dispatch(setSelectedDevicesShuncomListActionCreator({ selectedDevicesShuncom: [...selectedDevicesShuncom, device.node_id] }));
      } else if(isSwitch) {
        dispatch(setSelectedDevicesSwitchListActionCreator({ selectedDevicesSwitch: [...selectedDevicesSwitch, device.node_id] }));
      }
    } else if (isSelected) {
      if (isStandard) {
        const remainingSelected = selectedDevicesStandard.filter(
          (id: any) => id !== device.node_id
        );
        console.log(remainingSelected)
        dispatch(setSelectedDevicesStandardListActionCreator({ selectedDevicesStandard: remainingSelected }));
      } else if (isSolar) {
        const remainingSelected = selectedDevicesSolar.filter(
          (id: any) => id !== device.node_id
        );
        dispatch(setSelectedDevicesSolarListActionCreator({ selectedDevicesSolar: remainingSelected }));
      } else if (isHybrid) {
        const remainingSelected = selectedDevicesHybrid.filter(
          (id: any) => id !== device.node_id
        );
        dispatch(setSelectedDevicesHybridListActionCreator({ selectedDevicesHybrid: remainingSelected }));
      } else if (isRgb) {
        const remainingSelected = selectedDevicesRgb.filter(
          (id: any) => id !== device.node_id
        );
        dispatch(setSelectedDevicesRgbListActionCreator({ selectedDevicesRgb: remainingSelected }));
      } else if (isSmartec) {
        const remainingSelected = selectedDevicesSmartec.filter(
          (id: any) => id !== device.node_id
        );
        dispatch(setSelectedDevicesSmartecListActionCreator({ selectedDevicesSmartec: remainingSelected }));
      } else if (isShuncom) {
        const remainingSelected = selectedDevicesShuncom.filter(
          (id: any) => id !== device.node_id
        );
        dispatch(setSelectedDevicesShuncomListActionCreator({ selectedDevicesShuncom: remainingSelected }));
      } else if(isSwitch) {
        const remainingSelected = selectedDevicesSwitch.filter(
          (id: any) => id !== device.node_id
        );
        dispatch(setSelectedDevicesSwitchListActionCreator({ selectedDevicesSwitch: remainingSelected }));
      }
    }
  };

  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const calculateCenter = (devices: any) => {
    const defaultLat = 0;
    const defaultLng = 0;
    let minLat = devices[0]?.latitude;
    let maxLat = devices[0]?.latitude;
    let minLng = devices[0]?.longitude;
    let maxLng = devices[0]?.longitude;

    devices.forEach((device: any) => {
      minLat = Math.min(minLat, device.latitude);
      maxLat = Math.max(maxLat, device.latitude);
      minLng = Math.min(minLng, device.longitude);
      maxLng = Math.max(maxLng, device.longitude);
    });

    const lat =
      ((minLat !== undefined ? minLat : defaultLat) +
        (maxLat !== undefined ? maxLat : defaultLat)) /
      2;
    const lng =
      ((minLng !== undefined ? minLng : defaultLng) +
        (maxLng !== undefined ? maxLng : defaultLng)) /
      2;

    return { lat, lng };
  };

  const center:any = isStandard ?
    calculateCenter(standard.filter((lamp: any) => lamp.latitude))
  : 
    isSolar ?
      calculateCenter(solar.filter((lamp: any) => lamp.latitude))
    :
      isHybrid ?
        calculateCenter(hybrid.filter((lamp: any) => lamp.latitude))
      :
        isRgb ?
          calculateCenter(rgb.filter((lamp: any) => lamp.latitude))
        :
          isSmartec ?
            calculateCenter(smartec.filter((lamp: any) => lamp.latitude))
          :
            isShuncom ?
              calculateCenter(shuncom.filter((lamp: any) => lamp.latitude))
            :
              isSwitch ?
                calculateCenter(switchboard.filter((lamp: any) => lamp.latitude))
              :
                <></>

  const calculateMaxDistance = (devices: any) => {
    let maxDistance = 0;
    devices.forEach((device1: any, index1: any) => {
      devices.slice(index1 + 1).forEach((device2: any) => {
        const distance = Math.sqrt(
          Math.pow(device1.latitude - device2.latitude, 2) +
          Math.pow(device1.longitude - device2.longitude, 2)
        );
        maxDistance = Math.max(maxDistance, distance);
      });
    });
    return maxDistance;
  };

  const maxDistance:any = isStandard ?
    calculateMaxDistance(standard.filter((lamp: any) => lamp.latitude))
  : 
    isSolar ?
      calculateMaxDistance(solar.filter((lamp: any) => lamp.latitude))
    :
      isHybrid ?
        calculateMaxDistance(hybrid.filter((lamp: any) => lamp.latitude))
      :
        isRgb ?
          calculateMaxDistance(rgb.filter((lamp: any) => lamp.latitude))
        :
          isSmartec ?
            calculateMaxDistance(smartec.filter((lamp: any) => lamp.latitude))
          :
            isShuncom ?
              calculateMaxDistance(shuncom.filter((lamp: any) => lamp.latitude))
            :
              isSwitch ?
                calculateMaxDistance(switchboard.filter((lamp: any) => lamp.latitude))
              :
                <></>
 

  const zoom =
    maxDistance < 0.01
      ? 17
      : maxDistance < 0.05
      ? 13
      : maxDistance < 0.5
        ? 10
        : 8;

  useEffect(() => {
  }, [allDevices.nodes]);

  return (
    <CustomMapStyled id="map-container" style={{ height: "106%" }}>
      <GroupLeafletMapHeader className="top-map-header">
        
        <div className="top-left-map-section">
          <p style={{ border: `2px solid ${accentButtonsColor}`}}>
            <img src={Luminaire} alt="luminaire" />
            <span style={{ color: accentButtonsColor }}>{
              isStandard ? 
                standard.length
              : 
                isSolar ?
                  solar.length
                :
                  isHybrid ?
                    hybrid.length
                  :
                    isRgb ?
                      rgb.length
                    :
                      isSmartec ?
                        smartec.length
                      :
                        isShuncom ?
                          shuncom.length
                        :
                          isSwitch ?
                            switchboard.length
                          :
                            <></>
              }</span>
          </p>
        </div>
  
        {isStandard ? 
            showDevice && selectedDevicesStandard.length > 0 && (
              <div className="top-right-map-section">
                <h6>{t("devicesSelected")}</h6>
                <p>
                  <img src={Luminaire} alt="luminaire" />
                  <span>{selectedDevicesStandard.length}</span>
                </p>
                {/* <p>
                  <img src={Gateway} alt="gateway" />
                  <span>123</span>
                </p> */}
              </div>
            )
          : 
            isSolar ?
              showDevice && selectedDevicesSolar.length > 0 && (
                <div className="top-right-map-section">
                  <h6>{t("devicesSelected")}</h6>
                  <p>
                    <img src={Luminaire} alt="luminaire" />
                    <span>{selectedDevicesSolar.length}</span>
                  </p>
                  {/* <p>
                    <img src={Gateway} alt="gateway" />
                    <span>123</span>
                  </p> */}
                </div>
              )
            :
              isHybrid ?
                showDevice && selectedDevicesHybrid.length > 0 && (
                  <div className="top-right-map-section">
                    <h6>{t("devicesSelected")}</h6>
                    <p>
                      <img src={Luminaire} alt="luminaire" />
                      <span>{selectedDevicesHybrid.length}</span>
                    </p>
                    {/* <p>
                      <img src={Gateway} alt="gateway" />
                      <span>123</span>
                    </p> */}
                  </div>
                )
              :
                isRgb ?
                  showDevice && selectedDevicesRgb.length > 0 && (
                    <div className="top-right-map-section">
                      <h6>{t("devicesSelected")}</h6>
                      <p>
                        <img src={Luminaire} alt="luminaire" />
                        <span>{selectedDevicesRgb.length}</span>
                      </p>
                      {/* <p>
                        <img src={Gateway} alt="gateway" />
                        <span>123</span>
                      </p> */}
                    </div>
                  )
                :
                  isSmartec ?
                    showDevice && selectedDevicesSmartec.length > 0 && (
                      <div className="top-right-map-section">
                        <h6>{t("devicesSelected")}</h6>
                        <p>
                          <img src={Luminaire} alt="luminaire" />
                          <span>{selectedDevicesSmartec.length}</span>
                        </p>
                        {/* <p>
                          <img src={Gateway} alt="gateway" />
                          <span>123</span>
                        </p> */}
                      </div>
                    )
                  :
                    isShuncom ?
                      showDevice && selectedDevicesShuncom.length > 0 && (
                        <div className="top-right-map-section">
                          <h6>{t("devicesSelected")}</h6>
                          <p>
                            <img src={Luminaire} alt="luminaire" />
                            <span>{selectedDevicesShuncom.length}</span>
                          </p>
                          {/* <p>
                            <img src={Gateway} alt="gateway" />
                            <span>123</span>
                          </p> */}
                        </div>
                      )
                    :
                      isSwitch ?
                        showDevice && selectedDevicesSwitch.length > 0 && (
                          <div className="top-right-map-section">
                            <h6>{t("devicesSelected")}</h6>
                            <p>
                              <img src={Luminaire} alt="luminaire" />
                              <span>{selectedDevicesSwitch.length}</span>
                            </p>
                            {/* <p>
                              <img src={Gateway} alt="gateway" />
                              <span>123</span>
                            </p> */}
                          </div>
                        )
                      :
                        <></>
        
        }
      </GroupLeafletMapHeader>
      <MapContainer
        className="my-custom-map"
        center={[center.lat, center.lng]}
        zoom={zoom}
        dragging={true}
        touchZoom={false}
        doubleClickZoom={false}
        scrollWheelZoom={true}
        boxZoom={false}
        keyboard={false}
        zoomControl={false}
        attributionControl={false}
        maxZoom={30}
        minZoom={5}
      >
        <TileLayer
          key={"map layer"}
          //url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          url={`https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}`}
          maxZoom={30}
          maxNativeZoom={19}
        />
       
        <ReportsMapEventHandler
          lamps={isStandard ? 
            standard.filter((node:any) => node.latitude)
          : 
            isSolar ?
              solar.filter((node:any) => node.latitude)
            :
              isHybrid ?
                hybrid.filter((node:any) => node.latitude)
              :
                isRgb ?
                  rgb.filter((node:any) => node.latitude)
                :
                  isSmartec ?
                    smartec.filter((node:any) => node.latitude)
                  :
                    isShuncom ?
                      shuncom.filter((node:any) => node.latitude)
                    :
                      isSwitch ?
                        switchboard.filter((node:any) => node.latitude)
                      :
                        <></>
          }
          setMapBounds={setMap}
        ></ReportsMapEventHandler>
  
        <MarkerClusterGroup
          chunkedLoading
          onClick={() => {}}
          maxClusterRadius={0}
          removeOutsideVisibleBounds
          disableClusteringAtZoom={15}
          spiderfyOnMaxZoom={false}
          zoom={10}
        >
          {isStandard ? 
            standard.filter((node:any) => node.latitude)?.map((lamp: any) => (
              <ReportsCustomMarker
                filteredLamps={standard}
                interactive={interactiveMap}
                key={lamp.node_id + Math.random().toString()}
                lamp={lamp}
                setMarker={setSelectedLamp}
                selected={selectedDevicesStandard.some(
                  (id: any) => id === lamp.node_id
                )}
                editable={false}
                center={
                  new LatLng(
                    Number.parseFloat(lamp.latitude),
                    Number.parseFloat(lamp.longitude)
                  )
                }
              />
            ))
          : 
            isSolar ?
              solar.filter((node:any) => node.latitude)?.map((lamp: any) => (
                <ReportsCustomMarker
                  filteredLamps={solar}
                  interactive={interactiveMap}
                  key={lamp.node_id + Math.random().toString()}
                  lamp={lamp}
                  setMarker={setSelectedLamp}
                  selected={selectedDevicesSolar.some(
                    (id: any) => id === lamp.node_id
                  )}
                  editable={false}
                  center={
                    new LatLng(
                      Number.parseFloat(lamp.latitude),
                      Number.parseFloat(lamp.longitude)
                    )
                  }
                />
              ))
            :
              isHybrid ?
                hybrid.filter((node:any) => node.latitude)?.map((lamp: any) => (
                  <ReportsCustomMarker
                    filteredLamps={hybrid}
                    interactive={interactiveMap}
                    key={lamp.node_id + Math.random().toString()}
                    lamp={lamp}
                    setMarker={setSelectedLamp}
                    selected={selectedDevicesHybrid.some(
                      (id: any) => id === lamp.node_id
                    )}
                    editable={false}
                    center={
                      new LatLng(
                        Number.parseFloat(lamp.latitude),
                        Number.parseFloat(lamp.longitude)
                      )
                    }
                  />
                ))
              :
                isRgb ?
                  rgb.filter((node:any) => node.latitude)?.map((lamp: any) => (
                    <ReportsCustomMarker
                      filteredLamps={rgb}
                      interactive={interactiveMap}
                      key={lamp.node_id + Math.random().toString()}
                      lamp={lamp}
                      setMarker={setSelectedLamp}
                      selected={selectedDevicesRgb.some(
                        (id: any) => id === lamp.node_id
                      )}
                      editable={false}
                      center={
                        new LatLng(
                          Number.parseFloat(lamp.latitude),
                          Number.parseFloat(lamp.longitude)
                        )
                      }
                    />
                  ))
                :
                  isSmartec ?
                    smartec.filter((gate:any) => gate.latitude)?.map((lamp: any) => (
                      <ReportsCustomMarker
                        filteredLamps={smartec}
                        interactive={interactiveMap}
                        key={lamp.node_id + Math.random().toString()}
                        lamp={lamp}
                        setMarker={setSelectedLamp}
                        selected={selectedDevicesSmartec.some(
                          (id: any) => id === lamp.node_id
                        )}
                        editable={false}
                        center={
                          new LatLng(
                            Number.parseFloat(lamp.latitude),
                            Number.parseFloat(lamp.longitude)
                          )
                        }
                      />
                    ))
                  :
                    isShuncom ?
                      shuncom.filter((gate:any) => gate.latitude)?.map((lamp: any) => (
                        <ReportsCustomMarker
                          filteredLamps={shuncom}
                          interactive={interactiveMap}
                          key={lamp.node_id + Math.random().toString()}
                          lamp={lamp}
                          setMarker={setSelectedLamp}
                          selected={selectedDevicesShuncom.some(
                            (id: any) => id === lamp.node_id
                          )}
                          editable={false}
                          center={
                            new LatLng(
                              Number.parseFloat(lamp.latitude),
                              Number.parseFloat(lamp.longitude)
                            )
                          }
                        />
                      ))
                    :
                      isSwitch ?
                        switchboard.filter((switchboard:any) => switchboard.latitude)?.map((lamp: any) => (
                          <ReportsCustomMarker
                            filteredLamps={switchboard}
                            interactive={interactiveMap}
                            key={lamp.node_id + Math.random().toString()}
                            lamp={lamp}
                            setMarker={setSelectedLamp}
                            selected={selectedDevicesSwitch.some(
                              (id: any) => id === lamp.node_id
                            )}
                            editable={false}
                            center={
                              new LatLng(
                                Number.parseFloat(lamp.latitude),
                                Number.parseFloat(lamp.longitude)
                              )
                            }
                          />
                        ))
                      :
                        <></>
          }
        </MarkerClusterGroup>
      </MapContainer>
    </CustomMapStyled>
  );
};
