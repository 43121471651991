import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Checkbox, Select, Form, Button } from "antd";
import { store } from "@/redux/store";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { fetchAlarms } from "../../redux/actions/alarmsActions/alarmsActions";
import { useAlarmsService } from "../../services/alarmService";
import { AlarmsList } from "./AlarmsList/AlarmsList";
import { IconStyled } from "../LuminairePage/LuminairePageStyled";
import { FilterDropdownMenu, InputFormFiltering, ListItem, ButtonFilter, SelectFormFilteringSmall, ListOrganization, OrganizationCard, CompanyProfile, SelectFilterDropdown, Table,
  OptionSelect, ArrowImg } from "../UsersPage/UsersPageStyled";
  import { Icon, InputContainer } from "../UsersPage/UsersList/UsersStructureStyled";
import { TextSpan, Text } from "../../styles/shared-styles/text-sizes";
import arrowDown from "../../assets/new_icons/Arrow down.svg";
import filter from "../../assets/new_icons/filter.svg";
import searchIcon from "../../assets/new_icons/search.svg";

export const AlarmsPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchString, setSearchString] = useState("");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const alarms = useAppSelector((state) => state.alarms.allAlarms);
  //const { alarms } = useAppSelector((state) => state.alarms);
  const { selectedOrganizationId, selectedServiceId } = useAppSelector((state) => state.devices);
  const { getAlarms } = useAlarmsService();
  const { t } = useTranslation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="container-fluid" style={{ marginTop: "40px", paddingRight: "10px", paddingLeft: "0px", position: "relative", zIndex: 2 }}>
      <div style={{ display: "flex", alignItems: "center", justifyContent:"space-between", marginBottom: "22px", marginRight: "8px" }}>
        <div>
          <Text textType="title" fontWeight="700" marginBottom="8px">
            {t("alarmsPageTitle")}
          </Text>
          <Text textType="">
            {t("alarmsPageSubtitle")}
          </Text>
        </div>
        <div className="col-2" style={{ textAlign: "right", alignSelf: "flex-end", marginBottom: "0px" }}>
          <ul style={{ display: "inline-flex", marginBottom: "0px" }}>
            {/* <ListItem>
              <div className="input-group">
                <InputContainer
                  type="text"
                  className="form-control"
                  placeholder=""
                />
                <Icon className="input-group-text">
                  <img src={searchIcon} alt="Search" />
                </Icon>
              </div>
              <div className="position-relative">
                <IconStyled src={filter} onClick={toggleMenu} />

                {isOpen && (
                  <FilterDropdownMenu>
                    <div
                      className="list-container"
                      style={{
                        // justifyContent: "space-around",
                        marginBottom: "16px",
                      }}
                    >
                      <TextSpan
                        textType="Filtercaption"
                        color="#A3AED0"
                        marginBottom="5px"
                      >
                        Device
                      </TextSpan>

                      <div className="position-relative">
                        <SelectFilterDropdown
                          id="inputState"
                          className="form-control"
                        >
                          <OptionSelect selected>Oman</OptionSelect>
                          <OptionSelect>...</OptionSelect>
                        </SelectFilterDropdown>
                        <ArrowImg src={arrowDown} />
                      </div>
                      <TextSpan
                        textType="Filtercaption"
                        color="#A3AED0"
                        marginTop="15px"
                        marginBottom="5px"
                      >
                        Alarm type
                      </TextSpan>
                      <div className="position-relative">
                        <SelectFilterDropdown
                          id="inputState"
                          className="form-control"
                        >
                          <OptionSelect selected>Oman</OptionSelect>
                          <OptionSelect>...</OptionSelect>
                        </SelectFilterDropdown>
                        <ArrowImg src={arrowDown} />
                      </div>
                      <TextSpan
                        textType="Filtercaption"
                        color="#A3AED0"
                        marginTop="15px"
                        marginBottom="5px"
                      >
                        Status
                      </TextSpan>
                      <div className="position-relative">
                        <SelectFilterDropdown className="form-control">
                          <OptionSelect selected>Muscat</OptionSelect>
                          <OptionSelect>...</OptionSelect>
                        </SelectFilterDropdown>
                        <ArrowImg src={arrowDown} />
                      </div>
                      <TextSpan
                        textType="Filtercaption"
                        color="#A3AED0"
                        marginTop="15px"
                        marginBottom="5px"
                      >
                        Date
                      </TextSpan>
                      <div className="position-relative">
                        <SelectFilterDropdown className="form-control">
                          <OptionSelect selected>Muscat</OptionSelect>
                          <OptionSelect>...</OptionSelect>
                        </SelectFilterDropdown>
                        <ArrowImg src={arrowDown} />
                      </div>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <ButtonFilter>Apply Filter</ButtonFilter>
                    </div>
                  </FilterDropdownMenu>
                )}
              </div>
            </ListItem> */}

            <ListItem style={{ marginRight: "4px" }}>
              <TextSpan color="#A3AED0">{alarms.length} {t("alarms")}</TextSpan>
            </ListItem>
          </ul>
        </div>
      </div>
      <AlarmsList />
    </div>
  );
};
