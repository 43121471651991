import styled from "styled-components";

export const CardContainer = styled.div`
  background-color: #ffffff;
  padding: 24px;
  border: 2px solid #fff !important;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 3px rgba(125, 125, 125, 0.103);
  height: 700px;
`;

export const EmptyCardContainer = styled.div`
  background-color: #ffffff;
  padding: 32px;
  border: 2px solid #fff !important;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 3px rgba(125, 125, 125, 0.103);
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CommonCardContainer = styled.div`
  padding: ${(props: { padding?: string }) => props.padding || "24px"};
  background-color: #ffffff;
  border: 2px solid #fff !important;
  border-radius: 20px;
  box-shadow: 0px 4px 8px 3px rgba(125, 125, 125, 0.103);
`;
