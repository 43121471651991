import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  registerables,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import 'chart.js/auto';
import { useAppDispatch } from "../../../redux/hooks";
import { saveImageUsersActionCreator } from "../../../redux/slices/reportsSlice/pdfReportSlice";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ...registerables
);

interface ValuesProps {
  values: any;
}

export const UsersChartPdf = ({ values }: ValuesProps) => {
  const chartRef:any = useRef(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false,
      },
      maintainAspectRatio: false,
    },
    cutout: '85%'
  };
  
  const labels =  values?.user?.role_name === "owner" ?
    [t("owner"), t("admin"), t("observer"), t("maintenance"), t("external")]
  :
    [t("admin"), t("observer"), t("maintenance"), t("external")]
  const colors = values?.user?.role_name === "owner" ?
    ["#1B2559", "#FBBC05", "#45D0EE", "#444444", "#A3AED0"]
  :
    ["#FBBC05", "#45D0EE", "#444444", "#A3AED0"]
  
  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: ` ${t("total")}`,
        data: values?.user?.role_name === "owner" ?
          [values?.reports?.usersOwner, values?.reports?.usersAdmin, values?.reports?.usersObserver, values?.reports?.usersMaintenance, values?.reports?.usersExternal]
        :
          [values?.reports?.usersAdmin, values?.reports?.usersObserver, values?.reports?.usersMaintenance, values?.reports?.usersExternal],
        backgroundColor: colors,
        weight: 0.5
      }
    ],
  };

  useEffect(() => {
    const chart:any = chartRef.current;
    if (chart) {
      setTimeout(() => {
        const chartImage = chart.toBase64Image();
        dispatch(saveImageUsersActionCreator(chartImage));
      }, 2000);
    }
  }, []);

  return (
    <div style={{ width: "200px", height: "200px", position: "relative" }}>
      <Doughnut ref={chartRef} options={options} data={data} />
    </div>
  )
};
