import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setActualFilterWithAreaActionCreator, setFiltersActionCreator } from "../../../redux/slices/actualFilterSlice/actualFilterSlice";
import { filterAlarmDevicesActionCreator, filterDisconnectedDevicesActionCreator, filteredAllDevicesActionCreator, filteredAllDevicesFromGroupsActionCreator,
  filteredNodesInAreaActionCreator, filterOffDevicesActionCreator, filterOnDevicesActionCreator, filterSolarDevicesActionCreator } from "../../../redux/slices/devices/filteredDevicesSlice";
import { allowFlyModeActionCreator, setIsStaticViewActionCreator, setIsOpenedDetailActionCreator } from "../../../redux/slices/uiSlice/uiSlice";
import { DevicesFilter } from "../../../types/interfaces";
import { FilterElementStyled } from "./FilterElementStyled";
import OFF from "../../../assets/new_icons/OFF.svg";
import ON from "../../../assets/new_icons/ON.svg";

interface FilterElementProps {
  icon: string;
  title: string;
  type: "ON" | "OFF" | "Disconnected" | "Alarmed" | "All" | "selectArea" | "Solar";
  style?: any;
}

export const FilterElement = ({ icon, title, type, style }: FilterElementProps) => {
  const [isActive, setIsActive] = useState(false);
  const detailPopUp = useAppSelector((state) => state.ui.isOpenedDetail);
  const nodesInArea = useAppSelector((state) => state.ui.nodesInArea);
  const dispatch = useAppDispatch();
  const filter: DevicesFilter = {
    area: type === "selectArea",
    all: type === "All",
    on: type === "ON",
    off: type === "OFF",
    disconnected: type === "Disconnected",
    alarms: type === "Alarmed",
    solar: type === "Solar"
  };

  const devices = useAppSelector((state) => state.devices);
  const nodes = useAppSelector((state) => state.devices.nodes);
  const filteredNodes:any = useAppSelector((state) => state.filteredDevices.nodes);
  const nodesFromGroups = useAppSelector((state) => state.filteredDevices.nodesFromGroups);
  const allAreaNodes = useAppSelector((state) => state.devices.nodesInArea);
  const filteredAreaNodes = useAppSelector((state) => state.filteredDevices.nodesInArea);
  const actualFilter = useAppSelector((state) => state.actualFilter);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);

  const selected =
    (type === "selectArea" && actualFilter.area) ||
    (type === "All" && actualFilter.all) ||
    (type === "ON" && actualFilter.on) ||
    (type === "OFF" && actualFilter.off) ||
    (type === "Disconnected" && actualFilter.disconnected) ||
    (type === "Alarmed" && actualFilter.alarms) ||
    (type === "Solar" && actualFilter.solar);

  const handleClick = () => {
    let action;

    if (nodesFromGroups) {  
      action =
        type === "All"
          ? filteredAllDevicesFromGroupsActionCreator(nodesFromGroups)
          : type === "ON"
          ? filterOnDevicesActionCreator(nodesFromGroups)
          : type === "OFF"
          ? filterOffDevicesActionCreator(nodesFromGroups)
          : type === "Disconnected"
          ? filterDisconnectedDevicesActionCreator(nodesFromGroups)
          : type === "Alarmed"
          ? filterAlarmDevicesActionCreator(nodesFromGroups)
          : type === "Solar"
          ? filterSolarDevicesActionCreator(nodesFromGroups)
          : null;
    } else {
      if (nodesInArea) {
        action =
        type === "All"
          ? filteredNodesInAreaActionCreator(allAreaNodes)
          : type === "ON"
          ? filterOnDevicesActionCreator(allAreaNodes)
          : type === "OFF"
          ? filterOffDevicesActionCreator(allAreaNodes)
          : type === "Disconnected"
          ? filterDisconnectedDevicesActionCreator(allAreaNodes)
          : type === "Alarmed"
          ? filterAlarmDevicesActionCreator(allAreaNodes)
          : type === "Solar"
          ? filterSolarDevicesActionCreator(allAreaNodes)
          : null;
      } else {
        action =
          type === "All"
            ? filteredAllDevicesActionCreator(devices)
            //? filteredAllDevicesActionCreator(nodes)
            : type === "ON"
            ? filterOnDevicesActionCreator(nodes)
            : type === "OFF"
            ? filterOffDevicesActionCreator(nodes)
            : type === "Disconnected"
            ? filterDisconnectedDevicesActionCreator(nodes)
            : type === "Alarmed"
            ? filterAlarmDevicesActionCreator(nodes)
            : type === "Solar"
            ? filterSolarDevicesActionCreator(nodes)
            : null;
      }
    }

    if (detailPopUp) {
      dispatch(setIsOpenedDetailActionCreator(false));
    }
    if (action) dispatch(action);

    if (filter.area === true) {
      dispatch(setFiltersActionCreator(filter));
    } else {
      dispatch(setActualFilterWithAreaActionCreator(filter));
    }

    setIsActive(!isActive);
    dispatch(setIsStaticViewActionCreator(false));
    dispatch(allowFlyModeActionCreator(true));
  };

  return (
    <FilterElementStyled
      onClick={nodesInArea ? filteredAreaNodes.length > 0 ? handleClick : () => {} : filteredNodes.length > 0 ? handleClick : () => {}}
      title={title}
    >
      <div className={selected ? "active" : ""} style={{ border: selected ? `2px solid ${accentButtonsColor}` : `1.5px solid #A3AED0`}}>
        <img src={icon} style={{ padding: "1px", width: "18px", height: "18px", marginTop: icon === ON || icon === OFF ? "-2px": "" }} />
        {selected && <h1 style={{ color: accentButtonsColor}}>{title}</h1>}
      </div>
    </FilterElementStyled>
  );
};
