import styled from "styled-components";

export const DetailPopupStyled = styled.section`
  position: absolute;
  z-index: 2;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);
  border-radius: 14px;
  padding: 15px;

  .popup {
    &__button-container {
      display: flex;
      justify-content: space-between;

      &__icon {
        font-size: 1.2rem;
        color: #686b6f;
        cursor: pointer;
      }
    }

    &__title {
      font-weight: 600;
      font-size: 24px;
      padding: 0px 15px;
      margin-top: 10px;
      margin-bottom: 8px;
    }
    
    &__details-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;
      padding: 0px 15px;
    }

    &__status-container {
      margin-top: 16px;
      display: flex;
      gap: 5px;
      padding: 0px 15px;
    }

    &__onoff-container {
      width: 100%;
      border: 1px solid #c4c4c4;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #ffffff;
      height: 36px;
    }

    &__message-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 8px;
      margin-bottom: -18px;
    }

    &__link-container {
      cursor: pointer;
      position: absolute;
      margin-top: 7px;
      right: 50%;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 20px 10px 24px;
      gap: 4px;
      height: 45px;
      font-weight: 600;
      transform: translate(50%, 50%);
      color: #1b2559;
      box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
        0px 1px 3px rgba(0, 0, 0, 0.3);
    }
  }

  .status-container {
    &__icon {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #5e6871;
    }

    &__content {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }

  .details-container {
    &__details {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: 400;
      font-size: 14px;
    }

    &__icon {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 18px;
      padding-top: 2px;

      &--on {
        color: #87e897;
        font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
      }

      &--alarm {
        color: #cf2a2a;
        font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
      }
    }
  }

  .onoff-container {
    &__button {
      padding: 0;
      background-color: transparent;
      flex: 0.5;
      border: none;
      font-weight: 500;
      font-size: 15px;
      color: #979797;
      height: 100%;
      cursor: pointer;

      &--on {
        background: #56c568;
        border-radius: 0 7px 7px 0;
        color: #ffffff;
        transform: scale(1.03);
      }

      &--on-disabled {
        background: rgb(229, 229, 229);
        border-radius: 0 7px 7px 0;
        color: rgb(171, 171, 171);
        cursor: not-allowed;
        transform: scale(1.01);
        border-left: 0.5px solid rgb(171, 171, 171, 0.5);
        border-right: 1px solid rgb(171, 171, 171, 0.5);
      }

      &--off {
        background: #525252;
        border-radius: 7px 0 0 7px;
        color: #ffffff;
        transform: scale(1.03);
      }

      &--off-disabled {
        background: rgb(229, 229, 229);
        border-radius: 7px 0 0 7px;
        color: rgb(171, 171, 171);
        cursor: not-allowed;
        transform: scale(1.01);
        border-left: 1px solid rgb(171, 171, 171, 0.5);
        border-right: 0.5px solid rgb(171, 171, 171, 0.5);
      }
    }
  }

  .link-container {
    &__link {
      all: unset;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .move {
    color: #bdbdbd;
    cursor: move;
  }
`;

export const BatteryLevel = styled.div`
  padding: 15px;
  padding-bottom: 0px;

  .battery {
    &__progress-bar {
      width: 100%;
      height: 12px;
      -webkit-appearance: none;
      border-radius: 10px;
      position: relative;

      ::-webkit-slider-thumb {
        -webkit-appearance: none;
      }
    }

    &__percentages {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 14px;
    }
  }
`;

export const DetailPopupHybridViewStyled = styled.section`
  position: absolute;
  z-index: 1;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(2px);
  padding: 13px;
  padding-left: 18px;
  border-radius: 14px;

  .hybridpopup {
    &__block {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__title {
        width: 150px;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 0px;
        margin-right: -70px;
      }

      &__position {
        display: flex;
        align-items: center;
        margin-left: -70px;
        gap: 7px;

        &--icon {
          font-size: 18px;
        }

        &--text {
          font-size: 12px;
        }
      }

      &__close {
        align-self: flex-start;
        font-size: 18px;
        margin-top: -6px;
        cursor: pointer;
      }
    }

    &__bulk {
      &__icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        position: absolute;
        margin-top: -14px;
        right: 20%;
        border-radius: 40px;
        padding: 10px;
        height: 45px;
        font-weight: 600;
        transform: translate(50%, 15%);
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
          0px 1px 3px rgba(0, 0, 0, 0.3);
      }

      &__container {
        position: absolute;
        top: 50px;
        right: 80px;
        width: 215px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.08),
            rgba(255, 255, 255, 0.08)
          ),
          #ffffff;
        box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15),
          0px 4px 4px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        z-index: 9999;

        &--action {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          isolation: isolate;
          padding-left: 15px;
          width: 100%;
          height: 56px;
          cursor: pointer;
        }

        &--action-disabled {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          isolation: isolate;
          padding-left: 15px;
          width: 100%;
          height: 56px;
          cursor: not-allowed;
          background: #1c1b1f11;
        }

        &--action:hover {
          background-color: #1c1b1f11;
        }
      }
    }
  }
`;
