import { getUserPersonalInfoActionCreator } from "../../../redux/slices/managedUsers/managedUserSlice";
import { loadUsersActionCreator, updateUserActionCreator } from "../../../redux/slices/managedUsers/managedUsersListSlice";
import { managedUsersList } from "../../../types/interfaces";

export const getUsersAction = (usersList: managedUsersList) => async (dispatch: any) => {
  dispatch(loadUsersActionCreator(usersList));
};

export const getManagedUserPersonalInfoAction = (user: any) => async (dispatch: any) => {
  dispatch(getUserPersonalInfoActionCreator(user));
};

export const updateUserAction = (newInfo: any) => async (dispatch: any) => {
  dispatch(updateUserActionCreator(newInfo));
};
