import { useEffect, useState } from "react";
import styled from 'styled-components';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../redux/hooks";
import { CardSimple } from "../../LuminairePageStyled";
import { Text, TextSpan } from "../../../../styles/shared-styles/text-sizes";
import energyBlue from "../../../../assets/new_icons/Energy-blue.svg";

interface BatteryProps {
  selectedLamp: any
}

export const Battery = ({ selectedLamp }: BatteryProps) => {
  const [batteryLevel, setBatteryLevel] = useState(selectedLamp.batt_level);

  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const { t } = useTranslation();

  const batteryStyle:any = {
    width: '70px',
    height: '120px',
    border: '1.5px solid rgba(82, 82, 82, 0.6)', // Color #525252 con 60% de opacidad
    borderRadius: "10px",
    position: 'relative',
    overflow: 'hidden',
  };
  
  const levelStyle:any = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "linear-gradient(#00C7F2, #0CB2D6)",
    width: '100%',
    position: 'absolute',
    bottom: '0',
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    height: `${batteryLevel}%`,
  };

  const Shadow = styled.div`
    content: "";
    position: absolute;
    top: 0;
    left: 25%;
    width: 50%;
    height: 4px;
    /* background: linear-gradient(transparent, rgba(0, 0, 255, 0.2), transparent); */
    background: linear-gradient(to right, transparent, #80E0F6, transparent);
  `;

  useEffect(() => {
    setBatteryLevel(selectedLamp.batt_level);
  }, [selectedLamp.batt_level]);

  return (
    <CardSimple style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
        <Text color={mainTextColor} style={{ fontWeight: 500 }}>{t("Battery")}</Text>
      </div>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center", /* height: 0, zIndex: 6,  */justifyContent: "center", gap: "0.5px", top: "48px", /* left: 2, position: "relative" */}}>
        <div style={{width: '30px', height: '6px', border: '1.5px solid #525252', opacity: "0.6", borderTopLeftRadius: "12px", borderTopRightRadius: "12px", position: 'relative'}} />
        <div style={batteryStyle}>
          <div style={levelStyle}>
          {/*  tocar el position del text y el textspan. Zindex? */}
            <Text color="white" fontWeight="500" marginBottom="0" style={{ position: "absolute", bottom: 50, color: batteryLevel < 57 ? mainTextColor : "white" }}>{!batteryLevel && batteryLevel !== 0 ? '\"\"': batteryLevel}%</Text>
            <TextSpan color="white" marginBottom="0" style={{ textAlign: "center", fontSize: "10px", position: "absolute", color: batteryLevel < 10 ? mainTextColor : "white", bottom: 1 }}>{!selectedLamp.batt_voltage && selectedLamp.batt_voltage !== 0 ? '\"\"' : selectedLamp.batt_voltage}V</TextSpan>
          </div>
          {/* <Shadow /> */}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px", gap: "3px" }}>
        <img src={energyBlue} width={14} />
        <Text marginBottom="0">{!selectedLamp.batt_status ? '\"\"' : selectedLamp.batt_status}</Text>
      </div>      
    </CardSimple>
  );
};
