import { connectToMqtt } from "./mqttService";
import CryptoJS from "crypto-js";

class AuthService {
  public async smartecLogin(
    email: string,
    password: string
    ): Promise<any | null> {
      try {
      const encryptedPassword = CryptoJS.AES.encrypt(password, process.env.REACT_APP_SMARTEC as string).toString();

      const response = await fetch(`${process.env.REACT_APP_LOCAL_BASE_URL as string}/auth/login`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        //body: JSON.stringify({ email, password }),
        body: JSON.stringify({ email, password: encryptedPassword }),
      });

      if (response.ok) {
        const setCookieHeader = response.headers.get("Set-Cookie");
        const data = await response.json();
        let organizationsArray = data.user.orgs;
        let userId = data.user.user_id.toString();
        const { handleOrganizationChange } = connectToMqtt(organizationsArray, userId, data.mqttToken);
        // Extract cookies from the setCookieHeader if needed
        //return "success";
        //return { result: "success", organizationsArray };
        return { result: "success", organizationsArray, handleOrganizationChange, userId };
      } else {
        return null; // Invalid credentials or error
      }
    } catch (error) {
      console.error("Login error:", error);
      return null;
    }
  }

  public async smartecForgotPassword(email: string): Promise<any | null> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LOCAL_BASE_URL as string}/auth/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        const data = await response.json(); // Parse the response data
        return {
          data,
          error: null,
        };
      } else {
        return {
          data: null,
          error: await response.json(),
        };
      }
    } catch (error: any) {
      throw error;
    }
  }

  public async smartecResetPassword(
    password: string,
    confirmPassword: string,
    resetToken: string | null
  ): Promise<any | null> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LOCAL_BASE_URL as string}/auth/reset-password?token=${resetToken}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password, confirmPassword }),
        }
      );

      if (response.ok) {
        const data = await response.json(); // Parse the response data
        return {
          data,
          error: null,
        };
      } else {
        return {
          data: null,
          error: await response.json(),
        };
      }
    } catch (error: any) {
      throw error;
    }
  }
}

const authService = new AuthService();
export default authService;
