import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Checkbox } from "antd";
import { useAppSelector } from "../../../../redux/hooks";
import { RootState } from "../../../../redux/store";
import { managedUserServicesArray } from "../../../../types/interfaces";
import { useUsersService } from "../../../../services/usersService";
import { ServiceCard, ServiceList } from "./ServicesStyled";
import { CompanyProfile } from "../../UsersPageStyled";
import { ButtonOutlined } from "../../../../styles/shared-styles/buttons-sizes";
import { Text, TextSpan } from "../../../../styles/shared-styles/text-sizes";
import companyFlag from "../../../../assets/new_icons/Company.svg";

export interface servicesProps {}

export const Services = ({}: servicesProps) => {
  const [dataToUpdate, setDataToUpdate] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorExistingData, setErrorExistingData] = useState<boolean>(false);
  const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const { getManagedUserServices, postManagedUserServices } = useUsersService();
  const userRole = useAppSelector((state) => state.user.role_name);
  const userInfo = useAppSelector((state: RootState) => state.managedUser);
  const [servicesFromRedux, setServicesFromRedux] = useState<any>(userInfo?.services);
  const mainTextColor = useAppSelector((state) => state.selectedOrganization.mainTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const { t } = useTranslation();

  const handleDataToUpdate = (item:any, service:any, e: any) => {
    let obj = {
      orgId: item.orgId,
      services: [{
        id: service.id,
        selected: e.target.checked
      }]
    }
    setDataToUpdate(prevData => [...prevData, obj]);
  }

  // revisar esta función cuando haya más de un service en más de una org
  const handleUpdate = async (data:any) =>  {
    setIsLoading(true);
    let filteredArray:any = [];
    for (let i = 0; i < data.length; i++) {
      let orgId = data[i].orgId;
      let services = data[i].services;
      let orgIndex = filteredArray.findIndex((org:any) => org.orgId === orgId);
      if (orgIndex === -1) {
        filteredArray.push({orgId: orgId, services: []});
        orgIndex = filteredArray.length - 1;
      }

      for (let j = 0; j < services.length; j++) {
        let serviceId = services[j].id;
        let selected = services[j].selected;
        let serviceIndex = filteredArray[orgIndex].services.findIndex((service:any) => service.id === serviceId);
        if (serviceIndex !== -1) {
          filteredArray[orgIndex].services[serviceIndex].selected = selected;
        } else {
          filteredArray[orgIndex].services.push({id: serviceId, selected: selected});
        }
      }
    }

    let compareArrays = userInfo?.services?.every((originalObj:any) => {
      let obj = filteredArray.find((filteredObj:any) => filteredObj.orgId === originalObj.orgId);
      if (!obj || obj.services.length !== originalObj.services.length) {
        return false;
      }
      return originalObj.services.every((originalService:any) => {
        let resultService = obj.services.find((resService:any) => resService.id === originalService.id);
        return resultService && resultService.selected === originalService.selected;
      });
    })

    if (compareArrays) {
      setIsLoading(false);
      setErrorExistingData(true);
      return
    }

    try {
      const token:any = Cookies.get("token");
      const response = await postManagedUserServices(token, userInfo?.id, filteredArray);
      
      if (response) {
        setSuccess(true);
        await getManagedUserServices(token, userInfo?.id, setIsLoading);
      } else {
        setIsLoading(false);
        setErrorUpdate(true);
      }

      setTimeout(() => {
        setSuccess(false);
      }, 2000);

    } catch (error) {
      setIsLoading(false);
      setErrorUpdate(true);
    }
  }

  useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = `
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${accentButtonsColor} !important;
        border-color: ${accentButtonsColor} !important;
        border-radius: 0px !important;
      }
      
      .ant-checkbox .ant-checkbox-inner {
        border: 2px solid ${accentButtonsColor} !important;
        border-radius: 0px !important;
      }
    `;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, [accentButtonsColor]);

  return (
    <div style={{ display: "flex", flexDirection: "column", paddingRight: "30px", paddingLeft: "10px", height: "68.6%", position: "relative" }}>
      <Text color={mainTextColor} textType="subTitle" fontWeight="700" marginBottom="20px">{t("servicesPermits")}</Text>
      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap", overflowY: "auto", paddingTop: "2px", paddingLeft: "3px", maxHeight: "350px" }}>
        {servicesFromRedux?.map((service:any) => (
          <ServiceCard style={{ display: "flex", flexDirection: "column", width: "420px", paddingTop: "10px", paddingBottom: "10px", marginBottom: "6px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "8px", marginBottom: "18px", borderBottom: "2px solid #E7E0EC", width: "100%" }}>
              <div>
                <CompanyProfile src={service.image ? `https://${service.image}` : companyFlag} />
                <TextSpan fontWeight="700" style={{ marginLeft: "12px" }}>{service.orgName}</TextSpan>
              </div>
              <div style={{ display: "flex", alignItems: "center", paddingRight: "16px" }}>
                <Checkbox
                  checked={service?.services?.every((serv: managedUserServicesArray) => serv.selected)}
                  onChange={(e) => {
                    let updatedServices = service?.services.map((serv: managedUserServicesArray) => {
                      let newServ = {...serv, selected: true};
                      handleDataToUpdate(service, newServ, e);
                      return newServ;
                    });
                    let updatedServicesFromRedux = servicesFromRedux.map((serv: any) => {
                      if (serv === service) {
                        return {...serv, services: updatedServices};
                      }
                      return serv;
                    });
                  
                    setServicesFromRedux(updatedServicesFromRedux);
                  }}
                />
                <span style={{ fontSize: "12px", marginLeft: "8px" }}>{t("allServices")}</span>
              </div>
            </div>
            <ServiceList style={{ alignContent: "start", justifyContent: "flex-start" }}>
              {Array.isArray(service?.services) &&
                service?.services.map((serv: managedUserServicesArray) => (
                  <div>
                    <Checkbox
                      checked={serv !== undefined && serv?.selected}
                      onChange={(e) => { if (service?.services.length >= 2) {
                        let updatedService = {...serv, selected: e.target.checked};
                        handleDataToUpdate(service, updatedService, e);
                      
                        let updatedServicesFromRedux = servicesFromRedux.map((s: any) => {
                          if (s === service) {
                            let updatedServices = s.services.map((serv: managedUserServicesArray) => {
                              if (serv.id === updatedService.id) {
                                return updatedService;
                              }
                              return serv;
                            });
                            return {...s, services: updatedServices};
                          }
                          return s;
                        });
                      
                        setServicesFromRedux(updatedServicesFromRedux);
                      }
                      }}
                    />
                    <span style={{ fontSize: "12px", marginLeft: "8px" }}>{serv.servName}</span>
                  </div>
              ))}
            </ServiceList>
          </ServiceCard>
        ))}
      </div>
      <div style={{ textAlign: "right", marginBottom: "-2px", alignSelf: "flex-end", position: "absolute", bottom: "0" }}>  
        {isLoading ? (
          <ButtonOutlined type="button" disabled style={{ color: accentButtonsColor, border: `1px solid ${accentButtonsColor}` }}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
              {t("loading...")}
          </ButtonOutlined>
        ) : (
          <ButtonOutlined style={{ color: accentButtonsColor, border: `1px solid ${accentButtonsColor}`, cursor: userRole === "admin" && userInfo.role === "admin" ? "not-allowed" : "pointer" }}
            onClick={() => {
              if (userRole === "admin" && userInfo.role === "admin") {
              } else {
                if (dataToUpdate.length === 0) {
                  setErrorUpdate(true)
                } else {
                  handleUpdate(dataToUpdate)
                }}
              }
            }
          >
            {t("Update")}
          </ButtonOutlined>
        )}
      </div>
      <div style={{ height: '10px', marginBottom: "-27px", textAlign: "right", alignSelf: "flex-end", position: "absolute", bottom: "0" }}>
        {errorExistingData ?
          <Text marginBottom="0px" color="red">{t("errorExistingData")}</Text>
        :
          errorUpdate ?
            <Text marginBottom="0px" color="red">{t("errorUpdating")}</Text>
          : 
            success ?
              <Text marginBottom="0px" color="green">{t("updated")}</Text>
            :
              <></>
        }
      </div>
    </div>
  );
};
