import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Checkbox, Pagination } from "antd";
import type { PaginationProps } from "antd";
import { useAppSelector } from "../../../redux/hooks";
import { store } from "../../../redux/store";
import { fetchAlarms } from "../../../redux/actions/alarmsActions/alarmsActions";
import { useAlarmsService } from "../../../services/alarmService";
import { LoaderModal } from "../../../components/LoaderModal/LoaderModal";
import { GetBadge } from "../../../utils/alarmBadge/GetBadge";
import { ButtonSolvedStyled } from "../AlarmsPageStyled";
import { TextSpan, Text } from "../../../styles/shared-styles/text-sizes";
import alarmClock from "../../../assets/new_icons/alarm_clock.svg";
import clock from "../../../assets/new_icons/Clock.svg";
import gatewayImg from "../../../assets/new_icons/Gateways.svg";
import label from "../../../assets/new_icons/label.svg";
import luminaireImg from "../../../assets/new_icons/Luminaire_Img.svg";
import noAlarm from "../../../assets/new_icons/noAlarm_edited.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/new_icons/Arrow left dynamic.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/new_icons/Arrow right dynamic.svg";
import { ReactComponent as ID } from "../../../assets/new_icons/ID.svg"

//hay iconos que no son de new_icons, revisar

interface myDataType {
  key: number;
  checkbox: JSX.Element | any;
  luminaireImg: JSX.Element;
  luminaireName: JSX.Element;
  dateRegistered: JSX.Element;
  alarmDescription: JSX.Element;
  alarmType: JSX.Element;
  alarmStatus: JSX.Element;
  action: JSX.Element;
}

export const AlarmsList = () => {
  const [myData, setMyData] = useState<myDataType[]>([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showLoader, setShowLoader] = useState(false);
  const [masterChecked, setMasterChecked] = useState<boolean>(false);

  const alarms = useAppSelector((state) => state.alarms.allAlarms);
  const { selectedOrganizationId, selectedServiceId } = useAppSelector(
    (state) => state.devices
  );
  const buttonsColor = useAppSelector((state) => state.selectedOrganization.buttonsColor);
  const supportTextColor = useAppSelector((state) => state.selectedOrganization.supportTextColor);
  const accentTextColor = useAppSelector((state) => state.selectedOrganization.accentTextColor);
  const accentButtonsColor = useAppSelector((state) => state.selectedOrganization.accentButtonsColor);
  const userRole = useAppSelector((state) => state.user.role_name);
  const { getAlarms, solveAlarm, solveAllAlarms } = useAlarmsService();
  const { t } = useTranslation();

  function splitDateTime(dateString: string) {
    // Create a new Date object with the provided date string
    const date = new Date(dateString);

    // Extracting date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
    const day = String(date.getDate()).padStart(2, "0");

    // Extracting time components
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Return an object with date and time components
    return {
      date: `${year}-${month}-${day}`,
      time: `${hours}:${minutes}`,
    };
  }

  const markAsSolved = async (alarmId: number | string) => {
    let idsArr:any = [];
    if (alarmId === "all") {
      myData.map((alarm:any) => {
        if (alarm.checkbox) {
          idsArr.push(alarm.key.toString())
        }
      })
    }

    try {
      const token:any = Cookies.get("token");
      setShowLoader(true);
      if (idsArr.length > 0) {
        await solveAllAlarms(idsArr);
      } else {
        await solveAlarm(alarmId.toString());
      }

      let response = await getAlarms(selectedOrganizationId, selectedServiceId, token, setLoading)
      if (response) {
        store.dispatch(fetchAlarms(response));
      } else {
        store.dispatch(fetchAlarms([]));
      }
    } catch (error) {
      console.error("Error fetching alarm data:", error);
      setShowLoader(false);
    } finally {
      setShowLoader(false);
    }
  };

  const generateData = () => {
    const rowData: myDataType[] = [];

    if (alarms?.length > 0) {
      alarms?.map((alarm: any) => {
        rowData.push({
          key: alarm.id as number,
          checkbox: false,
          luminaireImg: <img src={alarm.device_name === "Gateway" ? gatewayImg : luminaireImg} height={80} width={80} />,
          luminaireName: (
            <>
              <Text marginBottom="10px" textType="title" fontWeight="500">
                {alarm.name}
              </Text>
              <div style={{ marginBottom: "5px" }}>
                <ID height={14} width={14} style={{ color: "#A3AED0", marginRight: "4px" }} />
                <TextSpan color={supportTextColor} fontWeight="500" style={{ marginRight: "16px" }}>
                  {alarm.device_id}
                </TextSpan>
                <img src={label} height={14} />
                <TextSpan color={supportTextColor} fontWeight="500">
                  {alarm.device_name}
                </TextSpan>
              </div>
            </>
          ),
          dateRegistered: (
            <div className="d-inline-block">
              <div className="d-flex align-items-center gap-2 mb-2" style={{ marginTop: "8px" }}>
                <img src={clock} width={20} height={20} style={{ opacity: 0.7 }} />
                <Text marginBottom="0px" color={supportTextColor} fontWeight="500">
                  {t("dateRegistered")}
                </Text>
              </div>
              <TextSpan fontWeight="500" textType="date">
                {splitDateTime(alarm.creation_date).date}
              </TextSpan>
              <div className="d-flex align-items-center gap-2 mb-2 justify-content-center">
                <img src={alarmClock} width={14} height={14} style={{ opacity: 0.7 }} />
                <Text marginBottom="0px" color={supportTextColor} fontWeight="500">
                  {splitDateTime(alarm.creation_date).time}
                </Text>
              </div>
            </div>
          ),
          alarmDescription: (
            <div style={{ marginTop: "8px" }}>
              <Text color={supportTextColor} marginBottom="5px" fontWeight="500">
                {t("alarmDescription")}
              </Text>
              <TextSpan fontWeight="600" marginBottom="0px">
                {t(`errorAlarmTitle${alarm.alarm_id}`)}
              </TextSpan>
              <br />
              <TextSpan marginBottom="20px" fontWeight="400" textType="alarmDesc">
                {t(`errorAlarmDescription${alarm.alarm_id}`)}
              </TextSpan>
            </div>
          ),
          alarmType: (
            <>
              <Text color={supportTextColor} marginBottom="8px">
                {t("alarmType")}
              </Text>
              <GetBadge status={alarm.type} />
              <TextSpan fontWeight="300" textType="alarm">
                {alarm.type}
              </TextSpan>
            </>
          ),
          alarmStatus: (
            <>
              <Text color={supportTextColor} marginBottom="8px">
                {t("Status")}
              </Text>
              <TextSpan>{alarm.status}</TextSpan>
            </>
          ),
          action: (
            <>
              {alarm.solved === "N" ? (
                <ButtonSolvedStyled
                  buttonsColor={buttonsColor}
                  accentTextColor={accentTextColor}
                  style={{ cursor: userRole === "observer" ? "not-allowed" : "", opacity: userRole === "observer" ? 0.5 : 1 }}
                  onClick={() => userRole !== "observer" && markAsSolved(alarm.id)}>
                  {t("markSolved")}
                </ButtonSolvedStyled>
              ) : (
                <TextSpan>{alarm.status}</TextSpan>
              )}
            </>
          ),
        });
      });
    } else {
      <Text color={supportTextColor} fontWeight="500" marginBottom="0px">
        {t("noAlarms")}
      </Text>
    }

    return rowData;
  };

  const checkAll = (e: React.ChangeEvent<HTMLInputElement>, currentRows: myDataType[]) => {
    const isChecked = e.target.checked;
    setMasterChecked(isChecked);

    setMyData((prevData) =>
      prevData.map((record) => ({
        ...record,
        checkbox: currentRows.some((row) => row.key === record.key) ? isChecked : record.checkbox,
      }))
    );
  };

  const handleCheckboxChange = (key: number) => {
    setMyData((prevData) => {
      const updatedData = prevData.map((record) =>
        record.key === key ? { ...record, checkbox: !record.checkbox } : record
      );

      const allChecked = updatedData.every((record) => record.checkbox);
      setMasterChecked(allChecked);
      return updatedData;
  });
  };

  const fetchData = () => {
    setLoading(true);
    const data = generateData();
    setMyData(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchAlarms({
      orgId: selectedOrganizationId,
      servId: selectedServiceId,
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [alarms]);

  const CardRow = ({ record }: { record: myDataType }) => (
    <>
      {showLoader ? (
        <LoaderModal showLoader={showLoader} />
      ) : (
        <div
          className="card"
          style={{
            padding: "16px",
            border: "none",
            boxShadow: "0px 2px 5px 0px #00000026",
            marginBottom: "16px",
          }}
        >
          <table>
            <tr>
              <td>
                <input
                  type="checkbox"
                  checked={record.checkbox}
                  className="form-check-input"
                  onChange={() => handleCheckboxChange(record.key)}
                  style={{ cursor: "pointer", width: "16px", height: "16px", backgroundColor: record.checkbox === true ? accentButtonsColor : "#FFFFFF" }}
                />
              </td>
              <td style={{ textAlign: "center" }}>{record.luminaireImg}</td>
              <td>{record.luminaireName}</td>
              <td>{record.dateRegistered}</td>
              <td>{record.alarmDescription}</td>
              <td>{record.alarmType}</td>
              <td>{record.alarmStatus}</td>
              <td style={{ textAlign: "right" }}>{record.action}</td>
            </tr>
          </table>
        </div>
      )}
   
{/* ponerle un margen al icono? */}


      {/* <table>
        <tr style={{ display: "flex", alignItems: "center", gap: "20px", justifyContent: "space-between", paddingLeft: "20px", paddingRight: "20px" }}>
          <td style={{ textAlign: "center" }}>{record.checkbox}</td>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "40px" }}>
            <td>{record.luminaireImg}</td>
            <td style={{ textAlign: "center" }}>{record.luminaireName}</td>
          </div>
          <td style={{ textAlign: "center" }}>{record.dateRegistered}</td>
          <td style={{ textAlign: "center" }}>{record.alarmDescription}</td>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "40px" }}>
            <td style={{ textAlign: "center" }}>{record.alarmType}</td>
            <td style={{ textAlign: "center" }}>{record.alarmStatus}</td>
          </div>
          <td style={{ textAlign: "right" }}>{record.action}</td>
        </tr>
      </table> */}
    </>
  );

  const indexOfLastRow = currentPage * pageSize;
  const indexOfFirstRow = indexOfLastRow - pageSize;
  const currentRows = myData.slice(indexOfFirstRow, indexOfLastRow);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <a>
          <ArrowLeftIcon height={12} style={{ color: "#525252" }} />
        </a>
      );
    }
    if (type === "next") {
      return (
        <a>
          <ArrowRightIcon height={12} style={{ color: "#525252" }} />
        </a>
      );
    }
    return originalElement;
  };

  return (
    <div className="position-relative" style={{ height: "calc(100vh - 250px)" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "20px", marginLeft: "19px", marginBottom: "10px" }}>
        <input
          type="checkbox"
          className="form-check-input"
          checked={masterChecked}
          id="mastercheck"
          onChange={(e) => checkAll(e, currentRows)}
          style={{ cursor: "pointer", width: "16px", height: "16px", backgroundColor: masterChecked ? accentButtonsColor : "#FFFFFF", marginTop: "0px" }}
        />
        {myData.filter((record) => record.checkbox).length > 1 &&
          <ButtonSolvedStyled
            buttonsColor={buttonsColor}
            accentTextColor={accentTextColor}
            style={{ cursor: userRole === "observer" ? "not-allowed" : "", opacity: userRole === "observer" ? 0.5 : 1 }}   
            onClick={async() => userRole !== "observer" && markAsSolved("all")}
          >
            {t("markAllSolved")}
          </ButtonSolvedStyled>
        }
      </div>
      <div className="row" style={{ marginBottom: "16px" }}>
        <div className="col-12">
          {showLoader ? (
            <LoaderModal showLoader={showLoader} />
          ) : (
            <div className="cards-container" style={{ maxHeight: myData.filter((record) => record.checkbox).length > 1 ? "591px" : "640px", overflowY: "auto", paddingLeft: "3px",
              paddingRight: "7px", paddingTop: "3px" }}
            >
              {currentRows.length > 0 ? (
                currentRows.map((record) => (
                  <CardRow key={record.key} record={record} />
                ))
              ) : (
                <div style={{ display: "flex", justifyContent: "center", height: "300px", alignItems: "center" }}>
                  <img src={noAlarm} height={82} />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="row end-0 bottom-0 position-absolute" style={{ textAlign: "right" }}>
        <div className="col-12">
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={myData.length}
            onChange={handlePageChange}
            showSizeChanger
            pageSizeOptions={["10", "20", "30", "50"]}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            itemRender={itemRender}
          />
        </div>
      </div>
    </div>
  );
};
